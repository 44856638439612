import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import React from "react";

import { buttonLoaderSelector } from "../../../../../../../components/loader/loader.selectors";
import { reserveFunds } from "../../../../../../orders/orders.operations";

import { paymentMethods } from "../../../../../../balance/balance.constants";
import SELECT_TYPES from "../../../../../../../components/select/select.constants";
import MainButton from "../../../../../../../components/main-button/main-button";
import Select from "../../../../../../../components/select/select";
import { reverseFundsSchema } from "./reverse-funds.schema";

import "./index.scss";

const ReverseFunds = ({ details: { orderId, taskId } }) => {
    const { status: statusLoading, button: buttonLoading } =
        useSelector(buttonLoaderSelector);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const {
        handleSubmit,
        control,
        formState: { errors, isValid },
    } = useForm({
        mode: "onChange",
        defaultValues: {},
        resolver: yupResolver(reverseFundsSchema),
    });

    const handleFormSubmit = ({ source }) => {
        dispatch(reserveFunds(orderId, taskId, source.value));
    };

    return (
        <form
            className="reverse-funds-modal"
            onSubmit={handleSubmit(handleFormSubmit)}
        >
            <h1 className="reverse-funds-modal__title">
                {t("chats_item.pay_for_the_order_before_the_start")}
            </h1>
            <Controller
                control={control}
                name="source"
                render={({ field: { onChange, onBlur, value, name } }) => (
                    <Select
                        placeholder={t("chats_item.payment_method_placeholder")}
                        label={t("chats_item.payment_method_title")}
                        onSelect={(orderId) => onChange(orderId)}
                        onBlur={(orderId) => onBlur(orderId)}
                        error={errors.source?.message}
                        type={SELECT_TYPES.WITH_LABEL}
                        options={paymentMethods}
                        value={value}
                        name={name}
                    />
                )}
            />
            <MainButton
                isLoading={statusLoading && buttonLoading === "confirm"}
                buttonSize="btn--medium"
                disabled={!isValid}
                type="submit"
            >
                {t("btn_pay")}
            </MainButton>
        </form>
    );
};

ReverseFunds.propTypes = { details: PropTypes.object };

export default ReverseFunds;
