export const isPermission = (array) => {
    return Object.values(array).some((value) => value === true);
};

export const renderPennies = (amount) => {
    if (amount < 10) {
        return `0${amount}`;
    } else return amount;
};

export const parseMoneyAmount = (value) => {
    const isValueWithComa = value.indexOf(",");

    if (isValueWithComa === -1) {
        return value + "00";
    } else {
        if (value.substring(isValueWithComa + 1).length === 1) {
            return value.replace(",", "") + "0";
        } else return value.replace(",", "");
    }
};
