import "./index.scss";
import PropTypes from "prop-types";
const Switch = ({ inputChangeOptions = {} }) => {
    return (
        <label className="switch">
            <input type="checkbox" {...inputChangeOptions} />
            <span className="slider-yellow round" />
        </label>
    );
};
Switch.propTypes = {
    inputChangeOptions: PropTypes.object,
};

export default Switch;
