import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import React, { memo } from "react";

import { leftbarConfig } from "./promotion-leftbar.configs";
import routerBook from "../../../../router/router-book";

import "./index.scss";

const PromotionLeftBar = () => {
    const { t } = useTranslation();

    return (
        <div className="promotion__leftbar">
            {Object.values(leftbarConfig).map(({ title, type }) => (
                <NavLink
                    to={`${routerBook.accessForSpecialist.promotion}/${type}`}
                    className={`promotion__leftbar-item ${type}`}
                    key={type}
                >
                    <span className="promotion__leftbar-item-circle" />
                    <span className="promotion__leftbar-item-title">
                        {t(title)}
                    </span>
                </NavLink>
            ))}
        </div>
    );
};

export default memo(PromotionLeftBar);
