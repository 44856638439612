import { get, destroy, patch, post, formDataHeaders } from "../api";

export const getChatList = async (params = "") => {
    const url = "/chats" + params;

    try {
        const { data } = await get(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const getChat = async (id) => {
    const url = `/chats/${id}`;

    try {
        const { data } = await get(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const postChat = async (reqBody = {}) => {
    const url = "/chats";

    try {
        const { data } = await post(url, reqBody);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const deleteChat = async (id) => {
    const url = `/chats/${id}`;

    try {
        const { data } = await destroy(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const disableChat = async (id) => {
    const url = `/chats/${id}/disable`;

    try {
        const { data } = await patch(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const postChatFileRequest = async (id, reqData = {}) => {
    const url = `/chats/${id}/upload`;

    try {
        const { data } = await post(url, reqData, formDataHeaders);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const getComplaintsTypes = async () => {
    const url = "/complaint/type/list";

    try {
        const { data } = await get(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const postComplaint = async (reqBody = {}) => {
    const url = "/complaint";

    try {
        const { data } = await post(url, reqBody);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};
