import { t } from "i18next";
import dayjs from "dayjs";

import { getLanguage } from "../../../../helpers/language";

export const convertDate = (date) => {
    const { value: language } = getLanguage();
    return dayjs(date).calendar(null, {
        sameDay:
            language === "en"
                ? "[Today] HH:mm"
                : `${t("calendar.TODAY")} HH:mm`,
        lastDay:
            language === "en"
                ? "[Yesterday] HH:mm"
                : `${t("calendar.YESTERDAY")} HH:mm`,
        lastWeek: "DD.MM.YYYY HH:mm",
        sameElse: "DD.MM.YYYY HH:mm",
    });
};

export const findSender = (participants, id) => {
    return participants.find(({ _id }) => _id !== id);
};
