import { faqTypes } from "./faq.types";
import { sizeItemOnPage } from "./faq.constants";

const initialQueryParams = { limit: sizeItemOnPage, page: 0 };

const initialState = {
    faq: {
        items: [],
        count: 0,
    },
    params: initialQueryParams,
    loading: false,
    error: "",
};

export const faq = (state = initialState, { type, payload }) => {
    switch (type) {
        case faqTypes.GET_ALL_FAQ_PENDING: {
            return { ...state, loading: true };
        }

        case faqTypes.GET_ALL_FAQ_FULFILLED: {
            return {
                ...state,
                faq: payload,
                loading: false,
            };
        }

        case faqTypes.GET_ALL_FAQ_REJECTED: {
            return { ...state, loading: false, error: payload };
        }

        case faqTypes.SET_PAGE:
            return { ...state, params: { ...state.params, page: payload } };

        case faqTypes.CLEAR_ALL_PARAMS:
            return {
                ...state,
                faq: initialState.faq,
                params: initialQueryParams,
            };
        default:
            return state;
    }
};

export default faq;
