export const allResultTypes = {
    // get orders
    GET_ORDERS_RESULT_PENDING: "all-result/GET_ORDERS_RESULT_PENDING",
    GET_ORDERS_RESULT_FULFILLED: "all-result/GET_ORDERS_RESULT_FULFILLED",
    GET_ORDERS_RESULT_REJECTED: "all-result/GET_ORDERS_RESULT_REJECTED",

    // get services
    GET_SERVICES_RESULT_PENDING: "all-result/GET_SERVICES_RESULT_PENDING",
    GET_SERVICES_RESULT_FULFILLED: "all-result/GET_SERVICES_RESULT_FULFILLED",
    GET_SERVICES_RESULT_REJECTED: "all-result/GET_SERVICES_RESULT_REJECTED",

    // set
    SET_CLARIFICATION: "all-result/SET_CLARIFICATION",
    CLEAR_ALL_PARAMS: "all-result/CLEAR_ALL_PARAMS",
    SET_SEARCH_QUERY: "all-result/SET_SEARCH_QUERY",
    SET_SUBCATEGORY: "all-result/SET_SUBCATEGORY",
    CLEAR_ALL_TAGS: "all-result/CLEAR_ALL_TAGS",
    SET_CATEGORY: "all-result/SET_CATEGORY",
    SET_LABEL: "all-result/SET_LABEL",
    SET_PAGE: "all-result/SET_PAGE",
    SET_CITY: "all-result/SET_CITY",
    SET_DISTRICT: "all-result/SET_DISTRICT",
};
