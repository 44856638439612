import * as yup from "yup";
import { t } from "i18next";
import {
    passwordValidation,
    isDigitValidation,
} from "../../../../../constants/input-validation";

export const emailLoginSchema = yup.object().shape({
    email: yup
        .string()
        .trim(true)
        .email("invalid_email")
        .required("validation_required"),
    password: yup
        .string()
        .trim(true)
        .required("validation_required")
        .min(8, ({ min }) => t("validation_min", { min }))
        .matches(passwordValidation, t("validation_password_required_symbols")),
});

export const phoneLoginSchema = yup.object().shape({
    phoneValue: yup
        .string()
        .trim(true)
        .required("validation_required")
        .matches(isDigitValidation, "only digits allowed")
        .min(7)
        .max(13),
    phoneCode: yup.string().required("validation_required"),
    password: yup
        .string()
        .trim(true)
        .required("validation_required")
        .min(8, ({ min }) => t("validation_min", { min }))
        .matches(passwordValidation, t("validation_password_required_symbols")),
});
