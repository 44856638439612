import { changeGlobalLoader } from "../../../components/loader/loader.actions";
import { specialistOrdersStatusSelector } from "./specialist-orders.selectors";

import * as api from "../../../services/requests/order";
import * as specialistOrdersActions from "./specialist-orders.actions";

export const getAllSpecialistOrders =
    (sizeItemsOnPage, params = "") =>
    (dispatch, getState) => {
        const status = specialistOrdersStatusSelector(getState());
        dispatch(specialistOrdersActions.getAllOrdersPending());
        dispatch(changeGlobalLoader(true));

        return api
            .getOrders(`?limit=${sizeItemsOnPage}` + status + params)
            .then(({ items, totalCount: count }) => {
                dispatch(
                    specialistOrdersActions.getAllOrdersFulfilled({
                        items,
                        count,
                    })
                );
            })
            .catch((error) => {
                dispatch(specialistOrdersActions.getAllOrdersRejected(error));
            })
            .finally(() => dispatch(changeGlobalLoader(false)));
    };

export const getSpecialistOrder = (id) => (dispatch) => {
    dispatch(specialistOrdersActions.getOrderPending());
    dispatch(changeGlobalLoader(true));

    return api
        .getOrder(id)
        .then((response) => {
            dispatch(specialistOrdersActions.getOrderFulfilled(response));
        })
        .catch((error) => {
            dispatch(specialistOrdersActions.getOrderRejected(error));
        })
        .finally(() => dispatch(changeGlobalLoader(false)));
};

export const editRequestStatusParameter =
    (sizeItemsOnPage, filterParameter) => (dispatch) => {
        dispatch(
            specialistOrdersActions.setStatus(
                `&statuses=${filterParameter.toUpperCase()}`
            )
        );
        dispatch(getAllSpecialistOrders(sizeItemsOnPage));
    };

export const editPageAll = (sizeItemsOnPage, pageCount) => (dispatch) => {
    dispatch(specialistOrdersActions.setPage(`&page=${pageCount}`));
    const url = `&page=${pageCount}`;
    dispatch(getAllSpecialistOrders(sizeItemsOnPage, url));
};
