import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

import Input from "../../../../../components/input/input";
import OauthLogin from "../../../oauth-login/oauth-login";
import { Icon } from "../../../../../components/image/image";

import routerBook from "../../../../../router/router-book";
import { emailLoginSchema, phoneLoginSchema } from "./login-form.schema";
import { authActiveButtons } from "../../../auth.constants";
import { sendLoginFormOperation } from "../../../auth.operations";

import CountryCodesDropdown from "../../../../../components/country-codes-dropdown/country-codes-dropdown";
import ButtonLoader from "../../../../../components/loader/components/button-loader/button-loader";
import { buttonLoaderSelector } from "../../../../../components/loader/loader.selectors";
import { generateBodyForAuthRequest } from "../../../auth.utils";
import { getItem } from "../../../../../helpers/local-storage";

import "../../../auth-forms.scss";

const LoginForm = () => {
    const [activeButton, setActiveButton] = useState(authActiveButtons.email);

    const {
        handleSubmit: handleSubmitEmail,
        register: registerEmail,
        formState: formStateEmail,
    } = useForm({
        mode: "all",
        resolver: yupResolver(emailLoginSchema),
    });

    const {
        handleSubmit: handleSubmitPhone,
        register: registerPhone,
        formState: formStatePhone,
        setValue: setValuePhone,
    } = useForm({
        mode: "all",
        resolver: yupResolver(phoneLoginSchema),
    });

    const { status: statusLoading } = useSelector(buttonLoaderSelector);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleEmailFormSubmit = (data) => {
        dispatch(
            sendLoginFormOperation(generateBodyForAuthRequest(data), navigate)
        );
    };

    const handlePhoneFormSubmit = (data) => {
        dispatch(
            sendLoginFormOperation(
                {
                    phone: data.phoneCode + data.phoneValue,
                    password: data.password,
                    deviceId: getItem("deviceID"),
                },
                navigate
            )
        );
    };

    return (
        <div className="auth_confirmation">
            <div className="auth_confirmation__info">
                <h2 className="auth_confirmation__title">
                    {t("welcome_message_title")}
                </h2>
                <p className="auth_confirmation__description">
                    {t("welcome_message_subtitle")}
                </p>
            </div>
            <div className="auth_confirmation__form">
                <div className="auth_confirmation__active_buttons">
                    <button
                        type="button"
                        onClick={() => setActiveButton(authActiveButtons.email)}
                        className={`${
                            activeButton === authActiveButtons.email && "active"
                        }`}
                    >
                        {t("email")}
                    </button>
                    <button
                        type="button"
                        onClick={() => setActiveButton(authActiveButtons.phone)}
                        className={`${
                            activeButton === authActiveButtons.phone && "active"
                        }`}
                    >
                        {t("number_phone")}
                    </button>
                </div>
                {activeButton === authActiveButtons.email ? (
                    <form onSubmit={handleSubmitEmail(handleEmailFormSubmit)}>
                        <div className="auth_confirmation__content">
                            <Input
                                label="enter_email"
                                type="secondary"
                                error={formStateEmail?.errors.email?.message}
                                options={{
                                    placeholderText: t("email"),
                                }}
                                inputChangeOptions={registerEmail("email")}
                            />
                            <Input
                                label="label_create_password"
                                type="password"
                                image={<Icon type="eyeHidden" />}
                                options={{
                                    placeholderText: "password",
                                }}
                                error={formStateEmail.errors.password?.message}
                                inputChangeOptions={registerEmail("password")}
                            />
                            <div className="auth_confirmation__question-tip">
                                <span
                                    onClick={() =>
                                        navigate(
                                            routerBook.auth.forgotPassword,
                                            {
                                                state: true,
                                            }
                                        )
                                    }
                                >
                                    {t("forgot_password")}?
                                </span>
                            </div>
                            <button
                                disabled={statusLoading}
                                type="submit"
                                className="auth_confirmation__btn"
                                style={{
                                    background: statusLoading && "#dfdfdf",
                                }}
                            >
                                {statusLoading ? (
                                    <ButtonLoader size={38} />
                                ) : (
                                    t("sing_in")
                                )}
                            </button>
                        </div>
                    </form>
                ) : (
                    <form onSubmit={handleSubmitPhone(handlePhoneFormSubmit)}>
                        <div className="auth_confirmation__content">
                            <div className="auth_confirmation__phone-form">
                                <CountryCodesDropdown
                                    register={registerPhone}
                                    setValue={setValuePhone}
                                    error={
                                        formStatePhone?.errors.phoneValue
                                            ?.message
                                    }
                                    phoneCodeError={
                                        formStatePhone?.errors.phoneCode
                                            ?.message
                                    }
                                    phoneValueName="phoneValue"
                                    phoneCodeName="phoneCode"
                                    withLabel
                                />
                            </div>
                            <Input
                                label="label_create_password"
                                type="password"
                                image={<Icon type="eyeHidden" />}
                                options={{
                                    placeholderText: "password",
                                }}
                                error={formStatePhone.errors.password?.message}
                                inputChangeOptions={registerPhone("password")}
                            />
                            <div className="auth_confirmation__question-tip">
                                <span
                                    onClick={() =>
                                        navigate(
                                            routerBook.auth.forgotPassword,
                                            {
                                                state: true,
                                            }
                                        )
                                    }
                                >
                                    {t("forgot_password")}?
                                </span>
                            </div>
                            <button
                                disabled={statusLoading}
                                type="submit"
                                className="auth_confirmation__btn"
                                style={{
                                    background: statusLoading && "#dfdfdf",
                                }}
                            >
                                {statusLoading ? (
                                    <ButtonLoader size={38} />
                                ) : (
                                    t("sing_in")
                                )}
                            </button>
                        </div>
                    </form>
                )}
                <OauthLogin />
            </div>
        </div>
    );
};

export default LoginForm;
