import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Fragment, useEffect } from "react";

import { getServices, getSubservices } from "./all-services.operations";
import { serviceSelector } from "./all-services.selectors";

import { errorHandlerTypes } from "../../components/error-handler/error-handler.constants";
import { backButtonConfig } from "../../components/back-button/back-button.config";
import ErrorHandler from "../../components/error-handler/error-handler";
import AutocompleteSearch from "../../components/autocomplete-search/autocomplete-search";
import ReactHelmet from "../../components/react-helmet/react-helmet";
import AllServicesItem from "./all-services-item/all-services-item";
import { Icon } from "../../components/image/image";

import "./index.scss";

const AllServices = () => {
    const { services, subservices } = useSelector(serviceSelector);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        dispatch(getSubservices());
        dispatch(getServices());
    }, []);

    return (
        <Fragment>
            <ReactHelmet title="all_service_title" />
            <div className="all_services">
                <div className="all_services__content">
                    <div className="all_services__header">
                        <button
                            onClick={() =>
                                navigate(
                                    backButtonConfig[location.state].href,
                                    { state: true }
                                )
                            }
                            className="all_services--back"
                        >
                            <Icon type="arrowLeftGreen" />
                            <p className="all_services--back_text">
                                {t(backButtonConfig[location.state].title)}
                            </p>
                        </button>
                        <h1 className="all_services__title">
                            {t("all_service_title")}
                        </h1>
                    </div>
                    <AutocompleteSearch buttonTitle="btn_search" />
                    {services.length > 0 ? (
                        <div className="all_services__inner">
                            {services.map((service) => (
                                <AllServicesItem
                                    subservices={subservices}
                                    key={service._id}
                                    {...service}
                                />
                            ))}
                        </div>
                    ) : (
                        <ErrorHandler
                            type={errorHandlerTypes.NOT_FOUND_SEARCH}
                        />
                    )}
                </div>
            </div>
        </Fragment>
    );
};

export default AllServices;
