import React from "react";

import ReactHelmet from "../../components/react-helmet/react-helmet";
import PromoVideo from "../../components/promo-video/promo-video";

import "./index.scss";

const SpecialistInfo = () => (
    <div className="specialist-info">
        <ReactHelmet title="nav_specialists" />
        <PromoVideo />
    </div>
);

export default SpecialistInfo;
