import { openModal } from "../../../../../../components/modal/modal.actions";
import { modalConstants } from "../../../../../../components/modal/modal.constants";
import { userRoles } from "../../../../../../constants/user-roles";
import {
    putTaskFixPrice,
    putTaskStatus,
} from "../../../../../orders/orders.operations";

import { accessTaskStatus } from "../../chat.config";

export const operationStatus = {
    COMPLETED_APPROVED: "completedApproved",
    IN_WORK_APPROVED: "inWorkApproved",
    PRICE_APPROVED: "priceApproved",
    PROPOSED_FIX_PRICE: "proposedFixPrice",
    CONFIRM_START_WORK: "confirmStartWork",
    CONFIRM_COMPLETE_WORK: "confirmCompleteWork",
    WORK_CANCELLED: "workCancelled",
};

export const byCreatorConfig = {
    [operationStatus.PROPOSED_FIX_PRICE]: {
        title: "chats_operations.propose_fix_price",
    },
    [operationStatus.CONFIRM_START_WORK]: {
        title: "chats_operations.propose_start_work",
    },
    [operationStatus.CONFIRM_COMPLETE_WORK]: {
        title: "chats_operations.propose_complete_task",
    },
};

export const byReceiverConfig = {
    [operationStatus.PROPOSED_FIX_PRICE]: {
        accessStatus: accessTaskStatus.negotiation,
        coupleOfButton: true,
        handleOnClick: (orderId, taskId, dispatch) =>
            dispatch(putTaskFixPrice(orderId, taskId, { eventType: "REFUSE" })),
        secondHandleOnClick: (orderId, taskId, dispatch, role) =>
            role === userRoles.customer
                ? dispatch(
                      putTaskFixPrice(orderId, taskId, { eventType: "APPROVE" })
                  )
                : dispatch(
                      openModal({
                          isOpen: true,
                          closeIcon: true,
                          renderType: modalConstants.agreeWithCommission,
                          details: {
                              orderId,
                              taskId,
                              type: operationStatus.PRICE_APPROVED,
                          },
                      })
                  ),
    },
    [operationStatus.CONFIRM_START_WORK]: {
        accessStatus: accessTaskStatus.priceIsFixed,
        coupleOfButton: false,
        operationType: "confirm",
        handleOnClick: (orderId, taskId, dispatch) =>
            dispatch(
                openModal({
                    isOpen: true,
                    closeIcon: true,
                    renderType: modalConstants.reverseFunds,
                    details: { orderId, taskId },
                })
            ),
    },
    [operationStatus.CONFIRM_COMPLETE_WORK]: {
        accessStatus: accessTaskStatus.inWork,
        coupleOfButton: false,
        operationType: "complete",
        handleOnClick: (orderId, taskId, dispatch) =>
            dispatch(putTaskStatus(orderId, taskId, "complete")),
    },
};

export const cancelledMessages = {
    priceRefused: "priceRefused",
    workCancelled: "workCancelled",
};
