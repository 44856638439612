import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import ButtonLoader from "../../../../components/loader/components/button-loader/button-loader";

import { disableChat } from "../../../chats/chats.operations";
import { deleteSupportQuestion } from "../../support.operations";
import { closeModal } from "../../../../components/modal/modal.actions";
import { buttonLoaderSelector } from "../../../../components/loader/loader.selectors";

import { deactivateTypes } from "./deactivate-question.constants";

import "./index.scss";

const DeactivateQuestion = ({
    details: { questionId, chatId, type = deactivateTypes.deactivateQuestion },
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { status: buttonStatus } = useSelector(buttonLoaderSelector);

    const handleDelete = () => {
        if (type === deactivateTypes.deactivateChat) {
            dispatch(disableChat(chatId, questionId));
        } else {
            dispatch(deleteSupportQuestion(questionId));
        }
    };

    const handleCloseModal = () => dispatch(closeModal());

    return (
        <div className="deactivate-question-modal">
            <div className="deactivate-question-modal__title">
                {type === deactivateTypes.deactivateQuestion
                    ? t("support.modal.delete-question")
                    : t("support.modal.deactivate-chat")}
            </div>
            <div className="deactivate-question-modal__btns">
                <button
                    className="deactivate-question-modal__btns-cancel"
                    onClick={handleCloseModal}
                >
                    {t("btn_cancel")}
                </button>
                <button
                    className={`deactivate-question-modal__btns-delete${
                        buttonStatus ? "-loading" : ""
                    }`}
                    onClick={handleDelete}
                    disabled={buttonStatus}
                >
                    {buttonStatus ? (
                        <ButtonLoader size={38} />
                    ) : (
                        t("btn_deactivate")
                    )}
                </button>
            </div>
        </div>
    );
};
DeactivateQuestion.propTypes = {
    details: PropTypes.object,
};
export default DeactivateQuestion;
