import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import {
    getAllComplaintsTypes,
    postComplaint,
} from "../../../../../../chats/chats.operations";
import { chatsSelector } from "../../../../../../chats/chats.selectors";

import { complaintSchema } from "./complaint.schema";
import { complainSteps } from "./complaint.constants";
import { getLanguage } from "../../../../../../../helpers/language";

import Textarea from "../../../../../../../components/input/textarea/textarea";

import "./index.scss";

const Complaint = ({ details }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [id, setID] = useState(null);
    const [fieldId, setFieldId] = useState(null);

    const { value: language } = getLanguage();
    const { complaintsTypes } = useSelector(chatsSelector);
    const [currentComplaintStep, setCurrentComplaintStep] = useState(
        complainSteps.first
    );

    const {
        formState: { errors },
        handleSubmit,
        register,
    } = useForm({
        mode: "all",
        resolver: yupResolver(
            currentComplaintStep === complainSteps.second
                ? complaintSchema
                : null
        ),
    });

    const handleFormSubmit = (data) => {
        if (currentComplaintStep === complainSteps.first) {
            if (!id) {
                setFieldId("validation_required");
                return null;
            }
            if (id) {
                setFieldId("");
                setCurrentComplaintStep(complainSteps.second);
            }
        } else {
            dispatch(
                postComplaint({
                    type: id,
                    text: data.text,
                    user: details.specialistId,
                })
            );
        }
    };

    useEffect(() => dispatch(getAllComplaintsTypes()), []);

    return (
        <div className="complain-modal">
            <form
                className="complain-modal__inner"
                onSubmit={handleSubmit(handleFormSubmit)}
            >
                <h1 className="complain-modal__inner-title">
                    {currentComplaintStep === complainSteps.first
                        ? t("complain-about-title")
                        : t("complain-complete-title")}
                </h1>
                {fieldId && (
                    <span className="complain-modal__inner--error">
                        {t(fieldId)}
                    </span>
                )}
                <div
                    className={`complain-modal__inner-quiz${
                        fieldId ? "--error" : ""
                    }`}
                >
                    {currentComplaintStep === complainSteps.first ? (
                        complaintsTypes.items &&
                        complaintsTypes.items.map(({ text, _id }) => (
                            <div
                                className="complain-modal__inner-quiz-item"
                                key={_id}
                            >
                                <div
                                    className={`complain-modal__inner-quiz-item-circle${
                                        id === _id ? "-active" : ""
                                    }`}
                                    onClick={() => setID(_id)}
                                >
                                    {id === _id && (
                                        <span className="complain-modal__inner-quiz-item-circle-active-inside" />
                                    )}
                                </div>
                                <span className="complain-modal__inner-quiz-item-title">
                                    {text[language]}
                                </span>
                            </div>
                        ))
                    ) : (
                        <Textarea
                            errors={t(errors?.text?.message)}
                            inputChangeOptions={register("text")}
                            placeholderText={t("complain-complete-subtitle")}
                            label={t("complain-complete-label")}
                        />
                    )}
                </div>

                <button type="submit" className="complain-modal__inner-btn">
                    {complaintsTypes === complainSteps.first
                        ? t("next")
                        : t("btn_send")}
                </button>
            </form>
        </div>
    );
};
Complaint.propTypes = {
    details: PropTypes.object,
};

export default Complaint;
