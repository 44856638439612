import { getLanguage } from "./language";

export const replaceValue = ({ isBold = false, value = {}, findText = "" }) => {
    const { textTranslations, text, values } = value;
    const { value: language } = getLanguage();
    const updatedValue = [];

    const currentValue = textTranslations
        ? textTranslations[language]
        : text || "";
    const splitValue = currentValue.split(findText || "%values");

    for (let i = 0; i < splitValue.length; i++) {
        updatedValue.push(splitValue[i]);
        if (i < splitValue.length - i) {
            updatedValue.push(isBold ? <b>{values[i]}</b> : values[i]);
        }
    }

    return updatedValue;
};
