import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";

import MainButton from "../../../../../components/main-button/main-button";
import { openModal } from "../../../../../components/modal/modal.actions";
import { modalConstants } from "../../../../../components/modal/modal.constants";
import { getLanguage } from "../../../../../helpers/language";
import routerBook from "../../../../../router/router-book";
import { ReactComponent as LiraIcon } from "../../../../../assets/images/currency/lira.svg";

import "./index.scss";

const PromotionServiceItem = ({
    promotion,
    activePromotions,
    primaryBtnTitle,
    secondaryBtnTitle,
    endDate,
    _id,
}) => {
    const { t } = useTranslation();
    const { value: language } = getLanguage();

    const navigate = useNavigate();
    const { filterParameter } = useParams();

    const dispatch = useDispatch();

    const handleBuyService = ({
        _id,
        nameTranslations,
        name,
        type,
        prices,
    }) => {
        dispatch(
            openModal({
                isOpen: true,
                closeIcon: false,
                renderType: modalConstants.promote,
                details: {
                    options: [{ nameTranslations, _id, name, type, prices }],
                },
            })
        );
    };

    const handleDeclinePromotion = () => {
        dispatch(
            openModal({
                isOpen: true,
                closeIcon: true,
                renderType: modalConstants.deactivatePromotion,
                details: { _id },
            })
        );
    };

    return (
        <div className="promotion__right-container-services-item">
            <div className="promotion__right-container-services-item-left">
                <span className="promotion__right-container-services-item-left-title">
                    {promotion?.nameTranslations[language]}
                </span>
                <span className="promotion__right-container-services-item-left-subtitle">
                    {promotion?.descriptionTranslations[language]}
                </span>
                {activePromotions ? (
                    <div className="promotion__right-container-services-item-left-acting">
                        <span className="promotion__right-container-services-item-left-acting-title">
                            {t("promotion.service.active-to")}{" "}
                            {dayjs(endDate).format("DD")}.
                            {dayjs(endDate).format("MM")}.
                            {dayjs(endDate).format("YYYY")}
                        </span>
                        <div className="promotion__right-container-services-item-left-acting-btns">
                            <MainButton
                                onClick={() =>
                                    handleBuyService({
                                        _id: promotion._id,
                                        nameTranslations:
                                            promotion?.nameTranslations,
                                        name: promotion?.name,
                                        type: promotion?.type,
                                        prices: promotion?.prices,
                                    })
                                }
                                buttonStyle="btn--green"
                                buttonSize="btn--medium"
                                type="submit"
                            >
                                {t(primaryBtnTitle)}
                            </MainButton>
                            <MainButton
                                buttonStyle="btn--light"
                                buttonSize="btn--medium"
                                type="submit"
                                onClick={(title) =>
                                    handleDeclinePromotion(title)
                                }
                            >
                                {t(secondaryBtnTitle)}
                            </MainButton>
                        </div>
                    </div>
                ) : (
                    <div className="promotion__right-container-services-item-left-price">
                        <MainButton
                            onClick={() =>
                                handleBuyService({
                                    _id: promotion._id,
                                    nameTranslations:
                                        promotion?.nameTranslations,
                                    name: promotion?.name,
                                    type: promotion?.type,
                                    prices: promotion?.prices,
                                })
                            }
                            buttonStyle="btn--green"
                            buttonSize="btn--medium"
                            type="submit"
                        >
                            {t(primaryBtnTitle)}
                        </MainButton>
                        <div className="promotion__right-container-services-item-left-price-inner">
                            <span className="promotion__right-container-services-item-left-price-inner-title">
                                {promotion?.prices?.month[0]?.price}
                            </span>

                            <LiraIcon className="promotion__right-container-services-item-left-price-icon" />
                        </div>
                        <MainButton
                            onClick={() =>
                                navigate(
                                    `${routerBook.accessForSpecialist.promotion}/${filterParameter}/${promotion._id}`,
                                    {
                                        state: true,
                                    }
                                )
                            }
                            buttonStyle="btn--light"
                            buttonSize="btn--medium"
                            type="submit"
                        >
                            {t("btn_find_out_more")}
                        </MainButton>
                    </div>
                )}
            </div>
            <div className="promotion__right-container-services-item-right">
                {promotion?.imageUrl && (
                    <img
                        className="promotion__right-container-services-item-right-img"
                        src={promotion.imageUrl}
                        alt="image"
                    />
                )}
            </div>
        </div>
    );
};

PromotionServiceItem.propTypes = {
    secondaryBtnTitle: PropTypes.string,
    primaryBtnTitle: PropTypes.string,
    activePromotions: PropTypes.bool,
    promotion: PropTypes.object,
    endDate: PropTypes.string,
    _id: PropTypes.string,
};

export default PromotionServiceItem;
