import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import AssessmentIcon from "./components/assessment-icon";

import "./index.scss";

const AssessmentPicker = ({ value, onChange }) => {
    const [clickState, setClickState] = useState([
        {
            id: "radio-1",
            inputNumber: 1,
            filled: false,
            checked: false,
        },
        {
            id: "radio-2",
            inputNumber: 2,
            filled: false,
            checked: false,
        },
        {
            id: "radio-3",
            inputNumber: 3,
            filled: false,
            checked: false,
        },
        {
            id: "radio-4",
            inputNumber: 4,
            filled: false,
            checked: false,
        },
        {
            id: "radio-5",
            inputNumber: 5,
            filled: false,
            checked: false,
        },
    ]);
    const [hoverState, setHoverState] = useState([
        {
            id: "radio-1",
            inputNumber: 1,
            filled: false,
            checked: false,
        },
        {
            id: "radio-2",
            inputNumber: 2,
            filled: false,
            checked: false,
        },
        {
            id: "radio-3",
            inputNumber: 3,
            filled: false,
            checked: false,
        },
        {
            id: "radio-4",
            inputNumber: 4,
            filled: false,
            checked: false,
        },
        {
            id: "radio-5",
            inputNumber: 5,
            filled: false,
            checked: false,
        },
    ]);

    useEffect(() => {
        handleOnMouseEnter(value);
        handleOnClick(value);
    }, [value]);

    const handleOnClick = (inputNumber) => {
        onChange(inputNumber);
        setClickState([
            ...clickState.map((i) => {
                if (i.inputNumber < inputNumber) {
                    return { ...i, filled: true, checked: false };
                } else if (i.inputNumber === inputNumber) {
                    return { ...i, filled: true, checked: true };
                } else {
                    return { ...i, filled: false, checked: false };
                }
            }),
        ]);
    };

    const handleOnMouseEnter = (inputNumber) => {
        setHoverState([
            ...clickState.map((i) => {
                if (i.inputNumber < inputNumber) {
                    return { ...i, filled: true, checked: false };
                } else if (i.inputNumber === inputNumber) {
                    return { ...i, filled: true, checked: true };
                } else {
                    return { ...i, filled: false, checked: false };
                }
            }),
        ]);
    };

    const handleOnMouseLeave = () => setHoverState([...clickState]);

    return (
        <div className="assessment-picker__container">
            {hoverState.map(({ checked, inputNumber, filled, id }) => {
                return (
                    <div
                        className={
                            checked === true
                                ? "assessment-picker__icon assessment-picker__icon_cheked"
                                : "assessment-picker__icon"
                        }
                        onMouseEnter={() => handleOnMouseEnter(inputNumber)}
                        onMouseLeave={handleOnMouseLeave}
                        key={id}
                    >
                        <label
                            className="assessment-picker__label"
                            htmlFor={id}
                        >
                            <AssessmentIcon fill={filled} />
                        </label>
                        <input
                            onClick={() => handleOnClick(inputNumber)}
                            className="assessment-picker__input"
                            value={inputNumber}
                            type="radio"
                            id={id}
                        />
                    </div>
                );
            })}
        </div>
    );
};

AssessmentPicker.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.number,
};

export default AssessmentPicker;
