import PropTypes from "prop-types";
import { useState } from "react";

import "./index.scss";

const Accordion = ({ children, icon, inlineStyles }) => {
    const [isOpen, setIsOpen] = useState(false);

    const classNames = (state) => {
        return state
            ? "accordion__btn accordion__btn-active"
            : "accordion__btn";
    };

    const clickHandler = () => setIsOpen(!isOpen);

    return (
        <div className="accordion" style={{ ...inlineStyles }}>
            <div className="accordion__content">
                {!isOpen ? children[0] : children}
            </div>
            <div className="accordion__btn-container">
                <button className={classNames(isOpen)} onClick={clickHandler}>
                    {icon}
                </button>
            </div>
        </div>
    );
};

Accordion.propTypes = {
    children: PropTypes.arrayOf(PropTypes.node),
    icon: PropTypes.node,
    inlineStyles: PropTypes.object,
};

export default Accordion;
