import { useTranslation } from "react-i18next";
import calendar from "dayjs/plugin/calendar";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import React from "react";

import { myAccountDataSelector } from "../../../my-account/my-account.selectors";
import { websocketsSelector } from "../../../../components/websockets/websockets.selectors";

import { typeOfLazeImage } from "../../../../components/lazy-image/lazyImage.constansts";
import { showLastMessage } from "../../../../helpers/show-last-message";
import { convertDate, findSender } from "./chats-content-item.utils";
import LazyImage from "../../../../components/lazy-image/lazy-image";
import { getPhotoByTypeGender } from "../../../../helpers/get-photo-by-type-gender";
import routerBook from "../../../../router/router-book";

import "./index.scss";

const ChatsContentItem = ({
    unreadMessages,
    participants,
    _id: chatId,
    updatedAt,
    messages = {},
}) => {
    const { onlineParticipants } = useSelector(websocketsSelector);
    const { _id: userId } = useSelector(myAccountDataSelector);
    const { t } = useTranslation();
    const navigate = useNavigate();
    dayjs.extend(calendar);

    const participant = findSender(participants, userId);
    const isOnline = onlineParticipants.includes(participant?._id);

    return (
        <div className="chats__content-item">
            <div
                title={isOnline ? t("online") : t("offline")}
                className="chats__content-item--avatar"
            >
                <LazyImage
                    image={getPhotoByTypeGender(
                        participant?.avatar,
                        participant?.gender
                    )}
                    type={typeOfLazeImage.avatar}
                    options={{
                        className: "chats__content-item--avatar-img",
                        alt: "avatar",
                    }}
                />
                {isOnline && (
                    <span className="chats__content-item--avatar-status" />
                )}
            </div>
            <div className="chats__content-item--info">
                <div className="chats__content-item--info-header">
                    <span className="chats__content-item--info-header-name">
                        {participant?.firstName || t("chats.deleted_user")}{" "}
                        {participant?.lastName}
                    </span>
                    <span className="chats__content-item--info-header-date">
                        {convertDate(updatedAt)}
                    </span>
                    {unreadMessages > 0 && (
                        <span
                            title={`${unreadMessages} ${t(
                                "chats.unread_messages"
                            )}`}
                            className="chats__content-item--info-header-messages"
                        >
                            {unreadMessages}
                        </span>
                    )}
                </div>
                <div className="chats__content-item--info-body">
                    {chatId
                        ? showLastMessage(messages)
                        : t("chats.no_messages_yet")}
                </div>
                <button
                    onClick={() =>
                        navigate(`${routerBook.chats}/${chatId}`, {
                            state: true,
                        })
                    }
                    className="chats__content-item--info-button"
                >
                    {t("chats.go_to_chat")}
                </button>
            </div>
        </div>
    );
};

ChatsContentItem.propTypes = {
    messages: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    unreadMessages: PropTypes.number,
    participants: PropTypes.array,
    updatedAt: PropTypes.string,
    _id: PropTypes.string,
};

export default ChatsContentItem;
