import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Fragment, useEffect } from "react";

import { specialistListSelector } from "../specialists-list/specialist-list.selectors";
import { myAccountDataSelector } from "../my-account/my-account.selectors";
import { ordersSelector } from "../orders/orders.selectors";
import { chatsSelector } from "../chats/chats.selectors";
import { getChat } from "../chats/chats.operations";
import Actions from "./components/actions/actions";

import { backButtonConfig } from "../../components/back-button/back-button.config";
import ReactHelmet from "../../components/react-helmet/react-helmet";
import BackButton from "../../components/back-button/back-button";
import Suggestions from "./components/suggestions/suggestions";
import { userRoles } from "../../constants/user-roles";
import Profile from "./components/profile/profile";
import Chat from "./components/chat/chat";

import "./index.scss";

const DiscussionWorkProject = () => {
    const { selectedSpecialist } = useSelector(specialistListSelector);
    const { orderTaskOtherSpecialist } = useSelector(ordersSelector);
    const { role } = useSelector(myAccountDataSelector);
    const {
        chat: { subcategoryTitle, operationsHistory },
    } = useSelector(chatsSelector);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { id } = useParams();

    useEffect(() => {
        dispatch(getChat(id, role));
    }, []);

    return (
        <Fragment>
            <ReactHelmet title="chats_item.title" />
            <div className="discussion_work_project">
                <div className="discussion_work_project__header">
                    <div className="discussion_work_project__header_btn">
                        <BackButton
                            onClickCallback={() =>
                                navigate(
                                    backButtonConfig[location.state].href,
                                    { state: true }
                                )
                            }
                            title={t(backButtonConfig[location.state].title)}
                        />
                    </div>
                    <div className="discussion_work_project__header_main">
                        <h1 className="discussion_work_project__header_main_title">
                            {t("chats_item.title")}
                        </h1>
                        <h5 className="discussion_work_project__header_main_subtitle">
                            {t("chats_item.order")}{" "}
                            {subcategoryTitle || t("not_selected")}
                        </h5>
                    </div>
                </div>
                <div className="discussion_work_project__body">
                    <aside className="discussion_work_project__body_profile">
                        {role === userRoles.customer ? (
                            <Profile {...selectedSpecialist} />
                        ) : (
                            <Actions operationsHistory={operationsHistory} />
                        )}
                        <Chat />
                    </aside>
                    <section className="discussion_work_project__body_main">
                        {role === userRoles.customer && (
                            <Actions operationsHistory={operationsHistory} />
                        )}
                        {role === userRoles.customer &&
                            orderTaskOtherSpecialist &&
                            orderTaskOtherSpecialist.length > 0 && (
                                <Suggestions
                                    orderTaskOtherSpecialist={
                                        orderTaskOtherSpecialist
                                    }
                                />
                            )}
                    </section>
                </div>
            </div>
        </Fragment>
    );
};

export default DiscussionWorkProject;
