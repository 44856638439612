/* eslint-disable react/prop-types */
import React, { Component } from "react";

import { errorHandlerTypes } from "../error-handler/error-handler.constants";
import ErrorHandler from "../error-handler/error-handler";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({ error, errorInfo });
    }

    render() {
        const { errorInfo } = this.state;
        const { children } = this.props;

        if (errorInfo) {
            return <ErrorHandler type={errorHandlerTypes.SOMETHING_WRONG} />;
        }

        return children;
    }
}

export default ErrorBoundary;
