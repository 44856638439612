export const promotionTypes = {
    // get promotion
    GET_PROMOTION_PENDING: "promotion/GET_PROMOTION_PENDING",
    GET_PROMOTION_FULFILLED: "promotion/GET_PROMOTION_FULFILLED",
    GET_PROMOTION_REJECTED: "promotion/GET_PROMOTION_REJECTED",

    // get promotion by id
    GET_PROMOTION_BY_ID_PENDING: "promotion/GET_PROMOTION_BY_ID_PENDING",
    GET_PROMOTION_BY_ID_FULFILLED: "promotion/GET_PROMOTION_BY_ID_FULFILLED",
    GET_PROMOTION_BY_ID_REJECTED: "promotion/GET_PROMOTION_BY_ID_REJECTED",

    // add promotion
    ADD_PROMOTION: "promotion/ADD_PROMOTION",

    // buy promotion
    BUY_PROMOTION_PENDING: "promotion/BUY_PROMOTION_PENDING",
    BUY_PROMOTION_FULFILLED: "promotion/BUY_PROMOTION_FULFILLED",
    BUY_PROMOTION_REJECTED: "promotion/BUY_PROMOTION_REJECTED",

    // get promotion history
    GET_PROMOTION_HISTORY_PENDING: "promotion/GET_PROMOTION_HISTORY_PENDING",
    GET_PROMOTION_HISTORY_FULFILLED:
        "promotion/GET_PROMOTION_HISTORY_FULFILLED",
    GET_PROMOTION_HISTORY_REJECTED: "promotion/GET_PROMOTION_HISTORY_REJECTED",

    // deactivate promotion
    DEACTIVATE_PROMOTION_PENDING: "promotion/DEACTIVATE_PROMOTION_PENDING",
    DEACTIVATE_PROMOTION_FULFILLED: "promotion/DEACTIVATE_PROMOTION_FULFILLED",
    DEACTIVATE_PROMOTION_REJECTED: "promotion/DEACTIVATE_PROMOTION_REJECTED",

    // set
    CLEAR_ALL_PARAMS: "promotion/CLEAR_ALL_PARAMS",
    SET_SEARCH_QUERY: "promotion/SET_SEARCH_QUERY",
    SET_PAGE: "promotion/SET_PAGE",
    SET_ACTIVE_PROMOTIONS_PAGE: "promotion/SET_ACTIVE_PROMOTIONS_PAGE",
    SET_HISTORY_PAGE: "promotion/SET_HISTORY_PAGE",
};
