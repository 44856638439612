import { post, get, put, destroy } from "../api";

// Create feedback for specialist after completed order
export const createFeedbackRequest = async (
    taskId,
    reqBody = { text: "", assessment: 0 }
) => {
    const url = `/feedback/task/${taskId}`;

    try {
        const { data } = await post(url, reqBody);

        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;

        throw { errorData, status };
    }
};

// Get all feedbacks for specialist
export const getAllUserFeedbacksRequest = async (specialistID) => {
    const url = `/feedback/${specialistID}`;

    try {
        const { data } = await get(url);

        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;

        throw { errorData, status };
    }
};

// Delete feedback
export const deleteFeedbackRequest = async (feedbackID) => {
    const url = `/feedback/${feedbackID}`;

    try {
        const { data } = await destroy(url);

        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;

        throw { errorData, status };
    }
};

// Update feedback
export const updateFeedbackRequest = async (
    feedbackID,
    reqBody = { text: "", assessment: 0 }
) => {
    const url = `/feedback/${feedbackID}`;

    try {
        const { data } = await put(url, reqBody);

        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;

        throw { errorData, status };
    }
};
