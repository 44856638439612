import PropTypes from "prop-types";

import "./index.scss";

const ImageZoomer = ({ details: { url } }) => {
    return (
        <div className="image-zoomer">
            <img src={url} />
        </div>
    );
};

ImageZoomer.propTypes = { details: PropTypes.object };

export default ImageZoomer;
