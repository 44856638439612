import { ReactComponent as NoInternetConnection } from "../../assets/images/error-pages/no_internet_connection.svg";
import somethingWentWrong from "../../assets/images/error-pages/something_went_wrong.svg";
import notFoundSearch from "../../assets/images/error-pages/not_found_search.svg";
import inDevelopment from "../../assets/images/error-pages/in_developement.svg";
import pageNotFound from "../../assets/images/error-pages/page_not_found.svg";
import accessDenied from "../../assets/images/error-pages/access_denied.svg";
import blockedUser from "../../assets/images/error-pages/blocked_user.svg";

import routerBook from "../../router/router-book";

export const options = {
    PAGE_NOT_FOUND: {
        image: pageNotFound,
        title: "error_handler_page_not_found_title",
        description: "error_handler_page_not_found_description",
        buttonText: "error_handler_page_not_found_button_text",
        onClickActions: routerBook.home,
    },
    NO_INTERNET: {
        image: <NoInternetConnection />,
        title: "error_handler_no_internet_title",
        description: "error_handler_no_internet_description",
        noShowButton: true,
    },
    SOMETHING_WRONG: {
        image: somethingWentWrong,
        title: "error_handler_something_wrong_title",
        description: "error_handler_something_wrong_description",
        buttonText: "error_handler_something_wrong_button_text",
        onClickActions: routerBook.home,
    },
    ACCESS_DENIED: {
        image: accessDenied,
        title: "error_handler_access_denied_title",
        description: "error_handler_access_denied_description",
        buttonText: "error_handler_access_denied_button_text",
        onClickActions: routerBook.home,
    },
    NOT_FOUND_SEARCH: {
        image: notFoundSearch,
        title: "error_handler_not_found_search_title",
        description: "error_handler_not_found_search_description",
        buttonText: "error_handler_not_found_search_button_text",
        onClickActions: 0,
    },
    EMPTY_FAVORITES: {
        image: notFoundSearch,
        title: "error_handler_empty_favorites_title",
        description: "error_handler_empty_favorites_description",
        buttonText: "error_handler_empty_favorites_button_text",
        onClickActions: routerBook.allServicesResult,
    },
    IN_DEVELOPMENT: {
        image: inDevelopment,
        title: "error_handler_in_development_title",
        description: "error_handler_in_development_description",
        buttonText: "error_handler_in_development_button_text",
        onClickActions: routerBook.home,
    },
    BLOCKED_USER: {
        image: blockedUser,
        title: "error_handler_blocked_user_title",
        description: "error_handler_blocked_user_description",
        subDescription: "error_handler_blocked_user_button_subdescription",
        noShowButton: true,
        onClickRedirect: "support@wconnect.work",
    },
};
