import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import React, { Fragment, useState } from "react";

import {
    sendEmailVerifyDataOperation,
    sendPhoneVerifyDataOperation,
} from "../auth.operations";
import {
    setRegisteredEmailAction,
    setRegisteredPhoneAction,
} from "../auth.actions";
import { authSelector } from "../auth.selectors";
import {
    verificationEmail,
    verificationPhone,
} from "./email-confirmation.schema";
import { authActiveButtons } from "../auth.constants";
import OauthLogin from "../oauth-login/oauth-login";

import { buttonLoaderSelector } from "../../../components/loader/loader.selectors";
import ButtonLoader from "../../../components/loader/components/button-loader/button-loader";
import ReactHelmet from "../../../components/react-helmet/react-helmet";
import Input from "../../../components/input/input";
import CountryCodesDropdown from "../../../components/country-codes-dropdown/country-codes-dropdown";

import "../auth-forms.scss";

const EmailConfirmation = () => {
    const [activeButton, setActiveButton] = useState(authActiveButtons.email);

    const { status: buttonLoading } = useSelector(buttonLoaderSelector);
    const { role: userRole } = useSelector(authSelector);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {
        register: registerEmail,
        handleSubmit: handleSubmitEmail,
        formState: formStateEmail,
    } = useForm({
        mode: "all",
        resolver: yupResolver(verificationEmail),
    });

    const {
        register: registerPhone,
        handleSubmit: handleSubmitPhone,
        formState: formStatePhone,
        setValue: setValuePhone,
    } = useForm({
        mode: "all",
        resolver: yupResolver(verificationPhone),
    });

    const handleEmailFormSubmit = (data) => {
        dispatch(setRegisteredEmailAction(data.email));

        dispatch(
            sendEmailVerifyDataOperation(
                { email: data.email, userRole },
                navigate
            )
        );
    };

    const handlePhoneFormSubmit = (data) => {
        dispatch(setRegisteredPhoneAction(data.phoneCode + data.phoneValue));

        dispatch(
            sendPhoneVerifyDataOperation(
                {
                    phone: data.phoneValue,
                    dialCode: data.phoneCode,
                    userRole,
                },
                navigate
            )
        );
    };

    return (
        <Fragment>
            <ReactHelmet title="enter_email" />
            <div className="auth_confirmation">
                <div className="auth_confirmation__info">
                    <h2 className="auth_confirmation__title">
                        {t("registration")}
                    </h2>
                    <p className="auth_confirmation__description">
                        {t("welcome_message_registration")}
                    </p>
                </div>
                <div className="auth_confirmation__form">
                    <div className="auth_confirmation__active_buttons">
                        <button
                            type="button"
                            onClick={() =>
                                setActiveButton(authActiveButtons.email)
                            }
                            className={`${
                                activeButton === authActiveButtons.email &&
                                "active"
                            }`}
                        >
                            {t("email")}
                        </button>
                        <button
                            type="button"
                            onClick={() =>
                                setActiveButton(authActiveButtons.phone)
                            }
                            className={`${
                                activeButton === authActiveButtons.phone &&
                                "active"
                            }`}
                        >
                            {t("number_phone")}
                        </button>
                    </div>
                    {activeButton === authActiveButtons.email ? (
                        <form
                            onSubmit={handleSubmitEmail(handleEmailFormSubmit)}
                        >
                            <div className="auth_confirmation__content">
                                <Input
                                    label="enter_email"
                                    type="secondary"
                                    error={
                                        formStateEmail?.errors.email?.message
                                    }
                                    options={{
                                        placeholderText: t("email"),
                                    }}
                                    inputChangeOptions={registerEmail("email")}
                                />
                                <button
                                    disabled={buttonLoading}
                                    type="submit"
                                    className="auth_confirmation__btn"
                                    style={{
                                        background: buttonLoading && "#dfdfdf",
                                    }}
                                >
                                    {buttonLoading ? (
                                        <ButtonLoader size={38} />
                                    ) : (
                                        t("next")
                                    )}
                                </button>
                            </div>
                        </form>
                    ) : (
                        <form
                            onSubmit={handleSubmitPhone(handlePhoneFormSubmit)}
                        >
                            <div className="auth_confirmation__content">
                                <div className="auth_confirmation__phone-form">
                                    <CountryCodesDropdown
                                        register={registerPhone}
                                        setValue={setValuePhone}
                                        phoneValueName="phoneValue"
                                        phoneCodeName="phoneCode"
                                        error={
                                            formStatePhone?.errors.phoneValue
                                                ?.message
                                        }
                                        phoneCodeError={
                                            formStatePhone?.errors.phoneCode
                                                ?.message
                                        }
                                        withLabel
                                    />
                                </div>
                                <button
                                    disabled={buttonLoading}
                                    type="submit"
                                    className="auth_confirmation__btn"
                                    style={{
                                        background: buttonLoading && "#dfdfdf",
                                    }}
                                >
                                    {buttonLoading ? (
                                        <ButtonLoader size={38} />
                                    ) : (
                                        t("next")
                                    )}
                                </button>
                            </div>
                        </form>
                    )}
                    <OauthLogin />
                </div>
            </div>
        </Fragment>
    );
};

export default EmailConfirmation;
