import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import React from "react";

import { buttonLoaderSelector } from "../../../../components/loader/loader.selectors";
import { closeModal } from "../../../../components/modal/modal.actions";
import { createIyzico } from "../../balance.operations";

import { handleConvertNumberValue } from "./balance-onboarding-iyzico-modal.utils";
import ButtonLoader from "../../../../components/loader/components/button-loader/button-loader";
import { paymentTypes } from "../../balance.constants";
import { balanceOnboardingIyzicoModalConfig } from "./balance-onboarding-iyzico-modal.config";
import { balanceOnboardingIyzicoModalSchema } from "./balance-onboarding-iyzico-modal.schema";

import "./index.scss";

const BalanceOnboardingIyzicoModal = () => {
    const { status: buttonStatus, button: buttonLoading } =
        useSelector(buttonLoaderSelector);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const {
        formState: { errors, isValid },
        handleSubmit,
        reset,
        control,
    } = useForm({
        mode: "all",
        defaultValues: {},
        resolver: yupResolver(balanceOnboardingIyzicoModalSchema),
    });

    const handleFormSubmit = (data) => {
        dispatch(
            createIyzico({
                ...data,
                iban: `TR${data.iban}`,
                callbackUrl: location.href,
            })
        );
        reset(data);
    };

    const handleFormCancel = () => {
        dispatch(closeModal());
        reset({});
    };

    const mapFormConfig = () => {
        return balanceOnboardingIyzicoModalConfig.map(
            ({ label, placeholderText, registerName, regex }) => (
                <Controller
                    name={registerName}
                    key={registerName}
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <div className="form-container">
                            <div className="form-container__info">
                                <label className="form-container__label">
                                    {t(label)}
                                </label>
                                <label className="form-container__error">
                                    {errors[registerName]?.message
                                        ? t(errors[registerName]?.message)
                                        : ""}
                                </label>
                            </div>
                            <input
                                className={`form-container__register_input${
                                    errors[registerName]?.message
                                        ? "--error"
                                        : ""
                                }`}
                                placeholder={t(placeholderText)}
                                onChange={(val) =>
                                    onChange(
                                        regex
                                            ? handleConvertNumberValue(val)
                                            : val
                                    )
                                }
                                onBlur={onBlur}
                                value={value}
                                type="text"
                            />
                        </div>
                    )}
                />
            )
        );
    };

    const loading = buttonStatus && buttonLoading === paymentTypes.iyzico.value;

    return (
        <div className="balance-onboarding-iyzico">
            <form
                className="balance-onboarding-iyzico__form"
                onSubmit={handleSubmit(handleFormSubmit)}
            >
                <div className="balance-onboarding-iyzico__form-content">
                    {mapFormConfig()}
                </div>
                <div className="balance-onboarding-iyzico__form-btns">
                    <button
                        className="balance-onboarding-iyzico__form-btns-cancel"
                        onClick={handleFormCancel}
                        type="button"
                    >
                        {t("btn_cancel")}
                    </button>
                    <button
                        className="balance-onboarding-iyzico__form-btns-save"
                        style={{ background: loading && "#dfdfdf" }}
                        disabled={!isValid || buttonStatus}
                        type="submit"
                    >
                        {loading ? <ButtonLoader size={24} /> : t("btn_save")}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default BalanceOnboardingIyzicoModal;
