export const forgotPasswordSteps = { request: "request", confirm: "confirm" };

export const authActiveButtons = {
    email: "emailButton",
    phone: "phoneButton",
};

export const oauthStep = {
    login: "ALLOW_USE_APP",
    registration: "REGISTRATION_BY_OAUTH",
};
