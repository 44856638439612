import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { supportTypes } from "../../support.constants";
import { editSort } from "../../support.operations";
import { supportPageSelector } from "../../support.selectors";

import "./index.scss";

const SupportSort = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const page = useSelector(supportPageSelector);
    return (
        <div className="support-sort">
            <label className="support-sort__label">
                {t("support.sort.sort-by")}
            </label>
            <select
                defaultValue={""}
                onChange={({ target: { value } }) => {
                    dispatch(editSort(value, page));
                }}
                className="support-sort__select"
            >
                {supportTypes.map(({ key, label }) => (
                    <option key={label} value={key}>
                        {t(`support.sort.${label}`)}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default SupportSort;
