export const specialistServicesTypes = {
    // get all specialist-services
    GET_SPECIALIST_SERVICES_PENDING:
        "specialist-services/GET_SPECIALIST_SERVICES_PENDING",
    GET_SPECIALIST_SERVICES_FULFILLED:
        "specialist-services/GET_SPECIALIST_SERVICES_FULFILLED",
    GET_SPECIALIST_SERVICES_REJECTED:
        "specialist-services/GET_SPECIALIST_SERVICES_REJECTED",

    // get specialist-service
    GET_SPECIALIST_SERVICE_PENDING:
        "specialist-services/GET_SPECIALIST_SERVICE_PENDING",
    GET_SPECIALIST_SERVICE_FULFILLED:
        "specialist-services/GET_SPECIALIST_SERVICE_FULFILLED",
    GET_SPECIALIST_SERVICE_REJECTED:
        "specialist-services/GET_SPECIALIST_SERVICE_REJECTED",

    // post specialist-service
    POST_SPECIALIST_SERVICE_PENDING:
        "specialist-services/POST_SPECIALIST_SERVICE_PENDING",
    POST_SPECIALIST_SERVICE_FULFILLED:
        "specialist-services/POST_SPECIALIST_SERVICE_FULFILLED",
    POST_SPECIALIST_SERVICE_REJECTED:
        "specialist-services/POST_SPECIALIST_SERVICE_REJECTED",

    // post specialist-service image
    POST_SPECIALIST_SERVICE_IMAGE_FULFILLED:
        "specialist-services/POST_SPECIALIST_SERVICE_IMAGE_FULFILLED",
    POST_SPECIALIST_SERVICE_IMAGE_REJECTED:
        "specialist-services/POST_SPECIALIST_SERVICE_IMAGE_REJECTED",

    // update specialist-service
    UPDATE_SPECIALIST_SERVICE_PENDING:
        "specialist-services/UPDATE_SPECIALIST_SERVICE_PENDING",
    UPDATE_SPECIALIST_SERVICE_FULFILLED:
        "specialist-services/UPDATE_SPECIALIST_SERVICE_FULFILLED",
    UPDATE_SPECIALIST_SERVICE_REJECTED:
        "specialist-services/UPDATE_SPECIALIST_SERVICE_REJECTED",

    // remove specialist-services
    REMOVE_SPECIALIST_SERVICE_PENDING:
        "specialist-services/REMOVE_SPECIALIST_SERVICE_PENDING",
    REMOVE_SPECIALIST_SERVICE_FULFILLED:
        "specialist-services/REMOVE_SPECIALIST_SERVICE_FULFILLED",
    REMOVE_SPECIALIST_SERVICE_REJECTED:
        "specialist-services/REMOVE_SPECIALIST_SERVICE_REJECTED",

    // remove specialist-services image
    REMOVE_SPECIALIST_SERVICE_IMAGE_PENDING:
        "specialist-services/REMOVE_SPECIALIST_SERVICE_IMAGE_PENDING",
    REMOVE_SPECIALIST_SERVICE_IMAGE_FULFILLED:
        "specialist-services/REMOVE_SPECIALIST_SERVICE_IMAGE_FULFILLED",
    REMOVE_SPECIALIST_SERVICE_IMAGE_REJECTED:
        "specialist-services/REMOVE_SPECIALIST_SERVICE_IMAGE_REJECTED",
};
