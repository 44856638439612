import { countryCodesTypes } from "./country-codes.types";

export const getCountryCodesRequest = () => ({
    type: countryCodesTypes.GET_COUNTRY_CODES_REQUEST,
});

export const getCountryCodesSuccess = (payload) => ({
    type: countryCodesTypes.GET_COUNTRY_CODES_SUCCESS,
    payload,
});

export const getCountryCodesError = (payload) => ({
    type: countryCodesTypes.GET_COUNTRY_CODES_ERROR,
    payload,
});

export const setCountryCode = (payload) => ({
    type: countryCodesTypes.SET_COUNTRY_CODE,
    payload,
});
