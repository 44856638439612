import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import CurrencyInput from "react-currency-input-field";

import { balanceReplenishSchema } from "./balance-replenish-modal.schema";
import {
    initializeSessionPayOperation,
    withdrawalMoneyOperation,
} from "../../balance.operations";
import { paymentTabs, paymentOperationTypes } from "../../balance.constants";
import { parseMoneyAmount } from "../../balance.utils";

import { closeModal } from "../../../../components/modal/modal.actions";
import MainButton from "../../../../components/main-button/main-button";

import { ReactComponent as IparaIcon } from "../../../../assets/images/currency/ipara.svg";
import { ReactComponent as IyzikoIcon } from "../../../../assets/images/currency/iyzico.svg";

import "./index.scss";

const BalanceReplenishModal = ({ details }) => {
    const { activeTab, type } = details;

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const {
        formState: { errors },
        handleSubmit,
        control,
    } = useForm({
        resolver: yupResolver(balanceReplenishSchema),
    });

    const submitHandler = (data) => {
        const value = parseMoneyAmount(data.moneyAmount);

        if (type === paymentOperationTypes.deposit) {
            dispatch(
                initializeSessionPayOperation({
                    money: value,
                    account: "test",
                    callbackUrl: "http://localhost:3000",
                })
            );
        }

        if (type === paymentOperationTypes.withdrawal) {
            dispatch(
                withdrawalMoneyOperation({
                    money: value,
                    account: "test",
                })
            );
        }

        dispatch(closeModal());
    };

    const renderPaymentIcon = () => {
        if (activeTab === paymentTabs.iyzico) {
            return <IyzikoIcon />;
        }

        if (activeTab === paymentTabs.ipara) {
            return <IparaIcon />;
        }
    };

    return (
        <div className="balance-replenish-modal">
            <p className="balance-replenish-modal__title">
                {type === paymentOperationTypes.withdrawal
                    ? t("balance-new.withdrawal")
                    : t("balance-new.balance-recharge")}
            </p>
            <div className="balance-replenish-modal__ipara-icon">
                {renderPaymentIcon()}
            </div>
            <div className="balance-replenish-modal__form">
                <form onSubmit={handleSubmit(submitHandler)}>
                    <div className="balance-replenish-modal__form-label">
                        <label className="balance-replenish-modal__form-label-text">
                            {t("balance-new.deposit-amount-label")}
                        </label>
                        <label className="balance-replenish-modal__form-label-error">
                            {errors.moneyAmount?.message
                                ? t(errors.moneyAmount?.message)
                                : ""}
                        </label>
                    </div>
                    <Controller
                        name="moneyAmount"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <CurrencyInput
                                id="input-example"
                                placeholder={t(
                                    "balance-new.deposit-amount-placeholder"
                                )}
                                allowNegativeValue={false}
                                value={value}
                                decimalsLimit={2}
                                onValueChange={onChange}
                                decimalSeparator=","
                                groupSeparator="."
                                suffix=" ₺"
                                disableAbbreviations={true}
                                className={`balance-replenish-modal__form-input ${
                                    errors.moneyAmount?.message &&
                                    "balance-replenish-modal__form-input--error"
                                }`}
                            />
                        )}
                    />
                    <div className="balance-replenish-modal__btn-wrapper">
                        <MainButton buttonSize="btn--stretch" type="submit">
                            {type === paymentOperationTypes.withdrawal
                                ? t("balance-new.withdraw")
                                : t("balance-refill-title")}
                        </MainButton>
                    </div>
                </form>
            </div>
        </div>
    );
};

BalanceReplenishModal.propTypes = {
    details: PropTypes.shape({
        activeTab: PropTypes.string,
        type: PropTypes.string,
    }),
};

export default BalanceReplenishModal;
