import PropTypes from "prop-types";

import defaultImg from "../../../../assets/images/custom-person-icon/male/manYellow.svg";

import "./index.scss";

const MyAccountInfoInitialImage = ({ defaultImage }) => {
    return (
        <div className="profile-initial-image">
            <label htmlFor="contained-button-file">
                <div className="profile-initial-image-content">
                    <img
                        className="profile-initial-image-content-file"
                        src={defaultImage}
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = defaultImg;
                        }}
                    />
                </div>
            </label>
        </div>
    );
};

MyAccountInfoInitialImage.propTypes = {
    defaultImage: PropTypes.any,
};
export default MyAccountInfoInitialImage;
