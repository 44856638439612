const ageToString = (age) => {
    let ageModifier;
    let count = age % 100;
    if (count >= 5 && count <= 20) {
        ageModifier = "year_3";
    } else {
        count = count % 10;
        if (count == 1) {
            ageModifier = "year_1";
        } else if (count >= 2 && count <= 4) {
            ageModifier = "year_2";
        } else {
            ageModifier = "year_3";
        }
    }
    return ageModifier;
};

export default ageToString;
