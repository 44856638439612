import React, { memo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { serviceSelector } from "../../../../pages/all-services/all-services.selectors";
import { allResultSelector } from "../../all-result.selectors";
import { removeTags } from "../../all-result.operations";

import { filterTypes, mapToFilterConfig } from "./filter.config";
import FilterItem from "./components/filter-item/filter-item";

import "./index.scss";

const Filter = ({ type }) => {
    const { clarificationsForSearch, subservicesForSearch, servicesForSearch } =
        useSelector(serviceSelector);
    const { tags } = useSelector(allResultSelector);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [openItem, setOpenItem] = useState({
        clarifications: { status: true, limit: 3 },
        subservices: { status: true, limit: 3 },
        services: { status: true, limit: 3 },
    });

    const handleChangeOpenItemStatus = useCallback(
        (item) => {
            setOpenItem((prevOpenItem) => ({
                ...prevOpenItem,
                [item]: {
                    ...prevOpenItem[item],
                    status: !prevOpenItem[item].status,
                },
            }));
        },
        [openItem]
    );

    const handleChangeOpenItemLimit = useCallback(
        (item) => {
            setOpenItem((prevOpenItem) => ({
                ...prevOpenItem,
                [item]: {
                    ...prevOpenItem[item],
                    limit: prevOpenItem[item].limit + 3,
                },
            }));
        },
        [openItem]
    );

    const renderFilterOptions = (title) => {
        switch (title) {
            case filterTypes.SERVICES:
                return servicesForSearch;
            case filterTypes.SUBSERVICES:
                return subservicesForSearch;
            case filterTypes.CLARIFICATIONS:
                return clarificationsForSearch;
            default:
                return [];
        }
    };

    return (
        <div className="filters">
            <div className="filters__title">{t("order_search.filter")}</div>
            {mapToFilterConfig.map(({ title }) => (
                <FilterItem
                    handleChangeOpenItemStatus={handleChangeOpenItemStatus}
                    handleChangeOpenItemLimit={handleChangeOpenItemLimit}
                    data={renderFilterOptions(title)}
                    openItem={openItem}
                    item={title}
                    key={title}
                    type={type}
                />
            ))}
            {tags.length > 0 && (
                <span
                    onClick={() => dispatch(removeTags(type))}
                    className="filters__subtitle"
                >
                    {t("order_search.remove_filter")}
                </span>
            )}
        </div>
    );
};

Filter.propTypes = { type: PropTypes.string };

export default memo(Filter);
