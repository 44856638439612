import storage from "redux-persist/lib/storage";

import * as requests from "../../services/requests/auth";
import * as accountRequests from "../../services/requests/my-account";

import { disconnectedWebsocket } from "../../components/websockets/websockets.operations";
import { changeButtonLoader } from "../../components/loader/loader.actions";
import { myAccountSelector } from "../my-account/my-account.selectors";
import { authSelector, sessionAccessTokenSelector } from "./auth.selectors";
import { countryCodesSelector } from "../../components/country-codes-dropdown/country-codes.selectors";
import { closeModal, openModal } from "../../components/modal/modal.actions";
import { getUser, putUserAvatar } from "../my-account/my-account.operations";
import { oauthStep } from "./auth.constants";
import * as authActions from "./auth.actions";
import * as myAccountActions from "../my-account/my-account.actions";

import { modalConstants } from "../../components/modal/modal.constants";
import { generateBodyForRegistrationRequest } from "./auth.utils";
import { getItem } from "../../helpers/local-storage";
import { parseJwt } from "../../helpers/parse-jwt";
import routerBook from "../../router/router-book";

export const setUserData = (data) => (dispatch, getState) => {
    const { user } = authSelector(getState());
    const newData = { ...data };

    const updateUserData = {
        ...user,
        firstName: newData.firstName,
        lastName: newData.lastName,
        phone: {
            ...newData.phone,
            number: newData.phone.number,
            dialCode: newData.phone.dialCode,
            fullNumber: newData.phone.dialCode + newData.phone.number,
        },
        services: newData.services,
        gender: newData.gender,
        birthday: newData.birthday,
        email: newData.email,
        createPassword: newData.createPassword,
        confirmPassword: newData.confirmPassword,
    };

    dispatch(authActions.setUserDataAction(updateUserData));
};

export const sendLoginFormOperation = (data, navigate) => async (dispatch) => {
    dispatch(changeButtonLoader({ status: true, button: "" }));
    dispatch(authActions.sendLoginFormRequest());

    if (data.email) dispatch(authActions.setRestoreAccountEmail(data.email));
    if (data.phone) dispatch(authActions.setRestoreAccountPhone(data.phone));

    return requests
        .sendLoginDataRequest(data)
        .then((response) => {
            const { deviceId } = data;
            const payload = { ...response, deviceId };
            dispatch(authActions.sendLoginFormSuccess(payload));
            dispatch(getUser());
            navigate(routerBook.home, { state: true });
        })
        .catch(({ errorData }) => {
            dispatch(authActions.sendLoginFormError(errorData.error));
        })
        .finally(() => {
            dispatch(changeButtonLoader({ status: false, button: "" }));
        });
};

export const sendOauthOperation =
    (oauthData, navigate) => async (dispatch, getState) => {
        dispatch(authActions.sendOauthRequest());

        const { role } = authSelector(getState());

        return requests
            .sendOauthRequest(oauthData)
            .then((response) => {
                const { deviceId } = oauthData;
                const payload = { ...response, deviceId };

                navigate(routerBook.auth.accountType, { state: true });

                if (payload.step === oauthStep.login) {
                    dispatch(authActions.sendOauthSuccess(payload));
                    dispatch(getUser());
                    navigate(routerBook.home, { state: true });
                }

                if (payload.step === oauthStep.registration && role) {
                    navigate(routerBook.auth.location, { state: true });

                    dispatch(authActions.setOauthTokenAction(payload.token));

                    accountRequests
                        .getUserById(payload.token)
                        .then((response) => {
                            dispatch(authActions.setUserDataAction(response));
                            dispatch(
                                myAccountActions.setAvatar(response.avatar)
                            );
                        });
                }
            })
            .catch(({ status, errorData }) => {
                dispatch(authActions.sendOauthError(errorData.error));
                if (status === 410) {
                    return requests
                        .getUserProfileFromGoogle(oauthData.googleToken)
                        .then((response) => response.json())
                        .then((data) => {
                            dispatch(
                                authActions.setRestoreAccountEmail(data.email)
                            );
                        });
                }
            });
    };

export const sendOauthRegistrationOperation =
    (data, navigate) => async (dispatch, getState) => {
        const { oauthToken } = authSelector(getState());

        dispatch(authActions.sendOauthRegistrationRequest());

        return requests
            .sendOauthRegistrationRequest(data, oauthToken)
            .then((response) => {
                dispatch(authActions.sendOauthRegistrationSuccess(response));
                dispatch(getUser());
                navigate(routerBook.home, { state: true });
            })
            .catch(({ errorData }) => {
                dispatch(
                    authActions.sendOauthRegistrationError(errorData.error)
                );
            });
    };

export const sendRegistrationForm =
    (navigate) => async (dispatch, getState) => {
        dispatch(changeButtonLoader({ status: true, button: "" }));
        dispatch(authActions.sendRegistrationFormRequest());

        const { avatar } = myAccountSelector(getState());
        const { user, role, city, district } = authSelector(getState());

        const updateUser = generateBodyForRegistrationRequest(
            {
                ...user,
                phone: {
                    number: user.phone.fullNumber,
                    isoCode: user.phone.isoCode,
                },
            },
            role,
            city?.name,
            district?.name
        );

        return requests
            .sendRegistrationDataRequest(updateUser, role)
            .then((response) => {
                const payload = { ...response, deviceId: updateUser.deviceId };
                dispatch(authActions.sendRegistrationFormSuccess(payload));

                if (avatar) {
                    if (avatar.includes("data:image/jpeg;base64")) {
                        dispatch(postAvatarOperation(avatar));
                    } else {
                        dispatch(putUserAvatar(avatar));
                    }
                }

                dispatch(getUser());
                navigate(routerBook.home, { state: true });
            })
            .catch(({ errorData }) =>
                dispatch(authActions.sendRegistrationFormError(errorData.error))
            )
            .finally(() =>
                dispatch(changeButtonLoader({ status: false, button: "" }))
            );
    };

export const forgotPassword = (data) => async (dispatch) => {
    dispatch(authActions.forgotPasswordRequest());
    dispatch(changeButtonLoader({ status: true, button: "" }));

    dispatch(authActions.setRestoreAccountEmail(data.email));

    return requests
        .sendForgotPasswordRequest(data)
        .then(() => {
            dispatch(authActions.forgotPasswordSuccess(data));
            dispatch(
                openModal({
                    isOpen: true,
                    closeIcon: false,
                    renderType: modalConstants.forgotPasswordPending,
                })
            );
        })
        .catch(({ errorData }) => {
            dispatch(authActions.forgotPasswordError(errorData.error));
        })
        .finally(() =>
            dispatch(changeButtonLoader({ status: false, button: "" }))
        );
};

export const resetPassword = (data, navigate) => async (dispatch) => {
    dispatch(authActions.resetPasswordRequest());
    dispatch(changeButtonLoader({ status: true, button: "" }));

    const deviceId = getItem("deviceID");
    const { token, newPassword: password } = data;
    const updateData = { password, token, deviceId };

    return requests
        .sendResetPasswordRequest(updateData)
        .then((response) => {
            const payload = { ...response, deviceId };
            dispatch(authActions.resetPasswordSuccess(payload));
            dispatch(getUser());
            navigate(routerBook.home, { state: true });
        })
        .then(() => storage.removeItem("persist:root"))
        .catch(({ errorData }) => {
            dispatch(authActions.resetPasswordError(errorData.error));
        })
        .finally(() =>
            dispatch(changeButtonLoader({ status: false, button: "" }))
        );
};

export const sendEmailVerifyDataOperation =
    (data, navigate) => async (dispatch) => {
        dispatch(authActions.sendEmailVerifyRequest());
        dispatch(changeButtonLoader({ status: true, button: "" }));

        return requests
            .sendEmailVerifyDataRequest(data)
            .then(() => {
                dispatch(authActions.sendEmailVerifySuccess(data.email));
                navigate(routerBook.auth.confirmationMessage, { state: true });
            })
            .catch(({ errorData, status }) => {
                dispatch(authActions.sendEmailVerifyError(errorData.error));
                if (status === 410) {
                    dispatch(authActions.setRestoreAccountEmail(data.email));
                }
            })
            .finally(() =>
                dispatch(changeButtonLoader({ status: false, button: "" }))
            );
    };

export const sendEmailConfirmationDataOperation =
    (data, navigate) => async (dispatch, getState) => {
        dispatch(authActions.sendEmailConfirmationRequest());
        const { user } = authSelector(getState());
        const token = sessionAccessTokenSelector(getState());

        return requests
            .sendEmailConfirmationDataRequest(data)
            .then(() => {
                if (token) dispatch(logoutUserOperation());
                const { email, userRole: role } = parseJwt(data.token);
                const payload = { role, user: { ...user, email } };

                dispatch(authActions.sendEmailConfirmationSuccess(payload));
                navigate(routerBook.auth.location, { state: true });
            })
            .catch(({ errorData }) =>
                dispatch(
                    authActions.sendEmailConfirmationError(errorData.error)
                )
            );
    };

export const sendPhoneVerifyDataOperation =
    (data, navigate) => async (dispatch, getState) => {
        dispatch(authActions.sendPhoneVerifyRequest());
        dispatch(changeButtonLoader({ status: true, button: "" }));

        const { countryCode } = countryCodesSelector(getState());

        return requests
            .sendPhoneVerifyDataRequest({
                phone: data.dialCode + data.phone,
                userRole: data.userRole,
            })
            .then((response) => {
                if (response?.test) {
                    dispatch(
                        openModal({
                            isOpen: true,
                            closeIcon: false,
                            renderType: modalConstants.testPhoneCode,
                            details: response.test,
                        })
                    );
                }

                dispatch(
                    authActions.sendPhoneVerifySuccess({
                        dialCode: data.dialCode,
                        number: data.phone,
                        isoCode: countryCode.isoCode,
                    })
                );
                navigate(routerBook.auth.phoneConfirmation, { state: true });
            })
            .catch(({ errorData, status }) => {
                dispatch(authActions.sendPhoneVerifyError(errorData.error));
                if (status === 410) {
                    dispatch(
                        authActions.setRestoreAccountPhone(
                            data.dialCode + data.phone
                        )
                    );
                }
            })
            .finally(() =>
                dispatch(changeButtonLoader({ status: false, button: "" }))
            );
    };

export const sendPhoneConfirmationDataOperation =
    (data, navigate) => async (dispatch) => {
        dispatch(authActions.sendPhoneConfirmationRequest());
        dispatch(changeButtonLoader({ status: true, button: "" }));

        return requests
            .sendPhoneConfirmationDataRequest(data)
            .then((response) => {
                dispatch(authActions.sendPhoneConfirmationSuccess());

                if (response.message) {
                    navigate(routerBook.auth.location, { state: true });
                }
            })
            .catch(({ errorData }) => {
                dispatch(
                    authActions.sendPhoneConfirmationError(errorData.error)
                );
            })
            .finally(() =>
                dispatch(changeButtonLoader({ status: false, button: "" }))
            );
    };

export const postAvatarOperation = (data) => (dispatch) => {
    dispatch(authActions.postAvatarRequest());

    let formData = new FormData();

    fetch(data)
        .then((res) => res.blob())
        .then((blob) => {
            const file = new File([blob], "File name", {
                type: "image/png",
            });
            formData.append("avatar", file);
            requests
                .sendAvatarRequest(formData)
                .then(() => {
                    dispatch(authActions.postAvatarSuccess());
                    dispatch(getUser());
                })
                .catch(({ errorData }) => {
                    dispatch(authActions.postAvatarError(errorData.error));
                });
        });
};

export const logoutUserOperation = () => (dispatch) => {
    dispatch(disconnectedWebsocket());
    dispatch(authActions.logoutUser());
};

export const restoreEmail = () => async (dispatch, getState) => {
    dispatch(changeButtonLoader({ status: true, button: "" }));
    dispatch(authActions.restoreEmailRequest());

    const { restoreAccount } = authSelector(getState());

    return requests
        .sendRestoreEmailRequest({ email: restoreAccount.email })
        .then(() => {
            dispatch(
                openModal({
                    isOpen: true,
                    closeIcon: false,
                    renderType: modalConstants.restoreAccountPending,
                })
            );
        })
        .catch(({ errorData }) => {
            dispatch(authActions.restoreEmailError(errorData.error));
            dispatch(closeModal());
        })
        .finally(() => {
            dispatch(changeButtonLoader({ status: false, button: "" }));
        });
};

export const restorePhone = () => async (dispatch, getState) => {
    dispatch(changeButtonLoader({ status: true, button: "" }));
    dispatch(authActions.restorePhoneRequest());

    const { restoreAccount, role } = authSelector(getState());

    return requests
        .sendRestorePhoneRequest({
            phone: restoreAccount.phone,
            userRole: role,
        })
        .then((response) => {
            if (response?.message) {
                dispatch(
                    openModal({
                        isOpen: true,
                        closeIcon: false,
                        renderType: modalConstants.accountPhoneCode,
                        details: {
                            message: response.message,
                        },
                    })
                );
            }

            if (response?.test) {
                dispatch(
                    openModal({
                        isOpen: true,
                        closeIcon: false,
                        renderType: modalConstants.accountPhoneCode,
                        details: {
                            test: response.test,
                            message: response.message,
                        },
                    })
                );
            }
        })
        .catch(({ errorData }) => {
            dispatch(authActions.restorePhoneError(errorData.error));
            dispatch(closeModal());
        })
        .finally(() => {
            dispatch(changeButtonLoader({ status: false, button: "" }));
        });
};

export const restoreAccount = (data) => async (dispatch) => {
    dispatch(authActions.restoreAccountRequest());

    return requests
        .sendRestoreAccountRequest(data)
        .then(() => {
            dispatch(
                openModal({
                    isOpen: true,
                    closeIcon: false,
                    renderType: modalConstants.restoreAccountSuccess,
                })
            );
        })
        .catch(({ errorData }) => {
            dispatch(authActions.restoreAccountError(errorData.error));
        });
};

export const restoreByOauth = () => async (dispatch, getState) => {
    dispatch(changeButtonLoader({ status: true, button: "" }));
    dispatch(authActions.sendRestoreByOauthRequest());

    const { restoreAccount } = authSelector(getState());

    return requests
        .sendRestoreByOauthRequest({ facebookToken: restoreAccount.facebook })
        .then(() => {
            dispatch(
                openModal({
                    isOpen: true,
                    closeIcon: false,
                    renderType: modalConstants.restoreAccountSuccess,
                })
            );
        })
        .catch(({ errorData }) => {
            dispatch(authActions.sendRestoreByOauthError(errorData.error));
        })
        .finally(() => {
            dispatch(changeButtonLoader({ status: false, button: "" }));
        });
};
