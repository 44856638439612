import { useDispatch, useSelector } from "react-redux";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import { roleSelector } from "../../../../../my-account/my-account.selectors";
import { openModal } from "../../../../../../components/modal/modal.actions";
import { buttonLoaderSelector } from "../../../../../../components/loader/loader.selectors";
import { ordersSelector } from "../../../../../orders/orders.selectors";

import LazyImage from "../../../../../../components/lazy-image/lazy-image";
import { modalConstants } from "../../../../../../components/modal/modal.constants";
import ButtonLoader from "../../../../../../components/loader/components/button-loader/button-loader";
import { typeOfLazeImage } from "../../../../../../components/lazy-image/lazyImage.constansts";
import { replaceValue } from "../../../../../../helpers/replace-values";
import { getPhotoByTypeGender } from "../../../../../../helpers/get-photo-by-type-gender";
import { chatHintMessage } from "../../chat.config";
import { Icon } from "../../../../../../components/image/image";
import {
    byCreatorConfig,
    byReceiverConfig,
    cancelledMessages,
} from "./chat-message.config";

import "./index.scss";

const ChatMessage = (message) => {
    const {
        _id: messageId,
        participant,
        messageType,
        createdBy,
        carousel,
        createdAt,
        operation,
        callback,
        orderId,
        taskId,
        userId,
        readBy,
        image,
        text,
        same,
        video,
    } = message;
    const {
        orderTask: { status, priceProposalHistory, priceProposal },
    } = useSelector(ordersSelector);
    const { status: statusLoading, button: buttonLoading } =
        useSelector(buttonLoaderSelector);
    const { role } = useSelector(roleSelector);
    const [duration, setDuration] = useState(0);
    const messageByReceiverRef = useRef(null);
    const messageByCreatorRef = useRef(null);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { proposedBy } = priceProposal;
    const accessToFixPrice =
        proposedBy && priceProposalHistory?.slice(-1).pop() === messageId;

    const handleLoadedMetadata = (ref) => {
        const video = ref.current;
        if (!video) return;
        video.onloadedmetadata = () => setDuration(video.duration);
        return duration;
    };

    const messageByCreator = () => {
        return (
            <div
                className={`chat_body_message_own${!same ? "-same" : ""} ${
                    image ? "--with-image" : ""
                }`}
                onAnimationStart={() => callback && callback((p) => (p += 1))}
            >
                {image ? (
                    <div
                        onLoad={() => callback && callback((p) => (p += 1))}
                        className="chat_body_message_own_image"
                        onClick={() => {
                            dispatch(
                                openModal({
                                    isOpen: true,
                                    closeIcon: false,
                                    renderType: modalConstants.chatCarousel,
                                    details: { carousel, url: image.url },
                                })
                            );
                        }}
                    >
                        <LazyImage
                            type={typeOfLazeImage.content}
                            options={{ alt: "image" }}
                            image={image.url}
                        />
                    </div>
                ) : video ? (
                    <div
                        onLoad={() => callback && callback((p) => (p += 1))}
                        className="chat_body_message_own_image"
                        onClick={() => {
                            dispatch(
                                openModal({
                                    isOpen: true,
                                    closeIcon: false,
                                    renderType: modalConstants.chatCarousel,
                                    details: { video },
                                })
                            );
                        }}
                    >
                        <>
                            <video
                                className="files-preview__container-video"
                                ref={messageByCreatorRef}
                            >
                                <source
                                    src={video.url}
                                    type={video.contentType}
                                />
                            </video>
                            {
                                <span className="chat_body_message_own_image-video">
                                    {messageByCreatorRef.current &&
                                        new Date(
                                            Math.ceil(
                                                handleLoadedMetadata(
                                                    messageByCreatorRef
                                                ) * 1000 || 0
                                            )
                                        )
                                            .toISOString()
                                            .substring(14, 19)}
                                </span>
                            }
                        </>
                    </div>
                ) : (
                    <div className="chat_body_message_own_text">{text}</div>
                )}
                <div className="chat_body_message_own_info">
                    <div className="chat_body_message_own_info_time">
                        {dayjs(createdAt).format("HH:mm")}
                    </div>
                    {readBy.includes(participant?._id) && (
                        <div className="chat_body_message_own_info_status">
                            <Icon type="successMessage" />
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const messageByReceiver = () => {
        return (
            <div
                className={`chat_body_message_companion${!same ? "-same" : ""}`}
                onTransitionEnd={() => callback && callback((p) => (p += 1))}
            >
                <div className="chat_body_message_companion_avatar">
                    <LazyImage
                        image={getPhotoByTypeGender(
                            participant?.avatar,
                            participant?.gender
                        )}
                        type={typeOfLazeImage.avatar}
                        options={{ alt: "avatar" }}
                    />
                </div>
                <div
                    className={`chat_body_message_companion_body ${
                        image ? "--with-image" : ""
                    }`}
                >
                    <div className="chat_body_message_companion_body__info">
                        {image ? (
                            <div
                                className="chat_body_message_companion_body_image"
                                onLoad={() =>
                                    callback && callback((p) => (p += 1))
                                }
                                onClick={() => {
                                    dispatch(
                                        openModal({
                                            isOpen: true,
                                            closeIcon: false,
                                            renderType:
                                                modalConstants.chatCarousel,
                                            details: { carousel, messageId },
                                        })
                                    );
                                }}
                            >
                                {image.contentType.includes("image") ? (
                                    <LazyImage
                                        type={typeOfLazeImage.content}
                                        options={{ alt: "image" }}
                                        image={image.url}
                                    />
                                ) : (
                                    <>
                                        <video
                                            className="files-preview__container-video"
                                            ref={messageByReceiverRef}
                                            src={image.url}
                                        />
                                        <span className="chat_body_message_own_image-video">
                                            {messageByReceiverRef.current &&
                                                new Date(
                                                    Math.ceil(
                                                        handleLoadedMetadata(
                                                            messageByReceiverRef
                                                        ) * 1000 || 0
                                                    )
                                                )
                                                    .toISOString()
                                                    .substring(14, 19)}
                                        </span>
                                    </>
                                )}
                            </div>
                        ) : (
                            <div className="chat_body_message_companion_body_description">
                                {text}
                            </div>
                        )}
                    </div>
                    <div className="chat_body_message_system_body__status">
                        <div className="chat_body_message_system_body__status">
                            <div className="chat_body_message_system_body__status_time">
                                {dayjs(createdAt).format("HH:mm")}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const operationMessageByReceiver = (type) => {
        return (
            <div
                className="chat_body_message_system"
                onTransitionEnd={() => callback && callback((p) => (p += 1))}
            >
                <div className="chat_body_message_system_avatar">
                    <Icon type="avatarGreenWconnect" />
                </div>
                <div className="chat_body_message_system_body">
                    <div className="chat_body_message_system_body__info">
                        <div className="chat_body_message_system_body_title">
                            Wconnect
                        </div>
                        <div className="chat_body_message_system_body_name">
                            {replaceValue({
                                value: operation.text,
                                isBold: true,
                            })}
                        </div>
                        <div className="chat_body_message_system_body_description"></div>
                        {!byReceiverConfig[type].coupleOfButton ? (
                            <div className="chat_body_message_system_body__service">
                                <button
                                    className="chat_body_message_system_body__service_confirm"
                                    disabled={
                                        status !==
                                            byReceiverConfig[type]
                                                .accessStatus ||
                                        (statusLoading &&
                                            byReceiverConfig[type]
                                                .operationType ===
                                                buttonLoading)
                                    }
                                    onClick={() =>
                                        byReceiverConfig[type].handleOnClick(
                                            orderId,
                                            taskId,
                                            dispatch
                                        )
                                    }
                                >
                                    {statusLoading &&
                                    byReceiverConfig[type].operationType ===
                                        buttonLoading ? (
                                        <ButtonLoader size={18} />
                                    ) : (
                                        t("btn_confirm")
                                    )}
                                </button>
                            </div>
                        ) : (
                            <div className="chat_body_message_system_body__service">
                                <button
                                    className="chat_body_message_system_body__service_cancel"
                                    disabled={
                                        status !==
                                            byReceiverConfig[type]
                                                .accessStatus ||
                                        !accessToFixPrice ||
                                        (statusLoading &&
                                            buttonLoading === "REFUSE" &&
                                            accessToFixPrice)
                                    }
                                    onClick={() =>
                                        byReceiverConfig[type].handleOnClick(
                                            orderId,
                                            taskId,
                                            dispatch
                                        )
                                    }
                                >
                                    {statusLoading &&
                                    buttonLoading === "REFUSE" &&
                                    accessToFixPrice ? (
                                        <ButtonLoader size={18} />
                                    ) : (
                                        t("btn_cancel")
                                    )}
                                </button>
                                <button
                                    className="chat_body_message_system_body__service_approve"
                                    disabled={
                                        status !==
                                            byReceiverConfig[type]
                                                .accessStatus ||
                                        !accessToFixPrice ||
                                        (statusLoading &&
                                            buttonLoading === "APPROVE" &&
                                            accessToFixPrice)
                                    }
                                    onClick={() =>
                                        byReceiverConfig[
                                            type
                                        ].secondHandleOnClick(
                                            orderId,
                                            taskId,
                                            dispatch,
                                            role
                                        )
                                    }
                                >
                                    {statusLoading &&
                                    buttonLoading === "APPROVE" &&
                                    accessToFixPrice ? (
                                        <ButtonLoader size={18} />
                                    ) : (
                                        t("btn_confirm")
                                    )}
                                </button>
                            </div>
                        )}
                    </div>
                    <div className="chat_body_message_system_body__status">
                        <div className="chat_body_message_system_body__status">
                            <div className="chat_body_message_system_body__status_time">
                                {dayjs(createdAt).format("HH:mm")}
                            </div>
                            {readBy.includes(participant?._id) && (
                                <div className="chat_body_message_system_body__status_status">
                                    <Icon type="successMessage" />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const operationMessageByCreator = (type) => {
        return (
            <>
                <div
                    className="chat_body_message_system"
                    onTransitionEnd={() =>
                        callback && callback((p) => (p += 1))
                    }
                >
                    <div className="chat_body_message_system_avatar">
                        <Icon type="avatarGreenWconnect" />
                    </div>
                    <div className="chat_body_message_system_body">
                        <div className="chat_body_message_system_body__info">
                            <div className="chat_body_message_system_body_title"></div>
                            <div className="chat_body_message_system_body_name">
                                {t(byCreatorConfig[type].title)}{" "}
                                {t("chats_operations.message_send")}!
                            </div>
                        </div>
                        <div className="chat_body_message_system_body__status">
                            <div className="chat_body_message_system_body__status">
                                <div className="chat_body_message_system_body__status_time">
                                    {dayjs(createdAt).format("HH:mm")}
                                </div>
                                {readBy.includes(participant?._id) && (
                                    <div className="chat_body_message_system_body__status_status">
                                        <Icon type="successMessage" />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="chat_body_message_system_hint"
                    onTransitionEnd={() =>
                        callback && callback((p) => (p += 1))
                    }
                >
                    <div className="chat_body_message_system_hint_text">
                        {t(chatHintMessage[role][type]?.title)}
                    </div>
                </div>
            </>
        );
    };

    const operationMessageBySystem = (type) => {
        return (
            <>
                <div
                    className="chat_body_message_system"
                    onTransitionEnd={() =>
                        callback && callback((p) => (p += 1))
                    }
                >
                    <div
                        className={`chat_body_message_system_avatar ${
                            cancelledMessages[operation.actionType]
                                ? "cancelled"
                                : ""
                        }`}
                    >
                        <Icon type="avatarGreenWconnect" />
                    </div>
                    <div
                        className={`chat_body_message_system_body ${
                            cancelledMessages[operation.actionType]
                                ? "cancelled"
                                : ""
                        }`}
                    >
                        <div className="chat_body_message_system_body__info">
                            <div className="chat_body_message_system_body_title">
                                Wconnect
                            </div>
                            <div
                                className={`chat_body_message_system_body_name`}
                            >
                                {replaceValue({
                                    value: operation.text,
                                    isBold: true,
                                })}
                            </div>
                        </div>
                        <div className="chat_body_message_system_body__status">
                            <div className="chat_body_message_system_body__status">
                                <div className="chat_body_message_system_body__status_time">
                                    {dayjs(createdAt).format("HH:mm")}
                                </div>
                                {readBy.includes(participant?._id, userId) && (
                                    <div className="chat_body_message_system_body__status_status">
                                        <Icon type="successMessage" />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {chatHintMessage[role][type]?.title && (
                    <div
                        className="chat_body_message_system_hint"
                        onTransitionEnd={() =>
                            callback && callback((p) => (p += 1))
                        }
                    >
                        <div className="chat_body_message_system_hint_text">
                            {t(chatHintMessage[role][type]?.title)}
                        </div>
                    </div>
                )}
            </>
        );
    };

    const renderTextMessage = () => {
        return createdBy === userId ? messageByCreator() : messageByReceiver();
    };

    const renderOperationMessage = () => {
        switch (true) {
            case operation.type === "action" && createdBy === userId:
                return operationMessageByCreator(operation.actionType);
            case operation.type === "action" && createdBy === participant?._id:
            case operation.type === "action" && !participant:
                return operationMessageByReceiver(operation.actionType);
            default:
                return operationMessageBySystem(operation.actionType);
        }
    };

    return messageType === "operation"
        ? renderOperationMessage()
        : renderTextMessage();
};

export default ChatMessage;
