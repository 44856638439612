import { sizeItemOnPage } from "./support.constants";
import { supportTypes } from "./support.types";

const initialQueryParams = { limit: sizeItemOnPage, text: "", page: 0 };

const initialState = {
    supportList: {
        items: [],
        count: 0,
    },
    supportChat: {},
    params: initialQueryParams,
    loading: false,
    error: "",
};

export const support = (state = initialState, { type, payload }) => {
    switch (type) {
        case supportTypes.POST_QUESTION_PENDING:
        case supportTypes.DELETE_QUESTION_PENDING:
        case supportTypes.GET_ALL_QUESTIONS_PENDING: {
            return { ...state, loading: true };
        }

        case supportTypes.POST_QUESTION_FULFILLED: {
            return {
                ...state,
                supportList: payload,
            };
        }

        case supportTypes.DELETE_QUESTION_FULFILLED: {
            return {
                ...state,
                supportList: payload,
            };
        }

        case supportTypes.GET_ALL_QUESTIONS_FULFILLED: {
            return {
                ...state,
                supportList: payload,
            };
        }

        case supportTypes.POST_QUESTION_REJECTED:
        case supportTypes.DELETE_QUESTION_REJECTED:
        case supportTypes.GET_ALL_QUESTIONS_REJECTED: {
            return { ...state, loading: false, error: payload };
        }

        case supportTypes.SET_PAGE:
            return { ...state, params: { ...state.params, page: payload } };

        case supportTypes.SET_TEXT:
            return { ...state, params: { ...state.params, text: payload } };

        case supportTypes.CLEAR_ALL_PARAMS:
            return {
                ...state,
                supportList: initialState.supportList,
                params: initialQueryParams,
            };
        default:
            return state;
    }
};

export default support;
