import { t } from "i18next";

export const mapStateToOptions = (array) => {
    return array.map(({ titleTranslations, _id, remark, title }) => {
        return {
            label: titleTranslations || title || remark || t("removed"),
            value: _id,
        };
    });
};

export const handleDeleteImage = (e, deletedIndex, image, setImage) => {
    e.preventDefault();
    return setImage(image.filter((_, index) => index !== deletedIndex));
};

export const convertValuesToSearchFormat = (values, additionalData) => {
    const searchFilters = [];

    if (values.categoryId?.value) {
        const categoryObj = additionalData.services.filter(
            (item) => item._id === values.categoryId?.value
        )[0];

        searchFilters.push({
            label:
                categoryObj.titleTranslations ||
                categoryObj?.title ||
                categoryObj?.remark ||
                t("removed"),
            value: `&categories=${categoryObj._id}`,
        });
    }

    if (values.subcategoryId?.value) {
        const subservicesObj = additionalData.subservices.filter(
            (item) => item._id === values.subcategoryId?.value
        )[0];

        searchFilters.push({
            label:
                subservicesObj.titleTranslations ||
                subservicesObj?.title ||
                subservicesObj?.remark ||
                t("removed"),
            value: `&subcategories=${subservicesObj._id}`,
        });
    }

    if (values.clarificationId?.value) {
        const clarificationObj = additionalData.clarifications.filter(
            (item) => item._id === values.clarificationId?.value
        )[0];

        searchFilters.push({
            label:
                clarificationObj.titleTranslations ||
                clarificationObj?.title ||
                clarificationObj?.remark ||
                t("removed"),
            value: `&clarifications=${clarificationObj._id}`,
        });
    }

    return searchFilters;
};

export const convertValuesToSearchString = (values) => {
    let searchString = "";

    if (values.categoryId?.value) {
        searchString += `&categories=${values.categoryId?.value}`;
    }

    if (values.subcategoryId?.value) {
        searchString += `&subcategories=${values.subcategoryId?.value}`;
    }

    if (values.clarificationId?.value) {
        searchString += `&clarifications=${values.subcategoryId?.value}`;
    }

    return searchString;
};
