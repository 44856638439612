import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import React from "react";

import { buttonLoaderSelector } from "../../../../../../../components/loader/loader.selectors";
import {
    closeModal,
    openModal,
} from "../../../../../../../components/modal/modal.actions";
import {
    putTaskFixPrice,
    putTaskStatus,
} from "../../../../../../orders/orders.operations";

import ButtonLoader from "../../../../../../../components/loader/components/button-loader/button-loader";
import { modalConstants } from "../../../../../../../components/modal/modal.constants";
import { operationStatus } from "../../chat-message/chat-message.config";

import "./index.scss";

const AgreeWithCommission = ({ details: { orderId, taskId, type } }) => {
    const { status: buttonStatus } = useSelector(buttonLoaderSelector);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleSubmit = () => {
        switch (type) {
            case operationStatus.PROPOSED_FIX_PRICE:
                dispatch(
                    openModal({
                        isOpen: true,
                        closeIcon: true,
                        renderType: modalConstants.proposedOrderAmount,
                        details: { orderId, taskId },
                    })
                );
                break;

            case operationStatus.PRICE_APPROVED:
                dispatch(
                    putTaskFixPrice(orderId, taskId, { eventType: "APPROVE" })
                );
                dispatch(closeModal());
                break;

            case operationStatus.CONFIRM_START_WORK:
                dispatch(putTaskStatus(orderId, taskId, "confirm"));
                dispatch(closeModal());
                break;

            default:
                return dispatch(closeModal());
        }
    };

    return (
        <div className="agree_with_commission">
            <span className="agree_with_commission__title">
                {t("chats_item.agree_with_commission")}
            </span>
            <div className="agree_with_commission__btns">
                <button
                    className="agree_with_commission__btns-cancel"
                    onClick={() => dispatch(closeModal())}
                >
                    {t("btn_cancel")}
                </button>
                <button
                    className={`agree_with_commission__btns-delete${
                        buttonStatus ? "-loading" : ""
                    }`}
                    onClick={handleSubmit}
                    disabled={buttonStatus}
                >
                    {buttonStatus ? (
                        <ButtonLoader size={38} />
                    ) : (
                        t("btn_confirm")
                    )}
                </button>
            </div>
        </div>
    );
};

AgreeWithCommission.propTypes = {
    details: PropTypes.object,
};

export default AgreeWithCommission;
