import React, { Fragment } from "react";

import MyAccountForm from "./components/my-account-form/my-account-form";
import ReactHelmet from "../../components/react-helmet/react-helmet";

const MyAccount = () => (
    <Fragment>
        <ReactHelmet title="my_account_title" />
        <MyAccountForm />
    </Fragment>
);

export default MyAccount;
