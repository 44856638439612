export const clientTypes = {
    // get client list
    GET_CLIENT_LIST_PENDING: "client/GET_CLIENT_LIST_PENDING",
    GET_CLIENT_LIST_FULFILLED: "client/GET_CLIENT_LIST_FULFILLED",
    GET_CLIENT_LIST_REJECTED: "client/GET_CLIENT_LIST_REJECTED",

    // set
    CLEAR_ALL_PARAMS: "client/CLEAR_ALL_PARAMS",
    SET_PAGE: "client/SET_PAGE",
};
