import { get } from "../api";

export const getClientListRequest = async (params = "") => {
    const url = "/customer/list" + params;

    try {
        const { data } = await get(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const getClientFavouritesRequest = async (params = "") => {
    const url = "/customer/favourites" + params;

    try {
        const { data } = await get(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};
