import { t } from "i18next";

import * as api from "../../services/requests/my-account";

import { successToastify } from "../../components/toastify/toastify.operations";
import { closeModal } from "../../components/modal/modal.actions";
import { myAccountSelector } from "./my-account.selectors";
import * as myAccountActions from "./my-account.actions";
import { authSelector } from "../auth/auth.selectors";
import {
    changeButtonLoader,
    changeGlobalLoader,
} from "../../components/loader/loader.actions";
import {
    logoutUser,
    sendLoginFormSuccess,
    setCityAction,
} from "../auth/auth.actions";
import { openModal } from "../../components/modal/modal.actions";
import { modalConstants } from "../../components/modal/modal.constants";

import { paymentTypes } from "../balance/balance.constants";
import routerBook from "../../router/router-book";

export const putUserAccount =
    (
        data = {},
        role = "",
        reset = () => {},
        userData = {},
        navigate = () => {}
    ) =>
    async (dispatch, getState) => {
        dispatch(changeButtonLoader({ status: true, button: "update-user" }));
        dispatch(myAccountActions.putUserPending());

        if ("avatar" in data) delete data.avatar;

        const { data: prevData } = myAccountSelector(getState());
        const user = authSelector(getState());

        return api
            .putUser(data, role)
            .then(() => {
                const newData = { ...prevData, ...data };
                dispatch(myAccountActions.putUserFulfilled(newData));
                dispatch(successToastify("toastify.my-account.data_changed"));
                const { data: updatedData } = myAccountSelector(getState());
                const updatedUser = { ...user, city: updatedData };
                dispatch(sendLoginFormSuccess(updatedUser));
                reset(userData);
                navigate(routerBook.myAccountInfo, { state: true });
            })
            .catch(({ errorData }) => {
                dispatch(myAccountActions.putUserRejected(errorData.error));
            })
            .finally(() =>
                dispatch(changeButtonLoader({ status: false, button: "" }))
            );
    };

export const putUserAvatar =
    (avatar, handleUploadImageChange) => async (dispatch) => {
        dispatch(myAccountActions.putUserAvatarPending());
        dispatch(changeButtonLoader({ status: true, button: "update-avatar" }));

        let avatarType = typeof avatar === "string" ? "Url" : "";
        let formData = new FormData();
        formData.append(`avatar${avatarType}`, avatar);

        return api
            .putAvatar(formData)
            .then(({ avatar }) => {
                dispatch(myAccountActions.putUserAvatarFulfilled(avatar));
                dispatch(successToastify("toastify.my-account.avatar_changed"));
                handleUploadImageChange(null);
            })
            .catch((error) =>
                dispatch(myAccountActions.putUserAvatarRejected(error))
            )
            .finally(() => {
                dispatch(changeButtonLoader({ status: false, button: "" }));
                dispatch(closeModal());
            });
    };

export const deleteUserAvatar = () => async (dispatch) => {
    dispatch(changeButtonLoader({ status: true, button: "delete-avatar" }));
    dispatch(myAccountActions.deleteUserAvatarPending());

    return api
        .deleteAvatar()
        .then(() => {
            dispatch(myAccountActions.deleteUserAvatarFulfilled(null));
            dispatch(successToastify("toastify.my-account.avatar_deleted"));
        })
        .catch((error) =>
            dispatch(myAccountActions.deleteUserAvatarRejected(error))
        )
        .finally(() =>
            dispatch(changeButtonLoader({ status: false, button: "" }))
        );
};

export const getUser = () => async (dispatch) => {
    dispatch(myAccountActions.getUserPending());
    dispatch(changeGlobalLoader(true));

    return api
        .getUserById()
        .then((response) => {
            const newResponse = {
                ...response,
                onboarding: {
                    [paymentTypes.stripe.value]:
                        response.stripe.onboarding.finished,
                    [paymentTypes.iyzico.value]:
                        response.iyizico.onboarding.finished,
                },
            };
            delete newResponse.stripe;
            delete newResponse.iyizico;
            dispatch(myAccountActions.putUserAvatarFulfilled(response.avatar));
            dispatch(setCityAction(response.city));
            dispatch(myAccountActions.getUserFulfilled(newResponse));
        })
        .catch((error) => {
            dispatch(myAccountActions.getUserRejected(error));
        })
        .finally(() => dispatch(changeGlobalLoader(false)));
};

export const deleteUser = (navigate) => async (dispatch) => {
    dispatch(changeButtonLoader({ status: true, button: "delete-account" }));
    dispatch(myAccountActions.deleteUserPending());

    return api
        .deleteUser()
        .then(() => {
            dispatch(myAccountActions.deleteUserFulfilled(null));
            dispatch(successToastify("toastify.my-account.account_deleted"));
            dispatch(logoutUser());
            navigate(routerBook.home, { state: true });
        })
        .catch((error) => dispatch(myAccountActions.deleteUserRejected(error)))
        .finally(() =>
            dispatch(changeButtonLoader({ status: false, button: "" }))
        );
};

export const getAvatars = () => async (dispatch) => {
    dispatch(myAccountActions.getAvatarsPending());

    return api
        .getAvatars()
        .then(({ assets }) =>
            dispatch(
                myAccountActions.getAvatarsFulfilled(assets.default_avatars)
            )
        )
        .catch((error) => dispatch(myAccountActions.getAvatarsRejected(error)));
};

export const getPhoneVerificationCode = (phone) => async (dispatch) => {
    dispatch(myAccountActions.getPhoneVerificationCodePending());

    return api
        .getPhoneVerificationCodeRequest(phone)
        .then((response) => {
            if (response?.test) {
                dispatch(
                    openModal({
                        isOpen: true,
                        closeIcon: false,
                        renderType: modalConstants.accountPhoneCode,
                        details: {
                            test: response.test,
                            message: response.message,
                        },
                    })
                );
            }

            dispatch(myAccountActions.getPhoneVerificationCodeFulfilled());
        })
        .catch(({ errorData }) =>
            dispatch(
                myAccountActions.getPhoneVerificationCodeRejected(
                    errorData.error
                )
            )
        );
};

export const phoneVerify = (data) => async (dispatch) => {
    dispatch(myAccountActions.phoneVerifyPending());

    return api
        .phoneVerifyRequest(data)
        .then((response) => {
            dispatch(
                myAccountActions.phoneVerifyFulfilled({
                    confirmed: response.phone.confirmed,
                    value: response.phone.value,
                })
            );
            dispatch(closeModal());
            dispatch(successToastify("phone_is_verified"));
            dispatch(getUser());
        })
        .catch(({ errorData }) =>
            dispatch(myAccountActions.phoneVerifyRejected(errorData.error))
        );
};

export const getEmailVerificationCode =
    (email, navigate) => async (dispatch) => {
        dispatch(myAccountActions.getEmailVerificationCodePending());

        return api
            .getEmailVerificationCodeRequest(email)
            .then(() => {
                dispatch(myAccountActions.getEmailVerificationCodeFulfilled());
                navigate(routerBook.auth.confirmationMessage, { state: true });
            })
            .catch(({ errorData }) => {
                dispatch(
                    myAccountActions.getEmailVerificationCodeRejected(
                        errorData.error
                    )
                );
            });
    };

export const emailVerify = (data, navigate) => async (dispatch) => {
    dispatch(myAccountActions.emailVerifyPending());

    return api
        .emailVerifyRequest(data)
        .then(() => {
            dispatch(myAccountActions.emailVerifyFulfilled());
            navigate(routerBook.myAccount, { state: true });
            dispatch(successToastify("email_is_verified"));
        })
        .catch(({ errorData }) => {
            dispatch(myAccountActions.emailVerifyRejected(errorData.error));
        });
};

export const oauthConnect = (data) => async (dispatch) => {
    dispatch(myAccountActions.sendOauthConnectPending());

    return api
        .sendOauthConnectRequest({ facebookToken: data })
        .then(() => {
            dispatch(myAccountActions.sendOauthConnectFulfilled(data));
            dispatch(
                successToastify(t("toastify.my-account.facebook_connected"))
            );
        })
        .catch(({ errorData }) => {
            dispatch(
                myAccountActions.sendOauthConnectRejected(errorData.error)
            );
        });
};
