import { useSelector } from "react-redux";
import { faqSelector } from "../../faq.selectors";
import { t } from "i18next";

import noFAQIcon from "../../../../assets/images/error-pages/empty-result.svg";
import "./index.scss";

const NoFAQ = () => {
    const { loading } = useSelector(faqSelector);

    return (
        <div className="no-faq">
            <img className="no-faq__img" src={noFAQIcon} alt="no faq" />
            <span className="no-faq__title">
                {loading ? t("faq.loading") : t("faq.empty")}
            </span>
        </div>
    );
};

export default NoFAQ;
