import { favouritesActionTypes } from "./favourites.types";

// get client favourites
export const getClientFavouritesRequest = () => ({
    type: favouritesActionTypes.GET_CLIENT_FAVOURITES_REQUEST,
});
export const getClientFavouritesSuccess = (data) => ({
    type: favouritesActionTypes.GET_CLIENT_FAVOURITES_SUCCESS,
    payload: data,
});
export const getClientFavouritesError = (error) => ({
    type: favouritesActionTypes.GET_CLIENT_FAVOURITES_ERROR,
    payload: error,
});

// get specialist favourites
export const getSpecialistFavouritesRequest = () => ({
    type: favouritesActionTypes.GET_SPECIALIST_FAVOURITES_REQUEST,
});
export const getSpecialistFavouritesSuccess = (data) => ({
    type: favouritesActionTypes.GET_SPECIALIST_FAVOURITES_SUCCESS,
    payload: data,
});
export const getSpecialistFavouritesError = (error) => ({
    type: favouritesActionTypes.GET_SPECIALIST_FAVOURITES_ERROR,
    payload: error,
});

// set
export const clearAllParams = () => ({
    type: favouritesActionTypes.CLEAR_ALL_PARAMS,
});
export const setPage = (data) => ({
    type: favouritesActionTypes.SET_PAGE,
    payload: data,
});

// add remove favourites
export const addRemoveFavouritesRequest = () => ({
    type: favouritesActionTypes.ADD_REMOVE_FAVOURITES_REQUEST,
});
export const addRemoveFavouritesSuccess = () => ({
    type: favouritesActionTypes.ADD_REMOVE_FAVOURITES_SUCCESS,
});
export const addRemoveFavouritesError = (error) => ({
    type: favouritesActionTypes.ADD_REMOVE_FAVOURITES_ERROR,
    payload: error,
});
