import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Fragment, useState, useEffect, useCallback, memo } from "react";

import { citiesLoaderSelector } from "../../../components/cities-loader/cities-loader.selectors";
import { citiesLoaderisLoadingSelector } from "../../../components/cities-loader/cities-loader.selectors";
import { getAllDistricts } from "../../../components/cities-loader/cities-loader.operations";
import { putUserAccount } from "../../my-account/my-account.operations";
import { setCityAction, setDistrictAction } from "../../auth/auth.actions";

import SELECT_TYPES from "../../../components/select/select.constants";

import LocationSelect from "../../../components/location-select/location-select";
import ReactHelmet from "../../../components/react-helmet/react-helmet";
import MainButton from "../../../components/main-button/main-button";
import routerBook from "../../../router/router-book";
import { selectLocation } from "./select-location.schema";

import location from "../../../assets/images/auth/location.svg";

import "./index.scss";
import { isObjectEqual } from "../../../helpers/isObjectEqual";
import { myAccountSelector } from "../../my-account/my-account.selectors";
import { authSelector } from "../auth.selectors";

const SelectLocation = memo(() => {
    const isAuth = window.location.pathname === routerBook.changeLocation;

    const isLoading = useSelector(citiesLoaderisLoadingSelector);
    const { cities, districts } = useSelector(citiesLoaderSelector);
    const { data } = useSelector(myAccountSelector);
    const { role } = useSelector(authSelector);

    const { city, district } = data;

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [selectedCity, setCity] = useState({ name: city, state_code: "" });

    const {
        handleSubmit,
        resetField,
        control,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(selectLocation),
    });

    useEffect(() => {
        if (selectedCity.state_code || city)
            dispatch(getAllDistricts(selectedCity.state_code, city));
    }, [selectedCity]);

    useEffect(() => {
        setValue("city", city);
        setValue("district", district);
    }, []);

    const handleFormSubmit = ({ city, district }) => {
        if (isAuth) {
            navigate(routerBook.home, { state: true });
            dispatch(putUserAccount({ city, district }, role));
        } else navigate(routerBook.auth.register, { state: true });
    };

    const handleSelectCity = useCallback((currentCity) => {
        if (
            isObjectEqual(currentCity, selectedCity) |
            (currentCity.name === selectedCity.name)
        )
            return;

        resetField("district");
        setCity(currentCity);
        dispatch(setCityAction(currentCity));
    });

    const handleSelectDistrict = (district) => {
        dispatch(setDistrictAction(district));
    };

    return (
        <Fragment>
            <ReactHelmet title="select_city" />
            <div className="location_container">
                <div className="location_container__content">
                    <img src={location} alt="location" />
                    <h2 className="location_container__title">
                        {t("select_city")}
                    </h2>
                    <div className="location_container__form">
                        <form onSubmit={handleSubmit(handleFormSubmit)}>
                            <Fragment>
                                <Controller
                                    name={"city"}
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <LocationSelect
                                            value={value}
                                            options={cities}
                                            type={SELECT_TYPES.WITHOUT_LABEL}
                                            error={errors.city?.message}
                                            onSelect={(city) => {
                                                onChange(city.name);
                                                handleSelectCity(city);
                                            }}
                                            placeholder="my_account_city_placeholder"
                                            label="select_city"
                                        />
                                    )}
                                />
                                <Controller
                                    name={"district"}
                                    control={control}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <LocationSelect
                                            value={value}
                                            options={districts || []}
                                            error={errors.district?.message}
                                            type={SELECT_TYPES.WITHOUT_LABEL}
                                            onSelect={(district) => {
                                                onChange(district.name);
                                                handleSelectDistrict(district);
                                            }}
                                            placeholder="my_account_district_placeholder"
                                            label="select_district"
                                        />
                                    )}
                                />
                            </Fragment>

                            <MainButton
                                isLoading={isLoading}
                                buttonStyle="btn--green"
                                buttonSize="btn--large"
                                type="submit"
                            >
                                {t("next")}
                            </MainButton>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>
    );
});
SelectLocation.displayName = "SelectLocation";
export default SelectLocation;
