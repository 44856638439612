import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import {
    promotionHistoryStatusesHandler,
    promotionHistoryTitlesConfig,
} from "./promotion-history.configs";
import { promotionSelector } from "../../promotion.selectors";
import { getPromotionHistory } from "../../promotion.operations";
import PromotionEmptyResult from "../promotion-empty-result/promotion-empty-result";
import { getLanguage } from "../../../../helpers/language";

import "./index.scss";

const PromotionHistory = () => {
    const { t } = useTranslation();
    const { value: language } = getLanguage();
    const dispatch = useDispatch();

    const { promotionHistory } = useSelector(promotionSelector);

    useEffect(() => {
        dispatch(getPromotionHistory());
    }, []);

    return (
        <table className="promotion__right-container-history">
            {promotionHistory.items.length ? (
                <tbody>
                    <tr className="promotion__right-container-history-header">
                        {promotionHistoryTitlesConfig.map((title) => (
                            <th
                                className="promotion__right-container-history-header-item"
                                key={title}
                            >
                                {t(title)}
                            </th>
                        ))}
                    </tr>
                    {promotionHistory.items.map(
                        ({ startDate, endDate, active, promotion, _id }) => (
                            <tr
                                key={_id}
                                className="promotion__right-container-history-content"
                            >
                                <td className="promotion__right-container-history-content-item">
                                    {promotion?.nameTranslations[language]}
                                </td>
                                <td className="promotion__right-container-history-content-item">
                                    {dayjs(startDate).format("DD")}.
                                    {dayjs(startDate).format("MM")}.
                                    {dayjs(startDate).format("YYYY")}
                                </td>
                                <td className="promotion__right-container-history-content-item">
                                    {dayjs(endDate).format("DD")}.
                                    {dayjs(endDate).format("MM")}.
                                    {dayjs(endDate).format("YYYY")}
                                </td>
                                <td
                                    className={`promotion__right-container-history-content-item ${
                                        active ? "active" : "disabled"
                                    }`}
                                >
                                    {t(promotionHistoryStatusesHandler(active))}
                                </td>
                            </tr>
                        )
                    )}
                </tbody>
            ) : (
                <PromotionEmptyResult />
            )}
        </table>
    );
};

export default PromotionHistory;
