import React from "react";

import LottieAnimation from "../../../lottie-animation/lottie-animation";
import loader from "../../../../assets/animation/global-loader.json";

import "./index.scss";

const GlobalLoader = () => {
    return (
        <div className="global-loader">
            <LottieAnimation lotti={loader} height={400} width={400} />
        </div>
    );
};

export default GlobalLoader;
