import { Fragment, memo, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Picker } from "emoji-mart";

import { errorToastify } from "../../../../components/toastify/toastify.operations";
import { websocketsSelector } from "../../../../components/websockets/websockets.selectors";
import { myAccountDataSelector } from "../../../my-account/my-account.selectors";
import { ordersSelector } from "../../../orders/orders.selectors";
import { chatsSelector } from "../../../chats/chats.selectors";
import { postChatFile } from "../../../chats/chats.operations";
import { openModal } from "../../../../components/modal/modal.actions";

import { typeOfLazeImage } from "../../../../components/lazy-image/lazyImage.constansts";
import { handleServiceType, generateItems, addEmoji } from "./chat.utils";
import ScrollToTopButton from "../../../../components/scroll-to-top-button/scroll-to-top-button";
import LazyImage from "../../../../components/lazy-image/lazy-image";
import { getPhotoByTypeGender } from "../../../../helpers/get-photo-by-type-gender";
import { Icon } from "../../../../components/image/image";
import { useChat } from "./use-chat";
import {
    selectedAdditionals,
    emogiPickerConfig,
    servicesData,
    accessTaskStatus,
    chatHintMessage,
} from "./chat.config";

import "emoji-mart/css/emoji-mart.css";
import "./index.scss";
import { modalConstants } from "../../../../components/modal/modal.constants";

const Chat = () => {
    const { _id: userId, role } = useSelector(myAccountDataSelector);
    const { onlineParticipants } = useSelector(websocketsSelector);
    const { chat } = useSelector(chatsSelector);
    const {
        orderTask: { status },
    } = useSelector(ordersSelector);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [messagesList, setMessagesList] = useState([]);
    const [scrollsCount, setScrollsCount] = useState(0);
    const [isOpen, setIsOpen] = useState(null);
    const [isScrollButtonActive, setIsScrollButtonActive] = useState(false);
    const chatParent = useRef(null);
    const inputRef = useRef(null);

    const {
        participant,
        messages,
        _id: chatId,
        orderId,
        taskId,
        isDisabled,
    } = chat;

    const { setMessageText, sendMessage, messageText } = useChat(setIsOpen);
    const isOnline = onlineParticipants.includes(participant?._id);

    const scrollHandler = (e) => {
        const bottomPos =
            e.target?.scrollHeight - (e.target?.scrollTop + window.innerHeight);

        if (bottomPos > -350) {
            setIsScrollButtonActive(true);
        } else setIsScrollButtonActive(false);
    };

    useEffect(() => {
        const domNode = chatParent.current;
        if (domNode) {
            domNode.scrollTo({
                top: domNode.scrollHeight,
            });
        }
    }, [messagesList, scrollsCount]);

    useEffect(() => {
        if (messages.items.length > 0) {
            setMessagesList(
                generateItems(
                    messages.items,
                    participant,
                    userId,
                    orderId,
                    taskId,
                    setScrollsCount,
                    messages.carousel
                )
            );
        }
    }, [messages]);
    const handleInputMessage = (e) => {
        if (e.target.value.includes("@")) {
            dispatch(
                openModal({
                    isOpen: true,
                    closeIcon: false,
                    renderType: modalConstants.attention,
                    details: { role },
                })
            );
        }
        setMessageText(e.target.value);
    };

    const handleKeyPress = ({ code }) => {
        if (code === "Enter" && messageText.trim()) return sendMessage();
    };

    const changeIsOpen = (selected) => {
        if (isOpen === selected) return setIsOpen(null);
        setIsOpen(selected);
    };

    const handleAddEmoji = (e) => {
        addEmoji(e, inputRef, messageText, setMessageText);
    };

    const handleAttachFile = (e) => {
        e.preventDefault();
        const reader = new FileReader();
        const file = e.target.files[0];

        if (file) {
            reader.onloadend = () => dispatch(postChatFile(chatId, file));
            reader.readAsDataURL(file);
        }
    };

    const notDisableChat = status !== accessTaskStatus.completed && !isDisabled;
    const isDisableChat = status === accessTaskStatus.completed || isDisabled;

    const renderdditionals = (selected, icon) => {
        switch (selected) {
            case "file":
                return (
                    <Fragment key={selected}>
                        <label
                            className="discussion_work_project_chat_input_services-item"
                            htmlFor={selected}
                        >
                            <Icon type={icon} />
                        </label>
                        <input
                            accept="image/png, image/gif, image/jpeg"
                            onClick={() => setIsOpen(null)}
                            onChange={handleAttachFile}
                            style={{ display: "none" }}
                            id={selected}
                            type="file"
                        />
                    </Fragment>
                );
            case "video":
                return (
                    <Fragment key={selected}>
                        <label
                            className="discussion_work_project_chat_input_services-item"
                            htmlFor={selected}
                        >
                            <Icon type={icon} />
                        </label>
                        <input
                            accept=".mp4"
                            onClick={() => setIsOpen(null)}
                            onChange={handleAttachFile}
                            style={{ display: "none" }}
                            id={selected}
                            type="file"
                        />
                    </Fragment>
                );

            default:
                return (
                    <div
                        className="discussion_work_project_chat_input_services-item"
                        onClick={() => changeIsOpen(selected)}
                        key={selected}
                    >
                        <Icon type={icon} />
                    </div>
                );
        }
    };

    return (
        <div className="discussion_work_project_chat">
            <div className="discussion_work_project_chat__header">
                <div
                    title={isOnline ? t("online") : t("offline")}
                    className="discussion_work_project_chat__header_img-active "
                >
                    <LazyImage
                        image={getPhotoByTypeGender(
                            participant?.avatar,
                            participant?.gender
                        )}
                        type={typeOfLazeImage.avatar}
                        options={{
                            className:
                                "discussion_work_project_chat__header_avatar",
                            alt: "avatar",
                        }}
                    />
                    {isOnline && (
                        <span className="discussion_work_project_chat__header_avatar_online" />
                    )}
                </div>
                <div className="discussion_work_project_chat__header_info">
                    <div className="discussion_work_project_chat__header_title">
                        {participant?.firstName || t("chats.deleted_user")}{" "}
                        {participant?.lastName}
                    </div>
                    <div className="discussion_work_project_chat__header_status">
                        {isOnline ? t("online") : t("offline")}
                    </div>
                </div>
            </div>
            <div
                ref={chatParent}
                onScroll={scrollHandler}
                className="discussion_work_project_chat_body"
                id="discussion_work_project_chat"
            >
                <div className="chat_body_message_system_hint">
                    <div className="chat_body_message_system_hint_text">
                        {t(chatHintMessage.startChat)}
                    </div>
                </div>
                {messages.items.length > 0 && messagesList}
            </div>
            <div className="discussion_work_project_chat_input">
                <ScrollToTopButton
                    type="chat-scroll"
                    chatRef={chatParent}
                    active={isScrollButtonActive}
                />
                <div
                    className={`discussion_work_project_chat_input_inner${
                        notDisableChat &&
                        isOpen &&
                        isOpen !== "file" &&
                        isOpen !== "video"
                            ? "-active"
                            : ""
                    }`}
                >
                    {participant ? (
                        isDisableChat ? (
                            <span className="discussion_work_project_chat_input_inner_chat_finished">
                                {t("chats_item.chat_finished")}
                            </span>
                        ) : (
                            <>
                                {selectedAdditionals.map(({ icon, selected }) =>
                                    renderdditionals(selected, icon)
                                )}
                                <div className="discussion_work_project_chat_input_body">
                                    <input
                                        className="discussion_work_project_chat_input_body_input"
                                        onChange={handleInputMessage}
                                        placeholder={t(
                                            "chats.write_you_message"
                                        )}
                                        onKeyPress={handleKeyPress}
                                        value={messageText}
                                        ref={inputRef}
                                    />
                                    <button
                                        className="discussion_work_project_chat_input_body_img"
                                        disabled={!messageText}
                                        onClick={() =>
                                            messageText.trim() && sendMessage()
                                        }
                                    >
                                        <Icon type="sendMessage" />
                                    </button>
                                </div>
                            </>
                        )
                    ) : (
                        <div className="discussion_work_project_chat_input_body_deleted">
                            <p>
                                {t("chats.cant_send_messege_to_deleted_user")}
                            </p>
                        </div>
                    )}
                </div>
                {isOpen === "service" &&
                    notDisableChat &&
                    servicesData.map(
                        ({ title, icon, typeService, access, roles }) =>
                            roles.includes(role) && (
                                <div
                                    className="discussion_work_project_chat_input_list_item"
                                    onClick={() =>
                                        status === access
                                            ? handleServiceType(
                                                  typeService,
                                                  dispatch,
                                                  {
                                                      orderId,
                                                      taskId,
                                                  },
                                                  role
                                              )
                                            : dispatch(
                                                  errorToastify(
                                                      "toastify.order.status_doesnt_match"
                                                  )
                                              )
                                    }
                                    key={title}
                                >
                                    <div className="discussion_work_project_chat_input_list_item_icon">
                                        {icon}
                                    </div>
                                    <div className="discussion_work_project_chat_input_list_item_title">
                                        {t(title)}
                                    </div>
                                </div>
                            )
                    )}
                {isOpen === "file" && notDisableChat && handleAttachFile()}
                {isOpen === "video" && notDisableChat && handleAttachFile()}
                {isOpen === "emoji" && notDisableChat && (
                    <Picker
                        onSelect={handleAddEmoji}
                        i18n={emogiPickerConfig}
                        native
                        exclude={["flags"]}
                        emojiSize={14}
                        color="#33b378"
                    />
                )}
            </div>
        </div>
    );
};

export default memo(Chat);
