import { errorHandlerTypes } from "../components/error-handler/error-handler.constants";
import { userRoles } from "../constants/user-roles";
import routerBook from "./router-book";

// auth block
import EmailConfirmation from "../pages/auth/email-confirmation/email-confirmation";
import PhoneConfirmation from "../pages/auth/phone-confirmation/phone-confirmation";
import UserTypeDistributor from "../pages/auth/user-types/user-type-distributor";
import ConfirmationMessage from "../pages/auth/confirmation-message/confirmation-message";
import SelectLocation from "../pages/auth/select-location/select-location";
import Registration from "../pages/auth/registration/registration";
import Conditions from "../pages/auth/conditions/conditions";
import Login from "../pages/auth/login/login";
import ForgotPassword from "../pages/auth/forgot-password/forgot-password";
import AuthMain from "../pages/auth/main/main";
import PrivacyPolicy from "../pages/privacy-policy/privacy-policy";
import TermsOfService from "../pages/terms-of-service/terms-of-service";

// pages block
import Blog from "../pages/blog/blog";
import Profile from "../pages/profile/profile";
import CustomerInfo from "../pages/customer-info/customer-info";
import SpecialistInfo from "../pages/specialist-info/specialist-info";
import MyAccount from "../pages/my-account/my-account";
import Orders from "../pages/orders/orders";
import OrderInfo from "../pages/order-info/order-info";
import CreateOrder from "../pages/create-order/create-order";
import AllServices from "../pages/all-services/all-services";
import AllServicesResults from "../pages/all-services-results/all-services-results";
import ProposalsForCooperation from "../pages/proposals-for-cooperation/proposals-for-cooperation.jsx";
import DiscussionWorkProject from "../pages/discussion-work-project/discussion-work-project";
import SpecialistProfile from "../pages/specialist-profile/specialist-profile";
import SpecialistServices from "../pages/specialist-services";
import SpecialistsList from "../pages/specialists-list/specialists-list";
import ErrorHandler from "../components/error-handler/error-handler";
import SpecialistOrders from "../pages/specialist-orders/specialist-orders";
import Chats from "../pages/chats/chats";
import AboutUs from "../pages/about-us/about-us";
import SpecialistNewOrders from "../pages/specialist-new-orders/specialist-new-orders.jsx";
import InfoAboutOrder from "../components/all-result/components/searched-order/components/info-about-order/info-about-order";
import Balance from "../pages/balance/balance";
import Promotion from "../pages/promotion/promotion";
import MyAccountInfoForm from "../pages/my-account-info/components/my-account-info-form/my-account-info-form";
import PromotionMethods from "../pages/promotion/components/promotion-methods/promotion-methods";
import BlogInfo from "../pages/blog-info/blog-info";
import RestoreAccount from "../pages/auth/restore-account/restore-account";
import Favourites from "../pages/favourites/favourites";
import Support from "../pages/support/support";
import SupportChat from "../pages/support/components/support-chat/support-chat";
import FAQ from "../pages/faq/faq";
import Notifications from "../pages/notifications/notifications";

export const privateRoutes = [
    {
        path: `${routerBook.home}`,
        component: <Profile />,
        roles: [userRoles.customer, userRoles.specialist],
    },
    {
        path: `${routerBook.myAccount}`,
        component: <MyAccount />,
        roles: [userRoles.customer, userRoles.specialist],
    },
    {
        path: `${routerBook.myAccountInfo}`,
        component: <MyAccountInfoForm />,
        roles: [userRoles.customer, userRoles.specialist],
    },
    {
        path: `${routerBook.orders}`,
        component: <Orders />,
        roles: [userRoles.customer],
    },
    {
        path: `${routerBook.ordersWithParameter}`,
        component: <Orders />,
        roles: [userRoles.customer],
    },
    {
        path: `${routerBook.orderInfo}`,
        component: <OrderInfo />,
        roles: [userRoles.specialist],
    },
    {
        path: `${routerBook.accessForSpecialist.promotionWithParameterInfo}`,
        component: <PromotionMethods />,
        roles: [userRoles.specialist],
    },
    {
        path: `${routerBook.orderInfoWithParameter}`,
        component: <OrderInfo />,
        roles: [userRoles.specialist],
    },
    {
        path: `${routerBook.createOrder}`,
        component: <CreateOrder />,
        roles: [userRoles.customer],
    },
    {
        path: `${routerBook.allServices}`,
        component: <AllServices />,
        roles: [userRoles.customer, userRoles.specialist],
    },
    {
        path: `${routerBook.allServicesResult}`,
        component: <AllServicesResults />,
        roles: [userRoles.customer],
    },
    {
        path: `${routerBook.specialistProfile}`,
        component: <SpecialistProfile />,
        roles: [userRoles.customer],
    },
    {
        path: `${routerBook.specialistsList}`,
        component: <SpecialistsList />,
        roles: [userRoles.customer],
    },
    {
        path: `${routerBook.proposalsForCooperationItem}`,
        component: <ProposalsForCooperation />,
        roles: [userRoles.customer],
    },
    {
        path: `${routerBook.chatsItem}`,
        component: <DiscussionWorkProject />,
        roles: [userRoles.customer, userRoles.specialist],
    },
    {
        path: `${routerBook.accessForSpecialist.services}`,
        component: <SpecialistServices />,
        roles: [userRoles.specialist],
    },
    {
        path: `${routerBook.accessForSpecialist.orders}`,
        component: <SpecialistOrders />,
        roles: [userRoles.specialist],
    },
    {
        path: `${routerBook.accessForSpecialist.ordersWithParameter}`,
        component: <SpecialistOrders />,
        roles: [userRoles.specialist],
    },
    {
        path: `${routerBook.accessForSpecialist.newOrders}`,
        component: <SpecialistNewOrders />,
        roles: [userRoles.specialist],
    },
    {
        path: `${routerBook.accessForSpecialist.newOrdersItem}`,
        component: <InfoAboutOrder />,
        roles: [userRoles.specialist],
    },
    {
        path: `${routerBook.chats}`,
        component: <Chats />,
        roles: [userRoles.customer, userRoles.specialist],
    },
    {
        path: `${routerBook.balance}`,
        component: <Balance />,
        roles: [userRoles.customer, userRoles.specialist],
    },
    {
        path: `${routerBook.changeLocation}`,
        component: <SelectLocation />,
        roles: [userRoles.customer, userRoles.specialist],
    },
    {
        path: `${routerBook.about}`,
        component: <AboutUs />,
        roles: [userRoles.customer, userRoles.specialist],
    },
    {
        path: `${routerBook.academy}`,
        component: <Blog />,
        roles: [userRoles.customer, userRoles.specialist],
    },
    {
        path: `${routerBook.privacyPolicy}`,
        component: <PrivacyPolicy />,
        roles: [userRoles.customer, userRoles.specialist],
    },
    {
        path: `${routerBook.termsAndConditions}`,
        component: <TermsOfService />,
        roles: [userRoles.customer, userRoles.specialist],
    },
    {
        path: `${routerBook.accessForSpecialist.promotion}`,
        component: <Promotion />,
        roles: [userRoles.specialist],
    },
    {
        path: `${routerBook.accessForSpecialist.promotionWithParameter}`,
        component: <Promotion />,
        roles: [userRoles.specialist],
    },
    {
        path: `${routerBook.academyInfo}`,
        component: <BlogInfo />,
        roles: [userRoles.customer, userRoles.specialist],
    },
    {
        path: `${routerBook.favorites}`,
        component: <Favourites />,
        roles: [userRoles.customer, userRoles.specialist],
    },
    {
        path: `${routerBook.faq}`,
        component: <FAQ />,
        roles: [userRoles.customer, userRoles.specialist],
    },
    {
        path: `${routerBook.support}`,
        component: <Support />,
        roles: [userRoles.customer, userRoles.specialist],
    },
    {
        path: `${routerBook.supportChat}`,
        component: <SupportChat />,
        roles: [userRoles.customer, userRoles.specialist],
    },
    {
        path: `${routerBook.notifications}`,
        component: <Notifications />,
        roles: [userRoles.customer, userRoles.specialist],
    },
    {
        path: `${routerBook.errors.accessDeniedError}`,
        component: <ErrorHandler type={errorHandlerTypes.ACCESS_DENIED} />,
        roles: [userRoles.customer, userRoles.specialist],
    },
    {
        path: `${routerBook.errors.somethingWrong}`,
        component: <ErrorHandler type={errorHandlerTypes.SOMETHING_WRONG} />,
        roles: [userRoles.customer, userRoles.specialist],
    },
    {
        path: `${routerBook.errors.notFound}`,
        component: <ErrorHandler type={errorHandlerTypes.PAGE_NOT_FOUND} />,
        roles: [userRoles.customer, userRoles.specialist],
    },
    {
        path: `${routerBook.auth.confirmationMessage}`,
        component: <ConfirmationMessage />,
        roles: [userRoles.customer, userRoles.specialist],
    },
    {
        path: `${routerBook.auth.confirmationMessageWithToken}`,
        component: <ConfirmationMessage />,
        roles: [userRoles.customer, userRoles.specialist],
    },
    {
        path: `${routerBook.auth.verificationMessageWithToken}`,
        component: <ConfirmationMessage />,
        roles: [userRoles.customer, userRoles.specialist],
    },
    {
        path: "*",
        component: <ErrorHandler type={errorHandlerTypes.ACCESS_DENIED} />,
        roles: [userRoles.customer, userRoles.specialist],
    },
];

export const publicRouter = [
    {
        path: `${routerBook.home}`,
        component: <Profile />,
    },
    {
        path: `${routerBook.auth.main}`,
        component: <AuthMain />,
    },
    {
        path: `${routerBook.employeeInfo}`,
        component: <SpecialistInfo />,
    },
    {
        path: `${routerBook.customerInfo}`,
        component: <CustomerInfo />,
    },
    {
        path: `${routerBook.auth.accountType}`,
        component: <UserTypeDistributor />,
    },
    {
        path: `${routerBook.auth.confirmationMessage}`,
        component: <ConfirmationMessage />,
    },
    {
        path: `${routerBook.auth.confirmationMessageWithToken}`,
        component: <ConfirmationMessage />,
    },
    {
        path: `${routerBook.auth.verificationMessageWithToken}`,
        component: <ConfirmationMessage />,
    },
    {
        path: `${routerBook.auth.emailConfirmation}`,
        component: <EmailConfirmation />,
    },
    {
        path: `${routerBook.auth.phoneConfirmation}`,
        component: <PhoneConfirmation />,
    },
    {
        path: `${routerBook.auth.location}`,
        component: <SelectLocation />,
    },
    {
        path: `${routerBook.auth.register}`,
        component: <Registration />,
    },
    {
        path: `${routerBook.auth.conditions}`,
        component: <Conditions />,
    },
    {
        path: `${routerBook.auth.login}`,
        component: <Login />,
    },
    {
        path: `${routerBook.auth.forgotPassword}`,
        component: <ForgotPassword />,
    },
    {
        path: `${routerBook.auth.resetPassword}`,
        component: <ForgotPassword />,
    },
    {
        path: `${routerBook.privacyPolicy}`,
        component: <PrivacyPolicy />,
    },
    {
        path: `${routerBook.termsAndConditions}`,
        component: <TermsOfService />,
    },
    {
        path: `${routerBook.allServices}`,
        component: <AllServices />,
    },
    {
        path: `${routerBook.allServicesResult}`,
        component: <AllServicesResults />,
    },
    {
        path: `${routerBook.about}`,
        component: <AboutUs />,
    },
    {
        path: `${routerBook.academy}`,
        component: <Blog />,
    },
    {
        path: `${routerBook.academyInfo}`,
        component: <BlogInfo />,
    },
    {
        path: `${routerBook.auth.restoreAccount}`,
        component: <RestoreAccount />,
    },
    {
        path: "*",
        component: <AuthMain />,
    },
];
