import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { Fragment } from "react";

import { backButtonConfig } from "../../components/back-button/back-button.config";
import SupportContent from "./components/support-content/support-content";
import ReactHelmet from "../../components/react-helmet/react-helmet";
import SupportCreateTicket from "./components/support-create-ticket/support-create-ticket";
import BackButton from "../../components/back-button/back-button";

import "./index.scss";

const Support = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <Fragment>
            <ReactHelmet title="nav-support" />
            <div className="support">
                <div className="support__header">
                    <div className="support__header-btn">
                        <BackButton
                            onClickCallback={() =>
                                navigate(
                                    backButtonConfig[location.state].href,
                                    { state: true }
                                )
                            }
                            title={t(backButtonConfig[location.state].title)}
                        />
                    </div>
                    <div className="support__header-content">
                        <span className="support__header-content-title">
                            {t("nav-support")}
                        </span>
                    </div>
                </div>
                <div className="support__body">
                    <div className="support__body-left">
                        <SupportCreateTicket />
                    </div>
                    <div className="support__body-right">
                        <SupportContent />
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Support;
