import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { getCountryCodes } from "./country-codes.operations";
import { countryCodesSelector } from "./country-codes.selectors";
import { openModal } from "../modal/modal.actions";
import { modalConstants } from "../modal/modal.constants";

import { ReactComponent as FlagIcon } from "../../assets/images/registration-icons/tr-flag.svg";
import { ReactComponent as ArrowDownIcon } from "../../assets/images/arrows-icons/arrow-down-filled.svg";

import "./index.scss";

const CountryCodesDropdown = ({
    register = () => {},
    setValue = () => {},
    phoneValueName = "",
    phoneCodeName = "",
    error = "",
    phoneCodeError = "",
    registeredFlag = "",
    registeredDialCode = "",
    withLabel = false,
    fullWidth = false,
    readOnly = false,
    isMyAccountPage = false,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { countryCodesList, countryCode } = useSelector(countryCodesSelector);

    useEffect(() => {
        if (!countryCodesList.items.length) {
            dispatch(getCountryCodes());
        }
    }, []);

    useEffect(() => {
        setValue(phoneCodeName, countryCode?.dialCode);
    }, []);

    const openModalHandler = () => {
        if (!readOnly) {
            dispatch(
                openModal({
                    isOpen: true,
                    closeIcon: false,
                    renderType: modalConstants.countryCodes,
                    details: { phoneCodeName, setValue },
                })
            );
        }
    };

    const renderFlag = () => {
        if (countryCode?.flag) {
            return (
                <img
                    src={countryCode.flag}
                    alt="country flag"
                    className="country-codes__dropdown-flag"
                />
            );
        } else if (registeredFlag) {
            return (
                <img
                    src={registeredFlag}
                    alt="country flag"
                    className="country-codes__dropdown-flag"
                />
            );
        } else
            return (
                <div className="country-codes__dropdown-default-flag">
                    <FlagIcon />
                </div>
            );
    };

    const renderDialCode = () => {
        if (countryCode?.dialCode) {
            return <span>{countryCode.dialCode}</span>;
        } else if (registeredDialCode) {
            return <span>{registeredDialCode}</span>;
        } else {
            return (
                <input
                    {...register(phoneCodeName)}
                    className="country-codes__phone-mask-input"
                    placeholder="+90"
                    readOnly={readOnly}
                />
            );
        }
    };

    return (
        <div className="country-codes">
            {!isMyAccountPage && (
                <div className="country-codes__info">
                    {withLabel && (
                        <label className="country-codes__label">
                            {t("label_phone")}
                        </label>
                    )}
                    <label className="country-codes__error">
                        {error ? t(error) : ""}
                    </label>
                </div>
            )}
            <div className="country-codes__form">
                <div
                    className={`country-codes__dropdown ${
                        fullWidth && "country-codes__dropdown--full-width"
                    } ${readOnly && "country-codes__dropdown--readonly"} ${
                        phoneCodeError &&
                        !registeredDialCode &&
                        !countryCode.dialCode &&
                        "country-codes__dropdown--error"
                    }`}
                    onClick={openModalHandler}
                >
                    {renderFlag()}
                    {renderDialCode()}
                    <ArrowDownIcon />
                </div>
                <input
                    {...register(phoneValueName)}
                    className={`country-codes__phone-value-input ${
                        fullWidth &&
                        "country-codes__phone-value-input--full-width"
                    } ${error && "country-codes__phone-value-input--error"}`}
                    placeholder="00 000 00 00"
                    disabled={readOnly}
                />
            </div>
        </div>
    );
};

CountryCodesDropdown.propTypes = {
    register: PropTypes.func,
    setValue: PropTypes.func,
    phoneValueName: PropTypes.string,
    phoneCodeName: PropTypes.string,
    error: PropTypes.string,
    phoneCodeError: PropTypes.string,
    registeredFlag: PropTypes.string,
    registeredDialCode: PropTypes.string,
    withLabel: PropTypes.bool,
    fullWidth: PropTypes.bool,
    readOnly: PropTypes.bool,
    isMyAccountPage: PropTypes.bool,
};

export default CountryCodesDropdown;
