import { blogActionTypes } from "./blog.types";

// get blog
export const getBlogListRequest = () => ({
    type: blogActionTypes.GET_BLOG_LIST_REQUEST,
});
export const getBlogListSucces = (data) => ({
    type: blogActionTypes.GET_BLOG_LIST_SUCCESS,
    payload: data,
});
export const getBlogListError = (error) => ({
    type: blogActionTypes.GET_BLOG_LIST_ERROR,
    payload: error,
});

// get blog by id
export const getBlogRequest = () => ({
    type: blogActionTypes.GET_BLOG_REQUEST,
});
export const getBlogSucces = (data) => ({
    type: blogActionTypes.GET_BLOG_SUCCESS,
    payload: data,
});
export const getBlogError = (error) => ({
    type: blogActionTypes.GET_BLOG_ERROR,
    payload: error,
});

// set
export const clearAllParams = () => ({
    type: blogActionTypes.CLEAR_ALL_PARAMS,
});
export const setPage = (data) => ({
    type: blogActionTypes.SET_PAGE,
    payload: data,
});
export const setSearchQuery = (data) => ({
    type: blogActionTypes.SET_SEARCH_QUERY,
    payload: data,
});
