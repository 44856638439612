import { faqTypes } from "./faq.types";

// get all FAQ
export const getAllFAQPending = () => ({
    type: faqTypes.GET_ALL_FAQ_PENDING,
});

export const getAllFAQFulfilled = (data) => ({
    type: faqTypes.GET_ALL_FAQ_FULFILLED,
    payload: data,
});

export const getAllFAQRejected = (error) => ({
    type: faqTypes.GET_ALL_FAQ_REJECTED,
    payload: error,
});

// set
export const clearAllParams = () => ({
    type: faqTypes.CLEAR_ALL_PARAMS,
});

export const setPage = (data) => ({
    type: faqTypes.SET_PAGE,
    payload: data,
});
