import PropTypes from "prop-types";

import ButtonLoader from "../../components/loader/components/button-loader/button-loader";

import "./index.scss";

const STYLES = [
    "btn--green",
    "btn--light",
    "btn--gray",
    "btn--white",
    "btn--dark",
];
const SIZES = ["btn--medium", "btn--large", "btn--stretch"];

const MainButton = ({
    onClick,
    children,
    type,
    buttonStyle,
    buttonSize,
    inlineStyle,
    isLoading = false,
    disabled = false,
}) => {
    const checkButtonStyle = STYLES.includes(buttonStyle)
        ? buttonStyle
        : STYLES[0];
    const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : STYLES[0];

    return (
        <button
            style={{ ...inlineStyle, background: isLoading && "#dfdfdf" }}
            className={`btn ${checkButtonStyle} ${checkButtonSize}`}
            onClick={onClick}
            type={type}
            disabled={isLoading || disabled}
        >
            {isLoading ? <ButtonLoader size={38} /> : children}
        </button>
    );
};

MainButton.propTypes = {
    onClick: PropTypes.func,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    type: PropTypes.string,
    buttonStyle: PropTypes.string,
    buttonSize: PropTypes.string,
    inlineStyle: PropTypes.object,
    isLoading: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default MainButton;
