import { genderTypes } from "../constants/genders";

import avatarMale from "../assets/images/specialists/male.png";
import avatarFemale from "../assets/images/specialists/female.png";
import avatarUndefined from "../assets/images/specialists/undefined.png";

export const getPhotoByTypeGender = (avatar, gender) => {
    if (avatar) return avatar;
    if (gender === genderTypes.male) return avatarMale;
    if (gender === genderTypes.female) return avatarFemale;
    if (!avatar) return avatarUndefined;
};
