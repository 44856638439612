import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import { ORDERS_FILTERS_SPECIALIST } from "../../specialist-orders.config";
import rouerBook from "../../../../router/router-book";

import "./index.scss";

const SpecialistOrderStatus = ({ filterParameter = "" }) => {
    const { t } = useTranslation();

    const setActiveClassName = (filter) => {
        if (filterParameter === filter) return `--${filter}`;
        return "";
    };

    return (
        <aside className="specialist_orders_sidebar">
            {Object.values(ORDERS_FILTERS_SPECIALIST).map((filter) => {
                if (filter === ORDERS_FILTERS_SPECIALIST.LINE) {
                    return (
                        <div
                            key={filter}
                            className="specialist_orders_sidebar__line"
                        />
                    );
                } else {
                    return (
                        <Link
                            className={`specialist_orders_sidebar__item${setActiveClassName(
                                filter
                            )}`}
                            to={`${rouerBook.accessForSpecialist.orders}/${filter}`}
                            key={filter}
                        >
                            <span className="specialist_orders_sidebar__item__mark" />
                            {t(`orders.navigation-sidebar.${filter}`)}
                        </Link>
                    );
                }
            })}
        </aside>
    );
};

SpecialistOrderStatus.propTypes = { filterParameter: PropTypes.string };

export default SpecialistOrderStatus;
