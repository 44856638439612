import { t } from "i18next";

import { convertScheduleInputValuesToTimeString } from "../../../../components/input/schedule-input/schedule-input.utils";
import { getLanguage } from "../../../../helpers/language";

export const convertSpecialistServiceDataToRequestSchema = ({ ...rest }) => {
    const updatedSpecialistServiceData = {
        ...rest,
        categoryId: rest.categoryId.value,
        clarificationId: rest.clarificationId.value,
        subcategoryId: rest.subcategoryId.value,
        schedule: convertScheduleInputValuesToTimeString(rest.schedule),
    };

    return updatedSpecialistServiceData;
};

export const convertServiceForDefaultValues = (service) => {
    if (service) {
        const { _id = "", titleTranslations } = service;
        const { value: language } = getLanguage();

        if (titleTranslations) {
            return { label: titleTranslations[language], value: _id };
        }

        return {
            label: service?.title || service?.remark || t("removed"),
            value: _id,
        };
    }
};
