import { ordersSpecialistTypes } from "./specialist-orders.types";

const initialQueryParams = {
    status: "&statuses=NEGOTIATION",
    limit: 10,
    page: 0,
};

const initialState = {
    params: initialQueryParams,

    orders: { items: [], totalCount: 0 },
    order: {},

    loading: false,
    error: "",
};

export const specialistOrders = (state = initialState, { type, payload }) => {
    switch (type) {
        // params
        case ordersSpecialistTypes.SET_STATUS:
            return {
                ...state,
                params: { ...state.params, status: payload, page: 0 },
            };
        case ordersSpecialistTypes.SET_PAGE:
            return { ...state, params: { ...state.params, page: payload } };

        case ordersSpecialistTypes.CLEAR_ALL_PARAMS:
            return { ...state, params: initialQueryParams };

        // loading
        case ordersSpecialistTypes.GET_ORDER_PENDING:
        case ordersSpecialistTypes.GET_ALL_ORDERS_PENDING: {
            return { ...state, loading: true };
        }

        //order
        case ordersSpecialistTypes.GET_ORDER_FULFILLED: {
            return { ...state, loading: false, order: payload };
        }

        // data all
        case ordersSpecialistTypes.GET_ALL_ORDERS_FULFILLED: {
            return { ...state, loading: false, orders: payload };
        }

        // error
        case ordersSpecialistTypes.GET_ORDER_REJECTED:
        case ordersSpecialistTypes.GET_ALL_ORDERS_REJECTED: {
            return { ...state, loading: false, error: payload };
        }

        default:
            return state;
    }
};
