import React, { memo, useState } from "react";
import PropTypes from "prop-types";

import SELECT_TYPES from "../../../select/select.constants";
import Select from "../../../select/select";

import "./index.scss";

const Sort = ({ sortConfig }) => {
    const [category, setCategory] = useState(sortConfig[0]);

    return (
        <div className="all-result-sort">
            <Select
                onSelect={(category) => setCategory(category)}
                type={SELECT_TYPES.WITH_LABEL}
                options={sortConfig}
                value={category}
            />
        </div>
    );
};

Sort.propTypes = { sortConfig: PropTypes.array };
export default memo(Sort);
