import { specialistTypes } from "./specialist-list.types";

const initialQueryParams = { limit: 5, page: 0 };

const initialState = {
    specialistList: { items: [], count: 0 },
    params: initialQueryParams,
    selectedSpecialist: null,
    loading: false,
    error: "",
};

export const specialist = (state = initialState, { type, payload }) => {
    switch (type) {
        // loading
        case specialistTypes.GET_SPECIALIST_LIST_PENDING: {
            return { ...state, loading: true };
        }

        // data specialist list
        case specialistTypes.GET_SPECIALIST_LIST_FULFILLED: {
            return { ...state, loading: false, specialistList: payload };
        }

        // data specialist
        case specialistTypes.GET_SPECIALIST_PROFILE_BY_ID_FULFILLED: {
            return { ...state, loading: false, selectedSpecialist: payload };
        }

        // error
        case specialistTypes.GET_SPECIALIST_PROFILE_BY_ID_REJECTED:
        case specialistTypes.GET_SPECIALIST_LIST_REJECTED: {
            return { ...state, loading: false, error: payload };
        }

        // set
        case specialistTypes.CLEAR_SELECTED_SPECIALIST: {
            return { ...state, selectedSpecialist: null };
        }
        case specialistTypes.CLEAR_ALL_PARAMS:
            return { ...state, params: initialQueryParams };

        case specialistTypes.SET_PAGE:
            return { ...state, params: { ...state.params, page: payload } };

        case specialistTypes.SPECIALIST_IS_NOT_DEFINED:
            return { ...state, selectedSpecialist: null };

        default:
            return state;
    }
};
