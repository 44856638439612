import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import "dayjs/locale/tr";
import "dayjs/locale/ru";
import "dayjs/locale/en";

import { typeOfLazeImage } from "../../../../components/lazy-image/lazyImage.constansts";
import MainButton from "../../../../components/main-button/main-button";
import LazyImage from "../../../../components/lazy-image/lazy-image";
import { getPhotoByTypeGender } from "../../../../helpers/get-photo-by-type-gender";
import { getLanguage } from "../../../../helpers/language";
import Rating from "../../../../components/rating/rating";
import { Icon } from "../../../../components/image/image";

import "./index.scss";

const ReviewItem = ({ feedback }) => {
    const { value: language } = getLanguage();
    const { t } = useTranslation();

    return (
        <div className="review-item">
            <LazyImage
                image={getPhotoByTypeGender(
                    feedback.reporter.avatar,
                    feedback.reporter.gender
                )}
                type={typeOfLazeImage.avatar}
                options={{
                    className: "review-item__avatar",
                    alt: "avatar",
                }}
            />
            <div className="review-item__content">
                <div className="review-item__content__header">
                    <p className="review-item__content__header__name">
                        {`${feedback.reporter.firstName} ${feedback.reporter.lastName}`}
                    </p>
                    <p className="review-item__content__header__date">
                        {dayjs(feedback.createdAt)
                            .locale(language || "en")
                            .format("DD MMMM YYYY")}
                    </p>
                </div>
                <Rating rating={feedback.assessment} />
                <p className="review-item__content__feedback">
                    {feedback.text}
                </p>
                <p className="review-item__content__order">
                    {t("specialist-profile.reviews.order-category")}
                    <span className="review-item__content__order__category">
                        {feedback.subcategoryTitle}
                    </span>
                </p>
            </div>
        </div>
    );
};

ReviewItem.propTypes = { feedback: PropTypes.object };

const Reviews = ({ feedbacks }) => {
    const { t } = useTranslation();
    const [isFullSize, setIsFullSize] = useState(false);

    return (
        <div
            className={`reviews${
                !feedbacks.length ? "--empty" : isFullSize ? "--full-size" : ""
            }`}
        >
            <p className="reviews__title">
                {t("specialist-profile.reviews.title")}
                <span className="reviews__counter">{feedbacks.length}</span>
            </p>
            {feedbacks.length ? (
                <>
                    {feedbacks.map((item, index) => {
                        if (!index && !isFullSize) {
                            return (
                                <div key={item._id}>
                                    <ReviewItem feedback={item} />
                                    <div className="reviews__see-more-button-container">
                                        <MainButton
                                            disabled={feedbacks.length < 2}
                                            buttonStyle="btn--gray"
                                            buttonSize="btn--medium"
                                            onClick={() => setIsFullSize(true)}
                                        >
                                            <span className="reviews__see-more-button">
                                                {t(
                                                    "specialist-profile.reviews.see-more-button"
                                                )}
                                            </span>
                                        </MainButton>
                                    </div>
                                </div>
                            );
                        }
                        return <ReviewItem key={item._id} feedback={item} />;
                    })}
                </>
            ) : (
                <div className="reviews__no-reviews">
                    <Icon type="noReviews" />
                    <p className="reviews__no-reviews__title">
                        {t("specialist-profile.reviews.empty.title")}
                    </p>
                </div>
            )}
        </div>
    );
};

Reviews.propTypes = { feedbacks: PropTypes.array };

export default Reviews;
