export const replaceValue = (text, value, href) => {
    if (!value) return text;

    return text.split(value).reduce((prev, current, item) => {
        if (!item) return [current];

        return prev.concat(
            <a
                className="privacy-policy__link"
                key={value + current}
                rel="noreferrer"
                target="_blank"
                href={href}
            >
                {value}
            </a>,
            current
        );
    }, []);
};
