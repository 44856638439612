import { sizeItemOnPage } from "./all-result.constant";
import { allResultTypes } from "./all-result.types";

const initialQueryParams = {
    limit: sizeItemOnPage,
    clarifications: "",
    subcategories: "",
    categories: "",
    district: {},
    text: "",
    city: { name: "", state_code: "" },
    page: 0,
};

const initialState = {
    services: { items: [], count: 0, countByText: 0 },
    orders: { count: 0, items: [], countByText: 0 },
    params: initialQueryParams,
    tags: [],

    loading: false,
    error: "",
};

export const allResult = (state = initialState, { type, payload }) => {
    switch (type) {
        // set
        case allResultTypes.SET_CLARIFICATION:
            return {
                ...state,
                params: { ...state.params, clarifications: payload, page: 0 },
            };
        case allResultTypes.SET_SUBCATEGORY:
            return {
                ...state,
                params: { ...state.params, subcategories: payload, page: 0 },
            };
        case allResultTypes.SET_CATEGORY:
            return {
                ...state,
                params: { ...state.params, categories: payload, page: 0 },
            };
        case allResultTypes.SET_PAGE:
            return { ...state, params: { ...state.params, page: payload } };
        case allResultTypes.SET_CITY:
            return {
                ...state,
                params: { ...state.params, city: payload, district: {} },
            };
        case allResultTypes.SET_DISTRICT:
            return { ...state, params: { ...state.params, district: payload } };
        case allResultTypes.SET_LABEL:
            return { ...state, tags: payload };
        case allResultTypes.CLEAR_ALL_TAGS:
            return { ...state, tags: initialState.tags };
        case allResultTypes.CLEAR_ALL_PARAMS:
            return {
                ...state,
                tags: initialState.tags,
                params: initialQueryParams,
            };
        case allResultTypes.SET_SEARCH_QUERY:
            return {
                ...state,
                params: { ...state.params, text: payload, page: 0 },
            };

        // loading
        case allResultTypes.GET_SERVICES_RESULT_PENDING:
        case allResultTypes.GET_ORDERS_RESULT_PENDING:
        case allResultTypes.GET_ORDER_PENDING: {
            return { ...state, loading: true };
        }

        // data services
        case allResultTypes.GET_SERVICES_RESULT_FULFILLED: {
            return { ...state, loading: false, services: payload };
        }

        // data orders
        case allResultTypes.GET_ORDERS_RESULT_FULFILLED: {
            return { ...state, loading: false, orders: payload };
        }

        // error
        case allResultTypes.GET_SERVICES_RESULT_REJECTED:
        case allResultTypes.GET_ORDERS_RESULT_REJECTED:
        case allResultTypes.GET_ORDER_REJECTED: {
            return { ...state, loading: false, error: payload };
        }

        default:
            return state;
    }
};
