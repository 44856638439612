import { languageList } from "../../../helpers/language";

const daysTr = ["Pz", "Pt", "Sa", "Ça", "Pe", "Cu", "Ct"];
const monthsTr = [
    "Ocak",
    "Şubat",
    "Mart",
    "Nisan",
    "Mayıs",
    "Haziran",
    "Temmuz",
    "Ağustos",
    "Eylül",
    "Ekim",
    "Kasım",
    "Aralık",
];

const daysRu = ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"];
const monthsRu = [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
];

const daysEn = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
const monthsEn = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export const selectedPickerLanguage = {
    [languageList.ru.value]: {
        localize: {
            day: (n) => daysRu[n],
            month: (n) => monthsRu[n],
        },
        formatLong: {
            date: () => "mm/dd/yyyy",
        },
    },
    [languageList.tr.value]: {
        localize: {
            day: (n) => daysTr[n],
            month: (n) => monthsTr[n],
        },
        formatLong: {
            date: () => "mm/dd/yyyy",
        },
    },
    [languageList.en.value]: {
        localize: {
            day: (n) => daysEn[n],
            month: (n) => monthsEn[n],
        },
        formatLong: {
            date: () => "mm/dd/yyyy",
        },
    },
};
