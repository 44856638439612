import { useTranslation } from "react-i18next";

import StageItem from "./stage-item/stage-item";
import { stages } from "./stages.config";

import "./index.scss";

const StagesWork = () => {
    const { t } = useTranslation();

    return (
        <section className="stages_work">
            <div className="stages_work__content">
                <div className="stages_work__header">
                    <h2 className="stages_work__title">
                        {t("profile_stages_work_title")}
                    </h2>
                    <div className="stages_work__subtitle">
                        {t("profile_stages_work_subtitle")}
                    </div>
                </div>
                <div className="stages_work__items">
                    {stages.map((stage) => (
                        <StageItem key={stage.number} {...stage} />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default StagesWork;
