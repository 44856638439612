import { object, string } from "yup";
import { t } from "i18next";

import { passwordValidation } from "../../../../../constants/input-validation";

export const forgotPasswordConfirmSchema = object().shape({
    newPassword: string()
        .required("validation_required")
        .min(8, ({ min }) => t("validation_min", { min }))
        .matches(passwordValidation, t("validation_password_required_symbols")),
    confirmNewPassword: string()
        .required("validation_required")
        .test("len", "validation_same_password", (val1, { parent }) => {
            return parent.newPassword === parent.confirmNewPassword;
        })
        .matches(passwordValidation, t("validation_password_required_symbols")),
});
