export const isEmptyObject = (obj = {}) =>
    Object.entries(obj).length === 0 && obj.constructor === Object;

export const formingAnObjectForSendingForm = (
    formData = {},
    changedFields = []
) => {
    const newData = { ...formData };
    const list = Object.keys(changedFields);

    Object.keys(formData).forEach((item) => {
        if (!list.includes(item)) {
            if (item === "priceFrom") {
                return;
            }
            delete newData[item];
        }
    });

    return newData;
};
