import { authTypes } from "./auth.types";

// common
export const setRoleAction = (payload) => ({
    type: authTypes.SET_ROLE,
    payload,
});

export const setEmailAction = (payload) => ({
    type: authTypes.SET_EMAIL,
    payload,
});

export const setEmailErrorAction = (payload) => ({
    type: authTypes.SET_EMAIL_ERROR,
    payload,
});

export const setCityAction = (payload) => ({
    type: authTypes.SET_CITY,
    payload,
});

export const setDistrictAction = (payload) => ({
    type: authTypes.SET_DISTRICT,
    payload,
});

export const setUserDataAction = (payload) => ({
    type: authTypes.SET_USER_DATA,
    payload,
});
export const setAvatarUserAction = (payload) => ({
    type: authTypes.SET_AVATAR_USER,
    payload,
});
export const setConditionsUserAction = (payload) => ({
    type: authTypes.SET_CONDITIONS_USER,
    payload,
});
export const setOauthTokenAction = (payload) => ({
    type: authTypes.SET_OAUTH_TOKEN,
    payload,
});
export const setRegisteredEmailAction = (payload) => ({
    type: authTypes.SET_REGISTERED_EMAIL,
    payload,
});
export const setRegisteredPhoneAction = (payload) => ({
    type: authTypes.SET_REGISTERED_PHONE,
    payload,
});

// login
export const sendLoginFormRequest = () => ({
    type: authTypes.SEND_LOGIN_FORM_REQUEST,
});

export const sendLoginFormSuccess = (payload) => ({
    type: authTypes.SEND_LOGIN_FORM_SUCCESS,
    payload,
});

export const sendLoginFormError = (payload) => ({
    type: authTypes.SEND_LOGIN_FORM_ERROR,
    payload,
});

// oauth
export const sendOauthRequest = () => ({
    type: authTypes.SEND_OAUTH_REQUEST,
});

export const sendOauthSuccess = (payload) => ({
    type: authTypes.SEND_OAUTH_SUCCESS,
    payload,
});

export const sendOauthError = (payload) => ({
    type: authTypes.SEND_OAUTH_ERROR,
    payload,
});

// registration
export const sendRegistrationFormRequest = () => ({
    type: authTypes.SEND_REGISTRATION_FORM_REQUEST,
});

export const sendRegistrationFormSuccess = (payload) => ({
    type: authTypes.SEND_REGISTRATION_FORM_SUCCESS,
    payload,
});

export const sendRegistrationFormError = (payload) => ({
    type: authTypes.SEND_REGISTRATION_FORM_ERROR,
    payload,
});

// oauth registration
export const sendOauthRegistrationRequest = () => ({
    type: authTypes.SEND_OAUTH_REGISTRATION_REQUEST,
});

export const sendOauthRegistrationSuccess = (payload) => ({
    type: authTypes.SEND_OAUTH_REGISTRATION_SUCCESS,
    payload,
});

export const sendOauthRegistrationError = (payload) => ({
    type: authTypes.SEND_OAUTH_REGISTRATION_ERROR,
    payload,
});

// forgot password
export const setForgotPasswordStep = (payload) => ({
    type: authTypes.SET_FORGOT_PASSWORD_STEP,
    payload,
});

export const forgotPasswordRequest = () => ({
    type: authTypes.FORGOT_PASSWORD_REQUEST,
});

export const forgotPasswordSuccess = (payload) => ({
    type: authTypes.FORGOT_PASSWORD_SUCCESS,
    payload,
});

export const forgotPasswordError = (payload) => ({
    type: authTypes.FORGOT_PASSWORD_ERROR,
    payload,
});

export const resetPasswordRequest = () => ({
    type: authTypes.RESET_PASSWORD_REQUEST,
});

export const resetPasswordSuccess = (payload) => ({
    type: authTypes.RESET_PASSWORD_SUCCESS,
    payload,
});

export const resetPasswordError = (payload) => ({
    type: authTypes.RESET_PASSWORD_ERROR,
    payload,
});

// email verify
export const sendEmailVerifyRequest = () => ({
    type: authTypes.SEND_EMAIL_VERIFY_REQUEST,
});

export const sendEmailVerifySuccess = (payload) => ({
    type: authTypes.SEND_EMAIL_VERIFY_SUCCESS,
    payload,
});

export const sendEmailVerifyError = (payload) => ({
    type: authTypes.SEND_EMAIL_VERIFY_ERROR,
    payload,
});

// email confirmation
export const sendEmailConfirmationRequest = () => ({
    type: authTypes.SEND_EMAIL_CONFIRMATION_REQUEST,
});

export const sendEmailConfirmationSuccess = (payload) => ({
    type: authTypes.SEND_EMAIL_CONFIRMATION_SUCCESS,
    payload,
});

export const sendEmailConfirmationError = (payload) => ({
    type: authTypes.SEND_EMAIL_CONFIRMATION_ERROR,
    payload,
});

// phone verify
export const sendPhoneVerifyRequest = () => ({
    type: authTypes.SEND_PHONE_VERIFY_REQUEST,
});

export const sendPhoneVerifySuccess = (payload) => ({
    type: authTypes.SEND_PHONE_VERIFY_SUCCESS,
    payload,
});

export const sendPhoneVerifyError = (payload) => ({
    type: authTypes.SEND_PHONE_VERIFY_ERROR,
    payload,
});

// phone confirmation
export const sendPhoneConfirmationRequest = () => ({
    type: authTypes.SEND_PHONE_CONFIRMATION_REQUEST,
});

export const sendPhoneConfirmationSuccess = (payload) => ({
    type: authTypes.SEND_PHONE_CONFIRMATION_SUCCESS,
    payload,
});

export const sendPhoneConfirmationError = (payload) => ({
    type: authTypes.SEND_PHONE_CONFIRMATION_ERROR,
    payload,
});

// update avatar
export const postAvatarRequest = () => ({
    type: authTypes.POST_AVATAR_REQUEST,
});

export const postAvatarSuccess = (data) => ({
    type: authTypes.POST_AVATAR_SUCCESS,
    payload: data,
});

export const postAvatarError = (error) => ({
    type: authTypes.POST_AVATAR_ERROR,
    payload: error,
});

//tokens
export const updateTokens = (data) => ({
    type: authTypes.UPDATE_TOKENS,
    payload: data,
});

// log out
export const logoutUser = () => ({
    type: authTypes.LOGOUT_USER,
});

// restore account
export const setRestoreAccountEmail = (payload) => ({
    type: authTypes.SET_RESTORE_ACCOUNT_EMAIL,
    payload,
});

export const restoreEmailRequest = () => ({
    type: authTypes.RESTORE_EMAIL_REQUEST,
});

export const restoreEmailSuccess = (payload) => ({
    type: authTypes.RESTORE_EMAIL_SUCCESS,
    payload,
});

export const restoreEmailError = (payload) => ({
    type: authTypes.RESTORE_EMAIL_ERROR,
    payload,
});

export const restoreAccountRequest = () => ({
    type: authTypes.RESTORE_ACCOUNT_REQUEST,
});

export const restoreAccountSuccess = (payload) => ({
    type: authTypes.RESTORE_ACCOUNT_SUCCESS,
    payload,
});

export const restoreAccountError = (payload) => ({
    type: authTypes.RESTORE_ACCOUNT_ERROR,
    payload,
});

// restore account by phone
export const setRestoreAccountPhone = (payload) => ({
    type: authTypes.SET_RESTORE_ACCOUNT_PHONE,
    payload,
});

export const restorePhoneRequest = () => ({
    type: authTypes.RESTORE_PHONE_REQUEST,
});

export const restorePhoneSuccess = (payload) => ({
    type: authTypes.RESTORE_PHONE_SUCCESS,
    payload,
});

export const restorePhoneError = (payload) => ({
    type: authTypes.RESTORE_PHONE_ERROR,
    payload,
});

// restore by oauth
export const setFacebookToken = (payload) => ({
    type: authTypes.SET_FACEBOOK_TOKEN,
    payload,
});

export const sendRestoreByOauthRequest = () => ({
    type: authTypes.SEND_RESTORE_BY_OAUTH_REQUEST,
});

export const sendRestoreByOauthSuccess = (payload) => ({
    type: authTypes.SEND_RESTORE_BY_OAUTH_SUCCESS,
    payload,
});

export const sendRestoreByOauthError = (payload) => ({
    type: authTypes.SEND_RESTORE_BY_OAUTH_ERROR,
    payload,
});
