import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";

import { websocketsSelector } from "../../../../components/websockets/websockets.selectors";
import { chatsSelector } from "../../../chats/chats.selectors";
import {
    editChat,
    editOperations,
    readMessagesOperation,
    updateReadByMessages,
} from "../../../chats/chats.operations";

import { socketEvents } from "../../../../components/websockets/websockets.constants";
import { openModal } from "../../../../components/modal/modal.actions";
import { modalConstants } from "../../../../components/modal/modal.constants";
import { myAccountDataSelector } from "../../../my-account/my-account.selectors";

export const useChat = (setIsOpen) => {
    const { socket } = useSelector(websocketsSelector);
    const { role } = useSelector(myAccountDataSelector);
    const {
        chat: { _id: chatId },
    } = useSelector(chatsSelector);
    const dispatch = useDispatch();

    const [messageText, setMessageText] = useState("");

    useEffect(() => {
        if (socket) {
            socket.on(socketEvents.RECEIVE_MESSAGE, (message) => {
                dispatch(editChat(message));
                dispatch(readMessagesOperation());
            });
            socket.on(socketEvents.MESSAGES_WERE_READ, ({ messageIds }) => {
                dispatch(updateReadByMessages(messageIds));
            });
            socket.on(socketEvents.OPERATION_HISTORY, (operation) => {
                dispatch(editOperations(operation));
            });
        }

        return () => {
            if (socket && socket.off) {
                socket.off(socketEvents.RECEIVE_MESSAGE);
                socket.off(socketEvents.MESSAGES_WERE_READ);
                socket.off(socketEvents.OPERATION_HISTORY);
            }
        };
    }, [socket]);

    useEffect(() => {
        if (chatId) {
            dispatch(readMessagesOperation());
            setMessageText("");
            setIsOpen(null);
        }
    }, [chatId]);

    const sendMessage = () => {
        const checkNumber = (string = "") => {
            let numericList = string
                .split("")
                .filter((letter) => "+0123456789".includes(letter));

            if (numericList.length > 7) return true;
            return false;
        };

        if (messageText.includes("@") || checkNumber(messageText)) {
            dispatch(
                openModal({
                    isOpen: true,
                    closeIcon: false,
                    renderType: modalConstants.attention,
                    details: { role },
                })
            );
            return setMessageText("");
        }

        socket.emit(socketEvents.SEND_MESSAGE, { chatId, messageText });
        setMessageText("");
    };

    return { sendMessage, messageText, setMessageText };
};
