import { blogActionTypes } from "./blog.types";
import { sizeItemOnPage } from "./blog.constants";

const initialQueryParams = {
    limit: sizeItemOnPage,
    page: 0,
    text: "",
};

const initialState = {
    blogList: {
        items: [],
        count: 0,
    },
    blogInfo: {},
    params: initialQueryParams,
    loading: false,
    error: "",
};

export const blog = (state = initialState, { type, payload }) => {
    switch (type) {
        // clear all params
        case blogActionTypes.CLEAR_ALL_PARAMS:
            return { ...state, params: initialQueryParams };

        // set page
        case blogActionTypes.SET_PAGE:
            return {
                ...state,
                params: { ...state.params, page: payload },
            };
        case blogActionTypes.SET_SEARCH_QUERY:
            return {
                ...state,
                params: { ...state.params, text: payload, page: 0 },
            };

        case blogActionTypes.GET_BLOG_LIST_REQUEST:
        case blogActionTypes.GET_BLOG_REQUEST: {
            return { ...state, loading: true };
        }

        case blogActionTypes.GET_BLOG_LIST_SUCCESS: {
            return {
                ...state,
                loading: false,
                blogList: {
                    ...payload,
                    count: payload.totalCount,
                },
            };
        }

        case blogActionTypes.GET_BLOG_SUCCESS: {
            return { ...state, loading: false, blogInfo: payload };
        }

        case blogActionTypes.GET_BLOG_LIST_ERROR:
        case blogActionTypes.GET_BLOG_ERROR: {
            return { ...state, loading: false, error: payload };
        }

        default:
            return state;
    }
};
