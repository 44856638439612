import * as api from "../../services/requests/promotion";

import { successToastify } from "../../components/toastify/toastify.operations";
import { promotionParamsSelector } from "./promotion.selectors";
import { closeModal } from "../../components/modal/modal.actions";
import * as promotionActions from "./promotion.actions";
import {
    changeButtonLoader,
    changeGlobalLoader,
} from "../../components/loader/loader.actions";

import {
    sizeItemOnPage,
    sizeHistoryItemOnPage,
    sizeActivePromotionItemOnPage,
} from "./promotion.constants";
import { paymentTypes } from "../balance/balance.constants";

// get promotion
export const getPromotion =
    (size = sizeItemOnPage, params = "") =>
    (dispatch, getState) => {
        dispatch(promotionActions.getPromotionPending());
        dispatch(changeGlobalLoader(true));

        const { name } = promotionParamsSelector(getState());

        return api
            .getPromotionRequest(`?limit=${size}` + name + params)
            .then(({ promotionTypes: items, totalCount: count }) => {
                dispatch(
                    promotionActions.getPromotionFulfilled({
                        items,
                        count,
                    })
                );
            })
            .catch((error) => {
                dispatch(promotionActions.getPromotionRejected(error));
            })
            .finally(() => dispatch(changeGlobalLoader(false)));
    };

// get promotion by id
export const getPromotionById = (id) => (dispatch) => {
    dispatch(promotionActions.getPromotionByIdPending());
    dispatch(changeGlobalLoader(true));

    return api
        .getPromotionByIdRequest(id)
        .then((response) => {
            dispatch(promotionActions.getPromotionByIdFulfilled(response));
        })
        .catch((error) => {
            dispatch(promotionActions.getPromotionByIdRejected(error));
        })
        .finally(() => dispatch(changeGlobalLoader(false)));
};

// buy promotion
export const buyPromotionById =
    ({ _id, priceCount, priceType, callbackUrl, specialistId }, source) =>
    (dispatch) => {
        dispatch(changeButtonLoader({ status: true, button: "buy-promotion" }));
        dispatch(promotionActions.buyPromotionPending());

        const { stripe, iyzico } = paymentTypes;

        const actionAfterPaid = ({ sessionUrl, message, checkoutUrl }) => {
            if (source === iyzico.value) window.open(checkoutUrl, "_self");
            if (source === stripe.value) window.open(sessionUrl, "_self");
            if (source === "balance") dispatch(successToastify(message));
            dispatch(closeModal());
        };

        let newData = {
            callbackUrl,
            source: "payment",
            priceCount,
            priceType,
            payload: {
                userId: specialistId,
            },
        };
        if (source === iyzico.value)
            newData = { ...newData, system: "iyizico" };
        if (source === stripe.value) newData = { ...newData, system: "stripe" };
        if (source === "balance") newData = { ...newData, source: "balance" };

        return api
            .buyPromotionByIdRequest(_id, newData)
            .then((response) => {
                actionAfterPaid(response);
            })
            .catch((error) => {
                dispatch(promotionActions.buyPromotionRejected(error));
            })
            .finally(() => {
                dispatch(changeButtonLoader({ status: false, button: "" }));
            });
    };

// get promotion history
export const getPromotionHistory =
    (size = sizeHistoryItemOnPage, params = "") =>
    (dispatch) => {
        dispatch(promotionActions.getPromotionHistoryPending());
        dispatch(changeGlobalLoader(true));

        return api
            .getPromotionHistoryRequest(`?limit=${size}` + params)
            .then(({ promotionBuyHistory: items, totalCount: count }) => {
                dispatch(
                    promotionActions.getPromotionHistoryFulfilled({
                        items,
                        count,
                    })
                );
            })
            .catch((error) => {
                dispatch(promotionActions.getPromotionHistoryRejected(error));
            })
            .finally(() => dispatch(changeGlobalLoader(false)));
    };

// deactivate promotion
export const deactivatePromotion = (id) => (dispatch) => {
    dispatch(promotionActions.deactivatePromotionPending());
    dispatch(changeGlobalLoader(true));

    return api
        .deactivatePromotionRequest(id)
        .then(() => {
            dispatch(promotionActions.deactivatePromotionFulfilled(id));
        })
        .catch((error) => {
            dispatch(promotionActions.deactivatePromotionRejected(error));
        })
        .finally(() => dispatch(changeGlobalLoader(false)));
};

// set
export const editPromotionPage = (data) => (dispatch) => {
    dispatch(promotionActions.setPage(`&page=${data}`));
    const url = `&page=${data}`;
    dispatch(getPromotion(sizeItemOnPage, url));
};

export const editSearchQuery = (data) => (dispatch) => {
    dispatch(promotionActions.setSearchQuery(`&name=${data}`));
    dispatch(getPromotion(sizeItemOnPage));
};

export const removeSearchText = (setQuery) => (dispatch) => {
    dispatch(promotionActions.setSearchQuery(""));
    setQuery("");
    dispatch(getPromotion(sizeItemOnPage));
};

// set active promotions
export const editActivePromotionsPage = (data) => (dispatch) => {
    dispatch(promotionActions.setActivePromotionsPage(`&page=${data}`));
    const url = `&page=${data}&onlyActive=true`;
    dispatch(getPromotionHistory(sizeActivePromotionItemOnPage, url));
};

// set history
export const editPromotionHistoryPage = (data) => (dispatch) => {
    dispatch(promotionActions.setHistoryPage(`&page=${data}`));
    const url = `&page=${data}`;
    dispatch(getPromotionHistory(sizeHistoryItemOnPage, url));
};
