import { resultTypes } from "../../all-result.constant";

export const searchedHeaderConfig = {
    true: {
        top: {
            [resultTypes.ORDERS]: "added-orders",
            [resultTypes.SERVICES]: "added-specialists",
        },
        default: {
            [resultTypes.ORDERS]: "added-orders",
            [resultTypes.SERVICES]: "added-specialists",
        },
    },
    false: {
        top: {
            [resultTypes.ORDERS]: "order_all",
            [resultTypes.SERVICES]: "all_service_top_title",
        },
        default: {
            [resultTypes.ORDERS]: "order_all",
            [resultTypes.SERVICES]: "all_service_title",
        },
    },
};
