import { modalTypes } from "./modal.types";

const initialState = {
    isOpen: false,
    renderType: null,
    details: null,
    closeIcon: true,
};

export const modal = (state = initialState, { type, payload }) => {
    switch (type) {
        case modalTypes.OPEN_MODAL:
            return payload;

        case modalTypes.CLEAR_MODAL_STATE:
        case modalTypes.CLOSE_MODAL:
            return initialState;

        default:
            return state;
    }
};
