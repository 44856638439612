import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Fragment, useEffect } from "react";
import PropTypes from "prop-types";

import { specialistOrdersSelector } from "../specialist-orders/redux/specialist-orders.selectors";
import { getSpecialistOrder } from "../specialist-orders/redux/specialist-orders.operations";
import { ordersSelector } from "../orders/orders.selectors";
import { getOrderTask } from "../orders/orders.operations";

import { backButtonConfig } from "../../components/back-button/back-button.config";
import { getLocalizedService } from "../../helpers/get-localized-service";
import ReactHelmet from "../../components/react-helmet/react-helmet";
import BackButton from "../../components/back-button/back-button";
import OrderInfoBody from "./order-info-body/order-info-body";

import "./index.scss";

const OrderInfo = () => {
    const { order } = useSelector(specialistOrdersSelector);
    const { orderTask } = useSelector(ordersSelector);
    const { orderId, taskId } = useParams();
    const { number } = order;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        dispatch(getSpecialistOrder(orderId));
        if (!taskId) dispatch(getOrderTask(orderId, taskId));
    }, []);

    const subCategoryTitle = order && getLocalizedService(order.subcategory);

    return (
        <Fragment>
            <ReactHelmet title={subCategoryTitle} />
            <div className="order-info">
                <div className="order-info__inner">
                    <div className="order-info__header">
                        <BackButton
                            title={t(backButtonConfig[location.state].title)}
                            onClickCallback={() =>
                                navigate(
                                    backButtonConfig[location.state].href,
                                    { state: true }
                                )
                            }
                        />
                        <div className="order-info__header_title">
                            {t("specialists_order")} {subCategoryTitle} (
                            {number})
                        </div>
                    </div>
                    <OrderInfoBody {...order} orderTask={orderTask} />
                </div>
            </div>
        </Fragment>
    );
};
OrderInfo.propTypes = {
    city: PropTypes.string,
    priceTo: PropTypes.string,
    comment: PropTypes.string,
    district: PropTypes.string,
    priceFrom: PropTypes.string,
    preferredGender: PropTypes.string,
    completionDeadline: PropTypes.string,
    category: PropTypes.object,
    subcategory: PropTypes.object,
    preferredYears: PropTypes.object,
    clarification: PropTypes.object,
};
export default OrderInfo;
