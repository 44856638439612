import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { memo, useState } from "react";

import { editSearchQuery, removeSearchText } from "../../promotion.operations";
import { promotionParamsSelector } from "../../promotion.selectors";

import { ReactComponent as DeleteIcon } from "../../../../assets/images/vectors/delete_icon.svg";
import "./index.scss";

const PromotionSearch = () => {
    const { name } = useSelector(promotionParamsSelector);
    const [query, setQuery] = useState("");
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleSearch = () => dispatch(editSearchQuery(query));
    const handleChangeName = ({ target: { value } }) => setQuery(value);

    return (
        <div className="promotion__search">
            <input
                onKeyPress={({ key }) => key === "Enter" && handleSearch()}
                onChange={handleChangeName}
                placeholder={t("search")}
                className="promotion__search_input"
                value={query}
            ></input>
            <button
                disabled={!query || query === name.split("=")[1]}
                className="promotion__search_btn"
                onClick={handleSearch}
            >
                {t("btn_search")}
            </button>
            <DeleteIcon
                onClick={() => name && dispatch(removeSearchText(setQuery))}
                className={`promotion__search_remove ${
                    !name ? "disabled" : ""
                }`}
            />
        </div>
    );
};

export default memo(PromotionSearch);
