export const modalConstants = {
    forgotPasswordPending: "FORGOT_PASSWORD_PENDING",
    removeOrder: "REMOVE_ORDER",
    cancelOrder: "CANCEL_ORDER",
    updateOrder: "UPDATE_ORDER",
    proposedOrderAmount: "PROPOSED_ORDER_AMOUNT",
    declinePropose: "DECLINE_PROPOSE",
    specialistServiceForm: "SPECIALIST_SERVICE_FORM",
    selectCustomerOrder: "SELECT_CUSTOMER_ORDER",
    sendFeedback: "SEND_FEEDBACK",
    createOrder: "CREATE_ORDER",
    imageZoomer: "IMAGE_ZOOMER",
    userNotSelectAllFields: "USER_NOW_SELECT_ALL_FIELDS",
    reverseFunds: "REVERSE_FUNDS",
    agreeWithCommission: "AGREE_WITH_COMMISSION",
    complain: "COMPLAIN",
    attention: "ATTENTION",
    auth: "AUTH",
    promote: "PROMOTE",
    deleteAccount: "DELETE_ACCOUNT",
    payPromote: "PAY_PROMOTE",
    cropAvatarImage: "CROP_AVATAR_IMAGE",
    onboardingIyzico: "ONBOARDING_IYZICO",
    chatCarousel: "CHAT_CAROUSEL",
    deactivatePromotion: "DEACTIVATE_PROMOTION",
    restoreAccountRequest: "RESTORE_ACCOUNT_REQUEST",
    restoreAccountPending: "RESTORE_ACCOUNT_PENDING",
    restoreAccountSuccess: "RESTORE_ACCOUNT_SUCCESS",
    notificationSettings: "NOTIFICATION_SETTINGS",
    createSupportTicket: "CREATE_SUPPORT_TICKET",
    selectIcon: "SELECT_ICON",
    registerToView: "REGISTER_TO_VIEW",
    deactivateQuestion: "DEACTIVATE_QUESTION",
    authPhoneCode: "AUTH_PHONE_CODE",
    testPhoneCode: "TEST_PHONE_CODE",
    accountPhoneCode: "ACCOUNT_PHONE_CODE",
    showSpecialistContacts: "SHOW_SPECIALIST_CONTACTS",
    countryCodes: "COUNTRY_CODES",
    balanceReplenish: "BALANCE_REPLENISH",
    balancePaymentType: "BALANCE_PAYMENT_TYPE",
};
