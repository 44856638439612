/* eslint-disable no-prototype-builtins */
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";
import dayjs from "dayjs";

import { buttonLoaderSelector } from "../../../../components/loader/loader.selectors";
import { myAccountDataSelector } from "../../../my-account/my-account.selectors";
import { deleteNotification } from "../../notifications.operations";

import { typeOfLazeImage } from "../../../../components/lazy-image/lazyImage.constansts";
import ButtonLoader from "../../../../components/loader/components/button-loader/button-loader";
import LazyImage from "../../../../components/lazy-image/lazy-image";
import { replaceValue } from "../../../../helpers/replace-values";
import { notificationConfig } from "../../notifications.config";

import deleteIcon from "../../../../assets/images/vectors/delete_icon.svg";
import "./index.scss";

const NotificationItem = ({ isRead, createdAt, name, _id, message }) => {
    const { status: buttonStatus, button: buttonLoading } =
        useSelector(buttonLoaderSelector);
    const { role } = useSelector(myAccountDataSelector);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    return (
        <div
            className={
                "notification_item" +
                " " +
                (!isRead ? "notification_item--not-read" : "")
            }
        >
            {!isRead && (
                <div className="notification_item__indicator">
                    <span className="notification_item__indicator_item" />
                </div>
            )}
            <div className="notification_item__info">
                <LazyImage
                    image={
                        name &&
                        notificationConfig.hasOwnProperty(name) &&
                        notificationConfig[name].IMAGE
                    }
                    type={typeOfLazeImage.content}
                    options={{
                        className: "notification_item__info_img",
                        alt: "logo",
                    }}
                />
                <div className="notification_item__info_description">
                    <div className="notification_item__info_description_text">
                        {replaceValue({ value: message, isBold: true })}
                    </div>
                    <div className="notification_item__info_description_date">
                        {dayjs(createdAt).format("DD.MM.YYYY HH:mm")}
                    </div>
                </div>
            </div>
            {name &&
                notificationConfig.hasOwnProperty(name) &&
                notificationConfig[name].SHOW_MORE && (
                    <button
                        onClick={() =>
                            navigate(notificationConfig[name].REDIRECT[role], {
                                state: true,
                            })
                        }
                        className="notification_item__nav_btn"
                    >
                        {t("notifications_show_more")}
                    </button>
                )}
            {buttonStatus && buttonLoading === _id ? (
                <div className="notification_item__nav_loader">
                    <ButtonLoader size={26} />
                </div>
            ) : (
                <img
                    onClick={() => dispatch(deleteNotification(_id))}
                    className="notification_item__nav_remove"
                    src={deleteIcon}
                    alt="delete icon"
                />
            )}
        </div>
    );
};

NotificationItem.propTypes = {
    isRead: PropTypes.bool,
    message: PropTypes.object,
    createdAt: PropTypes.string,
    _id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
};

export default NotificationItem;
