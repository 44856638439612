import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Icon } from "../../../../../components/image/image";
import "./index.scss";

const StageItem = ({ number, title, description }) => {
    const { t } = useTranslation();

    return (
        <div className="stage">
            <div className="stage__content">
                <div className="stage__number">
                    <div className="stage__number_title">{number}</div>
                    <div className="stage__number_image">
                        <Icon type="arrowLongRight" />
                    </div>
                </div>
                <div className="stage__info">
                    <div className="stage__info_title">{t(title)}</div>
                    <div className="stage__info_description">
                        {t(description)}
                    </div>
                </div>
            </div>
        </div>
    );
};

StageItem.propTypes = {
    number: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
};

export default StageItem;
