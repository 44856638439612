import routerBook from "../../router/router-book";

export const termsOfServiceConfig = [
    {
        title: "terms-of-service.general-provisions.title",
        titles: [
            { item: "terms-of-service.general-provisions.titles.first" },
            {
                item: "terms-of-service.general-provisions.titles.second",
                value: ["https://wconnect.work"],
                href: routerBook.home,
            },
            { item: "terms-of-service.general-provisions.titles.third" },
            { item: "terms-of-service.general-provisions.titles.fourth" },
            {
                item: "terms-of-service.general-provisions.titles.fifth",
                items: [
                    {
                        title: "terms-of-service.general-provisions.values.first.title",
                        subtitle:
                            "terms-of-service.general-provisions.values.first.subtitle",
                    },
                    {
                        title: "terms-of-service.general-provisions.values.second.title",
                        subtitle:
                            "terms-of-service.general-provisions.values.second.subtitle",
                        value: ["https://wconnect.work"],
                        href: routerBook.home,
                    },
                    {
                        title: "terms-of-service.general-provisions.values.third.title",
                        subtitle:
                            "terms-of-service.general-provisions.values.third.subtitle",
                    },
                    {
                        title: "terms-of-service.general-provisions.values.fourth.title",
                        subtitle:
                            "terms-of-service.general-provisions.values.fourth.subtitle",
                    },
                    {
                        title: "terms-of-service.general-provisions.values.fifth.title",
                        subtitle:
                            "terms-of-service.general-provisions.values.fifth.subtitle",
                    },
                    {
                        title: "terms-of-service.general-provisions.values.sixth.title",
                        subtitle:
                            "terms-of-service.general-provisions.values.sixth.subtitle",
                    },
                    {
                        title: "terms-of-service.general-provisions.values.seventh.title",
                        subtitle:
                            "terms-of-service.general-provisions.values.seventh.subtitle",
                    },
                    {
                        title: "terms-of-service.general-provisions.values.eighth.title",
                        subtitle:
                            "terms-of-service.general-provisions.values.eighth.subtitle",
                    },
                    {
                        title: "terms-of-service.general-provisions.values.ninth.title",
                        subtitle:
                            "terms-of-service.general-provisions.values.ninth.subtitle",
                    },
                    {
                        title: "terms-of-service.general-provisions.values.tenth.title",
                        subtitle:
                            "terms-of-service.general-provisions.values.tenth.subtitle",
                    },
                    {
                        title: "terms-of-service.general-provisions.values.eleventh.title",
                        subtitle:
                            "terms-of-service.general-provisions.values.eleventh.subtitle",
                    },
                    {
                        title: "terms-of-service.general-provisions.values.twelfth.title",
                        subtitle:
                            "terms-of-service.general-provisions.values.twelfth.subtitle",
                    },
                    {
                        title: "terms-of-service.general-provisions.values.thirteenth.title",
                        subtitle:
                            "terms-of-service.general-provisions.values.thirteenth.subtitle",
                    },
                    {
                        title: "terms-of-service.general-provisions.values.fourteenth.title",
                        subtitle:
                            "terms-of-service.general-provisions.values.fourteenth.subtitle",
                    },
                    {
                        title: "terms-of-service.general-provisions.values.fifteenth.title",
                        subtitle:
                            "terms-of-service.general-provisions.values.fifteenth.subtitle",
                    },
                    {
                        title: "terms-of-service.general-provisions.values.sixteenth.title",
                        subtitle:
                            "terms-of-service.general-provisions.values.sixteenth.subtitle",
                    },
                    {
                        title: "terms-of-service.general-provisions.values.seventeen.title",
                        subtitle:
                            "terms-of-service.general-provisions.values.seventeen.subtitle",
                    },
                    {
                        title: "terms-of-service.general-provisions.values.eighteen.title",
                        subtitle:
                            "terms-of-service.general-provisions.values.eighteen.subtitle",
                    },
                    {
                        title: "terms-of-service.general-provisions.values.nineteen.title",
                        subtitle:
                            "terms-of-service.general-provisions.values.nineteen.subtitle",
                    },
                    {
                        title: "terms-of-service.general-provisions.values.twenty.title",
                        subtitle:
                            "terms-of-service.general-provisions.values.twenty.subtitle",
                    },
                    {
                        title: "terms-of-service.general-provisions.values.twenty-one.title",
                        subtitle:
                            "terms-of-service.general-provisions.values.twenty-one.subtitle",
                    },
                    {
                        title: "terms-of-service.general-provisions.values.twenty-two.title",
                        subtitle:
                            "terms-of-service.general-provisions.values.twenty-two.subtitle",
                    },
                    {
                        title: "terms-of-service.general-provisions.values.twenty-three.title",
                        subtitle:
                            "terms-of-service.general-provisions.values.twenty-three.subtitle",
                    },
                    {
                        title: "terms-of-service.general-provisions.values.twenty-four.title",
                        subtitle:
                            "terms-of-service.general-provisions.values.twenty-four.subtitle",
                    },
                    {
                        title: "terms-of-service.general-provisions.values.twenty-five.title",
                        subtitle:
                            "terms-of-service.general-provisions.values.twenty-five.subtitle",
                    },
                    {
                        title: "terms-of-service.general-provisions.values.twenty-six.title",
                        subtitle:
                            "terms-of-service.general-provisions.values.twenty-six.subtitle",
                    },
                    {
                        title: "terms-of-service.general-provisions.values.twenty-seven.title",
                        subtitle:
                            "terms-of-service.general-provisions.values.twenty-seven.subtitle",
                    },
                    {
                        title: "terms-of-service.general-provisions.values.twenty-eight.title",
                        subtitle:
                            "terms-of-service.general-provisions.values.twenty-eight.subtitle",
                    },
                    {
                        title: "terms-of-service.general-provisions.values.twenty-nine.title",
                        subtitle:
                            "terms-of-service.general-provisions.values.twenty-nine.subtitle",
                        value: ["https://wconnect.work/privacy-policy"],
                        href: routerBook.privacyPolicy,
                    },
                    {
                        title: "terms-of-service.general-provisions.values.thirty.title",
                        subtitle:
                            "terms-of-service.general-provisions.values.thirty.subtitle",
                    },
                    {
                        title: "terms-of-service.general-provisions.values.thirty-one.title",
                        subtitle:
                            "terms-of-service.general-provisions.values.thirty-one.subtitle",
                    },
                ],
            },
        ],
        subtitles: [
            {
                item: "terms-of-service.general-provisions.subtitles.first",
            },
            {
                item: "terms-of-service.general-provisions.subtitles.second",
            },
        ],
    },
    {
        title: "terms-of-service.subject-contract.title",
        titles: [
            {
                item: "terms-of-service.subject-contract.titles.first",
                value: ["https://wconnect.work"],
                href: routerBook.home,
            },
            { item: "terms-of-service.subject-contract.titles.second" },
            { item: "terms-of-service.subject-contract.titles.third" },
            { item: "terms-of-service.subject-contract.titles.fourth" },
            { item: "terms-of-service.subject-contract.titles.fifth" },
            { item: "terms-of-service.subject-contract.titles.sixth" },
            { item: "terms-of-service.subject-contract.titles.seventh" },
            { item: "terms-of-service.subject-contract.titles.eighth" },
        ],
    },
    {
        title: "terms-of-service.providing-using.title",
        titles: [
            { item: "terms-of-service.providing-using.titles.first" },
            { item: "terms-of-service.providing-using.titles.second" },
            { item: "terms-of-service.providing-using.titles.third" },
            { item: "terms-of-service.providing-using.titles.fourth" },
            { item: "terms-of-service.providing-using.titles.fifth" },
            { item: "terms-of-service.providing-using.titles.sixth" },
            { item: "terms-of-service.providing-using.titles.seventh" },
            {
                item: "terms-of-service.providing-using.titles.eighth",
                items: [
                    {
                        subtitle:
                            "terms-of-service.providing-using.titles.eighth-first",
                    },
                    {
                        subtitle:
                            "terms-of-service.providing-using.titles.eighth-second",
                    },
                    {
                        subtitle:
                            "terms-of-service.providing-using.titles.eighth-third",
                    },
                ],
            },
            { item: "terms-of-service.providing-using.titles.ninth" },
            { item: "terms-of-service.providing-using.titles.tenth" },
            { item: "terms-of-service.providing-using.titles.elevnth" },
            {
                item: "terms-of-service.providing-using.titles.twelfth",
                items: [
                    {
                        subtitle:
                            "terms-of-service.providing-using.titles.twelfth-first",
                    },
                    {
                        subtitle:
                            "terms-of-service.providing-using.titles.twelfth-second",
                    },
                    {
                        subtitle:
                            "terms-of-service.providing-using.titles.twelfth-third",
                    },
                    {
                        subtitle:
                            "terms-of-service.providing-using.titles.twelfth-fourth",
                    },
                    {
                        subtitle:
                            "terms-of-service.providing-using.titles.twelfth-fifth",
                    },
                    {
                        subtitle:
                            "terms-of-service.providing-using.titles.twelfth-sixth",
                    },
                ],
            },
            { item: "terms-of-service.providing-using.titles.thirteenth" },
            {
                item: "terms-of-service.providing-using.titles.fourteenth",
                items: [
                    {
                        subtitle:
                            "terms-of-service.providing-using.titles.fourteenth-first",
                    },
                    {
                        subtitle:
                            "terms-of-service.providing-using.titles.fourteenth-second",
                    },
                    {
                        subtitle:
                            "terms-of-service.providing-using.titles.fourteenth-third",
                    },
                    {
                        subtitle:
                            "terms-of-service.providing-using.titles.fourteenth-fourth",
                    },
                    {
                        subtitle:
                            "terms-of-service.providing-using.titles.fourteenth-fifth",
                    },
                ],
            },
            { item: "terms-of-service.providing-using.titles.fifteenth" },
            { item: "terms-of-service.providing-using.titles.sixteenth" },
            { item: "terms-of-service.providing-using.titles.seventeen" },
            { item: "terms-of-service.providing-using.titles.eighteen" },
            { item: "terms-of-service.providing-using.titles.nineteen" },
            { item: "terms-of-service.providing-using.titles.twenty" },
            { item: "terms-of-service.providing-using.titles.twenty-one" },
        ],
    },
    {
        title: "terms-of-service.rights-company.title",
        titles: [
            { item: "terms-of-service.rights-company.titles.first" },
            { item: "terms-of-service.rights-company.titles.second" },
            { item: "terms-of-service.rights-company.titles.third" },
            { item: "terms-of-service.rights-company.titles.fourth" },
            { item: "terms-of-service.rights-company.titles.fifth" },
            { item: "terms-of-service.rights-company.titles.sixth" },
            { item: "terms-of-service.rights-company.titles.seventh" },
            { item: "terms-of-service.rights-company.titles.eighth" },
            { item: "terms-of-service.rights-company.titles.ningth" },
        ],
    },
    {
        title: "terms-of-service.rights-user.title",
        titles: [
            { item: "terms-of-service.rights-user.titles.first" },
            { item: "terms-of-service.rights-user.titles.second" },
            { item: "terms-of-service.rights-user.titles.third" },
            { item: "terms-of-service.rights-user.titles.fourth" },
            { item: "terms-of-service.rights-user.titles.fifth" },
            { item: "terms-of-service.rights-user.titles.sixth" },
            { item: "terms-of-service.rights-user.titles.seventh" },
            { item: "terms-of-service.rights-user.titles.eighth" },
            {
                item: "terms-of-service.rights-user.titles.ninth",
                items: [
                    {
                        subtitle:
                            "terms-of-service.rights-user.titles.ninth-first",
                    },
                    {
                        subtitle:
                            "terms-of-service.rights-user.titles.ninth-second",
                    },
                    {
                        subtitle:
                            "terms-of-service.rights-user.titles.ninth-third",
                    },
                    {
                        subtitle:
                            "terms-of-service.rights-user.titles.ninth-fourth",
                    },
                    {
                        subtitle:
                            "terms-of-service.rights-user.titles.ninth-fifth",
                    },
                ],
            },
            {
                item: "terms-of-service.rights-user.titles.tenth",
                items: [
                    {
                        subtitle:
                            "terms-of-service.rights-user.titles.tenth-first",
                    },
                    {
                        subtitle:
                            "terms-of-service.rights-user.titles.tenth-second",
                    },
                    {
                        subtitle:
                            "terms-of-service.rights-user.titles.tenth-third",
                    },
                    {
                        subtitle:
                            "terms-of-service.rights-user.titles.tenth-fourth",
                    },
                    {
                        subtitle:
                            "terms-of-service.rights-user.titles.tenth-fifth",
                    },
                    {
                        subtitle:
                            "terms-of-service.rights-user.titles.tenth-sixth",
                    },
                    {
                        subtitle:
                            "terms-of-service.rights-user.titles.tenth-seventh",
                    },
                    {
                        subtitle:
                            "terms-of-service.rights-user.titles.tenth-eigth",
                    },
                ],
            },
            {
                item: "terms-of-service.rights-user.titles.elevnth",
                items: [
                    {
                        subtitle:
                            "terms-of-service.rights-user.titles.elevnth-first",
                    },
                    {
                        subtitle:
                            "terms-of-service.rights-user.titles.elevnth-second",
                    },
                    {
                        subtitle:
                            "terms-of-service.rights-user.titles.elevnth-third",
                    },
                    {
                        subtitle:
                            "terms-of-service.rights-user.titles.elevnth-fourth",
                    },
                    {
                        subtitle:
                            "terms-of-service.rights-user.titles.elevnth-fifth",
                    },
                    {
                        subtitle:
                            "terms-of-service.rights-user.titles.elevnth-sixth",
                    },
                ],
            },
            {
                item: "terms-of-service.rights-user.titles.twelfth",
                items: [
                    {
                        subtitle:
                            "terms-of-service.rights-user.titles.twelfth-first",
                    },
                    {
                        subtitle:
                            "terms-of-service.rights-user.titles.twelfth-second",
                    },
                    {
                        subtitle:
                            "terms-of-service.rights-user.titles.twelfth-third",
                    },
                    {
                        subtitle:
                            "terms-of-service.rights-user.titles.twelfth-fourth",
                    },
                ],
            },
            { item: "terms-of-service.rights-user.titles.thirteenth" },
            { item: "terms-of-service.rights-user.titles.fourteenth" },
            {
                item: "terms-of-service.rights-user.titles.fifteenth",
                items: [
                    {
                        subtitle:
                            "terms-of-service.rights-user.titles.fifteenth-first",
                    },
                    {
                        subtitle:
                            "terms-of-service.rights-user.titles.fifteenth-second",
                    },
                ],
            },
            { item: "terms-of-service.rights-user.titles.sixteenth" },
        ],
    },
    {
        title: "terms-of-service.liability.title",
        titles: [
            { item: "terms-of-service.liability.titles.first" },
            { item: "terms-of-service.liability.titles.second" },
            { item: "terms-of-service.liability.titles.third" },
            { item: "terms-of-service.liability.titles.fourth" },
            { item: "terms-of-service.liability.titles.fifth" },
            { item: "terms-of-service.liability.titles.sixth" },
            {
                item: "terms-of-service.liability.titles.seventh",
                items: [
                    {
                        subtitle:
                            "terms-of-service.liability.titles.seventh-firth",
                    },
                    {
                        subtitle:
                            "terms-of-service.liability.titles.seventh-second",
                    },
                    {
                        subtitle:
                            "terms-of-service.liability.titles.seventh-third",
                    },
                    {
                        subtitle:
                            "terms-of-service.liability.titles.seventh-fourth",
                    },
                    {
                        subtitle:
                            "terms-of-service.liability.titles.seventh-fifth",
                    },
                ],
            },
            { item: "terms-of-service.liability.titles.eighth" },
            { item: "terms-of-service.liability.titles.ninth" },
            { item: "terms-of-service.liability.titles.tenth" },
            {
                item: "terms-of-service.liability.titles.elevnth",
                items: [
                    {
                        subtitle:
                            "terms-of-service.liability.titles.elevnth-firts",
                    },
                    {
                        subtitle:
                            "terms-of-service.liability.titles.elevnth-second",
                    },
                    {
                        subtitle:
                            "terms-of-service.liability.titles.elevnth-third",
                    },
                    {
                        subtitle:
                            "terms-of-service.liability.titles.elevnth-fourth",
                    },
                ],
            },
        ],
    },
    {
        title: "terms-of-service.intellectual-property.title",
        titles: [
            { item: "terms-of-service.intellectual-property.titles.first" },
        ],
    },
    {
        title: "terms-of-service.notifications-messages.title",
        titles: [
            {
                item: "terms-of-service.notifications-messages.titles.first",
                value: ["support@wconnect.work"],
                href: "mailto:https://support@wconnect.work",
            },
            { item: "terms-of-service.notifications-messages.titles.second" },
            { item: "terms-of-service.notifications-messages.titles.third" },
            { item: "terms-of-service.notifications-messages.titles.fourth" },
        ],
    },
    {
        title: "terms-of-service.settlement-of-disputes.title",
        titles: [
            { item: "terms-of-service.settlement-of-disputes.titles.first" },
            { item: "terms-of-service.settlement-of-disputes.titles.second" },
            { item: "terms-of-service.settlement-of-disputes.titles.third" },
        ],
    },
    {
        title: "terms-of-service.procedure-for-amending.title",
        titles: [
            { item: "terms-of-service.procedure-for-amending.titles.first" },
            { item: "terms-of-service.procedure-for-amending.titles.second" },
            { item: "terms-of-service.procedure-for-amending.titles.third" },
        ],
    },
    {
        title: "terms-of-service.final-provisions.title",
        titles: [
            { item: "terms-of-service.final-provisions.titles.first" },
            { item: "terms-of-service.final-provisions.titles.second" },
        ],
    },
];
