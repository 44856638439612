import PropTypes from "prop-types";

import convertRatingToWidth from "./rating.utils";

import "./index.scss";

const Rating = ({ rating = 1 }) => {
    return (
        <span className="rating" role="img">
            <span
                className="rating--yellow"
                style={{ width: convertRatingToWidth(rating) }}
            ></span>
        </span>
    );
};

Rating.propTypes = { rating: PropTypes.number.isRequired };

export default Rating;
