import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import React from "react";

import { allResultSelector } from "../../../../all-result.selectors";
import {
    editCategory,
    editClarification,
    editSubcategory,
} from "../../../../all-result.operations";

import useEventTracker from "../../../../../../hooks/use-event-tracker";
import { sizeItemOnPage } from "../../../../all-result.constant";
import { getLocalizedService } from "../../../../../../helpers/get-localized-service";
import { filterTypes } from "../../filter.config";

import { ReactComponent as ArrowDown } from "../../../../../../assets/images/arrows-icons/arrow_down.svg";
import "./index.scss";

const FilterItem = ({
    handleChangeOpenItemStatus,
    handleChangeOpenItemLimit,
    openItem,
    data = [],
    item,
    type,
}) => {
    const { tags } = useSelector(allResultSelector);
    const trackEvent = useEventTracker("filter");
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleSetActiveItem = (e, element) => {
        e.preventDefault();
        switch (item) {
            case filterTypes.SERVICES:
                trackEvent(
                    `filter_by_category_on_${type.toLowerCase()}`,
                    getLocalizedService(element)
                );
                return dispatch(editCategory(sizeItemOnPage, element, type));

            case filterTypes.SUBSERVICES:
                trackEvent(
                    `filter_by_subcategory_on_${type.toLowerCase()}`,
                    getLocalizedService(element)
                );
                return dispatch(editSubcategory(sizeItemOnPage, element, type));

            case filterTypes.CLARIFICATIONS:
                trackEvent(
                    `filter_by_clarification_on_${type.toLowerCase()}`,
                    getLocalizedService(element)
                );
                return dispatch(
                    editClarification(sizeItemOnPage, element, type)
                );

            default:
                break;
        }
    };

    const activeItem = ({ _id = "" }) => {
        if (tags.find(({ value }) => value.split("=")[1] === _id)) return true;
    };

    return (
        <ul className="filter-item">
            <div
                onClick={() => handleChangeOpenItemStatus(item)}
                className="filter-item__header"
            >
                <div className="filter-item__title">
                    {t(`order_search.item.${item}`)}
                </div>
                {data.length > 0 && (
                    <ArrowDown
                        className={`filter-item__img${
                            !openItem[item].status ? "_close" : ""
                        } `}
                    />
                )}
            </div>
            {data.length > 0 && openItem[item].status && (
                <>
                    {data.slice(0, openItem[item].limit).map((element) => (
                        <li className="filter-item__subtitle" key={element._id}>
                            <a
                                className={`${
                                    activeItem(element) ? "active" : ""
                                }`}
                                onClick={(e) => handleSetActiveItem(e, element)}
                            >
                                {getLocalizedService(element)}
                            </a>
                        </li>
                    ))}
                    {openItem[item].limit < data.length && (
                        <div className="filter-item__footer">
                            <ArrowDown className="filter-item__footer_img" />
                            <span
                                className="filter-item__footer_title"
                                onClick={() => handleChangeOpenItemLimit(item)}
                            >
                                {t("order_search.show_more")}
                            </span>
                        </div>
                    )}
                </>
            )}
            {!data.length && (
                <span className="filter-item__item_empty">
                    {t(`order_search.not_found.${item}`)}
                </span>
            )}
        </ul>
    );
};

FilterItem.propTypes = {
    handleChangeOpenItemStatus: PropTypes.func,
    handleChangeOpenItemLimit: PropTypes.func,
    openItem: PropTypes.object,
    item: PropTypes.string,
    type: PropTypes.string,
    data: PropTypes.array,
};

export default FilterItem;
