import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";

import { buttonLoaderSelector } from "../../../../../../../components/loader/loader.selectors";
import { putTaskFixPrice } from "../../../../../../orders/orders.operations";
import { closeModal } from "../../../../../../../components/modal/modal.actions";

import ButtonLoader from "../../../../../../../components/loader/components/button-loader/button-loader";
import { proposeOrderAmountSchema } from "./propose-order-amount.schema";
import { Icon } from "../../../../../../../components/image/image";

import "./index.scss";

const ProposeOrderAmount = ({ details: { orderId, taskId } }) => {
    const {
        formState: { errors, isValid },
        handleSubmit,
        register,
    } = useForm({
        mode: "all",
        defaultValues: {},
        resolver: yupResolver(proposeOrderAmountSchema),
    });

    const { status: isLoading } = useSelector(buttonLoaderSelector);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleFormSubmit = ({ price }) => {
        dispatch(
            putTaskFixPrice(orderId, taskId, { price, eventType: "PROPOSE" })
        );
        dispatch(closeModal());
    };

    return (
        <form
            className="propose_order_amount"
            onSubmit={handleSubmit(handleFormSubmit)}
        >
            <div className="propose_order_amount_title">
                {t("chats_item.propose_price_title")}
            </div>
            <div className="propose_order_amount_content">
                <label className="propose_order_amount_content_error">
                    {t(errors?.price?.message)}
                </label>
                <input
                    placeholder={t("chats_item.price_input_placeholder")}
                    className="propose_order_amount_content_input"
                    {...register("price")}
                    type="number"
                    step="0.01"
                    min="1"
                />
                <div className="propose_order_amount_content_img">
                    <Icon type="currencyLira" />
                </div>
            </div>
            <button
                style={{ background: isLoading ? "#f3f4f6" : "" }}
                className="propose_order_amount_btn"
                disabled={isLoading || !isValid}
                type="submit"
            >
                {isLoading ? (
                    <ButtonLoader size={38} />
                ) : (
                    t("chats_item.propose_price_btn")
                )}
            </button>
        </form>
    );
};

ProposeOrderAmount.propTypes = {
    details: PropTypes.object,
};

export default ProposeOrderAmount;
