import { get, post } from "../api";

export const getUserBalanceRequest = async (params = "") => {
    const url = "/balance/balance-user" + params;

    try {
        const { data } = await get(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const getUserBalanceDetailsRequest = async () => {
    const url = "/balance/detail-balance-user";

    try {
        const { data } = await get(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const getUserBalanceFullDetailsRequest = async () => {
    const url = "/balance/full-detail-balance-user";

    try {
        const { data } = await get(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const getUserBalanceHistoryRequest = async () => {
    const url = "/balance/history-detail-balance-user";

    try {
        const { data } = await get(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const initializeSessionPayRequest = async (reqData = {}) => {
    const url = "/balance/initialize-session-pay";

    try {
        const { data } = await post(url, reqData);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const checkSessionPayRequest = async (reqData = {}) => {
    const url = "/balance/check-session-pay-add-money-to-balance";

    try {
        const { data } = await post(url, reqData);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const withdrawalMoneyRequest = async (reqData = {}) => {
    const url = "/balance/withdrawal-money";

    try {
        const { data } = await post(url, reqData);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const printInvoiceRequest = async (params = "") => {
    const url = "/balance/print-pdf-invoice" + params;

    try {
        const { data } = await get(url, { responseType: "arraybuffer" });
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const printInvoiceListRequest = async (params = "") => {
    const url = "/balance/print-list-invoices" + params;

    try {
        const { data } = await get(url, { responseType: "arraybuffer" });
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};
