import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Fragment, useEffect } from "react";

import { getSpecialistProfileByID } from "../specialists-list/specialist-list.operations";
import { specialistListSelector } from "../specialists-list/specialist-list.selectors";

import { backButtonConfig } from "../../components/back-button/back-button.config";
import ProfileMainInformation from "./components/profile-main-information/profile-main-information";
import ReactHelmet from "../../components/react-helmet/react-helmet";
import BackButton from "../../components/back-button/back-button";
import Services from "./components/services/services";
import Reviews from "./components/reviews/reviews";

import "./index.scss";

const SpecialistProfile = () => {
    const { selectedSpecialist } = useSelector(specialistListSelector);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const { id } = useParams();

    useEffect(() => {
        if (id) dispatch(getSpecialistProfileByID(id));
    }, []);

    if (!selectedSpecialist) return null;

    return (
        <Fragment>
            <ReactHelmet title="specialist-profile.title" />
            <div className="specialist-profile">
                <div className="specialist-profile__header">
                    <BackButton
                        onClickCallback={() =>
                            navigate(backButtonConfig[location.state].href, {
                                state: true,
                            })
                        }
                        title={t(backButtonConfig[location.state].title)}
                    />
                    <h1 className="specialist-profile__header__title">
                        {t("specialist-profile.title")}
                    </h1>
                </div>
                <div className="specialist-profile__main-content">
                    <div className="specialist-profile__main-content__left-column">
                        <ProfileMainInformation
                            selectedSpecialist={selectedSpecialist}
                        />
                    </div>
                    <div className="specialist-profile__main-content__right-column">
                        <Services
                            specialistServices={selectedSpecialist.services}
                        />
                        <Reviews feedbacks={selectedSpecialist.feedbacks} />
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

SpecialistProfile.propTypes = {};

export default SpecialistProfile;
