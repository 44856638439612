import PropTypes from "prop-types";

import { Icon } from "../../../../../components/image/image";

import "./index.scss";

const InitialImage = ({ handleUploadClick, defaultImage }) => {
    return (
        <div className="initial-image">
            <label htmlFor="contained-button-file" onClick={handleUploadClick}>
                <div className="initial-image-content">
                    <div className="initial-image-content-plus">
                        <Icon type="cameraGreen" />
                    </div>
                    <img
                        className="initial-image-content-file"
                        src={defaultImage}
                    />
                </div>
            </label>
        </div>
    );
};

InitialImage.propTypes = {
    handleUploadClick: PropTypes.func.isRequired,
    defaultImage: PropTypes.any,
};

export default InitialImage;
