import * as requests from "../../services/requests/country-codes";
import * as countryCodesActions from "./country-codes.actions";

export const getCountryCodes = () => async (dispatch) => {
    dispatch(countryCodesActions.getCountryCodesRequest());

    return requests
        .getAllCitiesRequest()
        .then((data) => {
            dispatch(countryCodesActions.getCountryCodesSuccess(data));
        })
        .catch((error) =>
            dispatch(countryCodesActions.getCountryCodesError(error))
        );
};
