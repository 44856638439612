import { get } from "../api";

export const getAllCitiesRequest = async (country = "Turkey") => {
    const url = `/country/cities?country=${country}`;

    try {
        const { data } = await get(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const getAllDistrictsRequest = async (
    state_code = "",
    cityName = ""
) => {
    const city = state_code
        ? `city=${state_code}`
        : cityName && `cityName=${cityName}`;

    const url = `country/city/districts?${city}`;

    try {
        const { data } = await get(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};
