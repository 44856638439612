import { useTranslation } from "react-i18next";

import { multilanguageComfortConfig } from "./comfort.config";
import { getLanguage } from "../../../../helpers/language";
import { Icon } from "../../../../components/image/image";
import routerBook from "../../../../router/router-book";

import "./index.scss";

const Comfort = () => {
    const { value: language } = getLanguage();
    const { t } = useTranslation();

    return (
        <div className="comfort">
            <div className="comfort__content">
                <div className="comfort__info">
                    <h2 className="comfort__title">
                        {t("profile_comfort_title")}
                    </h2>
                    <p className="comfort__description">
                        {t("profile_comfort_description")}
                    </p>
                    <div className="comfort__links">
                        <a
                            href={routerBook.appLinks.googlePlay}
                            className="comfort__link comfort__link--playmarket"
                        >
                            <Icon type="playmarket" />
                            Google Play
                        </a>
                        <a
                            href={routerBook.appLinks.appStore}
                            className="comfort__link comfort__link--appstore"
                        >
                            <Icon type="appStore" />
                            App Store
                        </a>
                    </div>
                </div>
                <img
                    src={multilanguageComfortConfig[language]}
                    className="comfort__mobile"
                    alt="preview"
                />
            </div>
        </div>
    );
};

export default Comfort;
