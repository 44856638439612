import PromotionHistory from "./components/promotion-history/promotion-history";
import PromotionService from "./components/promotion-service/promotion-service";
import { promotionConstants } from "./promotion.constants";

export const promotionConfig = {
    [promotionConstants.condition]: (
        <PromotionService primaryBtnTitle="btn_buy" />
    ),
    [promotionConstants.acting]: (
        <PromotionService
            secondaryBtnTitle="btn_deactivate"
            primaryBtnTitle="btn_expand"
        />
    ),
    [promotionConstants.history]: <PromotionHistory />,
};
