import { userRoles } from "../../../../../../constants/user-roles";
import routerBook from "../../../../../../router/router-book";

export const profileNavigation = {
    [userRoles.customer]: [
        { name: "nav_profile", path: routerBook.myAccountInfo, state: true },
        { name: "balance-title", path: routerBook.balance, state: true },
        {
            name: "nav-support",
            path: `${routerBook.support}`,
            state: true,
        },
        {
            name: "faq-title",
            path: `${routerBook.faq}`,
            state: true,
        },
    ],
    [userRoles.specialist]: [
        { name: "nav_profile", path: routerBook.myAccountInfo, state: true },
        { name: "balance-title", path: routerBook.balance, state: true },
        {
            name: "promotion.title",
            path: routerBook.accessForSpecialist.promotion,
            state: true,
        },
        {
            name: "nav-support",
            path: `${routerBook.support}`,
            state: true,
        },
        {
            name: "faq-title",
            path: `${routerBook.faq}`,
            state: true,
        },
    ],
};
