export const supportTypes = {
    // get
    GET_ALL_QUESTIONS_PENDING: "support/GET_ALL_QUESTIONS_PENDING",
    GET_ALL_QUESTIONS_FULFILLED: "support/GET_ALL_QUESTIONS_FULFILLED",
    GET_ALL_QUESTIONS_REJECTED: "support/POST_QUESTIONS_REJECTED",

    // post
    POST_QUESTION_PENDING: "support/POST_QUESTION_PENDING",
    POST_QUESTION_FULFILLED: "support/POST_QUESTION_FULFILLED",
    POST_QUESTION_REJECTED: "support/POST_QUESTION_REJECTED",

    //delete
    DELETE_QUESTION_PENDING: "support/DELETE_QUESTION_PENDING",
    DELETE_QUESTION_FULFILLED: "support/DELETE_QUESTION_FULFILLED",
    DELETE_QUESTION_REJECTED: "support/DELETE_QUESTION_REJECTED",

    //set
    SET_PAGE: "support/SET_PAGE",
    SET_TEXT: "support/SET_TEXT",
    CLEAR_ALL_PARAMS: "support/CLEAR_ALL_PARAMS",
};
