import { Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";

import { myAccountDataSelector } from "../pages/my-account/my-account.selectors";
import { authSelector } from "../pages/auth/auth.selectors";

import { errorHandlerTypes } from "../components/error-handler/error-handler.constants";
import ErrorHandler from "../components/error-handler/error-handler";
import { publicRouter, privateRoutes } from "./routes";
import Layout from "../components/layout/layout";
import routerBook from "./router-book";

const Router = () => {
    const { isBanned } = useSelector(myAccountDataSelector);
    const { token, role } = useSelector(authSelector);

    const getPrivateRouter = () =>
        privateRoutes.map(({ path, component, roles }) => (
            <Route
                key={path}
                path={path}
                element={
                    roles.includes(role) ? (
                        component
                    ) : (
                        <ErrorHandler type={errorHandlerTypes.ACCESS_DENIED} />
                    )
                }
            />
        ));

    const getPublicRouter = () =>
        publicRouter.map(({ path, component }) => (
            <Route key={path} path={path} element={component} />
        ));

    return (
        <Routes>
            <Route path={routerBook.home} element={<Layout />}>
                {token && !isBanned ? getPrivateRouter() : undefined}
                {!token || isBanned ? getPublicRouter() : undefined}
            </Route>
        </Routes>
    );
};

export default Router;
