import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React from "react";

import BackButton from "../../../../components/back-button/back-button";
import { backButtonConfig } from "../../../../components/back-button/back-button.config";

import "./index.scss";

const CreateOrderHeader = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div className="create-order__header">
            <div className="create-order__header-btn">
                <BackButton
                    onClickCallback={() =>
                        navigate(backButtonConfig[location.state].href, {
                            state: true,
                        })
                    }
                    title={t(backButtonConfig[location.state].title)}
                />
            </div>
            <div className="create-order__header-content">
                <span className="create-order__header-content-title">
                    {t("order")}
                </span>
                <span className="create-order__header-content-subtitle">
                    {t("order_details")}
                </span>
            </div>
        </div>
    );
};

export default CreateOrderHeader;
