import React from "react";

import SpecialistListFormItem from "./components/specialists-item/specialists-item";

const Specialists = ({ specialists }) =>
    specialists.map((specialist) => (
        <SpecialistListFormItem key={specialist.service._id} {...specialist} />
    ));

export default Specialists;
