import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { getFeedbacks } from "../../feedback.operations";
import { clearFeedback } from "../../feedback.actions";

import ReactHelmet from "../../../../components/react-helmet/react-helmet";
import FeedbackForm from "../feedback-form/feedback-form";

import "./index.scss";

const FeedBackModal = ({
    details: { specialistId, firstName, lastName, taskId, orderId },
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        if (specialistId) {
            dispatch(getFeedbacks(specialistId, taskId));
            return () => dispatch(clearFeedback());
        }
    }, []);

    return (
        <Fragment>
            <ReactHelmet title="orders.executors.leave-an-review" />
            <div className="feedback-modal__container">
                <div className="feedback-modal__header">
                    <p className="feedback-modal__header_text">
                        {t("create_feedback__header")}
                    </p>
                    <p className="feedback-modal__header_username">
                        {`${firstName} ${lastName}?`}
                    </p>
                </div>
                <FeedbackForm taskId={taskId} orderId={orderId} />
            </div>
        </Fragment>
    );
};

FeedBackModal.propTypes = { details: PropTypes.object };

export default FeedBackModal;
