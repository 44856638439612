import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { editCategory } from "../../../../../components/all-result/all-result.operations";
import { myAccountDataSelector } from "../../../../my-account/my-account.selectors";

import { typeOfLazeImage } from "../../../../../components/lazy-image/lazyImage.constansts";
import LazyImage from "../../../../../components/lazy-image/lazy-image";
import useEventTracker from "../../../../../hooks/use-event-tracker";
import {
    resultTypes,
    sizeItemOnPage,
} from "../../../../../components/all-result/all-result.constant";
import { userRoles } from "../../../../../constants/user-roles";
import { getLocalizedService } from "../../../../../helpers/get-localized-service";
import routerBook from "../../../../../router/router-book";

import "./index.scss";

const CategoryItem = (category) => {
    const { role } = useSelector(myAccountDataSelector);
    const trackEvent = useEventTracker("filter");
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleRedirect = () => {
        switch (role) {
            case userRoles.customer:
                navigate(routerBook.allServicesResult, { state: true });
                dispatch(
                    editCategory(sizeItemOnPage, category, resultTypes.SERVICES)
                );
                trackEvent(
                    "filter_by_category_on_services",
                    getLocalizedService(category)
                );
                break;
            case userRoles.specialist:
                navigate(routerBook.accessForSpecialist.newOrders, {
                    state: true,
                });
                dispatch(
                    editCategory(sizeItemOnPage, category, resultTypes.ORDERS)
                );
                trackEvent(
                    "filter_by_category_on_orders",
                    getLocalizedService(category)
                );
                break;
            default:
                navigate(routerBook.allServicesResult, { state: true });
                dispatch(
                    editCategory(sizeItemOnPage, category, resultTypes.SERVICES)
                );
                trackEvent(
                    "filter_by_category_on_services",
                    getLocalizedService(category)
                );
                break;
        }
    };

    return (
        <div onClick={handleRedirect} className="category_item">
            <div className="category_item__container">
                <LazyImage
                    options={{ className: "category_item__image" }}
                    type={typeOfLazeImage.content}
                    image={category.icon}
                />
                <p className="category_item__description">
                    {getLocalizedService(category)}
                </p>
            </div>
        </div>
    );
};

export default CategoryItem;
