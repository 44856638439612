export function initFacebookSdk() {
    return new Promise(() => {
        window.fbAsyncInit = function () {
            window.FB.init({
                // appId: process.env.REACT_APP_CLIENT_ID_FACEBOOK,
                appId: "3331071290505896",
                cookie: true,
                xfbml: true,
                version: "v8.0",
            });

            // auto authenticate with the api if already logged in with facebook
            // window.FB.getLoginStatus(({ authResponse }) => {
            //     if (authResponse) {
            //         // accountService
            //         //     .apiAuthenticate(authResponse.accessToken)
            //         //     .then(resolve);
            //     } else {
            //         resolve();
            //     }
            // });
        };

        (function (d, s, id) {
            var js,
                fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        })(document, "script", "facebook-jssdk");
    });
}
