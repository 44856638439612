export const selectStyles = {
    control: (baseStyles, state) => ({
        ...baseStyles,
        margin: "36px 0 16px",
        height: "50px",
        boxShadow: state.isFocused && "none",
        border: state.isFocused && "1px solid #33b378",
        "&:hover": {
            border: state.isFocused && "1px solid #33b378",
        },
    }),
    placeholder: (baseStyles) => ({
        ...baseStyles,
        textAlign: "left",
    }),
    container: (baseStyles) => ({
        ...baseStyles,
        position: "static",
    }),
    menu: (baseStyles) => ({
        ...baseStyles,
        position: "absolute",
        width: "356px",
        maxHeight: "334px",
        top: "287px",
        "@media (max-width: 475px)": {
            width: "300px",
        },
    }),
    option: (baseStyles) => ({
        ...baseStyles,
        padding: "20px 16px",
        borderBottom: "1px solid #f1f1f1",
        fontSize: "14px",
        lineHeight: "16px",
        fontFamily: "SF Pro Display Medium",
        color: "#272a32",
        cursor: "pointer",
    }),
};

export const filterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    trim: true,
    stringify: (option) => `${option.value.name} ${option.value.dialCode}`,
};
