import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import { myAccountDataSelector } from "../../my-account/my-account.selectors";
import {
    editCategory,
    editSubcategory,
} from "../../../components/all-result/all-result.operations";

import { getLocalizedService } from "../../../helpers/get-localized-service";
import { typeOfLazeImage } from "../../../components/lazy-image/lazyImage.constansts";
import LazyImage from "../../../components/lazy-image/lazy-image";
import useEventTracker from "../../../hooks/use-event-tracker";
import {
    resultTypes,
    sizeItemOnPage,
} from "../../../components/all-result/all-result.constant";
import { userRoles } from "../../../constants/user-roles";
import routerBook from "../../../router/router-book";

import "./index.scss";

const AllServicesItem = (category) => {
    const { role } = useSelector(myAccountDataSelector);
    const trackEvent = useEventTracker("filter");
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { allServicesResult, accessForSpecialist } = routerBook;
    const { subservices = [], icon = "", _id } = category;
    const { SERVICES, ORDERS } = resultTypes;
    const sizeOfSubservices = 5;

    const handleSearchSubservice = (subservice) => {
        switch (role) {
            case userRoles.customer:
                dispatch(editSubcategory(sizeItemOnPage, subservice, SERVICES));
                navigate(allServicesResult, { state: true });
                trackEvent(
                    "filter_by_subcategory_on_services",
                    getLocalizedService(subservice)
                );
                break;
            case userRoles.specialist:
                dispatch(editSubcategory(sizeItemOnPage, subservice, ORDERS));
                navigate(accessForSpecialist.newOrders, { state: true });
                trackEvent(
                    "filter_by_subcategory_on_orders",
                    getLocalizedService(subservice)
                );
                break;
            default:
                dispatch(editSubcategory(sizeItemOnPage, subservice, SERVICES));
                navigate(allServicesResult, { state: true });
                trackEvent(
                    "filter_by_subcategory_on_services",
                    getLocalizedService(subservice)
                );
                break;
        }
    };

    const handleSearchService = () => {
        switch (role) {
            case userRoles.customer:
                navigate(allServicesResult, { state: true });
                dispatch(editCategory(sizeItemOnPage, category, SERVICES));
                trackEvent(
                    "filter_by_category_on_services",
                    getLocalizedService(category)
                );
                break;
            case userRoles.specialist:
                navigate(accessForSpecialist.newOrders, { state: true });
                dispatch(editCategory(sizeItemOnPage, category, ORDERS));
                trackEvent(
                    "filter_by_category_on_orders",
                    getLocalizedService(category)
                );
                break;
            default:
                navigate(allServicesResult, { state: true });
                dispatch(editCategory(sizeItemOnPage, category, SERVICES));
                trackEvent(
                    "filter_by_category_on_services",
                    getLocalizedService(category)
                );
                break;
        }
    };

    const getCurrentSubservices = () => {
        return subservices.filter(({ category }) => category === _id);
    };

    return (
        <div className="all_services__item">
            <div className="all_services__item_header">
                <LazyImage
                    options={{ className: "all_services__item-image" }}
                    type={typeOfLazeImage.content}
                    image={icon}
                />
                <div
                    className="all_services__item_header_title"
                    onClick={handleSearchService}
                >
                    {getLocalizedService(category)}
                </div>
                <h4 className="all_services__item_header_amount">
                    {getCurrentSubservices().length}
                </h4>
            </div>
            <ul className="all_services__item_list">
                {getCurrentSubservices()
                    .slice(0, sizeOfSubservices)
                    .map((subservice) => (
                        <li
                            className="all_services__item_list_description"
                            onClick={() => handleSearchSubservice(subservice)}
                            key={subservice._id}
                        >
                            {getLocalizedService(subservice)}
                        </li>
                    ))}
            </ul>
        </div>
    );
};

AllServicesItem.propTypes = {
    subservices: PropTypes.array,
    icon: PropTypes.string,
    _id: PropTypes.string,
};

export default AllServicesItem;
