import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

import { forgotPasswordSelector } from "../auth.selectors";
import { setForgotPasswordStep } from "../auth.actions";

import { forgotPasswordConfig } from "./forgot-password-configs";
import { forgotPasswordSteps } from "../auth.constants";

const ForgotPassword = () => {
    const { step } = useSelector(forgotPasswordSelector);
    const dispatch = useDispatch();
    const { token } = useParams();

    useEffect(() => {
        if (token) {
            dispatch(setForgotPasswordStep(forgotPasswordSteps.confirm));
        } else dispatch(setForgotPasswordStep(forgotPasswordSteps.request));
    }, []);

    return forgotPasswordConfig[step];
};

export default ForgotPassword;
