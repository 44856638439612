import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller, useFormState } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";

import { ordersSelector } from "../../orders.selectors";
import { serviceSelector } from "../../../all-services/all-services.selectors";
import { buttonLoaderSelector } from "../../../../components/loader/loader.selectors";
import { citiesLoaderSelector } from "../../../../components/cities-loader/cities-loader.selectors";

import { getOrderFulfilled } from "../../oders.actions";
import { closeModal } from "../../../../components/modal/modal.actions";

import {
    getClarifications,
    getSubservices,
} from "../../../all-services/all-services.operations";
import {
    deleteOrderImage,
    getOrder,
    postOrderImage,
    putOrder,
} from "../../orders.operations";
import { getAllDistricts } from "../../../../components/cities-loader/cities-loader.operations";

import ImageUploader from "../../../../components/input/image-uploader/image-uploader";
import ButtonLoader from "../../../../components/loader/components/button-loader/button-loader";
import LocationSelect from "../../../../components/location-select/location-select";
import DatePicker from "../../../../components/input/date-picker/date-picker";
import DualInput from "../../../../components/input/dual-input/dual-input";
import Textarea from "../../../../components/input/textarea/textarea";
import LazyImage from "../../../../components/lazy-image/lazy-image";
import Select from "../../../../components/select/select";
import Input from "../../../../components/input/input";

import SELECT_TYPES from "../../../../components/select/select.constants";
import { genders } from "../../../../constants/genders";
import { typeOfLazeImage } from "../../../../components/lazy-image/lazyImage.constansts";
import { IMAGE_UPLOADER_TYPES } from "../../../../components/input/image-uploader/image-uploader.constants";
import {
    ORDER_FORM_OTHER_FIELDS,
    ORDER_FORM_SELECT_FIELDS,
} from "./update-order-constants";

import {
    updateOrderSchema,
    updateOrderSchemaFixedType,
} from "./update-order.schema";
import {
    convertOrderToRequestSchema,
    convertServiceForDefaultValues,
    mapStateToOptions,
} from "./update-order.utils";
import {
    formingAnObjectForSendingForm,
    isEmptyObject,
} from "../../../../helpers/is-empty-object";

import deletePhotoIcon from "../../../../assets/images/create-order-icons/delete_photo.svg";

import "./index.scss";
import { isObjectEqual } from "../../../../helpers/isObjectEqual";
import { priceOptionsList } from "../../../create-order/components/create-order-form/create-order-form.config";

const UpdateOrder = ({ details: { _id } }) => {
    const { status: buttonStatus, button: buttonLoader } =
        useSelector(buttonLoaderSelector);
    const { clarifications, subservices, services } =
        useSelector(serviceSelector);
    const { cities, districts } = useSelector(citiesLoaderSelector);
    const { order } = useSelector(ordersSelector);

    const setDefaultTypeOfPrice = () => {
        if (order?.priceFrom !== undefined) return priceOptionsList[0].value;
        if (order?.fixedPrice !== undefined) return priceOptionsList[1].value;
        return "";
    };

    const [typeOfPrice, setTypeOfPrice] = useState("");

    useEffect(() => {
        setTypeOfPrice(setDefaultTypeOfPrice());
    }, [order]);

    //todo has a problem => data from order is lating 50/50

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        handleSubmit,
        register,
        reset,
        resetField,
        control,
        setValue,
        formState: { errors },
        watch,
    } = useForm({
        mode: "all",
        resolver: yupResolver(
            typeOfPrice === priceOptionsList[1].value
                ? updateOrderSchemaFixedType
                : updateOrderSchema
        ),
    });
    const priceFrom = watch("priceFrom");

    const { dirtyFields } = useFormState({ control });

    const [selectedCity, setSelectedCity] = useState({
        state_code: "",
        name: order?.city || "",
    });

    useEffect(() => {
        dispatch(getOrder(_id));
        return () => dispatch(getOrderFulfilled(null));
    }, []);

    useEffect(() => {
        if (order?._id) setDefaultValues();
    }, [order?._id]);

    useEffect(() => {
        if (selectedCity?.state_code || order?.city)
            dispatch(getAllDistricts(selectedCity.state_code, order?.city));
    }, [selectedCity, order?.city]);

    const handleFormSubmit = (data) => {
        const newData = formingAnObjectForSendingForm(
            convertOrderToRequestSchema(data),
            dirtyFields
        );

        dispatch(putOrder(newData, _id));
        reset(data);
    };

    const setDefaultValues = () => {
        ORDER_FORM_SELECT_FIELDS.forEach((item) => {
            setValue(
                item.fieldName,
                convertServiceForDefaultValues(order[item.defaultValueKey])
            );
        });
        ORDER_FORM_OTHER_FIELDS.forEach((item) => {
            setValue(item.fieldName, order[item.defaultValueKey]);
        });
        setValue(
            "preferredGender",
            genders.find(({ value }) => value === order.preferredGender)
        );
        if (order?.fixedPrice !== undefined)
            setValue("fixedPrice", order?.fixedPrice);
    };

    const clearFields = (fields = []) => {
        fields.forEach((item) => setValue(item, undefined));
    };

    const handleSelectCity = (currentCity) => {
        if (
            isObjectEqual(currentCity, selectedCity) |
            (currentCity.name === selectedCity.name)
        )
            return;
        resetField("district");
        setSelectedCity(currentCity);
    };

    return (
        <form
            onSubmit={handleSubmit(handleFormSubmit)}
            className="update-order-modal"
        >
            <div className="update-order-modal__form">
                <div className="update-order-modal__form-left">
                    <div className="update-order-modal__form-left__item">
                        <h1 className="update-order-modal__form-left__item-title">
                            {t("order_describde_order")}
                        </h1>
                        {services.length > 0 && (
                            <>
                                <Controller
                                    name="categoryId"
                                    control={control}
                                    render={({
                                        field: {
                                            onChange,
                                            onBlur,
                                            value,
                                            name,
                                        },
                                    }) => (
                                        <Select
                                            name={name}
                                            error={errors.categoryId?.message}
                                            value={value}
                                            onBlur={(category) =>
                                                onBlur(category)
                                            }
                                            options={mapStateToOptions(
                                                services
                                            )}
                                            onSelect={(category) => {
                                                clearFields([
                                                    "subcategoryId",
                                                    "clarificationId",
                                                ]);
                                                onChange(category);
                                                dispatch(
                                                    getSubservices(
                                                        category.value,
                                                        "local"
                                                    )
                                                );
                                            }}
                                            label={t(
                                                "specialist-services.form.service-sector.label"
                                            )}
                                            placeholder={t(
                                                "specialist-services.form.service-sector.placeholder"
                                            )}
                                        />
                                    )}
                                />
                                <Controller
                                    name="subcategoryId"
                                    control={control}
                                    render={({
                                        field: {
                                            onChange,
                                            onBlur,
                                            value,
                                            name,
                                        },
                                    }) => (
                                        <Select
                                            name={name}
                                            value={value}
                                            error={
                                                errors.subcategoryId?.message
                                            }
                                            onBlur={(category) =>
                                                onBlur(category)
                                            }
                                            options={mapStateToOptions(
                                                subservices
                                            )}
                                            onSelect={(category) => {
                                                clearFields([
                                                    "clarificationId",
                                                ]);
                                                onChange(category);
                                                dispatch(
                                                    getClarifications(
                                                        category.value,
                                                        "local"
                                                    )
                                                );
                                            }}
                                            label={t(
                                                "specialist-services.form.service.label"
                                            )}
                                            placeholder={t(
                                                "specialist-services.form.service.placeholder"
                                            )}
                                        />
                                    )}
                                />
                                <Controller
                                    name="clarificationId"
                                    control={control}
                                    render={({
                                        field: {
                                            onChange,
                                            onBlur,
                                            value,
                                            name,
                                        },
                                    }) => (
                                        <Select
                                            name={name}
                                            error={
                                                errors.clarificationId?.message
                                            }
                                            value={value}
                                            onBlur={(category) =>
                                                onBlur(category)
                                            }
                                            options={mapStateToOptions(
                                                clarifications
                                            )}
                                            onSelect={(category) => {
                                                onChange(category);
                                            }}
                                            label={t(
                                                "specialist-services.form.service-clarification.label"
                                            )}
                                            placeholder={t(
                                                "specialist-services.form.service-clarification.placeholder"
                                            )}
                                        />
                                    )}
                                />
                            </>
                        )}
                    </div>
                    <div className="update-order-modal__form-left__item">
                        <h1 className="update-order-modal__form-left__item-title">
                            {t("order_adress_order")}
                        </h1>
                        <Controller
                            control={control}
                            name="city"
                            render={({
                                field: { onChange, onBlur, name, value },
                            }) => (
                                <LocationSelect
                                    name={name}
                                    options={cities}
                                    value={value}
                                    error={errors.city?.message}
                                    onSelect={(city) => {
                                        handleSelectCity(city);
                                        onChange(city.name);
                                    }}
                                    onBlur={(city) => onBlur(city.name)}
                                    type={SELECT_TYPES.WITHOUT_LABEL}
                                    label={t(
                                        "specialist-services.form.city.label"
                                    )}
                                    placeholder={t(
                                        "specialist-services.form.city.placeholder"
                                    )}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="district"
                            render={({
                                field: { value, onChange, onBlur },
                            }) => (
                                <LocationSelect
                                    value={value}
                                    options={districts}
                                    onSelect={({ name }) => onChange(name)}
                                    onBlur={({ name }) => onBlur(name)}
                                    type={SELECT_TYPES.WITHOUT_LABEL}
                                    error={errors.district?.message}
                                    label={t(
                                        "specialist-services.form.district.label"
                                    )}
                                    placeholder={t(
                                        "specialist-services.form.district.placeholder"
                                    )}
                                />
                            )}
                        />
                        <Input
                            inputChangeOptions={register("address")}
                            label="specialist-services.form.address.label"
                            options={{
                                placeholderText:
                                    "specialist-services.form.address.placeholder",
                            }}
                            type="secondary"
                            error={errors.address?.message}
                        />
                    </div>
                    <div className="update-order-modal__form-left__item">
                        <h1 className="update-order-modal__form-left__item-title">
                            {t("order_comment_title")}
                        </h1>
                        <Textarea
                            placeholderText="order_comment_placeholder"
                            inputChangeOptions={register("comment")}
                            errors={t(errors.comment?.message)}
                            label={t("orders.order-item.order-comments")}
                        />
                    </div>
                </div>
                <div className="update-order-modal__form-right">
                    <div className="update-order-modal__form-right__item">
                        <h1 className="update-order-modal__form-right__item-title">
                            {t("order_date_title")}
                        </h1>
                        <Controller
                            control={control}
                            name="completionDeadline"
                            render={({ field: { onChange, value, ref } }) => (
                                <DatePicker
                                    onChange={onChange}
                                    selected={value}
                                    errors={errors.completionDeadline}
                                    dateRef={(elem) => {
                                        elem && ref(elem.input);
                                    }}
                                    minDate={Date.now()}
                                    maxVisibleYears={100}
                                    label={t("orders.order-item.date")}
                                />
                            )}
                        />
                    </div>
                    <div className="update-order-modal__form-right__item">
                        <h1 className="update-order-modal__form-right__item-title">
                            {order?.fixedPrice
                                ? t("order_fixed_price_title")
                                : t("order_price_title")}
                        </h1>
                        <div className="update-order-modal__form-right__option">
                            {priceOptionsList.map(({ value }) => (
                                <span
                                    onClick={() => setTypeOfPrice(value)}
                                    className={`update-order-modal__form-right__option-price${
                                        typeOfPrice === value ? "--active" : ""
                                    }`}
                                    key={value}
                                >
                                    {t(value)}
                                </span>
                            ))}
                        </div>
                        {typeOfPrice === priceOptionsList[1].value ? (
                            <Input
                                inputChangeOptions={register("fixedPrice")}
                                options={{
                                    placeholderText:
                                        "specialist-services.form.price.placeholder",
                                }}
                                type="fixed-price"
                                error={errors.fixedPrice?.message}
                            />
                        ) : (
                            <DualInput
                                inputOptionsFirst={register("priceFrom")}
                                inputOptionsSecond={register("priceTo")}
                                errorsOptionsFirst={t(
                                    errors.priceFrom?.message
                                )}
                                errorsOptionsSecond={t(errors.priceTo?.message)}
                                type="range-dual-price"
                                watchedPriceFrom={priceFrom}
                            />
                        )}
                    </div>
                    <div className="update-order-modal__form-right__item">
                        <h1 className="update-order-modal__form-right__item-title">
                            {t("order_gender_title")}
                        </h1>
                        <Controller
                            control={control}
                            name="preferredGender"
                            render={({
                                field: { onChange, onBlur, value, name },
                            }) => (
                                <Select
                                    name={name}
                                    error={errors.preferredGender?.message}
                                    value={value}
                                    onBlur={(preferredGender) =>
                                        onBlur(preferredGender)
                                    }
                                    options={genders}
                                    onSelect={(preferredGender) =>
                                        onChange(preferredGender)
                                    }
                                    label={t("gender")}
                                    placeholder={t("order_gender_placeholder")}
                                />
                            )}
                        />
                    </div>
                    <div className="update-order-modal__form-right__item">
                        <h1 className="update-order-modal__form-right__item-title">
                            {t("order_age_title")}
                        </h1>
                        <DualInput
                            inputOptionsFirst={register("preferredYears.from")}
                            inputOptionsSecond={register("preferredYears.to")}
                            errorsOptionsFirst={t(
                                errors.preferredYears?.from?.message
                            )}
                            errorsOptionsSecond={t(
                                errors.preferredYears?.to?.message
                            )}
                            type="range-dual"
                            label={t("age")}
                        />
                    </div>
                    <div className="update-order-modal__form-right__item">
                        <h1 className="update-order-modal__form-right__item-title">
                            {t("order_photo_title")}
                        </h1>
                        <div className="order-item__photos">
                            <ImageUploader
                                eventManagementFunctions={{
                                    callback: (item) =>
                                        item.length > 0 &&
                                        dispatch(postOrderImage(_id, item)),
                                }}
                                type={IMAGE_UPLOADER_TYPES.ORDER_ITEM}
                                id={"upload-order-photo"}
                            />
                            {order && order.images && order.images.length
                                ? order.images
                                      .slice(0)
                                      .reverse()
                                      .map(({ url, _id: imageId }) => (
                                          <div
                                              className="order-item__photos__item"
                                              key={imageId}
                                          >
                                              <LazyImage
                                                  image={url}
                                                  type={typeOfLazeImage.content}
                                                  options={{
                                                      className:
                                                          "order-item__photos__item-img",
                                                      alt: "logo",
                                                  }}
                                              />
                                              <img
                                                  onClick={() =>
                                                      dispatch(
                                                          deleteOrderImage(
                                                              _id,
                                                              imageId
                                                          )
                                                      )
                                                  }
                                                  src={deletePhotoIcon}
                                                  className="order-item__photos__item-close"
                                              />
                                          </div>
                                      ))
                                : null}
                        </div>
                    </div>
                </div>
            </div>
            <div className="update-order-modal__btns">
                <button
                    className="update-order-modal__btns-save"
                    disabled={isEmptyObject(dirtyFields) || buttonStatus}
                    style={{
                        background:
                            buttonStatus &&
                            buttonLoader === "update-order" &&
                            "#dfdfdf",
                    }}
                    type="submit"
                >
                    {buttonStatus && buttonLoader === "update-order" ? (
                        <ButtonLoader size={38} />
                    ) : (
                        t("btn_save")
                    )}
                </button>
                <button
                    onClick={() => dispatch(closeModal())}
                    className="update-order-modal__btns-cancel"
                    type="button"
                >
                    {t("btn_cancel")}
                </button>
            </div>
        </form>
    );
};

UpdateOrder.propTypes = {
    details: PropTypes.object,
};

export default UpdateOrder;
