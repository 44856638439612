import { useState, useRef } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { printInvoiceOperation } from "../../../../balance.operations";
import { paymentOperationTypes } from "../../../../balance.constants";
import { userBalanceSelector } from "../../../../balance.selectors";
import { myAccountDataSelector } from "../../../../../my-account/my-account.selectors";

import { ReactComponent as TransactionIcon } from "../../../../../../assets/images/currency/transactions_icon.svg";
import { ReactComponent as IparaIcon } from "../../../../../../assets/images/currency/ipara.svg";
import { ReactComponent as CurrencyLira } from "../../../../../../assets/images/currency/lira.svg";
import { ReactComponent as PaymentReceiptIcon } from "../../../../../../assets/images/currency/balance-payment-receipt.svg";
import { ReactComponent as ArrowIcon } from "../../../../../../assets/images/vectors/arrow_down_grey.svg";

import "./index.scss";

const BalanceHistoryItem = ({ item }) => {
    const { createdAt, type, _id, moneyConvert, credit, debit } = item;

    const [open, setOpen] = useState(false);
    const linkRef = useRef(null);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const balanceData = useSelector(userBalanceSelector);
    const { firstName, lastName } = useSelector(myAccountDataSelector);

    const clickHandler = (id) => {
        if (id === _id) {
            setOpen(!open);
        }
    };

    const downloadInvoice = async () => {
        dispatch(printInvoiceOperation(_id, linkRef));
    };

    return (
        <div className="balance__history-item-wrapper">
            <div className="balance__history-item">
                <div className="balance__history-item-left">
                    <div>
                        <TransactionIcon />
                        <div className="balance__history-item-left-payment-system">
                            <IparaIcon />
                        </div>
                    </div>
                    <div className="balance__history-item-left-info">
                        <p className="balance__history-item-left-info-title">
                            {debit
                                ? t("balance-new.deposit")
                                : credit
                                ? t("balance-new.withdrawal")
                                : ""}
                        </p>
                        <p className="balance__history-item-left-info-date">
                            {dayjs(createdAt).format("DD.MM.YYYY H:mm")}
                        </p>
                    </div>
                </div>
                <div className="balance__history-item-right">
                    <div>
                        <div className="balance__history-item-right-info">
                            <span>
                                {type === paymentOperationTypes.withdrawal &&
                                    "-"}
                                {`${moneyConvert}`.replace(".", ",")}
                            </span>
                            <CurrencyLira />
                        </div>
                        <div
                            className="balance__history-item-right-download"
                            onClick={downloadInvoice}
                        >
                            <PaymentReceiptIcon />
                            <span>{t("balance-new.download-receipt")}</span>
                        </div>
                        <a ref={linkRef} style={{ display: "none" }}></a>
                    </div>
                    <div
                        className={`balance__history-item-right-arrow ${
                            open && "balance__history-item-right-arrow-reversed"
                        }`}
                    >
                        <ArrowIcon onClick={() => clickHandler(_id)} />
                    </div>
                </div>
            </div>

            {open && (
                <div className="balance__history-item-extended">
                    <div className="balance__history-item-extended-item">
                        <div className="balance__history-item-extended-item-key">
                            {t("balance-new.kind-of-payment")}:
                        </div>
                        <div className="balance__history-item-extended-item-value">
                            {type === paymentOperationTypes.deposit
                                ? t("balance-new.deposit")
                                : t("balance-new.withdrawal")}
                        </div>
                    </div>
                    <div className="balance__history-item-extended-item">
                        <div className="balance__history-item-extended-item-key">
                            {t("balance-new.payment-type")}:
                        </div>
                        <div className="balance__history-item-extended-item-value">
                            <IparaIcon />
                        </div>
                    </div>
                    <div className="balance__history-item-extended-item">
                        <div className="balance__history-item-extended-item-key">
                            {t("balance-new.full-name")}:
                        </div>
                        <div className="balance__history-item-extended-item-value">
                            {`${firstName} ${lastName}`}
                        </div>
                    </div>
                    <div className="balance__history-item-extended-item">
                        <div className="balance__history-item-extended-item-key">
                            {t("balance-new.total-balance")}:
                        </div>
                        <div className="balance__history-item-extended-item-value balance__history-item-extended-item-balance">
                            <span>{`${balanceData.money},${balanceData.pennies}`}</span>
                            <CurrencyLira />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

BalanceHistoryItem.propTypes = {
    item: PropTypes.shape({
        createdAt: PropTypes.string,
        type: PropTypes.string,
        _id: PropTypes.string,
        moneyConvert: PropTypes.number,
        debit: PropTypes.number,
        credit: PropTypes.number,
    }),
};

export default BalanceHistoryItem;
