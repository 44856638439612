import { Fragment, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import CountryCodesDropdown from "../../../../components/country-codes-dropdown/country-codes-dropdown";
import Input from "../../../../components/input/input";
import MyAccountInfoInitialImage from "../my-account-initial-image/my-account-initial-image";

import { myAccountFormSchema } from "../../../my-account/components/my-account-form/my-account-form.schema";
import { myAccountSelector } from "../../../my-account/my-account.selectors";

import ReactHelmet from "../../../../components/react-helmet/react-helmet";
import { getUser } from "../../../my-account/my-account.operations";
import { getPhotoByTypeGender } from "../../../../helpers/get-photo-by-type-gender";
import { adaptGender } from "../../../../helpers/genders";
import {
    myAccountInfoFirstConfig,
    myAccountInfoSecondConfig,
} from "../../my-account-info.config";
import { userRoles } from "../../../../constants/user-roles";
import routerBook from "../../../../router/router-book";

import "./index.scss";

const MyAccountInfoForm = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { data, avatar } = useSelector(myAccountSelector);
    const { role, gender, phone, birthday } = data;

    const {
        register,
        formState: { errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(myAccountFormSchema),
        defaultValues: useMemo(() => {
            return {
                ...data,
                avatar,
                phoneValue: phone?.number,
                gender: t(adaptGender(gender).label),
                birthday: dayjs(birthday).format("DD.MM.YYYY"),
            };
        }, [data, avatar]),
    });

    useEffect(() => {
        dispatch(getUser());
    }, []);

    return (
        <Fragment>
            <ReactHelmet title="my_account_title" />

            <div className="my-account-info">
                <span className="my-account-info__title">
                    {t("my_account_title")}
                </span>
                <span className="my-account-info__subtitle">
                    {t(`my_account_${role.toLowerCase()}`)}
                </span>
                <div className="my-account-info__form">
                    <MyAccountInfoInitialImage
                        defaultImage={getPhotoByTypeGender(avatar, gender)}
                    />
                    <form>
                        {myAccountInfoFirstConfig.map((item) => (
                            <Input
                                type={item.typeInfo}
                                key={item.registerInfo}
                                label={t(item.labelInfo)}
                                inputChangeOptions={register(item.registerInfo)}
                                options={{
                                    placeholderText: item.placeholderTextInfo,
                                }}
                            />
                        ))}

                        <Input
                            type="info"
                            label={t("label_birthday")}
                            inputChangeOptions={register("birthday")}
                        />
                        <Input
                            type="info"
                            label={t("gender")}
                            inputChangeOptions={register("gender")}
                        />

                        {myAccountInfoSecondConfig.map((item) => (
                            <Input
                                type={item.typeInfo}
                                key={item.registerInfo}
                                label={t(item.labelInfo)}
                                inputChangeOptions={register(item.registerInfo)}
                                options={{
                                    placeholderText: item.placeholderTextInfo,
                                }}
                            />
                        ))}

                        <div className="form-container">
                            <div className="form-container__info">
                                <label className="form-container__label">
                                    {t("my_account_phone")}
                                </label>
                                <label className="form-container__error">
                                    {t(errors?.phone?.message)}
                                </label>
                            </div>
                            <div className="form-container__phone-form">
                                <CountryCodesDropdown
                                    register={register}
                                    phoneValueName="phoneValue"
                                    phoneCodeName="phoneCode"
                                    registeredDialCode={phone?.dialCode}
                                    registeredFlag={phone?.flag}
                                    fullWidth
                                    readOnly
                                />
                            </div>
                            <Input
                                type="info"
                                label="my_account_city"
                                options={{
                                    placeholderText:
                                        "my_account_city_placeholder",
                                }}
                                inputChangeOptions={register("city")}
                            />
                            <Input
                                type="info"
                                label="my_account_district"
                                options={{
                                    placeholderText:
                                        "my_account_district_placeholder",
                                }}
                                inputChangeOptions={register("district")}
                            />
                        </div>
                    </form>
                    {role === userRoles.specialist ? (
                        <button
                            className="my-account-info__form--promote"
                            onClick={() =>
                                navigate(
                                    routerBook.accessForSpecialist.promotion
                                )
                            }
                            type="button"
                        >
                            {t("btn_promote")}
                        </button>
                    ) : (
                        ""
                    )}

                    <button
                        className="my-account-info__form--edit"
                        onClick={() => navigate(routerBook.myAccount)}
                        type="button"
                    >
                        {t("btn_edit")}
                    </button>
                </div>
            </div>
        </Fragment>
    );
};

export default MyAccountInfoForm;
