import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { promotionSelector } from "../../promotion.selectors";
import { getPromotionById } from "../../promotion.operations";
import { promotionTypesContants } from "../../promotion.constants";

import BackButton from "../../../../components/back-button/back-button";
import MainButton from "../../../../components/main-button/main-button";
import { backButtonConfig } from "../../../../components/back-button/back-button.config";
import { openModal } from "../../../../components/modal/modal.actions";
import { modalConstants } from "../../../../components/modal/modal.constants";
import { getLanguage } from "../../../../helpers/language";

import { ReactComponent as LiraIcon } from "../../../../assets/images/currency/lira.svg";
import { ReactComponent as PromotionTopIcon } from "../../../../assets/images/promotion/promotion-methods-top-icon.svg";
import { ReactComponent as PromotionSearchIcon } from "../../../../assets/images/promotion/promotion-methods-search-icon.svg";
import { ReactComponent as PromotionProfileIcon } from "../../../../assets/images/promotion/promotion-methods-profile-icon.svg";

import promotionTopImage from "../../../../assets/images/promotion/promotion-methods-top.png";
import promotionSearchImage from "../../../../assets/images/promotion/promotion-methods-search.png";
import promotionProfileImage from "../../../../assets/images/promotion/promotion-methods-profile.png";

import "./index.scss";

const PromotionMethods = () => {
    const { promotion } = useSelector(promotionSelector);
    const { value: language } = getLanguage();

    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {
        extendedDescription,
        nameTranslations,
        prices,
        _id,
        type,
        activatedAccounts,
        bought,
        rate,
    } = promotion;

    useEffect(() => dispatch(getPromotionById(id)), []);

    const handleBuyService = ({
        _id,
        name,
        type,
        prices,
        nameTranslations,
    }) => {
        dispatch(
            openModal({
                isOpen: true,
                closeIcon: false,
                renderType: modalConstants.promote,
                details: {
                    options: [{ nameTranslations, _id, name, type, prices }],
                },
            })
        );
    };

    const renderPromotionIcon = (type) => {
        switch (type) {
            case promotionTypesContants.TOP_CATEGORY: {
                return <PromotionTopIcon />;
            }
            case promotionTypesContants.TOP: {
                return <PromotionSearchIcon />;
            }
            case promotionTypesContants.BEAUTY_NAME: {
                return <PromotionProfileIcon />;
            }
            default:
                return;
        }
    };

    const renderPromotionBottomImage = (type) => {
        switch (type) {
            case promotionTypesContants.TOP_CATEGORY: {
                return promotionTopImage;
            }
            case promotionTypesContants.TOP: {
                return promotionSearchImage;
            }
            case promotionTypesContants.BEAUTY_NAME: {
                return promotionProfileImage;
            }
            default:
                return;
        }
    };

    return (
        <>
            <div className="promotion-methods">
                <div className="promotion-methods__header">
                    <BackButton
                        onClickCallback={() =>
                            navigate(backButtonConfig[true].href, {
                                state: true,
                            })
                        }
                        title={t(backButtonConfig[true].title)}
                    />
                </div>
                <div className="promotion-methods__body">
                    <div>
                        <h1 className="promotion-methods__body-title">
                            {t(nameTranslations && nameTranslations[language])}
                        </h1>
                        <div className="promotion-methods__body-subtitle">
                            {extendedDescription &&
                                extendedDescription[language]}
                        </div>
                        <div className="promotion-methods__body-buy-button">
                            <MainButton
                                onClick={() =>
                                    handleBuyService({
                                        _id,
                                        nameTranslations,
                                        name: nameTranslations[language],
                                        type,
                                        prices,
                                    })
                                }
                                buttonStyle="btn--dark"
                                buttonSize="btn--medium"
                                type="submit"
                            >
                                {t("btn_buy")}
                            </MainButton>
                            <div className="promotion-methods__body-price">
                                <span>{prices?.month[0]?.price}</span>
                                <LiraIcon className="promotion-methods__body-price-icon" />
                            </div>
                        </div>
                    </div>

                    <div className="promotion-methods__body-icon">
                        {renderPromotionIcon(type)}
                    </div>
                </div>
            </div>

            <div className="promotion-methods__bottom-image">
                <div className="promotion-methods__rate">
                    <div className="promotion-methods__rate-item">
                        <div>
                            <div className="promotion-methods__rate-item-value">
                                {activatedAccounts}
                            </div>
                            <div className="promotion-methods__rate-item-title">
                                {t(
                                    "promotion.promotional-methods.accounts-activated"
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="promotion-methods__rate-item">
                        <div>
                            <div className="promotion-methods__rate-item-value">
                                {bought}
                            </div>
                            <div className="promotion-methods__rate-item-title">
                                {t("promotion.promotional-methods.bought")}
                            </div>
                        </div>
                    </div>
                    <div className="promotion-methods__rate-item">
                        <div>
                            <div className="promotion-methods__rate-item-value">
                                {rate}
                            </div>
                            <div className="promotion-methods__rate-item-title">
                                {t("promotion.promotional-methods.stars-rated")}
                            </div>
                        </div>
                    </div>
                </div>
                <img src={renderPromotionBottomImage(type)} alt="" />
            </div>
        </>
    );
};

export default PromotionMethods;
