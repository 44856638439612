import * as api from "../../services/requests/chats";

import { successToastify } from "../../components/toastify/toastify.operations";
import { getSpecialistProfileByID } from "../specialists-list/specialist-list.operations";
import { setUserOnlineStatus } from "../../components/websockets/websockets.actions";
import { specialistIsNotDefined } from "../specialists-list/specialist-list.actions";
import { websocketsSelector } from "../../components/websockets/websockets.selectors";
import { myAccountDataSelector } from "../my-account/my-account.selectors";
import { chatsNameSelector, chatsSelector } from "./chats.selectors";
import { getOrderTaskFulfilled } from "../orders/oders.actions";
import { ordersSelector } from "../orders/orders.selectors";
import * as chatActions from "./chats.actions";
import {
    changeGlobalLoader,
    changeButtonLoader,
} from "../../components/loader/loader.actions";
import {
    getOrderTask,
    getOrderTaskOtherSpecialist,
} from "../orders/orders.operations";

import { socketEvents } from "../../components/websockets/websockets.constants";
import { accessTaskStatus } from "../discussion-work-project/components/chat/chat.config";
import { operationStatus } from "../discussion-work-project/components/chat/components/chat-message/chat-message.config";
import { userRoles } from "../../constants/user-roles";
import routerBook from "../../router/router-book";
import { closeModal } from "../../components/modal/modal.actions";
import { deleteSupportQuestion } from "../support/support.operations";

// get chat list
export const getChatList =
    (sizeItemOnPage, params = "") =>
    (dispatch, getState) => {
        const name = chatsNameSelector(getState());

        dispatch(chatActions.getChatListPending());
        dispatch(changeGlobalLoader(true));

        return api
            .getChatList(`?limit=${sizeItemOnPage}` + name + params)
            .then(({ items, totalCount: count }) => {
                dispatch(chatActions.getChatListFulfilled({ items, count }));
                dispatch(editOnlineUsers(items));
            })
            .catch((error) => dispatch(chatActions.getChatListRejected(error)))
            .finally(() => dispatch(changeGlobalLoader(false)));
    };

// get chat
export const getChat = (id, role) => (dispatch, getState) => {
    const { onlineParticipants } = websocketsSelector(getState());
    const { _id: ownId } = myAccountDataSelector(getState());

    dispatch(chatActions.getChatPending());
    dispatch(changeGlobalLoader(true));

    return api
        .getChat(id)
        .then((chat) => {
            const { participants, messages, taskId, orderId } = chat;
            const { items, totalCount, carousel } = messages;

            const participant = participants.find(({ _id }) => _id !== ownId);

            const newMessages = { items, carousel, count: totalCount };

            const newChat = {
                ...chat,
                messages: newMessages,
                participant,
            };

            dispatch(chatActions.getChatFulfilled(newChat));

            dispatch(readMessagesOperation());

            if (taskId) dispatch(getOrderTask(orderId, taskId));

            if (role === userRoles.customer)
                participant
                    ? dispatch(getSpecialistProfileByID(participant?._id))
                    : dispatch(specialistIsNotDefined());

            if (orderId && role === userRoles.customer)
                dispatch(getOrderTaskOtherSpecialist(orderId, taskId));

            if (participant && participant.isOnline) {
                const newOnlineUser = [...onlineParticipants, participant._id];
                if (!onlineParticipants.includes(participant._id))
                    dispatch(setUserOnlineStatus(newOnlineUser));
            }
        })
        .catch((error) => dispatch(chatActions.getChatRejected(error)))
        .finally(() => dispatch(changeGlobalLoader(false)));
};

// post chat
export const postChat = (data, navigate) => (dispatch) => {
    dispatch(changeButtonLoader({ status: true }));
    dispatch(chatActions.postChatPending());

    return api
        .postChat(data)
        .then(({ _id: chatId }) => {
            navigate(`${routerBook.chats}/${chatId}`, { state: true });
            dispatch(successToastify("toastify.chats.created"));
        })
        .catch((error) => dispatch(chatActions.postChatRejected(error)))
        .finally(() => dispatch(changeButtonLoader({ status: false })));
};

// delete chat
export const deleteChat = (id) => (dispatch) => {
    dispatch(changeButtonLoader({ status: true, button: id }));
    dispatch(chatActions.deleteChatPending());

    return api
        .deleteChat(id)
        .then(() => dispatch(successToastify("toastify.chats.deleted")))
        .catch((error) => dispatch(chatActions.deleteChatRejected(error)))
        .finally(() =>
            dispatch(changeButtonLoader({ status: false, button: "" }))
        );
};

// disable chat
export const disableChat = (chatId, questionId) => (dispatch, getState) => {
    dispatch(chatActions.disableChatPending());
    const { chat } = chatsSelector(getState());

    return api
        .disableChat(chatId)
        .then(() => {
            const updateChat = { ...chat, isDisabled: true };
            dispatch(chatActions.disableChatFulfilled(updateChat));
            dispatch(deleteSupportQuestion(questionId));
        })
        .catch((error) => dispatch(chatActions.disableChatRejected(error)));
};

// set query
export const editChatListPage = (size, data) => (dispatch) => {
    dispatch(chatActions.setPage(`&page=${data}`));
    const url = `&page=${data}`;
    dispatch(getChatList(size, url));
};

export const editChatListName = (size, data) => (dispatch) => {
    dispatch(chatActions.setName(`&name=${data}`));
    dispatch(getChatList(size));
};

// receive new chat
export const editChats = (chat) => (dispatch, getState) => {
    const { chatList } = chatsSelector(getState());

    const newItems = [chat, ...chatList.items];
    const newCount = chatList.count + 1;
    const newChats = { items: newItems, count: newCount };

    const duplicate = chatList.items.find(({ _id }) => _id === chat._id);

    if (!duplicate) {
        dispatch(chatActions.getChatListFulfilled(newChats));
        dispatch(editOnlineUsers(newItems));
    }
};

// receive new message
export const editChat = (message) => (dispatch, getState) => {
    const { orderTask } = ordersSelector(getState());
    const { chat } = chatsSelector(getState());
    const { items, count, carousel } = chat.messages;

    const duplicate = items.find(({ _id }) => _id === message._id);

    const updatedMessages = {
        carousel: message?.image ? [...carousel, message] : carousel,
        items: [...items, message],
        count: count + 1,
    };


    const updatedChat = { ...chat, messages: updatedMessages };

    if (!duplicate) {
        if (message.messageType === "operation") {
            const { completed, inWork, priceIsFixed } = accessTaskStatus;
            const { actionType } = message.operation;

            const updatedTask = (status) => ({ ...orderTask, status });
            const disabledChat = { ...updatedChat, isDisabled: true };

            const { _id: messageId, createdBy: proposedBy } = message;
            const { priceProposal, priceProposalHistory } = orderTask;
            const taskWithFixPrice = {
                ...orderTask,
                priceProposalHistory: [...priceProposalHistory, messageId],
                priceProposal: { ...priceProposal, proposedBy },
            };

            switch (actionType) {
                case operationStatus.PROPOSED_FIX_PRICE:
                    dispatch(getOrderTaskFulfilled(taskWithFixPrice));
                    break;

                case operationStatus.COMPLETED_APPROVED:
                    dispatch(getOrderTaskFulfilled(updatedTask(completed)));
                    break;

                case operationStatus.IN_WORK_APPROVED:
                    dispatch(getOrderTaskFulfilled(updatedTask(inWork)));
                    break;

                case operationStatus.PRICE_APPROVED:
                    dispatch(getOrderTaskFulfilled(updatedTask(priceIsFixed)));
                    break;

                case operationStatus.WORK_CANCELLED:
                    return dispatch(chatActions.getChatFulfilled(disabledChat));

                default:
                    break;
            }
        }
        dispatch(chatActions.getChatFulfilled(updatedChat));
    }
};

// receive online users
export const editOnlineUsers = (items) => (dispatch, getState) => {
    const { onlineParticipants } = websocketsSelector(getState());
    const { _id: ownId } = myAccountDataSelector(getState());

    const onlineChatParticipants = items
        .map(({ participants }) =>
            participants.find(({ _id }) => _id !== ownId)
        )
        .filter(
            (user) => user?.isOnline && !onlineParticipants.includes(user?._id)
        );
    if (onlineChatParticipants.length > 0) {
        onlineChatParticipants.forEach(({ _id }) =>
            dispatch(setUserOnlineStatus([...onlineParticipants, _id]))
        );
    }
};

// read message
export const readMessagesOperation = () => (dispatch, getState) => {
    const { _id: ownId } = myAccountDataSelector(getState());
    const { socket } = websocketsSelector(getState());
    const { chat } = chatsSelector(getState());

    const { _id: chatId, messages, participant, isDisabled } = chat;

    let messageIds = [];

    messages.items
        .filter(
            ({ createdBy, readBy }) =>
                createdBy === participant?._id && !readBy.includes(ownId)
        )
        .forEach(({ _id }) => messageIds.push(_id));

    if (messageIds.length > 0 && socket && !isDisabled) {
        socket.emit(socketEvents.READ_MESSAGES, { chatId, messageIds });
        messageIds = [];
    }
};

export const updateReadByMessages = (messageIds) => (dispatch, getState) => {
    const { chat } = chatsSelector(getState());
    const { messages, participant } = chat;

    const updateMessagesList = messages.items.map((item) =>
        messageIds.includes(item._id) && !item.readBy.includes(participant._id)
            ? { ...item, readBy: [...item.readBy, participant._id] }
            : item
    );

    const updateMessages = { items: updateMessagesList, count: messages.count , carousel: messages.carousel };
    const updateChat = { ...chat, messages: updateMessages };

    dispatch(chatActions.getChatFulfilled(updateChat));
};

// receive new operation in chat
export const editOperations = (operation) => (dispatch, getState) => {
    const { chat } = chatsSelector(getState());

    const newOperation = { ...operation, createdAt: new Date() };
    const updatedOperationsHistory = [...chat.operationsHistory, newOperation];
    const newChat = { ...chat, operationsHistory: updatedOperationsHistory };

    dispatch(chatActions.getChatFulfilled(newChat));
};

// receive updated chat
export const updatedChatsOperation = (chat) => (dispatch, getState) => {
    const { chatList } = chatsSelector(getState());
    const { items, count } = chatList;

    const isPresented = items.some(({ _id }) => _id === chat._id);

    if (isPresented) {
        const newItems = items.map((el) => (el._id === chat._id ? chat : el));
        dispatch(chatActions.getChatListFulfilled({ items: newItems, count }));
    } else {
        const newChats = { items: [chat, ...items], count };
        dispatch(chatActions.getChatListFulfilled(newChats));
    }
};

// post chat file
export const postChatFile = (id, file) => (dispatch) => {
    dispatch(chatActions.postChatFilePending());

    let formData = new FormData();
    formData.append("file", file);

    return api
        .postChatFileRequest(id, formData)
        .then((message) => editChat(message))
        .catch((error) => dispatch(chatActions.postChatFileRejected(error)));
};

// get complaints types
export const getAllComplaintsTypes = () => (dispatch) => {
    dispatch(chatActions.getComplaintsTypesPending());
    return api
        .getComplaintsTypes()
        .then(({ items }) => {
            dispatch(chatActions.getComplaintsTypesFulfilled({ items }));
        })
        .catch(({ error }) => {
            dispatch(chatActions.getComplaintsTypesRejected(error));
        });
};

export const postComplaint = (data) => (dispatch) => {
    dispatch(chatActions.postComplaintPending());
    return api
        .postComplaint(data)
        .then(() => {
            dispatch(chatActions.postComplaintFulfilled());
            dispatch(successToastify("toastify.chats.complaint_created"));
            dispatch(closeModal());
        })
        .catch(({ error }) => {
            dispatch(chatActions.postComplaintRejected(error));
        });
};
