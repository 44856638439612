import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import React, { memo } from "react";

import { closeModal } from "../../../../components/modal/modal.actions";
import { createSupportQuestion } from "../../support.operations";
import { supportCreateTicketModalSchema } from "./support-create-ticket-modal.schema";
import SELECT_TYPES from "../../../../components/select/select.constants";
import Select from "../../../../components/select/select";
import Input from "../../../../components/input/input";
import {
    categoryConfig,
    supportCreateTicketModalConfig,
} from "./support-create-ticket-modal.config";

import "./index.scss";

const SupportCreateTicketModal = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const {
        formState: { errors },
        handleSubmit,
        register,
        control,
    } = useForm({
        mode: "all",
        defaultValues: {},
        resolver: yupResolver(supportCreateTicketModalSchema),
    });

    const handleFormSubmit = (data) => {
        dispatch(createSupportQuestion(data));
    };

    return (
        <div className="support-create-ticket-modal">
            <h1 className="support-create-ticket-modal__title">
                {t("support.modal.title")}
            </h1>
            <form
                className="support-create-ticket-modal__form"
                onSubmit={handleSubmit(handleFormSubmit)}
            >
                <div className="support-create-ticket-modal__form-content">
                    <Controller
                        control={control}
                        name="category"
                        render={({
                            field: { onChange, onBlur, value, name },
                        }) => (
                            <Select
                                placeholder={t("support.category.placeholder")}
                                onSelect={({ value }) => onChange(value)}
                                onBlur={({ value }) => onBlur(value)}
                                label={t("support.category.label")}
                                error={errors.category?.message}
                                type={SELECT_TYPES.WITH_LABEL}
                                options={categoryConfig}
                                value={value}
                                name={name}
                            />
                        )}
                    />
                    {supportCreateTicketModalConfig.map(
                        ({ label, placeholderText, registerName, type }) => (
                            <Input
                                key={registerName}
                                label={label}
                                type={type}
                                options={{ placeholderText }}
                                error={errors[registerName]?.message}
                                inputChangeOptions={register(registerName)}
                            />
                        )
                    )}
                </div>
                <div className="support-create-ticket-modal__form-btns">
                    <button
                        className="support-create-ticket-modal__form-btns-cancel"
                        onClick={() => dispatch(closeModal())}
                        type="button"
                    >
                        {t("btn_cancel")}
                    </button>
                    <button
                        className="support-create-ticket-modal__form-btns-save"
                        type="submit"
                    >
                        {t("btn_save")}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default memo(SupportCreateTicketModal);
