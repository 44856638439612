import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import AutocompleteSearch from "../../../../components/autocomplete-search/autocomplete-search";

import { userRoles } from "../../../../constants/user-roles";
import { roleSelector } from "../../../my-account/my-account.selectors";
import { citiesLoaderSelector } from "../../../../components/cities-loader/cities-loader.selectors";
import SELECT_TYPES from "../../../../components/select/select.constants";

import SpecialistBackground from "../../../../assets/images/background-images/homepage_banner_specialist.png";
import CustomerBackground from "../../../../assets/images/background-images/homepage_banner_customer.png";
import LocationSelect from "../../../../components/location-select/location-select";

import { getAllDistricts } from "../../../../components/cities-loader/cities-loader.operations";

import "./index.scss";
import {
    setCity,
    setDistrict,
} from "../../../../components/all-result/all-result.actions";
import { allResultParamsSelector } from "../../../../components/all-result/all-result.selectors";

const Banner = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { role } = useSelector(roleSelector);
    const { cities, districts } = useSelector(citiesLoaderSelector);
    const { city, district } = useSelector(allResultParamsSelector);

    const bannerBackgroundSelector = (role) => {
        switch (role) {
            case userRoles.customer:
                return { backgroundImage: `url(${CustomerBackground})` };
            case userRoles.specialist:
                return { backgroundImage: `url(${SpecialistBackground})` };
            default:
                return { backgroundImage: `url(${CustomerBackground})` };
        }
    };

    const handleChangeCity = (city) => dispatch(setCity(city));
    const handleChangeDistrict = (district) => dispatch(setDistrict(district));

    useEffect(() => {
        if (city.state_code) dispatch(getAllDistricts(city.state_code));
    }, [city]);

    return (
        <section className="banner" style={bannerBackgroundSelector(role)}>
            <div className="banner__content">
                <h1 className="banner__title">
                    <span className="banner__title--green">Wconnect</span> -{" "}
                    {t("profile_banner_title_first")}
                    <br />
                    {t("profile_banner_title_second")}
                </h1>
                <div className="banner__search">
                    <div className="banner__search_autocomplete">
                        <AutocompleteSearch />
                    </div>
                    <div className="banner__search__location">
                        <div className="banner__search__location_country">
                            <LocationSelect
                                value={city.name}
                                options={cities}
                                styleHeight={"60px"}
                                placeholder="my_account_city_placeholder"
                                onSelect={handleChangeCity}
                                type={SELECT_TYPES.WITHOUT_LABEL}
                            />
                        </div>
                        <div className="banner__search__location_district">
                            <LocationSelect
                                value={district.name}
                                options={districts}
                                styleHeight={"60px"}
                                placeholder="my_account_district_placeholder"
                                onSelect={handleChangeDistrict}
                                type={SELECT_TYPES.WITHOUT_LABEL}
                            />
                        </div>
                    </div>
                </div>
                <div className="banner__description">
                    <h2 className="banner__description_title">
                        {t("profile_banner_description_first")}{" "}
                        <span className="banner__description_title--green">
                            10000
                        </span>{" "}
                        {t("profile_banner_description_second")}
                    </h2>
                    <h3 className="banner__description_subtitle">
                        {t("profile_banner_description_third")}
                    </h3>
                </div>
            </div>
        </section>
    );
};

export default Banner;
