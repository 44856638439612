import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { Fragment } from "react";

import ReactHelmet from "../../../../components/react-helmet/react-helmet";
import BackButton from "../../../../components/back-button/back-button";
import { backButtonConfig } from "../../../../components/back-button/back-button.config";

import SupportChatBody from "./components/support-chat-body/support-chat-body";

import "./index.scss";

const SupportChat = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();

    return (
        <Fragment>
            <ReactHelmet title={t("support.chat.support")} />
            <div className="support_chat">
                <div className="support_chat__header">
                    <div className="support_chat__header_btn">
                        <BackButton
                            onClickCallback={() =>
                                navigate(
                                    backButtonConfig[location.state].href,
                                    { state: true }
                                )
                            }
                            title={t(backButtonConfig[location.state].title)}
                        />
                    </div>
                    <div className="support_chat__header_main">
                        <h1 className="support_chat__header_main_title">
                            {t("support.chat.support")}
                        </h1>
                    </div>
                </div>
                <div className="support_chat__body">
                    <SupportChatBody />
                </div>
            </div>
        </Fragment>
    );
};

export default SupportChat;
