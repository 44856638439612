import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import React from "react";

import "./index.scss";

const Textarea = ({
    inputChangeOptions = {},
    placeholderText = "",
    errors = false,
    label = "",
}) => {
    const { t } = useTranslation();

    return (
        <>
            {label && (
                <div className="custom-textarea__info">
                    <label className="custom-textarea__label">{label}</label>
                    {errors ? (
                        <p className="custom-textarea__error">{errors}</p>
                    ) : null}
                </div>
            )}
            <textarea
                className={`custom-textarea${errors ? "__erros" : ""}`}
                placeholder={t(placeholderText)}
                {...inputChangeOptions}
            />
        </>
    );
};

Textarea.propTypes = {
    errors: PropTypes.PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    inputChangeOptions: PropTypes.object,
    placeholderText: PropTypes.string,
    label: PropTypes.string,
};

export default Textarea;
