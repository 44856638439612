import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { buttonLoaderSelector } from "../../../../../../../components/loader/loader.selectors";
import { putTaskStatus } from "../../../../../../orders/orders.operations";
import { closeModal } from "../../../../../../../components/modal/modal.actions";

import ButtonLoader from "../../../../../../../components/loader/components/button-loader/button-loader";
import { accessTaskStatus } from "../../../chat.config";

import "./index.scss";

const DeclinePropose = ({
    details: { firstName, lastName, orderId, taskId, taskStatus },
}) => {
    const { status: isLoading } = useSelector(buttonLoaderSelector);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handelCancelPropose = (e) => {
        e.preventDefault();
        dispatch(putTaskStatus(orderId, taskId, "cancel"));
        dispatch(closeModal());
    };

    return (
        <div className="decline_propose">
            <h5 className="decline_propose_title">
                {t("btn_question_reject")}
                <span className="decline_propose_title_marked">
                    {" "}
                    {firstName} {lastName}
                </span>
                ?
            </h5>
            {taskStatus === accessTaskStatus.inWork && (
                <span className="decline_propose_subtitle">
                    {t("orders.order-item.cancel-confirm-commission-info")}
                </span>
            )}
            <div className="decline_propose_services">
                <button
                    className="decline_propose_services_cancel"
                    onClick={() => dispatch(closeModal())}
                >
                    {t("btn_cancel")}
                </button>
                <button
                    style={{ background: isLoading ? "#f3f4f6" : "" }}
                    className="decline_propose_services_decline"
                    onClick={handelCancelPropose}
                    disabled={isLoading}
                    type="submit"
                >
                    {isLoading ? <ButtonLoader size={38} /> : t("btn_reject")}
                </button>
            </div>
        </div>
    );
};

DeclinePropose.propTypes = {
    details: PropTypes.object,
};

export default DeclinePropose;
