import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { typeOfLazeImage } from "../../../../../components/lazy-image/lazyImage.constansts";
import LazyImage from "../../../../../components/lazy-image/lazy-image";

import "./index.scss";
import { Icon } from "../../../../../components/image/image";

const UploadedImage = ({ handleUploadClick, selectedFile, onDelete }) => {
    const { t } = useTranslation();

    const handleDeleteAvatar = (e) => {
        e.stopPropagation();
        onDelete();
    };
    return (
        <div className="uploaded-container" onClick={handleUploadClick}>
            <div className="uploaded-image-container">
                <LazyImage
                    image={selectedFile}
                    type={typeOfLazeImage.avatar}
                    options={{
                        alt: "avatar",
                        className: "uploaded-image",
                    }}
                />

                <div className="uploaded-image-container_delete">
                    <div
                        className="uploaded-image-container_delete_slide"
                        onClick={handleDeleteAvatar}
                    >
                        <div className="uploaded-image-container_delete_slide_text">
                            {t("btn_delete")}
                        </div>
                    </div>
                </div>
            </div>
            <div className="uploaded-container-plus">
                <Icon type="cameraGreen" />
            </div>
        </div>
    );
};

UploadedImage.propTypes = {
    handleUploadClick: PropTypes.func.isRequired,
    selectedFile: PropTypes.any.isRequired,
    onDelete: PropTypes.func,
};

export default UploadedImage;
