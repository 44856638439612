import PropTypes from "prop-types";

const AssessmentIcon = ({ fill = false }) => (
    <svg
        width="31"
        height="29"
        viewBox="0 0 31 29"
        xmlns="http://www.w3.org/2000/svg"
        style={{
            cursor: "pointer",
            transition: "0.1s linear",
            fill: fill ? "#33B378" : "transparent",
        }}
    >
        <path
            d="M15.5 2.14298L19.5059 9.73562L19.7354 10.1705L20.2199 10.2543L28.6788 11.7179L22.6957 17.8741L22.353 18.2267L22.423 18.7134L23.645 27.2106L15.9412 23.4226L15.5 23.2057L15.0588 23.4226L7.35505 27.2106L8.57703 18.7134L8.64702 18.2267L8.30432 17.8741L2.32119 11.7179L10.7801 10.2543L11.2646 10.1705L11.4941 9.73562L15.5 2.14298Z"
            stroke="#33B378"
            strokeWidth="2"
        />
    </svg>
);

AssessmentIcon.propTypes = { fill: PropTypes.any };

export default AssessmentIcon;
