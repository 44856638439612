import { citiesLoaderTypes } from "./cities-loader.types";

export const getAllCitiesPending = () => ({
    type: citiesLoaderTypes.GET_ALL_CITIES_PENDING,
});

export const getAllCitiesFulfilled = (data) => ({
    type: citiesLoaderTypes.GET_ALL_CITIES_FULFILLED,
    payload: data,
});

export const getAllCitiesRejected = (data) => ({
    type: citiesLoaderTypes.GET_ALL_CITIES_REJECTED,
    payload: data,
});

export const getAllDistrictsPending = () => ({
    type: citiesLoaderTypes.GET_ALL_DISTRICTS_PENDING,
});

export const getAllDistrictsFulfilled = (data) => ({
    type: citiesLoaderTypes.GET_ALL_DISTRICTS_FULFILLED,
    payload: data,
});

export const getAllDistrictsRejected = (data) => ({
    type: citiesLoaderTypes.GET_ALL_DISTRICTS_REJECTED,
    payload: data,
});
