import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import React, { memo } from "react";

import { myAccountDataSelector } from "../../../../../my-account/my-account.selectors";
import { getChat } from "../../../../../chats/chats.operations";

import { showLastMessage } from "../../../../../../helpers/show-last-message";
import LazyImage from "../../../../../../components/lazy-image/lazy-image";
import { isEmptyObject } from "../../../../../../helpers/is-empty-object";
import { typeOfLazeImage } from "../../../../../../components/lazy-image/lazyImage.constansts";
import { getPhotoByTypeGender } from "../../../../../../helpers/get-photo-by-type-gender";
import routerBook from "../../../../../../router/router-book";

import { ReactComponent as CurrencyLira } from "../../../../../../assets/images/currency/lira.svg";
import "./index.scss";

const SuggestionsItem = ({ chat = {}, specialist = {} }) => {
    const { avatar, gender, isOnline, lastName, firstName } = specialist;
    const { _id: chatId, fixedPrice } = chat;

    const { role } = useSelector(myAccountDataSelector);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleOpenChat = () => {
        dispatch(getChat(chatId, role));
        navigate(`${routerBook.chats}/${chatId}`, { state: true });
    };

    return (
        <div className="discussion_work_project_suggestions_item">
            <div className="discussion_work_project_suggestions_item__img">
                <span className="discussion_work_project_suggestions_item__img_avatar">
                    <LazyImage
                        image={getPhotoByTypeGender(avatar, gender)}
                        type={typeOfLazeImage.avatar}
                        options={{
                            className:
                                "discussion_work_project_suggestions_item__img_avatar",
                            title: isOnline ? t("online") : t("offline"),
                            alt: "avatar",
                        }}
                    />
                    {isOnline && (
                        <span className="discussion_work_project_suggestions_item__img_avatar_online" />
                    )}
                </span>
            </div>
            <div className="discussion_work_project_suggestions_item__info">
                <div className="discussion_work_project_suggestions_item__header">
                    <div className="discussion_work_project_suggestions_item__header_info">
                        <div className="discussion_work_project_suggestions_item__header_info_title">
                            {!isEmptyObject(specialist)
                                ? `${firstName} ${lastName}`
                                : t("chats.deleted_user")}
                        </div>
                        <div className="discussion_work_project_suggestions_item__header_info_price">
                            {fixedPrice || 0} <CurrencyLira />
                        </div>
                    </div>
                    <button
                        onClick={handleOpenChat}
                        className="discussion_work_project_suggestions_item__header_btn"
                    >
                        {t("chats.go_to_chat")}
                    </button>
                </div>
                <div className="discussion_work_project_suggestions_item__body">
                    <div className="discussion_work_project_suggestions_item__body_description">
                        {chat && chatId
                            ? showLastMessage(chat.lastMessage)
                            : t("chats.no_messages_yet")}
                    </div>
                </div>
            </div>
        </div>
    );
};

SuggestionsItem.propTypes = {
    specialist: PropTypes.object,
    chat: PropTypes.object,
};

export default memo(SuggestionsItem);
