import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";

import { getServices } from "../all-services/all-services.operations";
import {
    getBlogList,
    editPageAll,
    editSearchQuery,
    removeSearchQuery,
} from "./blog.operations";
import { clearAllParams } from "./blog.actions";
import { blogSelector, blogListParamsSelector } from "./blog.selectors";
import { sizeItemOnPage, filterOptions } from "./blog.constants";
// import { serviceSelector } from "../all-services/all-services.selectors";

import { errorHandlerTypes } from "../../components/error-handler/error-handler.constants";
import { backButtonConfig } from "../../components/back-button/back-button.config";
import ErrorHandler from "../../components/error-handler/error-handler";
import ReactHelmet from "../../components/react-helmet/react-helmet";
import BackButton from "../../components/back-button/back-button";
import Pagination from "../../components/pagination/pagination";
import Select from "../../components/select/select";
import BlogItem from "./components/blog-item/blog-item";
import { ReactComponent as DeleteIcon } from "../../assets/images/vectors/delete_icon.svg";

// import { mapStateToOptions } from "../create-order/components/create-order-form/create-order-form.utils";
import SELECT_TYPES from "../../components/select/select.constants";

import "./index.scss";

const Blog = () => {
    const [selectedCategory] = useState("");
    const [query, setQuery] = useState("");

    const {
        control,
        // formState: { errors },
    } = useForm({
        mode: "all",
        defaultValues: {},
    });

    const { blogList } = useSelector(blogSelector);
    const { page, text } = useSelector(blogListParamsSelector);
    // const { services } = useSelector(serviceSelector);

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        dispatch(getServices());
    }, []);

    useEffect(() => {
        dispatch(getBlogList(`&category=${selectedCategory}`));
        return () => dispatch(clearAllParams());
    }, [selectedCategory]);

    const handleSearch = () => dispatch(editSearchQuery(query));
    const handleChangeName = ({ target: { value } }) => setQuery(value);

    const handleChangeFilterOption = (option) => {
        switch (option) {
            case t(filterOptions.all): {
                return dispatch(getBlogList(`&category=${selectedCategory}`));
            }
            case t(filterOptions.popular): {
                return dispatch(
                    getBlogList(
                        `&category=${selectedCategory}&sortByPopularity=true`
                    )
                );
            }
            case t(filterOptions.newest): {
                return dispatch(
                    getBlogList(`&category=${selectedCategory}&sortByDate=true`)
                );
            }
            default:
                return;
        }
    };

    return (
        <Fragment>
            <ReactHelmet title="academy" />
            <div className="blog">
                <div className="blog__header">
                    <BackButton
                        onClickCallback={() =>
                            navigate(backButtonConfig[location.state].href, {
                                state: true,
                            })
                        }
                        title={t(backButtonConfig[location.state].title)}
                    />
                    <h1 className="blog__header-title">{t("academy")}</h1>
                </div>
                <div className="blog__container">
                    <div className="blog__container-search-header">
                        {/* <div className="blog__container-search-header-filter">
                            <Controller
                                name="academy-category-filter"
                                control={control}
                                render={({
                                    field: { onChange, onBlur, name },
                                }) => (
                                    <Select
                                        name={name}
                                        error={errors.categoryId?.message}
                                        value={t("all_service_title")}
                                        onBlur={(option) => onBlur(option)}
                                        options={[
                                            t("all_service_title"),
                                            ...mapStateToOptions(services),
                                        ]}
                                        onSelect={(option) => {
                                            onChange(option);
                                            if (
                                                option ===
                                                t("all_service_title")
                                            ) {
                                                dispatch(getBlogList());
                                            } else
                                                setSelectedCategory(
                                                    option.value
                                                );
                                        }}
                                        type={SELECT_TYPES.WITHOUT_LABEL}
                                        isRounded
                                    />
                                )}
                            />
                        </div> */}
                        <div className="blog__container-search-header-filter">
                            <Controller
                                name="academy-main-filter"
                                control={control}
                                render={({
                                    field: { onChange, onBlur, name },
                                }) => (
                                    <Select
                                        name={name}
                                        value={t(filterOptions.all)}
                                        onBlur={(option) => onBlur(option)}
                                        options={Object.values(
                                            filterOptions
                                        ).map((item) => t(item))}
                                        onSelect={(option) => {
                                            onChange(t(option));
                                            handleChangeFilterOption(t(option));
                                        }}
                                        type={SELECT_TYPES.WITHOUT_LABEL}
                                        isRounded
                                    />
                                )}
                            />
                        </div>
                        <div className="blog__container-search-header-textfield">
                            <input
                                onKeyPress={({ key }) =>
                                    key === "Enter" && handleSearch()
                                }
                                className="blog__container-search-header-textfield-input"
                                placeholder={t("search")}
                                onChange={handleChangeName}
                                value={query}
                            />
                            <button
                                disabled={
                                    !query || query === text.split("=")[1]
                                }
                                className="blog__container-search-header-textfield-btn"
                                onClick={handleSearch}
                            >
                                {t("btn_search")}
                            </button>
                            <DeleteIcon
                                onClick={() =>
                                    text &&
                                    dispatch(removeSearchQuery(setQuery))
                                }
                                className={`promotion__search_remove ${
                                    !text ? "disabled" : ""
                                }`}
                            />
                        </div>
                    </div>
                    {blogList.count > 0 ? (
                        <>
                            <div className="blog__container-content">
                                {blogList.items.map((blog) => (
                                    <BlogItem key={blog._id} {...blog} />
                                ))}
                            </div>
                            <Pagination
                                sizeItemOnPage={sizeItemOnPage}
                                elementType="page"
                                items={blogList}
                                getNextPageItems={(e) =>
                                    dispatch(editPageAll(e, selectedCategory))
                                }
                                isStartedPage={page}
                            />
                        </>
                    ) : (
                        <ErrorHandler
                            type={errorHandlerTypes.NOT_FOUND_SEARCH}
                        />
                    )}
                </div>
            </div>
        </Fragment>
    );
};

export default Blog;
