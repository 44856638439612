import { t } from "i18next";

import { getLanguage } from "../../../../helpers/language";

export const mapStateToOptions = (array) => {
    return array.map(({ titleTranslations, _id, remark, title }) => {
        return {
            label: titleTranslations || title || remark || t("removed"),
            value: _id,
        };
    });
};

export const convertOrderToRequestSchema = ({ ...rest }) => {
    delete rest.preferredGender;
    if (rest.preferredGender)
        return {
            ...rest,
            categoryId: rest.categoryId.value,
            clarificationId: rest.clarificationId.value,
            subcategoryId: rest.subcategoryId.value,
            // preferredGender: rest.preferredGender.value,
        };

    return {
        ...rest,
        categoryId: rest.categoryId.value,
        clarificationId: rest.clarificationId.value,
        subcategoryId: rest.subcategoryId.value,
    };
};

export const convertServiceForDefaultValues = (service) => {
    if (service) {
        const { _id = "", titleTranslations } = service;
        const { value: language } = getLanguage();

        if (titleTranslations) {
            return { label: titleTranslations[language], value: _id };
        }

        return {
            label: service?.title || service?.remark || t("removed"),
            value: _id,
        };
    }
};
