export const specialistTypes = {
    // get specialist list
    GET_SPECIALIST_LIST_PENDING: "specialist/GET_SPECIALIST_LIST_PENDING",
    GET_SPECIALIST_LIST_FULFILLED: "specialist/GET_SPECIALIST_LIST_FULFILLED",
    GET_SPECIALIST_LIST_REJECTED: "specialist/GET_SPECIALIST_LIST_REJECTED",

    // get specialist by id
    GET_SPECIALIST_PROFILE_BY_ID_FULFILLED:
        "specialist/GET_SPECIALIST_PROFILE_BY_ID_FULFILLED",
    GET_SPECIALIST_PROFILE_BY_ID_REJECTED:
        "specialist/GET_SPECIALIST_PROFILE_BY_ID_REJECTED",

    // set
    CLEAR_SELECTED_SPECIALIST: "specialist/CLEAR_SELECTED_SPECIALIST",
    SPECIALIST_IS_NOT_DEFINED: "specialist/SPECIALIST_IS_NOT_DEFINED",
    CLEAR_ALL_PARAMS: "specialist/CLEAR_ALL_PARAMS",
    SET_PAGE: "specialist/SET_PAGE",
};
