import { t } from "i18next";

import { getServiceListSearch } from "../../services/requests/specialist-services";
import { getOrderListSearch } from "../../services/requests/order";

import { myAccountDataSelector } from "../../pages/my-account/my-account.selectors";
import { changeGlobalLoader } from "../loader/loader.actions";
import * as allResultActions from "./all-result.actions";
import {
    allResultParamsSelector,
    allResultSelector,
} from "./all-result.selectors";

import { resultTypes, sizeItemOnPage } from "./all-result.constant";

// get orders
export const getOrders =
    (sizeItemOnPage, params = "") =>
    (dispatch, getState) => {
        dispatch(allResultActions.getOrdersResultPending());
        dispatch(changeGlobalLoader(true));
        const {
            clarifications,
            subcategories,
            categories,
            text,
            city,
            district,
        } = allResultParamsSelector(getState());
        const { role } = myAccountDataSelector(getState());

        const cityUrl = city.name ? `&city=${city.name}` : "";
        const districtUrl = district.name ? `&district=${district.name}` : "";

        return getOrderListSearch(
            `?limit=${sizeItemOnPage}` +
                text +
                categories +
                subcategories +
                clarifications +
                cityUrl +
                districtUrl +
                params,
            !!role
        )
            .then(({ items, totalCount: count, countByText }) => {
                dispatch(
                    allResultActions.getOrdersResultFulfilled({
                        items,
                        count,
                        countByText,
                    })
                );
            })
            .catch((error) => {
                dispatch(allResultActions.getOrdersResultRejected(error));
            })
            .finally(() => dispatch(changeGlobalLoader(false)));
    };

// get services
export const getServices =
    (sizeItemOnPage, params = "") =>
    (dispatch, getState) => {
        dispatch(allResultActions.getServicesResultPending());
        dispatch(changeGlobalLoader(true));
        const {
            clarifications,
            subcategories,
            categories,
            text,
            city,
            district,
        } = allResultParamsSelector(getState());
        const { role } = myAccountDataSelector(getState());

        const cityUrl = city.name ? `&city=${city.name}` : "";
        const districtUrl = district.name ? `&district=${district.name}` : "";

        return getServiceListSearch(
            `?limit=${sizeItemOnPage}` +
                text +
                categories +
                subcategories +
                clarifications +
                cityUrl +
                districtUrl +
                params,
            role
        )
            .then(({ topItems, items, totalCount: count, countByText }) => {
                dispatch(
                    allResultActions.getServicesResultFulfilled({
                        items,
                        count,
                        topItems,
                        countByText,
                    })
                );
            })
            .catch((error) => {
                dispatch(allResultActions.getServicesResultRejected(error));
            })
            .finally(() => dispatch(changeGlobalLoader(false)));
    };

// set
export const editPage = (size, data, type) => (dispatch) => {
    dispatch(allResultActions.setPage(`&page=${data}`));
    const url = `&page=${data}`;
    if (type === resultTypes.ORDERS) return dispatch(getOrders(size, url));
    dispatch(getServices(size, url));
};

export const editSearchQuery = (size, data, type) => (dispatch) => {
    dispatch(allResultActions.setSearchQuery(`&text=${data}`));
    if (type === resultTypes.ORDERS) return dispatch(getOrders(size));
    dispatch(getServices(size));
};

export const editCategory = (size, data, type) => (dispatch, getState) => {
    const { categories } = allResultParamsSelector(getState());
    const { tags } = allResultSelector(getState());

    const newLabel = {
        label:
            data.titleTranslations ||
            data?.title ||
            data?.remark ||
            t("removed"),
        value: `&categories=${data._id}`,
    };

    const duplicate = tags.find(
        ({ value }) => value === `&categories=${data._id}`
    );
    const updatedTags = [...tags, newLabel];

    if (!duplicate) {
        dispatch(allResultActions.setLabel(updatedTags));
        dispatch(
            allResultActions.setCategory(`${categories}&categories=${data._id}`)
        );
        if (type === resultTypes.ORDERS) return dispatch(getOrders(size));
        dispatch(getServices(size));
    }
};

export const editSubcategory = (size, data, type) => (dispatch, getState) => {
    const { subcategories } = allResultParamsSelector(getState());
    const { tags } = allResultSelector(getState());

    const newLabel = {
        label:
            data.titleTranslations ||
            data?.title ||
            data?.remark ||
            t("removed"),
        value: `&subcategories=${data._id}`,
    };

    const duplicate = tags.find(
        ({ value }) => value === `&subcategories=${data._id}`
    );
    const updatedTags = [...tags, newLabel];

    if (!duplicate) {
        dispatch(allResultActions.setLabel(updatedTags));
        dispatch(
            allResultActions.setSubcategory(
                `${subcategories}&subcategories=${data._id}`
            )
        );
        if (type === resultTypes.ORDERS) return dispatch(getOrders(size));
        dispatch(getServices(size));
    }
};

export const editClarification = (size, data, type) => (dispatch, getState) => {
    const { clarifications } = allResultParamsSelector(getState());
    const { tags } = allResultSelector(getState());

    const newLabel = {
        label:
            data.titleTranslations ||
            data?.title ||
            data?.remark ||
            t("removed"),
        value: `&clarifications=${data._id}`,
    };

    const duplicate = tags.find(
        ({ value }) => value === `&clarifications=${data._id}`
    );
    const updatedTags = [...tags, newLabel];

    if (!duplicate) {
        dispatch(allResultActions.setLabel(updatedTags));
        dispatch(
            allResultActions.setClarification(
                `${clarifications}&clarifications=${data._id}`
            )
        );
        if (type === resultTypes.ORDERS) return dispatch(getOrders(size));
        dispatch(getServices(size));
    }
};

export const removeTag = (value, type) => (dispatch, getState) => {
    const { clarifications, subcategories, categories } =
        allResultParamsSelector(getState());
    const { tags } = allResultSelector(getState());

    const removeTag = tags.find((item) => item.value === value);
    const query = removeTag.value.split("=")[0].substring(1);

    switch (query) {
        case "categories":
            dispatch(
                allResultActions.setCategory(
                    categories.replace(removeTag.value, "")
                )
            );
            break;
        case "subcategories":
            dispatch(
                allResultActions.setSubcategory(
                    subcategories.replace(removeTag.value, "")
                )
            );
            break;
        case "clarifications":
            dispatch(
                allResultActions.setClarification(
                    clarifications.replace(removeTag.value, "")
                )
            );
            break;

        default:
            break;
    }

    const updatedTags = tags.filter((item) => item.value !== value);
    dispatch(allResultActions.setLabel(updatedTags));
    if (type === resultTypes.ORDERS) return dispatch(getOrders(sizeItemOnPage));
    dispatch(getServices(sizeItemOnPage));
};

export const removeTags = (type) => (dispatch) => {
    dispatch(allResultActions.setClarification(""));
    dispatch(allResultActions.setSubcategory(""));
    dispatch(allResultActions.setCategory(""));
    dispatch(allResultActions.clearAllTags());

    if (type === resultTypes.SERVICES) dispatch(getServices(sizeItemOnPage));
    if (type === resultTypes.ORDERS) dispatch(getOrders(sizeItemOnPage));
};

export const removeText = (type, setQuery) => (dispatch) => {
    dispatch(allResultActions.setSearchQuery(""));
    setQuery("");

    if (type === resultTypes.SERVICES) dispatch(getServices(sizeItemOnPage));
    if (type === resultTypes.ORDERS) dispatch(getOrders(sizeItemOnPage));
};
