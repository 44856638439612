import { get, post } from "../api";

const paymentUrl = "/payments";

export const createStripeAccount = async (reqData = {}) => {
    const url = `${paymentUrl}/account`;
    try {
        const { data } = await post(url, reqData);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const getBalanceRequest = async () => {
    const url = `${paymentUrl}/account/balance`;
    try {
        const { data } = await get(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const refillStripeBalanceRequest = async (reqData = {}) => {
    const url = `${paymentUrl}/account/balance`;
    try {
        const { data } = await post(url, reqData);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const payoutStripeBalanceRequest = async (reqData = {}) => {
    const url = `${paymentUrl}/account/payout`;
    try {
        const { data } = await post(url, reqData);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const getTransactionsRequest = async (params = "") => {
    const url = `${paymentUrl}/account/transactions` + params;
    try {
        const { data } = await get(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const createIyzicoAccount = async (reqData = {}) => {
    const url = `${paymentUrl}/iyizico/account`;
    try {
        const { data } = await post(url, reqData);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};
