import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useState } from "react";
import dayjs from "dayjs";

import ScheduleWork from "../../schedule-work/schedule-work";
import { Icon } from "../../image/image";

import "./index.scss";

const ScheduleInput = ({ placeholder, onChange, value, error }) => {
    const { t } = useTranslation();
    const [isScheduleVisible, setIsScheduleVisible] = useState(false);

    const generatePlaceholderContent = () => {
        if (!value) return placeholder;

        return `${t("specialist-services.service.schedule")}: ${value.map(
            (item) => {
                if (item.isWeekend)
                    return ` ${t(`days.${item.day}`).toLowerCase()} - ${t(
                        "specialist-services.form.weekend"
                    )}`;
                return ` ${t(`days.${item.day}`).toLowerCase()} ${
                    item.workingHours[0].timeFrom.length > 5
                        ? dayjs(item.workingHours[0].timeFrom).format("HH:mm")
                        : item.workingHours[0].timeFrom
                } - ${
                    item.workingHours[0].timeTo.length > 5
                        ? dayjs(item.workingHours[0].timeTo).format("HH:mm")
                        : item.workingHours[0].timeTo
                }`;
            }
        )}`;
    };

    return (
        <div className={`schedule-input-container${error ? "--error" : ""}`}>
            <div
                className="schedule-input"
                onClick={() => setIsScheduleVisible(!isScheduleVisible)}
            >
                <p className="schedule-input__content">
                    {generatePlaceholderContent()}
                </p>
                <Icon type="dateOfBithday" />
            </div>
            {isScheduleVisible && (
                <ScheduleWork
                    defaultSchedule={value}
                    onSaveScheduleData={(data) => {
                        onChange(data);
                    }}
                    onCloseSchedule={() => setIsScheduleVisible(false)}
                />
            )}
            {error && <p className="schedule-input__error">{error}</p>}
        </div>
    );
};

ScheduleInput.propTypes = {
    placeholder: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    value: PropTypes.array,
    error: PropTypes.string,
};

export default ScheduleInput;
