import { useDispatch } from "react-redux";
import { useEffect } from "react";

import { getAllCities } from "./cities-loader.operations";

const CitiesLoader = () => {
    const dispatch = useDispatch();

    useEffect(() => dispatch(getAllCities()), []);

    return null;
};

export default CitiesLoader;
