import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import React, { Fragment } from "react";

import { buttonLoaderSelector } from "../../../components/loader/loader.selectors";
import { setConditionsUserAction } from "../auth.actions";
import {
    sendRegistrationForm,
    sendOauthRegistrationOperation,
} from "../auth.operations";
import { authSelector } from "../auth.selectors";

import ReactHelmet from "../../../components/react-helmet/react-helmet";
import MainButton from "../../../components/main-button/main-button";
import routerBook from "../../../router/router-book";

import "./index.scss";

const Conditions = () => {
    const { status: statusLoading } = useSelector(buttonLoaderSelector);
    const { role, user, city, district } = useSelector(authSelector);
    const { handleSubmit, register } = useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const {
        conditions,
        firstName,
        lastName,
        birthday,
        gender,
        createPassword,
        confirmPassword,
        verifications,
    } = user;

    const onSubmit = () => {
        if (!verifications) {
            dispatch(sendRegistrationForm(navigate));
        } else {
            dispatch(
                sendOauthRegistrationOperation(
                    {
                        firstName,
                        lastName,
                        city: city.name,
                        district: district.name,
                        birthday,
                        createPassword,
                        confirmPassword,
                        gender: gender.value,
                        role,
                    },
                    navigate
                )
            );
        }
    };

    const onChangeConditions = () => {
        dispatch(setConditionsUserAction(!conditions));
    };

    const userCondition = () => {
        if (role) return conditions;
        else return false;
    };

    return (
        <Fragment>
            <ReactHelmet title="conditions_cooperation_title" />
            <div className="conditions_container">
                <div className="conditions_container__content">
                    <h2 className="conditions_container__title">
                        {t("conditions_cooperation_title")}
                    </h2>
                    <div className="conditions_container__info">
                        <p className="conditions_container__description">
                            {t("conditions_info_1")}{" "}
                            <Link
                                className="conditions_container__link"
                                to={routerBook.termsAndConditions}
                                rel="noopener noreferrer"
                                target="_blank"
                                state={true}
                            >
                                {t("by_documents")}
                            </Link>{" "}
                            {t("in_site")}
                            <br />
                            <br />
                            {t("conditions_info_2")}{" "}
                            <Link
                                className="conditions_container__link"
                                to={routerBook.about}
                                rel="noopener noreferrer"
                                target="_blank"
                                state={true}
                            >
                                {t("by_link")}
                            </Link>
                            <br />
                            <br />
                            {t("conditions_info_3")}
                        </p>
                    </div>
                    <form
                        className="conditions_container__form"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <MainButton
                            isLoading={statusLoading}
                            buttonStyle="btn--green"
                            buttonSize="btn--large"
                            disabled={!conditions}
                            type="submit"
                        >
                            {t("register_now")}{" "}
                        </MainButton>
                        <div className="conditions_container__check">
                            <input
                                checkedoptions={register("checkbox")}
                                onChange={onChangeConditions}
                                checked={userCondition()}
                                type={"checkbox"}
                            />
                            <p className="conditions_container__description">
                                {t("agree_with")}{" "}
                                <Link
                                    className="conditions_container__link"
                                    to={routerBook.privacyPolicy}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    state={true}
                                >
                                    {t("rules")}
                                </Link>{" "}
                                {t("service")}
                            </p>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    );
};

export default Conditions;
