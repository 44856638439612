import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import React from "react";

import { buttonLoaderSelector } from "../../../../components/loader/loader.selectors";

import { closeModal } from "../../../../components/modal/modal.actions";
import ButtonLoader from "../../../../components/loader/components/button-loader/button-loader";
import { deleteOrder } from "../../orders.operations";

import "./index.scss";

const RemoveOrder = ({ details: { orderID } }) => {
    const { status: buttonStatus } = useSelector(buttonLoaderSelector);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleDeleteModal = () => dispatch(deleteOrder(orderID));
    const handleCloseModal = () => dispatch(closeModal());

    return (
        <div className="remove-order-modal">
            <span className="remove-order-modal__title">
                {t("orders.order-item.delete-confirm")}
            </span>
            <div className="remove-order-modal__btns">
                <button
                    className="remove-order-modal__btns-cancel"
                    onClick={handleCloseModal}
                >
                    {t("btn_cancel")}
                </button>
                <button
                    className={`remove-order-modal__btns-delete${
                        buttonStatus ? "-loading" : ""
                    }`}
                    onClick={handleDeleteModal}
                    disabled={buttonStatus}
                >
                    {buttonStatus ? (
                        <ButtonLoader size={38} />
                    ) : (
                        t("btn_delete")
                    )}
                </button>
            </div>
        </div>
    );
};

RemoveOrder.propTypes = {
    details: PropTypes.object,
};

export default RemoveOrder;
