export const notificationTypes = {
    // get notification list
    GET_NOTIFICATION_LIST_PENDING:
        "notifications/GET_NOTIFICATION_LIST_PENDING",
    GET_NOTIFICATION_LIST_FULFILLED:
        "notifications/GET_NOTIFICATION_LIST_FULFILLED",
    GET_NOTIFICATION_LIST_REJECTED:
        "notifications/GET_NOTIFICATION_LIST_REJECTED",

    // update notification status
    UPDATE_NOTIFICATION_STATUS_PENDING:
        "notifications/UPDATE_NOTIFICATION_STATUS_PENDING",
    UPDATE_NOTIFICATION_STATUS_FULFILLED:
        "notifications/UPDATE_NOTIFICATION_STATUS_FULFILLED",
    UPDATE_NOTIFICATION_STATUS_REJECTED:
        "notifications/UPDATE_NOTIFICATION_STATUS_REJECTED",

    // delete notification
    DELETE_NOTIFICATION_PENDING: "notifications/DELETE_NOTIFICATION_PENDING",
    DELETE_NOTIFICATION_FULFILLED:
        "notifications/DELETE_NOTIFICATION_FULFILLED",
    DELETE_NOTIFICATION_REJECTED: "notifications/DELETE_NOTIFICATION_REJECTED",

    // received new message
    RECEIVED_NEW_MESSAGE_FULFILLED:
        "notifications/RECEIVED_NEW_MESSAGE_FULFILLED",

    // set
    CLEAR_ALL_PARAMS: "notifications/CLEAR_ALL_PARAMS",
    SET_PAGE: "notifications/SET_PAGE",
};
