import { useEffect, useState, forwardRef, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";
import dayjs from "dayjs";

import {
    getUserBalanceHistoryOperation,
    printInvoiceListOperation,
} from "../../balance.operations";
import { userBalanceHistorySelector } from "../../balance.selectors";

import BalanceHistoryItem from "./components/balance-history-item/balance-history-item";
import MainButton from "../../../../components/main-button/main-button";

import { ReactComponent as CalendarIcon } from "../../../../assets/images/vectors/calendar-icon-green.svg";

import "./index.scss";

const CustomDateInput = forwardRef(({ value, onClick }, ref) => {
    const { t } = useTranslation();

    return (
        <div
            className="balance__history-custom-date-range-button"
            onClick={onClick}
            ref={ref}
        >
            <span className="balance__history-custom-date-range-button-text">
                {value ? value : t("balance-new.choose-date-range")}
            </span>
            <CalendarIcon />
        </div>
    );
});

CustomDateInput.displayName = "CustomDateInput";

CustomDateInput.propTypes = {
    value: PropTypes.string,
    onClick: PropTypes.func,
};

const BalanceHistory = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const linkRef = useRef(null);

    const { balance } = useSelector(userBalanceHistorySelector);

    useEffect(() => {
        dispatch(getUserBalanceHistoryOperation());
    }, []);

    const parsedStartDate = dayjs(startDate).format("YYYY.MM.DD");
    const parsedEndDate = dayjs(endDate).format("YYYY.MM.DD");

    const downloadInvoiceList = () => {
        if (startDate && endDate) {
            dispatch(
                printInvoiceListOperation(
                    {
                        startDate: parsedStartDate,
                        endDate: parsedEndDate,
                    },
                    linkRef
                )
            );
        }
    };

    return (
        <div className="balance__history">
            <div className="balance__history-header">
                <p className="balance__history-header-left">
                    {t("balance-new.history")}
                </p>
                <div className="balance__history-header-right">
                    <DatePicker
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(value) => {
                            setDateRange(value);
                        }}
                        customInput={<CustomDateInput />}
                        selectsRange
                        showMonthDropdown
                        showYearDropdown
                    />
                </div>
            </div>
            <div className="balance__history-items-container">
                {balance.map((item) => {
                    return <BalanceHistoryItem key={item._id} item={item} />;
                })}
            </div>
            <div className="balance__history-footer">
                <div className="balance__history-footer-btn">
                    <MainButton
                        buttonSize="btn--stretch"
                        onClick={downloadInvoiceList}
                    >
                        {t("balance-new.download-report")}
                    </MainButton>
                    <a ref={linkRef} style={{ display: "none" }}></a>
                </div>
            </div>
        </div>
    );
};

export default BalanceHistory;
