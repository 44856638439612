import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import React, { memo } from "react";

import { openModal } from "../../../../components/modal/modal.actions";

import { modalConstants } from "../../../../components/modal/modal.constants";
import { Icon } from "../../../../components/image/image";

import "./index.scss";

const SupportCreateTicket = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    return (
        <div
            onClick={() =>
                dispatch(
                    openModal({
                        isOpen: true,
                        closeIcon: false,
                        renderType: modalConstants.createSupportTicket,
                    })
                )
            }
            className="support-create-ticket"
        >
            <div className="support-create-ticket__icon">
                <Icon type="editOrder" />
            </div>
            <span className="support-create-ticket__title">
                {t("support.button.ask-a-question")}
            </span>
        </div>
    );
};

export default memo(SupportCreateTicket);
