import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
    Fragment,
    memo,
    useEffect,
    useRef,
    useState,
    useLayoutEffect,
} from "react";
import { useChat } from "../../../../../discussion-work-project/components/chat/use-chat";

import { Icon } from "../../../../../../components/image/image";
import LazyImage from "../../../../../../components/lazy-image/lazy-image";
import SupportContentEmpty from "../../../support-content-empty/support-content-empty";

import { chatsSelector } from "../../../../../chats/chats.selectors";
import { myAccountDataSelector } from "../../../../../my-account/my-account.selectors";
import { getChat, postChatFile } from "../../../../../chats/chats.operations";
import { openModal } from "../../../../../../components/modal/modal.actions";

import { selectedAdditionals, servicesData } from "./support-chat.config";
import { modalConstants } from "../../../../../../components/modal/modal.constants";
import { deactivateTypes } from "../../../deactivate-question/deactivate-question.constants";
import { typeOfLazeImage } from "../../../../../../components/lazy-image/lazyImage.constansts";
import { generateItems } from "../../../../../discussion-work-project/components/chat/chat.utils";

import "./index.scss";

const SupportChatBody = () => {
    const inputRef = useRef(null);
    const chatParent = useRef(null);
    const dispatch = useDispatch();

    const { t } = useTranslation();
    const { chatId } = useParams();
    const { questionId } = useParams();
    const { _id: userId } = useSelector(myAccountDataSelector);

    const { chat } = useSelector(chatsSelector);

    const [isOpen, setIsOpen] = useState(null);
    const [scrollsCount, setScrollsCount] = useState(0);
    const [messagesList, setMessagesList] = useState([]);
    const { setMessageText, sendMessage, messageText } = useChat(setIsOpen);

    const {
        messages,
        isDisabled,
        participants: { role },
    } = chat;

    const renderChatServices = (selected, icon) => {
        switch (selected) {
            case "file":
                return (
                    <Fragment key={selected}>
                        <label
                            className="support-chat_input_services-item"
                            htmlFor={selected}
                        >
                            <Icon type={icon} />
                        </label>
                        <input
                            accept="image/png, image/gif, image/jpeg"
                            onClick={() => setIsOpen(null)}
                            onChange={handleAttachFile}
                            style={{ display: "none" }}
                            id={selected}
                            type="file"
                        />
                    </Fragment>
                );

            case "service":
                return (
                    <div
                        className="support-chat_input_services-item"
                        onClick={() => changeIsOpen(selected)}
                        key={selected}
                    >
                        <Icon type={icon} />
                    </div>
                );
        }
    };
    const handleService = () => {
        dispatch(
            openModal({
                isOpen: true,
                closeIcon: true,
                renderType: modalConstants.deactivateQuestion,
                details: {
                    chatId: chatId,
                    questionId: questionId,
                    type: deactivateTypes.deactivateChat,
                },
            })
        );
        setIsOpen(null);
    };
    const changeIsOpen = (selected) => {
        if (isOpen === selected) return setIsOpen(null);
        setIsOpen(selected);
    };
    const handleKeyPress = ({ code }) => {
        if (code === "Enter" && messageText.trim()) return sendMessage();
    };
    const handleAttachFile = (e) => {
        e.preventDefault();
        const reader = new FileReader();
        const file = e.target.files[0];

        if (file) {
            reader.onloadend = () => dispatch(postChatFile(chatId, file));
            reader.readAsDataURL(file);
        }
    };

    useEffect(() => dispatch(getChat(chatId, role)), [chatId]);

    useLayoutEffect(() => {
        if (messages)
            setMessagesList(
                generateItems(
                    messages.items,
                    null,
                    userId,
                    null,
                    null,
                    setScrollsCount,
                    messages.carousel
                )
            );
    }, [messages]);

    useLayoutEffect(() => {
        const domNode = chatParent.current;
        if (domNode)
            domNode.scrollTo({
                top: domNode.scrollHeight,
            });
    }, [messagesList, scrollsCount, chatId]);

    return (
        <div className="support-chat">
            <div className="support-chat__header">
                <div
                    title={t("online")}
                    className="support-chat__header_img-active "
                >
                    <LazyImage
                        type={typeOfLazeImage.content}
                        options={{
                            className: "support-chat__header_avatar",
                            alt: "content",
                        }}
                    />
                </div>
                <div className="support-chat__header_info">
                    <div className="support-chat__header_title">
                        {t("support.chat.support")}
                    </div>
                    <div className="support-chat__header_status">
                        {t("online")}
                    </div>
                </div>
            </div>
            <div ref={chatParent} className="support-chat_body">
                {messages.items.length > 0 ? (
                    messagesList
                ) : (
                    <SupportContentEmpty />
                )}
            </div>
            <div className="support-chat_input">
                <div
                    className={`support-chat_input_inner${
                        isOpen ? "-active" : ""
                    }`}
                >
                    {isDisabled ? (
                        <span className="support-chat_input_inner_finished">
                            {t("chats_item.chat_finished")}
                        </span>
                    ) : (
                        <>
                            {selectedAdditionals.map(({ icon, selected }) =>
                                renderChatServices(selected, icon)
                            )}
                            <div className="support-chat_input_body">
                                <input
                                    value={messageText}
                                    className="support-chat_input_body_input"
                                    placeholder={t(
                                        "support.chat.write-message"
                                    )}
                                    ref={inputRef}
                                    onKeyPress={handleKeyPress}
                                    onChange={(e) =>
                                        setMessageText(e.target.value)
                                    }
                                />
                                <button
                                    className="support-chat_input_body_img"
                                    disabled={!messageText}
                                    onClick={() =>
                                        messageText.trim() && sendMessage()
                                    }
                                >
                                    <Icon type="sendMessage" />
                                </button>
                            </div>
                        </>
                    )}
                </div>
                {isOpen === "service" && (
                    <div
                        className="support-chat_input_list_item"
                        onClick={handleService}
                    >
                        <div className="support-chat_input_list_item_icon">
                            <Icon type={servicesData.icon} />
                        </div>
                        <div className="support-chat_input_list_item_title">
                            {t(servicesData.title)}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default memo(SupportChatBody);
