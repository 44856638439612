import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import React from "react";

import LazyImage from "../../../../components/lazy-image/lazy-image";

import routerBook from "../../../../router/router-book";
import { openModal } from "../../../../components/modal/modal.actions";
import { modalConstants } from "../../../../components/modal/modal.constants";
import { typeOfLazeImage } from "../../../../components/lazy-image/lazyImage.constansts";

import "./index.scss";

const SupportContentItem = ({
    _id = "",
    chatId = {},
    active = false,
    category = "",
    description = "",
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const handleDeactivateQuestion = () => {
        dispatch(
            openModal({
                isOpen: true,
                closeIcon: true,
                renderType: modalConstants.deactivateQuestion,
                details: {
                    questionId: _id,
                },
            })
        );
    };
    return (
        <div className={`support-content-item${active ? "" : "--resolved"}`}>
            <div className="support-content-item--avatar">
                <LazyImage
                    type={typeOfLazeImage.content}
                    options={{
                        className: "support-content-item--avatar-img",
                        alt: "avatar",
                    }}
                />
            </div>
            <div className="support-content-item--info">
                <div className="support-content-item--info-header">
                    <span className="support-content-item--info-header-name">
                        {category}
                    </span>
                    {!active && (
                        <span className="support-content-item--info-header-resolved">
                            {t("support.question.resolved")}
                        </span>
                    )}
                </div>
                <div className="support-content-item--info-body">
                    {description}
                </div>

                {active ? (
                    <>
                        <button
                            onClick={() =>
                                navigate(
                                    `${routerBook.support}/chat/${_id}/${chatId?._id}`,
                                    {
                                        state: true,
                                    }
                                )
                            }
                            className="support-content-item--info-button"
                        >
                            {t("support.button.go-to-chat")}
                        </button>

                        <button
                            onClick={handleDeactivateQuestion}
                            className="support-content-item--info-button-deactivate"
                        >
                            {t("btn_deactivate")}
                        </button>
                    </>
                ) : null}
            </div>
        </div>
    );
};

SupportContentItem.propTypes = {
    _id: PropTypes.string,
    chatId: PropTypes.object,
    active: PropTypes.bool,
    status: PropTypes.string,
    category: PropTypes.string,
    description: PropTypes.string,
};

export default SupportContentItem;
