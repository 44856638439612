import { useTranslation } from "react-i18next";
import React, { Fragment } from "react";

import ReactHelmet from "../../components/react-helmet/react-helmet";
import { privacyPolicyConfig } from "./privacy-policy.config";
import { replaceValue } from "./privacy-policy.utils";
import routerBook from "../../router/router-book";

import "./index.scss";

const PrivacyPolicy = () => {
    const { t } = useTranslation();

    return (
        <div className="privacy-policy">
            <ReactHelmet title="nav_privacy_policy" />
            <h1 className="privacy-policy__title">{t("nav_privacy_policy")}</h1>
            <section className="privacy-policy__container">
                {privacyPolicyConfig.map(
                    (
                        {
                            footer,
                            mediumSubitle,
                            mediumTitle,
                            subtitle,
                            title,
                            subtitles,
                            undertitle,
                            titles,
                            mediumSubitles,
                            footerSubtitle,
                        },
                        firstIndex
                    ) => (
                        <div
                            className="privacy-policy__container-item"
                            key={title}
                        >
                            <h1 className="privacy-policy__container-item__title">
                                <span className="privacy-policy__container-item__title-space">
                                    {firstIndex + 1}.
                                </span>
                                {t(title)}
                            </h1>
                            {titles &&
                                titles.map(
                                    ({ title, subtitle }, secondIndex) => (
                                        <Fragment key={title}>
                                            <h2 className="privacy-policy__container-item__titles">
                                                <span className="privacy-policy__container-item__titles-space">
                                                    {firstIndex + 1}.
                                                    {secondIndex + 1}
                                                </span>
                                                {t(title)}
                                            </h2>
                                            {subtitle && (
                                                <h2 className="privacy-policy__container-item__titles">
                                                    {t(subtitle)}
                                                </h2>
                                            )}
                                        </Fragment>
                                    )
                                )}
                            {undertitle && (
                                <h2 className="privacy-policy__container-item__subtitle">
                                    {replaceValue(
                                        t(undertitle),
                                        ["support@wconnect.work"],
                                        "mailto:https://support@wconnect.work"
                                    )}
                                </h2>
                            )}
                            {subtitle && (
                                <h2 className="privacy-policy__container-item__subtitle">
                                    {replaceValue(
                                        t(subtitle),
                                        [
                                            "https://wconnect.work/privacy-policy",
                                        ],
                                        routerBook.privacyPolicy
                                    )}
                                </h2>
                            )}
                            {subtitles &&
                                subtitles.map(({ subtitle }) => (
                                    <h2
                                        className="privacy-policy__container-item__subtitles"
                                        key={subtitle}
                                    >
                                        <span className="privacy-policy__container-item__subtitles-space">
                                            —
                                        </span>
                                        {t(subtitle)}
                                    </h2>
                                ))}
                            {mediumTitle && (
                                <h2 className="privacy-policy__container-item__medium-title">
                                    {t(mediumTitle)}
                                </h2>
                            )}
                            {mediumSubitle && (
                                <h2 className="privacy-policy__container-item__medium-subtitle">
                                    {t(mediumSubitle)}
                                </h2>
                            )}
                            {mediumSubitles &&
                                mediumSubitles.map(({ mediumSubitle }) => (
                                    <h2
                                        className="privacy-policy__container-item__medium-subtitles"
                                        key={mediumSubitle}
                                    >
                                        <span className="privacy-policy__container-item__medium-subtitles-space">
                                            —
                                        </span>
                                        {t(mediumSubitle)}
                                    </h2>
                                ))}
                            {footer && (
                                <h2 className="privacy-policy__container-item__footer">
                                    {t(footer)}
                                </h2>
                            )}
                            {footerSubtitle && (
                                <h2 className="privacy-policy__container-item__footer-subtitle">
                                    {t(footerSubtitle)}
                                </h2>
                            )}
                        </div>
                    )
                )}
            </section>
        </div>
    );
};

export default PrivacyPolicy;
