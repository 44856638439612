import PropTypes from "prop-types";
import React from "react";

import ChatsContentItem from "../chats-content-item/chats-content-item";

import "./index.scss";

const ChatsContent = ({ chats }) => {
    return (
        <div className="chats__content">
            {chats.map((chat) => (
                <ChatsContentItem key={chat._id} {...chat} />
            ))}
        </div>
    );
};

ChatsContent.propTypes = { chats: PropTypes.array };

export default ChatsContent;
