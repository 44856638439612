import { batch } from "react-redux";

import * as specialistServicesRequests from "../../services/requests/specialist-services";
import * as servicesRequests from "../../services/requests/service";

import { successToastify } from "../../components/toastify/toastify.operations";
import { changeGlobalLoader } from "../../components/loader/loader.actions";
import { myAccountDataSelector } from "../my-account/my-account.selectors";
import { closeModal } from "../../components/modal/modal.actions";
import {
    getClarificationFulfilled,
    getServiceFulfilled,
    getSubserviceFulfilled,
} from "../all-services/all-services.actions";

import {
    getSpecialistServiceFulfilled,
    getSpecialistServiceRejected,
    getSpecialistServicesFulfilled,
    getSpecialistServicesPending,
    getSpecialistServicesRejected,
    postSpecialistServicePending,
    postSpecialistServiceFulfilled,
    postSpecialistServiceRejected,
    removeSpecialistServicePending,
    removeSpecialistServiceFulfilled,
    removeSpecialistServiceRejected,
    postSpecialistServiceImageFulfilled,
    removeSpecialistServiceImagePending,
    updateSpecialistServicePending,
    updateSpecialistServiceFulfilled,
    updateSpecialistServiceRejected,
} from "./specialist-services.actions";
import { specialistServicesSelector } from "./specialist-services.selectors";

export const getSpecialistServices = () => (dispatch, getState) => {
    const { _id: specialistID } = myAccountDataSelector(getState());
    dispatch(getSpecialistServicesPending());
    dispatch(changeGlobalLoader(true));

    return specialistServicesRequests
        .getSpecialistServices(specialistID)
        .then((response) => dispatch(getSpecialistServicesFulfilled(response)))
        .catch((error) => dispatch(getSpecialistServicesRejected(error)))
        .finally(() => dispatch(changeGlobalLoader(false)));
};

export const getSpecialistServiceByID = (serviceID) => (dispatch) => {
    dispatch(changeGlobalLoader(true));

    return specialistServicesRequests
        .getSpecialistServiceByID(serviceID)
        .then(async (response) => {
            const { items: servicesItems } =
                await servicesRequests.getServices();
            const { items: subservicesItems } =
                await servicesRequests.getSubservices(response.categoryId);
            const { items: clarificationsItems } =
                await servicesRequests.getClarifications(
                    response.subcategoryId
                );

            batch(() => {
                dispatch(getServiceFulfilled(servicesItems));
                dispatch(getSubserviceFulfilled(subservicesItems));
                dispatch(getClarificationFulfilled(clarificationsItems));
            });

            dispatch(getSpecialistServiceFulfilled(response));
        })
        .catch((error) => dispatch(getSpecialistServiceRejected(error)))
        .finally(() => dispatch(changeGlobalLoader(false)));
};

export const deleteSpecialistService = (serviceID) => (dispatch) => {
    dispatch(removeSpecialistServicePending());
    dispatch(changeGlobalLoader(true));

    return specialistServicesRequests
        .deleteSpecialistService(serviceID)
        .then(() => {
            dispatch(removeSpecialistServiceFulfilled(serviceID));
            dispatch(successToastify("toastify.service.deleted"));
        })
        .catch((error) => dispatch(removeSpecialistServiceRejected(error)))
        .finally(() => dispatch(changeGlobalLoader(false)));
};

export const createServiceBySpecialist =
    (serviceData, images) => (dispatch) => {
        dispatch(postSpecialistServicePending());
        dispatch(changeGlobalLoader(true));

        return specialistServicesRequests
            .createServiceBySpecialist(serviceData)
            .then(async (response) => {
                dispatch(postSpecialistServiceFulfilled(response));
                const { _id: serviceID } = response;

                if (images.length) {
                    let formData = new FormData();
                    for (let i = 0; i < images.length; i++) {
                        formData.append("images", images[i]);
                    }

                    const serviceImages =
                        await specialistServicesRequests.postSpecialistServiceImages(
                            serviceID,
                            formData
                        );

                    const payload = { serviceID, images: serviceImages };
                    dispatch(postSpecialistServiceImageFulfilled(payload));
                }
                dispatch(successToastify("toastify.service.added"));
            })
            .catch((error) => dispatch(postSpecialistServiceRejected(error)))
            .finally(() => {
                dispatch(closeModal());
                dispatch(changeGlobalLoader(false));
            });
    };

export const updateServiceBySpecialist =
    (serviceID, serviceData, images) => (dispatch) => {
        dispatch(updateSpecialistServicePending());
        dispatch(changeGlobalLoader(true));

        return specialistServicesRequests
            .updateServiceBySpecialist(serviceID, serviceData)
            .then(async (response) => {
                dispatch(updateSpecialistServiceFulfilled(response));

                if (images.length) {
                    let formData = new FormData();
                    let isFormDataEmpty = true;
                    for (let i = 0; i < images.length; i++) {
                        if (!images[i].url) {
                            formData.append("images", images[i]);
                            isFormDataEmpty = false;
                        }
                    }
                    if (!isFormDataEmpty) {
                        const serviceImages =
                            await specialistServicesRequests.postSpecialistServiceImages(
                                response._id,
                                formData
                            );

                        const payload = { serviceID, images: serviceImages };
                        dispatch(postSpecialistServiceImageFulfilled(payload));
                    }
                }
                dispatch(successToastify("toastify.service.updated"));
            })
            .catch((error) => dispatch(updateSpecialistServiceRejected(error)))
            .finally(() => {
                dispatch(closeModal());
                dispatch(changeGlobalLoader(false));
            });
    };

export const deleteSpecialistServiceImage =
    (serviceID, imageID) => (dispatch, getState) => {
        dispatch(removeSpecialistServiceImagePending());

        const { selectedService, list } = specialistServicesSelector(
            getState()
        );
        const { items, totalCount } = list;

        return specialistServicesRequests
            .deleteSpecialistServiceImage(serviceID, imageID)
            .then(() => {
                const newServiceImages = selectedService.images.filter(
                    ({ _id }) => _id !== imageID
                );
                const newService = {
                    ...selectedService,
                    images: newServiceImages,
                };
                const newServices = items.map((item) =>
                    item._id === serviceID ? newService : item
                );
                const updateOrders = { items: newServices, totalCount };

                dispatch(getSpecialistServicesFulfilled(updateOrders));
                dispatch(getSpecialistServiceFulfilled(newService));
                dispatch(successToastify("toastify.service.deleted_photo"));
            })
            .catch((error) => dispatch(updateSpecialistServiceRejected(error)));
    };
