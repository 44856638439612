import { useSelector, useDispatch } from "react-redux";
import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { closeModal } from "../../../../../components/modal/modal.actions";
import {
    setRestoreAccountEmail,
    setRestoreAccountPhone,
} from "../../../auth.actions";
import { authSelector } from "../../../auth.selectors";

import ReactHelmet from "../../../../../components/react-helmet/react-helmet";

import emailIcon from "../../../../../assets/images/auth/forgot_password_email.svg";
import "./index.scss";

const RestoreAccountPending = () => {
    const { restoreAccount } = useSelector(authSelector);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        return () => {
            dispatch(setRestoreAccountEmail(""));
            dispatch(setRestoreAccountPhone(""));
        };
    }, []);

    return (
        <Fragment>
            <ReactHelmet title="forgot_password_pending_title" />
            <div className="restore-account__pending">
                <img
                    className="restore-account__pending-img"
                    src={emailIcon}
                    alt="letter sent"
                />
                <span className="restore-account__pending-title">
                    {t("forgot_password_pending_title")}
                </span>
                <div className="restore-account__pending-subtitle">
                    {t("forgot_password_pending_subtitle_first")}{" "}
                    <span className="restore-account__pending-subtitle-email">
                        {restoreAccount.email}
                    </span>
                    {t("forgot_password_pending_subtitle_second")}
                </div>
                <button
                    onClick={() => dispatch(closeModal())}
                    className="forgot-password__pending-btn"
                >
                    {t("btn_got_it")}
                </button>
            </div>
        </Fragment>
    );
};

export default RestoreAccountPending;
