import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
    editPromotionPage,
    getPromotion,
    editPromotionHistoryPage,
    editActivePromotionsPage,
} from "./promotion.operations";
import { clearAllParams } from "./promotion.actions";
import {
    promotionParamsSelector,
    promotionSelector,
    promotionHistoryParamsSelector,
    promotionActiveParamsSelector,
} from "./promotion.selectors";

import PromotionLeftBar from "./components/promotion-leftbar/promotion-leftbar";
import { errorHandlerTypes } from "../../components/error-handler/error-handler.constants";
import PromotionSearch from "./components/promotion-search/promotion-search";
import {
    promotionConstants,
    sizeItemOnPage,
    sizeHistoryItemOnPage,
    sizeActivePromotionItemOnPage,
} from "./promotion.constants";
import ErrorHandler from "../../components/error-handler/error-handler";
import ReactHelmet from "../../components/react-helmet/react-helmet";
import Pagination from "../../components/pagination/pagination";
import { promotionConfig } from "./promotion.configs";
import routerBook from "../../router/router-book";

import "./index.scss";

const Promotion = () => {
    const { allPromotion, promotionHistory } = useSelector(promotionSelector);
    const pageParams = useSelector(promotionParamsSelector);
    const historyPageParams = useSelector(promotionHistoryParamsSelector);
    const activePromotionsPageParams = useSelector(
        promotionActiveParamsSelector
    );

    const { promotion } = routerBook.accessForSpecialist;
    const { condition } = promotionConstants;
    const { filterParameter } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!filterParameter) {
            navigate(`${promotion}/${condition}`, { state: true });
        }
        dispatch(getPromotion());

        return () => dispatch(clearAllParams());
    }, []);

    const renderPaginationBar = (type) => {
        switch (type) {
            case promotionConstants.condition: {
                return (
                    allPromotion.count > sizeItemOnPage && (
                        <Pagination
                            getNextPageItems={(e) =>
                                dispatch(editPromotionPage(e))
                            }
                            sizeItemOnPage={sizeItemOnPage}
                            isStartedPage={pageParams.page}
                            items={allPromotion}
                            elementType="page"
                        />
                    )
                );
            }
            case promotionConstants.acting: {
                return (
                    promotionHistory.count > sizeActivePromotionItemOnPage && (
                        <Pagination
                            getNextPageItems={(e) =>
                                dispatch(editActivePromotionsPage(e))
                            }
                            sizeItemOnPage={sizeActivePromotionItemOnPage}
                            isStartedPage={activePromotionsPageParams.page}
                            items={promotionHistory}
                            elementType="page"
                        />
                    )
                );
            }
            case promotionConstants.history: {
                return (
                    promotionHistory.count > sizeHistoryItemOnPage && (
                        <Pagination
                            getNextPageItems={(e) =>
                                dispatch(editPromotionHistoryPage(e))
                            }
                            sizeItemOnPage={sizeHistoryItemOnPage}
                            isStartedPage={historyPageParams.page}
                            items={promotionHistory}
                            elementType="page"
                        />
                    )
                );
            }
            default:
                return;
        }
    };

    return (
        <Fragment>
            <ReactHelmet title="promotion.full-title" />
            <h1 className="promotion__title">{t("promotion.full-title")}</h1>
            <div className="promotion">
                <PromotionLeftBar />
                <div className="promotion__right">
                    <PromotionSearch />
                    <div className="promotion__right-container">
                        {allPromotion.count > 0 ? (
                            promotionConfig[filterParameter]
                        ) : (
                            <ErrorHandler
                                type={errorHandlerTypes.NOT_FOUND_SEARCH}
                            />
                        )}
                    </div>
                    {renderPaginationBar(filterParameter)}
                </div>
            </div>
        </Fragment>
    );
};

export default Promotion;
