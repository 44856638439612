import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { clearAllParams } from "../../components/all-result/all-result.actions";
import {
    getClientFavourites,
    getSpecialistFavourites,
    editPageAllClientFavourites,
    editPageAllSpecialistFavourites,
} from "./favourites.operations";
import {
    favouritesSelector,
    favouritesPageSelector,
} from "./favourites.selectors";
import { roleSelector } from "../my-account/my-account.selectors";

import { backButtonConfig } from "../../components/back-button/back-button.config";
import { sizeItemOnPage } from "./favourites.constants";
import { errorHandlerTypes } from "../../components/error-handler/error-handler.constants";
import FavouritesItem from "./components/favourites-item/favourites-item";
import ReactHelmet from "../../components/react-helmet/react-helmet";
import BackButton from "../../components/back-button/back-button";
import ErrorHandler from "../../components/error-handler/error-handler";
import Pagination from "../../components/pagination/pagination";
import { userRoles } from "../../constants/user-roles";

import "./index.scss";

const Favourites = () => {
    const { favouritesList } = useSelector(favouritesSelector);
    const page = useSelector(favouritesPageSelector);

    const { role } = useSelector(roleSelector);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const location = useLocation();

    useEffect(() => {
        if (role === userRoles.customer)
            dispatch(getClientFavourites(sizeItemOnPage));
        if (role === userRoles.specialist)
            dispatch(getSpecialistFavourites(sizeItemOnPage));
        return () => dispatch(clearAllParams());
    }, []);

    return (
        <Fragment>
            <ReactHelmet title="nav-favorites" />
            <section className="favourites">
                <div className="favourites__header">
                    <BackButton
                        title={t(backButtonConfig[location.state].title)}
                        onClickCallback={() =>
                            navigate(backButtonConfig[location.state].href, {
                                state: true,
                            })
                        }
                    />
                    <h1 className="favourites__title">{t("nav-favorites")}</h1>
                </div>

                {favouritesList.count > 0 ? (
                    <>
                        <div className="favourites__list">
                            {favouritesList.items.map((item) => {
                                return (
                                    <FavouritesItem key={item._id} {...item} />
                                );
                            })}
                        </div>
                        <Pagination
                            sizeItemOnPage={sizeItemOnPage}
                            elementType="page"
                            items={favouritesList}
                            getNextPageItems={(e) => {
                                role === userRoles.customer
                                    ? dispatch(
                                          editPageAllClientFavourites(
                                              sizeItemOnPage,
                                              e
                                          )
                                      )
                                    : dispatch(
                                          editPageAllSpecialistFavourites(
                                              sizeItemOnPage,
                                              e
                                          )
                                      );
                            }}
                            isStartedPage={page}
                        />
                    </>
                ) : (
                    <ErrorHandler type={errorHandlerTypes.EMPTY_FAVORITES} />
                )}
            </section>
        </Fragment>
    );
};

export default Favourites;
