export const scrollToTop = () => {
    if (typeof window === "undefined") return;

    window.requestAnimationFrame(() => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
    });
};

export const scrollToBottomChat= (chatRef) => {
    const domNode = chatRef.current;
    if (domNode) {
        domNode.scrollTo({
            top: domNode.scrollHeight,
        });
    }
};
