import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

import { restoreAccount } from "../auth.operations";

import routerBook from "../../../router/router-book";

const RestoreAccount = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { token } = useParams();

    useEffect(() => {
        if (token) dispatch(restoreAccount({ token }));
        navigate(routerBook.auth.login, { state: true });
    }, []);

    return null;
};

export default RestoreAccount;
