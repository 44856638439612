import { createSelector } from "reselect";

export const myAccountDataSelector = (state) => state.myAccount.data;
export const myAccountSelector = (state) => state.myAccount;
export const onboardingSelector = createSelector(
    myAccountDataSelector,
    (formList) => {
        const listOfMyAccountData = ["onboarding"];

        const renderObject = Object.entries(formList);

        const selectedInformation = renderObject.reduce((acc, current) => {
            const key = current[0];
            const value = current[1];

            return listOfMyAccountData.includes(key)
                ? { ...acc, [key]: value }
                : acc;
        }, {});

        return selectedInformation;
    }
);
export const roleSelector = createSelector(
    myAccountDataSelector,
    (formList) => {
        const listOfMyAccountData = ["role"];

        const renderObject = Object.entries(formList);

        const selectedInformation = renderObject.reduce((acc, current) => {
            const key = current[0];
            const value = current[1];

            return listOfMyAccountData.includes(key)
                ? { ...acc, [key]: value }
                : acc;
        }, {});

        return selectedInformation;
    }
);
