export const myAccountTypes = {
    // update user
    PUT_USER_PENDING: "my-account/PUT_USER_PENDING",
    PUT_USER_FULFILLED: "my-account/PUT_USER_FULFILLED",
    PUT_USER_REJECTED: "my-account/PUT_USER_REJECTED",

    // update avatar
    PUT_USER_AVATAR_PENDING: "my-account/PUT_USER_AVATAR_PENDING",
    PUT_USER_AVATAR_FULFILLED: "my-account/PUT_USER_AVATAR_FULFILLED",
    PUT_USER_AVATAR_REJECTED: "my-account/PUT_USER_AVATAR_REJECTED",

    // delete avatar
    DELETE_USER_AVATAR_PENDING: "my-account/DELETE_USER_AVATAR_PENDING",
    DELETE_USER_AVATAR_FULFILLED: "my-account/DELETE_USER_AVATAR_FULFILLED",
    DELETE_USER_AVATAR_REJECTED: "my-account/DELETE_USER_AVATAR_REJECTED",

    // get user
    GET_USER_PENDING: "my-account/GET_USER_PENDING",
    GET_USER_FULFILLED: "my-account/GET_USER_FULFILLED",
    GET_USER_REJECTED: "my-account/GET_USER_REJECTED",

    // get avatars
    GET_AVATARS_PENDING: "my-account/GET_AVATARS_PENDING",
    GET_AVATARS_FULFILLED: "my-account/GET_AVATARS_FULFILLED",
    GET_AVATARS_REJECTED: "my-account/GET_AVATARS_REJECTED",

    // delete user
    DELETE_USER_PENDING: "my-account/DELETE_USER_PENDING",
    DELETE_USER_FULFILLED: "my-account/DELETE_USER_FULFILLED",
    DELETE_USER_REJECTED: "my-account/DELETE_USER_REJECTED",

    // clear account data
    CLEAR_ACCOUNT_DATA: "my-account/CLEAR_ACCOUNT_DATA",

    // set
    SET_AVATAR: "my-account/SET_AVATAR",

    // reset
    RESET_AVATAR: "my-account/RESET_AVATAR",

    // phone verifications
    GET_PHONE_VERIFICATION_CODE_PENDING:
        "my-account/GET_PHONE_VERIFICATION_CODE_PENDING",
    GET_PHONE_VERIFICATION_CODE_FULFILLED:
        "my-account/GET_PHONE_VERIFICATION_CODE_FULFILLED",
    GET_PHONE_VERIFICATION_CODE_REJECTED:
        "my-account/GET_PHONE_VERIFICATION_CODE_REJECTED",

    PHONE_VERIFY_PENDING: "my-account/PHONE_VERIFY_PENDING",
    PHONE_VERIFY_FULFILLED: "my-account/PHONE_VERIFY_FULFILLED",
    PHONE_VERIFY_REJECTED: "my-account/PHONE_VERIFY_REJECTED",

    // email verifications
    GET_EMAIL_VERIFICATION_CODE_PENDING:
        "my-account/GET_EMAIL_VERIFICATION_CODE_PENDING",
    GET_EMAIL_VERIFICATION_CODE_FULFILLED:
        "my-account/GET_EMAIL_VERIFICATION_CODE_FULFILLED",
    GET_EMAIL_VERIFICATION_CODE_REJECTED:
        "my-account/GET_EMAIL_VERIFICATION_CODE_REJECTED",

    EMAIL_VERIFY_PENDING: "my-account/EMAIL_VERIFY_PENDING",
    EMAIL_VERIFY_FULFILLED: "my-account/EMAIL_VERIFY_FULFILLED",
    EMAIL_VERIFY_REJECTED: "my-account/EMAIL_VERIFY_REJECTED",

    // oauth connect
    SEND_OAUTH_CONNECT_PENDING: "auth/SEND_OAUTH_CONNECT_PENDING",
    SEND_OAUTH_CONNECT_FULFILLED: "auth/SEND_OAUTH_CONNECT_FULFILLED",
    SEND_OAUTH_CONNECT_REJECTED: "auth/SEND_OAUTH_CONNECT_REJECTED",
};
