import { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { modalConstants } from "../../../../components/modal/modal.constants";
import { openModal } from "../../../../components/modal/modal.actions";
import { paymentTabs } from "../../balance.constants";

import MainButton from "../../../../components/main-button/main-button";

import { ReactComponent as IyzicoIcon } from "../../../../assets/images/currency/iyzico.svg";
import { ReactComponent as IparaIcon } from "../../../../assets/images/currency/ipara.svg";

import "./index.scss";

const BalancePaymentTypeModal = ({ details }) => {
    const { type } = details;

    const [activeTab, setActiveTab] = useState(null);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const submitHandler = () => {
        dispatch(
            openModal({
                isOpen: true,
                closeIcon: false,
                renderType: modalConstants.balanceReplenish,
                details: { activeTab, type },
            })
        );
    };

    return (
        <div className="balance-payment-modal">
            <p className="balance-payment-modal__title">
                {t("balance-new.choose-payment-type")}
            </p>
            <div className="balance-payment-modal__types">
                <div
                    className={`balance-payment-modal__types-item ${
                        activeTab === paymentTabs.iyzico &&
                        "balance-payment-modal__types-active-item"
                    }`}
                    onClick={() => setActiveTab(paymentTabs.iyzico)}
                >
                    <IyzicoIcon />
                </div>
                <div
                    className={`balance-payment-modal__types-item ${
                        activeTab === paymentTabs.ipara &&
                        "balance-payment-modal__types-active-item"
                    }`}
                    onClick={() => setActiveTab(paymentTabs.ipara)}
                >
                    <IparaIcon />
                </div>
            </div>
            <MainButton buttonSize="btn--stretch" onClick={submitHandler}>
                {t("balance-new.choose")}
            </MainButton>
        </div>
    );
};

BalancePaymentTypeModal.propTypes = {
    details: PropTypes.shape({
        type: PropTypes.string,
    }),
};

export default BalancePaymentTypeModal;
