import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import dayjs from "dayjs";

import { openModal } from "../../../components/modal/modal.actions";

import { getLocalizedService } from "../../../helpers/get-localized-service";
import { modalConstants } from "../../../components/modal/modal.constants";
import { typeOfLazeImage } from "../../../components/lazy-image/lazyImage.constansts";
import LazyImage from "../../../components/lazy-image/lazy-image";

import { ReactComponent as CurrencyLira } from "../../../assets/images/currency/lira.svg";
import "./index.scss";

const OrderInfoBody = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        images = [],
        comment = "",
        city = "",
        address = "",
        district = "",
        priceTo = "",
        fixedPrice = "",
        priceFrom = "",
        preferredGender = "",
        completionDeadline = "",
        executionTime = "",
        category = {},
        subcategory = {},
        clarification = {},
        preferredYears = {},
        orderTask = {},
    } = props;

    const getTypeOfPrice = () => {
        if (orderTask?.fixedPrice) return orderTask?.fixedPrice;
        if (priceFrom) return `${priceFrom} - ${priceTo}`;
        if (fixedPrice) return fixedPrice;
    };

    return (
        <div className="order-info_body">
            <div className="order-info_body__details">
                <div className="order-info_body__details_title">
                    {t("orders.order-item.project-description")}
                </div>
                <div className="order-info_body__details_item">
                    <div className="order-info_body__details_item_description">
                        {t("orders.order-item.service-sector")}
                    </div>
                    <div className="order-info_body__details_item_value">
                        {getLocalizedService(category)}
                    </div>
                </div>
                <div className="order-info_body__details_item">
                    <div className="order-info_body__details_item_description">
                        {t("orders.order-item.selected-service")}
                    </div>
                    <div className="order-info_body__details_item_value">
                        {getLocalizedService(subcategory)}
                    </div>
                </div>
                <div className="order-info_body__details_item">
                    <div className="order-info_body__details_item_description">
                        {t("orders.order-item.service-clarification")}
                    </div>
                    <div className="order-info_body__details_item_value">
                        {getLocalizedService(clarification)}
                    </div>
                </div>
                <div className="order-info_body__details_title">
                    {t("orders.order-item.execution-address")}
                </div>
                <div className="order-info_body__details_item">
                    <div className="order-info_body__details_item_description">
                        {t("orders.order-item.city")}
                    </div>
                    <div className="order-info_body__details_item_value">
                        {city}
                    </div>
                </div>
                <div className="order-info_body__details_item">
                    <div className="order-info_body__details_item_description">
                        {t("orders.order-item.district")}
                    </div>
                    <div className="order-info_body__details_item_value">
                        {district}
                    </div>
                </div>
                <div className="order-info_body__details_item">
                    <div className="order-info_body__details_item_description">
                        {t("orders.order-item.address")}
                    </div>
                    <div className="order-info_body__details_item_value">
                        {address}
                    </div>
                </div>
                <div className="order-info_body__details_title">
                    {t("orders.order-item.execution-date")}
                </div>
                <div className="order-info_body__details_item">
                    <div className="order-info_body__details_item_description">
                        {t("orders.order-item.date")}
                    </div>
                    <div className="order-info_body__details_item_value">
                        {completionDeadline &&
                            dayjs(completionDeadline).format("DD.MM.YYYY")}
                    </div>
                </div>
                <div className="order-info_body__details_item">
                    <div className="order-info_body__details_item_description">
                        {t("orders.order-item.time")}
                    </div>
                    <div className="order-info_body__details_item_value">
                        {executionTime && executionTime / 3600000}{" "}
                        {t("locations.hour.abbreviated")}
                    </div>
                </div>
                <div className="order-info_body__details_title">
                    {orderTask?.fixedPrice || fixedPrice
                        ? t("orders.order-item.price-fixed")
                        : t("orders.order-item.order-range")}
                </div>
                <div className="order-info_body__details_item">
                    <div className="order-info_body__details_item_description">
                        {orderTask?.fixedPrice || fixedPrice
                            ? t("orders.order-item.price-fixed")
                            : t("orders.order-item.price-range")}
                    </div>
                    <div className="order-info_body__details_item_value">
                        {getTypeOfPrice()}
                        <CurrencyLira />
                    </div>
                </div>
                <div className="order-info_body__details_title">
                    {t("orders.order-item.prefer-gender-and-age")}
                </div>
                <div className="order-info_body__details_item">
                    <div className="order-info_body__details_item_description">
                        {t("orders.order-item.prefer-gender")}
                    </div>
                    <div className="order-info_body__details_item_value">
                        {t(preferredGender.toLowerCase())}
                    </div>
                </div>
                <div className="order-info_body__details_item">
                    <div className="order-info_body__details_item_description">
                        {t("orders.order-item.prefer-age")}
                    </div>
                    <div className="order-info_body__details_item_value">
                        {preferredYears?.from} - {preferredYears?.to}
                    </div>
                </div>
                <div className="order-info_body__details_title">
                    {t("orders.order-item.order-photos")}
                </div>
                <div className="order-info_body__details_photos">
                    {images.length >= 1 ? (
                        images.map(({ url, _id }) => (
                            <div
                                sd
                                key={_id}
                                className="order-info_body__details_photos__item"
                                onClick={() => {
                                    dispatch(
                                        openModal({
                                            isOpen: true,
                                            closeIcon: true,
                                            renderType:
                                                modalConstants.imageZoomer,
                                            details: {
                                                url,
                                            },
                                        })
                                    );
                                }}
                            >
                                <LazyImage
                                    image={url}
                                    type={typeOfLazeImage.content}
                                    options={{
                                        className:
                                            "order-info_body__details_photos__item-img",
                                        alt: "logo",
                                    }}
                                />
                            </div>
                        ))
                    ) : (
                        <div className="order-info_body__details_item_no-image">
                            {t("orders.error.no-added-images")}
                        </div>
                    )}
                </div>

                <div className="order-info_body__details_title">
                    {t("orders.order-item.order-comments")}
                </div>
                <div className="order-info_body__details_item">
                    <div className="order-info_body__details_item_description">
                        {comment ? comment : t("orders.error.no-comments")}
                    </div>
                </div>
            </div>
        </div>
    );
};
OrderInfoBody.propTypes = {
    city: PropTypes.string,
    address: PropTypes.string,
    priceTo: PropTypes.number,
    comment: PropTypes.string,
    district: PropTypes.string,
    priceFrom: PropTypes.number,
    fixedPrice: PropTypes.number,
    preferredGender: PropTypes.string,
    executionTime: PropTypes.string,
    completionDeadline: PropTypes.string,
    category: PropTypes.object,
    orderTask: PropTypes.object,
    subcategory: PropTypes.object,
    preferredYears: PropTypes.object,
    clarification: PropTypes.object,
    images: PropTypes.array,
};
export default OrderInfoBody;
