import { notificationTypes } from "./notifications.types";

// get notification list
export const getNotificationListPending = () => ({
    type: notificationTypes.GET_NOTIFICATION_LIST_PENDING,
});

export const getNotificationListFulfilled = (data) => ({
    type: notificationTypes.GET_NOTIFICATION_LIST_FULFILLED,
    payload: data,
});

export const getNotificationListRejected = (error) => ({
    type: notificationTypes.GET_NOTIFICATION_LIST_REJECTED,
    payload: error,
});

// update notification status
export const updateNotificationStatusPending = () => ({
    type: notificationTypes.UPDATE_NOTIFICATION_STATUS_PENDING,
});

export const updateNotificationStatusFulfilled = (data) => ({
    type: notificationTypes.UPDATE_NOTIFICATION_STATUS_FULFILLED,
    payload: data,
});

export const updateNotificationStatusRejected = (error) => ({
    type: notificationTypes.UPDATE_NOTIFICATION_STATUS_REJECTED,
    payload: error,
});

// delete notification
export const deleteNotificationPending = () => ({
    type: notificationTypes.DELETE_NOTIFICATION_PENDING,
});

export const deleteNotificationFulfilled = (data) => ({
    type: notificationTypes.DELETE_NOTIFICATION_FULFILLED,
    payload: data,
});

export const deleteNotificationRejected = (error) => ({
    type: notificationTypes.DELETE_NOTIFICATION_REJECTED,
    payload: error,
});

// received new message
export const receivedNewMessageFulfilled = (data) => ({
    type: notificationTypes.RECEIVED_NEW_MESSAGE_FULFILLED,
    payload: data,
});

// set
export const clearAllParams = () => ({
    type: notificationTypes.CLEAR_ALL_PARAMS,
});

export const setPage = (data) => ({
    type: notificationTypes.SET_PAGE,
    payload: data,
});
