export const sizeItemsOnPage = 5;

export const ORDERS_FILTERS_SPECIALIST = {
    NEGOTIATION: "negotiation",
    PRICE_IS_FIXED: "price_is_fixed",
    IN_WORK: "in_work",
    COMPLETED: "completed",
    LINE: "line",
    CANCELLED: "cancelled",
};
