import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";

import { openModal } from "../../../../components/modal/modal.actions";

import { modalConstants } from "../../../../components/modal/modal.constants";
import UploadedImage from "./uploaded-image/uploaded-image";
import InitialImage from "./initial-image/initial-image";
import { getPhotoByTypeGender } from "../../../../helpers/get-photo-by-type-gender";

const UploadImage = ({
    handleUploadImageChange,
    handleSendCroppedImage,
    handleSelectIcon,
    onDeleteAvatar,
    defaultImage,
    onChange,
    avatar,
    gender,
}) => {
    const [upload, setUpload] = useState({
        mainState: "initial",
        imageUploaded: 0,
        selectedFile: null,
    });
    const dispatch = useDispatch();

    useEffect(() => {
        if (defaultImage) {
            setUpload({
                mainState: "uploaded",
                selectedFile: defaultImage,
                imageUploaded: 1,
            });
        }
    }, [defaultImage]);

    const handleResetUpload = () => {
        setUpload({
            ...upload,
            mainState: "initial",
            selectedFile: null,
            imageUploaded: 0,
        });
        onChange(null);
        handleUploadImageChange(null);
    };

    const handleUploadClick = () => {
        dispatch(
            openModal({
                isOpen: true,
                closeIcon: true,
                renderType: modalConstants.selectIcon,
                details: {
                    onSendCroppedImage: handleSendCroppedImage,
                    onSelectIcon: handleSelectIcon,
                },
            })
        );
    };

    const handleDeleteAvatar = () => {
        onDeleteAvatar();
        handleResetUpload();
    };

    return (
        <div className="my-account__form--avatar">
            {upload.mainState === "initial" && (
                <InitialImage
                    defaultImage={getPhotoByTypeGender(avatar, gender)}
                    handleUploadClick={handleUploadClick}
                />
            )}
            {upload.mainState === "uploaded" && (
                <UploadedImage
                    onDelete={handleDeleteAvatar}
                    handleUploadClick={handleUploadClick}
                    selectedFile={upload.selectedFile}
                />
            )}
        </div>
    );
};

UploadImage.propTypes = {
    onChange: PropTypes.func.isRequired,
    onDeleteAvatar: PropTypes.func,
    handleSendCroppedImage: PropTypes.func,
    handleSelectIcon: PropTypes.func,
    reset: PropTypes.func,
    handleUploadImageChange: PropTypes.func.isRequired,
    defaultImage: PropTypes.any,
    avatar: PropTypes.any,
    gender: PropTypes.any,
};

export default UploadImage;
