import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import CarouselItem from "./carousel-item/carousel-item";

import { carouselList } from "./carousel.config";
import { Icon } from "../../../../components/image/image";

import "./index.scss";

const Carousel = () => {
    const { t } = useTranslation();
    const stepPage = 1;
    const minAmountOfPage = 1;

    const [x, setX] = useState(0);
    const [breadcrumbsAmount, setBreadcrumbsAmount] = useState(0);
    const [currentPage, setCurrentPage] = useState(minAmountOfPage);

    const [IsPagination, setIsPagination] = useState(true);

    let arr = [];
    for (let i = 0; i < breadcrumbsAmount; i++) {
        arr[i] = i + 1;
    }

    const getAmountToggleItems = (width) => {
        const widtCarouselItem =
            document.querySelector(".carousel_item").offsetWidth;
        if (width <= 280) return widtCarouselItem + 60;
        if (width <= 560) return widtCarouselItem + 60;
        if (width <= 860) return (widtCarouselItem + 60) * 2;
        if (width < 1170) return (widtCarouselItem + 60) * 3;
        return (widtCarouselItem + 60) * 4;
    };

    const toggleLeft = () => {
        const width = document.querySelector(".carousel_list")?.offsetWidth;
        if (currentPage <= minAmountOfPage) {
            setCurrentPage(1);
            setX(0);
        } else {
            setX((prev) => prev - getAmountToggleItems(width));
            setCurrentPage((prevCountPage) => prevCountPage - stepPage);
        }
    };

    const toggleRight = () => {
        const width = document.querySelector(".carousel_list")?.offsetWidth;
        if (currentPage >= breadcrumbsAmount) {
            setCurrentPage(0);
            setX(-getAmountToggleItems(width));
        }
        setX((prev) => prev + getAmountToggleItems(width));
        setCurrentPage((prevCountPage) => prevCountPage + stepPage);
    };

    useEffect(() => {
        window.screen.width < 767 ? setIsPagination(false) : null;
        const res = Math.round(
            document.querySelector(".carousel_list")?.offsetWidth / 300
        );
        const result = carouselList.length / res;
        setBreadcrumbsAmount(result);
    }, [toggleLeft, toggleRight]);
    return (
        <div className="carousel">
            <div className="carousel_content">
                <h2 className="carousel_title">
                    {t("profile_carousel_title")}
                </h2>
                <div
                    className={`carousel_list${
                        IsPagination ? "" : "-mobile-scroll"
                    }`}
                >
                    {carouselList.map((card, index) => (
                        <CarouselItem
                            key={card.title + index}
                            {...card}
                            style={{ transform: `translate(${-x}px)` }}
                        />
                    ))}
                </div>
                {IsPagination && (
                    <div className="carousel_pagination">
                        <div className="carousel_pagination__crumbs">
                            {arr.map((index) => (
                                <span
                                    key={index}
                                    className={`carousel_pagination__crumbs_item${
                                        index === currentPage ? "-active" : ""
                                    }`}
                                />
                            ))}
                        </div>
                        <div className="carousel_pagination__services">
                            <div
                                className={`carousel_pagination__services_left${
                                    currentPage === 1 ? "-blocked" : ""
                                }`}
                                onClick={toggleLeft}
                            >
                                <Icon type="arrowLeftWhite" />
                            </div>
                            <div
                                className={`carousel_pagination__services_right`}
                                onClick={toggleRight}
                            >
                                <Icon type="arrowRightWhite" />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Carousel;
