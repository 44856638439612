import { ORDERS_FILTERS_SPECIALIST } from "../specialist-orders/specialist-orders.config";
import { notificationName } from "./notifications.constants";
import { ORDERS_FILTERS } from "../orders/orders.constant";
import { userRoles } from "../../constants/user-roles";
import routerBook from "../../router/router-book";

import avatarSuccess from "../../assets/images/chat/avatar_green_wconnect.svg";
import avatarFailed from "../../assets/images/chat/avatar_red_wconnect.svg";

export const notificationConfig = {
    [notificationName.WELCOME]: {
        TOASTIFY_MESSAGE: "success",
        IMAGE: avatarSuccess,
        SHOW_MORE: false,
    },
    [notificationName.PROMOTION_IS_BUY]: {
        TOASTIFY_MESSAGE: "success",
        IMAGE: avatarSuccess,
        SHOW_MORE: false,
    },
    [notificationName.ORDER_CREATED]: {
        REDIRECT: {
            [userRoles.customer]: `${routerBook.orders}/${ORDERS_FILTERS.NEW}`,
        },
        TOASTIFY_MESSAGE: "success",
        IMAGE: avatarSuccess,
        SHOW_MORE: true,
    },
    [notificationName.PRICE_FIXED]: {
        REDIRECT: {
            [userRoles.customer]: `${routerBook.orders}/${ORDERS_FILTERS.PRICE_IS_FIXED}`,
            [userRoles.specialist]: `${routerBook.accessForSpecialist.orders}/${ORDERS_FILTERS_SPECIALIST.PRICE_IS_FIXED}`,
        },
        TOASTIFY_MESSAGE: "success",
        IMAGE: avatarSuccess,
        SHOW_MORE: true,
    },
    [notificationName.ORDER_IN_WORK]: {
        REDIRECT: {
            [userRoles.customer]: `${routerBook.orders}/${ORDERS_FILTERS.IN_WORK}`,
            [userRoles.specialist]: `${routerBook.accessForSpecialist.orders}/${ORDERS_FILTERS_SPECIALIST.IN_WORK}`,
        },
        TOASTIFY_MESSAGE: "success",
        IMAGE: avatarSuccess,
        SHOW_MORE: true,
    },
    [notificationName.ORDER_COMPLETED]: {
        REDIRECT: {
            [userRoles.customer]: `${routerBook.orders}/${ORDERS_FILTERS.COMPLETED}`,
            [userRoles.specialist]: `${routerBook.accessForSpecialist.orders}/${ORDERS_FILTERS_SPECIALIST.COMPLETED}`,
        },
        TOASTIFY_MESSAGE: "success",
        IMAGE: avatarSuccess,
        SHOW_MORE: true,
    },
    [notificationName.CANCELLED]: {
        REDIRECT: {
            [userRoles.customer]: `${routerBook.orders}/${ORDERS_FILTERS.CANCELLED}`,
            [userRoles.specialist]: `${routerBook.accessForSpecialist.orders}/${ORDERS_FILTERS_SPECIALIST.CANCELLED}`,
        },
        TOASTIFY_MESSAGE: "error",
        IMAGE: avatarFailed,
        SHOW_MORE: true,
    },
    [notificationName.PROPOSAL_FOR_COOPERATION]: {
        REDIRECT: {
            [userRoles.customer]: `${routerBook.orders}/${ORDERS_FILTERS.NEGOTIATION}`,
            [userRoles.specialist]: `${routerBook.accessForSpecialist.orders}/${ORDERS_FILTERS_SPECIALIST.NEGOTIATION}`,
        },
        TOASTIFY_MESSAGE: "success",
        IMAGE: avatarSuccess,
        SHOW_MORE: true,
    },
    [notificationName.DELETE_USER_AVATAR]: {
        TOASTIFY_MESSAGE: "error",
        IMAGE: avatarFailed,
        SHOW_MORE: false,
    },
    [notificationName.USER_BAN]: {
        TOASTIFY_MESSAGE: "error",
        IMAGE: avatarFailed,
        SHOW_MORE: false,
    },
    [notificationName.USER_UNBAN]: {
        TOASTIFY_MESSAGE: "success",
        IMAGE: avatarSuccess,
        SHOW_MORE: false,
    },
};

export const notificationsWithRequest = [
    notificationName.DELETE_USER_AVATAR,
    notificationName.DELETE_USER,
    notificationName.USER_UNBAN,
    notificationName.USER_BAN,
];
