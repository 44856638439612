import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { t } from "i18next";

import { errorHtmlToastify } from "../../toastify/toastify.operations";

import { IMAGE_UPLOADER_TYPES } from "./image-uploader.constants";

const maxLength = 10;
const maxImageSize = 5242880; //5 Mb
const maxImageSizeInMb = maxImageSize / 1024 / 1024;

const ImageUploader = ({
    id = "",
    type = IMAGE_UPLOADER_TYPES.ORDER_ITEM,
    eventManagementFunctions: {
        callback = () => {},
        ...eventManagementFunctionsRest
    },
}) => {
    const dispatch = useDispatch();

    const errorMaxLength = () => {
        dispatch(
            errorHtmlToastify(
                <h4>{t("toastify.images.max_length", { max: maxLength })}</h4>
            )
        );
        callback([]);
    };

    const errorMaxSize = (file) => {
        dispatch(
            errorHtmlToastify(
                <>
                    <h4>
                        {t("toastify.images.file_name", {
                            name: file.name.substring(0, 20),
                        })}
                    </h4>
                    <h4>{t("toastify.images.file_is_oversize")}</h4>
                    <h4>
                        {t("toastify.images.max_size", {
                            size: maxImageSizeInMb,
                        })}
                    </h4>
                </>
            )
        );
        callback([]);
    };

    return (
        <>
            <label htmlFor={id} className={type} />
            <input
                style={{ display: "none" }}
                multiple
                type="file"
                id={id}
                accept="image/png, image/gif, image/jpeg"
                onChange={({ target }) => {
                    if (target.files && target.files.length > 0) {
                        const { files } = target;
                        let arr = [];
                        Object.values(files).forEach((file) => {
                            arr.push(file);
                            if (files.length > maxLength) {
                                return errorMaxLength();
                            }
                            if (file.size > maxImageSize) {
                                return errorMaxSize(file);
                            }
                            if (arr.length === files.length) {
                                return callback(arr);
                            }
                        });
                    }
                }}
                {...eventManagementFunctionsRest}
            />
        </>
    );
};

ImageUploader.propTypes = {
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    eventManagementFunctions: PropTypes.object,
    value: PropTypes.array,
    setImage: PropTypes.func,
};

export default ImageUploader;
