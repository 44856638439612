import { specialistServicesTypes } from "./specialist-services.types";

const initialState = {
    list: { items: [], totalCount: 0 },
    selectedService: null,

    loading: true,
    error: "",
};

export const specialistServices = (state = initialState, { type, payload }) => {
    switch (type) {
        // loading
        case specialistServicesTypes.GET_SPECIALIST_SERVICE_PENDING:
        case specialistServicesTypes.POST_SPECIALIST_SERVICE_PENDING:
        case specialistServicesTypes.GET_SPECIALIST_SERVICES_PENDING:
        case specialistServicesTypes.REMOVE_SPECIALIST_SERVICE_PENDING:
        case specialistServicesTypes.UPDATE_SPECIALIST_SERVICE_PENDING:
        case specialistServicesTypes.REMOVE_SPECIALIST_SERVICE_IMAGE_PENDING: {
            return { ...state, loading: true };
        }

        // data all
        case specialistServicesTypes.GET_SPECIALIST_SERVICES_FULFILLED: {
            return { ...state, loading: false, list: payload };
        }

        case specialistServicesTypes.REMOVE_SPECIALIST_SERVICE_IMAGE_FULFILLED: {
            return {
                ...state,
                loading: false,
                list: {
                    ...state.list,
                    items: state.list.items.map((item) => {
                        if (item._id === payload.serviceID) {
                            item.images = item.images.filter(
                                (imageItem) => imageItem._id !== payload.imageID
                            );

                            return item;
                        }

                        return item;
                    }),
                },
            };
        }

        case specialistServicesTypes.UPDATE_SPECIALIST_SERVICE_FULFILLED:
            return {
                ...state,
                loading: false,
                list: {
                    ...state.list,
                    items: state.list.items.map((item) =>
                        item._id === payload._id ? payload : item
                    ),
                },
            };

        case specialistServicesTypes.POST_SPECIALIST_SERVICE_FULFILLED: {
            return {
                ...state,
                loading: false,
                list: {
                    items: [payload, ...state.list.items],
                    totalCount: state.totalCount + 1,
                },
            };
        }

        case specialistServicesTypes.REMOVE_SPECIALIST_SERVICE_FULFILLED: {
            return {
                ...state,
                loading: false,
                list: {
                    items: state.list.items.filter(
                        (item) => item._id !== payload
                    ),
                    totalCount: state.totalCount - 1,
                },
            };
        }

        case specialistServicesTypes.POST_SPECIALIST_SERVICE_IMAGE_FULFILLED: {
            return {
                ...state,
                loading: false,
                list: {
                    ...state.list,
                    items: state.list.items.map((item) => {
                        if (item._id === payload.serviceID) {
                            const updatedService = {
                                ...item,
                                images: payload.images,
                            };

                            return updatedService;
                        }

                        return item;
                    }),
                },
            };
        }

        case specialistServicesTypes.GET_SPECIALIST_SERVICE_FULFILLED: {
            return { ...state, loading: false, selectedService: payload };
        }

        // error
        case specialistServicesTypes.REMOVE_SPECIALIST_SERVICE_REJECTED:
        case specialistServicesTypes.GET_SPECIALIST_SERVICE_REJECTED:
        case specialistServicesTypes.GET_SPECIALIST_SERVICES_REJECTED:
        case specialistServicesTypes.POST_SPECIALIST_SERVICE_REJECTED:
        case specialistServicesTypes.REMOVE_SPECIALIST_SERVICE_IMAGE_REJECTED:
        case specialistServicesTypes.UPDATE_SPECIALIST_SERVICE_REJECTED:
        case specialistServicesTypes.POST_SPECIALIST_SERVICE_IMAGE_REJECTED: {
            return { ...state, loading: false, error: payload };
        }

        default:
            return state;
    }
};
