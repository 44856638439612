import io from "socket.io-client";

import { BASE_URL } from "../../services/api/api.config";
import { store } from "../../store/store";

export const socket = () => {
    const token = store.getState().auth.token;

    if (token)
        return io(`${BASE_URL}?token=${token}`, {
            transports: ["websocket", "polling"],
            reconnection: true,
            forceNew: true,
            secure: true,
        });
    return io(BASE_URL);
};
