import { useNavigate, useParams } from "react-router-dom";
import { useForm, useFormState } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import React, { Fragment } from "react";

import { buttonLoaderSelector } from "../../../../../components/loader/loader.selectors";
import { resetPassword } from "../../../auth.operations";

import { forgotPasswordConfirmSchema } from "./forgot-password-confirm.schema";
import ReactHelmet from "../../../../../components/react-helmet/react-helmet";
import { isEmptyObject } from "../../../../../helpers/is-empty-object";
import ButtonLoader from "../../../../../components/loader/components/button-loader/button-loader";
import Input from "../../../../../components/input/input";

import forgotPasswordIcon from "../../../../../assets/images/auth/forgot_password.svg";
import "./index.scss";

const ForgotPasswordConfirm = () => {
    const {
        handleSubmit,
        register,
        control,
        formState: { errors, isValid },
    } = useForm({
        mode: "all",
        resolver: yupResolver(forgotPasswordConfirmSchema),
    });

    const { status: buttonLoading } = useSelector(buttonLoaderSelector);
    const { dirtyFields } = useFormState({ control });
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { token } = useParams();

    const handleFormSubmit = (data) => {
        dispatch(resetPassword({ ...data, token }, navigate));
    };

    return (
        <Fragment>
            <ReactHelmet title="forgot_password_confirm_title" />
            <div className="forgot-password__confirm">
                <div className="forgot-password__confirm_content">
                    <img
                        src={forgotPasswordIcon}
                        alt="forgot password"
                        className="forgot-password__confirm-img"
                    />
                    <span className="forgot-password__confirm-title">
                        {t("forgot_password_confirm_title")}
                    </span>
                    <p className="forgot-password__confirm-subtitle">
                        {t("forgot_password_confirm_subtitle")}
                    </p>
                    <form onSubmit={handleSubmit(handleFormSubmit)}>
                        <Input
                            inputChangeOptions={register("newPassword")}
                            error={errors.newPassword?.message}
                            options={{
                                placeholderText: "password",
                            }}
                            label={t("label_create_password")}
                            type="password"
                        />
                        <Input
                            inputChangeOptions={register("confirmNewPassword")}
                            error={errors.confirmNewPassword?.message}
                            options={{
                                placeholderText: "password_confirm",
                            }}
                            label={t("label_confirm_password")}
                            type="password"
                        />
                        <button
                            className="forgot-password__confirm-btn"
                            style={{
                                background: buttonLoading && "#dfdfdf",
                            }}
                            disabled={
                                isEmptyObject(dirtyFields) ||
                                !isValid ||
                                buttonLoading
                            }
                        >
                            {buttonLoading ? (
                                <ButtonLoader size={38} />
                            ) : (
                                t("forgot_password_confirm_button")
                            )}
                        </button>
                    </form>
                </div>
            </div>
        </Fragment>
    );
};

export default ForgotPasswordConfirm;
