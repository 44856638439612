import { useCallback, useEffect, useState, memo } from "react";
import PropTypes from "prop-types";

import FilesPreview from "../files-preview/files-preview";

import { ReactComponent as LeftIcon } from "../../../../../../../assets/images/chat/left-carousel.svg";
import { ReactComponent as RightIcon } from "../../../../../../../assets/images/chat/right-carousel.svg";
import "./index.scss";

const ChatCarousel = ({ details: { carousel = [], url, video } }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [totalItems, setTotalItems] = useState(0);

    const next = useCallback(
        function next() {
            const totalItemIndex = totalItems - 1;
            if (activeIndex === totalItemIndex) setActiveIndex(0);
            if (activeIndex < totalItemIndex) setActiveIndex(activeIndex + 1);
        },
        [totalItems, activeIndex]
    );

    const previous = () => {
        if (activeIndex === 0) return setActiveIndex(totalItems - 1);
        setActiveIndex((activeIndex - 1) % totalItems);
    };

    useEffect(() => setTotalItems(carousel.length), [carousel.length]);

    const displayNext = () => {
        if (activeIndex !== totalItems - 1) return true;
        return false;
    };

    const displayPrevious = () => {
        if (activeIndex !== 0) return true;
        return false;
    };

    useEffect(() => {
        carousel.find(({ image }, index) => {
            if (image.url === url) setActiveIndex(index);
        });
    }, [url, carousel]);

    return video ? (
        <FilesPreview video={video} />
    ) : (
        <div className="chat-carousel">
            <span className="chat-carousel__count">{`${
                activeIndex + 1
            }/${totalItems}`}</span>
            {carousel.length && (
                <div className="chat-carousel__inner">
                    {carousel.map(({ image, _id }, index) => (
                        <div
                            className={`chat-carousel__inner-item ${
                                activeIndex === index && "active"
                            }`}
                            key={_id}
                        >
                            <img src={image.url} />
                        </div>
                    ))}
                </div>
            )}
            <div className="chat-carousel__actions">
                {displayPrevious() && (
                    <button
                        className="chat-carousel__actions-control-left"
                        onClick={previous}
                    >
                        <LeftIcon />
                    </button>
                )}
                {displayNext() && (
                    <button
                        className="chat-carousel__actions-control-right"
                        onClick={next}
                    >
                        <RightIcon />
                    </button>
                )}
            </div>
        </div>
    );
};

ChatCarousel.propTypes = { details: PropTypes.object };
ChatCarousel.defaultProps = { details: {} };
export default memo(ChatCarousel);
