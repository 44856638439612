import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import "./index.scss";

const CarouselItem = ({ title = "", image = "", style = {} }) => {
    const { t } = useTranslation();

    return (
        <div className="carousel_item" style={style}>
            <div className="carousel_item_title">{t(title)}</div>
            <img className="carousel_item_image" src={image} />
        </div>
    );
};
CarouselItem.propTypes = {
    title: PropTypes.string,
    style: PropTypes.object,
    image: PropTypes.string,
};
export default CarouselItem;
