// get all orders
import { ordersSpecialistTypes } from "./specialist-orders.types";

export const getAllOrdersPending = () => ({
    type: ordersSpecialistTypes.GET_ALL_ORDERS_PENDING,
});

export const getAllOrdersFulfilled = (data) => ({
    type: ordersSpecialistTypes.GET_ALL_ORDERS_FULFILLED,
    payload: data,
});

export const getAllOrdersRejected = (error) => ({
    type: ordersSpecialistTypes.GET_ALL_ORDERS_REJECTED,
    payload: error,
});

export const getOrderPending = () => ({
    type: ordersSpecialistTypes.GET_ORDER_PENDING,
});

export const getOrderFulfilled = (data) => ({
    type: ordersSpecialistTypes.GET_ORDER_FULFILLED,
    payload: data,
});

export const getOrderRejected = (error) => ({
    type: ordersSpecialistTypes.GET_ORDER_REJECTED,
    payload: error,
});

//params
export const setStatus = (data) => ({
    type: ordersSpecialistTypes.SET_STATUS,
    payload: data,
});
export const setPage = (data) => ({
    type: ordersSpecialistTypes.SET_PAGE,
    payload: data,
});
export const clearAllParams = () => ({
    type: ordersSpecialistTypes.CLEAR_ALL_PARAMS,
});
