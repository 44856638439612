import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { feedbackSchema } from "./feedback.schema";
import { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { t } from "i18next";

import { createFeedback, updateFeedback } from "../../feedback.operations";
import { buttonLoaderSelector } from "../../../../components/loader/loader.selectors";
import { feedbackSelector } from "../../feedback.selector";

import AssessmentPicker from "../../../../components/input/assessment-input/assesment-picker";
import { isEmptyObject } from "../../../../helpers/is-empty-object";
import ButtonLoader from "../../../../components/loader/components/button-loader/button-loader";
import { isExistFeedback } from "./feedback-form.utils";

import "./index.scss";

const FeedbackForm = ({ taskId, orderId }) => {
    const { feedbackBody, userFeedbacks } = useSelector(feedbackSelector);
    const { status: isLoading } = useSelector(buttonLoaderSelector);
    const { text, assessment } = feedbackBody;
    const dispatch = useDispatch();

    const {
        handleSubmit,
        register,
        control,
        reset,
        formState: { errors, isValid },
    } = useForm({
        mode: "all",
        defaultValues: useMemo(() => {
            return { text, assessment };
        }, [text, assessment]),
        resolver: yupResolver(feedbackSchema),
    });

    useEffect(() => reset({ text, assessment }), [text, assessment]);

    const handleFormSubmit = (data) => {
        if (isExistFeedback(userFeedbacks, taskId)) {
            return dispatch(updateFeedback(feedbackBody, userFeedbacks, data));
        }
        dispatch(createFeedback(taskId, orderId, data));
    };

    return (
        <form
            onSubmit={handleSubmit(handleFormSubmit)}
            className="feedback-form"
        >
            <div className="feedback-form__assessment-picker-container">
                <Controller
                    name="assessment"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                        return (
                            <AssessmentPicker
                                onChange={onChange}
                                value={value}
                            />
                        );
                    }}
                />
            </div>
            <div className="feedback-form__textarea-container">
                <div className="feedback-form__info">
                    <label className="feedback-form__info_label">
                        {t("create_feedback__textarea_label")}
                    </label>
                    <label className="feedback-form__info_error">
                        {t(errors?.text?.message)}
                    </label>
                </div>
                <textarea
                    placeholder={t("create_feedback__textarea_placeholder")}
                    className="feedback-form__textarea"
                    {...register("text")}
                />
            </div>
            <div className="feedback-form__submit-button-container">
                <button
                    disabled={!isEmptyObject(errors) || isLoading || !isValid}
                    style={{ background: isLoading ? "#f3f4f6" : "" }}
                    className="feedback-form__sumbit-button"
                    type="submit"
                >
                    {isLoading ? (
                        <ButtonLoader size={38} />
                    ) : (
                        t("create_feedback__submit_button")
                    )}
                </button>
            </div>
        </form>
    );
};

FeedbackForm.propTypes = {
    orderId: PropTypes.string,
    taskId: PropTypes.string,
};

export default FeedbackForm;
