export const balanceTypes = {
    // get balance
    GET_BALANCE_PENDING: "balance/GET_BALANCE_PENDING",
    GET_BALANCE_FULFILLED: "balance/GET_BALANCE_FULFILLED",
    GET_BALANCE_REJECTED: "balance/GET_BALANCE_REJECTED",

    // get transactions
    GET_TRANSACTIONS_PENDING: "balance/GET_TRANSACTIONS_PENDING",
    GET_TRANSACTIONS_FULFILLED: "balance/GET_TRANSACTIONS_FULFILLED",
    GET_TRANSACTIONS_REJECTED: "balance/GET_TRANSACTIONS_REJECTED",

    // refill balance
    REFILL_BALANCE_PENDING: "balance/REFILL_BALANCE_PENDING",
    REFILL_BALANCE_FULFILLED: "balance/REFILL_BALANCE_FULFILLED",
    REFILL_BALANCE_REJECTED: "balance/REFILL_BALANCE_REJECTED",

    // payout balance
    PAYOUT_BALANCE_PENDING: "balance/PAYOUT_BALANCE_PENDING",
    PAYOUT_BALANCE_FULFILLED: "balance/PAYOUT_BALANCE_FULFILLED",
    PAYOUT_BALANCE_REJECTED: "balance/PAYOUT_BALANCE_REJECTED",

    // create stripe
    CREATE_STRIPE_PENDING: "balance/CREATE_STRIPE_PENDING",
    CREATE_STRIPE_FULFILLED: "balance/CREATE_STRIPE_FULFILLED",
    CREATE_STRIPE_REJECTED: "balance/CREATE_STRIPE_REJECTED",

    // create stripe
    CREATE_IYZICO_PENDING: "balance/CREATE_IYZICO_PENDING",
    CREATE_IYZICO_FULFILLED: "balance/CREATE_IYZICO_FULFILLED",
    CREATE_IYZICO_REJECTED: "balance/CREATE_IYZICO_REJECTED",

    // set
    CLEAR_BALANCE: "balance/CLEAR_BALANCE",
};

// ******************* new balance types **********************
export const userBalanceTypes = {
    // get user balance
    GET_USER_BALANCE_REQUEST: "userBalance/GET_USER_BALANCE_REQUEST",
    GET_USER_BALANCE_SUCCESS: "userBalance/GET_USER_BALANCE_SUCCESS",
    GET_USER_BALANCE_ERROR: "userBalance/GET_USER_BALANCE_ERROR",

    // get user balance details
    GET_USER_BALANCE_DETAILS_REQUEST:
        "userBalance/GET_USER_BALANCE_DETAILS_REQUEST",
    GET_USER_BALANCE_DETAILS_SUCCESS:
        "userBalance/GET_USER_BALANCE_DETAILS_SUCCESS",
    GET_USER_BALANCE_DETAILS_ERROR:
        "userBalance/GET_USER_BALANCE_DETAILS_ERROR",

    // get user balance full details
    GET_USER_BALANCE_FULL_DETAILS_REQUEST:
        "userBalance/GET_USER_BALANCE_FULL_DETAILS_REQUEST",
    GET_USER_BALANCE_FULL_DETAILS_SUCCESS:
        "userBalance/GET_USER_BALANCE_FULL_DETAILS_SUCCESS",
    GET_USER_BALANCE_FULL_DETAILS_ERROR:
        "userBalance/GET_USER_BALANCE_FULL_DETAILS_ERROR",

    // get user balance history
    GET_USER_BALANCE_HISTORY_REQUEST:
        "userBalance/GET_USER_BALANCE_HISTORY_REQUEST",
    GET_USER_BALANCE_HISTORY_SUCCESS:
        "userBalance/GET_USER_BALANCE_HISTORY_SUCCESS",
    GET_USER_BALANCE_HISTORY_ERROR:
        "userBalance/GET_USER_BALANCE_HISTORY_ERROR",

    // initialize session pay
    INITIALIZE_SESSION_PAY_REQUEST:
        "userBalance/INITIALIZE_SESSION_PAY_REQUEST",
    INITIALIZE_SESSION_PAY_SUCCESS:
        "userBalance/INITIALIZE_SESSION_PAY_SUCCESS",
    INITIALIZE_SESSION_PAY_ERROR: "userBalance/INITIALIZE_SESSION_PAY_ERROR",

    // check session pay
    CHECK_SESSION_PAY_REQUEST: "userBalance/CHECK_SESSION_PAY_REQUEST",
    CHECK_SESSION_PAY_SUCCESS: "userBalance/CHECK_SESSION_PAY_SUCCESS",
    CHECK_SESSION_PAY_ERROR: "userBalance/CHECK_SESSION_PAY_ERROR",

    // withdrawal money
    WITHDRAWAL_MONEY_REQUEST: "userBalance/WITHDRAWAL_MONEY_REQUEST",
    WITHDRAWAL_MONEY_SUCCESS: "userBalance/WITHDRAWAL_MONEY_SUCCESS",
    WITHDRAWAL_MONEY_ERROR: "userBalance/WITHDRAWAL_MONEY_ERROR",

    // print invoice
    PRINT_INVOICE_REQUEST: "userBalance/PRINT_INVOICE_REQUEST",
    PRINT_INVOICE_SUCCESS: "userBalance/PRINT_INVOICE_SUCCESS",
    PRINT_INVOICE_ERROR: "userBalance/PRINT_INVOICE_ERROR",

    // print invoice list
    PRINT_INVOICE_LIST_REQUEST: "userBalance/PRINT_INVOICE_LIST_REQUEST",
    PRINT_INVOICE_LIST_SUCCESS: "userBalance/PRINT_INVOICE_LIST_SUCCESS",
    PRINT_INVOICE_LIST_ERROR: "userBalance/PRINT_INVOICE_LIST_ERROR",
};
