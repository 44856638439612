import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { myAccountSelector } from "../../pages/my-account/my-account.selectors";
import { setNavigate } from "../error-handler/error-handler.actions";
import { globalLoaderSelector } from "../loader/loader.selectors";
import { authSelector } from "../../pages/auth/auth.selectors";

import GlobalLoader from "../loader/components/global-loader/global-loader";
import { errorHandlerTypes } from "../error-handler/error-handler.constants";
import ErrorBoundary from "../error-boundry/error-boundry";
import ErrorHandler from "../error-handler/error-handler";
import Footer from "./components/footer/footer";
import Header from "./components/header/header";

import "./index.scss";

const Layout = () => {
    const [internetStatus, setInternetStatus] = useState(
        () => window.navigator.onLine
    );

    const isLoading = useSelector(globalLoaderSelector);
    const { token } = useSelector(authSelector);
    const {
        data: { isBanned },
    } = useSelector(myAccountSelector);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const checkingOffline = useCallback(() => setInternetStatus(false), []);
    const checkingOnline = useCallback(() => setInternetStatus(true), []);

    useEffect(() => {
        dispatch(setNavigate({ navigate }));
    }, []);

    useEffect(() => {
        window.addEventListener("online", checkingOnline);
        window.addEventListener("offline", checkingOffline);
        return () => {
            window.removeEventListener("online", checkingOnline);
            window.removeEventListener("offline", checkingOffline);
        };
    }, [checkingOnline, checkingOffline]);

    const displayContent = () => {
        switch (true) {
            case !internetStatus:
                return <ErrorHandler type={errorHandlerTypes.NO_INTERNET} />;
            case isBanned:
                return <ErrorHandler type={errorHandlerTypes.BLOCKED_USER} />;
            default:
                return Outlet();
        }
    };

    return (
        <>
            {isLoading && <GlobalLoader />}
            <div className="layout_container">
                <Header isBanned={isBanned} token={token} />
                <ErrorBoundary key={location.pathname}>
                    {displayContent()}
                </ErrorBoundary>
                {!isBanned && <Footer />}
            </div>
        </>
    );
};

export default Layout;
