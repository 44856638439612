const book = Object.freeze({
    home: "/",
    employeeInfo: "/employee-info",
    customerInfo: "/customer-info",
    privacyPolicy: "/privacy-policy",
    termsAndConditions: "/terms",
    academy: "/academy",
    academyInfo: "/academy/:id",
    auth: {
        main: "/auth",
        accountType: "/user-type",
        emailConfirmation: "/email-confirmation",
        phoneConfirmation: "/phone-confirmation",
        confirmationMessage: "/confirmation-message",
        confirmationMessageWithToken: "/check-email-code/:token",
        verificationMessageWithToken: "/verify-email-code/:token",
        location: "/select-location",
        register: "/register",
        conditions: "/conditions",
        moderation: "/moderation",
        login: "/login",
        forgotPassword: "/forgot-password",
        resetPassword: "/reset-password/:token",
        restoreAccount: "/restore-account/:token",
    },
    myAccount: "/profile-edit",
    myAccountInfo: "/profile-info",
    balance: "/balance",
    chats: "/chats",
    chatsItem: "/chats/:id",
    orders: "/orders",
    orderInfo: "/order-info",
    orderInfoWithParameter: "/order-info/:orderId/task/:taskId",
    ordersWithParameter: "/orders/:filterParameter",
    createOrder: "/create-an-order",
    allServices: "/all-services",
    allServicesResult: "/all-services-result",
    specialistsList: "/specialists",
    specialistProfile: "/specialists/:id",
    proposalsForCooperation: "/proposals-for-cooperation",
    proposalsForCooperationItem: "/proposals-for-cooperation/:id",
    accessForSpecialist: {
        services: "/my-services",
        orders: "/my-orders",
        ordersWithParameter: "/my-orders/:filterParameter",
        newOrders: "/new-orders",
        newOrdersItem: "/new-orders/:id",
        promotion: "/promotion",
        promotionWithParameter: "/promotion/:filterParameter",
        promotionWithParameterInfo: "/promotion/condition/:id",
    },
    errors: {
        accessDeniedError: "/error-access",
        somethingWrong: "/something-wrong",
        notFound: "/not-found",
    },
    changeLocation: "/change-location",
    about: "/about",
    clients: "/clients",
    faq: "/faq",
    notifications: "/notifications",
    favorites: "/favourites",
    support: "/support",
    supportChat: "/support/chat/:questionId/:chatId",
    socialMedia: {
        facebook: "https://www.facebook.com/wconnect.work",
        instagram: "https://www.instagram.com/wconnect.work",
        twitter: "https://twitter.com/WconnectWork",
        linkedin: "https://www.linkedin.com/company/wconnectwork/about",
        tiktok: "https://www.tiktok.com/@wconnect.work",
    },
    appLinks: {
        appStore: "https://apps.apple.com/ua/app/wconnect/id1630153752",
        googlePlay:
            "https://play.google.com/store/apps/details?id=work.connect.w",
    },
});

export default book;
