import { balanceTypes, userBalanceTypes } from "./balance.types";

const initialState = {
    balance: {
        balance: 0,
        money: 0,
        pennies: 0,
    },
    history: {
        balance: [],
    },
    loading: false,
    error: "",

    // old balance feature
    currentBalance: { availableBalance: 0, pendingBalance: 0 },
    transactions: { items: [], next: "" },
};

export const balance = (state = initialState, { type, payload }) => {
    switch (type) {
        // loading
        case balanceTypes.GET_TRANSACTIONS_PENDING:
        case balanceTypes.PAYOUT_BALANCE_PENDING:
        case balanceTypes.REFILL_BALANCE_PENDING:
        case balanceTypes.CREATE_IYZICO_PENDING:
        case balanceTypes.CREATE_STRIPE_PENDING:
        case balanceTypes.GET_BALANCE_PENDING: {
            return { ...state, loading: true };
        }

        // case userBalanceTypes.WITHDRAWAL_MONEY_REQUEST:
        case userBalanceTypes.GET_USER_BALANCE_REQUEST:
        case userBalanceTypes.GET_USER_BALANCE_HISTORY_REQUEST:
        case userBalanceTypes.CHECK_SESSION_PAY_REQUEST:
        case userBalanceTypes.PRINT_INVOICE_REQUEST:
        case userBalanceTypes.PRINT_INVOICE_LIST_REQUEST: {
            return { ...state, loading: true };
        }

        // data balance
        case balanceTypes.PAYOUT_BALANCE_FULFILLED:
        case balanceTypes.GET_BALANCE_FULFILLED: {
            return { ...state, loading: false, currentBalance: payload };
        }

        // case userBalanceTypes.WITHDRAWAL_MONEY_SUCCESS:
        case userBalanceTypes.GET_USER_BALANCE_SUCCESS:
        case userBalanceTypes.CHECK_SESSION_PAY_SUCCESS: {
            return { ...state, loading: false, balance: payload };
        }
        case userBalanceTypes.GET_USER_BALANCE_HISTORY_SUCCESS: {
            return { ...state, loading: false, history: payload };
        }
        case userBalanceTypes.PRINT_INVOICE_SUCCESS:
        case userBalanceTypes.PRINT_INVOICE_LIST_SUCCESS: {
            return { ...state, loading: false };
        }

        // data transactions
        case balanceTypes.GET_TRANSACTIONS_FULFILLED: {
            return { ...state, loading: false, transactions: payload };
        }

        // error
        case balanceTypes.GET_TRANSACTIONS_REJECTED:
        case balanceTypes.PAYOUT_BALANCE_REJECTED:
        case balanceTypes.REFILL_BALANCE_REJECTED:
        case balanceTypes.CREATE_IYZICO_REJECTED:
        case balanceTypes.CREATE_STRIPE_REJECTED:
        case balanceTypes.GET_BALANCE_REJECTED: {
            return { ...state, loading: false, error: payload };
        }

        // case userBalanceTypes.WITHDRAWAL_MONEY_ERROR:
        case userBalanceTypes.GET_USER_BALANCE_ERROR:
        case userBalanceTypes.GET_USER_BALANCE_HISTORY_ERROR:
        case userBalanceTypes.CHECK_SESSION_PAY_ERROR:
        case userBalanceTypes.PRINT_INVOICE_ERROR:
        case userBalanceTypes.PRINT_INVOICE_LIST_ERROR: {
            return { ...state, loading: false, error: payload };
        }

        // clear
        case balanceTypes.CLEAR_BALANCE: {
            return initialState;
        }

        default:
            return state;
    }
};
