import axios from "axios";

import { setErrorHandler } from "../../components/error-handler/error-handler.operations";
import { clearAccountData } from "../../pages/my-account/my-account.actions";
import { logoutUser, updateTokens } from "../../pages/auth/auth.actions";
import { clearModalState } from "../../components/modal/modal.actions";
import { errorHandlerSelector } from "../../components/error-handler/error-handler.selectors";
import { store } from "../../store/store";
import {
    sessionAccessTokenSelector,
    sessionRefreshTokenSelector,
} from "../../pages/auth/auth.selectors";

import { getLanguage } from "../../helpers/language";
import routerBook from "../../router/router-book";
import { BASE_URL } from "./api.config";

// multipart
export const formDataHeaders = {
    accept: "*/*",
    ContentType: "multipart/form-data",
    "Access-Control-Allow-Origin": "*",
};

// for multiple requests
let isRefreshing = false;
let failedQueue = [];

// failed requests queue
const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

const baseURL = `${BASE_URL}/api/v1`;
const apiClient = axios.create({ baseURL });

apiClient.interceptors.request.use(
    (config) => {
        const reduxStore = store.getState();
        const token = sessionAccessTokenSelector(reduxStore);

        config.headers["Accept-Language"] = getLanguage().value;
        const contentType = config.ContentType
            ? config.ContentType
            : "application/json";

        if (token) config.headers["Authorization"] = "Bearer " + token;
        (config.headers["Access-Control-Allow-Credentials"] = true),
            (config.headers["Content-Type"] = contentType);

        return config;
    },
    (error) => Promise.reject(error)
);

apiClient.interceptors.response.use(
    (response) => {
        return response;
    },
    async function (error) {
        const originalRequest = await error.config;
        store.dispatch(setErrorHandler(error.response));
        if (error.response.status === 401 && !originalRequest._retry) {
            if (isRefreshing) {
                return new Promise(function (resolve, reject) {
                    failedQueue.push({ resolve, reject });
                })
                    .then((token) => {
                        originalRequest.headers["Authorization"] =
                            "Bearer " + token;
                        return axios(originalRequest);
                    })
                    .catch((err) => {
                        return Promise.reject(err);
                    });
            }

            originalRequest._retry = true;
            isRefreshing = true;

            const reduxStore = store.getState();
            const token = sessionAccessTokenSelector(reduxStore);
            const refreshToken = sessionRefreshTokenSelector(reduxStore);

            axios.defaults.headers.common["Authorization"] = "Bearer " + token;

            return new Promise(function (resolve, reject) {
                axios
                    .post(baseURL + "/auth/refresh-token", {
                        token: refreshToken,
                    })
                    .then((res) => {
                        if (res.status === 200) {
                            delete res.data.role;
                            store.dispatch(updateTokens(res.data));
                            axios.defaults.headers.common["Authorization"] =
                                "Bearer " + res.data.token;
                            originalRequest.headers["Authorization"] =
                                "Bearer " + res.data.token;
                            processQueue(null, res.data.token);
                            resolve(axios(originalRequest));
                        }
                    })
                    .catch((err) => {
                        axios.delete(baseURL + `/auth/logout`).finally(() => {
                            const { navigate } = errorHandlerSelector(
                                store.getState()
                            );

                            store.dispatch(logoutUser());
                            store.dispatch(clearAccountData());
                            navigate(`${routerBook.home}`, { state: true });
                        });
                        processQueue(err, null);
                        reject(err);
                        store.dispatch(clearModalState());
                    })
                    .finally(() => {
                        isRefreshing = false;
                    });
            });
        }
        return Promise.reject(error);
    }
);

const { get, post, put, delete: destroy, patch } = apiClient;

export { get, post, put, destroy, patch };
