export const privacyPolicyConfig = [
    {
        title: "privacy-policy.terms.title",
        titles: [
            { title: "privacy-policy.terms.titles.first" },
            { title: "privacy-policy.terms.titles.second" },
            {
                title: "privacy-policy.terms.titles.third",
                subtitle: "privacy-policy.terms.subtitle",
            },
            { title: "privacy-policy.terms.titles.fourth" },
            { title: "privacy-policy.terms.titles.fifth" },
            { title: "privacy-policy.terms.titles.sixth" },
            { title: "privacy-policy.terms.titles.seventh" },
        ],
    },
    {
        title: "privacy-policy.collected-data.title",
        subtitle: "privacy-policy.collected-data.subtitle",
        subtitles: [
            { subtitle: "privacy-policy.collected-data.subtitles.first" },
            { subtitle: "privacy-policy.collected-data.subtitles.second" },
            { subtitle: "privacy-policy.collected-data.subtitles.third" },
            { subtitle: "privacy-policy.collected-data.subtitles.fourth" },
            { subtitle: "privacy-policy.collected-data.subtitles.fifth" },
            { subtitle: "privacy-policy.collected-data.subtitles.sixth" },
            { subtitle: "privacy-policy.collected-data.subtitles.seventh" },
            { subtitle: "privacy-policy.collected-data.subtitles.eighth" },
            { subtitle: "privacy-policy.collected-data.subtitles.ninth" },
        ],
    },
    {
        title: "privacy-policy.uses-data.title",
        subtitle: "privacy-policy.uses-data.subtitle",
        subtitles: [
            { subtitle: "privacy-policy.uses-data.subtitles.first" },
            { subtitle: "privacy-policy.uses-data.subtitles.second" },
        ],
        footer: "privacy-policy.uses-data.footer",
        footerSubtitle: "privacy-policy.uses-data.footer-subtitle",
    },
    {
        title: "privacy-policy.conidtions-data.title",
        subtitle: "privacy-policy.conidtions-data.subtitle",
        mediumTitle: "privacy-policy.conidtions-data.medium-title",
        mediumSubitle: "privacy-policy.conidtions-data.medium-subitle",
        mediumSubitles: [
            {
                mediumSubitle:
                    "privacy-policy.conidtions-data.medium-subitles.first",
            },
            {
                mediumSubitle:
                    "privacy-policy.conidtions-data.medium-subitles.second",
            },
        ],
        footer: "privacy-policy.conidtions-data.footer",
    },
    {
        title: "privacy-policy.change-delete-data.title",
        undertitle: "privacy-policy.change-delete-data.undertitle",
        mediumTitle: "privacy-policy.change-delete-data.medium-title",
        mediumSubitle: "privacy-policy.change-delete-data.medium-subitle",
        footer: "privacy-policy.change-delete-data.footer",
    },
    {
        title: "privacy-policy.protection-data.title",
        subtitle: "privacy-policy.protection-data.subtitle",
        footer: "privacy-policy.protection-data.footer",
    },
    {
        title: "privacy-policy.additional-data.title",
        subtitle: "privacy-policy.additional-data.subtitle",
        mediumTitle: "privacy-policy.additional-data.medium-title",
        mediumSubitle: "privacy-policy.additional-data.medium-subitle",
        footer: "privacy-policy.additional-data.footer",
    },
];
