import { modalTypes } from "./modal.types";

export const openModal = (status = false) => ({
    type: modalTypes.OPEN_MODAL,
    payload: status,
});

export const closeModal = () => ({
    type: modalTypes.CLOSE_MODAL,
});

export const clearModalState = () => ({
    type: modalTypes.CLEAR_MODAL_STATE,
});
