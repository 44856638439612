import { get, post, patch, destroy, formDataHeaders } from "../api";

export const getOrders = async (params = "") => {
    const url = "/order" + params;

    try {
        const { data } = await get(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const getOrdersForFavourites = async (id, params = "") => {
    const url = "/order/for-favourites" + params + `&userId=${id}`;
    try {
        const { data } = await get(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const getOrder = async (id) => {
    const url = `/order/${id}`;

    try {
        const { data } = await get(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const getOrderTask = async (orderId, taskId) => {
    const url = `/order/${orderId}/tasks/${taskId}`;

    try {
        const { data } = await get(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const getOrderListSearch = async (params = "", role = "") => {
    const url = `/order/${role ? "search" : "anon-search"}` + params;

    try {
        const { data } = await get(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const getOrderTaskOtherSpecialist = async (orderId, taskId) => {
    const url = `/order/${orderId}/tasks/${taskId}/other-specialists`;

    try {
        const { data } = await get(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const getOrderResponders = async (orderId, params = "") => {
    const url = `/order/${orderId}/responders` + params;

    try {
        const { data } = await get(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const postOrder = async (reqData = {}) => {
    const url = "/order";

    try {
        const { data } = await post(url, reqData);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const postOrderImage = async (id, reqData = {}) => {
    const url = `/order/${id}/images`;

    try {
        const { data } = await post(url, reqData, formDataHeaders);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const putOrder = async (reqData = {}, id) => {
    const url = `/order/${id}`;

    try {
        const { data } = await patch(url, reqData);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const putTaskFixPrice = async (orderID, taskID, reqBody = {}) => {
    const url = `/order/${orderID}/tasks/${taskID}/fix-price`;

    try {
        const { data } = await patch(url, reqBody);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const putTaskStatus = async (orderID, taskID, status) => {
    const url = `/order/${orderID}/tasks/${taskID}/${status}`;

    try {
        const { data } = await patch(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const deleteOrder = async (id) => {
    const url = `/order/${id}`;

    try {
        const { data } = await destroy(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const cancelOrder = async (orderID, taskID) => {
    const url = `/order/${orderID}/tasks/${taskID}/cancel`;

    try {
        const { data } = await patch(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const deleteOrderImage = async (id, imageId) => {
    const url = `/order/${id}/images/${imageId}`;

    try {
        const { data } = await destroy(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const reserveFundsRequest = async (orderId, taskId, reqData = {}) => {
    const url = `/order/${orderId}/tasks/${taskId}/reserve-funds`;
    try {
        const { data } = await post(url, reqData);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};
