import { userAddRemoveFavorites } from "../../services/requests/auth";
import * as api from "../../services/requests/service";

import { successToastify } from "../../components/toastify/toastify.operations";
import { allResultSelector } from "../../components/all-result/all-result.selectors";
import * as serviceActions from "./all-services.actions";
import {
    getOrdersResultFulfilled,
    getServicesResultFulfilled,
} from "../../components/all-result/all-result.actions";
import {
    changeGlobalLoader,
    changeButtonLoader,
} from "../../components/loader/loader.actions";

export const getServices =
    (loaderType = "global") =>
    (dispatch) => {
        if (loaderType === "global") {
            dispatch(changeGlobalLoader(true));
        } else {
            dispatch(changeButtonLoader({ status: true, button: "category" }));
        }

        dispatch(serviceActions.getServicePending());

        return api
            .getServices()
            .then(({ items }) => {
                dispatch(serviceActions.getServiceFulfilled(items));
                dispatch(serviceActions.setServiceForSearchFulfilled(items));
            })
            .catch((error) => {
                dispatch(serviceActions.getServiceRejected(error));
            })
            .finally(() =>
                loaderType === "global"
                    ? dispatch(changeGlobalLoader(false))
                    : dispatch(
                          changeButtonLoader({ status: false, button: "" })
                      )
            );
    };

export const getSubservices =
    (categoryId = "", loaderType = "global") =>
    (dispatch) => {
        dispatch(serviceActions.getSubservicePending());
        if (loaderType === "global") {
            dispatch(changeGlobalLoader(true));
        } else {
            dispatch(changeButtonLoader({ status: true, button: "category" }));
        }

        return api
            .getSubservices(categoryId)
            .then(({ items }) => {
                if (categoryId) {
                    dispatch(serviceActions.getSubserviceFulfilled(items));
                } else {
                    dispatch(serviceActions.getSubserviceFulfilled(items));
                    dispatch(
                        serviceActions.setSubserviceForSearchFulfilled(items)
                    );
                }
            })
            .catch((error) => {
                dispatch(serviceActions.getSubserviceRejected(error));
            })
            .finally(() =>
                loaderType === "global"
                    ? dispatch(changeGlobalLoader(false))
                    : dispatch(
                          changeButtonLoader({ status: false, button: "" })
                      )
            );
    };

export const getClarifications =
    (subcategoryId = "", loaderType = "global") =>
    (dispatch) => {
        dispatch(serviceActions.getClarificationPending());
        if (loaderType === "global") {
            dispatch(changeGlobalLoader(true));
        } else {
            dispatch(changeButtonLoader({ status: true, button: "category" }));
        }

        return api
            .getClarifications(subcategoryId)
            .then(({ items }) => {
                if (subcategoryId) {
                    dispatch(serviceActions.getClarificationFulfilled(items));
                } else {
                    dispatch(serviceActions.getClarificationFulfilled(items));
                    dispatch(
                        serviceActions.setClarificationForSearchFulfilled(items)
                    );
                }
            })
            .catch((error) => {
                dispatch(serviceActions.getClarificationRejected(error));
            })
            .finally(() =>
                loaderType === "global"
                    ? dispatch(changeGlobalLoader(false))
                    : dispatch(
                          changeButtonLoader({ status: false, button: "" })
                      )
            );
    };

export const addRemoveFavoritesServices = (data) => (dispatch, getState) => {
    dispatch(serviceActions.addRemoveFavoritesPending());
    const { services } = allResultSelector(getState());

    return userAddRemoveFavorites(data)
        .then(({ message }) => {
            const newServices = {
                ...services,
                items: services.items.map((item) =>
                    item.specialist._id === data.userId
                        ? {
                              ...item,
                              specialist: {
                                  ...item.specialist,
                                  isFavorite: data.addOrRemove,
                              },
                          }
                        : item
                ),
            };
            dispatch(getServicesResultFulfilled(newServices));
            dispatch(successToastify(message));
        })
        .catch((error) => {
            dispatch(serviceActions.addRemoveFavoritesRejected(error));
        });
};

export const addRemoveFavoritesOrders = (data) => (dispatch, getState) => {
    dispatch(serviceActions.addRemoveFavoritesPending());
    const { orders } = allResultSelector(getState());

    return userAddRemoveFavorites(data)
        .then(({ message }) => {
            const newOrders = {
                ...orders,
                items: orders.items.map((item) =>
                    item.customer._id === data.userId
                        ? {
                              ...item,
                              customer: {
                                  ...item.customer,
                                  isFavorite: data.addOrRemove,
                              },
                          }
                        : item
                ),
            };
            dispatch(getOrdersResultFulfilled(newOrders));
            dispatch(successToastify(message));
        })
        .catch((error) => {
            dispatch(serviceActions.addRemoveFavoritesRejected(error));
        });
};
