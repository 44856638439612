import { post, get, patch } from "../api";

export const postQuestion = async (question) => {
    const url = "/question";

    try {
        const { data } = await post(url, question);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const getQuestions = async (params = "") => {
    const url = `/question` + params;

    try {
        const { data } = await get(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const deleteQuestion = async (id) => {
    const url = `/question/deactivate/${id}`;

    try {
        const { data } = await patch(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};
