import noOrdersIcon from "../../../../../../assets/images/error-pages/empty-result.svg";
import PropTypes from "prop-types";
import "./index.scss";

const SpecialistOrderListEmpty = ({ text }) => {
    return (
        <div className="specialist_order_list_empty">
            <img
                className="specialist_order_list_empty__img"
                src={noOrdersIcon}
                alt="no orders"
            />
            <span className="specialist_order_list_empty__title">{text}</span>
        </div>
    );
};

SpecialistOrderListEmpty.propTypes = {
    text: PropTypes.string.isRequired,
};

export default SpecialistOrderListEmpty;
