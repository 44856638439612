import React, { Fragment } from "react";

import CreateOrderForm from "./components/create-order-form/create-order-form";
import ReactHelmet from "../../components/react-helmet/react-helmet";
import CreateOrderHeader from "./components/create-order-header/create-order-header";

import "./index.scss";

const CreateOrder = () => {
    return (
        <Fragment>
            <ReactHelmet title="nav_create_an_order" />
            <div className="create-order">
                <CreateOrderHeader />
                <CreateOrderForm />
            </div>
        </Fragment>
    );
};

export default CreateOrder;
