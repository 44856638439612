import stripeIcon from "../../assets/images/currency/stripe.svg";
import iyzicoIcon from "../../assets/images/currency/iyzico.svg";

export const operationTypes = { refill: "refill", payout: "payout " };

export const paymentTypes = {
    stripe: { value: "stripe", image: stripeIcon },
    iyzico: { value: "iyzico", image: iyzicoIcon },
};

export const paymentMethods = [
    { value: "balance", label: "chats_item.payment_method-balance" },
    // { value: paymentTypes.stripe.value, label: paymentTypes.stripe.image },
    { value: paymentTypes.iyzico.value, label: paymentTypes.iyzico.image },
];

export const paymentTabs = {
    iyzico: "IYZICO_SYSTEM",
    ipara: "IPARA_SYSTEM",
};

export const paymentOperationTypes = {
    deposit: "deposit",
    withdrawal: "withdrawal",
};
