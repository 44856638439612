export const faqTypes = {
    // get all FAQ
    GET_ALL_FAQ_PENDING: "FAQ/GET_ALL_FAQ_PENDING",
    GET_ALL_FAQ_FULFILLED: "FAQ/GET_ALL_FAQ_FULFILLED",
    GET_ALL_FAQ_REJECTED: "FAQ/GET_ALL_FAQ_REJECTED",

    // set
    CLEAR_ALL_PARAMS: "FAQ/CLEAR_ALL_PARAMS",
    SET_PAGE: "FAQ/SET_PAGE",
};
