import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGoogleLogin } from "@react-oauth/google";
// import AppleLogin from "react-apple-login";

import { sendOauthOperation } from "../auth.operations";
import { setFacebookToken } from "../auth.actions";
import { getItem } from "../../../helpers/local-storage";

// import { ReactComponent as AppleIcon } from "../../../assets/images/registration-icons/apple-icon.svg";
import { ReactComponent as GoogleIcon } from "../../../assets/images/registration-icons/google-icon.svg";
import { ReactComponent as FacebookIcon } from "../../../assets/images/registration-icons/facebook-icon.svg";

import "./index.scss";

const OauthLogin = () => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const googleLogin = useGoogleLogin({
        onSuccess: (codeResponse) => {
            dispatch(
                sendOauthOperation(
                    {
                        googleToken: codeResponse.access_token,
                        deviceId: getItem("deviceID"),
                    },
                    navigate
                )
            );
        },
    });

    const facebookLogin = async () => {
        const { authResponse } = await new Promise(window.FB.login);
        if (!authResponse) return;

        dispatch(setFacebookToken(authResponse.accessToken));

        dispatch(
            sendOauthOperation(
                {
                    facebookToken: authResponse.accessToken,
                    deviceId: getItem("deviceID"),
                },
                navigate
            )
        );
    };

    return (
        <div className="oauth-login">
            {/* <AppleLogin
                scope="email"
                // clientId={process.env.REACT_APP_CLIENT_ID_APPLE}
                clientId="com.wconnect"
                state="initial"
                usePopup={true}
                render={({ onClick }) => {
                    return (
                        <button type="button" onClick={onClick}>
                            <AppleIcon />
                            <span>{t("sign_in_apple")}</span>
                        </button>
                    );
                }}
            /> */}
            <button type="button" onClick={googleLogin}>
                <GoogleIcon />
                <span>{t("sign_in_google")}</span>
            </button>
            <button type="button" onClick={facebookLogin}>
                <FacebookIcon />
                <span>{t("sign_in_facebook")}</span>
            </button>
        </div>
    );
};

export default OauthLogin;
