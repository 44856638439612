import { countryCodesTypes } from "./country-codes.types";

const initialState = {
    countryCodesList: {
        items: [],
    },
    countryCode: {},
    loading: false,
    error: "",
};

export const countryCodes = (state = initialState, { type, payload }) => {
    switch (type) {
        case countryCodesTypes.GET_COUNTRY_CODES_REQUEST: {
            return { ...state, loading: true };
        }

        case countryCodesTypes.GET_COUNTRY_CODES_SUCCESS: {
            return {
                ...state,
                loading: false,
                countryCodesList: payload,
            };
        }

        case countryCodesTypes.GET_COUNTRY_CODES_ERROR: {
            return { ...state, loading: false, error: payload };
        }

        case countryCodesTypes.SET_COUNTRY_CODE: {
            return { ...state, countryCode: payload };
        }

        default:
            return state;
    }
};
