export const favouritesActionTypes = {
    // get client favourites
    GET_CLIENT_FAVOURITES_REQUEST: "favourites/GET_CLIENT_FAVOURITES_REQUEST",
    GET_CLIENT_FAVOURITES_SUCCESS: "favourites/GET_CLIENT_FAVOURITES_SUCCESS",
    GET_CLIENT_FAVOURITES_ERROR: "favourites/GET_CLIENT_FAVOURITES_ERROR",

    // get specialist favourites
    GET_SPECIALIST_FAVOURITES_REQUEST:
        "favourites/GET_SPECIALIST_FAVOURITES_REQUEST",
    GET_SPECIALIST_FAVOURITES_SUCCESS:
        "favourites/GET_SPECIALIST_FAVOURITES_SUCCESS",
    GET_SPECIALIST_FAVOURITES_ERROR:
        "favourites/GET_SPECIALIST_FAVOURITES_ERROR",

    // set
    CLEAR_ALL_PARAMS: "favourites/CLEAR_ALL_PARAMS",
    SET_PAGE: "favourites/SET_PAGE",

    // add remove favourites
    ADD_REMOVE_FAVOURITES_REQUEST: "favourites/ADD_REMOVE_FAVOURITES_REQUEST",
    ADD_REMOVE_FAVOURITES_SUCCESS: "favourites/ADD_REMOVE_FAVOURITES_SUCCESS",
    ADD_REMOVE_FAVOURITES_ERROR: "favourites/ADD_REMOVE_FAVOURITES_ERROR",
};
