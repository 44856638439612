import { languageList } from "../../../../helpers/language";

import mobilePreviewEn from "../../../../assets/images/background-images/mobile_preview_en.png";
import mobilePreviewRu from "../../../../assets/images/background-images/mobile_preview_ru.png";
import mobilePreviewTr from "../../../../assets/images/background-images/mobile_preview_tr.png";

export const multilanguageComfortConfig = {
    [languageList.en.value]: mobilePreviewEn,
    [languageList.ru.value]: mobilePreviewRu,
    [languageList.tr.value]: mobilePreviewTr,
};
