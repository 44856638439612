export const ORDER_FORM_SELECT_FIELDS = [
    {
        fieldName: "categoryId",
        defaultValueKey: "category",
    },
    {
        fieldName: "subcategoryId",
        defaultValueKey: "subcategory",
    },
    {
        fieldName: "clarificationId",
        defaultValueKey: "clarification",
    },
];

export const ORDER_FORM_OTHER_FIELDS = [
    {
        fieldName: "completionDeadline",
        defaultValueKey: "completionDeadline",
    },
    {
        fieldName: "district",
        defaultValueKey: "district",
    },
    {
        fieldName: "address",
        defaultValueKey: "address",
    },
    {
        fieldName: "city",
        defaultValueKey: "city",
    },
    {
        fieldName: "priceFrom",
        defaultValueKey: "priceFrom",
    },
    {
        fieldName: "priceTo",
        defaultValueKey: "priceTo",
    },
    {
        fieldName: "comment",
        defaultValueKey: "comment",
    },
    {
        fieldName: "preferredYears",
        defaultValueKey: "preferredYears",
    },
];
