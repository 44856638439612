import * as supportRequests from "../../services/requests/support";
import * as supportActions from "./support.actions";

import { successToastify } from "../../components/toastify/toastify.operations";
import {
    changeButtonLoader,
    changeGlobalLoader,
} from "../../components/loader/loader.actions";
import { closeModal } from "../../components/modal/modal.actions";
import { sizeItemOnPage } from "./support.constants";
import { supportSelector, supportTextSelector } from "./support.selectors";

export const createSupportQuestion =
    (question = {}) =>
    (dispatch, getState) => {
        dispatch(supportActions.postQuestionPending());
        dispatch(changeGlobalLoader(true));
        const {
            supportList: { items, count },
        } = supportSelector(getState());

        return supportRequests
            .postQuestion(question)
            .then(({ question, chat }) => {
                const supportTicket = {
                    ...question,
                    chatId: { _id: chat?._id },
                };
                const newItems = [supportTicket, ...items];
                dispatch(
                    supportActions.postQuestionFulfilled({
                        items: newItems,
                        count: count + 1,
                    })
                );
                dispatch(successToastify("toastify.support.created"));
            })
            .catch((error) =>
                dispatch(supportActions.postQuestionRejected(error))
            )
            .finally(() => {
                dispatch(closeModal());
                dispatch(changeGlobalLoader(false));
            });
    };
export const getAllSupportQuestions =
    (params = "") =>
    (dispatch) => {
        dispatch(supportActions.getAllQuestionsPending());
        dispatch(changeGlobalLoader(true));

        return supportRequests
            .getQuestions(`?limit=${sizeItemOnPage}` + params)
            .then(({ items, totalCount }) => {
                dispatch(
                    supportActions.getAllQuestionsFulfilled({
                        items: items,
                        count: totalCount,
                    })
                );
            })
            .catch((error) =>
                dispatch(supportActions.getAllQuestionsRejected(error))
            )
            .finally(() => {
                dispatch(closeModal());
                dispatch(changeGlobalLoader(false));
            });
    };

export const deleteSupportQuestion = (id) => (dispatch, getState) => {
    dispatch(changeButtonLoader({ status: true, button: "" }));
    dispatch(supportActions.deleteQuestionPending());

    const {
        supportList: { items, count },
    } = supportSelector(getState());

    return supportRequests
        .deleteQuestion(id)
        .then(() => {
            const newItems = items.map((question) =>
                question._id === id ? { ...question, active: false } : question
            );
            const newSupportList = { items: newItems, count: count };

            dispatch(supportActions.deleteQuestionFulfilled(newSupportList));
            dispatch(closeModal());
            dispatch(successToastify("toastify.support.deleted"));
        })
        .catch(({ error }) => {
            dispatch(supportActions.deleteQuestionRejected(error));
            dispatch(closeModal());
        })
        .finally(() =>
            dispatch(changeButtonLoader({ status: false, button: "" }))
        );
};

//set sort

export const editPageSupportQuestions = (page) => (dispatch, getState) => {
    const text = supportTextSelector(getState());
    dispatch(supportActions.setPage(`&page=${page}`));

    const url = text ? text + `&page=${page}` : `&page=${page}`;

    dispatch(getAllSupportQuestions(url));
};

export const editSort =
    (type = "") =>
    (dispatch) => {
        dispatch(supportActions.setPage(`&page=0`));
        dispatch(supportActions.setText(type));
        dispatch(getAllSupportQuestions(`${type}`));
    };
