import { memo, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { allResultSelector } from "./all-result.selectors";
import {
    getClarifications,
    getSubservices,
    getServices,
} from "../../pages/all-services/all-services.operations";
import { editPage } from "./all-result.operations";

import { errorHandlerTypes } from "../error-handler/error-handler.constants";
import { resultTypes, sizeItemOnPage } from "./all-result.constant";

import Sort from "./components/sort/sort";
import Filter from "./components/filter/filter";
import Search from "./components/search/search";
import Pagination from "../pagination/pagination";

import ErrorHandler from "../error-handler/error-handler";
import SearchedOrder from "./components/searched-order/searched-order";
import SearchedHeader from "./components/searched-header/searched-header";
import SearchedSpecialist from "./components/searched-specialist/searched-specialist";

import { Icon } from "../image/image";

import "./index.scss";

const AllServicesResult = ({ data, type, isFavorite, sortConfig }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const bodyRef = useRef(null);

    const [sidebarMaxHeight, setSidebarMaxHeight] = useState("100%");
    const [isFiltersVisible, setIsFiltersVisible] = useState(false);
    const [transitionsCount, setTransitionsCount] = useState(0);
    const { page } = useSelector(allResultSelector);
    const { items = [], count, topItems = [] } = data;

    useEffect(() => {
        dispatch(getClarifications());
        dispatch(getSubservices());
        dispatch(getServices());
    }, []);

    useEffect(() => {
        if (bodyRef && window.innerWidth <= 900) {
            setSidebarMaxHeight(bodyRef.current.clientHeight + "px");
        } else setSidebarMaxHeight("100%");
    }, [isFiltersVisible, transitionsCount, items]);

    return (
        <div className="all_result">
            <div className="all_result__header">
                <Sort sortConfig={sortConfig} type={type} />
                <Search type={type} />
            </div>
            <div className="all_result__inner">
                {!isFavorite && (
                    <div
                        className={
                            "all_result__filters-container" +
                            " " +
                            (isFiltersVisible
                                ? "all_result__filters-container_visible"
                                : "")
                        }
                    >
                        <div
                            className={
                                "filters-sidebar__visibility-button-container" +
                                " " +
                                (isFiltersVisible
                                    ? "filters-sidebar__visibility-button-container_visible"
                                    : "")
                            }
                            onTransitionEnd={() =>
                                setTransitionsCount((p) => (p += 1))
                            }
                        >
                            <button
                                className="filters-sidebar__visibility-button"
                                onClick={() => setIsFiltersVisible((p) => !p)}
                            >
                                {isFiltersVisible ? (
                                    t("all_service_filters_sidebar_visible")
                                ) : (
                                    <>
                                        <Icon type="filtersIcon" />{" "}
                                        {t(
                                            "all_service_filters_sidebar_hidden"
                                        )}
                                    </>
                                )}
                            </button>
                        </div>
                        <div
                            className="filters-sidebar__filters-container"
                            style={{ maxHeight: sidebarMaxHeight }}
                        >
                            <Filter type={type} />
                        </div>
                    </div>
                )}
                <div
                    ref={bodyRef}
                    className={
                        "all_result__body" +
                        " " +
                        (isFiltersVisible
                            ? "all_result__body_with-visible-sidebar-filters"
                            : "")
                    }
                >
                    <article className="all_result__searched">
                        <div className="all_result__searched_inner">
                            <div className="all_result__searched_header">
                                <SearchedHeader
                                    countByText={data?.countByText}
                                    isFavorite={isFavorite}
                                    count={
                                        !isFavorite && topItems.length > 0
                                            ? topItems.length
                                            : count
                                    }
                                    type={type}
                                    isTop={
                                        count > 0 &&
                                        !isFavorite &&
                                        topItems.length > 0
                                            ? "top"
                                            : "default"
                                    }
                                />
                                {count > 0 ? (
                                    <div className="all_result__searched_people">
                                        {type === resultTypes.ORDERS
                                            ? items.map((order) => (
                                                  <SearchedOrder
                                                      key={order._id}
                                                      {...order}
                                                  />
                                              ))
                                            : topItems.length > 0 && !isFavorite
                                            ? topItems.map((service) => (
                                                  <SearchedSpecialist
                                                      key={service._id}
                                                      {...service}
                                                  />
                                              ))
                                            : items.map((service) => (
                                                  <SearchedSpecialist
                                                      key={service._id}
                                                      {...service}
                                                  />
                                              ))}
                                    </div>
                                ) : (
                                    <ErrorHandler
                                        type={
                                            errorHandlerTypes.NOT_FOUND_SEARCH
                                        }
                                    />
                                )}
                            </div>
                        </div>
                    </article>
                    {topItems.length > 0 && items.length > 0 && !isFavorite ? (
                        <div className="all_result__searched-default">
                            <div className="all_result__searched-default-header">
                                <span className="all_result__searched-default-header_title">
                                    {t("all_service_title")}
                                </span>
                                <span className="all_result__searched-default-header_count">
                                    {type === resultTypes.ORDERS
                                        ? t("orders_count")
                                        : t("all_service_specialist")}{" "}
                                    {count}
                                </span>
                            </div>
                            <div className="all_result__searched-default-content">
                                {items.map((service) => (
                                    <SearchedSpecialist
                                        key={service._id}
                                        {...service}
                                    />
                                ))}
                            </div>
                        </div>
                    ) : null}
                    {count > 4 && (
                        <div className="all_result__pagination-container">
                            <Pagination
                                getNextPageItems={(e) => {
                                    dispatch(editPage(sizeItemOnPage, e, type));
                                }}
                                sizeItemOnPage={sizeItemOnPage}
                                isStartedPage={page}
                                elementType="page"
                                items={data}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

AllServicesResult.propTypes = {
    data: PropTypes.object,
    type: PropTypes.string,
    countByText: PropTypes.number,
    isFavorite: PropTypes.bool,
    sortConfig: PropTypes.array,
};

export default memo(AllServicesResult);
