import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { Fragment } from "react";

import { closeModal } from "../../../../../components/modal/modal.actions";
import { forgotPasswordSelector } from "../../../auth.selectors";

import ReactHelmet from "../../../../../components/react-helmet/react-helmet";

import emailIcon from "../../../../../assets/images/auth/forgot_password_email.svg";
import "./index.scss";

const ForgotPasswordPending = () => {
    const { email } = useSelector(forgotPasswordSelector);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleGotIt = () => dispatch(closeModal());

    return (
        <Fragment>
            <ReactHelmet title="forgot_password_pending_title" />
            <div className="forgot-password__pending">
                <img
                    className="forgot-password__pending-img"
                    src={emailIcon}
                    alt="letter sent"
                />
                <span className="forgot-password__pending-title">
                    {t("forgot_password_pending_title")}
                </span>
                <div className="forgot-password__pending-subtitle">
                    {t("forgot_password_pending_subtitle_first")}{" "}
                    <span className="forgot-password__pending-subtitle-email">
                        {email}
                    </span>
                    {t("forgot_password_pending_subtitle_second")}
                </div>
                <button
                    onClick={handleGotIt}
                    className="forgot-password__pending-btn"
                >
                    {t("btn_got_it")}
                </button>
            </div>
        </Fragment>
    );
};

export default ForgotPasswordPending;
