import { paymentTypes } from "../balance/balance.constants";
import { myAccountTypes } from "./my-account.types";

const initialState = {
    loading: false,
    error: "",
    avatar: null,
    avatars: {},
    data: {
        socialLinks: { fb: "", inst: "" },
        unreadNotifications: 0,
        phoneLength: 0,
        firstName: "",
        lastName: "",
        phone: {},
        city: "",
        role: "",
        verifications: {},
        onboarding: {
            [paymentTypes.stripe.value]: false,
            [paymentTypes.iyzico.value]: false,
        },
    },
};

export const myAccount = (state = initialState, { type, payload }) => {
    switch (type) {
        // loading
        case myAccountTypes.DELETE_USER_AVATAR_PENDING:
        case myAccountTypes.PUT_USER_AVATAR_PENDING:
        case myAccountTypes.GET_AVATARS_PENDING:
        case myAccountTypes.GET_USER_PENDING:
        case myAccountTypes.PUT_USER_PENDING:
        case myAccountTypes.PHONE_VERIFY_PENDING:
        case myAccountTypes.SEND_OAUTH_CONNECT_PENDING: {
            return { ...state, loading: true };
        }

        // data
        case myAccountTypes.GET_USER_FULFILLED:
        case myAccountTypes.PUT_USER_FULFILLED: {
            return { ...state, loading: false, data: payload };
        }

        // avatar
        case myAccountTypes.DELETE_USER_AVATAR_FULFILLED:
        case myAccountTypes.PUT_USER_AVATAR_FULFILLED: {
            return { ...state, loading: false, avatar: payload };
        }

        // error
        case myAccountTypes.DELETE_USER_AVATAR_REJECTED:
        case myAccountTypes.PUT_USER_AVATAR_REJECTED:
        case myAccountTypes.GET_USER_REJECTED:
        case myAccountTypes.GET_AVATARS_REJECTED:
        case myAccountTypes.PUT_USER_REJECTED:
        case myAccountTypes.PHONE_VERIFY_REJECTED:
        case myAccountTypes.SEND_OAUTH_CONNECT_REJECTED: {
            return { ...state, loading: false, error: payload };
        }

        // clear account data
        case myAccountTypes.CLEAR_ACCOUNT_DATA: {
            return initialState;
        }

        // avatars
        case myAccountTypes.GET_AVATARS_FULFILLED: {
            return { ...state, loading: false, avatars: payload };
        }

        // phone verify
        case myAccountTypes.PHONE_VERIFY_FULFILLED: {
            return {
                ...state,
                loading: false,
                data: {
                    ...state.data,
                    verifications: {
                        ...state.data.verifications,
                        phone: {
                            ...state.data.verifications.phone,
                            confirmed: payload.confirmed,
                            value: payload.value,
                        },
                    },
                },
            };
        }

        // facebook connect
        case myAccountTypes.SEND_OAUTH_CONNECT_FULFILLED: {
            return {
                ...state,
                loading: false,
                data: {
                    ...state.data,
                    verifications: {
                        ...state.data.verifications,
                        facebook: {
                            ...state.data.verifications.facebook,
                            confirmed: true,
                            // value: payload.value,
                        },
                    },
                },
            };
        }

        //set
        case myAccountTypes.SET_AVATAR: {
            return { ...state, loading: false, avatar: payload };
        }
        //reset
        case myAccountTypes.RESET_AVATAR: {
            return { ...state, loading: false, avatar: null };
        }

        default:
            return state;
    }
};
