import { errorHandlerTypes } from "./error-handler.types";

const initialState = { navigate: {} };

export const errorHandler = (state = initialState, { type, payload }) => {
    switch (type) {
        case errorHandlerTypes.SET_NAVIGATE:
            return { ...state, navigate: payload.navigate };

        default:
            return state;
    }
};

export default errorHandler;
