import { forgotPasswordSteps } from "./auth.constants";
import { authTypes } from "./auth.types";

const initialState = {
    isEmailConfirmed: false,
    isEmailVerified: false,
    refresh_token: "",
    access_token: "",
    loading: false,
    access: false,
    error: "",
    city: "",
    district: {},
    role: "",
    oauthToken: "",
    registeredEmail: "",
    registeredPhone: "",

    user: {
        confirmPassword: "",
        createPassword: "",
        conditions: false,
        firstName: "",
        birthday: "",
        lastName: "",
        avatar: null,
        gender: "",
        email: "",
        phone: {},
    },

    forgotPassword: { step: forgotPasswordSteps.request, email: "" },
    restoreAccount: { email: "", phone: "", facebook: "" },
};

export const auth = (state = initialState, { type, payload }) => {
    switch (type) {
        // common
        case authTypes.SET_ROLE: {
            return { ...state, role: payload };
        }
        case authTypes.SET_EMAIL: {
            return {
                ...state,
                emailStatus: payload.emailStatus,
                emailVerification: payload.email,
            };
        }
        case authTypes.SET_EMAIL_ERROR: {
            return { ...state, emailStatus: "error" };
        }
        case authTypes.SET_CITY: {
            return { ...state, city: payload, district: "" };
        }
        case authTypes.SET_DISTRICT: {
            return { ...state, district: payload };
        }
        case authTypes.SET_USER_DATA: {
            return { ...state, user: payload };
        }
        case authTypes.SET_AVATAR_USER: {
            return {
                ...state,
                user: { ...state.user, avatar: payload },
            };
        }
        case authTypes.SET_CONDITIONS_USER: {
            return {
                ...state,
                user: { ...state.user, conditions: payload },
            };
        }
        case authTypes.SET_OAUTH_TOKEN: {
            return {
                ...state,
                oauthToken: payload,
            };
        }
        case authTypes.SET_REGISTERED_EMAIL: {
            return {
                ...state,
                registeredEmail: payload,
            };
        }
        case authTypes.SET_REGISTERED_PHONE: {
            return {
                ...state,
                registeredPhone: payload,
            };
        }

        // restore account
        case authTypes.SET_RESTORE_ACCOUNT_EMAIL: {
            return {
                ...state,
                restoreAccount: { ...state.restoreAccount, email: payload },
            };
        }
        case authTypes.SET_RESTORE_ACCOUNT_PHONE: {
            return {
                ...state,
                restoreAccount: { ...state.restoreAccount, phone: payload },
            };
        }
        case authTypes.SET_FACEBOOK_TOKEN: {
            return {
                ...state,
                restoreAccount: { ...state.restoreAccount, facebook: payload },
            };
        }

        // forgot password
        case authTypes.SET_FORGOT_PASSWORD_STEP: {
            return {
                ...state,
                forgotPassword: { ...state.forgotPassword, step: payload },
            };
        }
        case authTypes.FORGOT_PASSWORD_SUCCESS: {
            return {
                ...state,
                forgotPassword: {
                    ...state.forgotPassword,
                    email: payload.email,
                    token: payload.token,
                },
            };
        }
        case authTypes.RESET_PASSWORD_SUCCESS: {
            return {
                ...state,
                ...payload,
                loading: false,
                error: "",
                forgotPassword: initialState.forgotPassword,
            };
        }

        // email verify
        case authTypes.SEND_EMAIL_VERIFY_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: "",
                isEmailVerified: false,
                user: { ...state.user, email: payload },
            };
        }

        case authTypes.SEND_PHONE_VERIFY_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: "",
                user: { ...state.user, phone: payload },
            };
        }

        // tokens
        case authTypes.UPDATE_TOKENS: {
            return { ...state, ...payload };
        }

        // loading
        case authTypes.SEND_EMAIL_CONFIRMATION_REQUEST:
        case authTypes.SEND_REGISTRATION_FORM_REQUEST:
        case authTypes.SEND_EMAIL_VERIFY_REQUEST:
        case authTypes.RESTORE_ACCOUNT_REQUEST:
        case authTypes.SEND_LOGIN_FORM_REQUEST:
        case authTypes.SEND_OAUTH_REQUEST:
        case authTypes.SEND_OAUTH_REGISTRATION_REQUEST:
        case authTypes.FORGOT_PASSWORD_REQUEST:
        case authTypes.RESET_PASSWORD_REQUEST:
        case authTypes.RESTORE_EMAIL_REQUEST: {
            return { ...state, loading: true };
        }

        // data
        case authTypes.SEND_REGISTRATION_FORM_SUCCESS:
        case authTypes.SEND_LOGIN_FORM_SUCCESS:
        case authTypes.SEND_OAUTH_SUCCESS:
        case authTypes.SEND_OAUTH_REGISTRATION_SUCCESS: {
            return { ...state, ...payload, loading: false, error: "" };
        }
        case authTypes.SEND_EMAIL_CONFIRMATION_SUCCESS: {
            return {
                ...state,
                isEmailConfirmed: true,
                loading: false,
                error: "",
                ...payload,
            };
        }

        // error
        case authTypes.SEND_REGISTRATION_FORM_ERROR:
        case authTypes.SEND_EMAIL_VERIFY_ERROR:
        case authTypes.RESTORE_ACCOUNT_ERROR:
        case authTypes.SEND_LOGIN_FORM_ERROR:
        case authTypes.SEND_OAUTH_ERROR:
        case authTypes.SEND_OAUTH_REGISTRATION_ERROR:
        case authTypes.FORGOT_PASSWORD_ERROR:
        case authTypes.RESET_PASSWORD_ERROR:
        case authTypes.RESTORE_EMAIL_ERROR: {
            return { ...state, loading: false, error: payload };
        }
        case authTypes.SEND_EMAIL_CONFIRMATION_ERROR: {
            return {
                ...state,
                isEmailVerified: false,
                loading: false,
                error: payload,
            };
        }

        // logout
        case authTypes.LOGOUT_USER: {
            return initialState;
        }

        default:
            return state;
    }
};
