import { myAccountTypes } from "./my-account.types";

// update user
export const putUserPending = () => ({
    type: myAccountTypes.PUT_USER_PENDING,
});

export const putUserFulfilled = (data) => ({
    type: myAccountTypes.PUT_USER_FULFILLED,
    payload: data,
});

export const putUserRejected = (error) => ({
    type: myAccountTypes.PUT_USER_REJECTED,
    payload: error,
});

// update avatar
export const putUserAvatarPending = () => ({
    type: myAccountTypes.PUT_USER_AVATAR_PENDING,
});

export const putUserAvatarFulfilled = (data) => ({
    type: myAccountTypes.PUT_USER_AVATAR_FULFILLED,
    payload: data,
});

export const putUserAvatarRejected = (error) => ({
    type: myAccountTypes.PUT_USER_AVATAR_REJECTED,
    payload: error,
});

// delete avatar
export const deleteUserAvatarPending = () => ({
    type: myAccountTypes.DELETE_USER_AVATAR_PENDING,
});

export const deleteUserAvatarFulfilled = (data) => ({
    type: myAccountTypes.DELETE_USER_AVATAR_FULFILLED,
    payload: data,
});

export const deleteUserAvatarRejected = (error) => ({
    type: myAccountTypes.DELETE_USER_AVATAR_REJECTED,
    payload: error,
});

// get user
export const getUserPending = () => ({
    type: myAccountTypes.GET_USER_PENDING,
});

export const getUserFulfilled = (data) => ({
    type: myAccountTypes.GET_USER_FULFILLED,
    payload: data,
});

export const getUserRejected = (error) => ({
    type: myAccountTypes.GET_USER_REJECTED,
    payload: error,
});

// get avatars
export const getAvatarsPending = () => ({
    type: myAccountTypes.GET_AVATARS_PENDING,
});

export const getAvatarsFulfilled = (data) => ({
    type: myAccountTypes.GET_AVATARS_FULFILLED,
    payload: data,
});

export const getAvatarsRejected = (error) => ({
    type: myAccountTypes.GET_AVATARS_REJECTED,
    payload: error,
});

// delete user
export const deleteUserPending = () => ({
    type: myAccountTypes.DELETE_USER_PENDING,
});

export const deleteUserFulfilled = () => ({
    type: myAccountTypes.DELETE_USER_FULFILLED,
});

export const deleteUserRejected = (error) => ({
    type: myAccountTypes.DELETE_USER_REJECTED,
    payload: error,
});

// clear account data
export const clearAccountData = () => ({
    type: myAccountTypes.CLEAR_ACCOUNT_DATA,
});

// set
export const setAvatar = (data) => ({
    type: myAccountTypes.SET_AVATAR,
    payload: data,
});

// set
export const resetAvatar = () => ({
    type: myAccountTypes.RESET_AVATAR,
});

// phone verifications
export const getPhoneVerificationCodePending = () => ({
    type: myAccountTypes.GET_PHONE_VERIFICATION_CODE_PENDING,
});

export const getPhoneVerificationCodeFulfilled = (data) => ({
    type: myAccountTypes.GET_PHONE_VERIFICATION_CODE_FULFILLED,
    payload: data,
});

export const getPhoneVerificationCodeRejected = (error) => ({
    type: myAccountTypes.GET_PHONE_VERIFICATION_CODE_REJECTED,
    payload: error,
});

export const phoneVerifyPending = () => ({
    type: myAccountTypes.PHONE_VERIFY_PENDING,
});

export const phoneVerifyFulfilled = (data) => ({
    type: myAccountTypes.PHONE_VERIFY_FULFILLED,
    payload: data,
});

export const phoneVerifyRejected = (error) => ({
    type: myAccountTypes.PHONE_VERIFY_REJECTED,
    payload: error,
});

// email verifications
export const getEmailVerificationCodePending = () => ({
    type: myAccountTypes.GET_EMAIL_VERIFICATION_CODE_PENDING,
});

export const getEmailVerificationCodeFulfilled = (data) => ({
    type: myAccountTypes.GET_EMAIL_VERIFICATION_CODE_FULFILLED,
    payload: data,
});

export const getEmailVerificationCodeRejected = (error) => ({
    type: myAccountTypes.GET_EMAIL_VERIFICATION_CODE_REJECTED,
    payload: error,
});

export const emailVerifyPending = () => ({
    type: myAccountTypes.EMAIL_VERIFY_PENDING,
});

export const emailVerifyFulfilled = (data) => ({
    type: myAccountTypes.EMAIL_VERIFY_FULFILLED,
    payload: data,
});

export const emailVerifyRejected = (error) => ({
    type: myAccountTypes.EMAIL_VERIFY_REJECTED,
    payload: error,
});

// oauth connect
export const sendOauthConnectPending = () => ({
    type: myAccountTypes.SEND_OAUTH_CONNECT_PENDING,
});

export const sendOauthConnectFulfilled = (payload) => ({
    type: myAccountTypes.SEND_OAUTH_CONNECT_FULFILLED,
    payload,
});

export const sendOauthConnectRejected = (payload) => ({
    type: myAccountTypes.SEND_OAUTH_CONNECT_REJECTED,
    payload,
});
