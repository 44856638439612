import React, { Fragment } from "react";

import ReactHelmet from "../../../components/react-helmet/react-helmet";
import LoginForm from "./components/login-form/login-form";

const Login = () => (
    <Fragment>
        <ReactHelmet title="sing_in" />
        <LoginForm />
    </Fragment>
);

export default Login;
