import { string, object, number, date, ref } from "yup";
import { t } from "i18next";

export const updateOrderSchema = object().shape({
    city: string().required("validation_required"),
    district: string()
        .required("validation_required")
        .min(3, ({ min }) => t("validation_min", { min }))
        .max(50, ({ max }) => t("validation_max", { max })),
    categoryId: object().required("validation_required"),
    subcategoryId: object().required("validation_required"),
    clarificationId: object().required("validation_required"),
    comment: string()
        .required("validation_required")
        .min(10, ({ min }) => t("validation_min", { min }))
        .max(500, ({ max }) => t("validation_max", { max })),
    preferredGender: object(),
    preferredYears: object({
        from: number("validation_positive_number")
            .nullable(true)
            .transform((curr, orig) => (orig === "" ? null : curr))
            .typeError("validation_positive_number")
            .positive("validation_positive_number")
            .min(18, ({ min }) => t("validation_min", { min }))
            .max(150, ({ max }) => t("validation_max", { max })),
        to: number("validation_positive_number")
            .nullable(true)
            .transform((curr, orig) => (orig === "" ? null : curr))
            .typeError("validation_positive_number")
            .positive("validation_positive_number")
            .min(ref("from"), ({ min }) => t("validation_min", { min }))
            .max(150, ({ max }) => t("validation_max", { max })),
    }),
    priceFrom: number("validation_positive_number")
        .typeError("validation_positive_number")
        .positive("validation_positive_number")
        .required("validation_required"),
    priceTo: number("validation_positive_number")
        .typeError("validation_positive_number")
        .positive("validation_positive_number")
        .moreThan(ref("priceFrom"), ({ more: min }) =>
            t("validation_min", { min: min + 1 })
        )
        .required("validation_required"),
    completionDeadline: date()
        .min(new Date(), "validation_min_date_today")
        .required("validation_required"),
});

export const updateOrderSchemaFixedType = object().shape({
    city: string().required("validation_required"),
    district: string()
        .required("validation_required")
        .min(3, ({ min }) => t("validation_min", { min }))
        .max(50, ({ max }) => t("validation_max", { max })),
    address: string()
        .required("validation_required")
        .min(3, ({ min }) => t("validation_min", { min }))
        .max(50, ({ max }) => t("validation_max", { max })),
    categoryId: object().required("validation_required"),
    subcategoryId: object().required("validation_required"),
    clarificationId: object().required("validation_required"),
    comment: string()
        .required("validation_required")
        .min(10, ({ min }) => t("validation_min", { min }))
        .max(500, ({ max }) => t("validation_max", { max })),
    preferredGender: object(),
    preferredYears: object({
        from: number("validation_positive_number")
            .nullable(true)
            .transform((curr, orig) => (orig === "" ? null : curr))
            .typeError("validation_positive_number")
            .positive("validation_positive_number")
            .min(18, ({ min }) => t("validation_min", { min }))
            .max(150, ({ max }) => t("validation_max", { max })),
        to: number("validation_positive_number")
            .nullable(true)
            .transform((curr, orig) => (orig === "" ? null : curr))
            .typeError("validation_positive_number")
            .positive("validation_positive_number")
            .min(ref("from"), ({ min }) => t("validation_min", { min }))
            .max(150, ({ max }) => t("validation_max", { max })),
    }),

    fixedPrice: number("validation_positive_number")
        .typeError("validation_number")
        .positive("validation_positive_number")
        .required("validation_required"),

    completionDeadline: date()
        .min(new Date(), "validation_min_date_today")
        .required("validation_required"),
});
