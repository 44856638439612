import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

import { serviceSelector } from "../../../all-services/all-services.selectors";
import { clearAllServices } from "../../../all-services/all-services.actions";
import { roleSelector } from "../../../my-account/my-account.selectors";
import ButtonLoader from "../../../../components/loader/components/button-loader/button-loader";
import {
    getServices,
    getSubservices,
} from "../../../all-services/all-services.operations";

import CategoryItem from "./category-item/category-item";
import routerBook from "../../../../router/router-book";

import arrowLeft from "../../../../assets/images/arrows-icons/arrow_right.svg";
import "./index.scss";

const Category = () => {
    const { services, loading } = useSelector(serviceSelector);
    const { role } = useSelector(roleSelector);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const countOfCategoryItems = 6;

    useEffect(() => {
        dispatch(getSubservices("", "item"));
        dispatch(getServices("item"));

        return () => dispatch(clearAllServices());
    }, [role]);

    return (
        <section className="category">
            <div className={`category__container${countOfCategoryItems}`}>
                <div className="category__items">
                    {loading
                        ? Array(countOfCategoryItems)
                              .fill(0)
                              .map((_, index) => (
                                  <div
                                      className="category_item__loading"
                                      key={index}
                                  >
                                      <ButtonLoader size={34} />
                                  </div>
                              ))
                        : services
                              .slice(0, countOfCategoryItems)
                              .map((service) => (
                                  <CategoryItem
                                      key={service._id}
                                      {...service}
                                  />
                              ))}
                </div>
                <button
                    onClick={() =>
                        navigate(routerBook.allServices, { state: true })
                    }
                    className="category__btn"
                >
                    {t("profile_category_all")}
                    <img
                        className="category__btn_img"
                        src={arrowLeft}
                        alt="Arrow Right"
                    />
                </button>
            </div>
        </section>
    );
};

export default Category;
