import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Fragment, useEffect } from "react";

import { specialistServicesSelector } from "./specialist-services.selectors";
import { getSpecialistServices } from "./specialist-services.operations";
import { modalConstants } from "../../components/modal/modal.constants";
import { openModal } from "../../components/modal/modal.actions";

import { backButtonConfig } from "../../components/back-button/back-button.config";
import ReactHelmet from "../../components/react-helmet/react-helmet";
import ServiceEmptyResult from "./components/service-empty-result/service-empty-result";
import BackButton from "../../components/back-button/back-button";
import MainButton from "../../components/main-button/main-button";
import ServiceItem from "./components/service-item";

import "./index.scss";

const SpecialistServices = () => {
    const { list } = useSelector(specialistServicesSelector);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        dispatch(getSpecialistServices());
    }, []);

    return (
        <Fragment>
            <ReactHelmet title="specialist-services.title" />
            <div className="specialist-services">
                <div className="specialist-services__header">
                    <BackButton
                        onClickCallback={() =>
                            navigate(backButtonConfig[location.state].href, {
                                state: true,
                            })
                        }
                        title={t(backButtonConfig[location.state].title)}
                    />
                    <h1 className="specialist-services__header__title">
                        {t("specialist-services.title")}
                    </h1>
                </div>
                <p className="specialist-services__header__subtitle">
                    {t("specialist-services.subtitle")}
                </p>
                {list.items.map((serviceInfo) => (
                    <ServiceItem
                        serviceInfo={serviceInfo}
                        key={serviceInfo._id}
                    />
                ))}
                <div className="specialist-services__add-new">
                    <MainButton
                        buttonStyle="btn--white"
                        buttonSize="btn--large"
                        onClick={() =>
                            dispatch(
                                openModal({
                                    isOpen: true,
                                    closeIcon: true,
                                    renderType:
                                        modalConstants.specialistServiceForm,
                                })
                            )
                        }
                    >
                        {t("specialist-services.add-new")}
                        <span className="specialist-services__add-new-symbol">
                            +
                        </span>
                    </MainButton>
                </div>
                {list.items.length === 0 && <ServiceEmptyResult />}
            </div>
        </Fragment>
    );
};

export default SpecialistServices;
