import React from "react";

import AuthMain from "../../../../../../pages/auth/main/components/main-distributor/main-distributor";

import "./index.scss";

const Auth = () => {
    return (
        <div className="select-order-auth">
            <AuthMain />;
        </div>
    );
};

export default Auth;
