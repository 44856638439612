import PropTypes from "prop-types";
import React from "react";

import { lazyImageConfig } from "./lazy-image.config";
import useLazyImage from "./use-lazy-image";

const LazyImage = ({ options = {}, type = "", image = "" }) => {
    const src = useLazyImage(lazyImageConfig[type], image);

    return <img src={src} {...options} />;
};

LazyImage.propTypes = {
    options: PropTypes.object,
    image: PropTypes.string,
    type: PropTypes.string,
};

export default LazyImage;
