import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Fragment, useEffect } from "react";
import { useParams } from "react-router";

import { getServices } from "../all-services/all-services.operations";
import { editStatusAll } from "./orders.operations";
import { ordersSelector } from "./orders.selectors";
import { clearAllParams } from "./oders.actions";

import OrdersSidebar from "./components/orders-sidebar/orders-sidebar";
import ReactHelmet from "../../components/react-helmet/react-helmet";
import { ORDERS_FILTERS, sizeItemOnPage } from "./orders.constant";
import MainButton from "../../components/main-button/main-button";
import OrdersList from "./components/orders-list/orders-list";
import routerBook from "../../router/router-book";

import "./index.scss";

const Orders = () => {
    const { orders } = useSelector(ordersSelector);
    const { filterParameter } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!filterParameter) {
            navigate(`${routerBook.orders}/${ORDERS_FILTERS.NEW}`, {
                state: true,
            });
        } else {
            dispatch(
                editStatusAll(
                    true,
                    sizeItemOnPage,
                    filterParameter.toUpperCase()
                )
            );
            dispatch(getServices());
        }

        return () => dispatch(clearAllParams());
    }, []);

    return (
        <Fragment>
            <ReactHelmet title="orders.title" />
            <div className="orders">
                <div className="orders__header">
                    <h1 className="orders__header__title">
                        {t("orders.title")}
                    </h1>
                    <div className="orders__header__controls-block">
                        <MainButton
                            buttonStyle="btn--light"
                            buttonSize="btn--large"
                            onClick={() =>
                                navigate(routerBook.chats, { state: true })
                            }
                        >
                            {t("orders.buttons.my-chats")}
                        </MainButton>
                        <MainButton
                            buttonStyle="btn--green"
                            buttonSize="btn--large"
                            onClick={() =>
                                navigate(routerBook.createOrder, {
                                    state: true,
                                })
                            }
                        >
                            {t("orders.buttons.new-order")}
                        </MainButton>
                    </div>
                </div>
                <div className="orders__content">
                    <div className="orders__content__nav-sidebar">
                        <OrdersSidebar filterParameter={filterParameter} />
                    </div>
                    <div
                        className={`orders__content__list${
                            filterParameter === ORDERS_FILTERS.CANCELLED &&
                            orders.items.length
                                ? "--disabled"
                                : ""
                        }`}
                    >
                        <OrdersList orders={orders} />
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Orders;
