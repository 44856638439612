import { useState, useRef, useEffect, memo } from "react";
import { useTranslation } from "react-i18next";
import { forwardRef } from "react";
import PropTypes from "prop-types";

import useOutsideClick from "../../hooks/use-outside-click";
import { getLanguage } from "../../helpers/language";
import SELECT_TYPES from "./location-select.constants";
import Tooltip from "../../components/tooltip/tooltip";
import { Icon } from "../../components/image/image";

import { ReactComponent as InfoIcon } from "../../assets/images/vectors/info.svg";
import "./index.scss";

const LocationSelect = memo(
    forwardRef(
        (
            {
                styleHeight = "",
                onSelect,
                label = "",
                value = "",
                error = "",
                options = [],
                placeholder = "",
                type = SELECT_TYPES.WITH_LABEL,
            },
            ref
        ) => {
            const { value: language } = getLanguage();
            const { t } = useTranslation();
            const selectRef = useRef();

            const [optionsList, setOptions] = useState(options);
            const [selected, setSelected] = useState(value);
            const [inputValue, setInputValue] = useState(value);
            const [isOpen, setOpen] = useState(false);

            const filteredOptionsByLanguage = optionsList.filter((item) => {
                const setSearchedItemLanguage = (lang) =>
                    item.nameTranslations?.[lang];

                const getTextInSearchedItem = (item) =>
                    item
                        ?.toLowerCase()
                        .includes(inputValue?.toLocaleLowerCase());

                return (
                    getTextInSearchedItem(setSearchedItemLanguage("en")) |
                    getTextInSearchedItem(setSearchedItemLanguage("ru")) |
                    getTextInSearchedItem(setSearchedItemLanguage("tr"))
                );
            });
            const proposedOptionsByLanguage = filteredOptionsByLanguage.map(
                (item) => item
            );

            const newFilteredArrayByLanguage = optionsList.filter((item) => {
                const checkTypeOption =
                    proposedOptionsByLanguage[
                        proposedOptionsByLanguage.indexOf(item)
                    ]?.name;

                return item.name !== checkTypeOption;
            });

            const filteredOptionsByLanguageList =
                filteredOptionsByLanguage.length >= 3 ||
                selected?.includes(inputValue)
                    ? [
                          ...filteredOptionsByLanguage,
                          ...newFilteredArrayByLanguage,
                      ]
                    : filteredOptionsByLanguage;

            useEffect(() => {
                setInputValue(value);
                setOptions(options);
                if (value !== selected) setSelected(value);
            }, [value, options]);

            const mapSelectLabel = (item) => {
                if (item?.label || item?.name) {
                    if (typeof item.label === "object")
                        return item.label[language];
                    else {
                        return t(item.label || item.name || item);
                    }
                }
                return t(item);
            };
            const checkSelectedItem = (item) => {
                if (type === SELECT_TYPES.WITHOUT_LABEL) {
                    if (item.name === value) return true;
                    if (selected === item) return true;
                    else return false;
                } else {
                    if (selected?.value === item?.value) return true;
                    else return false;
                }
            };

            const toggleDropdown = () => optionsList.length && setOpen(!isOpen);

            useOutsideClick(selectRef, () => setOpen(false));

            const setDropdownModifiers = () => {
                if (isOpen) {
                    return "-open";
                } else if (!optionsList.length && error) {
                    return "-error location-select__dropdown-disabled";
                } else if (value) {
                    return "-confirmed";
                } else if (error) {
                    return "-error";
                } else if (!optionsList.length) {
                    return "-disabled";
                }

                return "";
            };

            return (
                <div
                    ref={selectRef || ref}
                    className="location-select"
                    onClick={toggleDropdown}
                >
                    <div className="location-select__header">
                        {label && (
                            <label className="location-select__label">
                                {t(label)}
                            </label>
                        )}
                        <p className="location-select__error">
                            {" "}
                            {error && t(error)}
                        </p>
                    </div>
                    <div
                        className={`location-select__dropdown${setDropdownModifiers()}`}
                    >
                        <div
                            style={{ height: styleHeight }}
                            className="location-select__dropdown_header"
                        >
                            <input
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                                placeholder={t(placeholder)}
                                className="location-select__dropdown_header_input"
                            ></input>
                            <span
                                className={`location-select__dropdown_icon${
                                    isOpen && "-reverted"
                                }`}
                            >
                                <Icon type="triangleArrowDown" />
                            </span>
                        </div>
                        <div
                            className={`location-select__dropdown_body ${
                                isOpen && "open"
                            }`}
                        >
                            {filteredOptionsByLanguageList.length > 0 ? (
                                filteredOptionsByLanguageList.map(
                                    (item, index) => (
                                        <div
                                            className="location-select__dropdown_inner"
                                            key={index}
                                            onClick={() => {
                                                setInputValue(item.name);
                                                onSelect(item);
                                                toggleDropdown();
                                            }}
                                        >
                                            <div
                                                className="location-select__dropdown_item"
                                                id={item.id}
                                            >
                                                <div
                                                    className={`location-select__dropdown_circle${
                                                        checkSelectedItem(item)
                                                            ? ""
                                                            : "-disable"
                                                    }`}
                                                >
                                                    {checkSelectedItem(
                                                        item
                                                    ) && (
                                                        <div className="location-select__dropdown_circle-border"></div>
                                                    )}
                                                </div>
                                                <div className="location-select__dropdown_item__label">
                                                    {item &&
                                                        mapSelectLabel(
                                                            item?.name
                                                        )}
                                                </div>
                                            </div>
                                            {item.info && (
                                                <div className="location-select__dropdown_inner__info">
                                                    <Tooltip
                                                        content={item.info}
                                                    >
                                                        <InfoIcon />
                                                    </Tooltip>
                                                </div>
                                            )}
                                        </div>
                                    )
                                )
                            ) : (
                                <div className="location-select__dropdown_item">
                                    {t("nothing_found")}
                                </div>
                            )}
                        </div>
                        <div />
                    </div>
                </div>
            );
        }
    )
);

LocationSelect.propTypes = {
    error: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    options: PropTypes.array,
    label: PropTypes.string,
    onSelect: PropTypes.func,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    styleHeight: PropTypes.string,
};

LocationSelect.displayName = "Select";
export default LocationSelect;
