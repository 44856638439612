import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, Fragment, useState } from "react";

import { setUserData } from "../auth.operations";
import { authSelector } from "../auth.selectors";

import Input from "../../../components/input/input";
import Select from "../../../components/select/select";
import ReactHelmet from "../../../components/react-helmet/react-helmet";
import DatePicker from "../../../components/input/date-picker/date-picker";
import UploadImage from "../../my-account/components/upload-image/upload-image";
import CountryCodesDropdown from "../../../components/country-codes-dropdown/country-codes-dropdown";

import routerBook from "../../../router/router-book";
import { genders } from "../../../constants/genders";
import { adaptGender } from "../../../helpers/genders";
import { userSchema, oauthUserSchema } from "./registration.schema";
import { adult, maxAvailableToRegister } from "../../../constants/dates";

import { getAvatars } from "../../my-account/my-account.operations";
import { closeModal } from "../../../components/modal/modal.actions";
import { resetAvatar, setAvatar } from "../../my-account/my-account.actions";
import { myAccountSelector } from "../../my-account/my-account.selectors";
import { countryCodesSelector } from "../../../components/country-codes-dropdown/country-codes.selectors";

import "./index.scss";

const Registration = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { avatar } = useSelector(myAccountSelector);
    const { role, user, oauthToken, registeredEmail, registeredPhone } =
        useSelector(authSelector);
    const { countryCode } = useSelector(countryCodesSelector);

    const { gender, phone } = user;

    const [avatarImage, setAvatarImage] = useState(avatar);

    const {
        handleSubmit,
        register,
        control,
        formState: { errors },
        setValue,
    } = useForm({
        mode: "all",
        defaultValues: {
            ...user,
            gender: adaptGender(gender),
            phoneValue: phone?.number,
        },
        resolver: oauthToken
            ? yupResolver(oauthUserSchema)
            : yupResolver(userSchema),
    });

    useEffect(() => {
        dispatch(getAvatars());

        if (phone?.dialCode) {
            setValue("phoneCode", phone.dialCode);
        }
    }, []);

    const handleFormSubmit = (data) => {
        dispatch(
            setUserData({
                ...data,
                phone: {
                    dialCode: data.phoneCode,
                    number: data.phoneValue,
                    isoCode: countryCode.isoCode,
                },
            })
        );

        navigate(routerBook.auth.conditions, { state: true });
    };

    const handleSendCroppedImage = (croppedImage) => {
        const reader = new FileReader();
        reader.onloadend = () => dispatch(setAvatar(reader.result));
        reader.readAsDataURL(croppedImage);
        dispatch(closeModal());
    };

    const handleSelectCustomIcon = (avatar) => {
        dispatch(setAvatar(avatar));
        dispatch(closeModal());
    };
    const handleDeleteAvatar = () => dispatch(resetAvatar());

    return (
        <Fragment>
            <ReactHelmet title={`registration_${role.toLowerCase()}_title`} />
            <div className="registration_container">
                <div className="registration_container__content">
                    <h2 className="registration_container__title">
                        {t(`registration_${role.toLowerCase()}_title`)}
                    </h2>
                    <div className="registration_container__form">
                        <form onSubmit={handleSubmit(handleFormSubmit)}>
                            <div className="registration_container__photo">
                                <Controller
                                    control={control}
                                    name="avatar"
                                    render={({ field: { onChange } }) => (
                                        <UploadImage
                                            gender={gender}
                                            avatar={avatarImage}
                                            defaultImage={avatar}
                                            onChange={onChange}
                                            onDeleteAvatar={handleDeleteAvatar}
                                            handleSelectIcon={(avatar) =>
                                                handleSelectCustomIcon(avatar)
                                            }
                                            handleUploadImageChange={
                                                setAvatarImage
                                            }
                                            handleSendCroppedImage={
                                                handleSendCroppedImage
                                            }
                                        />
                                    )}
                                />
                            </div>
                            <Input
                                label="label_first_name"
                                type="secondary"
                                options={{
                                    placeholderText: "first_name",
                                }}
                                error={errors.firstName?.message}
                                inputChangeOptions={register("firstName")}
                            />
                            <Input
                                label="label_last_name"
                                type="secondary"
                                options={{
                                    placeholderText: "last_name",
                                }}
                                error={errors.lastName?.message}
                                inputChangeOptions={register("lastName")}
                            />

                            <div className="registration_container__date_info">
                                <label className="registration_container__date_label">
                                    {t("label_birthday")}
                                </label>
                                <div className="registration_container__date_error">
                                    {t(errors.birthday?.message)}
                                </div>
                            </div>
                            <div
                                className={`registration_container__date${
                                    errors.birthday?.message ? "-error" : ""
                                }`}
                            >
                                <Controller
                                    control={control}
                                    name="birthday"
                                    render={({
                                        field: { onChange, value, ref },
                                    }) => (
                                        <>
                                            <DatePicker
                                                dateRef={(elem) => {
                                                    elem && ref(elem.input);
                                                }}
                                                onChange={onChange}
                                                errors={errors.birthday}
                                                selected={value}
                                                maxDate={Date.now() - adult}
                                                minDate={
                                                    Date.now() -
                                                    maxAvailableToRegister
                                                }
                                                maxVisibleYears={150}
                                            />
                                        </>
                                    )}
                                />
                            </div>
                            <Controller
                                name="gender"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        label={"gender"}
                                        options={genders}
                                        error={errors.gender?.message}
                                        onSelect={(gender) =>
                                            field.onChange(gender)
                                        }
                                    />
                                )}
                            />
                            {!oauthToken && (
                                <Input
                                    label="label_email"
                                    type="secondary"
                                    readOnly={!!registeredEmail}
                                    error={errors.email?.message}
                                    options={{
                                        placeholderText: "name@example.com",
                                    }}
                                    inputChangeOptions={register("email")}
                                />
                            )}
                            {!oauthToken && (
                                <div className="registration_container__phone-form">
                                    <CountryCodesDropdown
                                        register={register}
                                        setValue={setValue}
                                        error={errors.phoneValue?.message}
                                        phoneCodeError={
                                            errors.phoneCode?.message
                                        }
                                        phoneValueName="phoneValue"
                                        phoneCodeName="phoneCode"
                                        readOnly={!!registeredPhone}
                                        withLabel
                                        fullWidth
                                    />
                                </div>
                            )}
                            <Input
                                label="label_create_password"
                                type="password"
                                options={{
                                    placeholderText: "password",
                                }}
                                error={errors.createPassword?.message}
                                inputChangeOptions={register("createPassword")}
                            />
                            <Input
                                label="label_confirm_password"
                                type="password"
                                options={{
                                    placeholderText: "password_confirm",
                                }}
                                error={errors.confirmPassword?.message}
                                inputChangeOptions={register("confirmPassword")}
                            />
                            <button className="registration_container__btn">
                                {t("next")}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Registration;
