export const promotionHistoryTitlesConfig = [
    "promotion.history.service",
    "promotion.history.activation-date",
    "promotion.history.deactivation-date",
    "promotion.history.status",
];

export const promotionHistoryStatusesHandler = (active) => {
    if (active) {
        return "promotion.history.active";
    } else return "promotion.history.disabled";
};
