import { feedbackActionTypes } from "./feedback.types";

const initialState = {
    userFeedbacks: { feedbacks: [], totalCount: 0 },
    feedbackBody: { text: "", assessment: null },

    loading: false,
    error: "",
};

export const feedback = (state = initialState, { type, payload }) => {
    switch (type) {
        // loading
        case feedbackActionTypes.CREATE_FEEDBACK_REQUEST:
        case feedbackActionTypes.GET_FEEDBACK_REQUEST:
        case feedbackActionTypes.DELETE_FEEDBACK_REQUEST:
        case feedbackActionTypes.UPDATE_FEEDBACK_REQUEST: {
            return { ...state, loading: true };
        }

        // data feedbackBody
        case feedbackActionTypes.CREATE_FEEDBACK_SUCCESS:
        case feedbackActionTypes.DELETE_FEEDBACK_SUCCESS:
        case feedbackActionTypes.UPDATE_FEEDBACK_SUCCESS: {
            return { ...state, feedbackBody: payload, loading: false };
        }

        // data userFeedbacks
        case feedbackActionTypes.GET_FEEDBACK_SUCCESS: {
            return { ...state, userFeedbacks: payload, loading: false };
        }

        // error
        case feedbackActionTypes.CREATE_FEEDBACK_ERROR:
        case feedbackActionTypes.GET_FEEDBACK_ERROR:
        case feedbackActionTypes.DELETE_FEEDBACK_ERROR:
        case feedbackActionTypes.UPDATE_FEEDBACK_ERROR: {
            return { ...state, loading: true, error: payload };
        }

        // set
        case feedbackActionTypes.CLEAR_FEEDBACK: {
            return { ...state, feedbackBody: initialState.feedbackBody };
        }
        case feedbackActionTypes.SET_ASSESSMENT: {
            return { ...state, assessment: payload };
        }
        case feedbackActionTypes.SET_FEEDBACK_TEXT: {
            return { ...state, text: payload };
        }

        default:
            return state;
    }
};
