import React from "react";

import ReactHelmet from "../../components/react-helmet/react-helmet";
import PromoVideo from "../../components/promo-video/promo-video";

import "./index.scss";

const ClientInfo = () => (
    <div className="customer-info">
        <ReactHelmet title="nav_customers" />
        <PromoVideo />
    </div>
);

export default ClientInfo;
