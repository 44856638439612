import { userRoles } from "../../../../../../constants/user-roles";
import routerBook from "../../../../../../router/router-book";

export const unsignedConfig = [
    { name: "sing_in", path: routerBook.auth.login, state: true },
];

export const signedConfig = {
    [userRoles.customer]: [
        { name: "nav_orders", path: routerBook.orders, state: true },
        {
            name: "nav_create_an_order",
            path: routerBook.createOrder,
            state: true,
        },
    ],
    [userRoles.specialist]: [
        {
            name: "my_services",
            path: routerBook.accessForSpecialist.services,
            state: true,
        },
        {
            name: "my_orders",
            path: routerBook.accessForSpecialist.orders,
            state: true,
        },
    ],
};
