import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { getLocalizedService } from "../../../../helpers/get-localized-service";
import { getSpecialistServicePending } from "../../specialist-services.actions";
import { deleteSpecialistService } from "../../specialist-services.operations";
import { openModal } from "../../../../components/modal/modal.actions";

import { modalConstants } from "../../../../components/modal/modal.constants";
import { typeOfLazeImage } from "../../../../components/lazy-image/lazyImage.constansts";
import LazyImage from "../../../../components/lazy-image/lazy-image";
import { Icon } from "../../../../components/image/image";
import { DAYS_LIST } from "../../../../constants/days";

import "./index.scss";

const ServiceItem = ({ serviceInfo }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const generateTimeStringForLayout = (searchedDay) => {
        const currentScheduleItem = serviceInfo.schedule.find(
            (scheduleItem) => scheduleItem.day === searchedDay
        )?.workingHours[0];

        if (!currentScheduleItem)
            return (
                t("specialist-services.form.weekend")[0].toLocaleUpperCase() +
                t("specialist-services.form.weekend").slice(1)
            );

        return `${dayjs(currentScheduleItem["timeFrom"]).format(
            "HH:mm"
        )} - ${dayjs(currentScheduleItem["timeTo"]).format("HH:mm")}`;
    };

    return (
        <div className="service-item">
            <div className="service-item__header">
                <p className="service-item__header__title">
                    {t("specialist-services.service.title")}:{" "}
                    {serviceInfo &&
                        getLocalizedService(serviceInfo.subcategory)}
                </p>
                <div className="service-item__header__controls">
                    <div
                        className="service-item__header__controls__buttons"
                        onClick={() => {
                            dispatch(getSpecialistServicePending());
                            dispatch(
                                openModal({
                                    isOpen: true,
                                    closeIcon: true,
                                    details: {
                                        serviceId: serviceInfo._id,
                                    },
                                    renderType:
                                        modalConstants.specialistServiceForm,
                                })
                            );
                        }}
                    >
                        <Icon type="editService" />
                    </div>
                    <div
                        className="service-item__header__controls__buttons"
                        onClick={() => {
                            dispatch(deleteSpecialistService(serviceInfo._id));
                        }}
                    >
                        <Icon type="deleteService" />
                    </div>
                </div>
            </div>
            <div className="service-item__divider" />
            <div className="service-item__body">
                <div className="service-item__body--left-column">
                    <p className="service-item__body__caption">
                        {t("specialist-services.service.service-description")}
                    </p>
                    <div className="service-item__body__description-container">
                        <span className="service-item__body__description">
                            {t("specialist-services.service.service-sector")}
                        </span>
                        <span className="service-item__body__description">
                            {serviceInfo &&
                                getLocalizedService(serviceInfo.category)}
                        </span>
                    </div>
                    <div className="service-item__body__description-container">
                        <span className="service-item__body__description">
                            {t("specialist-services.service.selected-service")}
                        </span>
                        <span className="service-item__body__description">
                            {serviceInfo &&
                                getLocalizedService(serviceInfo.subcategory)}
                        </span>
                    </div>
                    <div className="service-item__body__description-container">
                        <span className="service-item__body__description">
                            {t(
                                "specialist-services.service.service-clarification"
                            )}
                        </span>
                        <span className="service-item__body__description">
                            {serviceInfo &&
                                getLocalizedService(serviceInfo.clarification)}
                        </span>
                    </div>
                    <p className="service-item__body__caption">
                        {t("specialist-services.service.execution-address")}
                    </p>
                    <div className="service-item__body__description-container">
                        <span className="service-item__body__description">
                            {t("specialist-services.service.city")}
                        </span>
                        <span className="service-item__body__description">
                            {t("general.abbreviation.short")}
                            {serviceInfo.city}
                        </span>
                    </div>
                    <div className="service-item__body__description-container">
                        <span className="service-item__body__description">
                            {t("specialist-services.service.district")}
                        </span>
                        <span className="service-item__body__description">
                            {serviceInfo.district}
                        </span>
                    </div>
                    <div className="service-item__body__description-container">
                        <span className="service-item__body__description">
                            {t("specialist-services.service.address")}
                        </span>
                        <span className="service-item__body__description">
                            {serviceInfo.address || "-"}
                        </span>
                    </div>

                    <p className="service-item__body__caption">
                        {t("specialist-services.service.service-comments")}
                    </p>
                    <div className="service-item__body__comments">
                        {serviceInfo.comment || "-"}
                    </div>
                </div>
                <div className="service-item__body--right-column">
                    <p className="service-item__body__caption">
                        {t("specialist-services.service.schedule")}
                    </p>
                    <div className="service-item__body__schedule">
                        {DAYS_LIST.map((day) => {
                            return (
                                <div
                                    key={day}
                                    className="service-item__body__schedule__content-container"
                                >
                                    <span className="service-item__body__schedule__content">
                                        {t(`days.${day}`)}
                                    </span>
                                    <span className="service-item__body__schedule__content">
                                        {generateTimeStringForLayout(day)}
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                    <div className="service-item__body__schedule__divider" />
                    <p className="service-item__body__caption">
                        {t("specialist-services.service.service-range")}
                    </p>
                    <div className="service-item__body__description-container">
                        <span className="service-item__body__description">
                            {t("specialist-services.service.service-range")}
                        </span>
                        <span className="service-item__body__description">
                            {serviceInfo.priceFrom} - {serviceInfo.priceTo} ₺
                        </span>
                    </div>
                    <p className="service-item__body__caption">
                        {t("specialist-services.service.service-photos")}
                    </p>
                    <div className="service-item__photos">
                        {serviceInfo.images && serviceInfo.images.length ? (
                            serviceInfo.images
                                .slice(0)
                                .reverse()
                                .map(({ url, _id }) => (
                                    <div
                                        key={_id}
                                        className="service-item__photos__item"
                                        onClick={() => {
                                            dispatch(
                                                openModal({
                                                    isOpen: true,
                                                    closeIcon: true,
                                                    renderType:
                                                        modalConstants.imageZoomer,
                                                    details: {
                                                        url,
                                                    },
                                                })
                                            );
                                        }}
                                    >
                                        <LazyImage
                                            image={url}
                                            type={typeOfLazeImage.content}
                                            options={{
                                                className:
                                                    "service-item__photos__item-img",
                                                alt: "logo",
                                            }}
                                        />
                                    </div>
                                ))
                        ) : (
                            <span className="service-item__photos-empty">
                                {t("specialist-services.no-images")}
                            </span>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

ServiceItem.propTypes = { serviceInfo: PropTypes.object };

export default ServiceItem;
