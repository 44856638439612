import { t } from "i18next";
import { string, object, number, ref, array } from "yup";

export const serviceFormSchema = object().shape({
    categoryId: object().required("validation_required"),
    subcategoryId: object().required("validation_required"),
    clarificationId: object().required("validation_required"),
    schedule: array().required("validation_required"),
    district: string().required("validation_required"),
    city: string().required("validation_required"),
    address: string()
        .required("validation_required")
        .min(3, ({ min }) => t("validation_min", { min }))
        .max(50, ({ max }) => t("validation_max", { max })),
    priceFrom: number("validation_positive_number")
        .required("validation_required")
        .typeError("validation_positive_number")
        .positive("validation_positive_number"),
    priceTo: number("validation_positive_number")
        .required("validation_required")
        .typeError("validation_positive_number")
        .positive("validation_positive_number")
        .min(ref("priceFrom"), ({ min }) => t("validation_min", { min })),
    comment: string()
        .required("validation_required")
        .min(10, ({ min }) => t("validation_min", { min }))
        .max(500, ({ max }) => t("validation_max", { max })),
});
