import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import { userBalanceSelector } from "../../balance.selectors";
import { getUserBalanceOperation } from "../../balance.operations";
import { renderPennies } from "../../balance.utils";
import { paymentOperationTypes } from "../../balance.constants";
import { myAccountDataSelector } from "../../../my-account/my-account.selectors";
import { openModal } from "../../../../components/modal/modal.actions";
import { modalConstants } from "../../../../components/modal/modal.constants";
import MainButton from "../../../../components/main-button/main-button";

import { ReactComponent as CurrencyLira } from "../../../../assets/images/currency/lira.svg";
import { ReactComponent as IyzicoIcon } from "../../../../assets/images/currency/iyzico.svg";
import { ReactComponent as IparaIcon } from "../../../../assets/images/currency/ipara.svg";
import { ReactComponent as AddWalletIcon } from "../../../../assets/images/currency/balance-add-wallet.svg";

import "./index.scss";

const BalanceInfo = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { money, pennies } = useSelector(userBalanceSelector);
    const { onboarding } = useSelector(myAccountDataSelector);

    useEffect(() => {
        dispatch(getUserBalanceOperation("test"));
    }, []);

    const addMoneyToBalanceHandler = () => {
        dispatch(
            openModal({
                isOpen: true,
                closeIcon: false,
                renderType: modalConstants.balancePaymentType,
                details: { type: paymentOperationTypes.deposit },
            })
        );
    };

    const withdrawMoneyHandler = () => {
        dispatch(
            openModal({
                isOpen: true,
                closeIcon: false,
                renderType: modalConstants.balancePaymentType,
                details: { type: paymentOperationTypes.withdrawal },
            })
        );
    };

    const addIyzicoWallet = () => {
        dispatch(
            openModal({
                isOpen: true,
                closeIcon: false,
                renderType: modalConstants.onboardingIyzico,
            })
        );
    };

    return (
        <div className="balance__container-right">
            <div className="balance__container-right-balance">
                <p className="balance__container-right-balance-title">
                    {t("balance-title")}
                </p>
                <div className="balance__container-right-balance-info">
                    <div className="balance__container-right-balance-info-systems">
                        <div className="balance__container-right-balance-info-systems-item">
                            <div className="balance__container-right-balance-info-systems-item-icon">
                                <IparaIcon />
                            </div>
                            <div className="balance__container-right-balance-info-systems-item-amount">
                                <span>200</span>
                                <CurrencyLira />
                            </div>
                        </div>
                        <div className="balance__container-right-balance-info-systems-item">
                            <div className="balance__container-right-balance-info-systems-item-icon">
                                <IyzicoIcon />
                            </div>
                            <div className="balance__container-right-balance-info-systems-item-amount">
                                <span>400</span>
                                <CurrencyLira />
                            </div>
                        </div>
                    </div>
                    <div className="balance__container-right-balance-info-funds">
                        <span>{t("balance-new.main-balance")}</span>
                        <div className="balance__container-right-balance-info-funds-amount">
                            <span>{`${money},${renderPennies(pennies)}`}</span>
                            <CurrencyLira />
                        </div>
                    </div>
                    <div className="balance__container-right-balance-info-funds balance__container-right-balance-info-processing-funds">
                        <span>{t("balance-new.funds-in-processing")}</span>
                        <div className="balance__container-right-balance-info-funds-amount">
                            <span>0,00</span>
                            <CurrencyLira />
                        </div>
                    </div>
                    <div className="balance__container-right-balance-info-funds balance__container-right-balance-info-processing-funds">
                        <span>{t("balance-new.pending-funds")}</span>
                        <div className="balance__container-right-balance-info-funds-amount">
                            <span>0,00</span>
                            <CurrencyLira />
                        </div>
                    </div>
                </div>
                <MainButton
                    buttonSize="btn--stretch"
                    onClick={addMoneyToBalanceHandler}
                >
                    {t("balance-refill-title")}
                </MainButton>
                <MainButton
                    buttonSize="btn--stretch"
                    buttonStyle="btn--light"
                    inlineStyle={{ marginTop: "16px" }}
                    onClick={withdrawMoneyHandler}
                >
                    {t("balance-new.withdraw")}
                </MainButton>
            </div>
            <div className="balance__container-right-wallets">
                <p className="balance__container-right-wallets-title">
                    {t("balance-new.wallets")}
                </p>
                <div className="balance__container-right-wallets-info">
                    {onboarding.iyzico ? (
                        <div className="balance__container-right-wallets-info-icon">
                            <IyzicoIcon />
                        </div>
                    ) : (
                        <p className="balance__container-right-wallets-info-empty-results">
                            {t("balance-new.available-wallets")}
                        </p>
                    )}
                </div>
                <button
                    className="balance__container-right-wallets-btn"
                    onClick={addIyzicoWallet}
                >
                    <AddWalletIcon />
                    <span className="balance__container-right-wallets-btn-text">
                        {t("balance-create-payment-account")}
                    </span>
                </button>
            </div>
        </div>
    );
};

export default BalanceInfo;
