import { supportTypes } from "./support.types";

//post
export const postQuestionPending = (data) => ({
    type: supportTypes.POST_QUESTION_PENDING,
    payload: data,
});

export const postQuestionFulfilled = (data) => ({
    type: supportTypes.POST_QUESTION_FULFILLED,
    payload: data,
});

export const postQuestionRejected = (data) => ({
    type: supportTypes.POST_QUESTION_REJECTED,
    payload: data,
});

//get
export const getAllQuestionsPending = (data) => ({
    type: supportTypes.GET_ALL_QUESTIONS_PENDING,
    payload: data,
});

export const getAllQuestionsFulfilled = (data) => ({
    type: supportTypes.GET_ALL_QUESTIONS_FULFILLED,
    payload: data,
});

export const getAllQuestionsRejected = (data) => ({
    type: supportTypes.GET_ALL_QUESTIONS_REJECTED,
    payload: data,
});

//delete
export const deleteQuestionPending = (data) => ({
    type: supportTypes.DELETE_QUESTION_PENDING,
    payload: data,
});

export const deleteQuestionFulfilled = (data) => ({
    type: supportTypes.DELETE_QUESTION_FULFILLED,
    payload: data,
});

export const deleteQuestionRejected = () => ({
    type: supportTypes.DELETE_QUESTION_REJECTED,
});

//set
export const setPage = (data) => ({
    type: supportTypes.SET_PAGE,
    payload: data,
});

export const setText = (data) => ({
    type: supportTypes.SET_TEXT,
    payload: data,
});

export const clearAllParams = () => ({
    type: supportTypes.CLEAR_ALL_PARAMS,
});
