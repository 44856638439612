import { useState, useEffect } from "react";

import { scrollToBottomChat, scrollToTop } from "./scroll-to-top-button.utils";

import { ReactComponent as ArrowDownIcon } from "../../assets/images/vectors/arrow_down.svg";
import { ReactComponent as ArrowDownIconGreen } from "../../assets/images/vectors/arrow_down_green.svg";
import PropTypes from "prop-types";
import "./index.scss";

const ScrollToTopButton = ({ type, chatRef, active = false }) => {
    const [showScroll, setShowScroll] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", checkScrollTop);
        return () => window.removeEventListener("scroll", checkScrollTop);
    });

    const checkScrollTop = () => {
        if (!showScroll && window.pageYOffset > 400) {
            setShowScroll(true);
        } else if (showScroll && window.pageYOffset <= 400) {
            setShowScroll(false);
        }
    };

    if (type === "chat-scroll") {
        if (active)
            return (
                <button
                    onClick={() => scrollToBottomChat(chatRef)}
                    className="scroll-button-chat"
                >
                    <ArrowDownIconGreen className="scroll-button-chat__icon" />
                </button>
            );
        else return "";
    }

    return (
        showScroll && (
            <button onClick={scrollToTop} className="scroll-button">
                <ArrowDownIcon className="scroll-button__icon" />
            </button>
        )
    );
};

ScrollToTopButton.propTypes = {
    active: PropTypes.bool,
    type: PropTypes.string,
    chatRef: PropTypes.object,
};

export default ScrollToTopButton;
