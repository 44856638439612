import { useTranslation } from "react-i18next";

import { ReactComponent as EmptyIcon } from "../../../../assets/images/error-pages/empty-result.svg";

import "./index.scss";

const PromotionEmptyResult = () => {
    const { t } = useTranslation();

    return (
        <div className="promotion-empty-result">
            <EmptyIcon />
            <p className="promotion-empty-result__text">
                {t("promotion.promotions-empty")}
            </p>
        </div>
    );
};

export default PromotionEmptyResult;
