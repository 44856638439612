import { t } from "i18next";

import { getLanguage } from "./language";

export const getLocalizedService = (service = {}) => {
    const { value: language } = getLanguage();

    return service
        ? service.titleTranslations
            ? service.titleTranslations[language]
            : service?.title || service?.remark || t("not_found")
        : t("not_found");
};
