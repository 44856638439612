import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { clearAllParams } from "./specialist-list.actions";
import {
    getSpecialistList,
    editSpecialistListPage,
} from "./specialist-list.operations";
import {
    specialistListSelector,
    specialistListPageSelector,
} from "./specialist-list.selectors";

import { errorHandlerTypes } from "../../components/error-handler/error-handler.constants";
import { backButtonConfig } from "../../components/back-button/back-button.config";
import SpecialistListForm from "../../components/specialists/specialists";
import ErrorHandler from "../../components/error-handler/error-handler";
import ReactHelmet from "../../components/react-helmet/react-helmet";
import BackButton from "../../components/back-button/back-button";
import Pagination from "../../components/pagination/pagination";

import "./index.scss";

const SpecialistsList = () => {
    const { specialistList } = useSelector(specialistListSelector);
    const page = useSelector(specialistListPageSelector);
    const { items, count } = specialistList;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const sizeItemOnPage = 5;

    useEffect(() => {
        dispatch(getSpecialistList(sizeItemOnPage));
        return () => dispatch(clearAllParams());
    }, []);

    return (
        <Fragment>
            <ReactHelmet title="specialists" />
            <div className="specialist-list">
                <div className="specialist-list__header">
                    <div className="specialist-list__header-btn">
                        <BackButton
                            title={t(backButtonConfig[location.state].title)}
                            onClickCallback={() =>
                                navigate(
                                    backButtonConfig[location.state].href,
                                    { state: true }
                                )
                            }
                        />
                    </div>
                    <div className="specialist-list__header-content">
                        <span className="specialist-list__header-content-title">
                            {t("specialists")}
                        </span>
                        <span className="specialist-list__header-content-subtitle">
                            {count
                                ? `${t("specialists_found")} ${count}
                            ${t("specialists_men")}`
                                : t("error_handler_not_found_results")}
                        </span>
                    </div>
                </div>
                {count ? (
                    <Fragment>
                        <SpecialistListForm specialists={items} />
                        <Pagination
                            items={specialistList}
                            elementType="page"
                            sizeItemOnPage={sizeItemOnPage}
                            getNextPageItems={(e) => {
                                dispatch(
                                    editSpecialistListPage(sizeItemOnPage, e)
                                );
                            }}
                            isStartedPage={page}
                        />
                    </Fragment>
                ) : (
                    <ErrorHandler type={errorHandlerTypes.NOT_FOUND_SEARCH} />
                )}
            </div>
        </Fragment>
    );
};

export default SpecialistsList;
