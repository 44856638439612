import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Switch from "../../../../components/input/switch/switch";
import { closeModal } from "../../../../components/modal/modal.actions";

import "./index.scss";

const NotificationSettingsForm = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const handleOnCancel = () => dispatch(closeModal());

    return (
        <div className="notification-settings-form">
            <div className="notification-settings-form__title">
                Хотите изменить настройки оповещений?
            </div>

            <div className="notification-settings-form__types">
                <div className="notification-settings-form__types-item">
                    <Switch />
                    <div className="notification-settings-form__types-item-text">
                        Системные уведомления
                    </div>
                </div>
                <div className="notification-settings-form__types-item">
                    <Switch />
                    <div className="notification-settings-form__types-item-text">
                        Уведомления на рабочем столе
                    </div>
                </div>
                <div className="notification-settings-form__types-item">
                    <Switch />
                    <div className="notification-settings-form__types-item-text">
                        Уведомления чата
                    </div>
                </div>
            </div>
            <div className="notification-settings-form__btns">
                <button
                    className="notification-settings-form__btns-cancel"
                    onClick={handleOnCancel}
                >
                    {t("btn_cancel")}
                </button>
            </div>
        </div>
    );
};

export default NotificationSettingsForm;
