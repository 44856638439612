import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";

import { phoneConfirmation } from "./phone-confirmation.schema";
import { authSelector } from "../auth.selectors";
import OauthLogin from "../oauth-login/oauth-login";
import { sendPhoneConfirmationDataOperation } from "../auth.operations";

import Input from "../../../components/input/input";
import ButtonLoader from "../../../components/loader/components/button-loader/button-loader";
import { buttonLoaderSelector } from "../../../components/loader/loader.selectors";
import { openModal } from "../../../components/modal/modal.actions";
import { modalConstants } from "../../../components/modal/modal.constants";

import "../auth-forms.scss";

const PhoneConfirmation = () => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const { status: buttonLoading } = useSelector(buttonLoaderSelector);
    const { user } = useSelector(authSelector);
    const { phone } = user;

    const { register, handleSubmit, formState } = useForm({
        mode: "all",
        resolver: yupResolver(phoneConfirmation),
    });

    const handlePhoneConfirm = (data) => {
        dispatch(
            sendPhoneConfirmationDataOperation(
                {
                    phone: phone.dialCode + phone.number,
                    code: data.phoneVerify,
                },
                navigate
            )
        );
    };

    const handleOpenPhoneCodeModal = () => {
        dispatch(
            openModal({
                isOpen: true,
                closeIcon: false,
                renderType: modalConstants.authPhoneCode,
            })
        );
    };

    return (
        <div className="auth_confirmation">
            <div className="auth_confirmation__info">
                <h2 className="auth_confirmation__title">
                    {t("phone_verify_title")}
                </h2>
                <p className="auth_confirmation__description">
                    <span>{t("phone_verify_subtitle_first")}</span>
                    <span> {phone.number}. </span>
                    <span>{t("phone_verify_subtitle_second")}</span>
                </p>
            </div>
            <div className="auth_confirmation__form">
                <form onSubmit={handleSubmit(handlePhoneConfirm)}>
                    <div className="auth_confirmation__content">
                        <Input
                            label="phone_verify_code"
                            type="secondary"
                            error={formState?.errors.phoneVerify?.message}
                            options={{
                                placeholderText: t("phone_verify_code"),
                            }}
                            inputChangeOptions={register("phoneVerify")}
                        />
                        <div className="auth_confirmation__question-tip">
                            <span onClick={handleOpenPhoneCodeModal}>
                                {t("phone_verify_question")}
                            </span>
                        </div>
                        <button
                            disabled={buttonLoading}
                            type="submit"
                            className="auth_confirmation__btn"
                            style={{
                                background: buttonLoading && "#dfdfdf",
                            }}
                        >
                            {buttonLoading ? (
                                <ButtonLoader size={38} />
                            ) : (
                                t("phone_verify_button")
                            )}
                        </button>
                    </div>
                </form>
                <OauthLogin />
            </div>
        </div>
    );
};

export default PhoneConfirmation;
