import { combineReducers } from "redux";

import { specialist } from "../pages/specialists-list/specialist-list.reducer";
import { notifications } from "../pages/notifications/notifications.reducer";
import { cities } from "../components/cities-loader/cities-loader.reducer";
import { websockets } from "../components/websockets/websockets.reducer";
import { allResult } from "../components/all-result/all-result.reducer";
import { errorHandler } from "../components/error-handler/error-handler.reducer";
import { service } from "../pages/all-services/all-services.reducer";
import { specialistOrders } from "../pages/specialist-orders/redux/specialist-orders.reducer";
import { myAccount } from "../pages/my-account/my-account.reducer";
import { toastify } from "../components/toastify/toastify.reducer";
import { specialistServices } from "../pages/specialist-services/specialist-services.reducer";
import { promotion } from "../pages/promotion/promotion.reducer";
import { feedback } from "../pages/feedback/feedback.reducer";
import { loader } from "../components/loader/loader.reducer";
import { balance } from "../pages//balance/balance.reducer";
import { support } from "../pages/support/support.reducer";
import { client } from "../pages/clients/clients.reducer";
import { modal } from "../components/modal/modal.reducer";
import { order } from "../pages/orders/orders.reducer";
import { authTypes } from "../pages/auth/auth.types";
import { chats } from "../pages/chats/chats.reducer";
import { auth } from "../pages/auth/auth.reducer";
import { blog } from "../pages/blog/blog.reducer";
import { favourites } from "../pages/favourites/favourites.reducer";
import { countryCodes } from "../components/country-codes-dropdown/country-codes.reducer";
import { faq } from "../pages/faq/faq.reducer";

const appReducer = combineReducers({
    specialistServices,
    specialistOrders,
    notifications,
    errorHandler,
    websockets,
    specialist,
    promotion,
    myAccount,
    allResult,
    feedback,
    toastify,
    support,
    balance,
    service,
    loader,
    cities,
    client,
    order,
    modal,
    chats,
    auth,
    blog,
    faq,
    favourites,
    countryCodes,
});

export const rootReducer = (state, action) => {
    if (action.type === authTypes.LOGOUT_USER) {
        return appReducer({ cities: state.cities }, action);
    }

    return appReducer(state, action);
};
