import React, { Fragment } from "react";

import ReactHelmet from "../../../components/react-helmet/react-helmet";
import AuthMain from "./components/main-distributor/main-distributor";

const Home = () => (
    <Fragment>
        <ReactHelmet title="title_profile_form" />
        <AuthMain />
    </Fragment>
);

export default Home;
