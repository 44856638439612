import React, { useState } from "react";
import PropTypes from "prop-types";

import "./index.scss";

const Tooltip = ({ content, children }) => {
    const [active, setActive] = useState(false);
    const hideTip = () => setActive(false);
    const showTip = () => setActive(true);

    return (
        <div className="tooltip" onMouseEnter={showTip} onMouseLeave={hideTip}>
            {children}
            {active && (
                <div className="tooltip-content">
                    <div className="tooltip-content-active">{content}</div>
                </div>
            )}
        </div>
    );
};

Tooltip.propTypes = { content: PropTypes.string, children: PropTypes.element };

export default Tooltip;
