import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useForm, Controller, useFormState } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { serviceSelector } from "../../../all-services/all-services.selectors";
import { buttonLoaderSelector } from "../../../../components/loader/loader.selectors";
import { citiesLoaderSelector } from "../../../../components/cities-loader/cities-loader.selectors";

import { openModal } from "../../../../components/modal/modal.actions";
import {
    setCategory,
    setLabel,
} from "../../../../components/all-result/all-result.actions";
import { postOrder } from "../../../orders/orders.operations";
import {
    getClarifications,
    getSubservices,
    getServices,
} from "../../../all-services/all-services.operations";
import { getAllDistricts } from "../../../../components/cities-loader/cities-loader.operations";

import Input from "../../../../components/input/input";
import Select from "../../../../components/select/select";
import Textarea from "../../../../components/input/textarea/textarea";
import DualInput from "../../../../components/input/dual-input/dual-input";
import DatePicker from "../../../../components/input/date-picker/date-picker";
import ButtonLoader from "../../../../components/loader/components/button-loader/button-loader";
import ImageUploader from "../../../../components/input/image-uploader/image-uploader";
import LocationSelect from "../../../../components/location-select/location-select";

import routerBook from "../../../../router/router-book";
import SELECT_TYPES from "../../../../components/select/select.constants";
import { modalConstants } from "../../../../components/modal/modal.constants";
import { IMAGE_UPLOADER_TYPES } from "../../../../components/input/image-uploader/image-uploader.constants";

import { genders } from "../../../../constants/genders";
import { isEmptyObject } from "../../../../helpers/is-empty-object";
import {
    createOrderSchema,
    createOrderSchemaFixedType,
} from "./create-order-form.schema";
import {
    convertValuesToSearchFormat,
    convertValuesToSearchString,
    handleDeleteImage,
    mapStateToOptions,
} from "./create-order-form.utils";

import deletePhotoIcon from "../../../../assets/images/create-order-icons/delete_photo.svg";

import "./index.scss";
import { priceOptionsList } from "./create-order-form.config";

const CreateOrderForm = ({ details: receiverId }) => {
    const [typeOfPrice, setTypeOfPrice] = useState(priceOptionsList[0].value);

    const {
        handleSubmit,
        register,
        control,
        getValues,
        setValue,
        resetField,
        formState: { errors },
        watch,
        unregister,
    } = useForm({
        mode: "all",
        defaultValues: {
            ...JSON.parse(localStorage.getItem("unfinishedForm")),
        },
        resolver: yupResolver(
            typeOfPrice === priceOptionsList[1].value
                ? createOrderSchemaFixedType
                : createOrderSchema
        ),
    });

    const priceFrom = watch("priceFrom");

    const { dirtyFields } = useFormState({ control });

    const [image, setImage] = useState(
        JSON.parse(localStorage.getItem("unfinishedFormImages")) || []
    );
    const [city, setCityValue] = useState("");

    const { status: buttonStatus } = useSelector(buttonLoaderSelector);
    const { cities, districts } = useSelector(citiesLoaderSelector);
    const { clarifications, subservices, services } =
        useSelector(serviceSelector);

    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => dispatch(getServices()), []);

    useEffect(() => {
        if (city.state_code) dispatch(getAllDistricts(city.state_code));
    }, [city]);

    const handleShowSpecialistClick = () => {
        const values = getValues();

        if (
            values.categoryId?.value &&
            values.subcategoryId?.value &&
            values.clarificationId?.value
        ) {
            localStorage.setItem(
                "unfinishedForm",
                JSON.stringify({ ...values })
            );

            image.forEach(async (item) => {
                let reader = new FileReader();
                if (typeof item !== "string") {
                    reader.readAsDataURL(item);
                    reader.onload = function () {
                        localStorage.setItem(
                            "unfinishedFormImages",

                            localStorage.getItem("unfinishedFormImages")
                                ? JSON.stringify([
                                      ...JSON.parse(
                                          localStorage.getItem(
                                              "unfinishedFormImages"
                                          )
                                      ),
                                      reader.result,
                                  ])
                                : JSON.stringify([reader.result])
                        );
                    };
                }
            });

            dispatch(
                setLabel(
                    convertValuesToSearchFormat(values, {
                        services,
                        subservices,
                        clarifications,
                    })
                )
            );

            dispatch(setCategory(convertValuesToSearchString(values)));
            navigate(routerBook.allServicesResult, { state: true });
        } else {
            dispatch(
                openModal({
                    isOpen: true,
                    closeIcon: true,
                    renderType: modalConstants.userNotSelectAllFields,
                })
            );
        }
    };

    const handleFormSubmit = (data) => {
        Promise.allSettled(
            image.map((item, index) => {
                if (typeof item === "string") {
                    return fetch(item)
                        .then((res) => res.blob())
                        .then((blob) => {
                            const file = new File([blob], index, {
                                type: "image/png",
                            });

                            return file;
                        });
                } else {
                    return item;
                }
            })
        ).then((imageData) => {
            const image = imageData.map((item) => item.value);
            dispatch(postOrder(data, image, navigate, receiverId));
            localStorage.removeItem("unfinishedForm");
            localStorage.removeItem("unfinishedFormImages");
        });
    };

    const clearFields = (fields = []) => {
        fields.forEach((item) => setValue(item, undefined));
    };

    const handleSelectCity = (city) => {
        resetField("district");
        setCityValue(city);
    };

    useEffect(() => {
        unregister("fixedPrice");
        if (typeOfPrice === priceOptionsList[1].value) {
            unregister(["priceFrom", "priceTo"]);
        }
    }, [typeOfPrice, unregister]);

    return (
        <form
            onSubmit={handleSubmit(handleFormSubmit)}
            className="create-order-form"
        >
            <div className="create-order-form__content">
                <div className="create-order-form__content-left">
                    <div className="create-order-form__content-left__item">
                        <h1 className="create-order-form__content-left__item-title">
                            {t("order_describde_order")}
                        </h1>
                        {services.length > 0 && (
                            <>
                                <Controller
                                    name="categoryId"
                                    control={control}
                                    render={({
                                        field: {
                                            onChange,
                                            onBlur,
                                            value,
                                            name,
                                        },
                                    }) => (
                                        <Select
                                            name={name}
                                            error={errors.categoryId?.message}
                                            value={value}
                                            onBlur={(category) =>
                                                onBlur(category)
                                            }
                                            options={mapStateToOptions(
                                                services
                                            )}
                                            onSelect={(category) => {
                                                clearFields([
                                                    "subcategoryId",
                                                    "clarificationId",
                                                ]);
                                                onChange(category);
                                                dispatch(
                                                    getSubservices(
                                                        category.value,
                                                        "local"
                                                    )
                                                );
                                            }}
                                            label={t(
                                                "specialist-services.form.service-sector.label"
                                            )}
                                            placeholder={t(
                                                "specialist-services.form.service-sector.placeholder"
                                            )}
                                        />
                                    )}
                                />
                                <Controller
                                    name="subcategoryId"
                                    control={control}
                                    render={({
                                        field: {
                                            onChange,
                                            onBlur,
                                            value,
                                            name,
                                        },
                                    }) => (
                                        <Select
                                            name={name}
                                            value={value}
                                            error={
                                                errors.subcategoryId?.message
                                            }
                                            onBlur={(category) =>
                                                onBlur(category)
                                            }
                                            options={mapStateToOptions(
                                                subservices
                                            )}
                                            onSelect={(category) => {
                                                clearFields([
                                                    "clarificationId",
                                                ]);
                                                onChange(category);
                                                dispatch(
                                                    getClarifications(
                                                        category.value,
                                                        "local"
                                                    )
                                                );
                                            }}
                                            label={t(
                                                "specialist-services.form.service.label"
                                            )}
                                            placeholder={t(
                                                "specialist-services.form.service.placeholder"
                                            )}
                                        />
                                    )}
                                />
                                <Controller
                                    name="clarificationId"
                                    control={control}
                                    render={({
                                        field: {
                                            onChange,
                                            onBlur,
                                            value,
                                            name,
                                        },
                                    }) => (
                                        <Select
                                            name={name}
                                            error={
                                                errors.clarificationId?.message
                                            }
                                            value={value}
                                            onBlur={(category) =>
                                                onBlur(category)
                                            }
                                            options={mapStateToOptions(
                                                clarifications
                                            )}
                                            onSelect={(category) => {
                                                onChange(category);
                                            }}
                                            label={t(
                                                "specialist-services.form.service-clarification.label"
                                            )}
                                            placeholder={t(
                                                "specialist-services.form.service-clarification.placeholder"
                                            )}
                                        />
                                    )}
                                />
                            </>
                        )}
                    </div>
                    <div className="create-order-form__content-left__item">
                        <h1 className="create-order-form__content-left__item-title">
                            {t("order_adress_order")}
                        </h1>
                        <Controller
                            name="city"
                            control={control}
                            render={({
                                field: { onChange, onBlur, value, name },
                            }) => (
                                <LocationSelect
                                    name={name}
                                    error={errors.city?.message}
                                    value={value}
                                    onSelect={(city) => {
                                        handleSelectCity(city);
                                        onChange(city.name);
                                    }}
                                    onBlur={(city) => onBlur(city.name)}
                                    options={cities}
                                    type={SELECT_TYPES.WITHOUT_LABEL}
                                    label={t(
                                        "specialist-services.form.city.label"
                                    )}
                                    placeholder={t(
                                        "specialist-services.form.city.placeholder"
                                    )}
                                />
                            )}
                        />
                        <Controller
                            name="district"
                            control={control}
                            render={({
                                field: { value, onChange, onBlur },
                            }) => (
                                <LocationSelect
                                    value={value}
                                    options={dirtyFields.city ? districts : []}
                                    onSelect={({ name }) => onChange(name)}
                                    onBlur={({ name }) => onBlur(name)}
                                    type={SELECT_TYPES.WITHOUT_LABEL}
                                    error={errors.district?.message}
                                    label={t(
                                        "specialist-services.form.district.label"
                                    )}
                                    placeholder={t(
                                        "specialist-services.form.district.placeholder"
                                    )}
                                />
                            )}
                        />
                        <Input
                            inputChangeOptions={register("address")}
                            label="specialist-services.form.address.label"
                            options={{
                                placeholderText:
                                    "specialist-services.form.address.placeholder",
                            }}
                            type="secondary"
                            error={errors.address?.message}
                        />
                    </div>
                    <div className="create-order-form__content-left__item">
                        <h1 className="create-order-form__content-left__item-title">
                            {t("order_date_title")}
                        </h1>
                        <Controller
                            control={control}
                            name="completionDeadline"
                            render={({ field: { onChange, value, ref } }) => (
                                <DatePicker
                                    onChange={onChange}
                                    selected={value}
                                    errors={errors.completionDeadline}
                                    dateRef={(elem) => {
                                        elem && ref(elem.input);
                                    }}
                                    minDate={Date.now()}
                                    maxVisibleYears={100}
                                    label={t("orders.order-item.date")}
                                />
                            )}
                        />
                    </div>
                    <div className="create-order-form__content-left__item">
                        <h1 className="create-order-form__content-left__item-title">
                            {t("order_time_title")}
                        </h1>

                        <Input
                            style={{ marginTop: "12px" }}
                            inputChangeOptions={register("executionTime")}
                            label="specialist-services.form.executionTime.label"
                            options={{
                                placeholderText:
                                    "specialist-services.form.executionTime.placeholder",
                            }}
                            type="secondary"
                            error={errors.executionTime?.message}
                        />
                    </div>
                </div>
                <div className="create-order-form__content-right">
                    <div className="create-order-form__content-right__item">
                        <h1 className="create-order-form__content-right__item-title">
                            {t("order_price_title")}
                        </h1>
                        <div className="create-order-form__content-right__option">
                            {priceOptionsList.map(({ value }) => (
                                <span
                                    onClick={() => setTypeOfPrice(value)}
                                    className={`create-order-form__content-right__option-price${
                                        typeOfPrice === value ? "--active" : ""
                                    }`}
                                    key={value}
                                >
                                    {t(value)}
                                </span>
                            ))}
                        </div>
                        {typeOfPrice === priceOptionsList[1].value ? (
                            <Input
                                style={{ marginTop: "12px" }}
                                inputChangeOptions={register("fixedPrice")}
                                options={{
                                    placeholderText:
                                        "specialist-services.form.price.placeholder",
                                }}
                                type="fixed-price"
                                error={errors.fixedPrice?.message}
                            />
                        ) : (
                            <DualInput
                                inputOptionsFirst={register("priceFrom")}
                                inputOptionsSecond={register("priceTo")}
                                errorsOptionsFirst={t(
                                    errors?.priceFrom?.message
                                )}
                                errorsOptionsSecond={t(errors.priceTo?.message)}
                                type="range-dual-price"
                                watchedPriceFrom={priceFrom}
                            />
                        )}
                    </div>
                    <div className="create-order-form__content-right__item">
                        <h1 className="create-order-form__content-right__item-title">
                            {t("order_photo_title")}
                        </h1>
                        <div className="order-item__photos">
                            <ImageUploader
                                eventManagementFunctions={{
                                    callback: (item) => {
                                        setImage([...item, ...image]);
                                    },
                                }}
                                setImage={setImage}
                                value={image}
                                type={IMAGE_UPLOADER_TYPES.ORDER_ITEM}
                                id={"upload-order-photo"}
                            />
                            {image.length
                                ? image.map((item, index) => (
                                      <div
                                          className="order-item__photos__item"
                                          key={index}
                                      >
                                          <img
                                              src={
                                                  typeof item === "string"
                                                      ? item
                                                      : URL.createObjectURL(
                                                            item
                                                        )
                                              }
                                              className="order-item__photos__item-img"
                                          />
                                          <img
                                              onClick={(e) =>
                                                  handleDeleteImage(
                                                      e,
                                                      index,
                                                      image,
                                                      setImage
                                                  )
                                              }
                                              src={deletePhotoIcon}
                                              className="order-item__photos__item-close"
                                          />
                                      </div>
                                  ))
                                : null}
                        </div>
                    </div>
                    <div className="create-order-form__content-right__item">
                        <h1 className="create-order-form__content-right__item-title">
                            {t("order_gender_title")}
                        </h1>
                        <Controller
                            control={control}
                            name="preferredGender"
                            render={({
                                field: { onChange, onBlur, value, name },
                            }) => (
                                <Select
                                    name={name}
                                    error={errors.preferredGender?.message}
                                    value={value}
                                    onBlur={(preferredGender) =>
                                        onBlur(preferredGender)
                                    }
                                    options={genders}
                                    onSelect={(preferredGender) =>
                                        onChange(preferredGender)
                                    }
                                    label={t("gender")}
                                    placeholder={t("order_gender_placeholder")}
                                />
                            )}
                        />
                    </div>
                    <div className="create-order-form__content-right__item">
                        <h1 className="create-order-form__content-right__item-title">
                            {t("order_age_title")}
                        </h1>

                        <DualInput
                            inputOptionsFirst={register("preferredYears.from")}
                            inputOptionsSecond={register("preferredYears.to")}
                            errorsOptionsFirst={t(
                                errors.preferredYears?.from?.message
                            )}
                            errorsOptionsSecond={t(
                                errors.preferredYears?.to?.message
                            )}
                            type="range-dual"
                            label={t("age")}
                        />
                    </div>

                    <div className="create-order-form__content-right__item">
                        <h1 className="create-order-form__content-right__item-title">
                            {t("order_comment_title")}
                        </h1>
                        <Textarea
                            placeholderText="order_comment_placeholder"
                            inputChangeOptions={register("comment")}
                            errors={t(errors.comment?.message)}
                            label={t("orders.order-item.order-comments")}
                        />
                    </div>
                </div>
            </div>
            <div className="create-order-form__btns">
                <button
                    disabled={buttonStatus || isEmptyObject(dirtyFields)}
                    style={{
                        background: buttonStatus && "#dfdfdf",
                    }}
                    className="create-order-form__btns-left"
                    type="submit"
                >
                    {buttonStatus ? (
                        <ButtonLoader size={38} />
                    ) : (
                        t("order_btn_create")
                    )}
                </button>
                {!receiverId && (
                    <button
                        onClick={() => handleShowSpecialistClick()}
                        className="create-order-form__btns-right"
                        type="button"
                    >
                        {t("order_btn_specialists")}
                    </button>
                )}
            </div>
        </form>
    );
};

CreateOrderForm.propTypes = {
    details: PropTypes.string,
};

export default CreateOrderForm;
