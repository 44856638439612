import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { errorHandlerTypes } from "../../error-handler.constants";
import ReactHelmet from "../../../react-helmet/react-helmet";

import "./index.scss";

const ErrorHandlerContentItem = (
    {
        image,
        title,
        description,
        buttonText,
        onClickActions,
        noShowButton,
        subDescription,
        onClickRedirect,
    },
    type
) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <Fragment>
            <ReactHelmet title={title} />
            <div className="error-component">
                {errorHandlerTypes.PAGE_NOT_FOUND === type && (
                    <div className="error-component__not-found">
                        <span className="error-component__not-found-title">
                            404
                        </span>
                        <span className="error-component__not-found-subtitle">
                            {t(title)}
                        </span>
                        <img
                            className="error-component__not-found-image"
                            src={image}
                            alt={type}
                        />
                    </div>
                )}
                {errorHandlerTypes.PAGE_NOT_FOUND !== type && (
                    <>
                        {errorHandlerTypes.NO_INTERNET === type ? (
                            image
                        ) : (
                            <img
                                src={image}
                                className="error-component__image"
                                alt={type}
                            />
                        )}
                        <span className="error-component__title">
                            {t(title)}
                        </span>
                    </>
                )}
                <span className="error-component__description">
                    {t(description)}
                </span>
                {subDescription && (
                    <span className="error-component__subdescription">
                        {t(subDescription)}
                    </span>
                )}
                {onClickRedirect && (
                    <span className="error-component__redirect">
                        {t(onClickRedirect)}
                    </span>
                )}
                {!noShowButton ? (
                    <button
                        onClick={() =>
                            navigate(onClickActions, { state: true })
                        }
                        className="error-component__button"
                    >
                        {t(buttonText)}
                    </button>
                ) : null}
            </div>
        </Fragment>
    );
};

ErrorHandlerContentItem.propTypes = {
    image: PropTypes.elementType,
    title: PropTypes.string,
    description: PropTypes.string,
    onClickRedirect: PropTypes.string,
    subDescription: PropTypes.string,
    buttonText: PropTypes.string,
    onClickActions: PropTypes.func,
    noShowButton: PropTypes.bool,
    type: PropTypes.string,
};

export default ErrorHandlerContentItem;
