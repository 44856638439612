import { object, string } from "yup";
// import { t } from "i18next";

import { isDigitValidation } from "../../../constants/input-validation";

export const verificationEmail = object().shape({
    email: string().required("validation_required").email("validation_email"),
});

export const verificationPhone = object().shape({
    phoneValue: string()
        .trim(true)
        .required("validation_required")
        .matches(isDigitValidation, "only digits allowed")
        .min(7)
        .max(13),
    phoneCode: string().required("validation_required"),
});
