import {
    sizeItemOnPage,
    sizeHistoryItemOnPage,
    sizeActivePromotionItemOnPage,
} from "./promotion.constants";
import { promotionTypes } from "./promotion.types";

const initialQueryParams = { limit: sizeItemOnPage, page: 0, name: "" };
const initialActivePromotionsQueryParams = {
    limit: sizeActivePromotionItemOnPage,
    page: 0,
    name: "",
};
const initialHistoryQueryParams = {
    limit: sizeHistoryItemOnPage,
    page: 0,
    name: "",
};

const initialState = {
    allPromotion: { items: [], count: 0 },
    promotionHistory: { items: [], count: 0 },
    promotion: {},

    params: initialQueryParams,
    activePromotionsParams: initialActivePromotionsQueryParams,
    historyParams: initialHistoryQueryParams,

    loading: false,
    error: "",
};

export const promotion = (state = initialState, { type, payload }) => {
    switch (type) {
        // loading
        case promotionTypes.GET_PROMOTION_BY_ID_PENDING:
        case promotionTypes.GET_PROMOTION_PENDING:
        case promotionTypes.GET_PROMOTION_HISTORY_PENDING: {
            return { ...state, loading: true };
        }

        // data promotion list
        case promotionTypes.GET_PROMOTION_FULFILLED:
            return { ...state, allPromotion: payload, loading: false };

        // data promotion history
        case promotionTypes.GET_PROMOTION_HISTORY_FULFILLED:
            return {
                ...state,
                promotionHistory: payload,
                loading: false,
            };

        // data promotion
        case promotionTypes.GET_PROMOTION_BY_ID_FULFILLED:
            return { ...state, promotion: payload, loading: false };

        // error
        case promotionTypes.GET_PROMOTION_BY_ID_REJECTED:
        case promotionTypes.GET_PROMOTION_REJECTED:
        case promotionTypes.GET_PROMOTION_HISTORY_REJECTED: {
            return { ...state, loading: false, error: payload };
        }

        // add promotion
        case promotionTypes.ADD_PROMOTION:
            return {
                ...state,
                promotionHistory: [...state.promotionHistory, payload],
            };

        // deactivate promotion
        case promotionTypes.DEACTIVATE_PROMOTION_FULFILLED: {
            return {
                ...state,
                loading: false,
                promotionHistory: {
                    ...state.promotionHistory,
                    items: state.promotionHistory.items.filter(
                        (item) => item._id !== payload
                    ),
                },
            };
        }

        // set
        case promotionTypes.CLEAR_ALL_PARAMS:
            return {
                ...state,
                params: initialQueryParams,
                historyParams: initialHistoryQueryParams,
                activePromotionsParams: initialActivePromotionsQueryParams,
            };
        case promotionTypes.SET_PAGE:
            return { ...state, params: { ...state.params, page: payload } };
        case promotionTypes.SET_SEARCH_QUERY:
            return {
                ...state,
                params: { ...state.params, name: payload, page: 0 },
            };

        // set active promotions
        case promotionTypes.SET_ACTIVE_PROMOTIONS_PAGE:
            return {
                ...state,
                activePromotionsParams: {
                    ...state.activePromotionsParams,
                    page: payload,
                },
            };

        // set history
        case promotionTypes.SET_HISTORY_PAGE:
            return {
                ...state,
                historyParams: { ...state.historyParams, page: payload },
            };

        default:
            return state;
    }
};
