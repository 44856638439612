import { serviceTypes } from "./all-services.types";

// get service
export const getServicePending = () => ({
    type: serviceTypes.GET_SERVICE_PENDING,
});

export const getServiceFulfilled = (data) => ({
    type: serviceTypes.GET_SERVICE_FULFILLED,
    payload: data,
});

export const getServiceRejected = (error) => ({
    type: serviceTypes.GET_SERVICE_REJECTED,
    payload: error,
});

// get subservice
export const getSubservicePending = () => ({
    type: serviceTypes.GET_SUBSERVICE_PENDING,
});

export const getSubserviceFulfilled = (data) => ({
    type: serviceTypes.GET_SUBSERVICE_FULFILLED,
    payload: data,
});

export const getSubserviceRejected = (error) => ({
    type: serviceTypes.GET_SUBSERVICE_REJECTED,
    payload: error,
});

// get clarification
export const getClarificationPending = () => ({
    type: serviceTypes.GET_CLARIFICATION_PENDING,
});

export const getClarificationFulfilled = (data) => ({
    type: serviceTypes.GET_CLARIFICATION_FULFILLED,
    payload: data,
});

export const getClarificationRejected = (error) => ({
    type: serviceTypes.GET_CLARIFICATION_REJECTED,
    payload: error,
});

export const clearAllServices = () => ({
    type: serviceTypes.CLEAR_ALL_SERVICES,
});

// add remove favorites
export const addRemoveFavoritesPending = () => ({
    type: serviceTypes.ADD_REMOVE_FAVORITES_PENDING,
});

export const addRemoveFavoritesFulfilled = () => ({
    type: serviceTypes.ADD_REMOVE_FAVORITES_FULFILLED,
});

export const addRemoveFavoritesRejected = (error) => ({
    type: serviceTypes.ADD_REMOVE_FAVORITES_REJECTED,
    payload: error,
});

// set service for search
export const setServiceForSearchFulfilled = (data) => ({
    type: serviceTypes.SET_SERVICE_FOR_SEARCH_FULFILLED,
    payload: data,
});

// set subservice for search
export const setSubserviceForSearchFulfilled = (data) => ({
    type: serviceTypes.SET_SUBSERVICE_FOR_SEARCH_FULFILLED,
    payload: data,
});

// set clarification for search
export const setClarificationForSearchFulfilled = (data) => ({
    type: serviceTypes.SET_CLARIFICATION_FOR_SEARCH_FULFILLED,
    payload: data,
});
