import { useTranslation } from "react-i18next";
import React, { Fragment } from "react";

import ReactHelmet from "../../components/react-helmet/react-helmet";
import { termsOfServiceConfig } from "./terms-of-service.config";
import { replaceValue } from "./terms-of-service.utils";

import "./index.scss";

const TermsOfService = () => {
    const { t } = useTranslation();

    return (
        <div className="terms-of-service">
            <ReactHelmet title="nav_terms_of_service" />
            <h1 className="terms-of-service__title">
                {t("nav_terms_of_service")}
            </h1>
            <section className="terms-of-service__container">
                {termsOfServiceConfig.map(
                    ({ title, titles, subtitles }, firstIndex) => (
                        <div
                            className="terms-of-service__container-item"
                            key={title}
                        >
                            <h1 className="terms-of-service__container-item__title">
                                <span className="terms-of-service__container-item__title-space">
                                    {firstIndex + 1}.
                                </span>
                                {t(title)}
                            </h1>
                            {titles &&
                                titles.map(
                                    (
                                        { item, items, value, href },
                                        secondIndex
                                    ) => (
                                        <Fragment key={item}>
                                            <h2 className="terms-of-service__container-item__titles">
                                                <span className="terms-of-service__container-item__titles-space">
                                                    {firstIndex + 1}.
                                                    {secondIndex + 1}
                                                </span>
                                                {replaceValue(
                                                    t(item),
                                                    value,
                                                    href
                                                )}
                                            </h2>
                                            {items &&
                                                items.map(
                                                    ({
                                                        title,
                                                        subtitle,
                                                        value,
                                                        href,
                                                    }) => (
                                                        <h2
                                                            key={title}
                                                            className="terms-of-service__container-item__titles-item"
                                                        >
                                                            {title && (
                                                                <>
                                                                    <span className="terms-of-service__container-item__titles-item-space">
                                                                        —
                                                                    </span>
                                                                    <span className="terms-of-service__container-item__titles-item-bold">
                                                                        {t(
                                                                            title
                                                                        )}
                                                                    </span>
                                                                </>
                                                            )}{" "}
                                                            —{" "}
                                                            {replaceValue(
                                                                t(subtitle),
                                                                value,
                                                                href
                                                            )}
                                                        </h2>
                                                    )
                                                )}
                                        </Fragment>
                                    )
                                )}
                            {subtitles &&
                                subtitles.map(({ item }) => (
                                    <h2
                                        className="terms-of-service__container-item__subtitles"
                                        key={item}
                                    >
                                        {t(item)}
                                    </h2>
                                ))}
                        </div>
                    )
                )}
            </section>
        </div>
    );
};

export default TermsOfService;
