export const notificationName = {
    WELCOME: "Welcome",
    ORDER_CREATED: "OrderCreated",
    PRICE_FIXED: "OrderPriceFixed",
    ORDER_IN_WORK: "OrderInWork",
    ORDER_COMPLETED: "OrderCompleted",
    CANCELLED: "OrderCancelled",
    PROPOSAL_FOR_COOPERATION: "OrderProposalForCooperation",
    DELETE_USER_AVATAR: "DeleteUserAvatar",
    USER_BAN: "UserBan",
    USER_UNBAN: "UserUnban",
    DELETE_USER: "DeleteUser",
    PROMOTION_IS_BUY: "PromotionIsBuy",
};

export const sizeItemOnPage = 5;
