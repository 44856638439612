import PropTypes from "prop-types";

import "./index.scss";

const PhoneTestCodeModal = ({ details }) => {
    return (
        <div className="phone-code-modal">
            <span className="phone-code-modal__title">
                Verification {details}
            </span>
        </div>
    );
};

PhoneTestCodeModal.propTypes = {
    details: PropTypes.string,
};

export default PhoneTestCodeModal;
