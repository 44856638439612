import ErrorHandlerContentItem from "../error-handler-content-item/error-handler-content-item";
import { errorHandlerTypes } from "../../error-handler.constants";
import { options } from "../../error-handler.configs";

const ErrorHandlerContent = (type) => {
    switch (type) {
        case errorHandlerTypes.ACCESS_DENIED:
            return ErrorHandlerContentItem(options.ACCESS_DENIED, type);
        case errorHandlerTypes.NO_INTERNET:
            return ErrorHandlerContentItem(options.NO_INTERNET, type);
        case errorHandlerTypes.PAGE_NOT_FOUND:
            return ErrorHandlerContentItem(options.PAGE_NOT_FOUND, type);
        case errorHandlerTypes.SOMETHING_WRONG:
            return ErrorHandlerContentItem(options.SOMETHING_WRONG, type);
        case errorHandlerTypes.NOT_FOUND_SEARCH:
            return ErrorHandlerContentItem(options.NOT_FOUND_SEARCH, type);
        case errorHandlerTypes.EMPTY_FAVORITES:
            return ErrorHandlerContentItem(options.EMPTY_FAVORITES, type);
        case errorHandlerTypes.IN_DEVELOPMENT:
            return ErrorHandlerContentItem(options.IN_DEVELOPMENT, type);
        case errorHandlerTypes.BLOCKED_USER:
            return ErrorHandlerContentItem(options.BLOCKED_USER, type);

        default:
            return ErrorHandlerContentItem(options.SOMETHING_WRONG, type);
    }
};

export default ErrorHandlerContent;
