import { useDispatch, useSelector } from "react-redux";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { memo } from "react";
import dayjs from "dayjs";

import { ordersSelector } from "../../../orders/orders.selectors";
import { chatsSelector } from "../../../chats/chats.selectors";

import { typeOfLazeImage } from "../../../../components/lazy-image/lazyImage.constansts";
import LazyImage from "../../../../components/lazy-image/lazy-image";
import { descriptionItem, profileServices } from "./profile.config";
import { getPhotoByTypeGender } from "../../../../helpers/get-photo-by-type-gender";
import { Icon } from "../../../../components/image/image";
import routerBook from "../../../../router/router-book";

import "./index.scss";

const Profile = ({
    _id: specialistId,
    completedOrders,
    feedbackCount,
    firstName,
    createdAt,
    lastName,
    avatar,
    gender,
    rating,
    city,
    age,
}) => {
    const {
        chat: { orderId, taskId, district, _id: chatId },
    } = useSelector(chatsSelector);
    const {
        orderTask: { status },
    } = useSelector(ordersSelector);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    dayjs.extend(relativeTime);
    dayjs.extend(updateLocale);
    dayjs.updateLocale("en", {
        relativeTime: {
            s: t("locations.second.singular"),
            m: t("locations.minute.singular"),
            mm: `%d ${t("locations.minute.plural")}`,
            h: t("locations.hour.singular"),
            hh: `%d ${t("locations.hour.plural")}`,
            d: t("locations.day.singular"),
            dd: `%d ${t("locations.day.plural")}`,
            M: t("locations.month.singular"),
            MM: `%d ${t("locations.month.plural")}`,
            y: t("locations.year.singular"),
            yy: `%d ${t("locations.year.plural")}`,
        },
    });

    return (
        <div className="discussion_work_project_profile">
            <div className="discussion_work_project_profile__header">
                <LazyImage
                    image={getPhotoByTypeGender(avatar, gender)}
                    type={typeOfLazeImage.avatar}
                    options={{
                        className:
                            "discussion_work_project_profile__header_img",
                        alt: "avatar",
                    }}
                />
                <h5 className="discussion_work_project_profile__header_title">
                    {firstName || t("chats.deleted_user")} {lastName}
                </h5>
            </div>
            {firstName && lastName && age ? (
                <>
                    <div className="discussion_work_project_profile__rate">
                        <div
                            className="discussion_work_project_profile__rate_inner"
                            onClick={() =>
                                navigate(
                                    `${routerBook.specialistsList}/${specialistId}`,
                                    { state: true }
                                )
                            }
                        >
                            <div className="discussion_work_project_profile__rate_left">
                                <div className="discussion_work_project_profile__rate_left_img">
                                    <Icon type="star" />
                                </div>
                                <div className="discussion_work_project_profile__rate_left_number">
                                    {rating && rating.toFixed(1)}
                                </div>
                            </div>
                            <div className="discussion_work_project_profile__rate_right">
                                <div className="discussion_work_project_profile__rate_right_img">
                                    <Icon type="chat" />
                                </div>
                                <div className="discussion_work_project_profile__rate_right_number">
                                    {feedbackCount}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="discussion_work_project_profile__info">
                        <span className="discussion_work_project_profile__info_inner">
                            <div className="discussion_work_project_profile__info_descriptions">
                                {descriptionItem.map(({ title }, index) => (
                                    <div
                                        key={title + index}
                                        className="discussion_work_project_profile__info_descriptions_item"
                                    >
                                        {t(title)}
                                    </div>
                                ))}
                            </div>
                            <div className="discussion_work_project_profile__info_values">
                                <div className="discussion_work_project_profile__info_values_item">
                                    {completedOrders}
                                </div>
                                <div className="discussion_work_project_profile__info_values_item">
                                    {age} {t("chats.age")}
                                </div>
                                <div className="discussion_work_project_profile__info_values_item">
                                    {dayjs(createdAt).fromNow(true)}
                                </div>
                                <div className="discussion_work_project_profile__info_values_item">
                                    {city}
                                </div>
                                <div className="discussion_work_project_profile__info_values_item">
                                    {district || t("not_selected")}
                                </div>
                            </div>
                        </span>
                    </div>
                    <div className="discussion_work_project_profile__services">
                        {profileServices.map(
                            ({ title, image, actionType, action }) => (
                                <div
                                    className="discussion_work_project_profile__services__item"
                                    key={title}
                                    onClick={() =>
                                        actionType
                                            ? actionType === "navigate"
                                                ? action(specialistId, navigate)
                                                : action(
                                                      {
                                                          specialistId,
                                                          firstName,
                                                          lastName,
                                                          orderId,
                                                          taskId,
                                                          chatId,
                                                      },
                                                      dispatch,
                                                      status
                                                  )
                                            : action()
                                    }
                                >
                                    <div className="discussion_work_project_profile__services__item_img">
                                        {image}
                                    </div>
                                    <div className="discussion_work_project_profile__services__item_title">
                                        {t(title)}
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                </>
            ) : (
                ""
            )}
        </div>
    );
};

Profile.propTypes = {
    completedOrders: PropTypes.number,
    feedbackCount: PropTypes.number,
    firstName: PropTypes.string,
    createdAt: PropTypes.string,
    district: PropTypes.string,
    lastName: PropTypes.string,
    services: PropTypes.array,
    gender: PropTypes.string,
    rating: PropTypes.number,
    avatar: PropTypes.string,
    city: PropTypes.string,
    _id: PropTypes.string,
    age: PropTypes.number,
};

export default memo(Profile);
