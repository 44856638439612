import { promotionConstants } from "../../promotion.constants";

export const leftbarConfig = {
    condition: {
        type: promotionConstants.condition,
        title: "promotion.leftbar.condition",
    },
    active: {
        type: promotionConstants.acting,
        title: "promotion.leftbar.acting",
    },
    history: {
        type: promotionConstants.history,
        title: "promotion.leftbar.history",
    },
};
