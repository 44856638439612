import { useDispatch, useSelector } from "react-redux";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { roleSelector } from "../../pages/my-account/my-account.selectors";
import { serviceSelector } from "../../pages/all-services/all-services.selectors";
import { editSearchQuery } from "../all-result/all-result.operations";

import { resultTypes, sizeItemOnPage } from "../all-result/all-result.constant";
import { errorHandlerTypes } from "../error-handler/error-handler.constants";
import ButtonLoader from "../loader/components/button-loader/button-loader";
import { getLocalizedService } from "../../helpers/get-localized-service";
import useEventTracker from "../../hooks/use-event-tracker";
import useOutsideClick from "../../hooks/use-outside-click";
import ErrorHandler from "../error-handler/error-handler";
import { userRoles } from "../../constants/user-roles";
import routerBook from "../../router/router-book";

import "./index.scss";

const AutocompleteSearch = ({ buttonTitle }) => {
    const { subservices, services, loading } = useSelector(serviceSelector);
    const [display, setDisplay] = useState(false);
    const trackEvent = useEventTracker("search");
    const { role } = useSelector(roleSelector);
    const [query, setQuery] = useState("");
    const wrapperRef = useRef(null);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useOutsideClick(wrapperRef, () => setDisplay(false));

    const { allServicesResult, accessForSpecialist } = routerBook;
    const { SERVICES, ORDERS } = resultTypes;

    const searchButtonTextSelector = (role) => {
        switch (role) {
            case userRoles.customer:
                return t("profile_banner_title_search_customer");
            case userRoles.specialist:
                return t("profile_banner_title_search_specialist");
            default:
                return t("profile_banner_title_search_customer");
        }
    };

    const updateInputValue = (value) => {
        setDisplay(false);
        setQuery(getLocalizedService(value));
    };

    const handleSearch = () => {
        switch (role) {
            case userRoles.customer:
                if (query) {
                    navigate(allServicesResult, { state: true });
                    dispatch(editSearchQuery(sizeItemOnPage, query, SERVICES));
                    trackEvent("search_on_services", query);
                } else navigate(allServicesResult, { state: true });
                break;
            case userRoles.specialist:
                if (query) {
                    navigate(accessForSpecialist.newOrders, { state: true });
                    dispatch(editSearchQuery(sizeItemOnPage, query, ORDERS));
                    trackEvent("search_on_orders", query);
                } else navigate(accessForSpecialist.newOrders, { state: true });
                break;
            default:
                if (query) {
                    navigate(allServicesResult, { state: true });
                    dispatch(editSearchQuery(sizeItemOnPage, query, SERVICES));
                    trackEvent("search_on_services", query);
                } else navigate(allServicesResult, { state: true });
                break;
        }
    };

    const handlePressEnterKey = ({ key }) => key === "Enter" && handleSearch();
    const handleChangeName = ({ target: { value } }) => setQuery(value);

    const getCurrentService = (category) => {
        return services.find(({ _id }) => _id === category);
    };

    const foundedSubservices = () => {
        return subservices.filter(
            (subservice) =>
                getLocalizedService(subservice)
                    .toLowerCase()
                    .indexOf(query.toLowerCase()) > -1
        );
    };

    return (
        <div ref={wrapperRef} className="autocomplete-search">
            <div
                className={`autocomplete-search__block ${
                    display ? "active" : ""
                } `}
            >
                <input
                    className="autocomplete-search__block-input"
                    placeholder={t("input_search_question")}
                    onClick={() => setDisplay(true)}
                    onKeyPress={handlePressEnterKey}
                    onChange={handleChangeName}
                    value={query}
                />
                <button
                    className="autocomplete-search__block-btn"
                    onClick={handleSearch}
                >
                    {t(buttonTitle) || searchButtonTextSelector(role)}
                </button>
            </div>
            {display && (
                <div
                    className={`autocomplete-search__containter ${
                        loading ? "loading" : ""
                    }`}
                >
                    {loading ? (
                        <ButtonLoader size={50} />
                    ) : !!foundedSubservices().length ? (
                        foundedSubservices().map((subservice) => {
                            return (
                                <div
                                    className="autocomplete-search__containter-option"
                                    onClick={() => updateInputValue(subservice)}
                                    key={subservice._id}
                                >
                                    <div className="autocomplete-search__containter-option-title">
                                        {getLocalizedService(subservice)}{" "}
                                        {!!getCurrentService(
                                            subservice.category
                                        ) && (
                                            <span className="autocomplete-search__containter-option-subtitle">
                                                {t("profile_in_category")}{" "}
                                                <span className="autocomplete-search__containter-option-tag">
                                                    {`"${getLocalizedService(
                                                        getCurrentService(
                                                            subservice.category
                                                        )
                                                    )}"`}
                                                </span>
                                            </span>
                                        )}
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <ErrorHandler
                            type={errorHandlerTypes.NOT_FOUND_SEARCH}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

AutocompleteSearch.propTypes = { buttonTitle: PropTypes.string };
AutocompleteSearch.defaultProps = { buttonTitle: "" };

export default AutocompleteSearch;
