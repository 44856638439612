import { useSelector, useDispatch } from "react-redux";
import React, { Fragment, useEffect } from "react";

import { getUser } from "../my-account/my-account.operations";
import { getBalance } from "./balance.operations";
import { clearBalance } from "./balance.actions";
import { onboardingSelector } from "../my-account/my-account.selectors";

import BalanceHistory from "./components/balance-history/balance-history";
import ReactHelmet from "../../components/react-helmet/react-helmet";
import BalanceInfo from "./components/balance-info/balance-info";
import { isPermission } from "./balance.utils";

import "./index.scss";

const Balance = () => {
    const dispatch = useDispatch();

    const { onboarding } = useSelector(onboardingSelector);

    useEffect(() => {
        isPermission([onboarding.stripe])
            ? dispatch(getBalance())
            : dispatch(getUser());
        return () => dispatch(clearBalance());
    }, []);

    return (
        <Fragment>
            <ReactHelmet title="balance-title" />
            <div className="balance">
                <div className="balance__container">
                    <BalanceHistory />
                    <BalanceInfo />
                </div>
            </div>
        </Fragment>
    );
};

export default Balance;
