import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { memo } from "react";
import dayjs from "dayjs";

import { userRoles } from "../../../../constants/user-roles";
import { roleSelector } from "../../../../pages/my-account/my-account.selectors";

import LazyImage from "../../../lazy-image/lazy-image";
import FavoriteIcon from "../favorite-icon/favorite-icon";

import { typeOfLazeImage } from "../../../lazy-image/lazyImage.constansts";
import { getLocalizedService } from "../../../../helpers/get-localized-service";
import { getPhotoByTypeGender } from "../../../../helpers/get-photo-by-type-gender";
import { addRemoveFavoritesOrders } from "../../../../pages/all-services/all-services.operations";
import routerBook from "../../../../router/router-book";

import { ReactComponent as CurrencyLira } from "../../../../assets/images/currency/lira.svg";
import { ReactComponent as PopularIcon } from "../../../../assets/images/vectors/popular.svg";

import "./index.scss";

const SearchedOrder = (props) => {
    const {
        customer = {},
        priceTo = "",
        fixedPrice = "",
        priceFrom = "",
        createdAt = "",
        category,
        comment = "",
        clarification,
        subcategory,
        _id,
    } = props;
    const {
        avatar,
        gender,
        firstName,
        lastName,
        isOnline,
        popular,
        isFavorite,
        _id: customerId,
    } = customer;
    const { role } = useSelector(roleSelector);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    return (
        <div className="searched_order">
            <div className="searched_order__img">
                <span className="searched_order__img_avatar">
                    <LazyImage
                        image={getPhotoByTypeGender(avatar, gender)}
                        type={typeOfLazeImage.avatar}
                        options={{
                            title: isOnline ? t("online") : t("offline"),
                            className: "searched_order__img_avatar",
                            alt: "avatar",
                        }}
                    />
                    {isOnline && (
                        <span className="searched_order__img_avatar_online" />
                    )}
                </span>
            </div>
            <div className="searched_order__info">
                <div className="searched_order__header">
                    <div className="searched_order__header_info">
                        <div className="searched_order__header_info_day">
                            {dayjs(createdAt).format("DD")}{" "}
                            {t(
                                `full-months.${dayjs(createdAt).format("MMMM")}`
                            )}{" "}
                            {dayjs(createdAt).format("YYYY")}
                        </div>
                        <div className="searched_order__header_info_title">
                            {popular && (
                                <PopularIcon className="searched_order__header_info_title-popular" />
                            )}
                            {firstName} {lastName}
                        </div>
                        {fixedPrice ? (
                            <div className="searched_order__header_info_price">
                                {fixedPrice} <CurrencyLira />
                            </div>
                        ) : (
                            <div className="searched_order__header_info_price">
                                {priceFrom} - {priceTo} <CurrencyLira />
                            </div>
                        )}

                        <div className="searched_order__header_info_comment">
                            {comment}
                        </div>
                    </div>
                </div>
            </div>
            <div className="searched_order__right-block">
                {!!role && (
                    <FavoriteIcon
                        handleOnClick={() => {
                            dispatch(
                                addRemoveFavoritesOrders({
                                    userId: customerId,
                                    addOrRemove: !isFavorite,
                                })
                            );
                        }}
                        favorite={isFavorite}
                    />
                )}
                <button
                    className="searched_order__right-block-btn"
                    onClick={() => {
                        navigate(
                            `${routerBook.accessForSpecialist.newOrders}/${_id}`,
                            { state: true }
                        );
                    }}
                >
                    {role === userRoles.customer
                        ? t("all_service_call_to_work")
                        : t("btn_offer_cooperation")}
                </button>
            </div>
            <div className="searched_order__body">
                <div className="searched_order__body_tags">
                    <div className="searched_order__body_tags_list">
                        <div className="searched_order__body_tags_list_item">
                            {getLocalizedService(category)}
                        </div>
                        <div className="searched_order__body_tags_list_item">
                            {getLocalizedService(subcategory)}
                        </div>
                        <div className="searched_order__body_tags_list_item">
                            {getLocalizedService(clarification)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

SearchedOrder.propTypes = {
    priceFrom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    priceTo: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    fixedPrice: PropTypes.number,
    customer: PropTypes.object,
    createdAt: PropTypes.string,
    comment: PropTypes.string,
    category: PropTypes.object,
    clarification: PropTypes.object,
    subcategory: PropTypes.object,
    _id: PropTypes.string,
};

export default memo(SearchedOrder);
