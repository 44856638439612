import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import { sendLogoutRequest } from "../../../../../../services/requests/auth";

import { buttonLoaderSelector } from "../../../../../loader/loader.selectors";
import { changeButtonLoader } from "../../../../../loader/loader.actions";
import { myAccountSelector } from "../../../../../../pages/my-account/my-account.selectors";
import { logoutUser } from "../../../../../../pages/auth/auth.actions";

import ButtonLoader from "../../../../../loader/components/button-loader/button-loader";
import { typeOfLazeImage } from "../../../../../lazy-image/lazyImage.constansts";
import { getPhotoByTypeGender } from "../../../../../../helpers/get-photo-by-type-gender";
import routerBook from "../../../../../../router/router-book";
import LazyImage from "../../../../../lazy-image/lazy-image";
import { profileNavigation } from "./header-profile.config";

import arrow from "../../../../../../assets/images/arrows-icons/arrow_down.svg";
import "./index.scss";

const HeaderProfile = ({ isBanned, burgerHandler }) => {
    const [selectStatus, changeSelectStatus] = useState(false);
    const { status: buttonStatus, button: buttonLoading } =
        useSelector(buttonLoaderSelector);
    const {
        data: { gender, role, firstName, lastName },
        avatar,
    } = useSelector(myAccountSelector);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const toggleVisibleSelect = () => changeSelectStatus(!selectStatus);

    const handleLogout = () => {
        dispatch(changeButtonLoader({ status: true, button: "logout" }));
        sendLogoutRequest()
            .then(() => {
                dispatch(logoutUser());
                navigate(routerBook.home, { state: true });
            })
            .finally(() =>
                dispatch(changeButtonLoader({ status: false, button: "" }))
            );
    };

    const applyLogoutStyle = (classname = []) => {
        switch (true) {
            case isBanned:
                classname.push(" isBanned");
                break;

            case buttonStatus && buttonLoading === "logout":
                classname.push(" isLoading");
                break;

            default:
                classname;
                break;
        }
        return classname.join("");
    };

    return (
        role && (
            <>
                <div
                    onMouseEnter={toggleVisibleSelect}
                    onMouseLeave={toggleVisibleSelect}
                    className="header-profile"
                >
                    <LazyImage
                        image={getPhotoByTypeGender(avatar, gender)}
                        type={typeOfLazeImage.avatar}
                        options={{
                            className: "header-profile__photo",
                            alt: "avatar",
                        }}
                    />
                    <img
                        className={
                            selectStatus
                                ? "header-profile__arrow-active"
                                : "header-profile__arrow"
                        }
                        src={arrow}
                        alt="arrow"
                    />
                    <div className="header-profile__dropdown-item"></div>
                    {selectStatus && (
                        <div className="header-profile__dropdown">
                            {!isBanned && !!role && (
                                <div className="header-profile__dropdown-header">
                                    <LazyImage
                                        image={getPhotoByTypeGender(
                                            avatar,
                                            gender
                                        )}
                                        type={typeOfLazeImage.avatar}
                                        options={{
                                            className:
                                                "header-profile__dropdown-header__photo",
                                            alt: "avatar",
                                        }}
                                    />
                                    <div className="header-profile__dropdown-header__content">
                                        <span className="header-profile__dropdown-header__content-name">
                                            {firstName} {lastName}
                                        </span>
                                        <span className="header-profile__dropdown-header__content-role">
                                            {t(
                                                `my_account_${role.toLowerCase()}`
                                            )}
                                        </span>
                                    </div>
                                </div>
                            )}
                            {!isBanned &&
                                !!role &&
                                profileNavigation[role].map(
                                    ({ name, path, state }) => (
                                        <Link
                                            state={state}
                                            key={name + path}
                                            to={path}
                                        >
                                            <span className="header-profile__dropdown-item-title">
                                                {t(name)}
                                            </span>
                                        </Link>
                                    )
                                )}
                            <button
                                disabled={
                                    buttonStatus && buttonLoading === "logout"
                                }
                                className={applyLogoutStyle([
                                    "header-profile__dropdown-logout-btn",
                                ])}
                                onClick={handleLogout}
                            >
                                <span className="header-profile__dropdown-item-title">
                                    {buttonStatus &&
                                    buttonLoading === "logout" ? (
                                        <ButtonLoader size={24} />
                                    ) : (
                                        t("nav_log_out")
                                    )}
                                </span>
                            </button>
                        </div>
                    )}
                </div>
                <div className="mobile-header__profile">
                    {!isBanned &&
                        !!role &&
                        profileNavigation[role].map(({ name, path }) => (
                            <Link
                                key={name + path}
                                to={path}
                                className="mobile-header__navigation_link"
                                onClick={burgerHandler}
                            >
                                <span className="mobile-header__navigation_link-text">
                                    {t(name)}
                                </span>
                            </Link>
                        ))}
                    <button
                        onClick={() => {
                            burgerHandler();
                            handleLogout();
                        }}
                        disabled={buttonStatus && buttonLoading === "logout"}
                        className={applyLogoutStyle([
                            "mobile-header__navigation_link mobile-header__navigation_button",
                        ])}
                    >
                        <span className="mobile-header__navigation_link-text">
                            {buttonStatus && buttonLoading === "logout" ? (
                                <ButtonLoader size={32} />
                            ) : (
                                t("nav_log_out")
                            )}
                        </span>
                    </button>
                </div>
            </>
        )
    );
};

HeaderProfile.propTypes = {
    isBanned: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    burgerHandler: PropTypes.func,
};

export default HeaderProfile;
