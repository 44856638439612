import { Link, useNavigate } from "react-router-dom";
import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { myAccountSelector } from "../../../../pages/my-account/my-account.selectors";

import { typeOfLazeImage } from "../../../lazy-image/lazyImage.constansts";
import HeaderContent from "./components/header-content/header-content";
import { getPhotoByTypeGender } from "../../../../helpers/get-photo-by-type-gender";
import routerBook from "../../../../router/router-book";
import LazyImage from "../../../lazy-image/lazy-image";
import { Icon } from "../../../image/image";

import "./index.scss";

const Header = memo(({ isBanned, token }) => {
    const [isBurgerOpen, setIsBurgerOpen] = useState(false);
    const {
        data: { gender, role, city },
        avatar,
    } = useSelector(myAccountSelector);
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        if (isBurgerOpen) {
            document.body.classList.add("no-scroll");
        } else document.body.classList.remove("no-scroll");
    }, [isBurgerOpen]);

    return (
        <>
            <header className="header_container">
                <div className="header_container__header_block">
                    <div className="header_container__local_info">
                        <Link to={routerBook.home} state={true}>
                            <Icon type="mainLogo" />
                        </Link>
                        {!isBanned && token && (
                            <Link
                                className="header_container__location"
                                to="/change-location"
                            >
                                {t(city)}
                            </Link>
                        )}
                    </div>
                    <HeaderContent isBanned={isBanned} />
                </div>
            </header>
            <header className="mobile-header">
                <Link
                    onClick={() => setIsBurgerOpen(false)}
                    to={routerBook.home}
                    state={true}
                >
                    <Icon type="mainLogo" />
                </Link>
                <div className="burger">
                    {role && (
                        <LazyImage
                            image={getPhotoByTypeGender(avatar, gender)}
                            type={typeOfLazeImage.avatar}
                            options={{
                                alt: "avatar",
                                className: "header-profile__photo",
                                onClick: () =>
                                    navigate(routerBook.myAccount, {
                                        state: true,
                                    }),
                            }}
                        />
                    )}
                    <button
                        className={
                            "burger__button" +
                            " " +
                            (isBurgerOpen ? "burger__button_opened" : "")
                        }
                        onClick={() => {
                            setIsBurgerOpen((prev) => !prev);
                        }}
                    >
                        <span></span>
                    </button>
                </div>
                <div
                    className={
                        "burger__content" +
                        " " +
                        (isBurgerOpen ? "burger__content_opened" : "")
                    }
                >
                    <HeaderContent
                        isBanned={isBanned}
                        burgerHandler={() => setIsBurgerOpen(false)}
                    />
                </div>
            </header>
        </>
    );
});

Header.propTypes = {
    isBanned: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    token: PropTypes.PropTypes.string,
};
Header.displayName = "Header";
export default Header;
