import { Controller, useForm, useFormState } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { openModal } from "../../../../components/modal/modal.actions";
import { getPromotion } from "../../../promotion/promotion.operations";
import { promotionSelector } from "../../../promotion/promotion.selectors";
import { clearAllParams } from "../../../promotion/promotion.actions";

import { modalConstants } from "../../../../components/modal/modal.constants";
import { isEmptyObject } from "../../../../helpers/is-empty-object";
import { getLanguage } from "../../../../helpers/language";
import { promoteFormSchema } from "./promote-form.schema";
import Select from "../../../../components/select/select";
import { periods } from "../../../../constants/period";

import "./index.scss";

const PromoteForm = ({ details: { options } }) => {
    const {
        formState: { errors, isValid },
        handleSubmit,
        control,
    } = useForm({
        mode: "all",
        defaultValues: {},
        resolver: yupResolver(promoteFormSchema),
    });

    const { allPromotion } = useSelector(promotionSelector);
    const { dirtyFields } = useFormState({ control });
    const { value: language } = getLanguage();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!options) {
            dispatch(getPromotion(100));
            return () => dispatch(clearAllParams());
        }
    }, [options]);

    const handleFormSubmit = ({ promoteCondition = {} }) => {
        dispatch(
            openModal({
                isOpen: true,
                closeIcon: true,
                renderType: modalConstants.payPromote,
                details: {
                    _id: options[0]._id,
                    priceCount: promoteCondition.priceCount,
                    priceType: promoteCondition.priceType,
                },
            })
        );
    };

    const mapStatPricesToOptions = (array = []) => {
        return Object.keys(array)
            .map((key) => {
                return array[key].map(({ count, price }, index) => {
                    return {
                        label: `${count} ${t(periods[key].label)}, ${t(
                            "price"
                        )}: ${price}`,
                        value: count + price + index,
                        priceCount: count,
                        priceType: key,
                    };
                });
            })
            .flat();
    };

    return (
        <div className="promote-form">
            <h3 className="promote-form__title">
                {t("promotion.modal.selected-promotion")}:
            </h3>
            <h3 className="promote-form__title promote-form__title-promotion">
                {`"${options[0].nameTranslations[language]}"`}
            </h3>
            {options || allPromotion.count > 0 ? (
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <Controller
                        name="promoteCondition"
                        control={control}
                        render={({ field }) => (
                            <Select
                                {...field}
                                label="promotion.modal.select-promote-condition"
                                options={mapStatPricesToOptions(
                                    options[0].prices
                                )}
                                error={errors.promoteCondition?.message}
                                onSelect={(promoteCondition) =>
                                    field.onChange(promoteCondition)
                                }
                            />
                        )}
                    />
                    <button
                        disabled={!isValid || isEmptyObject(dirtyFields)}
                        className="promote-form__btn"
                        type="submit"
                    >
                        {t("btn_promote")}
                    </button>
                </form>
            ) : null}
        </div>
    );
};

PromoteForm.defaultProps = { details: { options: null } };
PromoteForm.propTypes = { details: PropTypes.object };
export default PromoteForm;
