import { getClientFavouritesRequest } from "../../services/requests/client";
import { getSpecialistFavouritesRequest } from "../../services/requests/specialist";
import { userAddRemoveFavorites } from "../../services/requests/auth";
import { successToastify } from "../../components/toastify/toastify.operations";
import * as favouritesActions from "./favourites.actions";
import { favouritesSelector } from "./favourites.selectors";

export const getClientFavourites =
    (sizeItemOnPage, params = "") =>
    (dispatch) => {
        dispatch(favouritesActions.getClientFavouritesRequest());

        return getClientFavouritesRequest(`?limit=${sizeItemOnPage}` + params)
            .then((data) => {
                dispatch(favouritesActions.getClientFavouritesSuccess(data));
            })
            .catch((error) =>
                dispatch(favouritesActions.getClientFavouritesError(error))
            );
    };

export const getSpecialistFavourites =
    (sizeItemOnPage, params = "") =>
    (dispatch) => {
        dispatch(favouritesActions.getSpecialistFavouritesRequest());

        return getSpecialistFavouritesRequest(
            `?limit=${sizeItemOnPage}` + params
        )
            .then((data) => {
                dispatch(
                    favouritesActions.getSpecialistFavouritesSuccess(data)
                );
            })
            .catch((error) =>
                dispatch(favouritesActions.getSpecialistFavouritesError(error))
            );
    };

export const editPageAllClientFavourites = (size, data) => (dispatch) => {
    dispatch(favouritesActions.setPage(`&page=${data}`));
    const url = `&page=${data}`;
    dispatch(getClientFavourites(size, url));
};

export const editPageAllSpecialistFavourites = (size, data) => (dispatch) => {
    dispatch(favouritesActions.setPage(`&page=${data}`));
    const url = `&page=${data}`;
    dispatch(getSpecialistFavourites(size, url));
};

export const addRemoveFavouritesCustomer = (data) => (dispatch, getState) => {
    dispatch(favouritesActions.addRemoveFavouritesRequest());
    const { favouritesList } = favouritesSelector(getState());

    return userAddRemoveFavorites(data)
        .then(({ message }) => {
            const newFavList = {
                ...favouritesList,
                items: favouritesList.items.map((item) =>
                    item._id === data.userId
                        ? {
                              ...item,
                              isFavorite: data.addOrRemove,
                          }
                        : item
                ),
            };
            dispatch(getClientFavourites(newFavList));
            dispatch(successToastify(message));
        })
        .catch((error) => {
            dispatch(favouritesActions.addRemoveFavouritesError(error));
        });
};

export const addRemoveFavouritesSpecialist = (data) => (dispatch, getState) => {
    dispatch(favouritesActions.addRemoveFavouritesRequest());
    const { favouritesList } = favouritesSelector(getState());

    return userAddRemoveFavorites(data)
        .then(({ message }) => {
            const newFavList = {
                ...favouritesList,
                items: favouritesList.items.map((item) =>
                    item._id === data.userId
                        ? {
                              ...item,
                              isFavorite: data.addOrRemove,
                          }
                        : item
                ),
            };
            dispatch(getSpecialistFavourites(newFavList));
            dispatch(successToastify(message));
        })
        .catch((error) => {
            dispatch(favouritesActions.addRemoveFavouritesError(error));
        });
};
