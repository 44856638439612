import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import Pagination from "../../../../components/pagination/pagination";
import SpecialistOrderListItem from "./components/specialist-order-list-item/specialist-order-list-item";
import SpecialistOrderListEmpty from "./components/specialist-order-list-empty/specialist-order-list-empty";

import { editPageAll } from "../../redux/specialist-orders.operations";
import { ordersPageSelector } from "../../redux/specialist-orders.selectors";
import {
    ORDERS_FILTERS_SPECIALIST,
    sizeItemsOnPage,
} from "../../specialist-orders.config";

const SpecialistOrderList = ({ orders = {}, filterParameter = "" }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const page = useSelector(ordersPageSelector);

    return (
        <>
            {orders.count >= 1 ? (
                orders.items.map((order, index) => (
                    <SpecialistOrderListItem
                        {...order}
                        key={index}
                        filterParameter={filterParameter}
                    />
                ))
            ) : (
                <SpecialistOrderListEmpty
                    text={
                        filterParameter ===
                        ORDERS_FILTERS_SPECIALIST.NEGOTIATION
                            ? t("orders.error.no-negotiation")
                            : t("orders.error.no-status-order")
                    }
                />
            )}
            <Pagination
                items={orders}
                elementType="page"
                sizeItemOnPage={sizeItemsOnPage}
                getNextPageItems={(e) => {
                    dispatch(editPageAll(sizeItemsOnPage, e));
                }}
                isStartedPage={page}
            />
        </>
    );
};
SpecialistOrderList.propTypes = {
    orders: PropTypes.object,
    filterParameter: PropTypes.string,
};
export default SpecialistOrderList;
