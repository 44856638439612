import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { closeModal } from "../../../../../components/modal/modal.actions";

import MainButton from "../../../../../components/main-button/main-button";
import routerBook from "../../../../../router/router-book";

import registrationUser from "../../../../../assets/images/auth/registration_user.svg";
import "./index.scss";

const AuthMain = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    return (
        <div className="auth_required_distributor">
            <div className="auth_required_distributor__content">
                <img src={registrationUser} alt="profile" />
                <h2 className="auth_required_distributor__title">
                    {t("title_profile_form")}
                </h2>
                <MainButton
                    type="button"
                    buttonSize="btn--large"
                    onClick={() => {
                        navigate(routerBook.auth.accountType, { state: true });
                        dispatch(closeModal());
                    }}
                >
                    {t("registration")}
                </MainButton>
                <MainButton
                    buttonStyle="btn--light"
                    buttonSize="btn--large"
                    onClick={() => {
                        navigate(routerBook.auth.login, { state: true });
                        dispatch(closeModal());
                    }}
                >
                    {t("sing_in")}
                </MainButton>
                <p className="auth_required_distributor__description">
                    {t("description_conditions_first")}
                    <Link
                        className="auth_required_distributor__description_link"
                        to={routerBook.termsAndConditions}
                        rel="noopener noreferrer"
                        target="_blank"
                        state={true}
                    >
                        {" "}
                        {t("conditions_of_use")}{" "}
                    </Link>
                    {t("description_conditions_last")}
                </p>
            </div>
        </div>
    );
};

export default AuthMain;
