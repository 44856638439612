import PropTypes from "prop-types";
import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Icon } from "../../../../components/image/image";
import { modalConstants } from "../../../../components/modal/modal.constants";
import { myAccountSelector } from "../../my-account.selectors";
import { openModal } from "../../../../components/modal/modal.actions";
import { genderTypes } from "../../../../constants/genders";

import "./index.scss";

const SelectIconForm = ({
    onChange,
    details: { onSelectIcon, onSendCroppedImage },
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const genders = useMemo(() => [
        { gender: "Man", label: "male" },
        { gender: "Woman", label: "female" },
    ]);

    const {
        avatar,
        avatars,
        data: { gender },
    } = useSelector(myAccountSelector);

    const currentGenderType = gender
        ? gender !== genderTypes.preferNotToTell
            ? gender.toLowerCase()
            : "male"
        : "male";

    const [currentGender, setCurrentGender] = useState(currentGenderType);
    const [icon, setIcon] = useState(avatar);
    const [upload, setUpload] = useState({
        mainState: "initial",
        imageUploaded: 0,
        selectedFile: null,
    });

    const handleCurrentGender = (label) => setCurrentGender(label);

    const handleResetUpload = () => {
        setUpload({
            ...upload,
            mainState: "initial",
            selectedFile: null,
            imageUploaded: 0,
        });
    };

    const handleChangeCustomIcon = (item) => {
        onSelectIcon(item);
        setIcon(item);
    };

    const handleUploadClick = (event) => {
        const file = event.target.files[0];
        if (!file) return;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            dispatch(
                openModal({
                    isOpen: true,
                    closeIcon: false,
                    renderType: modalConstants.cropAvatarImage,
                    details: {
                        file: [reader.result],
                        handleResetUpload,
                        handleSendCroppedImage: onSendCroppedImage,
                        onChange,
                    },
                })
            );
        };
    };

    return (
        <div className="select-icon-form">
            <h4 className="select-icon-form_title">
                {t("select_profile_screensaver")}
            </h4>
            {(gender === genderTypes.preferNotToTell) | !gender ? (
                <div className="select-icon-form__options">
                    {genders.map(({ gender, label }) => (
                        <div
                            key={label}
                            onClick={() => handleCurrentGender(label)}
                            className={`select-icon-form__options-item${
                                label === currentGender ? "--active" : ""
                            }`}
                        >
                            {gender}
                        </div>
                    ))}
                </div>
            ) : null}
            <div className="select-icon-form__items">
                {avatars[currentGender].map((item, index) => (
                    <div
                        key={item + index}
                        onClick={() => handleChangeCustomIcon(item)}
                        className={`select-icon-form__item${
                            icon === item ? "--active" : ""
                        }`}
                    >
                        <div className="select-icon-form__item-img">
                            <img src={item} />
                        </div>
                    </div>
                ))}
            </div>
            <label tmlfor="select-custom-icon">
                <div className="select-icon-form__upload">
                    {t("btn_upload_photo")}
                    <div className="select-icon-form__upload_img">
                        <Icon type="attachFile" />
                    </div>
                    <input
                        type="file"
                        accept="image/*"
                        id="select-custom-icon"
                        className="select-icon-form__upload_input"
                        onChange={handleUploadClick}
                    />
                </div>
            </label>
        </div>
    );
};
SelectIconForm.propTypes = {
    onChange: PropTypes.func,
    onSelect: PropTypes.func,
    reset: PropTypes.func,
    details: PropTypes.object,
    defaultImage: PropTypes.any,
    avatar: PropTypes.any,
    gender: PropTypes.string,
};
export default SelectIconForm;
