import * as yup from "yup";

export const selectLocation = yup.object().shape({
    city: yup
        .string()
        .nullable("validation_required")
        .required("validation_required"),
    district: yup
        .string()
        .nullable("validation_required")
        .required("validation_required"),
});
