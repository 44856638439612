import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

import { roleSelector } from "../../../../pages/my-account/my-account.selectors";

import routerBook from "../../../../router/router-book";
import Language from "../language/language";
import { Icon } from "../../../image/image";
import {
    footerLinks,
    footerNavsFirst,
    footerNavsSecond,
} from "./footer.config";

import "./index.scss";
import { memo } from "react";

const Footer = memo(() => {
    const { role } = useSelector(roleSelector);
    const { pathname } = useLocation();
    const { t } = useTranslation();

    const handleScrollToTop = (link) =>
        pathname === link && window.scroll({ top: 0, behavior: "smooth" });

    return (
        <footer className="footer">
            <div className="footer__content">
                <div className="footer__main">
                    <div className="footer__main-head">
                        <NavLink
                            className="footer__logo"
                            to={routerBook.home}
                            state={true}
                        >
                            <Icon type="mainLogo" />
                        </NavLink>
                        <Language />
                    </div>
                    <ul className="footer__social">
                        {footerLinks.map(({ title, link }) => (
                            <li key={link} className="footer__link">
                                <a href={link} target="_blank" rel="noreferrer">
                                    <Icon type={title} />
                                </a>
                            </li>
                        ))}
                    </ul>
                    <ul className="footer__navs footer__navs_first">
                        {footerNavsFirst[!!role].map(
                            ({ title, link, state }) => (
                                <li key={title} className="footer__nav">
                                    <NavLink
                                        onClick={() => handleScrollToTop(link)}
                                        state={state}
                                        to={link}
                                    >
                                        {t(title)}
                                    </NavLink>
                                </li>
                            )
                        )}
                    </ul>
                    <ul className="footer__navs footer__navs_second">
                        {footerNavsSecond[role || null].map(
                            ({ title, link, state }) => (
                                <li key={title} className="footer__nav">
                                    <NavLink
                                        onClick={() => handleScrollToTop(link)}
                                        state={state}
                                        to={link}
                                    >
                                        {t(title)}
                                    </NavLink>
                                </li>
                            )
                        )}
                    </ul>
                    <div className="footer__info">
                        <div className="footer__support">
                            <p className="footer__support_title">
                                {t("profile_footer_support")}{" "}
                            </p>
                            <a
                                href="mailto:https://support@wconnect.work"
                                target="_blanc"
                                className="footer__support_mail"
                            >
                                support@wconnect.work
                            </a>
                        </div>
                        <div className="footer__apps">
                            <a
                                target="_blanc"
                                href={routerBook.appLinks.googlePlay}
                                className="footer__app-link"
                            >
                                <Icon type="playmarket" />
                                <p>Google Play</p>
                            </a>
                            <a
                                target="_blanc"
                                href={routerBook.appLinks.appStore}
                                className="footer__app-link"
                            >
                                <Icon type="appStore" />
                                <p>App Store</p>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="footer__bottom">
                    <p className="footer__watermark">
                        © {dayjs(new Date()).format("YYYY")} wconnect.com
                    </p>
                    <NavLink
                        onClick={() =>
                            handleScrollToTop(routerBook.termsAndConditions)
                        }
                        to={routerBook.termsAndConditions}
                        className="footer__terms"
                        state={true}
                    >
                        {t("nav_terms_of_service")}
                    </NavLink>
                    <NavLink
                        onClick={() =>
                            handleScrollToTop(routerBook.privacyPolicy)
                        }
                        className="footer__policy"
                        to={routerBook.privacyPolicy}
                        state={true}
                    >
                        {t("nav_privacy_policy")}
                    </NavLink>
                </div>
            </div>
        </footer>
    );
});
Footer.displayName = "Footer";

export default Footer;
