import { useEffect } from "react";
import { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";

import { supportPageSelector, supportSelector } from "../../support.selectors";

import Pagination from "../../../../components/pagination/pagination";
import SupportSort from "../support-sort/support-sort";
import SupportContentItem from "../support-content-item/support-content-item";
import SupportContentEmpty from "../support-content-empty/support-content-empty";

import { sizeItemOnPage } from "../../support.constants";
import {
    editPageSupportQuestions,
    getAllSupportQuestions,
} from "../../support.operations";
import "./index.scss";
import { clearAllParams } from "../../support.actions";

const SupportContent = () => {
    const dispatch = useDispatch();
    const { supportList } = useSelector(supportSelector);
    const page = useSelector(supportPageSelector);

    useEffect(() => {
        dispatch(getAllSupportQuestions());

        return () => dispatch(clearAllParams());
    }, []);

    return (
        <Fragment>
            <div className="support-content">
                <SupportSort />
                {supportList.items.length >= 1 ? (
                    supportList.items.map((supportTicket) => (
                        <SupportContentItem
                            {...supportTicket}
                            key={supportTicket._id}
                        />
                    ))
                ) : (
                    <SupportContentEmpty />
                )}
            </div>
            {supportList.items.length >= 1 && (
                <Pagination
                    isStartedPage={page}
                    elementType="page"
                    items={supportList}
                    sizeItemOnPage={sizeItemOnPage}
                    getNextPageItems={(e) =>
                        dispatch(editPageSupportQuestions(e))
                    }
                />
            )}
        </Fragment>
    );
};

export default SupportContent;
