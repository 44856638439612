import specialistProfilePage from "./specialist-profile-page.json";
import specialistServices from "./specialist-services.json";
import authentification from "./authentification.json";
import termsOfService from "./terms-of-service.json";
import privacyPolicy from "./privacy-policy.json";
import notifications from "./notifications.json";
import errorHandler from "./error-handler.json";
import specialists from "./specialists.json";
import ordersPage from "./orders-page.json";
import validation from "./validation.json";
import myAccount from "./my-account.json";
import locations from "./locations.json";
import promotion from "./promotion.json";
import currency from "./currency.json";
import feedback from "./feedback.json";
import toastify from "./toastify.json";
import complain from "./complain.json";
import aboutUs from "./about-us.json";
import service from "./service.json";
import profile from "./profile.json";
import balance from "./balance.json";
import support from "./support.json";
import button from "./button.json";
import title from "./title.json";
import order from "./order.json";
import chats from "./chats.json";
import emogi from "./emogi.json";
import rest from "./rest.json";
import faq from "./faq.json";
import city from "./city.json";
import days from "./days.json";
import countryCodes from "./country-codes.json";

const translation = {
    ...specialistProfilePage,
    ...specialistServices,
    ...authentification,
    ...termsOfService,
    ...privacyPolicy,
    ...notifications,
    ...errorHandler,
    ...specialists,
    ...validation,
    ...ordersPage,
    ...myAccount,
    ...locations,
    ...promotion,
    ...complain,
    ...toastify,
    ...currency,
    ...feedback,
    ...support,
    ...balance,
    ...profile,
    ...service,
    ...aboutUs,
    ...button,
    ...chats,
    ...emogi,
    ...title,
    ...order,
    ...city,
    ...days,
    ...rest,
    ...faq,
    ...countryCodes,
};

export default translation;
