import { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Icon } from "../image/image";

import "./index.scss";

const Input = ({
    style = {},
    label,
    error = "",
    options = {},
    type = "primary",
    readOnly = false,
    inputChangeOptions = {},
    onKeyDownList = [],
    withIcon = false,
    iconType = "",
    onFocus = () => {},
    onChange = () => {},
    autoComplete = "on",
}) => {
    const {
        phone = false,
        minValue = "",
        typeText = "text",
        placeholderText = "",
        stepValue = "",
    } = options;

    const { t } = useTranslation();
    const [isShowPassword, setIsShowPassword] = useState(false);
    switch (type) {
        case "primary":
            return (
                <div className="form-container">
                    <div className="form-container__info">
                        <label className="form-container__label">
                            {t(label)}
                        </label>
                        <label className="form-container__error">
                            {error ? t(error) : ""}
                        </label>
                    </div>
                    <input
                        type={typeText}
                        min={minValue}
                        className={`form-container__input ${phone && "phone"}`}
                        {...inputChangeOptions}
                        placeholder={t(placeholderText)}
                        onKeyDown={(e) =>
                            onKeyDownList.includes(e.key)
                                ? e.preventDefault()
                                : ""
                        }
                        onFocus={onFocus}
                        onChange={onChange}
                        autoComplete={autoComplete}
                    />
                    {withIcon && (
                        <div className="form-container__with-icon">
                            <Icon type={iconType} />
                        </div>
                    )}
                </div>
            );
        case "secondary":
            return (
                <div className="form-container" style={style}>
                    <div className="form-container__info">
                        <label className="form-container__label">
                            {t(label)}
                        </label>
                        <label className="form-container__error">
                            {error ? t(error) : ""}
                        </label>
                    </div>
                    <input
                        type={typeText}
                        readOnly={readOnly}
                        className={`form-container__register_input${
                            readOnly ? "--disable" : ""
                        }${error ? "--error" : ""}`}
                        {...inputChangeOptions}
                        placeholder={t(placeholderText)}
                    />
                </div>
            );
        case "fixed-price":
            return (
                <div className="form-container" style={style}>
                    <div className="form-container__info">
                        {label && (
                            <label className="form-container__label">
                                {t(label)}
                            </label>
                        )}
                        {error && (
                            <label className="form-container__error">
                                {t(error)}
                            </label>
                        )}
                    </div>
                    <div className="form-container__fixed_price">
                        <input
                            type={typeText}
                            readOnly={readOnly}
                            className={`form-container__fixed_price_input${
                                readOnly ? "--disable" : ""
                            }${error ? "--error" : ""}`}
                            {...inputChangeOptions}
                            placeholder={t(placeholderText)}
                        />
                        <div
                            className={`form-container__fixed_price_currency${
                                readOnly ? "--disable" : ""
                            }${error ? "--error" : ""}`}
                        >
                            <Icon type="currencyLira" />
                        </div>
                    </div>
                </div>
            );
        case "password":
            return (
                <div className="form-container">
                    <div className="form-container__info">
                        <label className="form-container__label">
                            {t(label)}
                        </label>
                        <label className="form-container__error">
                            {error ? t(error) : ""}
                        </label>
                    </div>
                    <div
                        className={`form-container__password${
                            error ? "--error" : ""
                        }`}
                    >
                        <input
                            type={isShowPassword ? "text" : "password"}
                            className={`form-container__password_input`}
                            placeholder={t(placeholderText)}
                            {...inputChangeOptions}
                            autoComplete="off"
                        />
                        <div
                            className="form-container__password_image"
                            onClick={() => setIsShowPassword(!isShowPassword)}
                        >
                            <Icon
                                type={
                                    isShowPassword ? "eyeShowed" : "eyeHidden"
                                }
                            />
                        </div>
                    </div>
                </div>
            );
        case "phone":
            return (
                <div className="form-container">
                    <div className="form-container__info">
                        <label className="form-container__label">
                            {t(label)}
                        </label>
                        <label className="form-container__error">
                            {error ? t(error) : ""}
                        </label>
                    </div>
                    <input
                        type="phone"
                        className={`form-container__register_input${
                            readOnly ? "--disable" : ""
                        }${error ? "--error" : ""}`}
                        {...inputChangeOptions}
                        placeholder={t(placeholderText)}
                    />
                </div>
            );
        case "additionalInfo":
            return (
                <div className="form-container">
                    <div className="form-container__info">
                        <label className="form-container__label">
                            {t(label)}
                        </label>
                        <label className="form-container__error">
                            {error ? t(error) : ""}
                        </label>
                    </div>
                    <textarea
                        type={typeText}
                        min={minValue}
                        className={`form-container__register_textarea`}
                        {...inputChangeOptions}
                        placeholder={t(placeholderText)}
                    />
                </div>
            );
        case "amount":
            return (
                <div className="form-container">
                    <div className="form-container__info">
                        <label className="form-container__label">
                            {t(label)}
                        </label>
                        <label className="form-container__error">
                            {error ? t(error) : ""}
                        </label>
                    </div>
                    <input
                        step={stepValue}
                        type={typeText}
                        min={minValue}
                        className={`form-container__input ${phone && "phone"}`}
                        {...inputChangeOptions}
                        placeholder={t(placeholderText)}
                        onKeyDown={(e) =>
                            onKeyDownList.includes(e.key)
                                ? e.preventDefault()
                                : ""
                        }
                    />
                </div>
            );
        case "info":
            return (
                <div className="form-container">
                    <div className="form-container__info">
                        <label className="form-container__label">
                            {t(label)}
                        </label>
                        <label className="form-container__error">
                            {error ? t(error) : ""}
                        </label>
                    </div>
                    <input
                        disabled
                        {...inputChangeOptions}
                        className={`form-container__register_input--disable
                        `}
                        placeholder={t(placeholderText)}
                    />
                </div>
            );

        default:
            //primary
            <div className="form-container">
                <input
                    type={typeText}
                    min={minValue}
                    className={`form-container__input ${phone && "phone"}`}
                    {...inputChangeOptions}
                    placeholder={t(placeholderText)}
                />
            </div>;
    }
};

Input.propTypes = {
    onKeyDownList: PropTypes.array,
    error: PropTypes.string,
    label: PropTypes.string,
    blockedCursor: PropTypes.bool,
    options: PropTypes.object,
    type: PropTypes.string,
    readOnly: PropTypes.bool,
    inputChangeOptions: PropTypes.object,
    withIcon: PropTypes.bool,
    iconType: PropTypes.string,
    onFocus: PropTypes.func,
    onChange: PropTypes.func,
    style: PropTypes.func,
    autoComplete: PropTypes.string,
};

export default Input;
