import { getItem } from "../helpers/local-storage";

export const languageList = {
    en: {
        label: "ENG",
        value: "en",
    },
    ru: {
        label: "RU",
        value: "ru",
    },
    tr: {
        label: "TR",
        value: "tr",
    },
};

const checkLanguageForAvailability = (language = "") => {
    const isAvailable = Object.keys(languageList).includes(language);
    return isAvailable ? languageList[language] : languageList["ru"];
};

export const getLanguage = () => {
    const browserLanguage = navigator.language || navigator.userLanguage;
    const selectedLanguage = getItem("language");
    const language = selectedLanguage || browserLanguage;

    return checkLanguageForAvailability(language.substring(0, 2));
};
