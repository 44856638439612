import { get } from "../api";

// get blog list
export const getBlogListRequest = async (params = "") => {
    const url = "/blog/search" + params;

    try {
        const { data } = await get(url);

        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;

        throw { errorData, status };
    }
};

// get blog by id
export const getBlogRequest = async (id) => {
    const url = `/blog/${id}`;

    try {
        const { data } = await get(url);

        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;

        throw { errorData, status };
    }
};
