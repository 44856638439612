import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { createFilter } from "react-select";
import AsyncSelect from "react-select/async";
import PropTypes from "prop-types";

import { selectStyles, filterConfig } from "./country-codes-dropdown.config";
import { countryCodesSelector } from "../country-codes.selectors";
import { setCountryCode } from "../country-codes.actions";
import { closeModal } from "../../modal/modal.actions";
import MainButton from "../../main-button/main-button";
import { ReactComponent as LocationIcon } from "../../../assets/images/auth/location.svg";
import { ReactComponent as SearchIcon } from "../../../assets/images/vectors/search-loop.svg";

import "./index.scss";

const CountryCodesModal = ({ details }) => {
    const { setValue, phoneCodeName } = details;

    const [selectedOption, setSelectedOption] = useState(null);

    const { countryCodesList } = useSelector(countryCodesSelector);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { handleSubmit, control } = useForm();

    const handleChange = (event) => {
        setSelectedOption(event);
    };

    const submitHandler = () => {
        if (selectedOption) {
            dispatch(setCountryCode(selectedOption.value));
            setValue(phoneCodeName, selectedOption.value.dialCode);
            dispatch(closeModal());
        }
    };

    const promiseOptions = () =>
        new Promise((resolve) => {
            setTimeout(() => {
                resolve(
                    countryCodesList.items.map((item, index) => {
                        return {
                            label: item.name,
                            value: item,
                            key: index,
                        };
                    })
                );
            }, 1000);
        });

    return (
        <div className="country-codes-modal-outer-container">
            <div className="country-codes-modal">
                <LocationIcon />
                <p className="country-codes-modal__title">
                    {t("country_codes.title")}
                </p>
                <p className="country-codes-modal__description">
                    {t("country_codes.description")}
                </p>
                <form onSubmit={handleSubmit(submitHandler)}>
                    <div className="country-codes-modal__form">
                        <Controller
                            name="countryCode"
                            control={control}
                            render={() => (
                                <AsyncSelect
                                    placeholder="Find country"
                                    value={selectedOption}
                                    filterOption={createFilter(filterConfig)}
                                    loadOptions={promiseOptions}
                                    defaultOptions
                                    onChange={handleChange}
                                    components={{
                                        DropdownIndicator: () => (
                                            <div className="country-codes-modal__dropdown-search-icon">
                                                <SearchIcon />
                                            </div>
                                        ),
                                        IndicatorSeparator: () => null,
                                    }}
                                    styles={selectStyles}
                                    getOptionLabel={({ value }) => {
                                        return (
                                            <div className="country-codes-modal__dropdown-item">
                                                <img
                                                    src={value.flag}
                                                    alt="country flag"
                                                    className="country-codes-modal__dropdown-item-flag"
                                                />
                                                <span className="country-codes-modal__dropdown-item-name">
                                                    {value.name}
                                                </span>
                                                <span className="country-codes-modal__dropdown-item-code">
                                                    {value.dialCode}
                                                </span>
                                            </div>
                                        );
                                    }}
                                />
                            )}
                        />
                    </div>
                    <MainButton type="submit" buttonSize="btn--stretch">
                        {t("country_codes.button")}
                    </MainButton>
                </form>
            </div>
        </div>
    );
};

CountryCodesModal.propTypes = {
    details: PropTypes.shape({
        setValue: PropTypes.func,
        phoneCodeName: PropTypes.string,
    }),
};

export default CountryCodesModal;
