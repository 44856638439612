export const chatTypes = {
    // get chat list
    GET_CHAT_LIST_PENDING: "chats/GET_CHAT_LIST_PENDING",
    GET_CHAT_LIST_FULFILLED: "chats/GET_CHAT_LIST_FULFILLED",
    GET_CHAT_LIST_REJECTED: "chats/GET_CHAT_LIST_REJECTED",

    // get chat
    GET_CHAT_PENDING: "chats/GET_CHAT_PENDING",
    GET_CHAT_FULFILLED: "chats/GET_CHAT_FULFILLED",
    GET_CHAT_REJECTED: "chats/GET_CHAT_REJECTED",

    // post chat
    POST_CHAT_PENDING: "chats/POST_CHAT_PENDING",
    POST_CHAT_FULFILLED: "chats/POST_CHAT_FULFILLED",
    POST_CHAT_REJECTED: "chats/POST_CHAT_REJECTED",

    // delete chat
    DELETE_CHAT_PENDING: "chats/DELETE_CHAT_PENDING",
    DELETE_CHAT_FULFILLED: "chats/DELETE_CHAT_FULFILLED",
    DELETE_CHAT_REJECTED: "chats/DELETE_CHAT_REJECTED",

    // disable chat
    DISABLE_CHAT_PENDING: "chats/DISABLE_CHAT_PENDING",
    DISABLE_CHAT_FULFILLED: "chats/DISABLE_CHAT_FULFILLED",
    DISABLE_CHAT_REJECTED: "chats/DISABLE_CHAT_REJECTED",

    // post chat file
    POST_CHAT_FILE_PENDING: "chats/POST_CHAT_FILE_PENDING",
    POST_CHAT_FILE_FULFILLED: "chats/POST_CHAT_FILE_FULFILLED",
    POST_CHAT_FILE_REJECTED: "chats/POST_CHAT_FILE_REJECTED",

    // get complaints types
    GET_COMPLAINTS_TYPES_PENDING: "chats/GET_COMPLAINTS_TYPES_PENDING",
    GET_COMPLAINTS_TYPES_FULFILLED: "chats/GET_COMPLAINTS_TYPES_FULFILLED",
    GET_COMPLAINTS_TYPES_REJECTED: "chats/GET_COMPLAINTS_TYPES_REJECTED",

    // post complaint
    POST_COMPLAINT_PENDING: "chats/POST_COMPLAINT_PENDING",
    POST_COMPLAINT_FULFILLED: "chats/POST_COMPLAINT_FULFILLED",
    POST_COMPLAINT_REJECTED: "chats/POST_COMPLAINT_REJECTED",

    // set
    SET_COMPLAIN_STEP: "chats/SET_COMPLAIN_STEP",
    CLEAR_ALL_PARAMS: "chats/CLEAR_ALL_PARAMS",
    SET_NAME: "chats/SET_NAME",
    SET_PAGE: "chats/SET_PAGE",
};
