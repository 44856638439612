export const blogActionTypes = {
    // get blog list
    GET_BLOG_LIST_REQUEST: "blog/GET_BLOG_LIST_REQUEST",
    GET_BLOG_LIST_SUCCESS: "blog/GET_BLOG_LIST_SUCCESS",
    GET_BLOG_LIST_ERROR: "blog/GET_BLOG_LIST_ERROR",

    // get blog by id
    GET_BLOG_REQUEST: "blog/GET_BLOG_REQUEST",
    GET_BLOG_SUCCESS: "blog/GET_BLOG_SUCCESS",
    GET_BLOG_ERROR: "blog/GET_BLOG_ERROR",

    // set
    CLEAR_ALL_PARAMS: "blog/CLEAR_ALL_PARAMS",
    SET_PAGE: "blog/SET_PAGE",
    SET_SEARCH_QUERY: "blog/SET_SEARCH_QUERY",
};
