import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import FavouriteIcon from "../../../../components/all-result/components/favorite-icon/favorite-icon";
import { ReactComponent as PopularIcon } from "../../../../assets/images/vectors/popular.svg";
import { ReactComponent as RateIcon } from "../../../../assets/images/specialist-profile/star.svg";
import { userRoles } from "../../../../constants/user-roles";
import { genderTypes } from "../../../../constants/genders";
import { parseRaitingValue } from "../../favourites.utils";
import { openModal } from "../../../../components/modal/modal.actions";
import { modalConstants } from "../../../../components/modal/modal.constants";
import {
    addRemoveFavouritesCustomer,
    addRemoveFavouritesSpecialist,
} from "../../favourites.operations";
import routerBook from "../../../../router/router-book";

import maleImage from "../../../../assets/images/specialists/male.png";
import femaleImage from "../../../../assets/images/specialists/female.png";
import unknownGenderImage from "../../../../assets/images/specialists/undefined.png";

import "./index.scss";

const FavouritesItem = ({
    firstName,
    lastName,
    role,
    gender,
    popular,
    rating,
    city,
    district,
    avatar,
    _id,
    isFavourite,
}) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const renderGenderImage = (gender) => {
        switch (gender) {
            case genderTypes.male:
                return maleImage;
            case genderTypes.female:
                return femaleImage;
            default:
                return unknownGenderImage;
        }
    };

    const renderUserRole = (role) => {
        switch (role) {
            case userRoles.customer:
                return t("my_account_customer");
            case userRoles.specialist:
                return t("my_account_specialist");
            default:
                return;
        }
    };

    const handleConnectToUser = () => {
        dispatch(
            openModal({
                isOpen: true,
                closeIcon: true,
                renderType: modalConstants.selectCustomerOrder,
                details: _id,
            })
        );
    };

    return (
        <div className="favourites-item">
            <div className="favourites-item__icon">
                <FavouriteIcon
                    handleOnClick={() => {
                        role === userRoles.specialist
                            ? dispatch(
                                  addRemoveFavouritesCustomer({
                                      userId: _id,
                                      addOrRemove: !isFavourite,
                                  })
                              )
                            : dispatch(
                                  addRemoveFavouritesSpecialist({
                                      userId: _id,
                                      addOrRemove: !isFavourite,
                                  })
                              );
                    }}
                    favorite={isFavourite}
                    favPage
                />
            </div>
            <div className="favourites-item__avatar">
                <img
                    src={avatar ? avatar : renderGenderImage(gender)}
                    alt="avatar"
                />
            </div>
            <div className="favourites-item__name">
                {popular && <PopularIcon />}
                <span className="favourites-item__name-first">
                    {firstName}
                </span>{" "}
                <span>{lastName}</span>
            </div>
            <div className="favourites-item__role">
                <span>{renderUserRole(role)}</span>
            </div>
            {role === userRoles.specialist && (
                <div className="favourites-item__rate">
                    <span>{parseRaitingValue(rating)}</span>
                    <div className="favourites-item__rate-icon">
                        <RateIcon />
                    </div>
                </div>
            )}
            <div className="favourites-item__location">
                <div>
                    <span className="favourites-item__location-key">
                        {t("specialist-services.service.city")}:{" "}
                    </span>
                    <span className="favourites-item__location-value">
                        {city}
                    </span>
                </div>
                <div>
                    <span className="favourites-item__location-key">
                        {t("specialist-services.service.district")}:{" "}
                    </span>
                    <span className="favourites-item__location-value">
                        {district ? district : "Unknown"}
                    </span>
                </div>
            </div>
            {role === userRoles.specialist ? (
                <div className="favourites-item__specialist-buttons">
                    <button
                        className="favourites-item__button"
                        onClick={handleConnectToUser}
                    >
                        {t("specialist-profile.profile.contact-button")}
                    </button>
                    <button
                        className="favourites-item__button favourites-item__button-questionnaire"
                        onClick={() =>
                            navigate(`${routerBook.specialistsList}/${_id}`, {
                                state: true,
                            })
                        }
                    >
                        {t("chats_actions.questionnaire")}
                    </button>
                </div>
            ) : (
                <button
                    className="favourites-item__button favourites-item__button-customer"
                    onClick={handleConnectToUser}
                >
                    {t("specialist-profile.profile.contact-button")}
                </button>
            )}
        </div>
    );
};

FavouritesItem.propTypes = {
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    role: PropTypes.string,
    gender: PropTypes.string,
    popular: PropTypes.bool,
    rating: PropTypes.number,
    city: PropTypes.string,
    district: PropTypes.string,
    avatar: PropTypes.string,
    _id: PropTypes.string,
    isFavourite: PropTypes.bool,
};

export default FavouritesItem;
