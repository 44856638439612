import { Fragment } from "react";
import { t } from "i18next";
import dayjs from "dayjs";

import { openModal } from "../../../../components/modal/modal.actions";
import { putTaskStatus } from "../../../orders/orders.operations";

import { operationStatus } from "./components/chat-message/chat-message.config";
import { modalConstants } from "../../../../components/modal/modal.constants";
import ChatMessage from "./components/chat-message/chat-message";
import { userRoles } from "../../../../constants/user-roles";
import { typeServices } from "./chat.config";

import "./index.scss";

const convertDate = (date) => {
    const dateSplit = date && date.split(" ");
    const fullDay = date && `${t(`full-days.${dateSplit[0]}`)}`;
    const day = date && dateSplit[1];
    const month = date && `${t(`full-months.${dateSplit[2]}`)}`;
    const year = date && dateSplit[3];
    return { fullDay, day, month, year };
};

const renderMessages = (
    messages,
    participant,
    userId,
    orderId,
    taskId,
    callback,
    carousel
) => {
    let lastSenderId = undefined;

    return messages.reverse().map((message, index) => {
        let same = !lastSenderId || message.createdBy !== lastSenderId;
        lastSenderId = message.messageType !== "operation" && message.createdBy;

        if (index === messages.length - 1) {
            return renderMessage(
                message,
                same,
                participant,
                userId,
                orderId,
                taskId,
                callback,
                carousel
            );
        }

        return renderMessage(
            message,
            same,
            participant,
            userId,
            orderId,
            taskId,
            () => {},
            carousel
        );
    });
};

const renderMessage = (
    message,
    same = true,
    participant,
    userId,
    orderId,
    taskId,
    callback,
    carousel
) => {
    const { fullDay, day, month, year } = convertDate(message.date);
    return (
        <Fragment key={message._id + message?.createdAt}>
            {message.type ? (
                <span className="chat_body_date">
                    {fullDay}, {day} {month}, {year} {t("short-year")}
                </span>
            ) : (
                <ChatMessage
                    key={message._id}
                    participant={participant}
                    callback={callback}
                    carousel={carousel}
                    orderId={orderId}
                    userId={userId}
                    taskId={taskId}
                    same={same}
                    {...message}
                />
            )}
        </Fragment>
    );
};

const groupedDays = (messages) => {
    return messages.reduce((acc, message) => {
        const messageDay = dayjs(message.createdAt).format("dddd DD MMMM YYYY");

        if (acc[messageDay]) {
            return { ...acc, [messageDay]: acc[messageDay].concat([message]) };
        }
        return { ...acc, [messageDay]: [message] };
    }, {});
};

export const generateItems = (
    messages,
    participant,
    userId,
    orderId,
    taskId,
    callback,
    carousel
) => {
    const days = groupedDays(messages);
    const sortedDays = Object.keys(days).sort(
        (firstMessage, secondMessage) =>
            dayjs(secondMessage, "YYYY-MM-DD").unix() -
            dayjs(firstMessage, "YYYY-MM-DD").unix()
    );
    const items = sortedDays.reduce((acc, date) => {
        const sortedMessages = days[date].sort(
            (firstMessage, secondMessage) =>
                new Date(secondMessage.createdAt) -
                new Date(firstMessage.createdAt)
        );
        return acc.concat([...sortedMessages, { type: "day", date }]);
    }, []);
    return renderMessages(
        items,
        participant,
        userId,
        orderId,
        taskId,
        callback,
        carousel
    );
};

export const handleServiceType = (type, dispatch, details, role) => {
    const { orderId, taskId } = details;
    switch (type) {
        case typeServices.proposePrice:
            role === userRoles.customer
                ? dispatch(
                      openModal({
                          isOpen: true,
                          closeIcon: true,
                          renderType: modalConstants.proposedOrderAmount,
                          details,
                      })
                  )
                : dispatch(
                      openModal({
                          isOpen: true,
                          closeIcon: true,
                          renderType: modalConstants.agreeWithCommission,
                          details: {
                              orderId,
                              taskId,
                              type: operationStatus.PROPOSED_FIX_PRICE,
                          },
                      })
                  );
            break;

        case typeServices.approveOrder:
            return dispatch(
                openModal({
                    isOpen: true,
                    closeIcon: true,
                    renderType: modalConstants.agreeWithCommission,
                    details: {
                        orderId,
                        taskId,
                        type: operationStatus.CONFIRM_START_WORK,
                    },
                })
            );
        case typeServices.closeOrder:
            return dispatch(putTaskStatus(orderId, taskId, "complete"));

        default:
            break;
    }
};

export const addEmoji = (e, ref, message, setMessageText) => {
    let sym = e.unified.split("-");
    const codesArray = [];
    sym.forEach((el) => codesArray.push("0x" + el));
    const cursor = ref.current.selectionStart;
    const emoji = String.fromCodePoint(...codesArray);
    const text = message.slice(0, cursor) + emoji + message.slice(cursor);
    setMessageText(text);
};
