import { setToastifyStatus } from "./toastify.actions";

export const errorToastify = (title) => (dispatch) => {
    dispatch(setToastifyStatus({ title, type: "error" }));
};

export const errorHtmlToastify = (html) => (dispatch) => {
    dispatch(setToastifyStatus({ html, type: "error" }));
};

export const successToastify = (title) => (dispatch) => {
    dispatch(setToastifyStatus({ title, type: "success" }));
};
