import React, { memo, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { serviceSelector } from "../../../../pages/all-services/all-services.selectors";
import { editSearchQuery, removeText } from "../../all-result.operations";
import { allResultParamsSelector } from "../../all-result.selectors";

import { getLocalizedService } from "../../../../helpers/get-localized-service";
import { errorHandlerTypes } from "../../../error-handler/error-handler.constants";
import ButtonLoader from "../../../loader/components/button-loader/button-loader";
import useOutsideClick from "../../../../hooks/use-outside-click";
import useEventTracker from "../../../../hooks/use-event-tracker";
import ErrorHandler from "../../../error-handler/error-handler";
import { sizeItemOnPage } from "../../all-result.constant";

import { ReactComponent as DeleteIcon } from "../../../../assets/images/vectors/delete_icon.svg";
import "./index.scss";

const Search = ({ type }) => {
    const { subservices, services, loading } = useSelector(serviceSelector);
    const { text } = useSelector(allResultParamsSelector);
    const [display, setDisplay] = useState(false);
    const trackEvent = useEventTracker("search");
    const [query, setQuery] = useState("");
    const wrapperRef = useRef(null);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => text && setQuery(text.split("=")[1]), []);
    useOutsideClick(wrapperRef, () => setDisplay(false));

    const handleSearch = () => {
        setDisplay(false);
        if (!query && !text) return;
        if (!query) return dispatch(removeText(type, setQuery));
        dispatch(editSearchQuery(sizeItemOnPage, query, type));
        trackEvent(`search_on_${type.toLowerCase()}`, query);
    };

    const updateInputValue = (value) => {
        setDisplay(false);
        setQuery(getLocalizedService(value));
    };

    const getCurrentService = (category) => {
        return services.find(({ _id }) => _id === category);
    };

    const foundedSubservices = () => {
        return subservices.filter(
            (subservice) =>
                getLocalizedService(subservice)
                    .toLowerCase()
                    .indexOf(query.toLowerCase()) > -1
        );
    };

    return (
        <div
            className={`all_result__search ${display ? "active" : ""} `}
            ref={wrapperRef}
        >
            <div className="all_result__search_block">
                <input
                    onKeyPress={({ key }) => key === "Enter" && handleSearch()}
                    onChange={({ target: { value } }) => setQuery(value)}
                    className="all_result__search_block_input"
                    placeholder={t("input_search_question")}
                    onClick={() => setDisplay(true)}
                    value={query}
                ></input>
                <button
                    disabled={!query || query === text.split("=")[1]}
                    className="all_result__search_block_btn"
                    onClick={handleSearch}
                >
                    {t("btn_search")}
                </button>
                <DeleteIcon
                    onClick={() => text && dispatch(removeText(type, setQuery))}
                    className={`all_result__search_block_remove ${
                        !text ? "disabled" : ""
                    }`}
                />
            </div>
            {display && (
                <div
                    className={`all_result__search_container ${
                        loading ? "loading" : ""
                    }`}
                >
                    {loading ? (
                        <ButtonLoader size={50} />
                    ) : !!foundedSubservices().length ? (
                        foundedSubservices().map((subservice) => {
                            return (
                                <div
                                    className="all_result__search_container-option"
                                    onClick={() => updateInputValue(subservice)}
                                    key={subservice._id}
                                >
                                    <div className="all_result__search_container-option-title">
                                        {getLocalizedService(subservice)}{" "}
                                        {!!getCurrentService(
                                            subservice.category
                                        ) && (
                                            <span className="all_result__search_container-option-subtitle">
                                                {t("profile_in_category")}{" "}
                                                <span className="all_result__search_container-option-tag">
                                                    {`"${getLocalizedService(
                                                        getCurrentService(
                                                            subservice.category
                                                        )
                                                    )}"`}
                                                </span>
                                            </span>
                                        )}
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <ErrorHandler
                            type={errorHandlerTypes.NOT_FOUND_SEARCH}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

Search.propTypes = { type: PropTypes.string };
export default memo(Search);
