import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { closeModal } from "../../../modal/modal.actions";

import "./index.scss";

const RegisterToView = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handelCancelPropose = (e) => {
        e.preventDefault();
        dispatch(closeModal());
    };

    return (
        <div className="register-to-view">
            <h5 className="register-to-view_title">{t("register-to-view")} !</h5>
            <div className="register-to-view_services">
                <button
                    className="register-to-view_services_cancel"
                    onClick={handelCancelPropose}
                >
                    {t("btn_close")}
                </button>
            </div>
        </div>
    );
};

export default RegisterToView;
