import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import "./index.scss";
import { closeModal } from "../../../../components/modal/modal.actions";
import { deleteUser } from "../../my-account.operations";
import ButtonLoader from "../../../../components/loader/components/button-loader/button-loader";
import { buttonLoaderSelector } from "../../../../components/loader/loader.selectors";
import { useNavigate } from "react-router-dom";

const DeleteAccountForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { status: buttonStatus, button: buttonLoading } =
        useSelector(buttonLoaderSelector);
    const handleOnCancel = () => dispatch(closeModal());
    const handleOnDelete = () => dispatch(deleteUser(navigate));

    return (
        <div className="delete-form">
            <div className="delete-form__title">
                {t("want_to_delete_account")}
            </div>
            <div className="delete-form__btns">
                <button
                    className="delete-form__btns-cancel"
                    onClick={handleOnCancel}
                >
                    {t("btn_cancel")}
                </button>
                <button
                    onClick={handleOnDelete}
                    className="delete-form__btns-delete"
                    style={{
                        background:
                            buttonStatus &&
                            buttonLoading === "delete-account" &&
                            "#dfdfdf",
                    }}
                    type="submit"
                >
                    {buttonStatus && buttonLoading === "delete-account" ? (
                        <ButtonLoader size={38} />
                    ) : (
                        t("btn_delete")
                    )}
                </button>
            </div>
        </div>
    );
};

export default DeleteAccountForm;
