import {
    getAllCitiesRequest,
    getAllDistrictsRequest,
} from "../../services/requests/cities";

import * as citiesActions from "./cities-loader.actions";
export const getAllCities = () => (dispatch) => {
    dispatch(citiesActions.getAllCitiesPending());

    return getAllCitiesRequest()
        .then((cities) =>
            dispatch(citiesActions.getAllCitiesFulfilled(cities.items))
        )
        .catch((error) => dispatch(citiesActions.getAllCitiesRejected(error)));
};

export const getAllDistricts = (state_code, cityName) => (dispatch) => {
    dispatch(citiesActions.getAllDistrictsPending());

    return getAllDistrictsRequest(state_code, cityName)
        .then((districts) =>
            dispatch(citiesActions.getAllDistrictsFulfilled(districts.items))
        )
        .catch((error) =>
            dispatch(citiesActions.getAllDistrictsRejected(error))
        );
};
