import { promotionTypes } from "./promotion.types";

// get promotion
export const getPromotionPending = () => ({
    type: promotionTypes.GET_PROMOTION_PENDING,
});

export const getPromotionFulfilled = (data) => ({
    type: promotionTypes.GET_PROMOTION_FULFILLED,
    payload: data,
});

export const getPromotionRejected = (error) => ({
    type: promotionTypes.GET_PROMOTION_REJECTED,
    payload: error,
});

// get promotion by id
export const getPromotionByIdPending = () => ({
    type: promotionTypes.GET_PROMOTION_BY_ID_PENDING,
});

export const getPromotionByIdFulfilled = (data) => ({
    type: promotionTypes.GET_PROMOTION_BY_ID_FULFILLED,
    payload: data,
});

export const getPromotionByIdRejected = (error) => ({
    type: promotionTypes.GET_PROMOTION_BY_ID_REJECTED,
    payload: error,
});

// buy promotion
export const buyPromotionPending = () => ({
    type: promotionTypes.BUY_PROMOTION_PENDING,
});

export const buyPromotionFulfilled = (data) => ({
    type: promotionTypes.BUY_PROMOTION_FULFILLED,
    payload: data,
});

export const buyPromotionRejected = (error) => ({
    type: promotionTypes.BUY_PROMOTION_REJECTED,
    payload: error,
});

// get promotion history
export const getPromotionHistoryPending = () => ({
    type: promotionTypes.GET_PROMOTION_HISTORY_PENDING,
});

export const getPromotionHistoryFulfilled = (data) => ({
    type: promotionTypes.GET_PROMOTION_HISTORY_FULFILLED,
    payload: data,
});

export const getPromotionHistoryRejected = (error) => ({
    type: promotionTypes.GET_PROMOTION_HISTORY_REJECTED,
    payload: error,
});

// deactivate promotion
export const deactivatePromotionPending = () => ({
    type: promotionTypes.DEACTIVATE_PROMOTION_PENDING,
});

export const deactivatePromotionFulfilled = (data) => ({
    type: promotionTypes.DEACTIVATE_PROMOTION_FULFILLED,
    payload: data,
});

export const deactivatePromotionRejected = (error) => ({
    type: promotionTypes.DEACTIVATE_PROMOTION_REJECTED,
    payload: error,
});

// set
export const clearAllParams = () => ({
    type: promotionTypes.CLEAR_ALL_PARAMS,
});

export const setPage = (data) => ({
    type: promotionTypes.SET_PAGE,
    payload: data,
});

export const setSearchQuery = (data) => ({
    type: promotionTypes.SET_SEARCH_QUERY,
    payload: data,
});

export const addPromotionHistoryItem = (data) => ({
    type: promotionTypes.ADD_PROMOTION,
    payload: data,
});

export const setActivePromotionsPage = (data) => ({
    type: promotionTypes.SET_ACTIVE_PROMOTIONS_PAGE,
    payload: data,
});

export const setHistoryPage = (data) => ({
    type: promotionTypes.SET_HISTORY_PAGE,
    payload: data,
});
