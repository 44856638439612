import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { closeModal } from "../../../../../components/modal/modal.actions";
import MainButton from "../../../../../components/main-button/main-button";

import "./index.scss";

const RestoreAccountSuccess = () => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    return (
        <div className="restore-account-success">
            <p className="restore-account-success__title">
                {t("account_restored")}
            </p>
            <div className="restore-account-success__btn">
                <MainButton
                    buttonSize="btn--stretch"
                    buttonStyle="btn--green"
                    onClick={() => dispatch(closeModal())}
                >
                    OK
                </MainButton>
            </div>
        </div>
    );
};

export default RestoreAccountSuccess;
