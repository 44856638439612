import { t } from "i18next";

import { errorHandlerSelector } from "./error-handler.selectors";
import { errorToastify } from "../toastify/toastify.operations";
import {
    notEnoughFundsError,
    disabledChatError,
    emailVerifyErrors,
} from "./error-handler.constants";
import { openModal } from "../modal/modal.actions";

import { modalConstants } from "../modal/modal.constants";
import routerBook from "../../router/router-book";

export const setErrorHandler =
    ({ status, data: { error } }) =>
    (dispatch, getState) => {
        const { navigate } = errorHandlerSelector(getState());

        switch (status) {
            case 400:
                if (error.startsWith(emailVerifyErrors.veryOften)) {
                    return dispatch(errorToastify(t("too_many_tries")));
                }
                if (error.includes("is not allowed")) {
                    return dispatch(
                        errorToastify(
                            t("toastify.my-account.provide_email_or_phone")
                        )
                    );
                }
                return dispatch(errorToastify(error));
            case 409:
            case 424:
                if (error === notEnoughFundsError) {
                    return dispatch(
                        errorToastify(t("error_handler_not_enough_funds"))
                    );
                }
                if (error === disabledChatError) {
                    return dispatch(
                        errorToastify(t("error_handler_chat_disabled"))
                    );
                }
                return dispatch(errorToastify(error));

            case 403:
                return navigate(routerBook.errors.accessDeniedError, {
                    state: true,
                });

            case 404:
                if (
                    error.includes("is deleted, restore account or create new")
                ) {
                    return dispatch(
                        openModal({
                            isOpen: true,
                            closeIcon: false,
                            renderType: modalConstants.restoreAccountRequest,
                        })
                    );
                } else
                    return navigate(routerBook.errors.notFound, {
                        state: true,
                    });

            case 410:
                return dispatch(
                    openModal({
                        isOpen: true,
                        closeIcon: false,
                        renderType: modalConstants.restoreAccountRequest,
                    })
                );

            case 423:
                return dispatch(
                    errorToastify(t("error_handler_sms_service_not_available"))
                );

            default: {
                if (status > 401) {
                    return navigate(routerBook.errors.somethingWrong, {
                        state: true,
                    });
                }
            }
        }
    };
