import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm, useFormState } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";

import { citiesLoaderSelector } from "../../../../components/cities-loader/cities-loader.selectors";
import { buttonLoaderSelector } from "../../../../components/loader/loader.selectors";
import { openModal } from "../../../../components/modal/modal.actions";
import {
    deleteUserAvatar,
    getAvatars,
    getUser,
    putUserAccount,
    putUserAvatar,
    getPhoneVerificationCode,
    getEmailVerificationCode,
    oauthConnect,
} from "../../my-account.operations";
import { myAccountSelector } from "../../my-account.selectors";

import SELECT_TYPES from "../../../../components/select/select.constants";
import { userRoles } from "../../../../constants/user-roles";
import { modalConstants } from "../../../../components/modal/modal.constants";
import { myAccountFormSchema } from "./my-account-form.schema";
import { countryCodesSelector } from "../../../../components/country-codes-dropdown/country-codes.selectors";
import { adult, maxAvailableToRegister } from "../../../../constants/dates";
import { genders } from "../../../../constants/genders";
import { adaptGender } from "../../../../helpers/genders";

import DatePicker from "../../../../components/input/date-picker/date-picker";
import Select from "../../../../components/select/select";
import CountryCodesDropdown from "../../../../components/country-codes-dropdown/country-codes-dropdown";
import ButtonLoader from "../../../../components/loader/components/button-loader/button-loader";
import LocationSelect from "../../../../components/location-select/location-select";
import Input from "../../../../components/input/input";
import UploadImage from "../upload-image/upload-image";

import { getAllDistricts } from "../../../../components/cities-loader/cities-loader.operations";
import { setCityAction } from "../../../auth/auth.actions";
import { isObjectEqual } from "../../../../helpers/isObjectEqual";
import {
    isEmptyObject,
    formingAnObjectForSendingForm,
} from "../../../../helpers/is-empty-object";
import {
    inputsFirstConfig,
    inputsSecondConfig,
    inputsSocialConfig,
} from "./my-account-form.config";

import "./index.scss";

const MyAccountForm = () => {
    const { data, avatar } = useSelector(myAccountSelector);
    const { cities, districts } = useSelector(citiesLoaderSelector);
    const { status: buttonStatus, button: buttonLoading } =
        useSelector(buttonLoaderSelector);
    const { countryCode } = useSelector(countryCodesSelector);

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { role, gender, city, verifications, phone } = data;

    const [avatarImage, setAvatarImage] = useState(null);
    const [currentCity, setCity] = useState({ name: city, state_code: "" });
    const [checked, setChecked] = useState(verifications.facebook.confirmed);

    const {
        formState: { errors, isValid },
        handleSubmit,
        register,
        resetField,
        reset,
        control,
        watch,
    } = useForm({
        mode: "all",
        resolver: yupResolver(myAccountFormSchema),
        defaultValues: {
            ...data,
            phoneValue: phone?.number,
            gender: adaptGender(gender),
        },
    });
    const { dirtyFields } = useFormState({ control });

    const phoneInput = watch("phoneValue");
    const emailInput = watch("email");
    const genderInput = watch("gender");

    const adjustPhoneNumber = () => {
        if (countryCode.dialCode) {
            return countryCode.dialCode + phoneInput;
        } else return phone?.dialCode + phoneInput;
    };

    // useEffect(() => reset(data), [data]);
    useEffect(() => dispatch(getUser()), []);
    useEffect(() => dispatch(getAvatars()), []);

    const handleCancelButton = () => reset(data);
    const handleDeleteAvatar = () => dispatch(deleteUserAvatar());
    const handleSelectCustomIcon = (item) => dispatch(putUserAvatar(item));
    const handleSendCroppedImage = (croppedImage) =>
        dispatch(putUserAvatar(croppedImage));

    const handleSelectCity = (selectedCity) => {
        if (
            isObjectEqual(currentCity, selectedCity) |
            (currentCity.name === selectedCity.name)
        )
            return;

        reset({ district: "" }, { keepValues: true });
        resetField("district"); //doens`t work individually

        setCity(selectedCity);
        dispatch(setCityAction(selectedCity));
    };

    const handleDeleteButton = () => {
        dispatch(
            openModal({
                isOpen: true,
                closeIcon: true,
                renderType: modalConstants.deleteAccount,
            })
        );
    };

    const handleFormSubmit = (data) => {
        // if (!data.socialLinks.inst) delete data.socialLinks.inst;
        // if (!data.socialLinks.fb) delete data.socialLinks.fb;

        delete dirtyFields.phoneValue;
        delete dirtyFields.email;

        const newData = formingAnObjectForSendingForm(data, dirtyFields);
        const objectLength = Object.keys(newData).length;

        switch (true) {
            case "city" in newData && objectLength === 1:
                dispatch(
                    putUserAccount(
                        {
                            ...newData,
                            gender: genderInput.value,
                        },
                        role,
                        reset,
                        data,
                        navigate
                    )
                );
                handleCancelButton();
                break;

            default:
                dispatch(
                    putUserAccount(
                        {
                            ...newData,
                            gender: genderInput.value,
                        },
                        role,
                        reset,
                        data,
                        navigate
                    )
                );
                break;
        }
    };

    useEffect(() => {
        if (currentCity.state_code || city)
            dispatch(getAllDistricts(currentCity.state_code, city));
    }, [currentCity]);

    const facebookConnect = async () => {
        const { authResponse } = await new Promise(window.FB.login);
        if (!authResponse) return;

        dispatch(oauthConnect(authResponse.accessToken));
    };

    const onCheckedHandler = (event) => {
        setChecked(event.target.checked);

        if (!checked) {
            facebookConnect();
        }
    };

    return (
        <div className="my-account">
            <span className="my-account__title">{t("my_account_title")}</span>
            <span className="my-account__subtitle">
                {t(`my_account_${role.toLowerCase()}`)}
            </span>
            <div className="my-account__form">
                <Controller
                    control={control}
                    name="avatar"
                    render={({ field: { onChange } }) => (
                        <UploadImage
                            gender={gender}
                            avatar={avatarImage}
                            defaultImage={avatar}
                            onChange={onChange}
                            onDeleteAvatar={handleDeleteAvatar}
                            handleUploadImageChange={setAvatarImage}
                            handleSendCroppedImage={handleSendCroppedImage}
                            handleSelectIcon={handleSelectCustomIcon}
                        />
                    )}
                />
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                    {inputsFirstConfig.map((item) => (
                        <Input
                            inputChangeOptions={register(item.registerInfo)}
                            error={
                                item.link
                                    ? errors["socialLinks"]?.[item.link]
                                          ?.message
                                    : errors[item.registerInfo]?.message
                            }
                            options={{
                                placeholderText: item.placeholderTextInfo,
                            }}
                            readOnly={
                                item.disable && role === userRoles.specialist
                            }
                            label={t(item.labelInfo)}
                            key={item.registerInfo}
                            type={item.typeInfo}
                        />
                    ))}
                    <div className="my-account__form-date-info">
                        <label className="my-account__form-date-label">
                            {t("label_birthday")}
                        </label>
                        <div className="my-account__form-date-error">
                            {t(errors.birthday?.message)}
                        </div>
                    </div>
                    <div
                        className={`my-account__form-date${
                            errors.birthday?.message ? "-error" : ""
                        }`}
                    >
                        <Controller
                            control={control}
                            name="birthday"
                            render={({ field: { onChange, value, ref } }) => (
                                <>
                                    <DatePicker
                                        dateRef={(elem) => {
                                            elem && ref(elem.input);
                                        }}
                                        onChange={onChange}
                                        errors={errors.birthday}
                                        selected={value}
                                        maxDate={Date.now() - adult}
                                        minDate={
                                            Date.now() - maxAvailableToRegister
                                        }
                                        maxVisibleYears={150}
                                    />
                                </>
                            )}
                        />
                    </div>
                    <Controller
                        name="gender"
                        control={control}
                        render={({ field }) => (
                            <Select
                                {...field}
                                label={"gender"}
                                options={genders}
                                error={errors.gender?.message}
                                onSelect={(gender) => field.onChange(gender)}
                            />
                        )}
                    />
                    {inputsSocialConfig.map((item) => (
                        <Input
                            inputChangeOptions={register(item.registerInfo)}
                            error={
                                item.link
                                    ? errors["socialLinks"]?.[item.link]
                                          ?.message
                                    : errors[item.registerInfo]?.message
                            }
                            options={{
                                placeholderText: item.placeholderTextInfo,
                            }}
                            readOnly={
                                item.disable && role === userRoles.specialist
                            }
                            label={t(item.labelInfo)}
                            key={item.registerInfo}
                            type={item.typeInfo}
                        />
                    ))}
                    <div className="my-account__form-facebook-checkbox">
                        <label htmlFor="facebookVerify">
                            {t("my_account_facebook_connect")}
                        </label>
                        <input
                            type="checkbox"
                            id="facebookVerify"
                            checked={checked}
                            disabled={checked}
                            onChange={onCheckedHandler}
                        />
                    </div>
                    <Input
                        inputChangeOptions={register("email")}
                        options={{
                            placeholderText: "my_account_email_placeholder",
                        }}
                        label={t("my_account_email")}
                        type="secondary"
                    />
                    {verifications.email.confirmed &&
                    verifications.email.value === emailInput ? (
                        <div className="form-container__phone-confirmed">
                            <span>{t("email_is_verified")}</span>
                        </div>
                    ) : (
                        <div className="form-container__phone-confirmed form-container__phone-confirmed--not">
                            <span
                                onClick={() =>
                                    dispatch(
                                        getEmailVerificationCode(
                                            emailInput,
                                            navigate
                                        )
                                    )
                                }
                            >
                                {t("email_is_not_verified")}
                            </span>
                        </div>
                    )}
                    <div className="form-container">
                        <div className="form-container__info">
                            <label className="form-container__label">
                                {t("my_account_phone")}
                            </label>
                            <label className="form-container__error">
                                {t(errors?.phoneValue?.message)}
                            </label>
                        </div>
                        <div className="form-container__phone-form">
                            <CountryCodesDropdown
                                register={register}
                                phoneValueName="phoneValue"
                                phoneCodeName="phoneCode"
                                error={errors?.phoneValue?.message}
                                registeredDialCode={phone?.dialCode}
                                registeredFlag={phone?.flag}
                                fullWidth
                                isMyAccountPage
                            />
                        </div>
                        {verifications.phone.confirmed &&
                        adjustPhoneNumber() === verifications.phone.value ? (
                            <div className="form-container__phone-confirmed">
                                <span>{t("phone_is_verified")}</span>
                            </div>
                        ) : (
                            <div className="form-container__phone-confirmed form-container__phone-confirmed--not">
                                <span
                                    onClick={() =>
                                        dispatch(
                                            getPhoneVerificationCode(
                                                adjustPhoneNumber()
                                            )
                                        )
                                    }
                                >
                                    {t("phone_is_not_verified")}
                                </span>
                            </div>
                        )}
                    </div>
                    <Controller
                        name="city"
                        control={control}
                        render={({ field }) => (
                            <LocationSelect
                                {...field}
                                value={currentCity.name}
                                options={cities}
                                type={SELECT_TYPES.WITHOUT_LABEL}
                                error={errors.city?.message}
                                onSelect={(city) => {
                                    field.onChange(city.name);
                                    handleSelectCity(city);
                                }}
                                placeholder="my_account_city_placeholder"
                                label="my_account_city"
                            />
                        )}
                    />
                    <Controller
                        name="district"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <LocationSelect
                                value={value}
                                options={districts || []}
                                error={errors.district?.message}
                                type={SELECT_TYPES.WITHOUT_LABEL}
                                onSelect={({ name }) => onChange(name)}
                                onBlur={({ name }) => onChange(name)}
                                label="my_account_district"
                                placeholder="my_account_district_placeholder"
                            />
                        )}
                    />
                    {inputsSecondConfig.map((item) => (
                        <Input
                            inputChangeOptions={register(item.registerInfo)}
                            error={errors[item.registerInfo]?.message}
                            options={{
                                placeholderText: item.placeholderTextInfo,
                            }}
                            readOnly={
                                item.disable && role === userRoles.specialist
                            }
                            label={t(item.labelInfo)}
                            key={item.registerInfo}
                            type={item.typeInfo}
                        />
                    ))}
                    <div className="my-account__form--btns">
                        <button
                            className="my-account__form--save"
                            disabled={
                                isEmptyObject(dirtyFields) ||
                                !isValid ||
                                buttonStatus
                            }
                            style={{
                                background:
                                    buttonStatus &&
                                    buttonLoading === "update-user" &&
                                    "#dfdfdf",
                            }}
                            type="submit"
                        >
                            {buttonStatus && buttonLoading === "update-user" ? (
                                <ButtonLoader size={38} />
                            ) : (
                                t("btn_save")
                            )}
                        </button>

                        <button
                            className="my-account__form--delete"
                            onClick={handleDeleteButton}
                            type="button"
                        >
                            {t("btn_delete_account")}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default MyAccountForm;
