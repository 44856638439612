export const authTypes = {
    // common
    SET_CONDITIONS_USER: "auth/SET_CONDITIONS_USER",
    SET_AVATAR_USER: "auth/SET_AVATAR_USER",
    SET_USER_DATA: "auth/SET_USER_DATA",
    SET_EMAIL_ERROR: "auth/SET_EMAIL",
    SET_DISTRICT: "auth/SET_DISTRICT",
    SET_EMAIL: "auth/SET_EMAIL",
    SET_CITY: "auth/SET_CITY",
    SET_ROLE: "auth/SET_ROLE",
    SET_OAUTH_TOKEN: "auth/SET_OAUTH_TOKEN",
    SET_REGISTERED_EMAIL: "auth/SET_REGISTERED_EMAIL",
    SET_REGISTERED_PHONE: "auth/SET_REGISTERED_PHONE",

    // login
    SEND_LOGIN_FORM_REQUEST: "auth/SEND_LOGIN_FORM_REQUEST",
    SEND_LOGIN_FORM_SUCCESS: "auth/SEND_LOGIN_FORM_SUCCESS",
    SEND_LOGIN_FORM_ERROR: "auth/SEND_LOGIN_FORM_ERROR",

    // oauth
    SEND_OAUTH_REQUEST: "auth/SEND_OAUTH_REQUEST",
    SEND_OAUTH_SUCCESS: "auth/SEND_OAUTH_SUCCESS",
    SEND_OAUTH_ERROR: "auth/SEND_OAUTH_ERROR",

    // registration
    SEND_REGISTRATION_FORM_REQUEST: "auth/SEND_REGISTRATION_FORM_REQUEST",
    SEND_REGISTRATION_FORM_ERROR: "auth/SEND_REGISTRATION_FORM_ERROR",
    SEND_REGISTRATION_FORM_SUCCESS: "auth/REGISTRATION_FORM_SUCCESS",

    // oauth registration
    SEND_OAUTH_REGISTRATION_REQUEST: "auth/SEND_OAUTH_REGISTRATION_REQUEST",
    SEND_OAUTH_REGISTRATION_SUCCESS: "auth/SEND_OAUTH_REGISTRATION_SUCCESS",
    SEND_OAUTH_REGISTRATION_ERROR: "auth/SEND_OAUTH_REGISTRATION_ERROR",

    // forgot password
    SET_FORGOT_PASSWORD_STEP: "auth/SET_FORGOT_PASSWORD_STEP",
    FORGOT_PASSWORD_REQUEST: "auth/FORGOT_PASSWORD_REQUEST",
    FORGOT_PASSWORD_ERROR: "auth/FORGOT_PASSWORD_ERROR",
    FORGOT_PASSWORD_SUCCESS: "auth/FORGOT_PASSWORD_SUCCESS",
    RESET_PASSWORD_REQUEST: "auth/RESET_PASSWORD_REQUEST",
    RESET_PASSWORD_ERROR: "auth/RESET_PASSWORD_ERROR",
    RESET_PASSWORD_SUCCESS: "auth/RESET_PASSWORD_SUCCESS",

    // restore account
    SET_RESTORE_ACCOUNT_EMAIL: "auth/SET_RESTORE_ACCOUNT_EMAIL",
    RESTORE_EMAIL_REQUEST: "auth/RESTORE_EMAIL_REQUEST",
    RESTORE_EMAIL_ERROR: "auth/RESTORE_EMAIL_ERROR",
    RESTORE_EMAIL_SUCCESS: "auth/RESTORE_EMAIL_SUCCESS",
    RESTORE_ACCOUNT_REQUEST: "auth/RESTORE_ACCOUNT_REQUEST",
    RESTORE_ACCOUNT_ERROR: "auth/RESTORE_ACCOUNT_ERROR",
    RESTORE_ACCOUNT_SUCCESS: "auth/RESET_PASSWORD_SUCCESS",

    // restore account by phone
    SET_RESTORE_ACCOUNT_PHONE: "auth/SET_RESTORE_ACCOUNT_PHONE",
    RESTORE_PHONE_REQUEST: "auth/RESTORE_PHONE_REQUEST",
    RESTORE_PHONE_SUCCESS: "auth/RESTORE_PHONE_SUCCESS",
    RESTORE_PHONE_ERROR: "auth/RESTORE_PHONE_ERROR",

    // email verify
    SEND_EMAIL_VERIFY_REQUEST: "auth/SEND_EMAIL_VERIFY_REQUEST",
    SEND_EMAIL_VERIFY_SUCCESS: "auth/SEND_EMAIL_VERIFY_SUCCESS",
    SEND_EMAIL_VERIFY_ERROR: "auth/SEND_EMAIL_VERIFY_ERROR",

    // email confirmation
    SEND_EMAIL_CONFIRMATION_REQUEST: "auth/SEND_EMAIL_CONFIRMATION_REQUEST",
    SEND_EMAIL_CONFIRMATION_SUCCESS: "auth/SEND_EMAIL_CONFIRMATION_SUCCESS",
    SEND_EMAIL_CONFIRMATION_ERROR: "auth/SEND_EMAIL_CONFIRMATION_ERROR",

    // phone verify
    SEND_PHONE_VERIFY_REQUEST: "auth/SEND_PHONE_VERIFY_REQUEST",
    SEND_PHONE_VERIFY_SUCCESS: "auth/SEND_PHONE_VERIFY_SUCCESS",
    SEND_PHONE_VERIFY_ERROR: "auth/SEND_PHONE_VERIFY_ERROR",

    // phone confirmation
    SEND_PHONE_CONFIRMATION_REQUEST: "auth/SEND_PHONE_CONFIRMATION_REQUEST",
    SEND_PHONE_CONFIRMATION_SUCCESS: "auth/SEND_PHONE_CONFIRMATION_SUCCESS",
    SEND_PHONE_CONFIRMATION_ERROR: "auth/SEND_PHONE_CONFIRMATION_ERROR",

    // restore by oauth
    SET_FACEBOOK_TOKEN: "auth/SET_FACEBOOK_TOKEN",
    SEND_RESTORE_BY_OAUTH_REQUEST: "auth/SEND_RESTORE_BY_OAUTH_REQUEST",
    SEND_RESTORE_BY_OAUTH_SUCCESS: "auth/SEND_RESTORE_BY_OAUTH_SUCCESS",
    SEND_RESTORE_BY_OAUTH_ERROR: "auth/SEND_RESTORE_BY_OAUTH_ERROR",

    // send avatar
    POST_AVATAR_REQUEST: "auth/POST_AVATAR_REQUEST",
    POST_AVATAR_SUCCESS: "auth/POST_AVATAR_SUCCESS",
    POST_AVATAR_ERROR: "auth/POST_AVATAR_REJECTED",

    // tokens
    UPDATE_TOKENS: "auth/UPDATE_TOKENS",

    // log out
    LOGOUT_USER: "auth/LOGOUT_USER",
};
