import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import PropTypes from "prop-types";

import { openModal } from "../../../../../../components/modal/modal.actions";

import MainButton from "../../../../../../components/main-button/main-button";
import { showLastMessage } from "../../../../../../helpers/show-last-message";
import LazyImage from "../../../../../../components/lazy-image/lazy-image";
import { typeOfLazeImage } from "../../../../../../components/lazy-image/lazyImage.constansts";
import { modalConstants } from "../../../../../../components/modal/modal.constants";
import { getPhotoByTypeGender } from "../../../../../../helpers/get-photo-by-type-gender";
import { getLocalizedService } from "../../../../../../helpers/get-localized-service";
import { Icon } from "../../../../../../components/image/image";
import Rating from "../../../../../../components/rating/rating";
import routerBook from "../../../../../../router/router-book";
import { ORDERS_FILTERS } from "../../../../orders.constant";

import currencyLira from "../../../../../../assets/images/currency/lira.svg";
import "./index.scss";

const ExecutorItem = ({ taskItem, order }) => {
    const { specialist, priceProposal, chat, _id: taskId, orderId } = taskItem;
    const {
        firstName = "",
        lastName = "",
        avatar,
        gender,
        isOnline,
        feedback,
    } = specialist || {};
    const { subcategory } = order;
    const { filterParameter } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const chatId = (chat && chat._id) || taskItem.chatId;

    return (
        <div className="executor-item">
            <div
                title={isOnline ? t("online") : t("offline")}
                className="executor-item__avatar-container"
            >
                <LazyImage
                    image={getPhotoByTypeGender(avatar, gender)}
                    type={typeOfLazeImage.avatar}
                    options={{
                        className: "executor-item__avatar",
                        alt: "avatar",
                    }}
                />
                {isOnline && <span className="executor-item__avatar__status" />}
            </div>

            <div className="executor-item__content">
                <div className="executor-item__content__general-info">
                    <div>
                        <p className="executor-item__content__general-info__name">
                            {specialist ? (
                                <>
                                    {firstName} {lastName}
                                </>
                            ) : (
                                t("chats.deleted_user")
                            )}
                        </p>
                        <p className="executor-item__content__general-info__main-service">
                            {getLocalizedService(subcategory)}
                        </p>
                        <span className="executor-item__content__general-info__price-range">
                            {priceProposal?.price}{" "}
                            <img src={currencyLira} alt="currency" />
                        </span>
                    </div>

                    {filterParameter === ORDERS_FILTERS.COMPLETED ? (
                        feedback ? (
                            <div className="executor-item__content__general-info__rating">
                                {t("orders.executors.rating")}
                                <Rating rating={feedback.assessment} />
                            </div>
                        ) : specialist ? (
                            <MainButton
                                type="button"
                                buttonSize="btn--large"
                                onClick={() =>
                                    dispatch(
                                        openModal({
                                            isOpen: true,
                                            closeIcon: true,
                                            renderType:
                                                modalConstants.sendFeedback,
                                            details: {
                                                firstName,
                                                lastName,
                                                taskId,
                                                orderId,
                                            },
                                        })
                                    )
                                }
                            >
                                {t("orders.executors.leave-an-review")}
                            </MainButton>
                        ) : null
                    ) : (
                        <MainButton
                            type="button"
                            buttonSize="btn--large"
                            onClick={() =>
                                navigate(`${routerBook.chats}/${chatId}`, {
                                    state: true,
                                })
                            }
                        >
                            {t("orders.executors.go-to-chat")}
                        </MainButton>
                    )}
                </div>
                {filterParameter !== ORDERS_FILTERS.COMPLETED && !feedback && (
                    <div className="executor-item__request-text">
                        {chat && chatId
                            ? showLastMessage(chat.lastMessage)
                            : t("chats.no_messages_yet")}
                    </div>
                )}
                {feedback && (
                    <p className="executor-item__content__general-info__feedback">
                        {feedback.text}
                    </p>
                )}
            </div>
        </div>
    );
};

ExecutorItem.propTypes = {
    taskItem: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    order: PropTypes.object,
};

const ExecutorsList = ({ tasks, order }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { filterParameter } = useParams();

    const isNewOrNegotiationPage = () => {
        if (
            filterParameter === ORDERS_FILTERS.NEW ||
            filterParameter === ORDERS_FILTERS.NEGOTIATION
        ) {
            return true;
        }

        return false;
    };

    return (
        <div className="executors-list">
            <div
                className="executors-list__header"
                onClick={() =>
                    navigate(
                        `${routerBook.proposalsForCooperation}/${order._id}`,
                        { state: true }
                    )
                }
            >
                <p className="executors-list__header__content">
                    {t(
                        `orders.executors.${
                            isNewOrNegotiationPage() ? "title" : "selected"
                        }`
                    )}
                </p>
                {isNewOrNegotiationPage() && (
                    <p className="executors-list__header__content">
                        {t("orders.executors.order-suggestions")} (
                        {tasks.length})
                    </p>
                )}
            </div>
            <div className="executors-list__items">
                {tasks.length ? (
                    tasks.map((taskItem) => {
                        return (
                            <ExecutorItem
                                taskItem={taskItem}
                                key={taskItem._id}
                                order={order}
                            />
                        );
                    })
                ) : (
                    <div className="executors-list__empty">
                        <Icon type="sandClock" />
                        <p className="executors-list__empty__title">
                            {t("orders.error.no-executors.title")}
                        </p>
                        <p className="executors-list__empty__subtitle">
                            {t("orders.error.no-executors.subtitle")}
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
};

ExecutorsList.propTypes = { order: PropTypes.object, tasks: PropTypes.array };

export default ExecutorsList;
