import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { forwardRef } from "react";
import PropTypes from "prop-types";

import useOutsideClick from "../../hooks/use-outside-click";
import { getLanguage } from "../../helpers/language";
import SELECT_TYPES from "./select.constants";
import Tooltip from "../tooltip/tooltip";
import { Icon } from "../image/image";

import { ReactComponent as InfoIcon } from "../../assets/images/vectors/info.svg";
import "./index.scss";

const Select = forwardRef(
    (
        {
            onSelect,
            label,
            value,
            error,
            options,
            placeholder = "",
            type = SELECT_TYPES.WITH_LABEL,
            styleSelectHeader,
            isRounded = false,
        },
        ref
    ) => {
        const [selected, setSelected] = useState(value);
        const [isOpen, setOpen] = useState(false);
        const { value: language } = getLanguage();
        const { t } = useTranslation();
        const selectRef = useRef();

        useEffect(() => {
            if (value !== selected) setSelected(value);
        }, [value]);

        const mapSelectLabel = (item) => {
            if (item.label || item.city) {
                if (typeof item.label === "object") return item.label[language];
                if (item.label?.includes("/static/media/")) {
                    return (
                        <img
                            className="select__dropdown_item__label-img"
                            src={item.label}
                            alt="icon"
                        />
                    );
                } else {
                    return t(item.label || item.city);
                }
            }
            return t(item);
        };

        const getLabel = () => {
            if (!selected) {
                return (
                    <p className="select__dropdown_value">
                        {t(placeholder || label)}
                    </p>
                );
            }
            if (selected) return mapSelectLabel(selected);
        };

        const checkSelectedItem = (item) => {
            if (type === SELECT_TYPES.WITHOUT_LABEL) {
                if (selected === item) return true;
                else return false;
            } else {
                if (selected?.value === item?.value) return true;
                else return false;
            }
        };

        const toggleDropdown = () => options.length && setOpen(!isOpen);

        useOutsideClick(selectRef, () => setOpen(false));

        const setDropdownModifiers = () => {
            if (isOpen) {
                return "-open";
            } else if (!options.length && error) {
                return "-error select__dropdown-disabled";
            } else if (error) {
                return "-error";
            } else if (!options.length) {
                return "-disabled";
            }

            return "";
        };

        return (
            <div
                ref={selectRef || ref}
                className={`select ${isRounded && "select-blog"}`}
            >
                <div className="select__header">
                    <label className="select__label">{t(label)}</label>
                    <p className="select__error"> {error && t(error)}</p>
                </div>
                <div
                    className={`select__dropdown${setDropdownModifiers()} ${
                        isRounded && "select__dropdown-blog"
                    } ${isRounded && isOpen && "select__dropdown-blog-open"}`}
                >
                    <div
                        style={{ padding: styleSelectHeader }}
                        className={`select__dropdown_header ${
                            isRounded && "select__dropdown_header-blog"
                        }`}
                        onClick={toggleDropdown}
                    >
                        {getLabel()}
                        <span
                            className={`select__dropdown_icon${
                                isOpen && "-reverted"
                            }`}
                        >
                            <Icon type="triangleArrowDown" />
                        </span>
                    </div>
                    <div
                        className={`select__dropdown_body ${isOpen && "open"}`}
                    >
                        {options.map((item, index) => {
                            return (
                                <div
                                    className="select__dropdown_inner"
                                    key={index}
                                    onClick={() => {
                                        setSelected(item);
                                        onSelect(item);
                                        toggleDropdown();
                                    }}
                                >
                                    <div
                                        className="select__dropdown_item"
                                        id={item.id}
                                    >
                                        <div
                                            className={`select__dropdown_circle${
                                                checkSelectedItem(item)
                                                    ? ""
                                                    : "-disable"
                                            }`}
                                        >
                                            {checkSelectedItem(item) && (
                                                <div className="select__dropdown_circle-border"></div>
                                            )}
                                        </div>
                                        <div className="select__dropdown_item__label">
                                            {item
                                                ? mapSelectLabel(item)
                                                : t(item)}
                                        </div>
                                    </div>
                                    {item.info && (
                                        <div className="select__dropdown_inner__info">
                                            <Tooltip content={item.info}>
                                                <InfoIcon />
                                            </Tooltip>
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                    <div />
                </div>
            </div>
        );
    }
);

Select.propTypes = {
    error: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    options: PropTypes.array,
    label: PropTypes.string,
    onSelect: PropTypes.func,
    placeholder: PropTypes.string,
    styleSelectHeader: PropTypes.string,
    type: PropTypes.string,
    isRounded: PropTypes.bool,
};

Select.displayName = "Select";
export default Select;
