import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import ButtonLoader from "../../../../components/loader/components/button-loader/button-loader";

import { closeModal } from "../../../../components/modal/modal.actions";
import { buttonLoaderSelector } from "../../../../components/loader/loader.selectors";
import { deactivatePromotion } from "../../promotion.operations";

import "./index.scss";

const PromotionDeclineForm = ({ details: { _id } }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { status: buttonStatus } = useSelector(buttonLoaderSelector);

    const handleCloseModal = () => dispatch(closeModal());
    const handleDeactivatePromotion = () => {
        dispatch(deactivatePromotion(_id));
        dispatch(closeModal());
    };

    return (
        <div className="promotion-decline-form">
            <span className="promotion-decline-form__title">
                {t("promotion.modal.decline-promotion-question")}
            </span>
            <div className="promotion-decline-form__btns">
                <button
                    className="promotion-decline-form__btns-cancel"
                    onClick={handleCloseModal}
                >
                    {t("btn_cancel")}
                </button>
                <button
                    onClick={handleDeactivatePromotion}
                    className={`promotion-decline-form__btns-delete${
                        buttonStatus ? "-loading" : ""
                    }`}
                    disabled={buttonStatus}
                >
                    {buttonStatus ? (
                        <ButtonLoader size={38} />
                    ) : (
                        t("btn_deactivate")
                    )}
                </button>
            </div>
        </div>
    );
};

PromotionDeclineForm.propTypes = {
    details: PropTypes.object,
};

export default PromotionDeclineForm;
