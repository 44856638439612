import PropTypes from "prop-types";
import React from "react";

import noOrdersIcon from "../../../../../../assets/images/error-pages/empty-result.svg";
import "./index.scss";

const NoOrders = ({ text }) => {
    return (
        <div className="no-orders">
            <img
                className="no-orders__img"
                src={noOrdersIcon}
                alt="no orders"
            />
            <span className="no-orders__title">{text}</span>
        </div>
    );
};

NoOrders.propTypes = {
    text: PropTypes.string.isRequired,
};

export default NoOrders;
