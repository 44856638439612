import React from "react";

import promo from "../../assets/videos/promo.mp4";
import "./index.scss";

const PromoVideo = () => {
    return (
        <video className="promo-video" autoPlay muted loop>
            <source src={promo} type="video/mp4" />
        </video>
    );
};

export default PromoVideo;
