import { orderTypes } from "./orders.types";

// get all orders
export const getAllOrdersPending = () => ({
    type: orderTypes.GET_ALL_ORDERS_PENDING,
});

export const getAllOrdersFulfilled = (data) => ({
    type: orderTypes.GET_ALL_ORDERS_FULFILLED,
    payload: data,
});

export const getAllOrdersRejected = (error) => ({
    type: orderTypes.GET_ALL_ORDERS_REJECTED,
    payload: error,
});

// get all orders for favourites
export const getAllOrdersForFavouritesPending = () => ({
    type: orderTypes.GET_ALL_ORDERS_FOR_FAVOURITES_PENDING,
});

export const getAllOrdersForFavouritesFulfilled = (data) => ({
    type: orderTypes.GET_ALL_ORDERS_FOR_FAVOURITES_FULFILLED,
    payload: data,
});

export const getAllOrdersForFavouritesRejected = (error) => ({
    type: orderTypes.GET_ALL_ORDERS_FOR_FAVOURITES_REJECTED,
    payload: error,
});


// get order
export const getOrderPending = () => ({
    type: orderTypes.GET_ORDER_PENDING,
});

export const getOrderFulfilled = (data) => ({
    type: orderTypes.GET_ORDER_FULFILLED,
    payload: data,
});

export const getOrderRejected = (error) => ({
    type: orderTypes.GET_ORDER_REJECTED,
    payload: error,
});

// get order task
export const getOrderTaskPending = () => ({
    type: orderTypes.GET_ORDER_TASK_PENDING,
});

export const getOrderTaskFulfilled = (data) => ({
    type: orderTypes.GET_ORDER_TASK_FULFILLED,
    payload: data,
});

export const getOrderTaskRejected = (error) => ({
    type: orderTypes.GET_ORDER_TASK_REJECTED,
    payload: error,
});

// get order task other specialist
export const getOrderTaskOtherSpecialistPending = () => ({
    type: orderTypes.GET_ORDER_TASK_OTHER_SPECIALIST_PENDING,
});

export const getOrderTaskOtherSpecialistFulfilled = (data) => ({
    type: orderTypes.GET_ORDER_TASK_OTHER_SPECIALIST_FULFILLED,
    payload: data,
});

export const getOrderTaskOtherSpecialistRejected = (error) => ({
    type: orderTypes.GET_ORDER_TASK_OTHER_SPECIALIST_REJECTED,
    payload: error,
});

// get order responders
export const getOrderRespondersPending = () => ({
    type: orderTypes.GET_ORDER_RESPONDERS_PENDING,
});

export const getOrderRespondersFulfilled = (data) => ({
    type: orderTypes.GET_ORDER_RESPONDERS_FULFILLED,
    payload: data,
});

export const getOrderRespondersRejected = (error) => ({
    type: orderTypes.GET_ORDER_RESPONDERS_REJECTED,
    payload: error,
});

// post order
export const postOrderPending = () => ({
    type: orderTypes.POST_ORDER_PENDING,
});

export const postOrderFulfilled = (data) => ({
    type: orderTypes.POST_ORDER_FULFILLED,
    payload: data,
});

export const postOrderRejected = (error) => ({
    type: orderTypes.POST_ORDER_REJECTED,
    payload: error,
});

// post order image
export const postOrderImagePending = () => ({
    type: orderTypes.POST_ORDER_IMAGE_PENDING,
});

export const postOrderImageFulfilled = (data) => ({
    type: orderTypes.POST_ORDER_IMAGE_FULFILLED,
    payload: data,
});

export const postOrderImageRejected = (error) => ({
    type: orderTypes.POST_ORDER_IMAGE_REJECTED,
    payload: error,
});

// update order
export const updateOrderPending = () => ({
    type: orderTypes.UPDATE_ORDER_PENDING,
});

export const updateOrderFulfilled = (data) => ({
    type: orderTypes.UPDATE_ORDER_FULFILLED,
    payload: data,
});

export const updateOrderRejected = (error) => ({
    type: orderTypes.UPDATE_ORDER_REJECTED,
    payload: error,
});

// update task status
export const putTaskStatusPending = () => ({
    type: orderTypes.PUT_TASK_STATUS_PENDING,
});

export const putTaskStatusFulfilled = (data) => ({
    type: orderTypes.PUT_TASK_STATUS_FULFILLED,
    payload: data,
});

export const putTaskStatusRejected = (error) => ({
    type: orderTypes.PUT_TASK_STATUS_REJECTED,
    payload: error,
});

// update task fix price
export const putTaskFixPricePending = () => ({
    type: orderTypes.PUT_TASK_FIX_PRICE_PENDING,
});

export const putTaskFixPriceFulfilled = (data) => ({
    type: orderTypes.PUT_TASK_FIX_PRICE_FULFILLED,
    payload: data,
});

export const putTaskFixPriceRejected = (error) => ({
    type: orderTypes.PUT_TASK_FIX_PRICE_REJECTED,
    payload: error,
});

// remove order
export const removeOrderPending = () => ({
    type: orderTypes.REMOVE_ORDER_PENDING,
});

export const removeOrderFulfilled = (data) => ({
    type: orderTypes.REMOVE_ORDER_FULFILLED,
    payload: data,
});

export const removeOrderRejected = (error) => ({
    type: orderTypes.REMOVE_ORDER_REJECTED,
    payload: error,
});

// remove order image
export const removeOrderImagePending = () => ({
    type: orderTypes.REMOVE_ORDER_IMAGE_PENDING,
});

export const removeOrderImageFulfilled = (data) => ({
    type: orderTypes.REMOVE_ORDER_IMAGE_FULFILLED,
    payload: data,
});

export const removeOrderImageRejected = (error) => ({
    type: orderTypes.REMOVE_ORDER_IMAGE_REJECTED,
    payload: error,
});

// reserve funds
export const reserveFundsPending = () => ({
    type: orderTypes.RESERVE_FUNDS_PENDING,
});

export const reserveFundsFulfilled = (data) => ({
    type: orderTypes.RESERVE_FUNDS_FULFILLED,
    payload: data,
});

export const reserveFundsRejected = (error) => ({
    type: orderTypes.RESERVE_FUNDS_REJECTED,
    payload: error,
});

// set
export const clearAllParams = () => ({
    type: orderTypes.CLEAR_ALL_PARAMS,
});

export const setPage = (data) => ({
    type: orderTypes.SET_PAGE,
    payload: data,
});

export const setStatus = (data) => ({
    type: orderTypes.SET_STATUS,
    payload: data,
});
