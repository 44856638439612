import { userRoles } from "../../../../../../constants/user-roles";
import { paymentTypes } from "../../../../balance.constants";

import { ReactComponent as StripeIcon } from "../../../../../../assets/images/currency/stripe.svg";
import { ReactComponent as IyzicoIcon } from "../../../../../../assets/images/currency/iyzico.svg";

export const transactionTypes = {
    payout: "payout",
    transfer: "transfer",
    payment: "payment",
};

export const transactionConfigs = {
    [userRoles.customer]: {
        payout: {
            title: "balance-transactions-title-payout",
            type: transactionTypes.payout,
            paymentIcon: {
                [paymentTypes.stripe.value]: (
                    <StripeIcon className="balance-transactions-container-item__start-left-payment-icon" />
                ),
                [paymentTypes.iyzico.value]: (
                    <IyzicoIcon className="balance-transactions-container-item__start-left-payment-icon" />
                ),
            },
        },
        transfer: {
            title: "balance-transactions-title-transfer",
            type: transactionTypes.transfer,
            paymentIcon: {
                [paymentTypes.stripe.value]: (
                    <StripeIcon className="balance-transactions-container-item__start-left-payment-icon" />
                ),
                [paymentTypes.iyzico.value]: (
                    <IyzicoIcon className="balance-transactions-container-item__start-left-payment-icon" />
                ),
            },
        },
        payment: {
            title: "balance-transactions-title-refill",
            type: transactionTypes.payment,
            paymentIcon: {
                [paymentTypes.stripe.value]: (
                    <StripeIcon className="balance-transactions-container-item__start-left-payment-icon" />
                ),
                [paymentTypes.iyzico.value]: (
                    <IyzicoIcon className="balance-transactions-container-item__start-left-payment-icon" />
                ),
            },
        },
    },
    [userRoles.specialist]: {
        payout: {
            title: "balance-transactions-title-payout",
            type: transactionTypes.payout,
            paymentIcon: {
                [paymentTypes.stripe.value]: (
                    <StripeIcon className="balance-transactions-container-item__start-left-payment-icon" />
                ),
                [paymentTypes.iyzico.value]: (
                    <IyzicoIcon className="balance-transactions-container-item__start-left-payment-icon" />
                ),
            },
        },
        transfer: {
            title: "balance-transactions-title-transfer",
            type: transactionTypes.transfer,
            paymentIcon: {
                [paymentTypes.stripe.value]: (
                    <StripeIcon className="balance-transactions-container-item__start-left-payment-icon" />
                ),
                [paymentTypes.iyzico.value]: (
                    <IyzicoIcon className="balance-transactions-container-item__start-left-payment-icon" />
                ),
            },
        },
        payment: {
            title: "balance-transactions-title-payment",
            type: transactionTypes.payment,
            paymentIcon: {
                [paymentTypes.stripe.value]: (
                    <StripeIcon className="balance-transactions-container-item__start-left-payment-icon" />
                ),
                [paymentTypes.iyzico.value]: (
                    <IyzicoIcon className="balance-transactions-container-item__start-left-payment-icon" />
                ),
            },
        },
    },
};
