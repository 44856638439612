export const balanceOnboardingIyzicoModalConfig = [
    {
        label: "balance-label-tax-office",
        regex: false,
        placeholderText: "balance-placeholder-tax-office",
        registerName: "taxOffice",
    },
    {
        label: "balance-label-legal-company-title",
        regex: false,
        placeholderText: "balance-placeholder-legal-company-title",
        registerName: "legalCompanyTitle",
    },
    {
        label: "balance-label-tax-address",
        regex: false,
        placeholderText: "balance-placeholder-tax-address",
        registerName: "address",
    },
    {
        label: "balance-label-gsm-number",
        regex: true,
        placeholderText: "balance-placeholder-gsm-number",
        registerName: "gsmNumber",
    },
    {
        label: "balance-label-iban",
        regex: true,
        placeholderText: "balance-placeholder-iban",
        registerName: "iban",
    },
    {
        label: "balance-label-identity-number",
        regex: true,
        placeholderText: "balance-placeholder-identity-number",
        registerName: "identityNumber",
    },
];
