import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { memo } from "react";
import PropTypes from "prop-types";

import { removeTag, removeTags } from "../../all-result.operations";
import {
    allResultSelector,
    allResultParamsSelector,
} from "../../all-result.selectors";

import { searchedHeaderConfig } from "./searched-header.config";
import { getLanguage } from "../../../../helpers/language";
import { resultTypes } from "../../all-result.constant";
import { Icon } from "../../../image/image";

import "./index.scss";

const SearchedHeader = ({ count, type, countByText, isFavorite, isTop }) => {
    const { text, city, district } = useSelector(allResultParamsSelector);
    const { tags } = useSelector(allResultSelector);
    const { value: language } = getLanguage();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleRemoveTags = () => dispatch(removeTags(type));
    const handleRemoveTag = (e, value) => {
        e.preventDefault();
        dispatch(removeTag(value, type));
    };

    const searchQuery = text ? text.split("=")[1] : "";
    const cityQuery =
        city?.name && ` ${t("order_search.with_city")} ${city?.name} `;

    const districtQuery =
        district?.name &&
        ` ${t("order_search.and_district")} ${district?.name}  `;

    return (
        <div className="all_result__searched_header_global">
            {searchQuery && (
                <div className="all_result__searched_header_info">
                    <div className="all_result__searched_header_info_title">
                        {t("order_search.by_request")} {`"${searchQuery}"`}{" "}
                        {cityQuery}
                        {districtQuery}
                        {countByText ? t("order_search.found") : ""}{" "}
                        {countByText > 0 ? (
                            <span>
                                {countByText} {t("order_search.similar")}
                            </span>
                        ) : (
                            t("order_search.not_found_search")
                        )}{" "}
                        {count > 0 && countByText <= 0
                            ? t("order_search.found_similar")
                            : ""}
                    </div>
                </div>
            )}
            {tags.length > 0 && (
                <div className="all_result__searched_header_services">
                    {tags.map(({ value, label }) => (
                        <button
                            key={value}
                            className="all_result__searched_header_services_item"
                        >
                            <div className="all_result__searched_header_services_item_title">
                                {typeof label === "object"
                                    ? label[language]
                                    : label}
                            </div>
                            <div
                                className="all_result__searched_header_services_item_icon"
                                onClick={(e) => handleRemoveTag(e, value)}
                            >
                                <Icon type="closeCrosshairWhite" />
                            </div>
                        </button>
                    ))}
                    <button
                        className="all_result__searched_header_services_remove"
                        onClick={handleRemoveTags}
                    >
                        {t("order_search.remove_filter")}
                    </button>
                </div>
            )}
            <div className="all_result__searched_header_all_info">
                <div className="all_result__searched_header_all_info_title">
                    {t(
                        searchedHeaderConfig[isFavorite][isTop || "default"][
                            type
                        ]
                    )}
                </div>
                <div className="all_result__searched_header_all_info_count">
                    {type === resultTypes.ORDERS
                        ? t("orders_count")
                        : t("all_service_specialist")}{" "}
                    {count}
                </div>
            </div>
        </div>
    );
};

SearchedHeader.propTypes = {
    count: PropTypes.number,
    type: PropTypes.string,
    countByText: PropTypes.number,
    isFavorite: PropTypes.bool,
    isTop: PropTypes.string,
};

export default memo(SearchedHeader);
