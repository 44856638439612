import * as api from "../../services/requests/blog";
import * as blogActions from "./blog.actions";
import { sizeItemOnPage } from "./blog.constants";
import { blogListParamsSelector } from "./blog.selectors";

export const getBlogList =
    (size = sizeItemOnPage, params = "") =>
    (dispatch, getState) => {
        dispatch(blogActions.getBlogListRequest());

        const { text } = blogListParamsSelector(getState());

        return api
            .getBlogListRequest(`?limit=${size}` + text + params)
            .then((data) => {
                dispatch(blogActions.getBlogListSucces(data));
            })
            .catch((error) => dispatch(blogActions.getBlogListError(error)));
    };

export const getBlog = (id) => (dispatch) => {
    dispatch(blogActions.getBlogRequest());

    return api
        .getBlogRequest(id)
        .then((data) => {
            dispatch(blogActions.getBlogSucces(data));
        })
        .catch((error) => dispatch(blogActions.getBlogError(error)));
};

export const editPageAll = (page, data) => (dispatch) => {
    dispatch(blogActions.setPage(`&page=${page}&category=${data}`));
    const url = `&page=${page}&category=${data}`;
    dispatch(getBlogList(sizeItemOnPage, url));
};

export const editSearchQuery = (data) => (dispatch) => {
    dispatch(blogActions.setSearchQuery(`&text=${data}`));
    dispatch(getBlogList(sizeItemOnPage));
};

export const removeSearchQuery = (setQuery) => (dispatch) => {
    dispatch(blogActions.setSearchQuery(""));
    setQuery("");
    dispatch(getBlogList(sizeItemOnPage));
};
