import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { openModal } from "../../../modal/modal.actions";

import { getLocalizedService } from "../../../../helpers/get-localized-service";
import { typeOfLazeImage } from "../../../lazy-image/lazyImage.constansts";
import { modalConstants } from "../../../modal/modal.constants";
import { getPhotoByTypeGender } from "../../../../helpers/get-photo-by-type-gender";
import routerBook from "../../../../router/router-book";
import LazyImage from "../../../lazy-image/lazy-image";

import { ReactComponent as CurrencyLira } from "../../../../assets/images/currency/lira.svg";
import starIcon from "../../../../assets/images/vectors/star.svg";
import "./index.scss";

const SpecialistsItem = (specialist = {}) => {
    const {
        completedOrders,
        _id: receiverId,
        firstName,
        lastName,
        service,
        avatar,
        rating,
        gender,
        city,
        feedbacks,
    } = specialist;

    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleConnectToSpecialist = () => {
        if (specialist.task)
            return navigate(`${routerBook.chats}/${specialist.task.chatId}`, {
                state: true,
            });
        return dispatch(
            openModal({
                isOpen: true,
                closeIcon: true,
                renderType: modalConstants.selectCustomerOrder,
                details: receiverId,
            })
        );
    };

    const handleOpenSpecialistPage = (e) => {
        e.preventDefault();
        navigate(`${routerBook.specialistsList}/${receiverId}`, {
            state: true,
        });
    };

    return (
        <div className="specialists-item">
            <div className="specialists-item__left">
                <LazyImage
                    image={getPhotoByTypeGender(avatar, gender)}
                    type={typeOfLazeImage.avatar}
                    options={{
                        className: "specialists-item__left-avatar",
                        alt: "avatar",
                    }}
                />
                <div className="specialists-item__left-content">
                    <div className="specialists-item__left-name">
                        {firstName} {lastName}
                    </div>
                    <div className="specialists-item__left-service">
                        {service && getLocalizedService(service.subcategory)}
                    </div>
                    <div className="specialists-item__left-star">
                        <img src={starIcon} alt="star" />
                        <span className="specialists-item__left-star-count">
                            {rating.toFixed(1)}
                        </span>
                        ({feedbacks.length} {t("specialists_recall")})
                    </div>
                    <div className="specialists-item__left-buttons">
                        <button
                            className="specialists-item__left-buttons-primary"
                            onClick={handleConnectToSpecialist}
                        >
                            {t("specialists_to_contact")}
                        </button>
                        <button
                            className="specialists-item__left-buttons-secondary"
                            onClick={handleOpenSpecialistPage}
                        >
                            {t("specialists_questionnaire")}
                        </button>
                    </div>
                </div>
            </div>
            <div className="specialists-item__right">
                <div className="specialists-item__right-item">
                    <div className="specialists-item__right-item-title">
                        {t("specialists_completed_orders")}
                    </div>
                    <div className="specialists-item__right-item-subtitle">
                        {completedOrders}
                    </div>
                </div>
                <div className="specialists-item__right-item">
                    <div className="specialists-item__right-item-title">
                        {t("specialists_price")}
                    </div>
                    <div className="specialists-item__right-item-subtitle">
                        {service?.priceFrom || 0} - {service?.priceTo || 0}{" "}
                        <CurrencyLira />
                    </div>
                </div>
                <div className="specialists-item__right-item">
                    <div className="specialists-item__right-item-title">
                        {t("specialists_city")}
                    </div>
                    <div className="specialists-item__right-item-subtitle">
                        {city}
                    </div>
                </div>
                <div className="specialists-item__right-item">
                    <div className="specialists-item__right-item-title">
                        {t("specialists_area")}
                    </div>
                    <div className="specialists-item__right-item-subtitle">
                        {service?.district || t("not_selected")}
                    </div>
                </div>
                <div className="specialists-item__right-clarification">
                    {t("specialists_clarification")}{" "}
                    {service && getLocalizedService(service.clarification)}
                </div>
            </div>
        </div>
    );
};

export default SpecialistsItem;
