import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import PropTypes from "prop-types";
import { t } from "i18next";

import "./index.scss";

const Pagination = ({
    elementType = "placeholder",
    items = {},
    getNextPageItems = () => {},
    isStartedPage,
    sizeItemOnPage,
}) => {
    const [page, setPage] = useState(0);

    useEffect(() => {
        if (isStartedPage === 0 && page !== 0) {
            setPage(0);
            getNextPageItems(0);
        } else if (typeof isStartedPage === "string") {
            const newPage = isStartedPage.split("=")[1];
            setPage(parseInt(newPage));
        }
    }, [isStartedPage, getNextPageItems, page]);

    const _handleNextList = ({ selected }) => {
        getNextPageItems(selected);
        setPage(selected);
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    };

    if (elementType === "placeholder") return null;
    if (!items.count) return null;

    return (
        <div className="pagination-container">
            <div className="pagination__count">
                {t("all_service_pages_count", {
                    count: Math.ceil(items.count / sizeItemOnPage),
                })}
            </div>
            <div className="pagination__current">
                {t("all_service_current_page", { page: page + 1 })}
            </div>
            {items.count >= sizeItemOnPage && (
                <ReactPaginate
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                    nextLabel={t("btn_next")}
                    previousLabel={t("btn_prev")}
                    pageCount={
                        items.count
                            ? Math.ceil(items.count / sizeItemOnPage)
                            : 1
                    }
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={1}
                    forcePage={page}
                    onPageChange={_handleNextList}
                    disabledClassName={"disabled"}
                />
            )}
        </div>
    );
};

Pagination.propTypes = {
    elementType: PropTypes.string,
    items: PropTypes.object,
    getNextPageItems: PropTypes.func,
    isStartedPage: PropTypes.PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
    ]),
    sizeItemOnPage: PropTypes.number,
};

export default Pagination;
