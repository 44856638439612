import { complainSteps } from "../discussion-work-project/components/chat/components/modals/complaint/complaint.constants";
import { sizeItemOnPage } from "./chats.constants";
import { chatTypes } from "./chats.types";

const initialQueryParams = { limit: sizeItemOnPage, name: "", page: 0 };

const initialState = {
    params: initialQueryParams,
    chatList: { items: [], carousel:[], count: null },
    complaintsTypes: [],
    chat: {
        priceProposal: { approvedBy: null, price: 0, proposedBy: null },
        messages: { items: [], carousel: [], count: null },
        operationsHistory: [],
        subcategoryTitle: "",
        isDisabled: false,
        participants: [],
        participant: {},
        createdAt: "",
        createdBy: "",
        updatedAt: "",
        district: "",
        orderId: "",
        taskId: "",
        _id: "",
    },

    complainStep: complainSteps.first,

    loading: false,
    error: "",
};

export const chats = (state = initialState, { type, payload }) => {
    switch (type) {
        // loading
        case chatTypes.POST_COMPLAINT_PENDING:
        case chatTypes.GET_COMPLAINTS_TYPES_PENDING:
        case chatTypes.POST_CHAT_FILE_PENDING:
        case chatTypes.GET_CHAT_LIST_PENDING:
        case chatTypes.DISABLE_CHAT_PENDING:
        case chatTypes.DELETE_CHAT_PENDING:
        case chatTypes.GET_CHAT_PENDING: {
            return { ...state, loading: true };
        }

        // error
        case chatTypes.POST_COMPLAINT_REJECTED:
        case chatTypes.GET_COMPLAINTS_TYPES_REJECTED:
        case chatTypes.POST_CHAT_FILE_REJECTED:
        case chatTypes.GET_CHAT_LIST_REJECTED:
        case chatTypes.DISABLE_CHAT_REJECTED:
        case chatTypes.DELETE_CHAT_REJECTED:
        case chatTypes.GET_CHAT_REJECTED: {
            return { ...state, loading: false, error: payload };
        }

        // data chat list
        case chatTypes.GET_CHAT_LIST_FULFILLED:
        case chatTypes.DELETE_CHAT_FULFILLED:
            return { ...state, chatList: payload };

        // data chat
        case chatTypes.POST_CHAT_FILE_FULFILLED:
        case chatTypes.DISABLE_CHAT_FULFILLED:
        case chatTypes.GET_CHAT_FULFILLED:
            return { ...state, chat: payload };

        // data complaints types
        case chatTypes.GET_COMPLAINTS_TYPES_FULFILLED:
            return { ...state, loading: false, complaintsTypes: payload };
        case chatTypes.POST_COMPLAINT_FULFILLED:
            return { ...state, loading: false };

        // set
        case chatTypes.CLEAR_ALL_PARAMS:
            return { ...state, params: initialQueryParams };
        case chatTypes.SET_PAGE:
            return { ...state, params: { ...state.params, page: payload } };
        case chatTypes.SET_NAME:
            return { ...state, params: { ...state.params, name: payload } };
        case chatTypes.SET_COMPLAIN_STEP:
            return { ...state, complainStep: payload };

        default:
            return state;
    }
};

export default chats;
