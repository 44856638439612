export const errorHandlerTypes = Object.freeze({
    PAGE_NOT_FOUND: "PAGE_NOT_FOUND",
    NO_INTERNET: "NO_INTERNET",
    SOMETHING_WRONG: "SOMETHING_WRONG",
    ACCESS_DENIED: "ACCESS_DENIED",
    NOT_FOUND_SEARCH: "NOT_FOUND_SEARCH",
    IN_DEVELOPMENT: "IN_DEVELOPMENT",
    BLOCKED_USER: "BLOCKED_USER",
    EMPTY_FAVORITES: "EMPTY_FAVORITES",
});

export const notEnoughFundsError =
    "Not enough funds on balance, please use direct payment or update your balance!";

export const disabledChatError = "Chat is disabled!";

export const emailVerifyErrors = {
    veryOften: "VERY OFTEN",
};
