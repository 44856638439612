import { getItem } from "../../helpers/local-storage";

export const generateBodyForAuthRequest = (authData = {}) => {
    return { ...authData, deviceId: getItem("deviceID") };
};

export const generateBodyForRegistrationRequest = (
    user,
    role,
    city,
    district
) => {
    const updateUser = {
        role,
        city,
        district,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        phone: user.phone,
        gender: user.gender.value,
        birthday: user.birthday,
        createPassword: user.createPassword,
        confirmPassword: user.confirmPassword,
    };
    return generateBodyForAuthRequest(updateUser);
};
