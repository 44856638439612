import { get, post, put } from "../api";

const baseUrl = "/promotion-types";

// get promotion
export const getPromotionRequest = async (params = "") => {
    const url = baseUrl + params;

    try {
        const { data } = await get(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

// get promotion by id
export const getPromotionByIdRequest = async (id) => {
    const url = `${baseUrl}/${id}`;

    try {
        const { data } = await get(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

// buy promotion by id
export const buyPromotionByIdRequest = async (id, reqData = {}) => {
    const url = `${baseUrl}/buy/${id}`;

    try {
        const { data } = await post(url, reqData);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

// get promotion history
export const getPromotionHistoryRequest = async (params = "") => {
    const url = "/promotion-buy-history" + params;

    try {
        const { data } = await get(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

// deactivate promotion
export const deactivatePromotionRequest = async (id) => {
    const url = `/promotion-buy-history/disable-promotion/${id}`;

    try {
        const { data } = await put(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};
