import { citiesLoaderTypes } from "./cities-loader.types";

const initialState = {
    districts: [],

    cities: [],

    error: "",
    loading: false,
};

export const cities = (state = initialState, { type, payload }) => {
    switch (type) {
        // loading
        case citiesLoaderTypes.GET_ALL_CITIES_PENDING: {
            return { ...state, loading: true };
        }

        // data
        case citiesLoaderTypes.GET_ALL_CITIES_FULFILLED: {
            return { ...state, cities: payload, loading: false };
        }

        // error
        case citiesLoaderTypes.GET_ALL_CITIES_REJECTED: {
            return { ...state, error: payload, loading: false };
        }

        //districts
        case citiesLoaderTypes.GET_ALL_DISTRICTS_PENDING: {
            return { ...state, loading: true };
        }
        case citiesLoaderTypes.GET_ALL_DISTRICTS_FULFILLED: {
            return { ...state, districts: payload, loading: false };
        }
        case citiesLoaderTypes.GET_ALL_DISTRICTS_REJECTED: {
            return { ...state, error: payload, loading: false };
        }

        default:
            return state;
    }
};
