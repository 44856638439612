import mobile from "../../../../assets/images/carousel/mobile.png";
import yoga from "../../../../assets/images/carousel/yoga.png";
import cosmetics from "../../../../assets/images/carousel/cosmetics.png";
import handmade from "../../../../assets/images/carousel/handmade.png";
import hairdressing from "../../../../assets/images/carousel/hairdressing.png";
import photography from "../../../../assets/images/carousel/photography.png";
import repair_of_household_appliances from "../../../../assets/images/carousel/repair_of_household_appliances.png";
import home_renovation from "../../../../assets/images/carousel/home_renovation.png";

export const carouselList = [
    { title: "profile_cosmetic_care", image: cosmetics },
    { title: "profile_hairdressing", image: hairdressing },
    { title: "profile_handmade", image: handmade },
    { title: "profile_repair_phone", image: mobile },
    { title: "profile_photography", image: photography },
    { title: "profile_yoga_courses", image: yoga },
    {
        title: "profile_repair_of_household_appliances",
        image: repair_of_household_appliances,
    },
    { title: "profile_home_renovation", image: home_renovation },
];
