import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Fragment, useEffect } from "react";
import { useParams } from "react-router";
import PropTypes from "prop-types";

import { specialistOrdersSelector } from "./redux/specialist-orders.selectors";
import * as specialistOrdersOperations from "./redux/specialist-orders.operations";
import { clearAllParams } from "./redux/specialist-orders.actions";

import SpecialistOrderSidebar from "./components/specialist-orders-sidebar/specialist-orders-sidebar";
import ReactHelmet from "../../components/react-helmet/react-helmet";
import SpecialistOrderList from "./components/specilaist-order-list/specialist-order-list";
import MainButton from "../../components/main-button/main-button";
import routerBook from "../../router/router-book";
import {
    ORDERS_FILTERS_SPECIALIST,
    sizeItemsOnPage,
} from "./specialist-orders.config";

import "./index.scss";

const SpecialistOrders = () => {
    const { orders } = useSelector(specialistOrdersSelector);
    const { filterParameter } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!filterParameter) {
            navigate(
                `${routerBook.accessForSpecialist.orders}/${ORDERS_FILTERS_SPECIALIST.NEGOTIATION}`
            );
        } else {
            dispatch(
                specialistOrdersOperations.editRequestStatusParameter(
                    sizeItemsOnPage,
                    filterParameter
                )
            );
        }
        return () => dispatch(clearAllParams());
    }, []);

    return (
        <Fragment>
            <ReactHelmet title="orders.title" />
            <div className="specialist-orders">
                <div className="specialist-orders__header">
                    <h1 className="specialist-orders__header__title">
                        {t("orders.title")}
                    </h1>

                    <MainButton
                        buttonSize="btn--large"
                        buttonStyle="btn--green"
                        onClick={() =>
                            navigate(routerBook.chats, { state: true })
                        }
                        inlineStyle={{ marginBottom: "0px" }}
                    >
                        {t("orders.buttons.my-chats")}
                    </MainButton>
                </div>
                <div className="specialist-orders__content">
                    <div className="specialist-orders__content__sidebar">
                        <SpecialistOrderSidebar
                            filterParameter={filterParameter}
                        />
                    </div>
                    <div className="specialist-orders__content__list">
                        <SpecialistOrderList
                            orders={orders}
                            filterParameter={filterParameter}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

SpecialistOrders.propTypes = { filterParameter: PropTypes.string };

export default SpecialistOrders;
