import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { clearAllParams } from "../../components/all-result/all-result.actions";
import { getOrders } from "../../components/all-result/all-result.operations";
import { backButtonConfig } from "../../components/back-button/back-button.config";
import { allResultSelector } from "../../components/all-result/all-result.selectors";

import AllServicesResult from "../../components/all-result/all-result";
import ReactHelmet from "../../components/react-helmet/react-helmet";
import BackButton from "../../components/back-button/back-button";
import { sortConfig } from "./specialist-new-orders.config";
import {
    resultTypes,
    sizeItemOnPage,
} from "../../components/all-result/all-result.constant";

import "./index.scss";

const SpecialistNewOrders = () => {
    const { orders } = useSelector(allResultSelector);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(getOrders(sizeItemOnPage));
        return () => dispatch(clearAllParams());
    }, []);

    return (
        <Fragment>
            <ReactHelmet title="order_all" />
            <section className="all-orders-search">
                <div className="all-orders-search__header">
                    <BackButton
                        onClickCallback={() =>
                            navigate(backButtonConfig[location.state].href, {
                                state: true,
                            })
                        }
                        title={t(backButtonConfig[location.state].title)}
                    />
                    <h1 className="all-orders-search__title">
                        {t("order_all")}
                    </h1>
                </div>
                <AllServicesResult
                    type={resultTypes.ORDERS}
                    sortConfig={sortConfig}
                    isFavorite={false}
                    data={orders}
                />
            </section>
        </Fragment>
    );
};

export default SpecialistNewOrders;
