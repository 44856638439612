import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import "./index.scss";

const ScheduleWorkItem = ({
    day,
    isWeekend,
    scheduleTimeAM,
    scheduleTimePM,
    defaultTimeAM,
    defaultTimePM,
    onChangeTimeAM,
    onChangeTimePM,
    onChangeDayStatus,
}) => {
    const { t } = useTranslation();

    const [slicedScheduleListAM, setSlicedScheduleListAM] =
        useState(scheduleTimeAM);
    const [slicedScheduleListPM, setSlicedScheduleListPM] =
        useState(scheduleTimePM);

    const getFormatedTime = (time) =>
        time.includes("Z") ? dayjs(time).format("H:mm") : time;

    const [timePM, setTimePM] = useState(getFormatedTime(defaultTimePM));
    const [timeAM, setTimeAM] = useState(getFormatedTime(defaultTimeAM));

    const getSlicedScheduleListAM = () => {
        const indexArr = scheduleTimeAM.findIndex((time) => time === timePM);
        setSlicedScheduleListAM(scheduleTimeAM.slice(0, indexArr));
    };

    const getSlicedScheduleListPM = () => {
        const indexArr = scheduleTimePM.findIndex((time) => time === timeAM);
        setSlicedScheduleListPM(scheduleTimePM.slice(indexArr + 1));
    };

    useEffect(() => {
        getSlicedScheduleListAM();
        getSlicedScheduleListPM();
    }, [timePM, timeAM, isWeekend, defaultTimeAM, defaultTimePM]);

    return (
        <div className="schedule_work__item">
            <input
                type="checkbox"
                checked={!isWeekend}
                onChange={() => onChangeDayStatus(day)}
                className="schedule_work__checkbox"
            />
            <div className="schedule_work__day">
                {t(`days.${day.toUpperCase()}`)}
            </div>
            {isWeekend ? (
                <div className="schedule_work__weekend">
                    {t("specialist-services.form.weekend")}
                </div>
            ) : (
                <div className="schedule_work__time">
                    <select
                        value={timeAM}
                        onChange={(e) => {
                            setTimeAM(e.target.value);
                            onChangeTimeAM(day, e.target.value);
                        }}
                        className="schedule_work__time--am"
                    >
                        {slicedScheduleListAM.map((time, index) => {
                            if (index < 22) {
                                return (
                                    <option key={`${time}_${index}`}>
                                        {time}
                                    </option>
                                );
                            }
                        })}
                    </select>
                    <select
                        value={timePM}
                        onChange={(e) => {
                            setTimePM(e.target.value);
                            onChangeTimePM(day, e.target.value);
                        }}
                        className={`schedule_work__time--pm`}
                    >
                        {slicedScheduleListPM.map((time, index) => {
                            return (
                                <option key={`${time}_${index}`}>{time}</option>
                            );
                        })}
                    </select>
                </div>
            )}
        </div>
    );
};
ScheduleWorkItem.propTypes = {
    day: PropTypes.string.isRequired,
    isWeekend: PropTypes.bool.isRequired,
    scheduleTimeAM: PropTypes.array,
    scheduleTimePM: PropTypes.array,
    defaultTimeAM: PropTypes.string.isRequired,
    defaultTimePM: PropTypes.string.isRequired,
    onChangeTimeAM: PropTypes.func.isRequired,
    onChangeTimePM: PropTypes.func.isRequired,
    onChangeDayStatus: PropTypes.func.isRequired,
};
export default ScheduleWorkItem;
