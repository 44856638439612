import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { modalInformationSelector } from "./modal.selectors";
import { closeModal } from "./modal.actions";

import ProposeOrderAmount from "../../pages/discussion-work-project/components/chat/components/modals/propose-order-amount/propose-order-amount";
import DeclinePropose from "../../pages/discussion-work-project/components/chat/components/modals/decline-propose/decline-propose";
import ForgotPasswordPending from "../../pages/auth/forgot-password/components/forgot-password-pending/forgot-password-pending";
import SelectOrderModal from "../all-result/components/searched-specialist/components/select-order-modal/select-order-modal";
import RemoveOrder from "../../pages/orders/components/remove-order/remove-order";
import UpdateOrder from "../../pages/orders/components/update-order/update-order";

import { modalConstants } from "./modal.constants";
import Modal from "./modal";
import ServiceForm from "../../pages/specialist-services/components/service-form";
import FeedBackModal from "../../pages/feedback/components/feedback-modal/feedback-modal";
import { clearAllServices } from "../../pages/all-services/all-services.actions";
import CreateOrderForm from "../../pages/create-order/components/create-order-form/create-order-form";
import ImageZoomer from "./image-zoomer/image-zoomer";
import UserNotSelectFields from "./user-not-select-fields/user-not-select-fields";
import CancelOrder from "../../pages/orders/components/cancel-order/cancel-order";
import ReverseFunds from "../../pages/discussion-work-project/components/chat/components/modals/reverse-funds/reverse-funds";
import AgreeWithCommission from "../../pages/discussion-work-project/components/chat/components/modals/agree-with-commission/agree-with-commission";
import Complaint from "../../pages/discussion-work-project/components/chat/components/modals/complaint/complaint";
import Attention from "../../pages/discussion-work-project/components/chat/components/modals/attention/attention";
import Auth from "../all-result/components/searched-specialist/components/auth/auth";
import PromoteForm from "../../pages/my-account/components/promote-form/promote-form";
import DeleteAccountForm from "../../pages/my-account/components/delete-account-form/delete-account-form";
import PromotionModalPay from "../../pages/promotion/components/promotion-modal-pay/promotion-modal-pay";
import CropImage from "../../pages/my-account/components/upload-image/crop-image/crop-image";
import BalanceOnboardingIyzicoModal from "../../pages/balance/components/balance-onboarding-iyzico-modal/balance-onboarding-iyzico-modal";
import ChatCarousel from "../../pages/discussion-work-project/components/chat/components/modals/chat-carousel/chat-carousel";
import PromotionDeclineForm from "../../pages/promotion/components/promotion-decline-form/promotion-decline-form";
import RestoreAccountRequest from "../../pages/auth/restore-account/components/restore-account-request/restore-account-request";
import RestoreAccountPending from "../../pages/auth/restore-account/components/restore-account-pending/restore-account-pending";
import RestoreAccountSuccess from "../../pages/auth/restore-account/components/restore-account-success/restore-account-success";
import NotificationSettingsForm from "../../pages/notifications/components/notification-settings-form/notification-settings-form";
import SupportCreateTicketModal from "../../pages/support/components/support-create-ticket-modal/support-create-ticket-modal";
import SelectIconForm from "../../pages/my-account/components/select-icon-form/select-icon-form";
import DeactivateQuestion from "../../pages/support/components/deactivate-question/deactivate-question";
import PhoneCodeModal from "../../pages/auth/phone-confirmation/components/phone-code-modal";
import PhoneTestCodeModal from "../../pages/auth/phone-confirmation/components/phone-test-code-modal";
import PhoneVerifyForm from "../../pages/my-account/components/phone-verify-form/phone-verify-form";
import SpecialistContactsModal from "../../pages/specialist-profile/components/specialist-contacts-modal/specialist-contacts-modal";
import CountryCodesModal from "../country-codes-dropdown/components/country-codes-modal";
import BalanceReplenishModal from "../../pages/balance/components/balance-replenish-modal/balance-replenish-modal";
import BalancePaymentTypeModal from "../../pages/balance/components/balance-payment-type-modal/balance-payment-type-modal";
import RegisterToView from "../all-result/components/register-to-view/register-to-view";

const RenderModal = () => {
    const dispatch = useDispatch();
    const { isOpen, renderType, details, type, closeIcon } = useSelector(
        modalInformationSelector
    );
    const getModalContent = () => {
        switch (renderType) {
            case modalConstants.forgotPasswordPending:
                return <ForgotPasswordPending type={type} />;
            case modalConstants.removeOrder:
                return <RemoveOrder details={details} type={type} />;
            case modalConstants.cancelOrder:
                return <CancelOrder details={details} type={type} />;
            case modalConstants.updateOrder:
                return <UpdateOrder details={details} type={type} />;

            case modalConstants.proposedOrderAmount:
                return <ProposeOrderAmount details={details} />;

            case modalConstants.declinePropose:
                return <DeclinePropose details={details} />;
            case modalConstants.specialistServiceForm:
                return <ServiceForm />;
            case modalConstants.sendFeedback:
                return <FeedBackModal details={details} />;

            case modalConstants.selectCustomerOrder:
                return <SelectOrderModal details={details} />;

            case modalConstants.createOrder:
                return <CreateOrderForm details={details} />;

            case modalConstants.userNotSelectAllFields:
                return <UserNotSelectFields />;

            case modalConstants.reverseFunds:
                return <ReverseFunds details={details} />;

            case modalConstants.imageZoomer:
                return <ImageZoomer details={details} />;

            case modalConstants.agreeWithCommission:
                return <AgreeWithCommission details={details} />;

            case modalConstants.complain:
                return <Complaint details={details} />;

            case modalConstants.auth:
                return <Auth details={details} />;

            case modalConstants.promote:
                return <PromoteForm details={details} />;

            case modalConstants.attention:
                return <Attention details={details} />;

            case modalConstants.deleteAccount:
                return <DeleteAccountForm details={details} />;

            case modalConstants.payPromote:
                return <PromotionModalPay details={details} />;

            case modalConstants.cropAvatarImage:
                return <CropImage details={details} />;

            case modalConstants.onboardingIyzico:
                return <BalanceOnboardingIyzicoModal />;
            case modalConstants.deactivatePromotion:
                return <PromotionDeclineForm details={details} />;

            case modalConstants.chatCarousel:
                return <ChatCarousel details={details} />;

            case modalConstants.restoreAccountRequest:
                return <RestoreAccountRequest />;
            case modalConstants.restoreAccountPending:
                return <RestoreAccountPending />;
            case modalConstants.restoreAccountSuccess:
                return <RestoreAccountSuccess />;

            case modalConstants.notificationSettings:
                return <NotificationSettingsForm />;
            case modalConstants.createSupportTicket:
                return <SupportCreateTicketModal />;
            case modalConstants.selectIcon:
                return <SelectIconForm details={details} />;

            case modalConstants.registerToView:
                return <RegisterToView />;

            case modalConstants.deactivateQuestion:
                return <DeactivateQuestion details={details} type={type} />;
            case modalConstants.authPhoneCode:
                return <PhoneCodeModal details={details} />;
            case modalConstants.testPhoneCode:
                return <PhoneTestCodeModal details={details} />;
            case modalConstants.accountPhoneCode:
                return <PhoneVerifyForm details={details} />;
            case modalConstants.showSpecialistContacts:
                return <SpecialistContactsModal details={details} />;
            case modalConstants.countryCodes:
                return <CountryCodesModal details={details} />;
            case modalConstants.balanceReplenish:
                return <BalanceReplenishModal details={details} />;
            case modalConstants.balancePaymentType:
                return <BalancePaymentTypeModal details={details} />;

            default:
                return <></>;
        }
    };

    return (
        <Modal
            onClose={() => {
                if (modalConstants.getText === renderType) {
                    details.onClick();
                } else if (
                    renderType === modalConstants.specialistServiceForm
                ) {
                    dispatch(clearAllServices());
                    dispatch(closeModal());
                }
                dispatch(closeModal());
            }}
            status={isOpen}
            closeIcon={closeIcon}
        >
            {getModalContent()}
        </Modal>
    );
};

RenderModal.propTypes = {
    modalInformation: PropTypes.object,
    isOpen: PropTypes.bool,
    renderType: PropTypes.string,
    type: PropTypes.string,
    closeModal: PropTypes.func,
    details: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
};

export default RenderModal;
