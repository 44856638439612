import { specialistServicesTypes } from "./specialist-services.types";

// get all services
export const getSpecialistServicesPending = () => ({
    type: specialistServicesTypes.GET_SPECIALIST_SERVICES_PENDING,
});

export const getSpecialistServicesFulfilled = (data) => ({
    type: specialistServicesTypes.GET_SPECIALIST_SERVICES_FULFILLED,
    payload: data,
});

export const getSpecialistServicesRejected = (error) => ({
    type: specialistServicesTypes.GET_SPECIALIST_SERVICES_REJECTED,
    payload: error,
});

// get service
export const getSpecialistServicePending = () => ({
    type: specialistServicesTypes.GET_SPECIALIST_SERVICE_PENDING,
});

export const getSpecialistServiceFulfilled = (data) => ({
    type: specialistServicesTypes.GET_SPECIALIST_SERVICE_FULFILLED,
    payload: data,
});

export const getSpecialistServiceRejected = (error) => ({
    type: specialistServicesTypes.GET_SPECIALIST_SERVICE_REJECTED,
    payload: error,
});

// post service
export const postSpecialistServicePending = () => ({
    type: specialistServicesTypes.POST_SPECIALIST_SERVICE_PENDING,
});

export const postSpecialistServiceFulfilled = (data) => ({
    type: specialistServicesTypes.POST_SPECIALIST_SERVICE_FULFILLED,
    payload: data,
});

export const postSpecialistServiceRejected = (error) => ({
    type: specialistServicesTypes.POST_SPECIALIST_SERVICE_REJECTED,
    payload: error,
});

export const postSpecialistServiceImageFulfilled = (data) => ({
    type: specialistServicesTypes.POST_SPECIALIST_SERVICE_IMAGE_FULFILLED,
    payload: data,
});

export const postSpecialistServiceImageRejected = (error) => ({
    type: specialistServicesTypes.POST_SPECIALIST_SERVICE_IMAGE_REJECTED,
    payload: error,
});

// update service
export const updateSpecialistServicePending = () => ({
    type: specialistServicesTypes.UPDATE_SPECIALIST_SERVICE_PENDING,
});

export const updateSpecialistServiceFulfilled = (data) => ({
    type: specialistServicesTypes.UPDATE_SPECIALIST_SERVICE_FULFILLED,
    payload: data,
});

export const updateSpecialistServiceRejected = (error) => ({
    type: specialistServicesTypes.UPDATE_SPECIALIST_SERVICE_REJECTED,
    payload: error,
});

// remove service
export const removeSpecialistServicePending = () => ({
    type: specialistServicesTypes.REMOVE_SPECIALIST_SERVICE_PENDING,
});

export const removeSpecialistServiceFulfilled = (data) => ({
    type: specialistServicesTypes.REMOVE_SPECIALIST_SERVICE_FULFILLED,
    payload: data,
});

export const removeSpecialistServiceRejected = (error) => ({
    type: specialistServicesTypes.REMOVE_SPECIALIST_SERVICE_REJECTED,
    payload: error,
});

// remove service image
export const removeSpecialistServiceImagePending = () => ({
    type: specialistServicesTypes.REMOVE_SPECIALIST_SERVICE_IMAGE_PENDING,
});

export const removeSpecialistServiceImageFulfilled = (data) => ({
    type: specialistServicesTypes.REMOVE_SPECIALIST_SERVICE_IMAGE_FULFILLED,
    payload: data,
});

export const removeSpecialistServiceImageRejected = (error) => ({
    type: specialistServicesTypes.REMOVE_SPECIALIST_SERVICE_IMAGE_REJECTED,
    payload: error,
});
