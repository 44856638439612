export const getCroppedImg = (image, crop, setResult, setSelectedImage) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
    );
    if (canvas.width === 0 && canvas.height === 0) return;
    setResult(canvas.toDataURL("image/jpeg", 1));
    canvas.toBlob((blob) => {
        setSelectedImage(
            new File([blob], "fileName.jpg", { type: "image/jpeg" })
        );
    }, "image/jpeg");
};
