import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";

import { chatsNameSelector } from "../../chats.selectors";
import { editChatListName } from "../../chats.operations";

import { sizeItemOnPage } from "../../chats.constants";

import "./index.scss";

const ChatsSearch = () => {
    const defaultName = useSelector(chatsNameSelector);
    const [name, setName] = useState("");
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleSearch = () => dispatch(editChatListName(sizeItemOnPage, name));
    const handleChangeName = ({ target: { value } }) => setName(value);

    return (
        <div className="chats__search">
            <input
                className="chats__search-input"
                onChange={handleChangeName}
                defaultValue={defaultName}
                placeholder={t("search")}
            ></input>
            <button
                disabled={defaultName === name}
                className="chats__search-btn"
                onClick={handleSearch}
            >
                {t("btn_search")}
            </button>
        </div>
    );
};

export default ChatsSearch;
