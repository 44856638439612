import PropTypes from "prop-types";
import ReactCrop from "react-image-crop";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { closeModal } from "../../../../../components/modal/modal.actions";
import { buttonLoaderSelector } from "../../../../../components/loader/loader.selectors";

import { getCroppedImg } from "./crop-image.utils.js";
import { typeOfLazeImage } from "../../../../../components/lazy-image/lazyImage.constansts";

import { Icon } from "../../../../../components/image/image";
import LazyImage from "../../../../../components/lazy-image/lazy-image";
import ButtonLoader from "../../../../../components/loader/components/button-loader/button-loader";

import "./index.scss";
import "react-image-crop/dist/ReactCrop.css";

const CropImage = ({
    details: { file, handleResetUpload, handleSendCroppedImage },
}) => {
    const [selectedImage, setSelectedImage] = useState(null);
    const { status: buttonStatus, button: buttonLoading } =
        useSelector(buttonLoaderSelector);
    const [crop, setCrop] = useState({
        unit: "%",
        width: 75,
        height: 60,
        x: 25,
        y: 25,
    });
    const [result, setResult] = useState(null);
    const [image, setImage] = useState(null);
    const [promt, setPromt] = useState(false);
    const [imageWidth, setImageWidth] = useState(0);
    const [imageHeight, setImageHeight] = useState(0);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleSubmit = (e) => {
        e.preventDefault();
        handleSendCroppedImage(selectedImage);
    };

    const onImgLoad = (image) => {
        const { offsetHeight, offsetWidth } = image;
        setImage(image);
        setImageWidth(offsetWidth);
        setImageHeight(offsetHeight);
    };

    const handleCropImage = () => {
        getCroppedImg(image, crop, setResult, setSelectedImage);
    };

    const handleCancel = () => {
        dispatch(closeModal());
        handleResetUpload();
    };

    return (
        <form className="crop-image" onSubmit={handleSubmit}>
            <div className={`crop-image__promt${promt ? "-active" : ""}`}>
                <div className="crop-image__promt-img">
                    <Icon
                        type={promt ? "circleApproved" : "circleInformation"}
                    />
                </div>

                <div className="crop-image__promt-text">
                    {t("crop_photo_please")}
                </div>
            </div>
            {file && (
                <div className="crop-image__before">
                    <div
                        className={`${
                            imageWidth === imageHeight &&
                            "crop-image__before-wrapper"
                        }`}
                    >
                        <ReactCrop
                            onDragEnd={() => setPromt(true)}
                            className="crop-image__before-img"
                            onImageLoaded={onImgLoad}
                            onChange={setCrop}
                            crop={crop}
                            src={file}
                            aspect={16 / 9}
                        />
                    </div>
                    <button
                        className="crop-image__before-btn"
                        onClick={handleCropImage}
                        type="button"
                    >
                        {t("btn_crop_and_preview")}
                    </button>
                </div>
            )}
            {result && (
                <div className="crop-image__after">
                    <LazyImage
                        options={{ className: "crop-image__after-img" }}
                        type={typeOfLazeImage.content}
                        image={result}
                    />
                    <button
                        className="crop-image__after-btn"
                        disabled={!selectedImage}
                        type="submit"
                        style={{
                            background:
                                buttonStatus &&
                                buttonLoading === "update-avatar" &&
                                "#dfdfdf",
                        }}
                    >
                        {buttonStatus && buttonLoading === "update-avatar" ? (
                            <ButtonLoader size={38} />
                        ) : (
                            t("btn_change")
                        )}
                    </button>
                </div>
            )}
            <button
                className="crop-image__btn"
                onClick={handleCancel}
                type="button"
            >
                {t("btn_cancel")}
            </button>
        </form>
    );
};

CropImage.propTypes = { details: PropTypes.object.isRequired };
CropImage.defaultProps = { details: {} };
export default CropImage;
