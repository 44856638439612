export const orderTypes = {
    // get all orders
    GET_ALL_ORDERS_PENDING: "order/GET_ALL_ORDERS_PENDING",
    GET_ALL_ORDERS_FULFILLED: "order/GET_ALL_ORDERS_FULFILLED",
    GET_ALL_ORDERS_REJECTED: "order/GET_ALL_ORDERS_REJECTED",

    // get all orders for favourites
    GET_ALL_ORDERS_FOR_FAVOURITES_PENDING: "order/GET_ALL_ORDERS_FOR_FAVOURITES_PENDING",
    GET_ALL_ORDERS_FOR_FAVOURITES_FULFILLED: "order/GET_ALL_ORDERS_FOR_FAVOURITES_FULFILLED",
    GET_ALL_ORDERS_FOR_FAVOURITES_REJECTED: "order/GET_ALL_ORDERS_FOR_FAVOURITES_REJECTED",

    // get order
    GET_ORDER_PENDING: "order/GET_ORDER_PENDING",
    GET_ORDER_FULFILLED: "order/GET_ORDER_FULFILLED",
    GET_ORDER_REJECTED: "order/GET_ORDER_REJECTED",

    // get order task
    GET_ORDER_TASK_PENDING: "order/GET_ORDER_TASK_PENDING",
    GET_ORDER_TASK_FULFILLED: "order/GET_ORDER_TASK_FULFILLED",
    GET_ORDER_TASK_REJECTED: "order/GET_ORDER_TASK_REJECTED",

    // get order task other specialist
    GET_ORDER_TASK_OTHER_SPECIALIST_PENDING:
        "order/GET_ORDER_TASK_OTHER_SPECIALIST_PENDING",
    GET_ORDER_TASK_OTHER_SPECIALIST_FULFILLED:
        "order/GET_ORDER_TASK_OTHER_SPECIALIST_FULFILLED",
    GET_ORDER_TASK_OTHER_SPECIALIST_REJECTED:
        "order/GET_ORDER_TASK_OTHER_SPECIALIST_REJECTED",

    // get order responders
    GET_ORDER_RESPONDERS_PENDING: "order/GET_ORDER_RESPONDERS_PENDING",
    GET_ORDER_RESPONDERS_FULFILLED: "order/GET_ORDER_RESPONDERS_FULFILLED",
    GET_ORDER_RESPONDERS_REJECTED: "order/GET_ORDER_RESPONDERS_REJECTED",

    // post order
    POST_ORDER_PENDING: "order/POST_ORDER_PENDING",
    POST_ORDER_FULFILLED: "order/POST_ORDER_FULFILLED",
    POST_ORDER_REJECTED: "order/POST_ORDER_REJECTED",

    // post order image
    POST_ORDER_IMAGE_PENDING: "order/POST_ORDER_IMAGE_PENDING",
    POST_ORDER_IMAGE_FULFILLED: "order/POST_ORDER_IMAGE_FULFILLED",
    POST_ORDER_IMAGE_REJECTED: "order/POST_ORDER_IMAGE_REJECTED",

    // update order
    UPDATE_ORDER_PENDING: "order/UPDATE_ORDER_PENDING",
    UPDATE_ORDER_FULFILLED: "order/UPDATE_ORDER_FULFILLED",
    UPDATE_ORDER_REJECTED: "order/UPDATE_ORDER_REJECTED",

    // update task status
    PUT_TASK_STATUS_PENDING: "order/PUT_TASK_STATUS_PENDING",
    PUT_TASK_STATUS_FULFILLED: "order/PUT_TASK_STATUS_FULFILLED",
    PUT_TASK_STATUS_REJECTED: "order/PUT_TASK_STATUS_REJECTED",

    // update task fix price
    PUT_TASK_FIX_PRICE_PENDING: "order/PUT_TASK_FIX_PRICE_PENDING",
    PUT_TASK_FIX_PRICE_FULFILLED: "order/PUT_TASK_FIX_PRICE_FULFILLED",
    PUT_TASK_FIX_PRICE_REJECTED: "order/PUT_TASK_FIX_PRICE_REJECTED",

    // remove order
    REMOVE_ORDER_PENDING: "order/REMOVE_ORDER_PENDING",
    REMOVE_ORDER_FULFILLED: "order/REMOVE_ORDER_FULFILLED",
    REMOVE_ORDER_REJECTED: "order/REMOVE_ORDER_REJECTED",

    // remove order image
    REMOVE_ORDER_IMAGE_PENDING: "order/REMOVE_ORDER_IMAGE_PENDING",
    REMOVE_ORDER_IMAGE_FULFILLED: "order/REMOVE_ORDER_IMAGE_FULFILLED",
    REMOVE_ORDER_IMAGE_REJECTED: "order/REMOVE_ORDER_IMAGE_REJECTED",

    // reserve funds
    RESERVE_FUNDS_PENDING: "order/RESERVE_FUNDS_PENDING",
    RESERVE_FUNDS_FULFILLED: "order/RESERVE_FUNDS_FULFILLED",
    RESERVE_FUNDS_REJECTED: "order/RESERVE_FUNDS_REJECTED",

    // set
    CLEAR_ALL_PARAMS: "order/CLEAR_ALL_PARAMS",
    SET_STATUS: "order/SET_STATUS",
    SET_PAGE: "order/SET_PAGE",
};
