import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import React from "react";

import { getLanguage } from "../../helpers/language";

const ReactHelmet = ({
    description = "app_description",
    image = null,
    title = "",
}) => {
    const { value: language } = getLanguage();
    const { t } = useTranslation();

    return (
        <Helmet>
            <title>{`Wconnect${title && ` - ${t(title)}`}`}</title>
            <meta name="description" content={t(description)} />
            <html lang={language} />
            <meta property="og:url" content="https://wconnect.work" />
            <meta property="og:type" content="article" />
            <meta
                property="og:title"
                content={`Wconnect${title && ` - ${t(title)}`}`}
            />
            <meta property="og:description" content={t(description)} />
            <meta property="og:image:width" content="500" />
            <meta property="og:image:height" content="500" />
            <meta
                property="og:image"
                content={
                    image ||
                    "https://backend-dev.wconnect.lampawork.com/assets/main/logo.jpg"
                }
            />
        </Helmet>
    );
};

ReactHelmet.propTypes = {
    description: PropTypes.string,
    title: PropTypes.string,
    image: PropTypes.any,
};

export default ReactHelmet;
