import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import dayjs from "dayjs";

import { customerPromotionTypesConstants } from "../../../promotion/promotion.constants";
import { getPromotion } from "../../../promotion/promotion.operations";
import { promotionSelector } from "../../../promotion/promotion.selectors";
import { openModal } from "../../../../components/modal/modal.actions";
import { modalConstants } from "../../../../components/modal/modal.constants";
import { typeOfLazeImage } from "../../../../components/lazy-image/lazyImage.constansts";
import MainButton from "../../../../components/main-button/main-button";
import LazyImage from "../../../../components/lazy-image/lazy-image";
import ageToString from "../../../../helpers/age-to-string";
import { Icon } from "../../../../components/image/image";
import { getPhotoByTypeGender } from "../../../../helpers/get-photo-by-type-gender";
import { ReactComponent as PopularIcon } from "../../../../assets/images/vectors/popular.svg";

import "./index.scss";

const ProfileMainInformation = ({
    selectedSpecialist: {
        firstName,
        lastName,
        avatar,
        gender,
        city,
        rating,
        popular,
        age,
        createdAt,
        _id: receiverId,
        completedOrders,
        feedbacks,
        contactBought,
        email,
        phone,
    },
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { allPromotion } = useSelector(promotionSelector);
    const { items } = allPromotion;
    const buyContactPromotion = items.find(
        (item) => item.type === customerPromotionTypesConstants.BUY_CONTACT
    );

    useEffect(() => {
        dispatch(getPromotion());
    }, []);

    const calcOnSiteTime = () => {
        const result = dayjs().format("YYYY") - dayjs(createdAt).format("YYYY");

        if (!result) return t("year_0");

        return `${result} ${t(ageToString(result))}`;
    };

    calcOnSiteTime();

    const handleConnectToSpecialist = () => {
        dispatch(
            openModal({
                isOpen: true,
                closeIcon: true,
                renderType: modalConstants.selectCustomerOrder,
                details: receiverId,
            })
        );
    };

    const showContactsHandler = () => {
        dispatch(
            openModal({
                isOpen: true,
                closeIcon: false,
                renderType: modalConstants.showSpecialistContacts,
                details: {
                    promotionId: buyContactPromotion._id,
                    specialistId: receiverId,
                },
            })
        );
    };

    const renderSpecialistPhoneNumber = (phone) => {
        if (phone?.dialCode) {
            return phone.dialCode + phone.number;
        } else return phone.number;
    };

    return (
        <div className="profile-main-information">
            <LazyImage
                image={getPhotoByTypeGender(avatar, gender)}
                type={typeOfLazeImage.avatar}
                options={{
                    className: "profile-main-information__avatar",
                    alt: "avatar",
                }}
            />
            <span className="profile-main-information__name">
                {`${firstName} ${lastName}`}
                {popular && (
                    <PopularIcon className="profile-main-information__name-popular" />
                )}
            </span>
            <div className="profile-main-information__reviews-block">
                <span className="profile-main-information__divider" />
                <div className="profile-main-information__reviews-block__container">
                    <div className="profile-main-information__reviews-block__stars">
                        <Icon type="star" /> {rating.toFixed(1)}
                    </div>
                    <div className="profile-main-information__reviews-block__reviews">
                        <Icon type="review" />
                        {feedbacks.length}
                    </div>
                </div>
            </div>
            <div className="profile-main-information__general-metrics profile-main-information__general-metrics-title">
                <p className="profile-main-information__general-metrics__option profile-main-information__general-metrics__option-main">
                    {t("specialist-profile.profile.general")}
                </p>
            </div>
            <span className="profile-main-information__divider" />
            <div className="profile-main-information__general-metrics">
                <div className="profile-main-information__general-metrics__left-column">
                    <p className="profile-main-information__general-metrics__option">
                        {t("specialist-profile.profile.executed-orders")}
                    </p>
                    <p className="profile-main-information__general-metrics__option">
                        {t("specialist-profile.profile.age")}
                    </p>
                    <p className="profile-main-information__general-metrics__option">
                        {t("specialist-profile.profile.on-site")}
                    </p>
                    <p className="profile-main-information__general-metrics__option">
                        {t("specialist-profile.profile.city")}
                    </p>
                </div>
                <div className="profile-main-information__general-metrics__right-column">
                    <p className="profile-main-information__general-metrics__value">
                        {completedOrders}
                    </p>
                    <p className="profile-main-information__general-metrics__value">
                        {age} {t(ageToString(age))}
                    </p>
                    <p className="profile-main-information__general-metrics__value">
                        {calcOnSiteTime()}
                    </p>
                    <p className="profile-main-information__general-metrics__value">
                        {city}
                    </p>
                </div>
            </div>
            <span className="profile-main-information__divider" />
            <div className="profile-main-information__general-metrics profile-main-information__general-metrics-title">
                <p className="profile-main-information__general-metrics__option profile-main-information__general-metrics__option-main">
                    {t("specialist-profile.contacts.title")}
                </p>
                {!contactBought && (
                    <p
                        className="profile-main-information__general-metrics__show-button"
                        onClick={showContactsHandler}
                    >
                        {t("specialist-profile.contacts.show-button")}
                    </p>
                )}
            </div>
            <span className="profile-main-information__divider" />
            {contactBought && (
                <>
                    <div className="profile-main-information__general-metrics">
                        <div className="profile-main-information__general-metrics__left-column">
                            <p className="profile-main-information__general-metrics__option">
                                {t("my_account_phone")}:
                            </p>
                            <p className="profile-main-information__general-metrics__option">
                                {t("my_account_email")}:
                            </p>
                            <p className="profile-main-information__general-metrics__option">
                                Facebook:
                            </p>
                            <p className="profile-main-information__general-metrics__option">
                                Instagram:
                            </p>
                        </div>
                        <div className="profile-main-information__general-metrics__right-column profile-main-information__general-metrics__right-column-contacts">
                            <p className="profile-main-information__general-metrics__value">
                                {renderSpecialistPhoneNumber(phone)}
                            </p>
                            <p className="profile-main-information__general-metrics__value">
                                {email && email}
                            </p>
                            <p className="profile-main-information__general-metrics__value">
                                www.facebook.com
                            </p>
                            <p className="profile-main-information__general-metrics__value">
                                www.instagram.com
                            </p>
                        </div>
                    </div>
                    <span className="profile-main-information__divider" />
                </>
            )}
            <MainButton
                buttonSize="btn--medium"
                buttonStyle="btn--green"
                onClick={handleConnectToSpecialist}
            >
                {t("specialist-profile.profile.contact-button")}
            </MainButton>
        </div>
    );
};

ProfileMainInformation.propTypes = {
    selectedSpecialist: PropTypes.object.isRequired,
};

export default ProfileMainInformation;
