import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import React from "react";

import { myAccountDataSelector } from "../../../../../../pages/my-account/my-account.selectors";

import { signedConfig, unsignedConfig } from "./header-content.config";
import MainButton from "../../../../../main-button/main-button";
import routerBook from "../../../../../../router/router-book";
import HeaderProfile from "../header-profile/header-profile";

import { ReactComponent as UnreadIcon } from "../../../../../../assets/images/vectors/readNotification.svg";
import notifications from "../../../../../../assets/images/header-icons/arrow_notifications.svg";
import favorites from "../../../../../../assets/images/header-icons/arrow_favorites.svg";

import "./index.scss";

const HeaderContent = ({ isBanned, burgerHandler }) => {
    const { unreadNotifications } = useSelector(myAccountDataSelector);
    const { role } = useSelector(myAccountDataSelector);
    const navigate = useNavigate();
    const { t } = useTranslation();

    return !!role ? (
        <>
            <nav className="header_container__nav_block_signed">
                {!isBanned && (
                    <>
                        {signedConfig[role].map(({ name, path, state }) => (
                            <NavLink
                                className="header_container__nav_block_signed_link"
                                state={state}
                                key={name}
                                to={path}
                            >
                                <span className="header_container__nav_block_signed_text">
                                    {t(name)}
                                </span>
                            </NavLink>
                        ))}
                        <NavLink
                            to={routerBook.favorites}
                            state={true}
                            className="header_container__nav_block_signed_favorites"
                        >
                            <img
                                className="header_container__nav_block_signed_favorites_img"
                                src={favorites}
                                alt="favorites"
                            />
                            {/* <UnreadIcon className="header_container__nav_block_signed_favorites_unread" /> */}
                        </NavLink>
                        <NavLink
                            to={routerBook.notifications}
                            state={true}
                            className="header_container__nav_block_signed_notifications"
                        >
                            <img
                                className="header_container__nav_block_signed_notifications_img"
                                src={notifications}
                                alt="notifications"
                            />
                            {unreadNotifications > 0 && (
                                <UnreadIcon className="header_container__nav_block_signed_notifications_unread" />
                            )}
                        </NavLink>
                    </>
                )}
                <HeaderProfile isBanned={isBanned} />
            </nav>
            <nav className="mobile-header__navigation">
                {!isBanned && (
                    <>
                        {signedConfig[role].map(({ name, path, state }) => (
                            <NavLink
                                className="mobile-header__navigation_link"
                                onClick={burgerHandler}
                                state={state}
                                key={name}
                                to={path}
                            >
                                <span className="mobile-header__navigation_link-text">
                                    {t(name)}
                                </span>
                            </NavLink>
                        ))}
                        <NavLink
                            to={routerBook.favorites}
                            className="mobile-header__navigation_link"
                            onClick={burgerHandler}
                            state={true}
                        >
                            <span className="mobile-header__navigation_link-text">
                                {t("nav-favorites")}
                            </span>
                            <UnreadIcon className="mobile-header__navigation_unread-favorites" />
                        </NavLink>
                        <NavLink
                            to={routerBook.notifications}
                            className="mobile-header__navigation_link"
                            onClick={burgerHandler}
                            state={true}
                        >
                            <span className="mobile-header__navigation_link-text">
                                {t("notifications_title")}
                            </span>
                            {unreadNotifications > 0 && (
                                <UnreadIcon className="mobile-header__navigation_unread-notification" />
                            )}
                        </NavLink>
                    </>
                )}
                <HeaderProfile
                    isBanned={isBanned}
                    burgerHandler={burgerHandler}
                />
            </nav>
        </>
    ) : (
        <>
            <nav className="header_container__nav_block_unsigned">
                {unsignedConfig.map(({ name, path, state }) => (
                    <NavLink
                        className="header_container__nav_link"
                        state={state}
                        key={name}
                        to={path}
                    >
                        <h4 className="header_container__nav_text">
                            {t(name)}
                        </h4>
                    </NavLink>
                ))}
                <MainButton
                    buttonSize="btn--medium"
                    onClick={() =>
                        navigate(routerBook.auth.accountType, { state: true })
                    }
                >
                    {t("registration")}
                </MainButton>
            </nav>
            <nav className="mobile-header__navigation">
                {unsignedConfig.map(({ name, path, state }) => (
                    <NavLink
                        className="mobile-header__navigation_link"
                        onClick={burgerHandler}
                        state={state}
                        key={name}
                        to={path}
                    >
                        <span className="mobile-header__navigation_link-text">
                            {t(name)}
                        </span>
                    </NavLink>
                ))}
                <button
                    onClick={() => {
                        burgerHandler();
                        navigate(routerBook.auth.accountType, { state: true });
                    }}
                    className="mobile-header__navigation_link mobile-header__navigation_button"
                >
                    <span className="mobile-header__navigation_link-text">
                        {t("registration")}
                    </span>
                </button>
            </nav>
        </>
    );
};

HeaderContent.propTypes = {
    isBanned: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    burgerHandler: PropTypes.func,
};

export default HeaderContent;
