import { ReactComponent as MainLogo } from "../../assets/images/wconnect_logo.svg";

import { ReactComponent as Roller } from "../../assets/images/category-icons/roller.svg";
import { ReactComponent as Home } from "../../assets/images/category-icons/home.svg";
import { ReactComponent as Jack } from "../../assets/images/category-icons/jack.svg";
import { ReactComponent as Todo } from "../../assets/images/category-icons/todo.svg";
import { ReactComponent as Car } from "../../assets/images/category-icons/car.svg";
import { ReactComponent as Foot } from "../../assets/images/category-icons/foot.svg";

import { ReactComponent as Wrench } from "../../assets/images/category-icons/wrench.svg";
import { ReactComponent as Camera } from "../../assets/images/category-icons/camera.svg";
import { ReactComponent as Chair } from "../../assets/images/category-icons/chair.svg";
import { ReactComponent as ArrowLongRight } from "../../assets/images/arrows-icons/arrow_long_right.svg";
import { ReactComponent as ArrowLeftGreen } from "../../assets/images/arrows-icons/arrow_left_green.svg";
import { ReactComponent as ArrowDownGreen } from "../../assets/images/arrows-icons/arrow_down.svg";
import { ReactComponent as ArrowLeftWhite } from "../../assets/images/arrows-icons/arrow_left_white.svg";
import { ReactComponent as ArrowRightWhite } from "../../assets/images/arrows-icons/arrow_right_white.svg";

import { ReactComponent as CloseCrosshairGray } from "../../assets/images/close-icons/close-crosshair-gray.svg";
import { ReactComponent as CloseCrosshairWhite } from "../../assets/images/close-icons/close-crosshair-white.svg";
import { ReactComponent as EyeHidden } from "../../assets/images/vectors/eye_hidden.svg";
import { ReactComponent as EyeShowed } from "../../assets/images/vectors/eye_showed.svg";
import { ReactComponent as Chat } from "../../assets/images/vectors/chat.svg";
import { ReactComponent as Like } from "../../assets/images/vectors/like.svg";
import { ReactComponent as Report } from "../../assets/images/vectors/report.svg";
import { ReactComponent as CircleApproved } from "../../assets/images/vectors/circle_approved.svg";
import { ReactComponent as CircleInformation } from "../../assets/images/vectors/circle_information.svg";
import { ReactComponent as SettingsIcon } from "../../assets/images/vectors/settings_icon.svg";
import { ReactComponent as ChatPrice } from "../../assets/images/chat/service/price.svg";
import { ReactComponent as ChatApprove } from "../../assets/images/chat/service/approve.svg";
import { ReactComponent as ChatClose } from "../../assets/images/chat/service/close.svg";

import { ReactComponent as FaceBookFooter } from "../../assets/images/footer-icons/facebook-footer.svg";
import { ReactComponent as FaceBook } from "../../assets/images/footer-icons/facebook.svg";
import { ReactComponent as Vkontakte } from "../../assets/images/footer-icons/vkontakte.svg";
import { ReactComponent as Instagram } from "../../assets/images/footer-icons/instagram.svg";
import { ReactComponent as Youtube } from "../../assets/images/footer-icons/youtube.svg";
import { ReactComponent as Playmarket } from "../../assets/images/footer-icons/playmarket.svg";
import { ReactComponent as AppStore } from "../../assets/images/footer-icons/appstore.svg";
import { ReactComponent as Linkedin } from "../../assets/images/footer-icons/linkedin.svg";
import { ReactComponent as TikTok } from "../../assets/images/footer-icons/tiktok.svg";
import { ReactComponent as Twiiter } from "../../assets/images/footer-icons/twitter.svg";

import { ReactComponent as CameraGreen } from "../../assets/images/vectors/camera_green.svg";
import { ReactComponent as AddPhotoCircle } from "../../assets/images/registration-icons/add_photo_circle.svg";
import { ReactComponent as Undownload } from "../../assets/images/registration-icons/undownload.svg";
import { ReactComponent as DateOfBithday } from "../../assets/images/registration-icons/date_of_bithday.svg";
import { ReactComponent as EmailCheckboxArrow } from "../../assets/images/registration-icons/email_checkbox_arrow.svg";
import { ReactComponent as TriangleArrowDown } from "../../assets/images/registration-icons/triangle_arrow_down.svg";
import { ReactComponent as EmailSend } from "../../assets/images/auth/email_send.svg";
import { ReactComponent as EnFlag } from "../../assets/images/header-icons/en.svg";
import { ReactComponent as RuFlag } from "../../assets/images/header-icons/ru.svg";
import { ReactComponent as TrFlag } from "../../assets/images/header-icons/tr.svg";

import { ReactComponent as Star } from "../../assets/images/specialist-profile/star.svg";
import { ReactComponent as Review } from "../../assets/images/specialist-profile/review.svg";

import { ReactComponent as ChatService } from "../../assets/images/vectors/chat_service.svg";
import { ReactComponent as AttachFile } from "../../assets/images/vectors/attach_file.svg";
import { ReactComponent as ChooseEmoji } from "../../assets/images/vectors/choose_emoji.svg";
import { ReactComponent as SendMessage } from "../../assets/images/vectors/send_message.svg";
import { ReactComponent as SuccessMessage } from "../../assets/images/chat/success_message.svg";
import { ReactComponent as TopVectorTip } from "../../assets/images/chat/top_vector_tip.svg";
import { ReactComponent as TopVectorTipGray } from "../../assets/images/chat/top_vector_tip_gray.svg";
import { ReactComponent as AvatarGreenWconnect } from "../../assets/images/chat/avatar_green_wconnect.svg";
import { ReactComponent as ChooseVideo } from "../../assets/images/vectors/choose_video.svg";

import { ReactComponent as User } from "../../assets/images/orders/user.svg";
import { ReactComponent as EditOrder } from "../../assets/images/orders/edit-order.svg";
import { ReactComponent as DeleteOrder } from "../../assets/images/orders/delete-order.svg";
import { ReactComponent as EditService } from "../../assets/images/specialist-services/edit-service.svg";
import { ReactComponent as DeleteService } from "../../assets/images/specialist-services/delete-service.svg";
import { ReactComponent as SandClock } from "../../assets/images/orders/sand-clock.svg";
import { ReactComponent as NoReviews } from "../../assets/images/orders/no-reviews.svg";
import { ReactComponent as CurrencyLira } from "../../assets/images/currency/lira.svg";
import { ReactComponent as FiltersIcon } from "../../assets/images/vectors/filters_icon.svg";
import { ReactComponent as StartIcon } from "../../assets/images/video-player/start.svg";
import { ReactComponent as DownloadIcon } from "../../assets/images/video-player/download.svg";
import { ReactComponent as PrevIcon } from "../../assets/images/video-player/prev.svg";
import { ReactComponent as NextIcon } from "../../assets/images/video-player/next.svg";
import { ReactComponent as StopIcon } from "../../assets/images/video-player/stop.svg";
import { ReactComponent as VolumeOn } from "../../assets/images/video-player/volume-on.svg";
import { ReactComponent as VolumeOff } from "../../assets/images/video-player/volume-off.svg";

import { ReactComponent as SearchLoop } from "../../assets/images/vectors/search-loop.svg";

export const image = {
    mainLogo: <MainLogo />,

    // categories
    roller: <Roller />,
    home: <Home />,
    jack: <Jack />,
    todo: <Todo />,
    car: <Car />,
    foot: <Foot />,
    wrench: <Wrench />,
    chair: <Chair />,
    camera: <Camera />,

    // rest
    arrowRightWhite: <ArrowRightWhite />,
    arrowLeftWhite: <ArrowLeftWhite />,
    arrowLongRight: <ArrowLongRight />,
    arrowLeftGreen: <ArrowLeftGreen />,
    arrowDownGreen: <ArrowDownGreen />,
    closeCrosshairGray: <CloseCrosshairGray />,
    closeCrosshairWhite: <CloseCrosshairWhite />,
    circleApproved: <CircleApproved />,
    circleInformation: <CircleInformation />,
    settingsIcon: <SettingsIcon />,
    eyeHidden: <EyeHidden />,
    eyeShowed: <EyeShowed />,
    cameraGreen: <CameraGreen />,
    chat: <Chat />,
    like: <Like />,
    report: <Report />,
    filtersIcon: <FiltersIcon />,

    //chat
    avatarGreenWconnect: <AvatarGreenWconnect />,
    chatService: <ChatService />,
    attachFile: <AttachFile />,
    chooseEmoji: <ChooseEmoji />,
    chooseVideo: <ChooseVideo />,
    sendMessage: <SendMessage />,
    successMessage: <SuccessMessage />,
    topVectorTip: <TopVectorTip />,
    topVectorTipGray: <TopVectorTipGray />,
    // chat services
    chatPrice: <ChatPrice />,
    chatApprove: <ChatApprove />,
    chatClose: <ChatClose />,

    // footer
    facebookFooter: <FaceBookFooter />,
    facebook: <FaceBook />,
    vkontakte: <Vkontakte />,
    instagram: <Instagram />,
    youtube: <Youtube />,
    playmarket: <Playmarket />,
    appStore: <AppStore />,
    linkedin: <Linkedin />,
    twitter: <Twiiter />,
    tiktok: <TikTok />,

    //auth
    emailSend: <EmailSend />,

    //registration
    addPhotoCircle: <AddPhotoCircle />,
    undownload: <Undownload />,
    dateOfBithday: <DateOfBithday />,
    emailCheckboxArrow: <EmailCheckboxArrow />,
    triangleArrowDown: <TriangleArrowDown />,

    //header
    en: <EnFlag />,
    ru: <RuFlag />,
    tr: <TrFlag />,

    //specialist-profile

    star: <Star />,
    review: <Review />,

    //orders
    user: <User />,
    editOrder: <EditOrder />,
    deleteOrder: <DeleteOrder />,
    sandClock: <SandClock />,

    // specialist-service
    deleteService: <DeleteService />,
    editService: <EditService />,
    noReviews: <NoReviews />,

    // payment
    currencyLira: <CurrencyLira />,

    // video player
    startIcon: <StartIcon />,
    downloadIcon: <DownloadIcon />,
    prevIcon: <PrevIcon />,
    nextIcon: <NextIcon />,
    stopIcon: <StopIcon />,
    volumeOn: <VolumeOn />,
    volumeOff: <VolumeOff />,

    searchLoopIcon: <SearchLoop />,
};

export const Icon = ({ type }) => image[type];
