import { PersistGate } from "redux-persist/integration/react";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import React, { Suspense } from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "./localization/i18n";

import { persistor, store } from "./store/store";
import { initFacebookSdk } from "./pages/auth/oauth-login/oauth-login.utils";
import App from "./app";

initFacebookSdk();

ReactDOM.render(
    <React.StrictMode>
        <Suspense fallback={<div>Loading...</div>}>
            <GoogleOAuthProvider clientId="381223067139-is6dpkfuj3bt8sjetrs0qa403gbp13rb.apps.googleusercontent.com">
                <PersistGate loading={null} persistor={persistor}>
                    <BrowserRouter>
                        <Provider store={store}>
                            <HelmetProvider>
                                <App />
                            </HelmetProvider>
                        </Provider>
                    </BrowserRouter>
                </PersistGate>
            </GoogleOAuthProvider>
        </Suspense>
    </React.StrictMode>,
    document.getElementById("root")
);
