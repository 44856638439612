import PropTypes from "prop-types";
import { useState } from "react";

import { Icon } from "../../../components/image/image";

import { getLanguage } from "../../../helpers/language";

const FAQItem = ({ titleTranslations = {}, descriptionTranslations = {} }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { value: lang = "en" } = getLanguage();
    return (
        <div onClick={() => setIsOpen(!isOpen)} className="faq__content-item">
            <div className="faq__content-item-title">
                <div className="faq__content-item-title-text">
                    {titleTranslations[lang]}
                </div>
                <div
                    className={`faq__content-item-title-img${
                        isOpen ? "--active" : ""
                    }`}
                >
                    <Icon type="arrowDownGreen" />
                </div>
            </div>
            {isOpen && (
                <div
                    className={`faq__content-item-description${
                        isOpen ? "--active" : ""
                    }`}
                >
                    {descriptionTranslations[lang]}
                </div>
            )}
        </div>
    );
};

FAQItem.propTypes = {
    titleTranslations: PropTypes.object,
    descriptionTranslations: PropTypes.object,
};

export default FAQItem;
