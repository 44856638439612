import { destroy, formDataHeaders, post } from "../api";

import { userRoles } from "../../constants/user-roles";

export const sendLoginDataRequest = async (reqData = {}) => {
    const url = `/auth/login`;

    try {
        const { data } = await post(url, reqData);

        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;

        throw { errorData, status };
    }
};

export const sendOauthRequest = async (reqData = {}) => {
    const url = `/auth/oauth`;

    try {
        const { data } = await post(url, reqData);

        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;

        throw { errorData, status };
    }
};

export const sendOauthRegistrationRequest = async (reqData = {}, token) => {
    const url = `/auth/registration/oauth`;

    try {
        const { data } = await post(url, reqData, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;

        throw { errorData, status };
    }
};

export const getUserProfileFromGoogle = async (token) => {
    const url = `https://www.googleapis.com/oauth2/v2/userinfo?access_token=${token}`;

    return fetch(url);
};

export const sendEmailVerifyDataRequest = async (reqData = {}) => {
    const url = `/auth/email-verify`;

    try {
        const { data } = await post(url, reqData);

        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;

        throw { errorData, status };
    }
};

export const sendEmailConfirmationDataRequest = async (reqData = {}) => {
    const url = `/auth/check-email-code`;

    try {
        const { data } = await post(url, reqData);

        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;

        throw { errorData, status };
    }
};

export const sendPhoneVerifyDataRequest = async (reqData = {}) => {
    const url = `/auth/phone-verify`;

    try {
        const { data } = await post(url, reqData);

        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;

        throw { errorData, status };
    }
};

export const sendPhoneConfirmationDataRequest = async (reqData = {}) => {
    const url = `/auth/check-phone-code`;

    try {
        const { data } = await post(url, reqData);

        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;

        throw { errorData, status };
    }
};

export const sendRegistrationDataRequest = async (reqData = {}, role) => {
    const url =
        role === userRoles.customer
            ? "/auth/registration/customer"
            : "/auth/registration/specialist";

    try {
        const { data } = await post(url, reqData);

        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;

        throw { errorData, status };
    }
};

export const sendRefreshTokenRequest = async (reqData = {}) => {
    const url = `/auth/refresh_token`;

    try {
        const { data } = await post(url, reqData);

        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;

        throw { errorData, status };
    }
};

export const sendForgotPasswordRequest = async (reqData = {}) => {
    const url = `/auth/forgot-password`;

    try {
        const { data } = await post(url, reqData);

        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;

        throw { errorData, status };
    }
};

export const sendResetPasswordRequest = async (reqData = {}) => {
    const url = `/auth/reset-password`;

    try {
        const { data } = await post(url, reqData);

        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;

        throw { errorData, status };
    }
};

export const sendAvatarRequest = async (reqData = {}) => {
    const url = "user/avatar";
    try {
        const { data } = await post(url, reqData, formDataHeaders);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const sendLogoutRequest = async () => {
    const url = `/auth/logout`;

    try {
        const { data } = await destroy(url);

        return data;
    } catch (err) {
        const { data: errorData, status } = err.response || {};

        throw { errorData, status };
    }
};

export const sendRestoreEmailRequest = async (reqData = {}) => {
    const url = `/auth/restore-mail`;

    try {
        const { data } = await post(url, reqData);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const sendRestorePhoneRequest = async (reqData = {}) => {
    const url = `/auth/restore-phone`;

    try {
        const { data } = await post(url, reqData);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const sendRestoreAccountRequest = async (reqData = {}) => {
    const url = `/auth/restore-account`;

    try {
        const { data } = await post(url, reqData);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const sendRestoreByOauthRequest = async (reqData = {}) => {
    const url = `/auth/restore-by-oauth`;

    try {
        const { data } = await post(url, reqData);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const userAddRemoveFavorites = async (reqData = {}) => {
    const url = "user/add-remove-favorites";

    try {
        const { data } = await post(url, reqData);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};
