import * as api from "../../services/requests/notifications";

import { successToastify } from "../../components/toastify/toastify.operations";
import { setToastifyStatus } from "../../components/toastify/toastify.actions";
import { myAccountDataSelector } from "../my-account/my-account.selectors";
import { notificationsSelector } from "./notifications.seletors";
import * as notificationActions from "./notifications.actions";
import { getUser } from "../my-account/my-account.operations";
import {
    changeGlobalLoader,
    changeButtonLoader,
} from "../../components/loader/loader.actions";

import { replaceValue } from "../../helpers/replace-values";
import { sizeItemOnPage } from "./notifications.constants";
import {
    notificationConfig,
    notificationsWithRequest,
} from "./notifications.config";
import { putUserFulfilled } from "../my-account/my-account.actions";

// get notification list
export const getNotificationList =
    (params = "") =>
    (dispatch) => {
        dispatch(notificationActions.getNotificationListPending());
        dispatch(changeGlobalLoader(true));

        return api
            .getNotificationList(`?limit=${sizeItemOnPage}` + params)
            .then(({ items, totalCount: count }) => {
                dispatch(
                    notificationActions.getNotificationListFulfilled({
                        items,
                        count,
                    })
                );
            })
            .catch((error) => {
                dispatch(
                    notificationActions.getNotificationListRejected(error)
                );
            })
            .finally(() => dispatch(changeGlobalLoader(false)));
    };

// update notification list status
export const updateNotificationListStatus = () => (dispatch, getState) => {
    const { allNotification } = notificationsSelector(getState());
    const { items } = allNotification;

    const unreadNotificationsIds = {
        notificationsIds: items
            .filter(({ isRead }) => !isRead)
            .map(({ _id }) => _id),
    };

    if (unreadNotificationsIds.notificationsIds.length > 0) {
        dispatch(updateNotificationStatus(unreadNotificationsIds));
    }
};

export const updateNotificationStatus =
    (notificationsIds) => (dispatch, getState) => {
        const user = myAccountDataSelector(getState());
        const { unreadNotifications } = user;

        const response = {
            ...user,
            unreadNotifications:
                notificationsIds.length < unreadNotifications
                    ? unreadNotifications - 1
                    : unreadNotifications - notificationsIds.length,
        };

        dispatch(putUserFulfilled(response));

        return api.putNotificationStatus(notificationsIds);
    };

// delete notification
export const deleteNotification = (id) => (dispatch, getState) => {
    dispatch(changeButtonLoader({ status: true, button: id }));
    dispatch(notificationActions.deleteNotificationPending());

    const { allNotification } = notificationsSelector(getState());
    const { items, count } = allNotification;

    return api
        .deleteNotifction(id)
        .then(() => {
            dispatch(successToastify("toastify.notifications.deleted"));
            const newItems = items.filter(({ _id }) => _id !== id);
            const newCount = count - 1;
            dispatch(
                notificationActions.deleteNotificationFulfilled({
                    items: newItems,
                    count: newCount,
                })
            );
        })
        .catch((error) => {
            dispatch(notificationActions.deleteNotificationRejected(error));
        })
        .finally(() =>
            dispatch(changeButtonLoader({ status: false, button: "" }))
        );
};

// receive notification
export const receiveNotification = (response) => (dispatch, getState) => {
    const { allNotification } = notificationsSelector(getState());
    const user = myAccountDataSelector(getState());
    const { items, count } = allNotification;
    const { unreadNotifications } = user;

    if (notificationsWithRequest.includes(response.name)) dispatch(getUser());

    dispatch(
        notificationActions.receivedNewMessageFulfilled({
            items: [response, ...items],
            count: count + 1,
        })
    );

    dispatch(
        putUserFulfilled({
            ...user,
            unreadNotifications: unreadNotifications + 1,
        })
    );

    dispatch(
        setToastifyStatus({
            title: replaceValue({ value: response.message, isBold: false }),
            type: notificationConfig[response.name].TOASTIFY_MESSAGE,
        })
    );
};

// set
export const editNotificationsPage = (data) => (dispatch) => {
    dispatch(notificationActions.setPage(`&page=${data}`));
    const url = `&page=${data}`;
    dispatch(getNotificationList(url));
};
