import { sizeItemOnPage } from "./notifications.constants";
import { notificationTypes } from "./notifications.types";

const initialQueryParams = { limit: sizeItemOnPage, page: 0 };

const initialState = {
    allNotification: { items: [], count: 0 },
    newNotification: {},

    params: initialQueryParams,

    loading: false,
    error: "",
};

export const notifications = (state = initialState, { type, payload }) => {
    switch (type) {
        // loading
        case notificationTypes.UPDATE_NOTIFICATION_STATUS_PENDING:
        case notificationTypes.GET_NOTIFICATION_LIST_PENDING:
        case notificationTypes.DELETE_NOTIFICATION_PENDING: {
            return { ...state, loading: true };
        }

        // data notification list
        case notificationTypes.UPDATE_NOTIFICATION_STATUS_FULFILLED:
        case notificationTypes.GET_NOTIFICATION_LIST_FULFILLED:
        case notificationTypes.RECEIVED_NEW_MESSAGE_FULFILLED:
        case notificationTypes.DELETE_NOTIFICATION_FULFILLED:
            return { ...state, allNotification: payload };

        // error
        case notificationTypes.UPDATE_NOTIFICATION_STATUS_REJECTED:
        case notificationTypes.GET_NOTIFICATION_LIST_REJECTED:
        case notificationTypes.DELETE_NOTIFICATION_REJECTED: {
            return { ...state, loading: false, error: payload };
        }

        // set
        case notificationTypes.CLEAR_ALL_PARAMS:
            return { ...state, params: initialQueryParams };
        case notificationTypes.SET_PAGE:
            return { ...state, params: { ...state.params, page: payload } };

        default:
            return state;
    }
};

export default notifications;
