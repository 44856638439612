const updateOrderTasks = (order, taskId, reqBody) => {
    const tasks = order.tasks.map((task) =>
        task._id === taskId
            ? { ...task, specialist: { ...task.specialist, feedback: reqBody } }
            : task
    );
    return { ...order, tasks };
};

export const updateOrdersAfterFeedback = (orders, orderId, taskId, reqBody) => {
    const { items, count } = orders;
    const updateItems = items.map((order) =>
        order._id === orderId ? updateOrderTasks(order, taskId, reqBody) : order
    );

    const updateOrders = { items: updateItems, count };

    return updateOrders;
};

export const updateSpecialistAfterFeedback = (selectedSpecialist, reqBody) => {
    const newFeedbackCount = selectedSpecialist.feedbackCount + 1;
    const sumOfRating =
        selectedSpecialist.rating * selectedSpecialist.feedbackCount +
        reqBody.assessment;
    const newRating = sumOfRating / newFeedbackCount;
    const updateSpecialist = {
        ...selectedSpecialist,
        feedbackCount: newFeedbackCount,
        rating: newRating,
    };

    return updateSpecialist;
};

export const updateSpecialistAfterChangedFeedback = (
    selectedSpecialist,
    userFeedbacks,
    feedbackBody,
    reqBody
) => {
    const updatedFeedbacks = userFeedbacks.feedbacks.map((feedback) =>
        feedback._id === feedbackBody._id
            ? {
                  ...feedback,
                  ...reqBody,
              }
            : feedback
    );

    const newReting = updatedFeedbacks.reduce(
        (acc, { assessment }) => acc + assessment,
        0
    );

    const updatedRating = newReting / userFeedbacks.totalCount;

    const updateSpecialist = {
        ...selectedSpecialist,
        feedbacks: updatedFeedbacks,
        rating: updatedRating,
    };

    return updateSpecialist;
};
