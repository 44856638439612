export const supportCreateTicketModalConfig = [
    {
        label: "support.modal.title-label",
        placeholderText: "support.modal.title-placeholder",
        registerName: "title",
        type: "secondary",
    },
    {
        label: "support.modal.description-label",
        placeholderText: "support.modal.description-placeholder",
        registerName: "description",
        type: "additionalInfo",
    },
];

export const categoryConfig = [
    { value: "General", label: "support.category.general" },
    { value: "Marketing", label: "support.category.marketing" },
    { value: "Promotion", label: "support.category.promotion" },
    { value: "Payment", label: "support.category.payment" },
];
