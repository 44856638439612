import React, { createRef, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { modalInformationSelector } from "./modal.selectors";
import { modalConstants } from "./modal.constants";

import close from "../../assets/images/close-icons/close_modal.svg";
import "./index.scss";

const Modal = ({ children, onClose, status, closeIcon }) => {
    const { renderType } = useSelector(modalInformationSelector);

    const backdropRef = createRef();

    useEffect(() => {
        window.addEventListener("keydown", handleKeyPress);
        return function cleanup() {
            window.removeEventListener("keydown", handleKeyPress);
        };
    });

    const handleKeyPress = (e) => {
        if (e.code !== "Escape") return;

        onClose(false);
    };

    const handleBackdropClick = (e) => {
        const { current } = backdropRef;

        if (current && e.target !== current) return;
        renderType !== modalConstants.cropAvatarImage && onClose(false);
    };

    return (
        <div
            className={`backdrop ${
                status ? "backdrop-visual" : "backdrop-hidden"
            }`}
            ref={backdropRef}
            onClick={handleBackdropClick}
        >
            <div className={`modal ${status ? "modal" : "modal-not-show"}`}>
                {children}
                {closeIcon && (
                    <img
                        onClick={onClose}
                        className="modal-close"
                        src={close}
                        alt="close"
                    />
                )}
            </div>
        </div>
    );
};

Modal.propTypes = {
    onClose: PropTypes.func,
    status: PropTypes.bool,
    closeIcon: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
};

export default Modal;
