import { useForm, useFormState } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { buttonLoaderSelector } from "../../../../../components/loader/loader.selectors";
import { forgotPasswordSelector } from "../../../auth.selectors";
import { forgotPassword } from "../../../auth.operations";

import { forgotPasswordRequestSchema } from "./forgot-password-request.schema";
import ReactHelmet from "../../../../../components/react-helmet/react-helmet";
import { isEmptyObject } from "../../../../../helpers/is-empty-object";
import ButtonLoader from "../../../../../components/loader/components/button-loader/button-loader";
import Input from "../../../../../components/input/input";

import forgotPasswordIcon from "../../../../../assets/images/auth/forgot_password.svg";
import "./index.scss";

const ForgotPasswordRequest = () => {
    const {
        handleSubmit,
        register,
        control,
        setValue,
        reset,
        formState: { errors, isValid },
    } = useForm({
        mode: "all",
        resolver: yupResolver(forgotPasswordRequestSchema),
    });

    const { dirtyFields } = useFormState({ control });

    const { status: buttonLoading } = useSelector(buttonLoaderSelector);
    const { email } = useSelector(forgotPasswordSelector);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        setValue("email", email);
    }, [email, setValue]);

    const handleFormSubmit = (data) => {
        dispatch(forgotPassword(data));
        reset(data);
    };

    return (
        <Fragment>
            <ReactHelmet title="forgot_password_request_title" />
            <div className="forgot-password__request">
                <div className="forgot-password__request_content">
                    <img
                        src={forgotPasswordIcon}
                        alt="forgot password"
                        className="forgot-password__request-img"
                    />
                    <p className="forgot-password__request-title">
                        {t("forgot_password_request_title")}
                    </p>
                    <span className="forgot-password__request-subtitle">
                        {t("forgot_password_request_subtitle")}
                    </span>
                    <form
                        onSubmit={handleSubmit(handleFormSubmit)}
                        className="forgot-password__request_form"
                    >
                        <Input
                            label="label_email"
                            type="secondary"
                            options={{
                                placeholderText: "email",
                            }}
                            error={errors.email?.message}
                            inputChangeOptions={register("email")}
                        />
                        <button
                            className="forgot-password__request-btn"
                            style={{
                                background: buttonLoading && "#dfdfdf",
                            }}
                            disabled={
                                isEmptyObject(dirtyFields) ||
                                !isValid ||
                                buttonLoading
                            }
                        >
                            {buttonLoading ? (
                                <ButtonLoader size={38} />
                            ) : (
                                t("btn_send")
                            )}
                        </button>
                    </form>
                </div>
            </div>
        </Fragment>
    );
};

export default ForgotPasswordRequest;
