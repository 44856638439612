import { ToastContainer, toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import React from "react";

import { toastifyStatusSelector } from "./toastify.selectors";
import { setToastifyStatus } from "./toastify.actions";

import "react-toastify/dist/ReactToastify.css";
import "./index.scss";

const Toastify = () => {
    const { title, html, type } = useSelector(toastifyStatusSelector);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const Notification = () => {
        if (html) {
            return <div>{html}</div>;
        } else if (Array.isArray(title)) {
            return <h4>{t(title.join(""))}</h4>;
        } else {
            return <h4>{t(title)}</h4>;
        }
    };

    if (type === "error") {
        toast.error(<Notification />);
        dispatch(
            setToastifyStatus({
                isVisible: false,
                type: "",
                title: "",
                html: "",
            })
        );
    }

    if (type === "success") {
        toast.success(<Notification />);
        dispatch(
            setToastifyStatus({
                isVisible: false,
                type: "",
                title: "",
                html: "",
            })
        );
    }

    return (
        <ToastContainer
            autoClose={5000}
            position="bottom-right"
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            limit={6}
            pauseOnHover
        />
    );
};

export default Toastify;
