import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Fragment, useEffect } from "react";

import { myAccountDataSelector } from "../my-account/my-account.selectors";
import { clearAllParams } from "./notifications.actions";
import {
    updateNotificationListStatus,
    editNotificationsPage,
    getNotificationList,
} from "./notifications.operations";
import {
    notificationsSelector,
    notificationsPageSelector,
} from "./notifications.seletors";

import { Icon } from "../../components/image/image";
import NotificationItem from "./components/notification-item/notification-item";
import ReactHelmet from "../../components/react-helmet/react-helmet";
import Pagination from "../../components/pagination/pagination";
import { sizeItemOnPage } from "./notifications.constants";

import emptyResultIcon from "../../assets/images/error-pages/empty-result.svg";
import "./index.scss";
import { openModal } from "../../components/modal/modal.actions";
import { modalConstants } from "../../components/modal/modal.constants";

const Notifications = () => {
    const { unreadNotifications } = useSelector(myAccountDataSelector);
    const { allNotification } = useSelector(notificationsSelector);
    const { page } = useSelector(notificationsPageSelector);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { items, count } = allNotification;

    const handleNotificationSettings = () => {
        dispatch(
            openModal({
                isOpen: true,
                closeIcon: true,
                renderType: modalConstants.notificationSettings,
            })
        );
    };

    useEffect(() => {
        dispatch(getNotificationList());

        return () => {
            dispatch(updateNotificationListStatus());
            dispatch(clearAllParams());
        };
    }, []);

    return (
        <Fragment>
            <ReactHelmet title="notifications_title" />
            <div className="notifications">
                <h1 className="notifications__title">
                    {t("notifications_title")}
                </h1>
                <div className="notifications__body">
                    <div className="notifications__header">
                        <h4 className="notifications__header_title">
                            {unreadNotifications > 0
                                ? `${t("notifications_new_first")}
                     ${unreadNotifications}
                        ${t("notifications_new_second")}`
                                : t("notifications_no_message.title")}
                        </h4>
                        <div
                            className="notifications__header_img"
                            onClick={handleNotificationSettings}
                        >
                            <Icon type="settingsIcon" />
                        </div>
                    </div>
                    <div className="notifications__list">
                        {count > 0 ? (
                            items.map((notification) => (
                                <NotificationItem
                                    key={notification._id}
                                    {...notification}
                                />
                            ))
                        ) : (
                            <div className="notifications__empty-result">
                                <img
                                    className="chats__empty-result--img"
                                    src={emptyResultIcon}
                                    alt="empty result"
                                />
                                <span className="chats__empty-result--title">
                                    {t("notifications_no_message.description")}
                                </span>
                            </div>
                        )}
                    </div>
                </div>
                <Pagination
                    getNextPageItems={(e) => {
                        dispatch(editNotificationsPage(e));
                        dispatch(updateNotificationListStatus());
                    }}
                    sizeItemOnPage={sizeItemOnPage}
                    items={allNotification}
                    isStartedPage={page}
                    elementType="page"
                />
            </div>
        </Fragment>
    );
};

export default Notifications;
