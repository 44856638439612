import { ORDERS_FILTERS } from "../../../../pages/orders/orders.constant";
import { userRoles } from "../../../../constants/user-roles";
import routerBook from "../../../../router/router-book";

export const footerLinks = [
    { title: "facebookFooter", link: routerBook.socialMedia.facebook },
    { title: "instagram", link: routerBook.socialMedia.instagram },
    { title: "twitter", link: routerBook.socialMedia.twitter },
    { title: "tiktok", link: routerBook.socialMedia.tiktok },
    { title: "linkedin", link: routerBook.socialMedia.linkedin },
];

export const footerNavsFirst = {
    true: [
        { title: "profile_footer_main", link: routerBook.home, state: true },
        { title: "profile_footer_about", link: routerBook.about, state: true },
        { title: "academy", link: routerBook.academy, state: true },
        { title: "faq-title", link: routerBook.faq, state: true },
    ],
    false: [
        { title: "profile_footer_main", link: routerBook.home, state: true },
        { title: "profile_footer_about", link: routerBook.about, state: true },
        {
            title: "all_service_title",
            link: routerBook.allServices,
            state: true,
        },
        {
            title: "specialist_new_orders",
            link: routerBook.allServicesResult,
            state: true,
        },
        { title: "academy", link: routerBook.academy, state: true },
    ],
};

export const footerNavsSecond = {
    [userRoles.customer]: [
        {
            title: "profile_footer_orders",
            link: `${routerBook.orders}/${ORDERS_FILTERS.NEW}`,
            state: true,
        },
        {
            title: "profile_footer_create_order",
            link: routerBook.createOrder,
            state: true,
        },
        {
            title: "profile_footer_profile",
            link: routerBook.myAccountInfo,
            state: true,
        },
        {
            title: "profile_footer_specialists",
            link: routerBook.specialistsList,
            state: true,
        },
    ],
    [userRoles.specialist]: [
        {
            title: "specialist_new_orders",
            link: routerBook.accessForSpecialist.newOrders,
            state: true,
        },
        {
            title: "my_orders",
            link: routerBook.accessForSpecialist.orders,
            state: true,
        },
        {
            title: "my_services",
            link: routerBook.accessForSpecialist.services,
            state: true,
        },
        {
            title: "profile_footer_profile",
            link: routerBook.myAccountInfo,
            state: true,
        },
    ],
    null: [
        {
            title: "title_profile_form",
            link: routerBook.auth.main,
            state: true,
        },
        {
            title: "sing_in",
            link: routerBook.auth.login,
            state: true,
        },
        {
            title: "registration",
            link: routerBook.auth.accountType,
            state: true,
        },
        {
            title: "forgot_password",
            link: routerBook.auth.forgotPassword,
            state: true,
        },
    ],
};
