import { get, post, destroy, formDataHeaders, patch } from "../api";

export const createServiceBySpecialist = async (serviceData) => {
    const url = "/service";

    try {
        const { data } = await post(url, serviceData);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const updateServiceBySpecialist = async (serviceID, serviceData) => {
    const url = `/service/${serviceID}`;

    try {
        const { data } = await patch(url, serviceData);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const deleteSpecialistService = async (serviceID) => {
    const url = `/service/${serviceID}`;

    try {
        const { data } = await destroy(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const getSpecialistServices = async (specialistID = "") => {
    const url = `/service?specialistId=${specialistID}`;

    try {
        const { data } = await get(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const getSpecialistServiceByID = async (serviceID) => {
    const url = "/service/" + serviceID;

    try {
        const { data } = await get(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const postSpecialistServiceImages = async (id, reqData = {}) => {
    const url = `/service/${id}/images`;

    try {
        const { data } = await post(url, reqData, formDataHeaders);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const deleteSpecialistServiceImage = async (serviceID, imageID) => {
    const url = `/service/${serviceID}/images/${imageID}`;

    try {
        const { data } = await destroy(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const getServiceListSearch = async (params = "", role = "") => {
    const url = `/service/${role ? "search" : "anon-search"}` + params;

    try {
        const { data } = await get(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};
