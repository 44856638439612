import { useTranslation } from "react-i18next";
import React, { Fragment } from "react";

import ReactHelmet from "../../components/react-helmet/react-helmet";

import AboutUsImage from "../../assets/images/abous-us/about_us.png";
import "./index.scss";

const AboutUs = () => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <ReactHelmet title="about_us-title" />
            <div className="about-us">
                <h1 className="about-us__title">{t("about_us-title")}</h1>
                <h3 className="about-us__subtitle">{t("about_us-subtitle")}</h3>
                <img className="about-us__img" src={AboutUsImage} alt="logo" />
                <span className="about-us__content-item">
                    {t("about_us-content-item-first")}
                </span>
                <span className="about-us__content-item">
                    {t("about_us-content-item-second")}
                </span>
                <span className="about-us__content-item">
                    {t("about_us-content-item-third")}
                </span>
                <span className="about-us__content-item">
                    {t("about_us-content-item-fourth")}
                </span>
            </div>
        </Fragment>
    );
};

export default AboutUs;
