import { serviceTypes } from "./all-services.types";

const initialState = {
    clarificationsForSearch: [],
    subservicesForSearch: [],
    servicesForSearch: [],

    clarifications: [],
    subservices: [],
    services: [],

    loading: false,
    error: "",
};

export const service = (state = initialState, { type, payload }) => {
    switch (type) {
        // loading
        case serviceTypes.GET_CLARIFICATION_PENDING:
        case serviceTypes.GET_SUBSERVICE_PENDING:
        case serviceTypes.GET_SERVICE_PENDING: {
            return { ...state, loading: true };
        }

        // data clarification
        case serviceTypes.GET_CLARIFICATION_FULFILLED: {
            return { ...state, loading: false, clarifications: payload };
        }

        // data subservice
        case serviceTypes.GET_SUBSERVICE_FULFILLED: {
            return { ...state, loading: false, subservices: payload };
        }

        // data service
        case serviceTypes.GET_SERVICE_FULFILLED: {
            return { ...state, loading: false, services: payload };
        }

        // data clarification for search
        case serviceTypes.SET_CLARIFICATION_FOR_SEARCH_FULFILLED: {
            return {
                ...state,
                loading: false,
                clarificationsForSearch: payload,
            };
        }

        // data subservice for search
        case serviceTypes.SET_SUBSERVICE_FOR_SEARCH_FULFILLED: {
            return { ...state, loading: false, subservicesForSearch: payload };
        }

        // data service for search
        case serviceTypes.SET_SERVICE_FOR_SEARCH_FULFILLED: {
            return { ...state, loading: false, servicesForSearch: payload };
        }

        // error
        case serviceTypes.GET_CLARIFICATION_REJECTED:
        case serviceTypes.GET_SUBSERVICE_REJECTED:
        case serviceTypes.GET_SERVICE_REJECTED: {
            return { ...state, loading: false, error: payload };
        }

        // clear
        case serviceTypes.CLEAR_ALL_SERVICES: {
            return initialState;
        }

        default:
            return state;
    }
};
