import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import routerBook from "../../../../router/router-book";
import { ORDERS_FILTERS } from "../../orders.constant";

import "./index.scss";

const OrdersSidebar = ({ filterParameter = "" }) => {
    const { t } = useTranslation();

    const setActiveClassName = (filter) => {
        if (filterParameter === filter) return `--${filter}`;
        return "";
    };

    return (
        <div className="orders-sidebar">
            {Object.values(ORDERS_FILTERS).map((filter) => {
                if (filter === ORDERS_FILTERS.LINE) {
                    return (
                        <div key={filter} className="orders-sidebar__line" />
                    );
                } else {
                    return (
                        <Link
                            className={`orders-sidebar__item${setActiveClassName(
                                filter
                            )}`}
                            to={`${routerBook.orders}/${filter}`}
                            state={true}
                            key={filter}
                        >
                            <span className="orders-sidebar__item__mark" />
                            {t(`orders.navigation-sidebar.${filter}`)}
                        </Link>
                    );
                }
            })}
        </div>
    );
};

OrdersSidebar.propTypes = { filterParameter: PropTypes.string };

export default OrdersSidebar;
