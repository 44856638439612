const stages = [
    {
        number: "01",
        title: "profile_stages_work_first_title",
        description: "profile_stages_work_first_description",
    },
    {
        number: "02",
        title: "profile_stages_work_second_title",
        description: "profile_stages_work_second_description",
    },
    {
        number: "03",
        title: "profile_stages_work_third_title",
        description: "profile_stages_work_third_description",
    },
    {
        number: "04",
        title: "profile_stages_work_fourth_title",
        description: "profile_stages_work_fourth_description",
    },
];
export { stages };
