import { t } from "i18next";

import { typeOfLazeImage } from "../components/lazy-image/lazyImage.constansts";
import LazyImage from "../components/lazy-image/lazy-image";
import { replaceValue } from "./replace-values";

export const showLastMessage = (lastMessage = {}) => {
    if (lastMessage) {
        const { text, image, operation, messageType } = lastMessage;

        if (text) return text;

        if (image) {
            if (lastMessage.image.contentType.includes("image")) {
                return (
                    <LazyImage
                        options={{
                            className: "chats__content-item--info-body-img",
                            alt: "image",
                        }}
                        type={typeOfLazeImage.content}
                        image={image?.url}
                    />
                );
            }
            return (
                <video
                    className="chats__content-item--info-body-img"
                    src={lastMessage.image.url}
                />
            );
        }

        if (messageType) {
            return replaceValue({ value: operation.text, isBold: true });
        }
    }

    return t("chats.no_messages_yet");
};
