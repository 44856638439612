import { useTranslation } from "react-i18next";
import React, { Fragment } from "react";
import PropTypes from "prop-types";

import "./index.scss";

const DualInput = ({
    type = "",
    inputOptionsFirst,
    inputOptionsSecond,
    errorsOptionsFirst,
    errorsOptionsSecond,
    placeholderOptionsFirst,
    label = "",
    watchedPriceFrom,
}) => {
    const { t } = useTranslation();

    return (
        <Fragment>
            {type === "range-dual-price" && (
                <>
                    <div className="form-container__info">
                        <label className="form-container__label">{label}</label>

                        {errorsOptionsFirst || errorsOptionsSecond ? (
                            <p className="form-container__error">
                                {errorsOptionsFirst
                                    ? errorsOptionsFirst
                                    : errorsOptionsSecond}
                            </p>
                        ) : null}
                    </div>
                    <div className="custom-input-dual-price-container">
                        <span className="custom-input-dual-price-from">
                            {t("from")}
                        </span>
                        <input
                            {...inputOptionsFirst}
                            className={`custom-input-dual-price-input ${
                                errorsOptionsFirst ? "errors" : ""
                            }`}
                            placeholder="0"
                            type="number"
                            min="0"
                        />
                        <span className="custom-input-dual-price-to">
                            {t("to")}
                        </span>
                        <input
                            {...inputOptionsSecond}
                            className={`custom-input-dual-price-input ${
                                errorsOptionsSecond ? "errors" : ""
                            }`}
                            placeholder="0"
                            type="number"
                            min="0"
                            disabled={!watchedPriceFrom}
                        />
                    </div>
                </>
            )}
            {type === "range-dual" && (
                <>
                    {label && (
                        <div className="form-container__info">
                            <label className="form-container__label">
                                {label}
                            </label>
                            {errorsOptionsFirst || errorsOptionsSecond ? (
                                <p className="form-container__error">
                                    {errorsOptionsFirst
                                        ? errorsOptionsFirst
                                        : errorsOptionsSecond}
                                </p>
                            ) : null}
                        </div>
                    )}
                    <div className="custom-input-dual-container">
                        <span className="custom-input-dual-from">
                            {t("from")}
                        </span>
                        <input
                            {...inputOptionsFirst}
                            className={`custom-input-dual-input ${
                                errorsOptionsFirst ? "errors" : ""
                            }`}
                            placeholder="0"
                            type="number"
                        />
                        <span className="custom-input-dual-to">{t("to")}</span>
                        <input
                            {...inputOptionsSecond}
                            className={`custom-input-dual-input ${
                                errorsOptionsSecond ? "errors" : ""
                            }`}
                            placeholder="0"
                            type="number"
                        />
                    </div>
                </>
            )}
            {type === "single" && (
                <input
                    {...inputOptionsFirst}
                    className={`custom-input-single-input ${
                        errorsOptionsFirst ? "errors" : ""
                    }`}
                    placeholder={t(placeholderOptionsFirst)}
                    type="text"
                />
            )}
        </Fragment>
    );
};

DualInput.propTypes = {
    type: PropTypes.string,
    errorsOptionsFirst: PropTypes.string,
    errorsOptionsSecond: PropTypes.string,
    inputOptionsFirst: PropTypes.object,
    inputOptionsSecond: PropTypes.object,
    placeholderOptionsFirst: PropTypes.string,
    label: PropTypes.string,
    watchedPriceFrom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export { DualInput };

export default DualInput;
