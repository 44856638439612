export const serviceTypes = {
    // get service
    GET_SERVICE_PENDING: "service/GET_SERVICE_PENDING",
    GET_SERVICE_FULFILLED: "service/GET_SERVICE_FULFILLED",
    GET_SERVICE_REJECTED: "service/GET_SERVICE_REJECTED",

    // get subservice
    GET_SUBSERVICE_PENDING: "service/GET_SUBSERVICE_PENDING",
    GET_SUBSERVICE_FULFILLED: "service/GET_SUBSERVICE_FULFILLED",
    GET_SUBSERVICE_REJECTED: "service/GET_SUBSERVICE_REJECTED",

    // get clarification
    GET_CLARIFICATION_PENDING: "service/GET_CLARIFICATION_PENDING",
    GET_CLARIFICATION_FULFILLED: "service/GET_CLARIFICATION_FULFILLED",
    GET_CLARIFICATION_REJECTED: "service/GET_CLARIFICATION_REJECTED",

    // add remove favorites
    ADD_REMOVE_FAVORITES_PENDING: "service/ADD_REMOVE_FAVORITES_PENDING",
    ADD_REMOVE_FAVORITES_FULFILLED: "service/ADD_REMOVE_FAVORITES_FULFILLED",
    ADD_REMOVE_FAVORITES_REJECTED: "service/ADD_REMOVE_FAVORITES_REJECTED",

    // set service for search
    SET_SERVICE_FOR_SEARCH_FULFILLED:
        "service/SET_SERVICE_FOR_SEARCH_FULFILLED",

    // set subservice for search
    SET_SUBSERVICE_FOR_SEARCH_FULFILLED:
        "service/SET_SUBSERVICE_FOR_SEARCH_FULFILLED",

    // set clarification for search
    SET_CLARIFICATION_FOR_SEARCH_FULFILLED:
        "service/SET_CLARIFICATION_FOR_SEARCH_FULFILLED",

    // clear state
    CLEAR_ALL_SERVICES: "service/CLEAR_ALL_SERVICES",
};
