import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { getOrder } from "../../../../../../pages/orders/orders.operations";
import { ordersSelector } from "../../../../../../pages/orders/orders.selectors";

import InfoAboutOrderForm from "../info-about-order-form/info-about-order-form";
import { backButtonConfig } from "../../../../../back-button/back-button.config";
import ReactHelmet from "../../../../../react-helmet/react-helmet";
import BackButton from "../../../../../back-button/back-button";
import { getLocalizedService } from "../../../../../../helpers/get-localized-service";

import "./index.scss";

const InfoAboutOrder = () => {
    const { order } = useSelector(ordersSelector);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { t } = useTranslation();
    const { id } = useParams();
    const { number } = order;
    useEffect(() => {
        dispatch(getOrder(id));
    }, []);

    const subCategoryTitle = order && getLocalizedService(order.subcategory);

    return (
        <Fragment>
            <ReactHelmet title={subCategoryTitle} />
            <div className="info_about_order">
                <div className="info_about_order__inner">
                    <div className="info_about_order__header">
                        <BackButton
                            onClickCallback={() =>
                                navigate(
                                    backButtonConfig[location.state].href,
                                    { state: true }
                                )
                            }
                            title={t(backButtonConfig[location.state].title)}
                        />
                        <div className="info_about_order__header_title">
                            {t("specialists_order")} {subCategoryTitle} (
                            {number})
                        </div>
                    </div>
                    <InfoAboutOrderForm />
                </div>
            </div>
        </Fragment>
    );
};

export default InfoAboutOrder;
