import { get } from "../api";

export const getSpecialistList = async (params = "") => {
    const url = "/specialist/list" + params;

    try {
        const { data } = await get(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const getSpecialistFavouritesRequest = async (params = "") => {
    const url = "/specialist/favourites" + params;

    try {
        const { data } = await get(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const getSpecialistInfoByID = async (params = "") => {
    const url = "/specialist/" + params;

    try {
        const { data } = await get(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const searchSpecialist = async (params = "") => {
    const url = "/specialist/search" + params;

    try {
        const { data } = await get(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};
