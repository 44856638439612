import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { Fragment } from "react";

import { ordersPageSelector } from "../../orders.selectors";
import { editPageAll } from "../../orders.operations";

import { ORDERS_FILTERS, sizeItemOnPage } from "../../orders.constant";
import Pagination from "../../../../components/pagination/pagination";
import OrderItem from "./components/order-item/order-item";
import NoOrders from "./components/no-orders/no-orders";

const OrdersList = ({ orders }) => {
    const page = useSelector(ordersPageSelector);
    const { filterParameter } = useParams();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { items } = orders;

    return (
        <Fragment>
            {items.length ? (
                items.map((item) => <OrderItem key={item._id} {...item} />)
            ) : (
                <NoOrders
                    text={
                        filterParameter === ORDERS_FILTERS.NEW
                            ? t("orders.error.no-new-order")
                            : t("orders.error.no-status-order")
                    }
                />
            )}
            <Pagination
                items={orders}
                elementType="page"
                sizeItemOnPage={sizeItemOnPage}
                getNextPageItems={(e) => {
                    dispatch(editPageAll(sizeItemOnPage, e));
                }}
                isStartedPage={page}
            />
        </Fragment>
    );
};

OrdersList.propTypes = {
    orders: PropTypes.object.isRequired,
};

export default OrdersList;
