import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

import { errorToastify } from "./components/toastify/toastify.operations";
import { receiveNotification } from "./pages/notifications/notifications.operations";
import { websocketsSelector } from "./components/websockets/websockets.selectors";
import { editChats } from "./pages/chats/chats.operations";
import { authSelector } from "./pages/auth/auth.selectors";
import {
    editUserOfflineStatus,
    editUserOnlineStatus,
    connectToWebsocket,
    disconnectedWebsocket,
} from "./components/websockets/websockets.operations";

import { socketEvents } from "./components/websockets/websockets.constants";
import ScrollToTopButton from "./components/scroll-to-top-button/scroll-to-top-button";
import { getItem, removeItem } from "./helpers/local-storage";
import { generateDeviceID } from "./helpers/device-id";
import CitiesLoader from "./components/cities-loader";
import Toastify from "./components/toastify/toastify";
import Modal from "./components/modal/render-modals";
import ScrollToTop from "./hooks/scroll-to-top";
import routerBook from "./router/router-book";
import Router from "./router/router";

import "./assets/styles/index.scss";

const App = () => {
    const { socket } = useSelector(websocketsSelector);
    const { token } = useSelector(authSelector);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!getItem("deviceID")) generateDeviceID();
    }, []);

    useEffect(() => {
        if (token) dispatch(connectToWebsocket());
        return () => dispatch(disconnectedWebsocket());
    }, [token]);

    useEffect(() => {
        const cachedOrderData = getItem("unfinishedForm");

        if (cachedOrderData) {
            if (
                window.location.pathname === routerBook.createOrder ||
                window.location.pathname === routerBook.allServicesResult
            )
                return;
            else {
                removeItem("unfinishedForm");
                removeItem("unfinishedFormImages");
            }
        }
    }, [window.location.pathname]);

    useEffect(() => {
        if (socket) {
            socket.on(socketEvents.USER_ONLINE, ({ userId }) => {
                dispatch(editUserOnlineStatus(userId));
            });
            socket.on(socketEvents.USER_DISCONNECTED, ({ userId }) => {
                dispatch(editUserOfflineStatus(userId));
            });
            socket.on(socketEvents.EXCEPTION, ({ errorMessage }) => {
                dispatch(errorToastify(errorMessage));
            });
            socket.on(socketEvents.CREATED_CHAT, ({ chat }) => {
                dispatch(editChats(chat));
            });
            socket.on(socketEvents.NOTIFICATION, (response) => {
                dispatch(receiveNotification(response));
            });

            socket.on(
                socketEvents.DISCONNECT,
                () => token && dispatch(connectToWebsocket())
            );
        }
    }, [socket]);
    
    return (
        <div className="app">
            <ScrollToTopButton />
            <CitiesLoader />
            <ScrollToTop />
            <Toastify />
            <Modal />
            <Router />
        </div>
    );
};

export default App;
