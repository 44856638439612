import { useSelector } from "react-redux";
import { Fragment } from "react";

import { roleSelector } from "../my-account/my-account.selectors";

import ReactHelmet from "../../components/react-helmet/react-helmet";
import StagesWork from "./components/stages-work/stages-work";
import { userRoles } from "../../constants/user-roles";
import Category from "./components/category/category";
import Carousel from "./components/carousel/carousel";
import Comfort from "./components/comfort/comfort";
import Banner from "./components/banner/banner";

const Profile = () => {
    const { role } = useSelector(roleSelector);

    const customerLayout = () => {
        return (
            <Fragment>
                <Banner />
                <Category />
                <Carousel />
                <StagesWork />
                <Comfort />
            </Fragment>
        );
    };

    const specialistLayout = () => {
        return (
            <Fragment>
                <Banner />
                <Category />
                <StagesWork />
                <Comfort />
            </Fragment>
        );
    };

    const layoutGenerator = (role) => {
        switch (role) {
            case userRoles.customer:
                return customerLayout();
            case userRoles.specialist:
                return specialistLayout();

            default:
                return customerLayout();
        }
    };

    return (
        <Fragment>
            <ReactHelmet />
            {layoutGenerator(role)}
        </Fragment>
    );
};

export default Profile;
