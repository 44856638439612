import ReactDatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import React from "react";

import { selectedPickerLanguage } from "./date-picker.config";
import { getItem } from "../../../helpers/local-storage";

import "react-datepicker/dist/react-datepicker.css";
import "./index.scss";

const DatePicker = ({
    onChange,
    selected,
    maxDate,
    minDate,
    maxVisibleYears,
    errors,
    dateRef,
    label,
}) => {
    const selectedLanguage = getItem("language");
    const { t } = useTranslation();

    return (
        <>
            {label && (
                <div className="custom-date-picker__info">
                    <label className="custom-date-picker__label">{label}</label>
                    {errors?.message ? (
                        <p className="custom-date-picker__error">
                            {t(errors?.message)}
                        </p>
                    ) : null}
                </div>
            )}
            <ReactDatePicker
                value={selected ? dayjs(selected).format("DD.MM.YYYY") : null}
                className={`custom-date-picker${errors ? "__errors" : ""}`}
                locale={selectedPickerLanguage[selectedLanguage]}
                selected={selected ? new Date(selected) : null}
                yearDropdownItemNumber={maxVisibleYears}
                placeholderText={t("select_date")}
                maxDate={maxDate || ""}
                minDate={minDate || ""}
                scrollableYearDropdown
                onChange={onChange}
                showMonthDropdown
                showYearDropdown
                ref={dateRef}
            />
        </>
    );
};

DatePicker.propTypes = {
    errors: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    selected: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date),
    ]),
    maxDate: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.instanceOf(Date),
    ]),
    minDate: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.instanceOf(Date),
    ]),
    maxVisibleYears: PropTypes.number,
    onChange: PropTypes.func,
    dateRef: PropTypes.func,
    label: PropTypes.string,
};

export default DatePicker;
