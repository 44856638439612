import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { clearAllServices } from "../../../all-services/all-services.actions";
import { modalInformationSelector } from "../../../../components/modal/modal.selectors";
import { citiesLoaderSelector } from "../../../../components/cities-loader/cities-loader.selectors";
import { getSpecialistServiceFulfilled } from "../../specialist-services.actions";
import { closeModal } from "../../../../components/modal/modal.actions";
import { specialistServicesSelector } from "../../specialist-services.selectors";
import {
    getClarifications,
    getServices,
    getSubservices,
} from "../../../all-services/all-services.operations";
import {
    createServiceBySpecialist,
    deleteSpecialistServiceImage,
    getSpecialistServiceByID,
    updateServiceBySpecialist,
} from "../../specialist-services.operations";

import DualPriceInput from "../../../../components/input/dual-input/dual-input";
import { mapStateToOptions } from "../../../orders/components/update-order/update-order.utils";
import SELECT_TYPES from "../../../../components/select/select.constants";
import ScheduleInput from "../../../../components/input/schedule-input";
import MainButton from "../../../../components/main-button/main-button";
import ImageUploader from "../../../../components/input/image-uploader/image-uploader";
import LocationSelect from "../../../../components/location-select/location-select";
import Textarea from "../../../../components/input/textarea/textarea";

import { handleDeleteImage } from "../../../create-order/components/create-order-form/create-order-form.utils";
import { IMAGE_UPLOADER_TYPES } from "../../../../components/input/image-uploader/image-uploader.constants";
import Select from "../../../../components/select/select";
import { serviceFormSchema } from "./service-form.schema";
import Input from "../../../../components/input/input";
import {
    convertServiceForDefaultValues,
    convertSpecialistServiceDataToRequestSchema,
} from "./service-form.utils";
import {
    SERVICE_FORM_SELECT_FIELDS,
    SERVICE_FORM_OTHER_FIELDS,
} from "./service-form.constants";

import { serviceSelector } from "../../../all-services/all-services.selectors";
import deletePhotoIcon from "../../../../assets/images/create-order-icons/delete_photo.svg";
import "./index.scss";
import LazyImage from "../../../../components/lazy-image/lazy-image";
import { typeOfLazeImage } from "../../../../components/lazy-image/lazyImage.constansts";
import { getAllDistricts } from "../../../../components/cities-loader/cities-loader.operations";
import { isObjectEqual } from "../../../../helpers/isObjectEqual";

const ServiceForm = () => {
    const { selectedService } = useSelector(specialistServicesSelector);
    const { details } = useSelector(modalInformationSelector);
    const [identityError, setIdentityError] = useState("");
    const { clarifications, subservices, services } =
        useSelector(serviceSelector);
    const {
        list: { items: specialistServicesList },
    } = useSelector(specialistServicesSelector);
    const [image, setImage] = useState([]);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const {
        handleSubmit,
        register,
        control,
        setValue,
        formState: { errors },
        getValues,
        resetField,
        watch,
    } = useForm({
        mode: "all",
        resolver: yupResolver(serviceFormSchema),
    });
    const [selectedCity, setSelectedCity] = useState({
        state_code: "",
        name: selectedService?.city,
    });

    const priceFrom = watch("priceFrom");

    const handleIdentityService = () => {
        const { categoryId, subcategoryId, clarificationId } = getValues();

        if (
            categoryId?.value &&
            subcategoryId?.value &&
            clarificationId?.value &&
            specialistServicesList.some(
                ({ category, subcategory, clarification }) =>
                    category._id === categoryId.value &&
                    subcategory._id === subcategoryId.value &&
                    clarification._id === clarificationId.value
            )
        ) {
            setIdentityError(t("validation_service_already_exists"));
        } else setIdentityError("");
    };

    useEffect(() => {
        if (details) {
            dispatch(getSpecialistServiceByID(details.serviceId));
        } else dispatch(getServices());

        return () => dispatch(getSpecialistServiceFulfilled(null));
    }, []);

    useEffect(() => {
        if (selectedService) setDefaultValues();
    }, [selectedService]);
    const handleFormSubmit = (data) => {
        const specialistServiceData =
            convertSpecialistServiceDataToRequestSchema(data);

        if (details) {
            dispatch(
                updateServiceBySpecialist(
                    selectedService._id,
                    specialistServiceData,
                    image
                )
            );
        } else {
            dispatch(createServiceBySpecialist(specialistServiceData, image));
        }
    };

    const clearFields = (fields = []) => {
        fields.forEach((item) => setValue(item, undefined));
    };

    const setDefaultValues = () => {
        SERVICE_FORM_SELECT_FIELDS.forEach((item) => {
            setValue(
                item.fieldName,
                convertServiceForDefaultValues(
                    selectedService[item.defaultValueKey]
                )
            );
        });
        SERVICE_FORM_OTHER_FIELDS.forEach((item) => {
            setValue(item.fieldName, selectedService[item.defaultValueKey]);
        });
        if (selectedService?.images?.length) setImage(selectedService?.images);
    };

    const { cities, districts } = useSelector(citiesLoaderSelector);

    const handleCancelClick = () => {
        dispatch(clearAllServices());
        dispatch(closeModal());
    };

    const handleSelectCity = (currentCity) => {
        if (
            isObjectEqual(currentCity, selectedCity) |
            (currentCity.name === selectedCity.name)
        )
            return;
        resetField("district");
        setSelectedCity(currentCity);
    };

    useEffect(() => {
        if (selectedCity?.state_code || selectedService?.city)
            dispatch(
                getAllDistricts(selectedCity.state_code, selectedService?.city)
            );
    }, [selectedCity, selectedService?.city]);

    return (
        <form
            onSubmit={handleSubmit(handleFormSubmit)}
            className="service-form"
        >
            <div className="service-form-error">
                <p className="service-form-error__text">
                    {identityError ? identityError : " "}
                </p>
            </div>
            <div className="service-form-container">
                <div className="service-form__left-column">
                    <p className="service-form__section-title">
                        {t("specialist-services.service.service-description")}
                    </p>
                    {services.length && (
                        <>
                            <Controller
                                name="categoryId"
                                control={control}
                                render={({
                                    field: { onChange, onBlur, value, name },
                                }) => (
                                    <Select
                                        name={name}
                                        error={errors.categoryId?.message}
                                        value={value}
                                        onBlur={(category) => onBlur(category)}
                                        options={mapStateToOptions(services)}
                                        onSelect={(category) => {
                                            clearFields([
                                                "subcategoryId",
                                                "clarificationId",
                                            ]);
                                            onChange(category);
                                            dispatch(
                                                getSubservices(
                                                    category.value,
                                                    "local"
                                                )
                                            );
                                            handleIdentityService();
                                        }}
                                        label={t(
                                            "specialist-services.form.service-sector.label"
                                        )}
                                        placeholder={t(
                                            "specialist-services.form.service-sector.placeholder"
                                        )}
                                    />
                                )}
                            />
                            <Controller
                                name="subcategoryId"
                                control={control}
                                render={({
                                    field: { onChange, onBlur, value, name },
                                }) => (
                                    <Select
                                        name={name}
                                        value={value}
                                        error={errors.subcategoryId?.message}
                                        onBlur={(category) => onBlur(category)}
                                        options={mapStateToOptions(subservices)}
                                        onSelect={(category) => {
                                            clearFields(["clarificationId"]);
                                            onChange(category);
                                            dispatch(
                                                getClarifications(
                                                    category.value,
                                                    "local"
                                                )
                                            );
                                            handleIdentityService();
                                        }}
                                        label={t(
                                            "specialist-services.form.service.label"
                                        )}
                                        placeholder={t(
                                            "specialist-services.form.service.placeholder"
                                        )}
                                    />
                                )}
                            />
                            <Controller
                                name="clarificationId"
                                control={control}
                                render={({
                                    field: { onChange, onBlur, value, name },
                                }) => (
                                    <Select
                                        name={name}
                                        error={errors.clarificationId?.message}
                                        value={value}
                                        onBlur={(category) => onBlur(category)}
                                        options={mapStateToOptions(
                                            clarifications
                                        )}
                                        onSelect={(category) => {
                                            onChange(category);
                                            handleIdentityService();
                                        }}
                                        label={t(
                                            "specialist-services.form.service-clarification.label"
                                        )}
                                        placeholder={t(
                                            "specialist-services.form.service-clarification.placeholder"
                                        )}
                                    />
                                )}
                            />
                        </>
                    )}
                    <p className="service-form__section-title">
                        {t("specialist-services.service.execution-address")}
                    </p>
                    <Controller
                        control={control}
                        name="city"
                        render={({
                            field: { onChange, onBlur, name, value },
                        }) => (
                            <LocationSelect
                                value={value}
                                name={name}
                                error={errors.city?.message}
                                onSelect={(city) => {
                                    handleSelectCity(city);
                                    onChange(city.name);
                                }}
                                onBlur={(city) => onBlur(city.name)}
                                options={cities}
                                type={SELECT_TYPES.WITHOUT_LABEL}
                                label={t("specialist-services.form.city.label")}
                                placeholder={t(
                                    "specialist-services.form.city.placeholder"
                                )}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="district"
                        render={({ field: { value, onChange, onBlur } }) => (
                            <LocationSelect
                                value={value}
                                options={districts}
                                onSelect={({ name }) => onChange(name)}
                                onBlur={({ name }) => onBlur(name)}
                                type={SELECT_TYPES.WITHOUT_LABEL}
                                error={errors.district?.message}
                                label={t(
                                    "specialist-services.form.district.label"
                                )}
                                placeholder={t(
                                    "specialist-services.form.district.placeholder"
                                )}
                            />
                        )}
                    />
                    <Input
                        inputChangeOptions={register("address")}
                        label="specialist-services.form.address.label"
                        options={{
                            placeholderText:
                                "specialist-services.form.address.placeholder",
                        }}
                        type="secondary"
                        error={errors.address?.message}
                    />

                    <p className="service-form__section-title">
                        {t("specialist-services.service.schedule")}
                    </p>
                    <Controller
                        control={control}
                        name="schedule"
                        render={({ field: { onChange, value } }) => (
                            <ScheduleInput
                                placeholder={t(
                                    "specialist-services.form.schedule-placeholder"
                                )}
                                error={t(errors.schedule?.message)}
                                onChange={onChange}
                                value={value}
                            />
                        )}
                    />
                </div>
                <div className="service-form__right-column">
                    <p className="service-form__section-title">
                        {t("specialist-services.service.price-range")}
                    </p>
                    <DualPriceInput
                        inputOptionsFirst={register("priceFrom")}
                        inputOptionsSecond={register("priceTo")}
                        errorsOptionsFirst={t(errors.priceFrom?.message)}
                        errorsOptionsSecond={t(errors.priceTo?.message)}
                        type="range-dual-price"
                        label={t("specialist-services.form.price.range")}
                        watchedPriceFrom={priceFrom}
                    />
                    <p className="service-form__section-title">
                        {t("specialist-services.form.photos-upload-title")}
                    </p>
                    <div className="order-item__photos">
                        <ImageUploader
                            eventManagementFunctions={{
                                callback: (item) => {
                                    setImage([...image, ...item]);
                                },
                            }}
                            setImage={setImage}
                            value={image}
                            type={IMAGE_UPLOADER_TYPES.ORDER_ITEM}
                            id={"upload-order-photo"}
                        />
                        {image.length
                            ? image
                                  .slice(0)
                                  .reverse()
                                  .map((item, index) => (
                                      <div
                                          className="order-item__photos__item"
                                          key={
                                              item.url ||
                                              index + item.lastModifiedDate
                                          }
                                      >
                                          <LazyImage
                                              image={
                                                  item.url
                                                      ? item.url
                                                      : URL.createObjectURL(
                                                            item
                                                        )
                                              }
                                              type={typeOfLazeImage.content}
                                              options={{
                                                  className:
                                                      "order-item__photos__item-img",
                                                  alt: "logo",
                                              }}
                                          />
                                          <img
                                              onClick={(e) => {
                                                  if (item.url) {
                                                      dispatch(
                                                          deleteSpecialistServiceImage(
                                                              selectedService._id,
                                                              item._id
                                                          )
                                                      );
                                                  }

                                                  handleDeleteImage(
                                                      e,
                                                      index,
                                                      image,
                                                      setImage
                                                  );
                                              }}
                                              src={deletePhotoIcon}
                                              className="order-item__photos__item-close"
                                          />
                                      </div>
                                  ))
                            : null}
                    </div>
                    <p className="service-form__section-title">
                        {t("specialist-services.form.comments.label")}
                    </p>
                    <Textarea
                        label={t("orders.order-item.order-comments")}
                        errors={t(errors.comment?.message)}
                        inputChangeOptions={register("comment")}
                        placeholderText={t(
                            "specialist-services.form.comments.placeholder"
                        )}
                    />
                </div>
            </div>
            <div className="service-form__btns">
                <MainButton
                    buttonStyle="btn--green"
                    buttonSize="btn--medium"
                    disabled={!!identityError}
                >
                    {t("btn_save")}
                </MainButton>
                <MainButton
                    buttonStyle="btn--light"
                    buttonSize="btn--medium"
                    onClick={() => handleCancelClick()}
                >
                    {t("btn_cancel")}
                </MainButton>
            </div>
        </form>
    );
};

export default ServiceForm;
