import { useTranslation } from "react-i18next";
import React from "react";

import emptyResultIcon from "../../../../assets/images/error-pages/empty-result.svg";
import "./index.scss";

const ServiceEmptyResult = () => {
    const { t } = useTranslation();

    return (
        <div className="service__empty-result">
            <img
                className="service__empty-result--img"
                src={emptyResultIcon}
                alt="empty result"
            />
            <span className="service__empty-result--title">
                {t("specialist-services.empty-result")}
            </span>
        </div>
    );
};

export default ServiceEmptyResult;
