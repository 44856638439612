import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { buttonLoaderSelector } from "../../../../../components/loader/loader.selectors";
import { authSelector } from "../../../auth.selectors";
import {
    restoreEmail,
    restorePhone,
    restoreByOauth,
} from "../../../auth.operations";

import ReactHelmet from "../../../../../components/react-helmet/react-helmet";
import ButtonLoader from "../../../../../components/loader/components/button-loader/button-loader";

import restoreAccountIcon from "../../../../../assets/images/auth/forgot_password.svg";

import "./index.scss";

const RestoreAccountRequest = () => {
    const { t } = useTranslation();

    const { status: buttonLoading } = useSelector(buttonLoaderSelector);
    const {
        restoreAccount: { email, phone, facebook },
    } = useSelector(authSelector);
    const dispatch = useDispatch();

    const restoreAccountHandler = () => {
        if (email) dispatch(restoreEmail());
        if (phone) dispatch(restorePhone());
        if (facebook) dispatch(restoreByOauth());
    };

    return (
        <Fragment>
            <ReactHelmet title="restore_account_title" />
            <div className="restore-account__request">
                <div className="restore-account__request_content">
                    <img
                        className="restore-account__request-img"
                        src={restoreAccountIcon}
                        alt="forgot password"
                    />
                    <p className="restore-account__request-title">
                        {t("restore_account_title")}
                    </p>
                    <span className="restore-account__request-subtitle">
                        {t("restore_account_subtitle")}
                    </span>
                </div>
                <div className="restore-account__request-btns">
                    <button
                        className="restore-account__request-btns-left"
                        style={{
                            background: buttonLoading && "#dfdfdf",
                        }}
                        onClick={restoreAccountHandler}
                        disabled={buttonLoading}
                    >
                        {buttonLoading ? (
                            <ButtonLoader size={38} />
                        ) : (
                            t("restore_account_button")
                        )}
                    </button>
                </div>
            </div>
        </Fragment>
    );
};

export default RestoreAccountRequest;
