import { loaderTypes } from "./loader.types";

const initialState = {
    buttonLoader: { status: false, button: "" },
    globalLoader: false,
};

export const loader = (state = initialState, { type, payload }) => {
    switch (type) {
        case loaderTypes.CHANGE_GLOBAL_LOADER:
            return { ...state, globalLoader: payload };

        case loaderTypes.CHANGE_BUTTON_LOADER:
            return { ...state, buttonLoader: payload };

        default:
            return state;
    }
};
