import { object, string, date } from "yup";
import { t } from "i18next";
import dayjs from "dayjs";

import {
    passwordValidation,
    isDigitValidation,
} from "../../../constants/input-validation";

const minimalAllowedDate = dayjs().subtract(18, "years").toDate();

export const userSchema = object().shape({
    phoneValue: string()
        .trim(true)
        .required("validation_required")
        .matches(isDigitValidation, "only digits allowed")
        .min(7)
        .max(13),
    phoneCode: string().required("validation_required"),
    firstName: string()
        .trim(true)
        .required("validation_required")
        .min(2, ({ min }) => t("validation_min", { min })),
    lastName: string()
        .trim(true)
        .required("validation_required")
        .min(2, ({ min }) => t("validation_min", { min })),
    gender: object()
        .nullable("validation_required")
        .required("validation_required"),
    birthday: date()
        .nullable()
        .transform((curr, orig) => (orig === "" ? null : curr))
        .max(minimalAllowedDate, () => t("validation_minimal_age"))
        .required("validation_required"),
    createPassword: string()
        .trim(true)
        .required("validation_required")
        .min(8, ({ min }) => t("validation_min", { min }))
        .matches(passwordValidation, t("validation_password_required_symbols")),
    confirmPassword: string()
        .required("validation_required")
        .test("len", "validation_same_password", (val1, { parent }) => {
            return parent.createPassword === parent.confirmPassword;
        })
        .matches(passwordValidation, t("validation_password_required_symbols")),
});

export const oauthUserSchema = object().shape({
    firstName: string()
        .trim(true)
        .required("validation_required")
        .min(2, ({ min }) => t("validation_min", { min })),
    lastName: string()
        .trim(true)
        .required("validation_required")
        .min(2, ({ min }) => t("validation_min", { min })),
    gender: object().nullable("validation_required"),
    birthday: date()
        .nullable()
        .transform((curr, orig) => (orig === "" ? null : curr))
        .max(minimalAllowedDate, () => t("validation_minimal_age")),
    createPassword: string()
        .trim(true)
        .required("validation_required")
        .min(8, ({ min }) => t("validation_min", { min }))
        .matches(passwordValidation, t("validation_password_required_symbols")),
    confirmPassword: string()
        .required("validation_required")
        .test("len", "validation_same_password", (val1, { parent }) => {
            return parent.createPassword === parent.confirmPassword;
        })
        .matches(passwordValidation, t("validation_password_required_symbols")),
});
