import { useTranslation } from "react-i18next";
import { useState } from "react";
import PropTypes from "prop-types";

import ScheduleWorkItem from "./schedule-work-item/schedule-work-item";

import { scheduleTime, scheduleWork } from "./schedule-work.config";
import { ReactComponent as Close } from "../../assets/images/close-icons/close-crosshair-gray.svg";

import "./index.scss";

const ScheduleWork = ({
    onSaveScheduleData,
    onCloseSchedule,
    defaultSchedule = [],
}) => {
    const { t } = useTranslation();

    const checkDefaultSchedule = () => {
        if (defaultSchedule.length) {
            const scheduleFromDefaultValues = [];

            scheduleWork.forEach((item) => {
                const itemFromDefaultValues = defaultSchedule.find(
                    (defaultItem) => defaultItem.day === item.day
                );

                if (itemFromDefaultValues) {
                    scheduleFromDefaultValues.push(itemFromDefaultValues);
                } else {
                    scheduleFromDefaultValues.push(item);
                }
            });
            return scheduleFromDefaultValues;
        }

        return scheduleWork;
    };

    const [schedule, setSchedule] = useState(checkDefaultSchedule());
    const setDayStatus = (day) => {
        const newSchedule = schedule.map((obj) =>
            obj.day === day
                ? {
                      ...obj,
                      isWeekend: !obj.isWeekend,
                      workingHours: [{ timeFrom: "8:00", timeTo: "17:00" }],
                  }
                : obj
        );
        setSchedule(newSchedule);
    };
    const setTimeAM = (day, value) => {
        const newSchedule = schedule.map((obj) =>
            obj.day === day
                ? {
                      ...obj,
                      workingHours: [
                          {
                              timeTo: obj.workingHours[0].timeTo,
                              timeFrom: value,
                          },
                      ],
                  }
                : obj
        );
        setSchedule(newSchedule);
    };
    const setTimePM = (day, value) => {
        const newSchedule = schedule.map((obj) =>
            obj.day === day
                ? {
                      ...obj,
                      workingHours: [
                          {
                              timeTo: value,
                              timeFrom: obj.workingHours[0].timeFrom,
                          },
                      ],
                  }
                : obj
        );
        setSchedule(newSchedule);
    };

    const onSave = () => {
        onSaveScheduleData(schedule);
        onCloseSchedule();
    };
    const onCancel = () => {
        setSchedule(scheduleWork);
        onCloseSchedule();
    };
    const onClose = (e) => {
        e.preventDefault();
        onCloseSchedule();
    };

    return (
        <div className="schedule_work">
            <div className="schedule_work__content">
                <div className="schedule_work__header">
                    <h3 className="schedule_work__title">
                        {t("specialist-services.service.schedule")}
                    </h3>
                    <div
                        className="schedule_work__btn--close"
                        onClick={onClose}
                    >
                        <Close />
                    </div>
                </div>

                <div className="schedule_work__table">
                    {schedule.map(
                        ({ day, isWeekend = false, workingHours }) => (
                            <ScheduleWorkItem
                                key={day}
                                day={day}
                                isWeekend={isWeekend}
                                defaultTimeAM={workingHours[0]?.timeFrom}
                                defaultTimePM={workingHours[0]?.timeTo}
                                scheduleTimeAM={scheduleTime}
                                scheduleTimePM={scheduleTime}
                                onChangeTimePM={setTimePM}
                                onChangeTimeAM={setTimeAM}
                                onChangeDayStatus={setDayStatus}
                            />
                        )
                    )}
                </div>
                <div className="schedule_work__service">
                    <button
                        className="schedule_work__btn--cancel"
                        onClick={onCancel}
                    >
                        {t("btn_cancel")}
                    </button>
                    <button
                        className="schedule_work__btn--save"
                        onClick={onSave}
                    >
                        {t("btn_save")}
                    </button>
                </div>
            </div>
        </div>
    );
};
ScheduleWork.propTypes = {
    onSaveScheduleData: PropTypes.func.isRequired,
    onCloseSchedule: PropTypes.func.isRequired,
    defaultSchedule: PropTypes.array,
};
export default ScheduleWork;
