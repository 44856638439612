import { t } from "i18next";

import { userRoles } from "../../../../constants/user-roles";
import { Icon } from "../../../../components/image/image";

export const typeServices = {
    proposePrice: "PROPOSE_PRICE",
    approveOrder: "APPROVE_ORDER",
    closeOrder: "CLOSE_ORDER",
};

export const accessTaskStatus = {
    negotiation: "NEGOTIATION",
    priceIsFixed: "PRICE_IS_FIXED",
    inWork: "IN_WORK",
    completed: "COMPLETED",
};

export const failureTaskStatus = {
    cancelled: "CANCELLED",
};

export const servicesData = [
    {
        typeService: typeServices.proposePrice,
        icon: <Icon type="chatPrice" />,
        title: "chats_item.propose_fix_price",
        access: accessTaskStatus.negotiation,
        roles: [userRoles.customer, userRoles.specialist],
    },
    {
        typeService: typeServices.approveOrder,
        icon: <Icon type="chatApprove" />,
        title: "chats_item.accept_start_order",
        access: accessTaskStatus.priceIsFixed,
        roles: [userRoles.specialist],
    },
    {
        typeService: typeServices.closeOrder,
        icon: <Icon type="chatClose" />,
        title: "chats_item.close_order",
        access: accessTaskStatus.inWork,
        roles: [userRoles.specialist],
    },
];

export const selectedAdditionals = [
    {
        icon: "chatService",
        selected: "service",
    },
    {
        icon: "attachFile",
        selected: "file",
    },
    {
        icon: "chooseVideo",
        selected: "video",
    },
    {
        icon: "chooseEmoji",
        selected: "emoji",
    },
];

export const emogiPickerConfig = {
    search: t("emogi.search"),
    clear: t("emogi.clear"),
    notfound: t("emogi.notfound"),
    skintext: t("emogi.skintext"),
    categories: {
        search: t("emogi.categories.search"),
        recent: t("emogi.categories.recent"),
        smileys: t("emogi.categories.smileys"),
        people: t("emogi.categories.people"),
        nature: t("emogi.categories.nature"),
        foods: t("emogi.categories.foods"),
        activity: t("emogi.categories.activity"),
        places: t("emogi.categories.places"),
        objects: t("emogi.categories.objects"),
        symbols: t("emogi.categories.symbols"),
        flags: t("emogi.categories.flags"),
        custom: t("emogi.categories.custom"),
    },
};

const operationStatus = {
    COMPLETED_APPROVED: "completedApproved",
    IN_WORK_APPROVED: "inWorkApproved",
    PRICE_APPROVED: "priceApproved",
    PROPOSED_FIX_PRICE: "proposedFixPrice",
    CONFIRM_START_WORK: "confirmStartWork",
    CONFIRM_COMPLETE_WORK: "confirmCompleteWork",
    WORK_CANCELLED: "workCancelled",
};

export const chatHintMessage = {
    [userRoles.specialist]: {
        [operationStatus?.PRICE_APPROVED]: {
            title: "chats_hints.specialist_confirm_start_work",
        },
        [operationStatus?.PROPOSED_FIX_PRICE]: {
            title: "chats_hints.specialist_proposed_fix_price",
        },
        [operationStatus?.PRICE_APPROVED]: {
            title: "chats_hints.specialist_price_approved",
        },
        [operationStatus?.COMPLETED_APPROVED]: {
            title: "chats_hints.specialist_completed_approved",
        },
        [operationStatus?.WORK_CANCELLED]: {
            title: "chats_hints.specialist_reject_an_order",
        },
    },
    [userRoles.customer]: {
        [operationStatus?.CONFIRM_START_WORK]: {
            title: "chats_hints.customer_confirm_complete_work",
        },
        [operationStatus?.PROPOSED_FIX_PRICE]: {
            title: "chats_hints.customer_proposed_fix_price",
        },
        [operationStatus?.COMPLETED_APPROVED]: {
            title: "chats_hints.customer_completed_approved",
        },
    },
    startChat: "chats_hints.start_chat",
};
