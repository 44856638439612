import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import React from "react";

import { buttonLoaderSelector } from "../../../../components/loader/loader.selectors";
import { buyPromotionById } from "../../promotion.operations";
import SELECT_TYPES from "../../../../components/select/select.constants";
import MainButton from "../../../../components/main-button/main-button";
import { promotionModalPaySchema } from "./promotion-modal-pay.schema";
import { paymentMethods } from "../../../balance/balance.constants";
import Select from "../../../../components/select/select";
import routerBook from "../../../../router/router-book";
import { promotionConstants } from "../../promotion.constants";

import "./index.scss";

const PromotionModalPay = ({ details: { _id, priceCount, priceType } }) => {
    const { status: statusLoading, button: buttonLoading } =
        useSelector(buttonLoaderSelector);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const {
        handleSubmit,
        control,
        formState: { errors, isValid },
    } = useForm({
        mode: "onChange",
        defaultValues: {},
        resolver: yupResolver(promotionModalPaySchema),
    });

    const callbackUrl = `${location.origin}${routerBook.accessForSpecialist.promotion}/${promotionConstants.acting}`;

    const handleFormSubmit = ({ source: { value } }) => {
        dispatch(
            buyPromotionById({ _id, priceCount, priceType, callbackUrl }, value)
        );
    };

    return (
        <form
            className="promotion_modal_pay"
            onSubmit={handleSubmit(handleFormSubmit)}
        >
            <h1 className="promotion_modal_pay__title">
                {t("promotion.modal-pay.title")}
            </h1>
            <Controller
                control={control}
                name="source"
                render={({ field: { onChange, onBlur, value, name } }) => (
                    <Select
                        placeholder={t(
                            "promotion.modal-pay.select-placeholder"
                        )}
                        label={t("promotion.modal-pay.select-label")}
                        onSelect={(orderId) => onChange(orderId)}
                        onBlur={(orderId) => onBlur(orderId)}
                        error={errors.source?.message}
                        type={SELECT_TYPES.WITH_LABEL}
                        options={paymentMethods}
                        value={value}
                        name={name}
                    />
                )}
            />
            <MainButton
                isLoading={statusLoading && buttonLoading === "buy-promotion"}
                buttonSize="btn--medium"
                disabled={!isValid}
                type="submit"
            >
                {t("btn_pay")}
            </MainButton>
        </form>
    );
};

PromotionModalPay.propTypes = { details: PropTypes.object };
PromotionModalPay.defaultProps = { details: {} };
export default PromotionModalPay;
