import { useTranslation } from "react-i18next";

import "./index.scss";

const UserNotSelectFields = () => {
    const { t } = useTranslation();
    return (
        <div className="user-not-select-fields">
            {t("validation_select_all_fields")}
        </div>
    );
};

export default UserNotSelectFields;
