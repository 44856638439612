export const ordersSpecialistTypes = {
    // get  orders
    GET_ALL_ORDERS_PENDING: "order/GET_ALL_ORDERS_PENDING",
    GET_ALL_ORDERS_FULFILLED: "order/GET_ALL_ORDERS_FULFILLED",
    GET_ALL_ORDERS_REJECTED: "order/GET_ALL_ORDERS_REJECTED",

    //get order
    GET_ORDER_PENDING: "order/GET_ORDER_PENDING",
    GET_ORDER_FULFILLED: "order/GET_ORDER_FULFILLED",
    GET_ORDER_REJECTED: "order/GET_ORDER_REJECTED",

    // set
    SET_PAGE: "order/SET_PAGE",
    SET_STATUS: "order/SET_STATUS",
    CLEAR_ALL_PARAMS: "order/CLEAR_ALL_PARAMS",
};
