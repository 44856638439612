import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import FAQItem from "./components/faq-item";
import NoFAQ from "./components/no-faq/no-faq";
import { clearAllParams } from "./faq.actions";
import { getAllFAQ } from "./faq.operations";
import { faqSelector } from "./faq.selectors";

import "./index.scss";

const FAQ = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        faq: { items },
        loading,
    } = useSelector(faqSelector);

    useEffect(() => {
        dispatch(getAllFAQ());

        return () => dispatch(clearAllParams());
    }, []);

    return (
        <div className="faq">
            <div className="faq__header">
                <h1 className="faq__header-title">{t("faq-title")}</h1>
            </div>
            <div className="faq__content">
                {items.length > 0 && !loading ? (
                    items.map((item) => <FAQItem {...item} key={item._id} />)
                ) : (
                    <NoFAQ />
                )}
            </div>
        </div>
    );
};

export default FAQ;
