import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import dayjs from "dayjs";

import Accordion from "../../../../components/accordion/accordion";
import { Icon } from "../../../../components/image/image";
import { DAYS_LIST } from "../../../../constants/days";
import { getLanguage } from "../../../../helpers/language";

import "./index.scss";

const Services = ({ specialistServices }) => {
    const { t } = useTranslation();
    const { value: language } = getLanguage();

    const schedule = (specialistSchedule) => {
        return DAYS_LIST.map((day) => {
            return (
                <div className="schedule__item" key={day}>
                    <span>
                        {t("specialist-services.service.schedule")}:{" "}
                        {t(`days.${day}`).toLowerCase()}
                    </span>
                    <span>
                        {generateTimeStringForLayout(day, specialistSchedule)}
                    </span>
                </div>
            );
        });
    };

    const generateTimeStringForLayout = (searchedDay, specialistSchedule) => {
        const currentScheduleItem = specialistSchedule.find(
            (scheduleItem) => scheduleItem.day === searchedDay
        )?.workingHours[0];

        if (!currentScheduleItem)
            return (
                t("specialist-services.form.weekend")[0].toLocaleUpperCase() +
                t("specialist-services.form.weekend").slice(1)
            );

        return `${dayjs(currentScheduleItem["timeFrom"]).format(
            "HH:mm"
        )} - ${dayjs(currentScheduleItem["timeTo"]).format("HH:mm")}`;
    };

    return (
        <div className="services">
            <p className="services__title">
                {t("specialist-profile.services-and-prices-title")}
            </p>
            {specialistServices.length ? (
                specialistServices.map((category) => {
                    return category.subcategories[0].services.map((service) => {
                        return (
                            <div key={service._id}>
                                <div className="services__category">
                                    {
                                        service.category.titleTranslations[
                                            language
                                        ]
                                    }
                                </div>
                                <div className="services__item">
                                    <div className="services__item__additional">
                                        <span>
                                            {
                                                service.clarification
                                                    .titleTranslations[language]
                                            }
                                        </span>
                                        <span>
                                            {`${service.priceFrom} - ${service.priceTo} `}
                                            {t("currency.short.lira")}
                                        </span>
                                    </div>
                                    <Accordion
                                        icon={<Icon type="triangleArrowDown" />}
                                        inlineStyles={{
                                            padding: "16px 0",
                                        }}
                                    >
                                        {schedule(service.schedule)}
                                    </Accordion>
                                    <div className="services__item__additional">
                                        <span>
                                            {t(
                                                "specialist-services.service.address"
                                            )}
                                        </span>
                                        <span className="services__item__additional-address">{service.address}</span>
                                    </div>
                                    {service.comment && (
                                        <p className="services__item__comment">
                                            {service.comment}
                                        </p>
                                    )}
                                </div>
                            </div>
                        );
                    });
                })
            ) : (
                <p>ivan</p>
            )}
        </div>
    );
};

Services.propTypes = {
    specialistServices: PropTypes.array.isRequired,
};

export default Services;
