import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { clearAllParams } from "../../components/all-result/all-result.actions";
import { getServices } from "../../components/all-result/all-result.operations";

import { backButtonConfig } from "../../components/back-button/back-button.config";
import { allResultSelector } from "../../components/all-result/all-result.selectors";
import AllServicesResult from "../../components/all-result/all-result";

import ReactHelmet from "../../components/react-helmet/react-helmet";
import BackButton from "../../components/back-button/back-button";
import { sortConfig } from "./all-services-results.config";
import {
    resultTypes,
    sizeItemOnPage,
} from "../../components/all-result/all-result.constant";

import "./index.scss";

const AllServicesResults = () => {
    const { services } = useSelector(allResultSelector);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const location = useLocation();

    useEffect(() => {
        dispatch(getServices(sizeItemOnPage));
        return () => dispatch(clearAllParams());
    }, []);

    return (
        <Fragment>
            <ReactHelmet title="all_service_title" />
            <section className="all-services-results">
                <div className="all-services-results__header">
                    <BackButton
                        title={t(backButtonConfig[location.state].title)}
                        onClickCallback={() =>
                            navigate(backButtonConfig[location.state].href, {
                                state: true,
                            })
                        }
                    />
                    <h1 className="all-services-results__title">
                        {t("all_service_title")}
                    </h1>
                </div>
                <AllServicesResult
                    type={resultTypes.SERVICES}
                    sortConfig={sortConfig}
                    isFavorite={false}
                    data={services}
                />
            </section>
        </Fragment>
    );
};

export default AllServicesResults;
