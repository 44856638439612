export const myAccountInfoFirstConfig = [
    {
        typeInfo: "info",
        labelInfo: "my_account_name",
        registerInfo: "firstName",
        disable: true,
    },
    {
        typeInfo: "info",
        labelInfo: "my_account_surname",
        registerInfo: "lastName",
        disable: true,
    },
];

export const myAccountInfoSecondConfig = [
    {
        typeInfo: "info",
        labelInfo: "my_account_facebook",
        registerInfo: "socialLinks.fb",
        placeholderTextInfo: "my_account_facebook_placeholder",
        disable: true,
    },
    {
        typeInfo: "info",
        labelInfo: "my_account_instagram",
        registerInfo: "socialLinks.inst",
        placeholderTextInfo: "my_account_instagram_placeholder",
        disable: true,
    },
    {
        typeInfo: "info",
        labelInfo: "my_account_email",
        registerInfo: "email",
        placeholderTextInfo: "my_account_email_placeholder",
        disable: true,
    },
];
