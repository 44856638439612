import { orderTypes } from "./orders.types";
import { sizeItemOnPage } from "./orders.constant";

const initialQueryParams = {
    status: "&statuses=NEW",
    limit: sizeItemOnPage,
    page: 0,
};

const initialState = {
    orders: { items: [], count: 0 },
    order: {},

    orderTask: {
        priceProposal: { proposedBy: null, approvedBy: null, price: 0 },
        priceProposalHistory: [],
        confirmations: {},
        status: "",
    },
    orderResponders: { orderName: "", items: [], count: 0 },
    orderTaskOtherSpecialist: [],

    params: initialQueryParams,
    loading: false,
    error: "",
};

export const order = (state = initialState, { type, payload }) => {
    switch (type) {
        // clear all params
        case orderTypes.CLEAR_ALL_PARAMS:
            return { ...state, params: initialQueryParams };

        // set status
        case orderTypes.SET_STATUS:
            return {
                ...state,
                params: { ...state.params, status: payload, page: 0 },
            };

        // set page
        case orderTypes.SET_PAGE:
            return {
                ...state,
                params: { ...state.params, page: payload },
            };

        // loading
        case orderTypes.GET_ORDER_TASK_OTHER_SPECIALIST_PENDING:
        case orderTypes.GET_ORDER_RESPONDERS_PENDING:
        case orderTypes.PUT_TASK_FIX_PRICE_PENDING:
        case orderTypes.REMOVE_ORDER_IMAGE_PENDING:
        case orderTypes.POST_ORDER_IMAGE_PENDING:
        case orderTypes.PUT_TASK_STATUS_PENDING:
        case orderTypes.GET_ORDER_TASK_PENDING:
        case orderTypes.GET_ALL_ORDERS_FOR_FAVOURITES_PENDING:
        case orderTypes.GET_ALL_ORDERS_PENDING:
        case orderTypes.RESERVE_FUNDS_PENDING:
        case orderTypes.UPDATE_ORDER_PENDING:
        case orderTypes.REMOVE_ORDER_PENDING:
        case orderTypes.POST_ORDER_PENDING:
        case orderTypes.GET_ORDER_PENDING: {
            return { ...state, loading: true };
        }

        // orders
        case orderTypes.PUT_TASK_FIX_PRICE_FULFILLED:
        case orderTypes.REMOVE_ORDER_IMAGE_FULFILLED:
        case orderTypes.POST_ORDER_IMAGE_FULFILLED:
        case orderTypes.PUT_TASK_STATUS_FULFILLED:
        case orderTypes.GET_ALL_ORDERS_FOR_FAVOURITES_FULFILLED:
        case orderTypes.GET_ALL_ORDERS_FULFILLED:
        case orderTypes.REMOVE_ORDER_FULFILLED:
        case orderTypes.UPDATE_ORDER_FULFILLED:
        case orderTypes.POST_ORDER_FULFILLED: {
            return { ...state, loading: false, orders: payload };
        }

        // order
        case orderTypes.GET_ORDER_FULFILLED: {
            return { ...state, loading: false, order: payload };
        }

        // order task
        case orderTypes.GET_ORDER_TASK_FULFILLED: {
            return { ...state, loading: false, orderTask: payload };
        }

        // order task
        case orderTypes.GET_ORDER_TASK_OTHER_SPECIALIST_FULFILLED: {
            return {
                ...state,
                loading: false,
                orderTaskOtherSpecialist: payload,
            };
        }

        // order responders
        case orderTypes.GET_ORDER_RESPONDERS_FULFILLED: {
            return { ...state, loading: false, orderResponders: payload };
        }

        // error
        case orderTypes.GET_ORDER_TASK_OTHER_SPECIALIST_REJECTED:
        case orderTypes.GET_ORDER_RESPONDERS_REJECTED:
        case orderTypes.PUT_TASK_FIX_PRICE_REJECTED:
        case orderTypes.REMOVE_ORDER_IMAGE_REJECTED:
        case orderTypes.POST_ORDER_IMAGE_REJECTED:
        case orderTypes.PUT_TASK_STATUS_REJECTED:
        case orderTypes.GET_ORDER_TASK_REJECTED:
        case orderTypes.GET_ALL_ORDERS_FOR_FAVOURITES_REJECTED:
        case orderTypes.GET_ALL_ORDERS_REJECTED:
        case orderTypes.RESERVE_FUNDS_REJECTED:
        case orderTypes.UPDATE_ORDER_REJECTED:
        case orderTypes.REMOVE_ORDER_REJECTED:
        case orderTypes.POST_ORDER_REJECTED:
        case orderTypes.GET_ORDER_REJECTED: {
            return { ...state, loading: false, error: payload };
        }

        default:
            return state;
    }
};
