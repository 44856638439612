import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import React, { memo, useEffect } from "react";
import PropTypes from "prop-types";

import {
    promotionConstants,
    sizeActivePromotionItemOnPage,
} from "../../promotion.constants";
import PromotionServiceItem from "./components/promotion-service-item";
import { promotionSelector } from "../../promotion.selectors";
import { getPromotionHistory } from "../../promotion.operations";
import PromotionEmptyResult from "../promotion-empty-result/promotion-empty-result";

const PromotionService = ({ primaryBtnTitle, secondaryBtnTitle }) => {
    const { allPromotion, promotionHistory } = useSelector(promotionSelector);
    const dispatch = useDispatch();

    const { filterParameter } = useParams();

    useEffect(() => {
        dispatch(
            getPromotionHistory(
                sizeActivePromotionItemOnPage,
                "&onlyActive=true"
            )
        );
    }, []);

    return (
        <div>
            {filterParameter === promotionConstants.acting && (
                <>
                    {promotionHistory.items.length ? (
                        <>
                            {promotionHistory.items.map((item) => (
                                <PromotionServiceItem
                                    key={item._id}
                                    promotion={item.promotion}
                                    primaryBtnTitle={primaryBtnTitle}
                                    secondaryBtnTitle={secondaryBtnTitle}
                                    activePromotions
                                    {...item}
                                />
                            ))}
                        </>
                    ) : (
                        <PromotionEmptyResult />
                    )}
                </>
            )}

            {filterParameter === promotionConstants.condition && (
                <>
                    {allPromotion.items.length ? (
                        <>
                            {allPromotion.items.map((item) => (
                                <PromotionServiceItem
                                    key={item._id}
                                    promotion={item}
                                    primaryBtnTitle={primaryBtnTitle}
                                    secondaryBtnTitle={secondaryBtnTitle}
                                />
                            ))}
                        </>
                    ) : (
                        <PromotionEmptyResult />
                    )}
                </>
            )}
        </div>
    );
};

PromotionService.propTypes = {
    secondaryBtnTitle: PropTypes.string,
    primaryBtnTitle: PropTypes.string,
};

PromotionService.defaultProps = {
    primaryBtnTitle: "btn_buy",
    secondaryBtnTitle: "",
};

export default memo(PromotionService);
