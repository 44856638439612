import * as api from "../../services/requests/payment";
import * as newBalanceApi from "../../services/requests/balance";

import { successToastify } from "../../components/toastify/toastify.operations";
import { balanceSelector, transactionsSelector } from "./balance.selectors";
import { myAccountDataSelector } from "../my-account/my-account.selectors";
import { getUserFulfilled } from "../my-account/my-account.actions";
import { closeModal } from "../../components/modal/modal.actions";
import * as balanceActions from "./balance.actions";
import {
    changeGlobalLoader,
    changeButtonLoader,
} from "../../components/loader/loader.actions";

import { transactionTypes } from "./components/balance-transactions/components/balance-transactions-item/balance-transactions-item.config";
import { paymentTypes } from "./balance.constants";

// get balance
export const getBalance = () => (dispatch) => {
    dispatch(balanceActions.getBalancePending());
    dispatch(changeGlobalLoader(true));

    return api
        .getBalanceRequest()
        .then((data) => dispatch(balanceActions.getBalanceFulfilled(data)))
        .catch((error) => dispatch(balanceActions.getBalanceRejected(error)))
        .finally(() => dispatch(changeGlobalLoader(false)));
};

// get transactions
export const getTransactions =
    (limit = 5) =>
    (dispatch) => {
        dispatch(changeButtonLoader({ status: true, button: "transactions" }));
        dispatch(balanceActions.getTransactionsPending());

        return api
            .getTransactionsRequest(`?size=${limit}`)
            .then((data) => {
                dispatch(balanceActions.getTransactionsFulfilled(data));
            })
            .catch((error) => {
                dispatch(balanceActions.getTransactionsRejected(error));
            })
            .finally(() => {
                dispatch(changeButtonLoader({ status: false, button: "" }));
            });
    };

// create stripe
export const createStripe = (callbackUrl) => (dispatch) => {
    dispatch(balanceActions.createStripePending());
    dispatch(
        changeButtonLoader({ status: true, button: paymentTypes.stripe.value })
    );

    return api
        .createStripeAccount({ callbackUrl })
        .then(({ onboardingUrl }) => window.open(onboardingUrl, "_self"))
        .catch((error) => dispatch(balanceActions.createStripeRejected(error)))
        .finally(() => {
            dispatch(changeButtonLoader({ status: false, button: "" }));
        });
};

// refill stripe balance
export const refillStripeBalance = (data) => (dispatch) => {
    dispatch(
        changeButtonLoader({ status: true, button: paymentTypes.stripe.value })
    );
    dispatch(balanceActions.refillBalancePending());

    return api
        .refillStripeBalanceRequest(data)
        .then(({ sessionUrl }) => window.open(sessionUrl, "_self"))
        .catch((error) => dispatch(balanceActions.refillBalanceRejected(error)))
        .finally(() => {
            dispatch(changeButtonLoader({ status: false, button: "" }));
        });
};

// payout stripe balance
export const payoutStripeBalance = (data, setValue) => (dispatch, getState) => {
    dispatch(
        changeButtonLoader({ status: true, button: paymentTypes.stripe.value })
    );
    dispatch(balanceActions.payoutBalancePending());

    const transactions = transactionsSelector(getState());
    const balance = balanceSelector(getState());
    const { items, next } = transactions;

    const newAvaliableBalance = balance.availableBalance - data.amount;
    const updateBalance = { ...balance, availableBalance: newAvaliableBalance };

    return api
        .payoutStripeBalanceRequest(data)
        .then(({ message }) => {
            dispatch(balanceActions.getBalanceFulfilled(updateBalance));
            const newItem = {
                type: transactionTypes.payout,
                amount: data.amount,
                date: new Date(),
            };
            const newTransactions = { items: [newItem, ...items], next };
            dispatch(balanceActions.getTransactionsFulfilled(newTransactions));
            dispatch(successToastify(message));
            setValue("amount", undefined);
        })
        .catch((error) => dispatch(balanceActions.payoutBalanceRejected(error)))
        .finally(() => {
            dispatch(changeButtonLoader({ status: false, button: "" }));
        });
};

// create iyzico
export const createIyzico = (data) => (dispatch, getState) => {
    const account = myAccountDataSelector(getState());
    dispatch(balanceActions.createIyzicoPending());
    dispatch(
        changeButtonLoader({ status: true, button: paymentTypes.iyzico.value })
    );

    return api
        .createIyzicoAccount(data)
        .then(({ onboarding: { finished } }) => {
            dispatch(
                getUserFulfilled({
                    ...account,
                    onboarding: { ...account.onboarding, iyzico: finished },
                })
            );
            dispatch(
                successToastify("balance-onboarding-finished-placeholder")
            );
        })
        .catch((error) => dispatch(balanceActions.createIyzicoRejected(error)))
        .finally(() => {
            dispatch(changeButtonLoader({ status: false, button: "" }));
            dispatch(closeModal());
        });
};

// **************** new balance operations ******************
export const getUserBalanceOperation = (account) => async (dispatch) => {
    dispatch(balanceActions.getUserBalanceRequest());

    return newBalanceApi
        .getUserBalanceRequest(`?account=${account}`)
        .then((response) =>
            dispatch(balanceActions.getUserBalanceSuccess(response))
        )
        .catch((error) => dispatch(balanceActions.getUserBalanceError(error)));
};

export const getUserBalanceDetailsOperation = () => async (dispatch) => {
    dispatch(balanceActions.getUserBalanceDetailsRequest());

    return newBalanceApi
        .getUserBalanceDetailsRequest()
        .then((response) =>
            dispatch(balanceActions.getUserBalanceDetailsSuccess(response))
        )
        .catch((error) =>
            dispatch(balanceActions.getUserBalanceDetailsError(error))
        );
};

export const getUserBalanceFullDetailsOperation = () => async (dispatch) => {
    dispatch(balanceActions.getUserBalanceFullDetailsRequest());

    return newBalanceApi
        .getUserBalanceFullDetailsRequest()
        .then((response) =>
            dispatch(balanceActions.getUserBalanceFullDetailsSuccess(response))
        )
        .catch((error) =>
            dispatch(balanceActions.getUserBalanceFullDetailsError(error))
        );
};

export const getUserBalanceHistoryOperation = () => async (dispatch) => {
    dispatch(balanceActions.getUserBalanceHistoryRequest());

    return newBalanceApi
        .getUserBalanceHistoryRequest()
        .then((response) =>
            dispatch(balanceActions.getUserBalanceHistorySuccess(response))
        )
        .catch((error) =>
            dispatch(balanceActions.getUserBalanceHistoryError(error))
        );
};

export const initializeSessionPayOperation = (data) => async (dispatch) => {
    dispatch(balanceActions.initializeSessionPayRequest());
    dispatch(changeGlobalLoader(true));

    return newBalanceApi
        .initializeSessionPayRequest(data)
        .then(() => {
            dispatch(balanceActions.initializeSessionPaySuccess());
        })
        .then(() => dispatch(checkSessionPayOperation()))
        .catch((error) =>
            dispatch(balanceActions.initializeSessionPayError(error))
        )
        .finally(() => dispatch(changeGlobalLoader(false)));
};

export const checkSessionPayOperation = (data) => async (dispatch) => {
    dispatch(balanceActions.checkSessionPayRequest());

    return newBalanceApi
        .checkSessionPayRequest(data)
        .then((response) => {
            dispatch(balanceActions.checkSessionPaySuccess(response));
        })
        .catch((error) => dispatch(balanceActions.checkSessionPayError(error)));
};

export const withdrawalMoneyOperation = (data) => async (dispatch) => {
    dispatch(balanceActions.withdrawalMoneyRequest());

    return newBalanceApi
        .withdrawalMoneyRequest(data)
        .then((response) =>
            dispatch(balanceActions.withdrawalMoneySuccess(response))
        )
        .catch((error) => dispatch(balanceActions.withdrawalMoneyError(error)));
};

export const printInvoiceOperation = (id, ref) => async (dispatch) => {
    dispatch(balanceActions.printInvoiceRequest());

    return newBalanceApi
        .printInvoiceRequest(`?idInvoice=${id}`)
        .then((response) => {
            const blob = new Blob([response], {
                type: "application/pdf",
            });
            const blobUrl = URL.createObjectURL(blob);

            ref.current.download = "blank.pdf";
            ref.current.href = blobUrl;
            ref.current.click();

            URL.revokeObjectURL(ref.current.href);
        })
        .catch((error) => dispatch(balanceActions.printInvoiceError(error)));
};

export const printInvoiceListOperation =
    ({ startDate, endDate }, ref) =>
    async (dispatch) => {
        dispatch(
            changeButtonLoader({ status: true, button: "invoiceDownload" })
        );
        dispatch(balanceActions.printInvoiceListRequest());

        return newBalanceApi
            .printInvoiceListRequest(
                `?startDate=${startDate}&endDate=${endDate}`
            )
            .then((response) => {
                const blob = new Blob([response], { type: "application/pdf" });
                const blobUrl = URL.createObjectURL(blob);

                ref.current.download = "blank.pdf";
                ref.current.href = blobUrl;
                ref.current.click();

                URL.revokeObjectURL(ref.current.href);
            })
            .catch((error) =>
                dispatch(balanceActions.printInvoiceListError(error))
            )
            .finally(() => {
                dispatch(changeButtonLoader({ status: false, button: "" }));
            });
    };
