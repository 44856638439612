import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import React from "react";
import dayjs from "dayjs";
import { useParams } from "react-router";

import { openModal } from "../../../../../../components/modal/modal.actions";

import LazyImage from "../../../../../../components/lazy-image/lazy-image";
import { modalConstants } from "../../../../../../components/modal/modal.constants";
import { typeOfLazeImage } from "../../../../../../components/lazy-image/lazyImage.constansts";
import { getLocalizedService } from "../../../../../../helpers/get-localized-service";
import { ORDERS_FILTERS } from "../../../../orders.constant";
import ExecutorsList from "../executors-list/executors-list";

import editOrderIcon from "../../../../../../assets/images/orders/edit-order.svg";
import deleteOrderIcon from "../../../../../../assets/images/orders/delete-order.svg";
import "./index.scss";
import { getHoursByMilliseconds } from "../../../../../../helpers/get-hours-by-milliseconds";

const OrderItem = (order) => {
    const {
        completionDeadline,
        clarification,
        executionTime,
        subcategory,
        category,
        priceFrom,
        fixedPrice,
        preferredGender,
        preferredYears,
        district,
        comment,
        priceTo,
        address,
        images,
        city,
        tasks,
        _id,
    } = order;

    const { filterParameter } = useParams();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleDeleteOrder = () => {
        let renderType = modalConstants.cancelOrder;

        if (
            filterParameter === ORDERS_FILTERS.NEW ||
            filterParameter === ORDERS_FILTERS.NEGOTIATION
        ) {
            renderType = modalConstants.removeOrder;
        }

        dispatch(
            openModal({
                renderType: renderType,
                closeIcon: false,
                details: {
                    orderID: _id,
                    taskID: tasks[0]?._id,
                    status: tasks[0]?.status,
                },
                isOpen: true,
            })
        );
    };

    const handleEditOrder = () => {
        dispatch(
            openModal({
                renderType: modalConstants.updateOrder,
                closeIcon: true,
                details: { _id },
                isOpen: true,
            })
        );
    };

    return (
        <>
            <div className="order-item">
                <div className="order-item__header">
                    <p className="order-item__header__title">
                        {t("orders.order-item.header")}
                        {getLocalizedService(subcategory)}
                    </p>
                    {filterParameter !== ORDERS_FILTERS.CANCELLED &&
                    filterParameter !== ORDERS_FILTERS.COMPLETED ? (
                        <div
                            className="order-item__header__controls"
                            style={{
                                width:
                                    filterParameter === ORDERS_FILTERS.NEW ||
                                    filterParameter ===
                                        ORDERS_FILTERS.NEGOTIATION
                                        ? 75
                                        : "unset",
                            }}
                        >
                            {filterParameter === ORDERS_FILTERS.NEW ||
                            filterParameter === ORDERS_FILTERS.NEGOTIATION ? (
                                <img
                                    className="order-item__header__controls-edit"
                                    onClick={handleEditOrder}
                                    src={editOrderIcon}
                                />
                            ) : null}

                            <img
                                className="order-item__header__controls-delete"
                                onClick={handleDeleteOrder}
                                src={deleteOrderIcon}
                            />
                        </div>
                    ) : null}
                </div>
                <div className="order-item__divider" />
                <div className="order-item__body">
                    <div className="order-item__body--left-column">
                        <p className="order-item__body__caption">
                            {t("orders.order-item.project-description")}
                        </p>
                        <div className="order-item__body__description-container">
                            <span className="order-item__body__value">
                                {t("orders.order-item.service-sector")}
                            </span>
                            <span className="order-item__body__description">
                                {getLocalizedService(category)}
                            </span>
                        </div>
                        <div className="order-item__body__description-container">
                            <span className="order-item__body__value">
                                {t("orders.order-item.selected-service")}
                            </span>
                            <span className="order-item__body__description">
                                {getLocalizedService(subcategory)}
                            </span>
                        </div>
                        <div className="order-item__body__description-container">
                            <span className="order-item__body__value">
                                {t("orders.order-item.service-clarification")}
                            </span>
                            <span className="order-item__body__description">
                                {getLocalizedService(clarification)}
                            </span>
                        </div>
                        <p className="order-item__body__caption">
                            {t("orders.order-item.execution-address")}
                        </p>
                        <div className="order-item__body__description-container">
                            <span className="order-item__body__value">
                                {t("orders.order-item.city")}
                            </span>
                            <span className="order-item__body__description">
                                {t("general.abbreviation.short")} {city}
                            </span>
                        </div>
                        <div className="order-item__body__description-container">
                            <span className="order-item__body__value">
                                {t("orders.order-item.district")}
                            </span>
                            <span className="order-item__body__description">
                                {district}
                            </span>
                        </div>
                        <div className="order-item__body__description-container">
                            <span className="order-item__body__value">
                                {t("orders.order-item.address")}
                            </span>
                            <span className="order-item__body__description">
                                {address}
                            </span>
                        </div>
                        <p className="order-item__body__caption">
                            {t("orders.order-item.execution-date")}
                        </p>
                        <div className="order-item__body__description-container">
                            <span className="order-item__body__value">
                                {t("orders.order-item.date")}
                            </span>
                            <span className="order-item__body__description">
                                {dayjs(completionDeadline).format("DD.MM.YYYY")}
                            </span>
                        </div>
                        <p className="order-item__body__caption">
                            {t("orders.order-item.execution-time")}
                        </p>
                        <div className="order-item__body__description-container">
                            <span className="order-item__body__value">
                                {t("orders.order-item.time")}
                            </span>
                            <span className="order-item__body__description">
                                {`${getHoursByMilliseconds(executionTime)} ${t(
                                    "locations.hour.abbreviated"
                                )}`}
                                .
                            </span>
                        </div>
                    </div>
                    <div className="order-item__body--right-column">
                        <p className="order-item__body__caption">
                            {filterParameter ===
                                ORDERS_FILTERS.PRICE_IS_FIXED || fixedPrice
                                ? t("orders.order-item.price-fixed")
                                : t("orders.order-item.order-range")}
                        </p>
                        {filterParameter === ORDERS_FILTERS.PRICE_IS_FIXED ||
                        fixedPrice ? (
                            <div className="order-item__body__description-container">
                                <span className="order-item__body__value">
                                    {t("orders.order-item.price-fixed")}
                                </span>
                                <span className="order-item__body__description">
                                    {fixedPrice} {t("currency.short.lira")}
                                </span>
                            </div>
                        ) : (
                            <div className="order-item__body__description-container">
                                <span className="order-item__body__value">
                                    {t("orders.order-item.price-range")}
                                </span>
                                <span className="order-item__body__description">
                                    {`${priceFrom} - ${priceTo} `}
                                    {t("currency.short.lira")}
                                </span>
                            </div>
                        )}

                        <p className="order-item__body__caption">
                            {t("orders.order-item.order-photos")}
                        </p>
                        <div className="order-item__photos">
                            {images && images.length ? (
                                images
                                    .slice(0)
                                    .reverse()
                                    .map(({ url, _id }) => (
                                        <div
                                            key={_id}
                                            className="order-item__photos__item"
                                            style={{
                                                marginTop: "12px",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => {
                                                dispatch(
                                                    openModal({
                                                        isOpen: true,
                                                        closeIcon: true,
                                                        renderType:
                                                            modalConstants.imageZoomer,
                                                        details: {
                                                            url,
                                                        },
                                                    })
                                                );
                                            }}
                                        >
                                            <LazyImage
                                                image={url}
                                                type={typeOfLazeImage.content}
                                                options={{
                                                    className:
                                                        "order-item__photos__item-img",
                                                    alt: "logo",
                                                }}
                                            />
                                        </div>
                                    ))
                            ) : (
                                <span className="order-item__photos-empty">
                                    {t("orders.order-item.no-images")}
                                </span>
                            )}
                        </div>

                        <p className="order-item__body__caption">
                            {t("orders.order-item.prefer-gender-and-age")}
                        </p>
                        <div className="order-item__body__description-container">
                            <span className="order-item__body__value">
                                {t("orders.order-item.prefer-gender")}
                            </span>
                            <span className="order-item__body__description">
                                {t(preferredGender.toLowerCase())}
                            </span>
                        </div>
                        <div className="order-item__body__description-container">
                            <span className="order-item__body__value">
                                {t("orders.order-item.prefer-age")}
                            </span>
                            <span className="order-item__body__description">
                                {`${preferredYears?.from} - ${preferredYears?.to} `}
                            </span>
                        </div>
                        <p className="order-item__body__caption">
                            {t("orders.order-item.order-comments")}
                        </p>
                        <div className="order-item__body__description-container-no-dash">
                            {comment}
                        </div>
                    </div>
                </div>
            </div>
            {filterParameter !== ORDERS_FILTERS.NEW &&
                filterParameter !== ORDERS_FILTERS.CANCELLED && (
                    <ExecutorsList tasks={tasks} order={order} />
                )}
        </>
    );
};

OrderItem.propTypes = { order: PropTypes.object };

export default OrderItem;
