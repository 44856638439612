import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { openModal } from "../../../../../../components/modal/modal.actions";

import MainButton from "../../../../../../components/main-button/main-button";
import CustomerCardInfo from "../../../customer-card-info/customer-card-info";
import { ORDERS_FILTERS_SPECIALIST } from "../../../../specialist-orders.config";
import { Icon } from "../../../../../../components/image/image";
import { getLocalizedService } from "../../../../../../helpers/get-localized-service";
import routerBook from "../../../../../../router/router-book";

import "./index.scss";

const SpecialistOrderListItem = (props) => {
    const {
        _id = "",
        city = "",
        number = "",
        district = "",
        address = "",
        priceFrom = "",
        priceTo = "",
        fixedPrice = "",
        filterParameter = "",
        completionDeadline = "",
        executionTime = "",
        tasks = [],
        customer = {},
        category = {},
        subcategory = {},
        clarification = {},
    } = props;

    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleCancelOrder = () => {
        if (filterParameter === ORDERS_FILTERS_SPECIALIST.COMPLETED)
            return alert(
                "Cancel task , but not delete, request in the process..."
            );
        dispatch(
            openModal({
                isOpen: true,
                closeIcon: false,
                renderType: "DECLINE_PROPOSE",
                details: {
                    orderId: _id,
                    taskId: tasks[0]?._id,
                },
            })
        );
    };

    return (
        <div
            className={`specialist-orders__list_item${
                filterParameter === "cancelled" ? "-cancelled" : ""
            }`}
        >
            <div className="specialist-orders__list_item__header">
                <div className="specialist-orders__list_item__header_title">
                    {t("order")}: {getLocalizedService(subcategory)} ({number})
                </div>
                {filterParameter != "cancelled" &&
                filterParameter != "completed" ? (
                    <div
                        className="specialist-orders__list_item__header_btn"
                        onClick={handleCancelOrder}
                    >
                        <Icon type="deleteService" />
                    </div>
                ) : (
                    ""
                )}
            </div>
            <div className="specialist-orders__list_item__info">
                <div className="specialist-orders__list_item__info_title">
                    {t("orders.order-item.project-description")}
                </div>
                <div className="specialist-orders__list_item__info_item">
                    <div className="specialist-orders__list_item__info_item_description">
                        {t("orders.order-item.service-sector")}
                    </div>
                    <div className="specialist-orders__list_item__info_item_value">
                        {getLocalizedService(category)}
                    </div>
                </div>
                <div className="specialist-orders__list_item__info_item">
                    <div className="specialist-orders__list_item__info_item_description">
                        {t("orders.order-item.selected-service")}
                    </div>
                    <div className="specialist-orders__list_item__info_item_value">
                        {getLocalizedService(subcategory)}
                    </div>
                </div>
                <div className="specialist-orders__list_item__info_item">
                    <div className="specialist-orders__list_item__info_item_description">
                        {t("orders.order-item.service-clarification")}
                    </div>
                    <div className="specialist-orders__list_item__info_item_value">
                        {getLocalizedService(clarification)}
                    </div>
                </div>
                <div className="specialist-orders__list_item__info_title">
                    {t("orders.order-item.execution-address")}
                </div>
                <div className="specialist-orders__list_item__info_item">
                    <div className="specialist-orders__list_item__info_item_description">
                        {t("orders.order-item.city")}
                    </div>
                    <div className="specialist-orders__list_item__info_item_value">
                        {city}
                    </div>
                </div>
                <div className="specialist-orders__list_item__info_item">
                    <div className="specialist-orders__list_item__info_item_description">
                        {t("orders.order-item.district")}
                    </div>
                    <div className="specialist-orders__list_item__info_item_value">
                        {district}
                    </div>
                </div>
                <div className="specialist-orders__list_item__info_item">
                    <div className="specialist-orders__list_item__info_item_description">
                        {t("orders.order-item.address")}
                    </div>
                    <div className="specialist-orders__list_item__info_item_value">
                        {address}
                    </div>
                </div>
                <div className="specialist-orders__list_item__info_title">
                    {t("orders.order-item.execution-date")}
                </div>
                <div className="specialist-orders__list_item__info_item">
                    <div className="specialist-orders__list_item__info_item_description">
                        {t("orders.order-item.execution-date")}
                    </div>
                    <div className="specialist-orders__list_item__info_item_value">
                        {new Date(completionDeadline).toLocaleDateString()}
                    </div>
                </div>
                <div className="specialist-orders__list_item__info_item">
                    <div className="specialist-orders__list_item__info_item_description">
                        {t("orders.order-item.execution-time")}
                    </div>
                    <div className="specialist-orders__list_item__info_item_value">
                        {executionTime && executionTime / 3600000}{" "}
                        {t("locations.hour.abbreviated")}
                    </div>
                </div>
            </div>
            {filterParameter != "cancelled" ? (
                <MainButton
                    buttonSize="btn--large"
                    buttonStyle="btn--green"
                    onClick={() =>
                        navigate(
                            `${routerBook.orderInfo}/${_id}/task/${tasks[0]?._id}`,
                            { state: true }
                        )
                    }
                    inlineStyle={{ marginBottom: "0px", marginTop: "16px" }}
                >
                    {t("orders.buttons.orders-details")}
                </MainButton>
            ) : (
                ""
            )}
            <CustomerCardInfo
                {...customer}
                key={customer?._id}
                filterParameter={filterParameter}
                subcategory={subcategory}
                isOnline={customer?.isOnline}
                priceFrom={priceFrom}
                priceTo={priceTo}
                fixedPrice={fixedPrice}
                chat={tasks[0]?.chat}
                priceProposal={tasks[0]?.priceProposal}
                buttonTitle={t("chats.go_to_chat")}
            />
        </div>
    );
};

SpecialistOrderListItem.propTypes = {
    _id: PropTypes.string,
    city: PropTypes.string,
    district: PropTypes.string,
    address: PropTypes.string,
    number: PropTypes.string,
    executionTime: PropTypes.string,
    priceFrom: PropTypes.number,
    priceTo: PropTypes.number,
    fixedPrice: PropTypes.number,
    filterParameter: PropTypes.string,
    completionDeadline: PropTypes.string,
    category: PropTypes.object,
    subcategory: PropTypes.object,
    priceProposal: PropTypes.object,
    clarification: PropTypes.object,
    customer: PropTypes.object,
    tasks: PropTypes.array,
};

export default SpecialistOrderListItem;
