import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";

import { sendEmailConfirmationDataOperation } from "../auth.operations";
import { emailVerify } from "../../my-account/my-account.operations";

import ReactHelmet from "../../../components/react-helmet/react-helmet";

import emailApprove from "../../../assets/images/auth/email_approve.svg";
import "./index.scss";

const ConfirmationMessage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { token } = useParams();
    const { pathname } = useLocation();

    useEffect(() => {
        if (token && pathname === `/check-email-code/${token}`) {
            dispatch(sendEmailConfirmationDataOperation({ token }, navigate));
        }

        if (token && pathname === `/verify-email-code/${token}`) {
            dispatch(emailVerify({ token }, navigate));
        }
    }, []);

    return (
        <Fragment>
            <ReactHelmet title="email_confirm" />
            <div className="confirm_container">
                <div className="confirm_container__content">
                    <h2 className="confirm_container__title">
                        {t("email_confirm")}
                    </h2>
                    <p className="confirm_container__description">
                        {t("email_confirm_description")}
                    </p>
                    <img
                        className="confirm_container__img"
                        src={emailApprove}
                        alt="email_approve"
                    />
                </div>
            </div>
        </Fragment>
    );
};

export default ConfirmationMessage;
