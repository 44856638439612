import { string, object } from "yup";
import { t } from "i18next";
import {
    passwordValidation,
    isDigitValidation,
} from "../../../../constants/input-validation";

const regexUrl =
    /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

export const myAccountFormSchema = object().shape({
    firstName: string()
        .trim(true)
        .required("validation_required")
        .min(2, ({ min }) => t("validation_min", { min })),
    lastName: string()
        .trim(true)
        .required("validation_required")
        .min(2, ({ min }) => t("validation_min", { min })),
    socialLinks: object({
        fb: string()
            .trim()
            .nullable(true)
            .transform((value) => (value === "" ? null : value))
            .matches(regexUrl, "validation_link"),
        inst: string()
            .trim()
            .nullable(true)
            .transform((value) => (value === "" ? null : value))
            .matches(regexUrl, "validation_link"),
    }),
    phoneValue: string()
        .trim(true)
        .matches(isDigitValidation, "only digits allowed")
        .min(7)
        .max(13),
    city: string().required("validation_required"),
    district: string().trim(true).required("validation_required"),
    oldPassword: string()
        .trim()
        .nullable(true)
        .transform((value) => (value === "" ? null : value))
        .matches(passwordValidation, t("validation_password_required_symbols")),
    newPassword: string()
        .trim()
        .nullable(true)
        .transform((value) => (value === "" ? null : value))
        .matches(passwordValidation, t("validation_password_required_symbols")),
});
