import { websocketsSelector } from "./websockets.selectors";
import * as websocketsActions from "./websockets.actions";

import { socketEvents } from "./websockets.constants";
import { socket } from "./websockets.utils";

// connect
export const connectToWebsocket = () => (dispatch) => {
    dispatch(websocketsActions.openingWebsocketConnection());

    socket().on(socketEvents.SUCCESS_CONNECTION, () => {
        dispatch(websocketsActions.websocketConnected(socket()));
    });
};

// disconnect
export const disconnectedWebsocket = () => (dispatch) => {
    dispatch(websocketsActions.disconnectWebsocket());
    socket().disconnect();
};

// edit user online status
export const editUserOnlineStatus = (userId) => (dispatch, getState) => {
    const { onlineParticipants } = websocketsSelector(getState());

    const newArray = [...onlineParticipants, userId];
    if (!onlineParticipants.includes(userId)) {
        return dispatch(websocketsActions.setUserOnlineStatus(newArray));
    }
};

// edit user offline status
export const editUserOfflineStatus = (userId) => (dispatch, getState) => {
    const { onlineParticipants } = websocketsSelector(getState());

    const newArray = onlineParticipants.filter((id) => id !== userId);
    if (onlineParticipants.includes(userId)) {
        return dispatch(websocketsActions.setUserOnlineStatus(newArray));
    }
};
