import * as faqRequests from "../../services/requests/faq";
import * as faqActions from "./faq.actions";

import { sizeItemOnPage } from "./faq.constants";
import { changeGlobalLoader } from "../../components/loader/loader.actions";

export const getAllFAQ =
    (params = "") =>
    (dispatch) => {
        dispatch(faqActions.getAllFAQPending());
        dispatch(changeGlobalLoader(true));

        return faqRequests
            .getFAQ(`?limit=${sizeItemOnPage}` + params)
            .then(({ items, totalCount }) => {
                dispatch(
                    faqActions.getAllFAQFulfilled({
                        items: items,
                        count: totalCount,
                    })
                );
            })
            .catch((error) => dispatch(faqActions.getAllFAQRejected(error)))
            .finally(() => {
                dispatch(changeGlobalLoader(false));
            });
    };
