import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import { getLocalizedService } from "../../../../helpers/get-localized-service";
import { typeOfLazeImage } from "../../../../components/lazy-image/lazyImage.constansts";
import MainButton from "../../../../components/main-button/main-button";
import { showLastMessage } from "../../../../helpers/show-last-message";
import LazyImage from "../../../../components/lazy-image/lazy-image";
import { getPhotoByTypeGender } from "../../../../helpers/get-photo-by-type-gender";
import { Icon } from "../../../../components/image/image";
import routerBook from "../../../../router/router-book";

import "./index.scss";

const CustomerCardInfo = ({
    priceFrom = "",
    priceTo = "",
    subcategory = "",
    firstName = "",
    lastName = "",
    avatar = null,
    priceProposal = {},
    fixedPrice = "",
    chat = {},
    isOnline = false,
    filterParameter = "",
    gender = "",
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const getTypeOfPrice = () => {
        if (fixedPrice) return fixedPrice;
        if (priceFrom) return `${priceFrom} - ${priceTo}`;
        if (priceProposal?.price) return priceProposal?.price;
    };
    return (
        <div className="customer_card_info">
            <div className="customer_card_info__img">
                <span
                    className={`customer_card_info__img_avatar${
                        isOnline ? "-active" : ""
                    }`}
                >
                    <LazyImage
                        image={getPhotoByTypeGender(avatar, gender)}
                        type={typeOfLazeImage.avatar}
                        options={{
                            title: isOnline ? t("online") : t("offline"),
                            className: "customer_card_info__img_avatar",
                            alt: "avatar",
                        }}
                    />
                </span>
            </div>
            <div className="customer_card_info__info">
                <div className="customer_card_info__header">
                    <div className="customer_card_info__header_info">
                        <div className="customer_card_info__header_info_title">
                            {firstName} {lastName}
                        </div>
                        <div className="customer_card_info__header_info_type">
                            {getLocalizedService(subcategory)}
                        </div>
                        <div className="customer_card_info__header_info_price">
                            {getTypeOfPrice()}
                            <Icon type="currencyLira" />
                        </div>
                    </div>
                    <div className="customer_card_info__body">
                        <div className="customer_card_info__body_description">
                            {chat && chat?._id
                                ? showLastMessage(chat.lastMessage)
                                : t("chats.no_messages_yet")}
                        </div>
                    </div>
                    {filterParameter != "cancelled" && chat && chat._id ? (
                        <MainButton
                            buttonSize="btn--large"
                            buttonStyle="btn--green"
                            onClick={() =>
                                navigate(`${routerBook.chats}/${chat._id}`, {
                                    state: true,
                                })
                            }
                            inlineStyle={{
                                marginBottom: "0px",
                                marginTop: "0px",
                            }}
                        >
                            {t("chats.go_to_chat")}
                        </MainButton>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        </div>
    );
};

CustomerCardInfo.propTypes = {
    chat: PropTypes.object,
    customer: PropTypes.object,
    priceProposal: PropTypes.object,
    fixedPrice: PropTypes.number,
    chatId: PropTypes.string,
    gender: PropTypes.string,
    avatar: PropTypes.string,
    lastName: PropTypes.string,
    firstName: PropTypes.string,
    buttonTitle: PropTypes.string,
    description: PropTypes.string,
    filterParameter: PropTypes.string,
    subcategory: PropTypes.object,
    priceFrom: PropTypes.number,
    priceTo: PropTypes.number,
    isOnline: PropTypes.bool,
};

export default CustomerCardInfo;
