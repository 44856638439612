import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { memo } from "react";

import ChatsEmptyResult from "../../../chats/components/chats-empty-result/chats-empty-result";
import ActionsItem from "./components/actions-item";

import "./index.scss";

const Actions = ({ operationsHistory = [] }) => {
    const { t } = useTranslation();

    return (
        <div className="discussion_work_project_actions">
            <div className="discussion_work_project_actions_title">
                {t("chats_operations.title")}
            </div>
            <div className="discussion_work_project_actions__list">
                {operationsHistory.length > 0 ? (
                    operationsHistory.map((operation) => (
                        <ActionsItem {...operation} key={operation._id} />
                    ))
                ) : (
                    <ChatsEmptyResult />
                )}
            </div>
        </div>
    );
};

Actions.propTypes = { operationsHistory: PropTypes.array };

export default memo(Actions);
