import { useNavigate } from "react-router-dom";
import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import DOMPurify from "dompurify";

import { Icon } from "../../../../components/image/image";
import routerBook from "../../../../router/router-book";
import { getLanguage } from "../../../../helpers/language";
import { parseSocialUrl } from "../../../../helpers/social-url-parser";
import { socialMedia } from "./blog-item.config";

import "./index.scss";

const BlogItem = ({ image, title, text, category, _id, updatedAt }) => {
    const { value: language } = getLanguage();

    const navigate = useNavigate();

    const sanitizedTitle = () => {
        if (title) {
            return {
                __html: DOMPurify.sanitize(title[language]),
            };
        }
    };

    const sanitizedText = () => {
        if (title) {
            return {
                __html: DOMPurify.sanitize(text[language]),
            };
        }
    };

    return (
        <div
            className="blog__container-right-content-item"
            onClick={() =>
                navigate(`${routerBook.academy}/${_id}`, {
                    state: true,
                })
            }
        >
            <img
                className="blog__container-right-content-item-image"
                src={image}
            />
            <div className="blog__container-right-content-item-content">
                <div className="blog__container-right-content-item-content-category">
                    {category.titleTranslations[language]}
                </div>
                <div
                    className="blog__container-right-content-item-content-title"
                    dangerouslySetInnerHTML={sanitizedTitle()}
                ></div>
                <div
                    className="blog__container-right-content-item-content-description"
                    dangerouslySetInnerHTML={sanitizedText()}
                ></div>
                <div className="blog__container-right-content-item-content-date">
                    <span className="blog__container-right-content-item-content-date-item">
                        {dayjs(updatedAt).format("MMMM")}{" "}
                        {dayjs(updatedAt).format("D")},{" "}
                        {dayjs(updatedAt).format("YYYY")}
                    </span>
                </div>
                <div className="blog__container-right-content-item-content-footer">
                    {Object.values(socialMedia).map(({ name, href }) => (
                        <a
                            className="blog__container-right-content-item-content-footer-item"
                            href={`${href}${parseSocialUrl(
                                location.href
                            )}/${_id}`}
                            rel="noreferrer"
                            target="_blank"
                            key={name}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <Icon type={name} />
                        </a>
                    ))}
                </div>
            </div>
        </div>
    );
};

BlogItem.propTypes = {
    text: PropTypes.object,
    title: PropTypes.object,
    image: PropTypes.string,
    category: PropTypes.object,
    _id: PropTypes.string,
    updatedAt: PropTypes.string,
};

export default BlogItem;
