import { favouritesActionTypes } from "./favourites.types";
import { sizeItemOnPage } from "./favourites.constants";

const initialQueryParams = {
    limit: sizeItemOnPage,
    page: 0,
};

const initialState = {
    favouritesList: {
        items: [],
        count: 0,
    },
    params: initialQueryParams,
    loading: false,
    error: "",
};

export const favourites = (state = initialState, { type, payload }) => {
    switch (type) {
        // clear all params
        case favouritesActionTypes.CLEAR_ALL_PARAMS:
            return { ...state, params: initialQueryParams };

        // set page
        case favouritesActionTypes.SET_PAGE:
            return {
                ...state,
                params: { ...state.params, page: payload },
            };

        case favouritesActionTypes.GET_CLIENT_FAVOURITES_REQUEST:
        case favouritesActionTypes.GET_SPECIALIST_FAVOURITES_REQUEST: {
            return { ...state, loading: true };
        }

        case favouritesActionTypes.GET_CLIENT_FAVOURITES_SUCCESS:
        case favouritesActionTypes.GET_SPECIALIST_FAVOURITES_SUCCESS: {
            return {
                ...state,
                loading: false,
                favouritesList: {
                    ...payload,
                    count: payload.totalCount,
                },
            };
        }

        case favouritesActionTypes.GET_CLIENT_FAVOURITES_ERROR:
        case favouritesActionTypes.GET_SPECIALIST_FAVOURITES_ERROR: {
            return { ...state, loading: false, error: payload };
        }

        default:
            return state;
    }
};
