import PropTypes from "prop-types";
import dayjs from "dayjs";

import { replaceValue } from "../../../../../helpers/replace-values";

import "./index.scss";

const ActionsItem = ({ createdAt = "", text = {} }) => {
    return (
        <div className="discussion_work_project_actions__list_item">
            <div className="discussion_work_project_actions__list_item_date">
                {dayjs(createdAt).format("DD.MM.YYYY")}
            </div>
            <div className="discussion_work_project_actions__list_item_title">
                {replaceValue({ isBold: true, value: text })}
            </div>
        </div>
    );
};

ActionsItem.propTypes = { createdAt: PropTypes.string, text: PropTypes.object };

export default ActionsItem;
