import { balanceTypes, userBalanceTypes } from "./balance.types";

// get balance
export const getBalancePending = () => ({
    type: balanceTypes.GET_BALANCE_PENDING,
});

export const getBalanceFulfilled = (data) => ({
    type: balanceTypes.GET_BALANCE_FULFILLED,
    payload: data,
});

export const getBalanceRejected = (error) => ({
    type: balanceTypes.GET_BALANCE_REJECTED,
    payload: error,
});

// get transactions
export const getTransactionsPending = () => ({
    type: balanceTypes.GET_TRANSACTIONS_PENDING,
});

export const getTransactionsFulfilled = (data) => ({
    type: balanceTypes.GET_TRANSACTIONS_FULFILLED,
    payload: data,
});

export const getTransactionsRejected = (error) => ({
    type: balanceTypes.GET_TRANSACTIONS_REJECTED,
    payload: error,
});

// refill balance
export const refillBalancePending = () => ({
    type: balanceTypes.REFILL_BALANCE_PENDING,
});

export const refillBalanceFulfilled = (data) => ({
    type: balanceTypes.REFILL_BALANCE_FULFILLED,
    payload: data,
});

export const refillBalanceRejected = (error) => ({
    type: balanceTypes.REFILL_BALANCE_REJECTED,
    payload: error,
});

// payout balance
export const payoutBalancePending = () => ({
    type: balanceTypes.PAYOUT_BALANCE_PENDING,
});

export const payoutBalanceFulfilled = (data) => ({
    type: balanceTypes.PAYOUT_BALANCE_FULFILLED,
    payload: data,
});

export const payoutBalanceRejected = (error) => ({
    type: balanceTypes.PAYOUT_BALANCE_REJECTED,
    payload: error,
});

// create stripe
export const createStripePending = () => ({
    type: balanceTypes.CREATE_STRIPE_PENDING,
});

export const createStripeFulfilled = (data) => ({
    type: balanceTypes.CREATE_STRIPE_FULFILLED,
    payload: data,
});

export const createStripeRejected = (error) => ({
    type: balanceTypes.CREATE_STRIPE_REJECTED,
    payload: error,
});

// create iyzico
export const createIyzicoPending = () => ({
    type: balanceTypes.CREATE_IYZICO_PENDING,
});

export const createIyzicoFulfilled = (data) => ({
    type: balanceTypes.CREATE_IYZICO_FULFILLED,
    payload: data,
});

export const createIyzicoRejected = (error) => ({
    type: balanceTypes.CREATE_IYZICO_REJECTED,
    payload: error,
});

// clear balance
export const clearBalance = () => ({
    type: balanceTypes.CLEAR_BALANCE,
});

// ***************** new balance actions ******************
// get user balance
export const getUserBalanceRequest = () => ({
    type: userBalanceTypes.GET_USER_BALANCE_REQUEST,
});

export const getUserBalanceSuccess = (data) => ({
    type: userBalanceTypes.GET_USER_BALANCE_SUCCESS,
    payload: data,
});

export const getUserBalanceError = (error) => ({
    type: userBalanceTypes.GET_USER_BALANCE_ERROR,
    payload: error,
});

// get user balance details
export const getUserBalanceDetailsRequest = () => ({
    type: userBalanceTypes.GET_USER_BALANCE_DETAILS_REQUEST,
});

export const getUserBalanceDetailsSuccess = (data) => ({
    type: userBalanceTypes.GET_USER_BALANCE_DETAILS_SUCCESS,
    payload: data,
});

export const getUserBalanceDetailsError = (error) => ({
    type: userBalanceTypes.GET_USER_BALANCE_DETAILS_ERROR,
    payload: error,
});

// get user balance full details
export const getUserBalanceFullDetailsRequest = () => ({
    type: userBalanceTypes.GET_USER_BALANCE_FULL_DETAILS_REQUEST,
});

export const getUserBalanceFullDetailsSuccess = (data) => ({
    type: userBalanceTypes.GET_USER_BALANCE_FULL_DETAILS_SUCCESS,
    payload: data,
});

export const getUserBalanceFullDetailsError = (error) => ({
    type: userBalanceTypes.GET_USER_BALANCE_FULL_DETAILS_ERROR,
    payload: error,
});

// get user balance history
export const getUserBalanceHistoryRequest = () => ({
    type: userBalanceTypes.GET_USER_BALANCE_HISTORY_REQUEST,
});

export const getUserBalanceHistorySuccess = (data) => ({
    type: userBalanceTypes.GET_USER_BALANCE_HISTORY_SUCCESS,
    payload: data,
});

export const getUserBalanceHistoryError = (error) => ({
    type: userBalanceTypes.GET_USER_BALANCE_HISTORY_ERROR,
    payload: error,
});

// initialize session pay
export const initializeSessionPayRequest = () => ({
    type: userBalanceTypes.INITIALIZE_SESSION_PAY_REQUEST,
});

export const initializeSessionPaySuccess = (data) => ({
    type: userBalanceTypes.INITIALIZE_SESSION_PAY_SUCCESS,
    payload: data,
});

export const initializeSessionPayError = (error) => ({
    type: userBalanceTypes.INITIALIZE_SESSION_PAY_ERROR,
    payload: error,
});

// check session pay
export const checkSessionPayRequest = () => ({
    type: userBalanceTypes.CHECK_SESSION_PAY_REQUEST,
});

export const checkSessionPaySuccess = (data) => ({
    type: userBalanceTypes.CHECK_SESSION_PAY_SUCCESS,
    payload: data,
});

export const checkSessionPayError = (error) => ({
    type: userBalanceTypes.CHECK_SESSION_PAY_ERROR,
    payload: error,
});

// check withdrawal money
export const withdrawalMoneyRequest = () => ({
    type: userBalanceTypes.WITHDRAWAL_MONEY_REQUEST,
});

export const withdrawalMoneySuccess = (data) => ({
    type: userBalanceTypes.WITHDRAWAL_MONEY_SUCCESS,
    payload: data,
});

export const withdrawalMoneyError = (error) => ({
    type: userBalanceTypes.WITHDRAWAL_MONEY_ERROR,
    payload: error,
});

// print invoice
export const printInvoiceRequest = () => ({
    type: userBalanceTypes.PRINT_INVOICE_REQUEST,
});

export const printInvoiceSuccess = (data) => ({
    type: userBalanceTypes.PRINT_INVOICE_SUCCESS,
    payload: data,
});

export const printInvoiceError = (error) => ({
    type: userBalanceTypes.PRINT_INVOICE_ERROR,
    payload: error,
});

// print invoice list
export const printInvoiceListRequest = () => ({
    type: userBalanceTypes.PRINT_INVOICE_LIST_REQUEST,
});

export const printInvoiceListSuccess = (data) => ({
    type: userBalanceTypes.PRINT_INVOICE_LIST_SUCCESS,
    payload: data,
});

export const printInvoiceListError = (error) => ({
    type: userBalanceTypes.PRINT_INVOICE_LIST_ERROR,
    payload: error,
});
