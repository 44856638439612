import * as api from "../../services/requests/feedback";

import { successToastify } from "../../components/toastify/toastify.operations";
import { specialistListSelector } from "../specialists-list/specialist-list.selectors";
import { changeButtonLoader } from "../../components/loader/loader.actions";
import { getSpecialistProfileByIDFulfilled } from "../specialists-list/specialist-list.actions";
import { closeModal } from "../../components/modal/modal.actions";
import { getAllOrdersFulfilled } from "../orders/oders.actions";
import { ordersSelector } from "../orders/orders.selectors";
import * as feedbackActions from "./feedback.actions";

import { isExistFeedback } from "./components/feedback-form/feedback-form.utils";
import {
    updateOrdersAfterFeedback,
    updateSpecialistAfterChangedFeedback,
    updateSpecialistAfterFeedback,
} from "./feedback.utils";

export const getFeedbacks = (specialistId, taskId) => (dispatch) => {
    dispatch(feedbackActions.getFeedbackRequest());

    return api
        .getAllUserFeedbacksRequest(specialistId)
        .then((data) => {
            dispatch(feedbackActions.getFeedbackSucces(data));
            const exist = isExistFeedback(data, taskId);
            if (exist) dispatch(feedbackActions.createFeedbackSucces(exist));
        })
        .catch((error) => dispatch(feedbackActions.getFeedbackError(error)));
};

export const createFeedback =
    (taskId, orderId, reqBody) => (dispatch, getState) => {
        dispatch(changeButtonLoader({ status: true, button: "" }));
        dispatch(feedbackActions.createFeedbackRequest());

        const { selectedSpecialist } = specialistListSelector(getState());
        const { orders } = ordersSelector(getState());

        return api
            .createFeedbackRequest(taskId, reqBody)
            .then(() => {
                if (orders.count > 0) {
                    dispatch(
                        getAllOrdersFulfilled(
                            updateOrdersAfterFeedback(
                                orders,
                                orderId,
                                taskId,
                                reqBody
                            )
                        )
                    );
                }
                dispatch(
                    getSpecialistProfileByIDFulfilled(
                        updateSpecialistAfterFeedback(
                            selectedSpecialist,
                            reqBody
                        )
                    )
                );
                dispatch(feedbackActions.createFeedbackSucces(reqBody));
                dispatch(successToastify("toastify.feedback.success"));
            })
            .catch((error) =>
                dispatch(feedbackActions.createFeedbackError(error))
            )
            .finally(() => {
                dispatch(changeButtonLoader({ status: false, button: "" }));
                dispatch(closeModal());
            });
    };

export const updateFeedback =
    (feedbackBody, userFeedbacks, reqBody) => (dispatch, getState) => {
        dispatch(changeButtonLoader({ status: true, button: "" }));
        dispatch(feedbackActions.updateFeedbackRequest());

        const { selectedSpecialist } = specialistListSelector(getState());

        return api
            .updateFeedbackRequest(feedbackBody._id, reqBody)
            .then(() => {
                dispatch(
                    getSpecialistProfileByIDFulfilled(
                        updateSpecialistAfterChangedFeedback(
                            selectedSpecialist,
                            userFeedbacks,
                            feedbackBody,
                            reqBody
                        )
                    )
                );
                dispatch(successToastify("toastify.feedback.updated"));
            })
            .catch((error) => {
                dispatch(feedbackActions.updateFeedbackError(error));
            })
            .finally(() => {
                dispatch(changeButtonLoader({ status: false, button: "" }));
                dispatch(closeModal());
            });
    };
