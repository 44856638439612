import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { restoreAccount } from "../../../auth/auth.operations";
import { authSelector } from "../../../auth/auth.selectors";
import { phoneVerify } from "../../my-account.operations";
import { buttonLoaderSelector } from "../../../../components/loader/loader.selectors";
import ButtonLoader from "../../../../components/loader/components/button-loader/button-loader";
import Input from "../../../../components/input/input";
import {
    openModal,
    closeModal,
} from "../../../../components/modal/modal.actions";
import { modalConstants } from "../../../../components/modal/modal.constants";
import routerBook from "../../../../router/router-book";

import "./index.scss";

const PhoneVerifyForm = ({ details }) => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const { status: buttonLoading } = useSelector(buttonLoaderSelector);
    const {
        restoreAccount: { phone },
    } = useSelector(authSelector);

    const { handleSubmit, formState, register } = useForm();

    const phoneNumberFromResponse = details.message.replace(/\D/g, "");

    const handlePhoneConfirm = (data) => {
        if (phone) {
            dispatch(
                restoreAccount({
                    phone,
                    codePhone: data.phoneVerify,
                })
            );
            dispatch(closeModal());
            navigate(routerBook.auth.login, { state: true });
        } else {
            dispatch(
                phoneVerify({
                    code: data.phoneVerify,
                })
            );
        }
    };

    const handleOpenPhoneCodeModal = () => {
        dispatch(
            openModal({
                isOpen: true,
                closeIcon: false,
                renderType: modalConstants.authPhoneCode,
                details: { phoneNumber: phoneNumberFromResponse },
            })
        );
    };

    return (
        <div className="phone-verify">
            <h2 className="phone-verify__title">{t("phone_verify_title")}</h2>
            <p className="phone-verify__description">
                <span>{t("phone_verify_subtitle_first")}</span>
                <span> +{phoneNumberFromResponse}. </span>
                <span>{t("phone_verify_subtitle_second")}</span>
            </p>
            {details.test && (
                <p className="phone-verify__description">
                    Verification {details.test}
                </p>
            )}
            <form onSubmit={handleSubmit(handlePhoneConfirm)}>
                <div className="phone-verify__form">
                    <Input
                        label="phone_verify_code"
                        type="secondary"
                        error={formState?.errors.phoneVerify?.message}
                        options={{
                            placeholderText: t("phone_verify_code"),
                        }}
                        inputChangeOptions={register("phoneVerify")}
                    />
                    <div className="phone-verify__question-tip">
                        <span onClick={handleOpenPhoneCodeModal}>
                            {t("phone_verify_question")}
                        </span>
                    </div>
                    <button
                        disabled={buttonLoading}
                        type="submit"
                        className="phone-verify__btn"
                        style={{
                            background: buttonLoading && "#dfdfdf",
                        }}
                    >
                        {buttonLoading ? (
                            <ButtonLoader size={38} />
                        ) : (
                            t("phone_verify_button")
                        )}
                    </button>
                </div>
            </form>
        </div>
    );
};

PhoneVerifyForm.propTypes = {
    details: PropTypes.shape({
        test: PropTypes.string,
        message: PropTypes.string,
    }),
};

export default PhoneVerifyForm;
