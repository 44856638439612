import { allResultTypes } from "./all-result.types";

// get orders
export const getOrdersResultPending = () => ({
    type: allResultTypes.GET_ORDERS_RESULT_PENDING,
});

export const getOrdersResultFulfilled = (data) => ({
    type: allResultTypes.GET_ORDERS_RESULT_FULFILLED,
    payload: data,
});

export const getOrdersResultRejected = (error) => ({
    type: allResultTypes.GET_ORDERS_RESULT_REJECTED,
    payload: error,
});

// get services
export const getServicesResultPending = () => ({
    type: allResultTypes.GET_SERVICES_RESULT_PENDING,
});

export const getServicesResultFulfilled = (data) => ({
    type: allResultTypes.GET_SERVICES_RESULT_FULFILLED,
    payload: data,
});

export const getServicesResultRejected = (error) => ({
    type: allResultTypes.GET_SERVICES_RESULT_REJECTED,
    payload: error,
});

// set
export const clearAllParams = () => ({
    type: allResultTypes.CLEAR_ALL_PARAMS,
});

export const clearAllTags = () => ({
    type: allResultTypes.CLEAR_ALL_TAGS,
});

export const setPage = (data) => ({
    type: allResultTypes.SET_PAGE,
    payload: data,
});

export const setCategory = (data) => ({
    type: allResultTypes.SET_CATEGORY,
    payload: data,
});

export const setSubcategory = (data) => ({
    type: allResultTypes.SET_SUBCATEGORY,
    payload: data,
});

export const setClarification = (data) => ({
    type: allResultTypes.SET_CLARIFICATION,
    payload: data,
});

export const setLabel = (data) => ({
    type: allResultTypes.SET_LABEL,
    payload: data,
});

export const setCity = (data) => ({
    type: allResultTypes.SET_CITY,
    payload: data,
});
export const setDistrict = (data) => ({
    type: allResultTypes.SET_DISTRICT,
    payload: data,
});

export const setSearchQuery = (data) => ({
    type: allResultTypes.SET_SEARCH_QUERY,
    payload: data,
});
