import { genderTypes } from "../constants/genders";

export const adaptGender = (gen) => {
    switch (gen) {
        case genderTypes.male: {
            return { label: "men", value: genderTypes.male };
        }
        case genderTypes.female: {
            return { label: "women", value: genderTypes.female };
        }
        case genderTypes.preferNotToTell: {
            return {
                label: "prefer_not_to_tell",
                value: genderTypes.preferNotToTell,
            };
        }
        default:
            return;
    }
};
