import { specialistTypes } from "./specialist-list.types";

// get specialist list
export const getSpecialistListPending = () => ({
    type: specialistTypes.GET_SPECIALIST_LIST_PENDING,
});

export const getSpecialistListFulfilled = (data) => ({
    type: specialistTypes.GET_SPECIALIST_LIST_FULFILLED,
    payload: data,
});

export const getSpecialistListRejected = (error) => ({
    type: specialistTypes.GET_SPECIALIST_LIST_REJECTED,
    payload: error,
});

// get specialist profile by id
export const getSpecialistProfileByIDFulfilled = (data) => ({
    type: specialistTypes.GET_SPECIALIST_PROFILE_BY_ID_FULFILLED,
    payload: data,
});

export const getSpecialistProfileByIDRejected = (error) => ({
    type: specialistTypes.GET_SPECIALIST_PROFILE_BY_ID_REJECTED,
    payload: error,
});

export const specialistIsNotDefined = () => ({
    type: specialistTypes.SPECIALIST_IS_NOT_DEFINED,
});

// set
export const clearSelectedSpecialist = () => ({
    type: specialistTypes.CLEAR_SELECTED_SPECIALIST,
});

export const clearAllParams = () => ({
    type: specialistTypes.CLEAR_ALL_PARAMS,
});

export const setPage = (data) => ({
    type: specialistTypes.SET_PAGE,
    payload: data,
});
