export const promotionConstants = {
    condition: "condition",
    history: "history",
    acting: "acting",
};

export const promotionTypesContants = {
    BEAUTY_NAME: "BEAUTY_NAME",
    TOP: "TOP",
    TOP_CATEGORY: "TOP_CATEGORY",
};

export const customerPromotionTypesConstants = {
    BUY_CONTACT: "BUY_CONTACT",
};

export const sizeItemOnPage = 3;
export const sizeActivePromotionItemOnPage = 3;
export const sizeHistoryItemOnPage = 7;
