import { useTranslation } from "react-i18next";
import React, { memo } from "react";
import PropTypes from "prop-types";

import SuggestionsItem from "./components/suggestions-item/suggestions-item";

import "./index.scss";

const Suggestions = ({ orderTaskOtherSpecialist = [] }) => {
    const { t } = useTranslation();

    return (
        <div className="discussion_work_project_suggestions">
            <h3 className="discussion_work_project_suggestions_title">
                {t("chats_suggestions.title")}
            </h3>
            <div className="discussion_work_project_suggestions_list">
                {orderTaskOtherSpecialist.map((task) => (
                    <SuggestionsItem {...task} key={task.chat.taskId} />
                ))}
            </div>
        </div>
    );
};

Suggestions.propTypes = {
    orderTaskOtherSpecialist: PropTypes.array,
};

export default memo(Suggestions);
