import { batch } from "react-redux";

import * as servicesRequests from "../../services/requests/service";
import * as api from "../../services/requests/order";

import { successToastify } from "../../components/toastify/toastify.operations";
import { closeModal, openModal } from "../../components/modal/modal.actions";
import { ordersSelector, ordersStatusSelector } from "./orders.selectors";
import { disableChatFulfilled } from "../chats/chats.actions";
import { chatsSelector } from "../chats/chats.selectors";
import * as orderActions from "./oders.actions";
import {
    changeButtonLoader,
    changeGlobalLoader,
} from "../../components/loader/loader.actions";
import {
    getClarificationFulfilled,
    getServiceFulfilled,
    getSubserviceFulfilled,
} from "../all-services/all-services.actions";

import { failureTaskStatus } from "../discussion-work-project/components/chat/chat.config";
import { modalConstants } from "../../components/modal/modal.constants";
import { paymentTypes } from "../balance/balance.constants";
import routerBook from "../../router/router-book";

// get
export const getAllOrders =
    (loading = true, sizeItemOnPage, params = "") =>
    (dispatch, getState) => {
        const status = ordersStatusSelector(getState());
        dispatch(orderActions.getAllOrdersPending());
        loading && dispatch(changeGlobalLoader(true));

        return api
            .getOrders(`?limit=${sizeItemOnPage}` + status + params)
            .then(({ items, totalCount: count }) => {
                dispatch(orderActions.getAllOrdersFulfilled({ items, count }));
            })
            .catch((error) =>
                dispatch(orderActions.getAllOrdersRejected(error))
            )
            .finally(() => loading && dispatch(changeGlobalLoader(false)));
    };

export const getAllOrdersForFavourites =
    (id, sizeItemOnPage, params = "") =>
    (dispatch) => {
        dispatch(orderActions.getAllOrdersForFavouritesPending());

        return api
            .getOrdersForFavourites(id, `?page=0&limit=${sizeItemOnPage}` + params)
            .then(({ items, totalCount: count }) => {
                dispatch(
                    orderActions.getAllOrdersForFavouritesFulfilled({
                        items,
                        count,
                    })
                );
            })
            .catch((error) =>
                dispatch(orderActions.getAllOrdersForFavouritesRejected(error))
            );
    };

export const getOrder = (id) => (dispatch) => {
    dispatch(orderActions.getOrderPending());
    dispatch(changeGlobalLoader(true));

    return api
        .getOrder(id)
        .then(async (response) => {
            const { items: servicesItems } =
                await servicesRequests.getServices();
            const { items: subservicesItems } =
                await servicesRequests.getSubservices(response.categoryId);
            const { items: clarificationsItems } =
                await servicesRequests.getClarifications(
                    response.subcategoryId
                );

            batch(() => {
                dispatch(getServiceFulfilled(servicesItems));
                dispatch(getSubserviceFulfilled(subservicesItems));
                dispatch(getClarificationFulfilled(clarificationsItems));
            });

            dispatch(orderActions.getOrderFulfilled(response));
        })
        .catch((error) => {
            dispatch(orderActions.getOrderRejected(error));
            dispatch(closeModal());
        })
        .finally(() => dispatch(changeGlobalLoader(false)));
};

export const getOrderTask = (orderId, taskId) => (dispatch) => {
    dispatch(orderActions.getOrderTaskPending());
    dispatch(changeGlobalLoader(true));

    return api
        .getOrderTask(orderId, taskId)
        .then((response) => {
            dispatch(orderActions.getOrderTaskFulfilled(response));
        })
        .catch((error) => {
            dispatch(orderActions.getOrderTaskRejected(error));
            dispatch(closeModal());
        })
        .finally(() => dispatch(changeGlobalLoader(false)));
};

export const getOrderTaskOtherSpecialist = (orderId, taskId) => (dispatch) => {
    dispatch(orderActions.getOrderTaskOtherSpecialistPending());
    dispatch(changeGlobalLoader(true));

    return api
        .getOrderTaskOtherSpecialist(orderId, taskId)
        .then(({ otherSpecialists }) => {
            dispatch(
                orderActions.getOrderTaskOtherSpecialistFulfilled(
                    otherSpecialists
                )
            );
        })
        .catch((error) => {
            dispatch(orderActions.getOrderTaskOtherSpecialistRejected(error));
            dispatch(closeModal());
        })
        .finally(() => dispatch(changeGlobalLoader(false)));
};

export const getOrderResponders =
    (orderId, sizeItemOnPage, params = "") =>
    (dispatch) => {
        dispatch(orderActions.getOrderRespondersPending());
        dispatch(changeGlobalLoader(true));

        return api
            .getOrderResponders(orderId, `?limit=${sizeItemOnPage}` + params)
            .then(({ items, totalCount: count, orderName }) => {
                dispatch(
                    orderActions.getOrderRespondersFulfilled({
                        items,
                        count,
                        orderName,
                    })
                );
            })
            .catch((error) =>
                dispatch(orderActions.getOrderRespondersRejected(error))
            )
            .finally(() => dispatch(changeGlobalLoader(false)));
    };

// post
export const postOrder =
    (data, image, navigate, receiverId) => (dispatch, getState) => {
        dispatch(changeButtonLoader({ status: true, button: "" }));
        dispatch(orderActions.postOrderPending());

        const { orders } = ordersSelector(getState());
        const { items, count } = orders;

        let updatedData = {
            ...data,
            executionTime: data.executionTime * 3600000,
            categoryId: data.categoryId.value,
            clarificationId: data.clarificationId.value,
            subcategoryId: data.subcategoryId.value,
        };

        if (data.preferredGender) {
            updatedData = {
                ...updatedData,
                preferredGender: data.preferredGender.value,
            };
        }

        if (data.preferredYears.from) {
            updatedData = {
                ...updatedData,
                preferredYears: { from: data.preferredYears.from },
            };
        }

        if (data.preferredYears.to) {
            updatedData = {
                ...updatedData,
                preferredYears: {
                    to: data.preferredYears.to,
                    from: data.preferredYears.from,
                },
            };
        }

        delete data.preferredYears.from;
        delete data.preferredYears.to;

        return api
            .postOrder(updatedData)
            .then((item) => {
                const newOrders = { items: [...items, item], count: count + 1 };

                if (image && image.length) {
                    dispatch(orderActions.postOrderImagePending());

                    let formData = new FormData();
                    for (let i = 0; i < image.length; i++) {
                        formData.append("images", image[i]);
                    }

                    api.postOrderImage(item._id, formData)
                        .then((images) => {
                            const newOrder = { ...item, images };
                            const newItems = [...items, newOrder];
                            const newCount = count + 1;
                            const orders = { items: newItems, count: newCount };
                            dispatch(orderActions.postOrderFulfilled(orders));
                        })
                        .catch((error) =>
                            dispatch(orderActions.postOrderImageRejected(error))
                        );
                } else dispatch(orderActions.postOrderFulfilled(newOrders));

                dispatch(successToastify("toastify.order.added"));
                if (receiverId) {
                    dispatch(
                        openModal({
                            isOpen: true,
                            closeIcon: true,
                            renderType: modalConstants.selectCustomerOrder,
                            details: receiverId,
                        })
                    );
                } else navigate(routerBook.orders, { state: true });
            })
            .catch((error) => dispatch(orderActions.postOrderRejected(error)))
            .finally(() =>
                dispatch(changeButtonLoader({ status: false, button: "" }))
            );
    };

// post
export const postOrderImage = (id, image) => (dispatch, getState) => {
    dispatch(orderActions.postOrderImagePending());

    const { order, orders } = ordersSelector(getState());
    const { items, count } = orders;

    let formData = new FormData();
    for (let i = 0; i < image.length; i++) {
        formData.append("images", image[i]);
    }

    return api
        .postOrderImage(id, formData)
        .then((images) => {
            const newItem = { ...order, images };
            const newItems = items.map((body) =>
                body._id === id ? newItem : body
            );
            const newOrders = { items: newItems, count };
            dispatch(orderActions.postOrderImageFulfilled(newOrders));
            dispatch(orderActions.getOrderFulfilled(newItem));
            dispatch(successToastify("toastify.order.uploaded_photo"));
        })
        .catch((error) => {
            dispatch(orderActions.postOrderImageRejected(error));
        });
};

// put
export const putOrder = (data, id) => (dispatch, getState) => {
    dispatch(changeButtonLoader({ status: true, button: "update-order" }));
    dispatch(orderActions.updateOrderPending());

    const {
        orders: { items, count },
        order: { images },
    } = ordersSelector(getState());

    return api
        .putOrder(data, id)
        .then((response) => {
            const newItem = { ...response, images };
            const newItems = items.map((item) =>
                item._id === id ? { ...newItem, tasks: item.tasks } : item
            );
            const newOrders = { items: newItems, count };
            dispatch(orderActions.updateOrderFulfilled(newOrders));
            dispatch(closeModal());
            dispatch(successToastify("toastify.order.updated"));
        })
        .catch((error) => {
            dispatch(orderActions.updateOrderRejected(error));
            dispatch(closeModal());
        })
        .finally(() =>
            dispatch(changeButtonLoader({ status: false, button: "" }))
        );
};

// put task
export const putTaskStatus =
    (orderID, taskID, status) => (dispatch, getState) => {
        dispatch(changeButtonLoader({ status: true, button: status }));
        dispatch(orderActions.putTaskStatusPending());
        const { chat } = chatsSelector(getState());

        return api
            .putTaskStatus(orderID, taskID, status)
            .then((response) => {
                dispatch(orderActions.getOrderTaskFulfilled(response));
                if (response.status === failureTaskStatus.cancelled)
                    dispatch(successToastify("toastify.order.cancelled"));
                if (response.confirmations.confirmedByCustomerAsCompleted) {
                    const updateChat = { ...chat, isDisabled: true };
                    dispatch(disableChatFulfilled(updateChat));
                }
            })
            .catch(({ error }) => {
                dispatch(orderActions.putTaskStatusRejected(error));
            })
            .finally(() => {
                dispatch(changeButtonLoader({ status: false, button: "" }));
            });
    };

export const putTaskFixPrice = (orderId, taskId, data) => (dispatch) => {
    dispatch(changeButtonLoader({ status: true, button: data.eventType }));
    dispatch(orderActions.putTaskStatusPending());

    return api
        .putTaskFixPrice(orderId, taskId, data)
        .then((response) =>
            dispatch(orderActions.getOrderTaskFulfilled(response))
        )
        .catch(({ error }) =>
            dispatch(orderActions.putTaskStatusRejected(error))
        )
        .finally(() =>
            dispatch(changeButtonLoader({ status: false, button: "" }))
        );
};

// delete
export const deleteOrder = (id) => (dispatch, getState) => {
    dispatch(changeButtonLoader({ status: true, button: "" }));
    dispatch(orderActions.removeOrderPending());

    const {
        orders: { items, count },
    } = ordersSelector(getState());

    return api
        .deleteOrder(id)
        .then(() => {
            const newItems = items.filter(({ _id }) => _id !== id);
            const newOrders = { items: newItems, count: count - 1 };
            dispatch(orderActions.removeOrderFulfilled(newOrders));
            dispatch(closeModal());
            dispatch(successToastify("toastify.order.deleted"));
        })
        .catch(({ error }) => {
            dispatch(orderActions.removeOrderRejected(error));
            dispatch(closeModal());
        })
        .finally(() =>
            dispatch(changeButtonLoader({ status: false, button: "" }))
        );
};

// cancel
export const cancelOrder = (orderID, taskID) => (dispatch, getState) => {
    dispatch(changeButtonLoader({ status: true, button: "" }));
    dispatch(orderActions.removeOrderPending());

    const {
        orders: { items, count },
    } = ordersSelector(getState());

    return api
        .cancelOrder(orderID, taskID)
        .then(() => {
            const newItems = items.filter(({ _id }) => _id !== orderID);
            const newOrders = { items: newItems, count: count - 1 };
            dispatch(orderActions.removeOrderFulfilled(newOrders));
            dispatch(closeModal());
            dispatch(successToastify("toastify.order.cancelled"));
        })
        .catch(({ error }) => {
            dispatch(orderActions.removeOrderRejected(error));
            dispatch(closeModal());
        })
        .finally(() =>
            dispatch(changeButtonLoader({ status: false, button: "" }))
        );
};

export const deleteOrderImage = (id, imageId) => (dispatch, getState) => {
    dispatch(orderActions.removeOrderImagePending());

    const { order, orders } = ordersSelector(getState());
    const { items, count } = orders;

    return api
        .deleteOrderImage(id, imageId)
        .then(() => {
            const { images } = order;
            const newOrderImages = images.filter(({ _id }) => _id !== imageId);
            const newOrder = { ...order, images: newOrderImages };
            const newOrders = items.map((item) =>
                item._id === id ? newOrder : item
            );
            const updateOrders = { items: newOrders, count };

            dispatch(orderActions.removeOrderImageFulfilled(updateOrders));
            dispatch(orderActions.getOrderFulfilled(newOrder));
            dispatch(successToastify("toastify.order.deleted_photo"));
        })
        .catch((error) =>
            dispatch(orderActions.removeOrderImageRejected(error))
        );
};

// reserve funds
export const reserveFunds = (orderId, taskId, source) => (dispatch) => {
    dispatch(changeButtonLoader({ status: true, button: "confirm" }));
    dispatch(orderActions.reserveFundsPending());

    const { stripe, iyzico } = paymentTypes;

    const actionAfterPaid = ({ sessionUrl, message, checkoutUrl }) => {
        if (source === iyzico.value) window.open(checkoutUrl, "_self");
        if (source === stripe.value) window.open(sessionUrl, "_self");
        if (source === "balance") dispatch(successToastify(message));
        dispatch(closeModal());
    };

    let newData = { callbackUrl: location.href, source: "payment" };
    if (source === iyzico.value) newData = { ...newData, system: "iyizico" };
    if (source === stripe.value) newData = { ...newData, system: "stripe" };
    if (source === "balance") newData = { ...newData, source: "balance" };

    return api
        .reserveFundsRequest(orderId, taskId, newData)
        .then((response) => actionAfterPaid(response))
        .catch((error) => dispatch(orderActions.reserveFundsRejected(error)))
        .finally(() => {
            dispatch(changeButtonLoader({ status: false, button: "" }));
        });
};

// set
export const editPageAll = (size, data) => (dispatch) => {
    dispatch(orderActions.setPage(`&page=${data}`));
    const url = `&page=${data}`;
    dispatch(getAllOrders(true, size, url));
};

export const editPageResponders = (orderId, size, data) => (dispatch) => {
    dispatch(orderActions.setPage(`&page=${data}`));
    const url = `&page=${data}`;
    dispatch(getOrderResponders(true, orderId, size, url));
};

export const editStatusAll =
    (loading = true, size, data) =>
    (dispatch) => {
        dispatch(orderActions.setStatus(`&statuses=${data}`));
        dispatch(getAllOrders(loading, size));
    };
