import { feedbackActionTypes } from "./feedback.types";

// creating
export const createFeedbackRequest = () => ({
    type: feedbackActionTypes.CREATE_FEEDBACK_REQUEST,
});
export const createFeedbackSucces = (data) => ({
    type: feedbackActionTypes.CREATE_FEEDBACK_SUCCESS,
    payload: data,
});
export const createFeedbackError = (error) => ({
    type: feedbackActionTypes.CREATE_FEEDBACK_ERROR,
    payload: error,
});

// getting
export const getFeedbackRequest = () => ({
    type: feedbackActionTypes.GET_FEEDBACK_REQUEST,
});
export const getFeedbackSucces = (data) => ({
    type: feedbackActionTypes.GET_FEEDBACK_SUCCESS,
    payload: data,
});
export const getFeedbackError = (error) => ({
    type: feedbackActionTypes.GET_FEEDBACK_ERROR,
    payload: error,
});

// deleting
export const deleteFeedbackRequest = () => ({
    type: feedbackActionTypes.DELETE_FEEDBACK_REQUEST,
});
export const deleteFeedbackSucces = (data) => ({
    type: feedbackActionTypes.DELETE_FEEDBACK_SUCCESS,
    payload: data,
});
export const deleteFeedbackError = (error) => ({
    type: feedbackActionTypes.DELETE_FEEDBACK_ERROR,
    payload: error,
});

// updating
export const updateFeedbackRequest = () => ({
    type: feedbackActionTypes.UPDATE_FEEDBACK_REQUEST,
});
export const updateFeedbackSucces = (data) => ({
    type: feedbackActionTypes.UPDATE_FEEDBACK_SUCCESS,
    payload: data,
});
export const updateFeedbackError = (error) => ({
    type: feedbackActionTypes.UPDATE_FEEDBACK_ERROR,
    payload: error,
});

// state setting
export const setAssessment = (assessment) => ({
    type: feedbackActionTypes.SET_ASSESSMENT,
    payload: assessment,
});
export const setFeedbackText = (text) => ({
    type: feedbackActionTypes.SET_FEEDBACK_TEXT,
    payload: text,
});

// clear
export const clearFeedback = () => ({
    type: feedbackActionTypes.CLEAR_FEEDBACK,
});
