import { chatTypes } from "./chats.types";

// get chat list
export const getChatListPending = () => ({
    type: chatTypes.GET_CHAT_LIST_PENDING,
});

export const getChatListFulfilled = (data) => ({
    type: chatTypes.GET_CHAT_LIST_FULFILLED,
    payload: data,
});

export const getChatListRejected = (error) => ({
    type: chatTypes.GET_CHAT_LIST_REJECTED,
    payload: error,
});

// get chat
export const getChatPending = () => ({
    type: chatTypes.GET_CHAT_PENDING,
});

export const getChatFulfilled = (data) => ({
    type: chatTypes.GET_CHAT_FULFILLED,
    payload: data,
});

export const getChatRejected = (error) => ({
    type: chatTypes.GET_CHAT_REJECTED,
    payload: error,
});

// post chat
export const postChatPending = () => ({
    type: chatTypes.POST_CHAT_PENDING,
});

export const postChatFulfilled = (data) => ({
    type: chatTypes.POST_CHAT_FULFILLED,
    payload: data,
});

export const postChatRejected = (error) => ({
    type: chatTypes.POST_CHAT_REJECTED,
    payload: error,
});

// delete chat
export const deleteChatPending = () => ({
    type: chatTypes.DELETE_CHAT_PENDING,
});

export const deleteChatFulfilled = (data) => ({
    type: chatTypes.DELETE_CHAT_FULFILLED,
    payload: data,
});

export const deleteChatRejected = (error) => ({
    type: chatTypes.DELETE_CHAT_REJECTED,
    payload: error,
});

// disable chat
export const disableChatPending = () => ({
    type: chatTypes.DISABLE_CHAT_PENDING,
});

export const disableChatFulfilled = (data) => ({
    type: chatTypes.DISABLE_CHAT_FULFILLED,
    payload: data,
});

export const disableChatRejected = (error) => ({
    type: chatTypes.DISABLE_CHAT_REJECTED,
    payload: error,
});

// post chat file
export const postChatFilePending = () => ({
    type: chatTypes.POST_CHAT_FILE_PENDING,
});

export const postChatFileFulfilled = (data) => ({
    type: chatTypes.POST_CHAT_FILE_FULFILLED,
    payload: data,
});

export const postChatFileRejected = (error) => ({
    type: chatTypes.POST_CHAT_FILE_REJECTED,
    payload: error,
});

// get chat
export const getComplaintsTypesPending = () => ({
    type: chatTypes.GET_COMPLAINTS_TYPES_PENDING,
});

export const getComplaintsTypesFulfilled = (data) => ({
    type: chatTypes.GET_COMPLAINTS_TYPES_FULFILLED,
    payload: data,
});

export const getComplaintsTypesRejected = (error) => ({
    type: chatTypes.GET_COMPLAINTS_TYPES_REJECTED,
    payload: error,
});

// set
export const clearAllParams = () => ({
    type: chatTypes.CLEAR_ALL_PARAMS,
});

export const setComplainStep = (data) => ({
    type: chatTypes.SET_COMPLAIN_STEP,
    payload: data,
});

export const setPage = (data) => ({
    type: chatTypes.SET_PAGE,
    payload: data,
});

export const setName = (data) => ({
    type: chatTypes.SET_NAME,
    payload: data,
});


// post complaint
export const postComplaintPending = () => ({
    type: chatTypes.POST_COMPLAINT_PENDING,
});

export const postComplaintFulfilled = (data) => ({
    type: chatTypes.POST_COMPLAINT_FULFILLED,
    payload: data,
});

export const postComplaintRejected = (error) => ({
    type: chatTypes.POST_COMPLAINT_REJECTED,
    payload: error,
});