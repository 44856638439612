import React, { useRef } from "react";
import PropTypes from "prop-types";

import ButtonLoader from "../../../../../../../components/loader/components/button-loader/button-loader";
import useVideoPlayer from "../../../../../../../hooks/use-video-player";
import { Icon } from "../../../../../../../components/image/image";

import "./index.scss";

const FilesPreview = ({ video }) => {
    const videoRef = useRef(null);
    const {
        player,
        setOnLoadedMetaData,
        togglePlay,
        handleOnTimeUpdate,
        handleVideoProgress,
        currentVideoTime,
        pastVideoTime,
        videoReturnBack,
        videoAdvanceForward,
        toggleMute,
        toggleDownload,
    } = useVideoPlayer(videoRef);

    return (
        <div className="files-preview">
            <div className="files-preview__container">
                <video
                    className="files-preview__container-video"
                    onLoadedMetadata={setOnLoadedMetaData}
                    onTimeUpdate={handleOnTimeUpdate}
                    onEnded={togglePlay}
                    src={video.url}
                    ref={videoRef}
                />
                {player.isReady && (
                    <div className="files-preview__controls">
                        <div className="files-preview__controls-actions">
                            <button
                                className={`files-preview__controls-actions-item ${
                                    player.downloadLoader ? "download" : ""
                                }`}
                                onClick={() => toggleDownload(video.url)}
                                disabled={player.downloadLoader}
                            >
                                {player.downloadLoader ? (
                                    <ButtonLoader size={24} />
                                ) : (
                                    <Icon type="downloadIcon" />
                                )}
                            </button>
                            <div className="files-preview__controls-actions-play">
                                <button
                                    className="files-preview__controls-actions-play-item"
                                    onClick={videoReturnBack}
                                >
                                    <Icon type="prevIcon" />
                                </button>
                                <button
                                    className="files-preview__controls-actions-play-item"
                                    onClick={togglePlay}
                                >
                                    {!player.playing ? (
                                        <Icon type="startIcon" />
                                    ) : (
                                        <Icon type="stopIcon" />
                                    )}
                                </button>
                                <button
                                    className="files-preview__controls-actions-play-item"
                                    onClick={videoAdvanceForward}
                                >
                                    <Icon type="nextIcon" />
                                </button>
                            </div>
                            <button
                                className="files-preview__controls-actions-item"
                                onClick={toggleMute}
                            >
                                {!player.muted ? (
                                    <Icon type="volumeOn" />
                                ) : (
                                    <Icon type="volumeOff" />
                                )}
                            </button>
                        </div>
                        <input
                            onChange={handleVideoProgress}
                            value={player.progress}
                            type="range"
                            max="100"
                            min="0"
                        />
                        <div className="files-preview__controls-time">
                            <span className="files-preview__controls-time-item">
                                {currentVideoTime()}
                            </span>
                            <span className="files-preview__controls-time-item">
                                {pastVideoTime()}
                            </span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

FilesPreview.propTypes = { video: PropTypes.object };
FilesPreview.defaultProps = { video: {} };
export default FilesPreview;
