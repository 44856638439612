import PropTypes from "prop-types";
import React from "react";

import "./index.scss";

const FavoriteIcon = ({ favorite, handleOnClick, favPage }) => (
    <div
        onClick={handleOnClick}
        className={`favorite-icon ${favPage && "favorite-icon-no-margin"}`}
    >
        <svg
            width="30"
            height="26"
            viewBox="0 0 30 26"
            fill={favorite ? "#33B378" : "none"}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.99996 1.77182C4.94996 1.77182 1.66663 5.05516 1.66663 9.10516C1.66663 16.4385 10.3333 23.1052 15 24.6558C19.6666 23.1052 28.3333 16.4385 28.3333 9.10516C28.3333 5.05516 25.05 1.77182 21 1.77182C18.52 1.77182 16.3266 3.00316 15 4.88782C14.3237 3.92463 13.4254 3.13855 12.381 2.59615C11.3366 2.05374 10.1768 1.77099 8.99996 1.77182Z"
                stroke="#33B378"
                strokeWidth="2.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    </div>
);

FavoriteIcon.defaultProps = { favorite: false, handleOnClick: () => {} };
FavoriteIcon.propTypes = {
    handleOnClick: PropTypes.func,
    favorite: PropTypes.bool,
    favPage: PropTypes.bool,
};
export default FavoriteIcon;
