import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { websocketsSelector } from "../../components/websockets/websockets.selectors";
import { chatsPageSelector, chatsSelector } from "./chats.selectors";
import {
    editChatListPage,
    getChatList,
    updatedChatsOperation,
} from "./chats.operations";
import { clearAllParams } from "./chats.actions";

import { socketEvents } from "../../components/websockets/websockets.constants";
import { backButtonConfig } from "../../components/back-button/back-button.config";
import ReactHelmet from "../../components/react-helmet/react-helmet";
import ChatsContent from "./components/chats-content/chats-content";
import BackButton from "../../components/back-button/back-button";
import ChatsSearch from "./components/chats-search/chats-search";
import Pagination from "../../components/pagination/pagination";
import ChatsEmptyResult from "./components/chats-empty-result/chats-empty-result";
import { sizeItemOnPage } from "./chats.constants";

import "./index.scss";

const Chats = () => {
    const { socket } = useSelector(websocketsSelector);
    const { chatList } = useSelector(chatsSelector);
    const page = useSelector(chatsPageSelector);
    const { items, count } = chatList;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        dispatch(getChatList(sizeItemOnPage));
        return () => dispatch(clearAllParams());
    }, []);

    useEffect(() => {
        socket?.on(socketEvents.CHAT_UPDATED, (chat) =>
            dispatch(updatedChatsOperation(chat))
        );
        return () => socket?.off && socket.off(socketEvents.CHAT_UPDATED);
    }, [socket]);

    return (
        <Fragment>
            <ReactHelmet title="chats.title" />
            <div className="chats">
                <div className="chats__header">
                    <div className="chats__header-btn">
                        <BackButton
                            onClickCallback={() =>
                                navigate(
                                    backButtonConfig[location.state].href,
                                    { state: true }
                                )
                            }
                            title={t(backButtonConfig[location.state].title)}
                        />
                    </div>
                    <div className="chats__header-content">
                        <span className="chats__header-content-title">
                            {t("chats.title")}
                        </span>
                    </div>
                </div>
                <ChatsSearch />
                {count ? (
                    <Fragment>
                        <ChatsContent chats={items} />
                        <Pagination
                            getNextPageItems={(e) => {
                                dispatch(editChatListPage(sizeItemOnPage, e));
                            }}
                            sizeItemOnPage={sizeItemOnPage}
                            isStartedPage={page}
                            elementType="page"
                            items={chatList}
                        />
                    </Fragment>
                ) : (
                    <ChatsEmptyResult />
                )}
            </div>
        </Fragment>
    );
};

export default Chats;
