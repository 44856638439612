import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import PropTypes from "prop-types";
import { memo } from "react";
import dayjs from "dayjs";

import { openModal } from "../../../modal/modal.actions";

import { addRemoveFavoritesServices } from "../../../../pages/all-services/all-services.operations";

import { authSelector } from "../../../../pages/auth/auth.selectors";
import { roleSelector } from "../../../../pages/my-account/my-account.selectors";

import { modalConstants } from "../../../modal/modal.constants";
import { typeOfLazeImage } from "../../../lazy-image/lazyImage.constansts";
import { promotionTypesContants } from "../../../../pages/promotion/promotion.constants";
import routerBook from "../../../../router/router-book";

import { getLocalizedService } from "../../../../helpers/get-localized-service";
import { getPhotoByTypeGender } from "../../../../helpers/get-photo-by-type-gender";

import FavoriteIcon from "../favorite-icon/favorite-icon";
import LazyImage from "../../../lazy-image/lazy-image";
import { ReactComponent as CurrencyLira } from "../../../../assets/images/currency/lira.svg";
import { ReactComponent as PopularIcon } from "../../../../assets/images/vectors/popular.svg";

import "./index.scss";

const SearchedSpecialist = ({
    specialist = {},
    priceTo = "",
    priceFrom = "",
    createdAt = "",
    category,
    clarification,
    subcategory,
    comment,
}) => {
    const {
        popular,
        avatar,
        gender,
        firstName,
        lastName,
        _id: receiverId,
        isOnline,
        buyPromotion,
        isFavorite,
    } = specialist;

    const [favorite, setFavorite] = useState(isFavorite);

    const { role } = useSelector(roleSelector);
    const { token } = useSelector(authSelector);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleNavigate = () => {
        if (!token) {
            dispatch(
                openModal({
                    isOpen: true,
                    closeIcon: true,
                    renderType: modalConstants.registerToView,
                })
            );
        }
        !!role &&
            navigate(`${routerBook.specialistsList}/${receiverId}`, {
                state: true,
            });
    };
    const handleOfferCooperation = () => {
        !!role
            ? dispatch(
                  openModal({
                      isOpen: true,
                      closeIcon: true,
                      renderType: modalConstants.selectCustomerOrder,
                      details: receiverId,
                  })
              )
            : dispatch(
                  openModal({
                      isOpen: true,
                      closeIcon: true,
                      renderType: modalConstants.auth,
                  })
              );
    };

    const isBeautyName =
        buyPromotion &&
        buyPromotion.find(
            ({ type }) => type === promotionTypesContants.BEAUTY_NAME
        );

    return (
        <div className="searched_specialist">
            <div className="searched_specialist__img">
                <span className="searched_specialist__img_avatar">
                    <LazyImage
                        image={getPhotoByTypeGender(avatar, gender)}
                        type={typeOfLazeImage.avatar}
                        options={{
                            title: isOnline ? t("online") : t("offline"),
                            className: "searched_specialist__img_avatar",
                            alt: "avatar",
                        }}
                    />
                    {isOnline && (
                        <span className="searched_specialist__img_avatar_online" />
                    )}
                </span>
            </div>
            <div className="searched_specialist__info">
                <div className="searched_specialist__header">
                    <div className="searched_specialist__header_info">
                        <div
                            onClick={handleNavigate}
                            className={`searched_specialist__header_info_title ${
                                isBeautyName ? "beauty" : ""
                            }`}
                        >
                            {popular && (
                                <PopularIcon
                                    className={`searched_specialist__header_info_title-popular ${
                                        isBeautyName ? "beauty" : ""
                                    }`}
                                />
                            )}
                            {firstName}{" "}
                            {!!role ? lastName : `${lastName.charAt(0)}.`}
                        </div>
                        <div className="searched_specialist__header_info_day">
                            {dayjs(createdAt).format("DD")}{" "}
                            {t(
                                `full-months.${dayjs(createdAt).format("MMMM")}`
                            )}{" "}
                            {dayjs(createdAt).format("YYYY")}
                        </div>
                        <div className="searched_specialist__header_info_price">
                            {priceFrom} - {priceTo} <CurrencyLira />
                        </div>
                    </div>
                </div>
            </div>
            <div className="searched_specialist__description">{comment}</div>
            <div className="searched_specialist__right-block">
                {!!role && (
                    <FavoriteIcon
                        handleOnClick={() => {
                            setFavorite(!favorite);
                            dispatch(
                                addRemoveFavoritesServices({
                                    userId: receiverId,
                                    addOrRemove: !favorite,
                                })
                            );
                        }}
                        favorite={favorite}
                    />
                )}
                <button
                    className="searched_specialist__right-block-btn"
                    onClick={handleOfferCooperation}
                >
                    {t("all_service_call_to_work")}
                </button>
            </div>
            <div className="searched_specialist__body">
                <div className="searched_specialist__body_tags">
                    <div className="searched_specialist__body_tags_list">
                        <div className="searched_specialist__body_tags_list_item">
                            {getLocalizedService(category)}
                        </div>
                        <div className="searched_specialist__body_tags_list_item">
                            {getLocalizedService(subcategory)}
                        </div>
                        <div className="searched_specialist__body_tags_list_item">
                            {getLocalizedService(clarification)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

SearchedSpecialist.propTypes = {
    priceFrom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    priceTo: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    specialist: PropTypes.object,
    createdAt: PropTypes.string,
    category: PropTypes.object,
    clarification: PropTypes.object,
    subcategory: PropTypes.object,
    comment: PropTypes.string,
};

export default memo(SearchedSpecialist);
