import { string, object } from "yup";
import { t } from "i18next";

export const balanceOnboardingIyzicoModalSchema = object().shape({
    taxOffice: string().trim(true).required("validation_required"),
    legalCompanyTitle: string().trim(true).required("validation_required"),
    address: string().trim(true).required("validation_required"),
    gsmNumber: string()
        .required("validation_required")
        .matches(
            /^\d{12}$/,
            t("validation_phone_number_count_of_symbols", { length: 12 })
        ),
    iban: string()
        .required("validation_required")
        .matches(
            /^\d{24}$/,
            t("validation_phone_number_count_of_symbols", { length: 24 })
        ),
    identityNumber: string()
        .required("validation_required")
        .matches(
            /^\d{11}$/,
            t("validation_phone_number_count_of_symbols", { length: 11 })
        ),
});
