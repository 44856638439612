import { get } from "../api";

export const getServices = async () => {
    const url = "/category?limit=1000";

    try {
        const { data } = await get(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const getSubservices = async (categoryId) => {
    const category = categoryId ? `&categoryId=${categoryId}` : "";
    const url = "/category/subcategory?limit=1000" + category;

    try {
        const { data } = await get(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const getClarifications = async (subcategoryId) => {
    const subcategory = subcategoryId ? `&subcategoryId=${subcategoryId}` : "";
    const url = "/category/clarification?limit=1000" + subcategory;

    try {
        const { data } = await get(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const createServiceBySpecialist = async () => {
    const url = "/service";

    try {
        const { data } = await get(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};
