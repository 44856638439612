import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { paymentTypes } from "../../../balance/balance.constants";
import { buyPromotionById } from "../../../promotion/promotion.operations";
import { closeModal } from "../../../../components/modal/modal.actions";
import MainButton from "../../../../components/main-button/main-button";
import { ReactComponent as PaymentCardIcon } from "../../../../assets/images/currency/payment-card.svg";

import "./index.scss";

const SpecialistContactsModal = ({ details }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { promotionId, specialistId } = details;
    const { iyzico } = paymentTypes;

    const callbackUrl = `${location.origin}/specialists/${specialistId}`;

    const payForContactsHandler = () => {
        dispatch(
            buyPromotionById(
                {
                    _id: promotionId,
                    priceCount: 1,
                    priceType: "day",
                    callbackUrl,
                    specialistId,
                },
                iyzico.value
            )
        );
        dispatch(closeModal());
    };

    return (
        <div className="specialist-contacts-modal">
            <PaymentCardIcon />
            <p className="specialist-contacts-modal__title">
                {t("specialist-profile.contacts.modal-title")}
            </p>
            <p className="specialist-contacts-modal__description">
                {t("specialist-profile.contacts.modal-description")}
            </p>
            <div className="specialist-contacts-modal__buttons">
                <div className="specialist-contacts-modal__buttons__button-wrapper">
                    <MainButton
                        buttonSize="btn--stretch"
                        onClick={payForContactsHandler}
                    >
                        {t("btn_pay")}
                    </MainButton>
                </div>
                <div className="specialist-contacts-modal__buttons__button-wrapper">
                    <MainButton
                        buttonSize="btn--stretch"
                        buttonStyle="btn--light"
                        onClick={() => dispatch(closeModal())}
                    >
                        {t("specialist-profile.contacts.no-button")}
                    </MainButton>
                </div>
            </div>
        </div>
    );
};

SpecialistContactsModal.propTypes = {
    details: PropTypes.shape({
        promotionId: PropTypes.string,
        specialistId: PropTypes.string,
    }),
};
export default SpecialistContactsModal;
