import * as api from "../../services/requests/specialist";

import { changeGlobalLoader } from "../../components/loader/loader.actions";
import * as specialistActions from "./specialist-list.actions";

// get specialist list
export const getSpecialistList =
    (sizeItemOnPage, params = "") =>
    (dispatch) => {
        dispatch(specialistActions.getSpecialistListPending());
        dispatch(changeGlobalLoader(true));

        return api
            .getSpecialistList(`?limit=${sizeItemOnPage}` + params)
            .then(({ items, totalCount: count }) => {
                const payload = { items, count };
                dispatch(specialistActions.getSpecialistListFulfilled(payload));
            })
            .catch((error) =>
                dispatch(specialistActions.getSpecialistListRejected(error))
            )
            .finally(() => dispatch(changeGlobalLoader(false)));
    };

export const getSpecialistListByCategory =
    (sizeItemOnPage, category, params = "") =>
    (dispatch) => {
        dispatch(specialistActions.getSpecialistListPending());
        dispatch(changeGlobalLoader(true));

        return api
            .searchSpecialist(
                `?category=${category}&limit=${sizeItemOnPage}` + params
            )
            .then(({ items, totalCount: count }) => {
                const payload = { items, count };
                dispatch(specialistActions.getSpecialistListFulfilled(payload));
            })
            .catch((error) =>
                dispatch(specialistActions.getSpecialistListRejected(error))
            )
            .finally(() => dispatch(changeGlobalLoader(false)));
    };

export const getSpecialistProfileByID = (specialistID) => (dispatch) => {
    dispatch(specialistActions.clearSelectedSpecialist());
    dispatch(changeGlobalLoader(true));

    return api
        .getSpecialistInfoByID(specialistID)
        .then((data) =>
            dispatch(specialistActions.getSpecialistProfileByIDFulfilled(data))
        )
        .catch((error) =>
            dispatch(specialistActions.getSpecialistProfileByIDRejected(error))
        )
        .finally(() => dispatch(changeGlobalLoader(false)));
};

// set
export const editSpecialistListPage = (size, data, category) => (dispatch) => {
    dispatch(specialistActions.setPage(`&page=${data}`));
    const url = `&page=${data}`;
    if (category) {
        dispatch(getSpecialistListByCategory(size, category, url));
    } else dispatch(getSpecialistList(size, url));
};
