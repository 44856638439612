import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import React, { Fragment, useEffect } from "react";
import dayjs from "dayjs";
import DOMPurify from "dompurify";

import { blogSelector } from "../blog/blog.selectors";
import { getBlog } from "../blog/blog.operations";
import { socialMedia } from "../blog/components/blog-item/blog-item.config";
import { getLanguage } from "../../helpers/language";
import { parseSocialUrl } from "../../helpers/social-url-parser";

import { backButtonConfig } from "../../components/back-button/back-button.config";
import ReactHelmet from "../../components/react-helmet/react-helmet";
import BackButton from "../../components/back-button/back-button";
import { Icon } from "../../components/image/image";

import "./quill.core.scss";
import "./index.scss";

const BlogInfo = () => {
    const dispatch = useDispatch();
    const { blogInfo } = useSelector(blogSelector);

    const { t } = useTranslation();
    const { value: language } = getLanguage();

    const routerLocation = useLocation();
    const navigate = useNavigate();
    const { id } = useParams();

    const { text, title, image, updatedAt, category, _id, creator } = blogInfo;

    useEffect(() => {
        dispatch(getBlog(id));
    }, []);

    const sanitizedTitle = () => {
        if (title) {
            return {
                __html: DOMPurify.sanitize(title[language]),
            };
        }
    };

    const sanitizedText = () => {
        if (text) {
            return {
                __html: DOMPurify.sanitize(text[language]),
            };
        }
    };

    return (
        <Fragment>
            <ReactHelmet
                title={title && title[language]}
                image={image}
                description={text && text[language]}
            />
            <div className="blog-info">
                <div className="blog-info__header">
                    <BackButton
                        onClickCallback={() =>
                            navigate(
                                backButtonConfig[routerLocation.state].href,
                                {
                                    state: true,
                                }
                            )
                        }
                        title={t(backButtonConfig[routerLocation.state].title)}
                    />
                </div>
                <div className="blog-info__body">
                    <div className="blog-info__body-category">
                        {category?.titleTranslations[language]}
                    </div>
                    <div className="blog-info__body-title">
                        <span
                            className="ql-editor"
                            dangerouslySetInnerHTML={sanitizedTitle()}
                        ></span>
                    </div>
                    <div className="blog-info__body-date">
                        <span>
                            By {creator?.firstName} {creator?.lastName}
                        </span>
                        <span className="blog-info__body-date-divider">|</span>
                        <span>
                            {dayjs(updatedAt).format("MMMM")}{" "}
                            {dayjs(updatedAt).format("D")},{" "}
                            {dayjs(updatedAt).format("YYYY")}
                        </span>
                    </div>
                    <img
                        className="blog-info__body-image"
                        alt="image"
                        src={image}
                    />
                    <div
                        className="blog-info__body-description ql-editor"
                        dangerouslySetInnerHTML={sanitizedText()}
                    ></div>
                    <div className="blog-info__body-social">
                        {Object.values(socialMedia).map(({ name, href }) => (
                            <a
                                className="blog-info__body-social-item"
                                href={`${href}${parseSocialUrl(
                                    location.href
                                )}/${_id}`}
                                rel="noreferrer"
                                target="_blank"
                                key={name}
                            >
                                <Icon type={name} />
                            </a>
                        ))}
                    </div>
                </div>
                <div className="blog-info__related"></div>
            </div>
        </Fragment>
    );
};

export default BlogInfo;
