import { formDataHeaders, post, put, get, destroy, patch } from "../api";

import { userRoles } from "../../constants/user-roles";

export const putUser = async (reqData = {}, role) => {
    const url = role === userRoles.customer ? "/customer" : "/specialist";

    try {
        const { data } = await put(url, reqData);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;

        throw { errorData, status };
    }
};

export const putAvatar = async (reqData = {}) => {
    const url = "user/avatar";

    try {
        const { data } = await post(url, reqData, formDataHeaders);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const getUserById = async (token) => {
    const url = `/user/me`;

    try {
        const { data } = await get(url, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const getAvatars = async () => {
    const url = "/static-paths  ";

    try {
        const { data } = await get(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const deleteAvatar = async () => {
    const url = "user/avatar";

    try {
        const { data } = await destroy(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const deleteUser = async () => {
    const url = "user/me";

    try {
        const { data } = await destroy(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const getPhoneVerificationCodeRequest = async (phone) => {
    const url = `/verifications/code-for-phone/${phone}`;

    try {
        const { data } = await get(url);

        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;

        throw { errorData, status };
    }
};

export const phoneVerifyRequest = async (reqData = {}) => {
    const url = `/verifications/verify-phone`;

    try {
        const { data } = await patch(url, reqData);

        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;

        throw { errorData, status };
    }
};

export const getEmailVerificationCodeRequest = async (email) => {
    const url = `/verifications/code-for-email/${email}`;

    try {
        const { data } = await get(url);

        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;

        throw { errorData, status };
    }
};

export const emailVerifyRequest = async (reqData = {}) => {
    const url = `/verifications/verify-email`;

    try {
        const { data } = await patch(url, reqData);

        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;

        throw { errorData, status };
    }
};

export const sendOauthConnectRequest = async (reqData = {}) => {
    const url = `/auth/oauth/connect`;

    try {
        const { data } = await post(url, reqData);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};
