export const feedbackActionTypes = {
    // create feedback actions
    CREATE_FEEDBACK_REQUEST: "feedback/CREATE_FEEDBACK_REQUEST",
    CREATE_FEEDBACK_SUCCESS: "feedback/CREATE_FEEDBACK_SUCCESS",
    CREATE_FEEDBACK_ERROR: "feedback/CREATE_FEEDBACK_ERROR",

    // get all user feedbacks actions
    GET_FEEDBACK_REQUEST: "feedback/GET_FEEDBACK_REQUEST",
    GET_FEEDBACK_SUCCESS: "feedback/GET_FEEDBACK_SUCCESS",
    GET_FEEDBACK_ERROR: "feedback/GET_FEEDBACK_ERROR",

    // delete feedback actions
    DELETE_FEEDBACK_REQUEST: "feedback/DELETE_FEEDBACK_REQUEST",
    DELETE_FEEDBACK_SUCCESS: "feedback/DELETE_FEEDBACK_SUCCESS",
    DELETE_FEEDBACK_ERROR: "feedback/DELETE_FEEDBACK_ERROR",

    // update feedback actions
    UPDATE_FEEDBACK_REQUEST: "feedback/UPDATE_FEEDBACK_REQUEST",
    UPDATE_FEEDBACK_SUCCESS: "feedback/UPDATE_FEEDBACK_SUCCESS",
    UPDATE_FEEDBACK_ERROR: "feedback/UPDATE_FEEDBACK_ERROR",

    // data
    SET_ASSESSMENT: "feedback/SET_ASSESSMENT",
    SET_FEEDBACK_TEXT: "feedback/SET_FEEDBACK_TEXT",

    // clear
    CLEAR_FEEDBACK: "feedback/CLEAR_FEEDBACK",
};
