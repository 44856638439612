import { useState, useEffect } from "react";

const useVideoPlayer = (video) => {
    const [player, setPlayer] = useState({
        playing: false,
        isReady: false,
        muted: false,
        progress: 0,
    });

    useEffect(() => {
        player.muted
            ? (video.current.muted = true)
            : (video.current.muted = false);
    }, [player.muted, video]);

    useEffect(() => {
        player.playing ? video.current.play() : video.current.pause();
    }, [player.playing, video]);

    const handleOnTimeUpdate = () => {
        const progress =
            (video.current.currentTime / video.current.duration) * 100;
        setPlayer((prevValues) => ({ ...prevValues, progress }));
    };

    const handleVideoProgress = (event) => {
        const manualChange = Number(event.target.value);
        video.current.currentTime =
            (video.current.duration / 100) * manualChange;
        setPlayer((prevValues) => ({ ...prevValues, progress: manualChange }));
    };

    const currentVideoTime = () => {
        return new Date(Math.ceil(video.current.currentTime * 1000) || 0)
            .toISOString()
            .substring(14, 19);
    };

    const pastVideoTime = () => {
        return new Date(
            Math.ceil(
                video.current.duration * 1000 - video.current.currentTime * 1000
            ) || 0
        )
            .toISOString()
            .substring(14, 19);
    };

    const videoReturnBack = () => {
        video.current.currentTime = video.current.currentTime - 15;
    };

    const videoAdvanceForward = () => {
        video.current.currentTime = video.current.currentTime + 15;
    };

    const togglePlay = () => {
        setPlayer((prevValues) => ({
            ...prevValues,
            playing: !prevValues.playing,
        }));
    };

    const toggleMute = () => {
        setPlayer((prevValues) => ({
            ...prevValues,
            muted: !prevValues.muted,
        }));
    };

    const toggleDownload = (src) => {
        setPlayer((prevValues) => ({ ...prevValues, downloadLoader: true }));
        fetch(src)
            .then((response) => response.blob())
            .then((blob) => {
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = "Wconnect - video";
                link.click();
            })
            .finally(() => {
                setPlayer((prevValues) => ({
                    ...prevValues,
                    downloadLoader: false,
                }));
            });
    };

    const setOnLoadedMetaData = () =>
        setPlayer((prevValues) => ({
            ...prevValues,
            isReady: true,
        }));

    for (let e of document.querySelectorAll('input[type="range"]')) {
        e.style.setProperty("--value", e.value);
        e.style.setProperty("--min", e.min == "" ? "0" : e.min);
        e.style.setProperty("--max", e.max == "" ? "100" : e.max);
        e.addEventListener("input", () =>
            e.style.setProperty("--value", e.value)
        );
    }

    return {
        player,
        setOnLoadedMetaData,
        togglePlay,
        handleOnTimeUpdate,
        handleVideoProgress,
        currentVideoTime,
        pastVideoTime,
        videoReturnBack,
        videoAdvanceForward,
        toggleMute,
        toggleDownload,
    };
};

export default useVideoPlayer;
