import { errorToastify } from "../../../../components/toastify/toastify.operations";
import { openModal } from "../../../../components/modal/modal.actions";

import { modalConstants } from "../../../../components/modal/modal.constants";
import { accessTaskStatus, failureTaskStatus } from "../chat/chat.config";
import { Icon } from "../../../../components/image/image";
import routerBook from "../../../../router/router-book";

export const descriptionItem = [
    {
        title: "chats_description_user_info.completed_orders",
    },
    {
        title: "chats_description_user_info.age",
    },
    {
        title: "chats_description_user_info.already_on_the_site",
    },
    {
        title: "chats_description_user_info.city",
    },
    {
        title: "chats_description_user_info.district",
    },
];

export const profileServices = [
    {
        title: "chats_actions.questionnaire",
        actionType: "navigate",
        action: (_id, navigate) =>
            navigate(`${routerBook.specialistsList}/${_id}`, { state: true }),
    },
    {
        title: "chats_actions.feedback",
        image: <Icon type="like" />,
        actionType: "dispatch",
        action: (details, dispatch, taskStatus) =>
            taskStatus === accessTaskStatus.completed
                ? dispatch(
                      openModal({
                          isOpen: true,
                          closeIcon: true,
                          renderType: modalConstants.sendFeedback,
                          details,
                      })
                  )
                : dispatch(
                      errorToastify(
                          "toastify.order.status_doesnt_match_for_feedback"
                      )
                  ),
    },
    {
        title: "chats_actions.cancel",
        actionType: "dispatch",
        action: (details, dispatch, taskStatus) =>
            taskStatus !== accessTaskStatus.completed &&
            taskStatus !== failureTaskStatus.cancelled
                ? dispatch(
                      openModal({
                          isOpen: true,
                          closeIcon: true,
                          renderType: modalConstants.declinePropose,
                          details: { ...details, taskStatus },
                      })
                  )
                : dispatch(errorToastify("toastify.order.status_doesnt_match")),
    },
    {
        title: "chats_actions.report",
        image: <Icon type="report" />,
        actionType: "dispatch",
        action: (details, dispatch, taskStatus) =>
            dispatch(
                openModal({
                    isOpen: true,
                    closeIcon: true,
                    renderType: modalConstants.complain,
                    details: { ...details, taskStatus },
                })
            ),
    },
];
