import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { closeModal } from "../../../../../../../components/modal/modal.actions";

import PropTypes from "prop-types";

import "./index.scss";
import { userRoles } from "../../../../../../../constants/user-roles";

const Attention = ({ details: { role = "CUSTOMER" } }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleClick = () => dispatch(closeModal());

    return (
        <div className="attention">
            <h1 className="attention-title">{t("complain-attention-title")}</h1>
            <span className="attention-subtitle">
                {role === userRoles.customer
                    ? t("complain-attention-subtitle.customer")
                    : t("complain-attention-subtitle.specialist")}
            </span>
            <button
                className="attention-btn"
                onClick={handleClick}
                type="submit"
            >
                {t("btn_close")}
            </button>
        </div>
    );
};

Attention.propTypes = {
    details: PropTypes.object,
};

export default Attention;
