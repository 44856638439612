import PropTypes from "prop-types";
import React from "react";

import LottieAnimation from "../../../lottie-animation/lottie-animation";
import loader from "../../../../assets/animation/button-loader.json";

import "./index.scss";

const ButtonLoader = ({ size }) => {
    return (
        <div className="spinner-container">
            <LottieAnimation lotti={loader} width={size} height={size} />
        </div>
    );
};

ButtonLoader.propTypes = { size: PropTypes.number };

export default ButtonLoader;
