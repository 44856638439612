import { toastifyTypes } from "./toastify.types";

const initialState = { isVisible: false, type: "", title: "", html: "" };

export const toastify = (state = initialState, { type, payload }) => {
    switch (type) {
        case toastifyTypes.SET_TOASTIFY_STATUS:
            return payload;

        default:
            return state;
    }
};
