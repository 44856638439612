export const inputsFirstConfig = [
    {
        typeInfo: "secondary",
        labelInfo: "my_account_name",
        registerInfo: "firstName",
        placeholderTextInfo: "my_account_name_placeholder",
    },
    {
        typeInfo: "secondary",
        labelInfo: "my_account_surname",
        registerInfo: "lastName",
        placeholderTextInfo: "my_account_surname_placeholder",
    },
];

export const inputsSocialConfig = [
    {
        typeInfo: "secondary",
        labelInfo: "my_account_instagram",
        registerInfo: "socialLinks.inst",
        placeholderTextInfo: "my_account_instagram_placeholder",
        link: "inst",
    },
    {
        typeInfo: "secondary",
        labelInfo: "my_account_facebook",
        registerInfo: "socialLinks.fb",
        placeholderTextInfo: "my_account_facebook_placeholder",
        link: "fb",
    },
];

export const inputsSecondConfig = [
    {
        typeInfo: "password",
        labelInfo: "my_account_old_password",
        registerInfo: "oldPassword",
        placeholderTextInfo: "my_account_old_password_placeholder",
    },
    {
        typeInfo: "password",
        labelInfo: "my_account_new_password",
        registerInfo: "newPassword",
        placeholderTextInfo: "my_account_new_password_placeholder",
    },
];
