import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { closeModal } from "../../../../components/modal/modal.actions";
import { getPhoneVerificationCode } from "../../../my-account/my-account.operations";

import "./index.scss";

const PhoneCodeModal = ({ details }) => {
    const { phoneNumber } = details;

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const closeModalHandler = () => {
        dispatch(getPhoneVerificationCode(`+${phoneNumber}`));
        dispatch(closeModal());
    };

    return (
        <div className="phone-code-modal">
            <p className="phone-code-modal__title">
                {t("phone_verify_question")}
            </p>
            <p className="phone-code-modal__description">
                {t("phone_verify_modal_text")}
            </p>
            <button
                className="phone-code-modal__btn"
                onClick={closeModalHandler}
            >
                {t("phone_verify_modal_button")}
            </button>
        </div>
    );
};

PhoneCodeModal.propTypes = {
    details: PropTypes.shape({
        phoneNumber: PropTypes.string,
    }),
};

export default PhoneCodeModal;
