import { get, patch, destroy } from "../api";

export const getNotificationList = async (params = "") => {
    const url = "/notification/list" + params;

    try {
        const { data } = await get(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const deleteNotificationList = async () => {
    const url = "/notification/list";

    try {
        const { data } = await destroy(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const putNotificationStatus = async (reqData = {}) => {
    const url = `/notification/read`;

    try {
        const { data } = await patch(url, reqData);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};

export const deleteNotifction = async (id) => {
    const url = `/notification/${id}`;

    try {
        const { data } = await destroy(url);
        return data;
    } catch (err) {
        const { data: errorData, status } = err.response;
        throw { errorData, status };
    }
};
