import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import React from "react";
import dayjs from "dayjs";

import { buttonLoaderSelector } from "../../../../../loader/loader.selectors";
import { postChat } from "../../../../../../pages/chats/chats.operations";
import { ordersSelector } from "../../../../../../pages/orders/orders.selectors";

import { compareOptions } from "../../../../../../helpers/compare-options";
import ButtonLoader from "../../../../../loader/components/button-loader/button-loader";
import { backButtonConfig } from "../../../../../back-button/back-button.config";
import { typeOfLazeImage } from "../../../../../lazy-image/lazyImage.constansts";
import { getLocalizedService } from "../../../../../../helpers/get-localized-service";
import { genders } from "../../../../../../constants/genders";
import LazyImage from "../../../../../lazy-image/lazy-image";

import { ReactComponent as CurrencyLira } from "../../../../../../assets/images/currency/lira.svg";
import "./index.scss";

const InfoAboutOrderForm = () => {
    const { status: statusLoading } = useSelector(buttonLoaderSelector);
    const { order } = useSelector(ordersSelector);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const {
        preferredGender,
        preferredYears,
        clarification,
        _id: orderId,
        subcategory,
        createdAt,
        priceFrom,
        fixedPrice,
        createdBy,
        executionTime,
        category,
        district,
        address,
        priceTo,
        comment,
        images,
        city,
    } = order;

    const handleOfferCooperation = () => {
        dispatch(postChat({ orderId, receiverIds: [createdBy] }, navigate));
    };

    return (
        <div className="info_about_order_form">
            <div className="info_about_order_form__details">
                <div className="info_about_order_form__details_title">
                    {t("orders.order-item.project-description")}
                </div>
                <div className="info_about_order_form__details_item">
                    <div className="info_about_order_form__details_item_description">
                        {t("orders.order-item.service-sector")}
                    </div>
                    <div className="info_about_order_form__details_item_value">
                        {getLocalizedService(category)}
                    </div>
                </div>
                <div className="info_about_order_form__details_item">
                    <div className="info_about_order_form__details_item_description">
                        {t("orders.order-item.selected-service")}
                    </div>
                    <div className="info_about_order_form__details_item_value">
                        {getLocalizedService(subcategory)}
                    </div>
                </div>
                <div className="info_about_order_form__details_item">
                    <div className="info_about_order_form__details_item_description">
                        {t("orders.order-item.service-clarification")}
                    </div>
                    <div className="info_about_order_form__details_item_value">
                        {getLocalizedService(clarification)}
                    </div>
                </div>
                <div className="info_about_order_form__details_title">
                    {t("orders.order-item.execution-address")}
                </div>
                <div className="info_about_order_form__details_item">
                    <div className="info_about_order_form__details_item_description">
                        {t("orders.order-item.city")}
                    </div>
                    <div className="info_about_order_form__details_item_value">
                        {city}
                    </div>
                </div>
                <div className="info_about_order_form__details_item">
                    <div className="info_about_order_form__details_item_description">
                        {t("orders.order-item.district")}
                    </div>
                    <div className="info_about_order_form__details_item_value">
                        {district}
                    </div>
                </div>
                <div className="info_about_order_form__details_item">
                    <div className="info_about_order_form__details_item_description">
                        {t("orders.order-item.address")}
                    </div>
                    <div className="info_about_order_form__details_item_value">
                        {address}
                    </div>
                </div>
                <div className="info_about_order_form__details_title">
                    {t("orders.order-item.execution-date")}
                </div>
                <div className="info_about_order_form__details_item">
                    <div className="info_about_order_form__details_item_description">
                        {t("orders.order-item.date")}
                    </div>
                    <div className="info_about_order_form__details_item_value">
                        {dayjs(createdAt).format("DD.MM.YYYY")}
                    </div>
                </div>
                <div className="info_about_order_form__details_item">
                    <div className="info_about_order_form__details_item_description">
                        {t("orders.order-item.time")}
                    </div>
                    <div className="info_about_order_form__details_item_value">
                        {executionTime && executionTime / 3600000}{" "}
                        {t("locations.hour.abbreviated")}
                    </div>
                </div>
                <div className="info_about_order_form__details_title">
                    {fixedPrice
                        ? t("orders.order-item.order-fixed")
                        : t("orders.order-item.order-range")}
                </div>
                <div className="info_about_order_form__details_item">
                    <div className="info_about_order_form__details_item_description">
                        {fixedPrice
                            ? t("orders.order-item.price-fixed")
                            : t("orders.order-item.price-range")}
                    </div>
                    {fixedPrice ? (
                        <div className="info_about_order_form__details_item_value">
                            {fixedPrice} <CurrencyLira />
                        </div>
                    ) : (
                        <div className="info_about_order_form__details_item_value">
                            {priceFrom} - {priceTo} <CurrencyLira />
                        </div>
                    )}
                </div>
                <div className="info_about_order_form__details_title">
                    {t("orders.order-item.prefer-gender-and-age")}
                </div>
                <div className="info_about_order_form__details_item">
                    <div className="info_about_order_form__details_item_description">
                        {t("orders.order-item.prefer-gender")}
                    </div>
                    <div className="info_about_order_form__details_item_value">
                        {preferredGender &&
                            t(compareOptions(genders, preferredGender))}
                    </div>
                </div>
                <div className="info_about_order_form__details_item">
                    <div className="info_about_order_form__details_item_description">
                        {t("orders.order-item.prefer-age")}
                    </div>
                    <div className="info_about_order_form__details_item_value">
                        {preferredYears?.from} - {preferredYears?.to}
                    </div>
                </div>
                <div className="info_about_order_form__details_title">
                    {t("orders.order-item.order-photos")}
                </div>
                <div className="info_about_order_form__details_item">
                    <div className="info_about_order_form__details_item_description">
                        {images?.length ? (
                            <div className="info_about_order_form__details_item_description_image">
                                {images.map(({ url, _id }) => (
                                    <LazyImage
                                        key={_id}
                                        image={url}
                                        type={typeOfLazeImage.content}
                                        options={{
                                            className:
                                                "info_about_order_form__details_item_description_image_item",
                                            alt: "logo",
                                        }}
                                    />
                                ))}
                            </div>
                        ) : (
                            <div className="info_about_order_form__details_item_description_text">
                                {t("orders.order-item.no-photos")}
                            </div>
                        )}
                    </div>
                </div>
                <div className="info_about_order_form__details_title">
                    {t("orders.order-item.order-comments")}
                </div>
                <div className="info_about_order_form__details_item">
                    <div className="info_about_order_form__details_item_description">
                        {comment}
                    </div>
                </div>
            </div>
            <div className="info_about_order_form__btns">
                <button
                    style={{
                        background: statusLoading && "#dfdfdf",
                    }}
                    className="info_about_order_form__btns-left"
                    onClick={handleOfferCooperation}
                    disabled={statusLoading}
                    type="button"
                >
                    {statusLoading ? (
                        <ButtonLoader size={38} />
                    ) : (
                        t("btn_offer_cooperation")
                    )}
                </button>
                <button
                    onClick={() =>
                        navigate(backButtonConfig[location.state].href, {
                            state: true,
                        })
                    }
                    className="info_about_order_form__btns-right"
                    type="button"
                >
                    {t(backButtonConfig[location.state].title)}
                </button>
            </div>
        </div>
    );
};

export default InfoAboutOrderForm;
