import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { ordersPageSelector, ordersSelector } from "../orders/orders.selectors";
import { clearAllParams } from "../orders/oders.actions";
import {
    editPageResponders,
    getOrderResponders,
} from "../orders/orders.operations";

import { errorHandlerTypes } from "../../components/error-handler/error-handler.constants";
import { backButtonConfig } from "../../components/back-button/back-button.config";
import ErrorHandler from "../../components/error-handler/error-handler";
import SpecialistsItem from "../../components/specialists/components/specialists-item/specialists-item";
import ReactHelmet from "../../components/react-helmet/react-helmet";
import BackButton from "../../components/back-button/back-button";
import Pagination from "../../components/pagination/pagination";

import "./index.scss";

const ProposalsForCooperation = () => {
    const { orderResponders } = useSelector(ordersSelector);
    const page = useSelector(ordersPageSelector);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { id } = useParams();

    const { items, count, orderName } = orderResponders;
    const sizeItemOnPage = 5;

    useEffect(() => {
        dispatch(getOrderResponders(id, sizeItemOnPage));
        return () => dispatch(clearAllParams());
    }, []);

    return (
        <Fragment>
            <ReactHelmet title="specialists_list_of_proposals" />
            <div className="proposals-list">
                <div className="proposals-list__header">
                    <div className="proposals-list__header-btn">
                        <BackButton
                            onClickCallback={() =>
                                navigate(
                                    backButtonConfig[location.state].href,
                                    { state: true }
                                )
                            }
                            title={t(backButtonConfig[location.state].title)}
                        />
                    </div>
                    <div className="proposals-list__header-content">
                        <span className="proposals-list__header-content-title">
                            {t("specialists_list_of_proposals")}
                        </span>
                        <span className="proposals-list__header-content-subtitle">
                            {t("specialists_order")} {orderName}
                        </span>
                    </div>
                </div>
                {count ? (
                    <Fragment>
                        {items.map((specialist) => (
                            <SpecialistsItem
                                key={specialist.task._id}
                                {...specialist}
                            />
                        ))}
                        <Pagination
                            items={orderResponders}
                            elementType="page"
                            sizeItemOnPage={sizeItemOnPage}
                            getNextPageItems={(e) => {
                                dispatch(
                                    editPageResponders(id, sizeItemOnPage, e)
                                );
                            }}
                            isStartedPage={page}
                        />
                    </Fragment>
                ) : (
                    <ErrorHandler type={errorHandlerTypes.NOT_FOUND_SEARCH} />
                )}
            </div>
        </Fragment>
    );
};

export default ProposalsForCooperation;
