export const filterTypes = {
    SERVICES: "services",
    SUBSERVICES: "subservices",
    CLARIFICATIONS: "clarifications",
};

export const mapToFilterConfig = [
    { title: filterTypes.SERVICES },
    { title: filterTypes.SUBSERVICES },
    { title: filterTypes.CLARIFICATIONS },
];
