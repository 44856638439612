import { Controller, useForm, useFormState } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import { clearAllParams } from "../../../../../../pages/orders/oders.actions";
import { buttonLoaderSelector } from "../../../../../loader/loader.selectors";
import { myAccountDataSelector } from "../../../../../../pages/my-account/my-account.selectors";
import { closeModal, openModal } from "../../../../../modal/modal.actions";
import { postChat } from "../../../../../../pages/chats/chats.operations";
import { ordersSelector } from "../../../../../../pages/orders/orders.selectors";
import {
    editStatusAll,
    getAllOrdersForFavourites,
} from "../../../../../../pages/orders/orders.operations";

import { ORDERS_FILTERS } from "../../../../../../pages/orders/orders.constant";
import { isEmptyObject } from "../../../../../../helpers/is-empty-object";
import ButtonLoader from "../../../../../loader/components/button-loader/button-loader";
import { modalConstants } from "../../../../../modal/modal.constants";
import ReactHelmet from "../../../../../react-helmet/react-helmet";
import SELECT_TYPES from "../../../../../select/select.constants";
import { userRoles } from "../../../../../../constants/user-roles";
import { getLocalizedService } from "../../../../../../helpers/get-localized-service";
import Select from "../../../../../select/select";

import "./index.scss";

const SelectOrderModal = ({ details: receiverId }) => {
    const { orders, loading: orderLoading } = useSelector(ordersSelector);
    const { role } = useSelector(myAccountDataSelector);
    const { status: statusLoading } = useSelector(buttonLoaderSelector);
    const { handleSubmit, control } = useForm({ mode: "all" });
    const { dirtyFields } = useFormState({ control });
    const { count, items } = orders;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const sizeItemOnPage = 100;

    useEffect(() => {
        switch (role) {
            case userRoles.customer:
                dispatch(
                    editStatusAll(
                        false,
                        sizeItemOnPage,
                        `${ORDERS_FILTERS.NEW.toUpperCase()}&statuses=${ORDERS_FILTERS.NEGOTIATION.toUpperCase()}`
                    )
                );
                break;
            case userRoles.specialist:
                dispatch(getAllOrdersForFavourites(receiverId, sizeItemOnPage));

                break;
            default:
                return;
        }

        return () => dispatch(clearAllParams());
    }, []);

    const mapStateToOptions = (array) => {
        return array.map(({ subcategoryId, _id, comment }) => {
            return {
                value: _id,
                label: getLocalizedService(subcategoryId),
                info: comment,
            };
        });
    };

    const onSubmit = ({ orderId }) => {
        if (orderId) {
            dispatch(
                postChat(
                    { orderId: orderId.value, receiverIds: [receiverId] },
                    navigate
                )
            );
            dispatch(closeModal());
        } else {
            dispatch(
                openModal({
                    isOpen: true,
                    closeIcon: true,
                    renderType: modalConstants.createOrder,
                    details: receiverId,
                })
            );
        }
    };

    return (
        <Fragment>
            <ReactHelmet title="all_service_call_to_work" />
            <form
                className="select-order-modal"
                onSubmit={handleSubmit(onSubmit)}
            >
                {orderLoading ? (
                    <>
                        <h1 className="select-order-modal__title">
                            {t("select_order_modal.select_order")}
                        </h1>
                        <ButtonLoader size={71} />
                        <button
                            disabled={isEmptyObject(dirtyFields)}
                            className="select-order-modal__btn"
                            type="submit"
                        >
                            {t("btn_confirm")}
                        </button>
                    </>
                ) : count > 0 ? (
                    <>
                        <h1 className="select-order-modal__title">
                            {t("select_order_modal.select_order")}
                        </h1>
                        <Controller
                            control={control}
                            name="orderId"
                            render={({
                                field: { onChange, onBlur, value, name },
                            }) => (
                                <Select
                                    label={t("select_order_modal.select_label")}
                                    onSelect={(orderId) => onChange(orderId)}
                                    onBlur={(orderId) => onBlur(orderId)}
                                    options={mapStateToOptions(items)}
                                    type={SELECT_TYPES.WITH_LABEL}
                                    value={value}
                                    name={name}
                                />
                            )}
                        />
                        <button
                            disabled={
                                isEmptyObject(dirtyFields) || statusLoading
                            }
                            style={{
                                background: statusLoading && "#dfdfdf",
                            }}
                            className="select-order-modal__btn"
                            type="submit"
                        >
                            {statusLoading ? (
                                <ButtonLoader size={38} />
                            ) : (
                                t("btn_confirm")
                            )}
                        </button>
                    </>
                ) : (
                    <>
                        <h1 className="select-order-modal__title">
                            {t("select_order_modal.no_orders")}
                        </h1>
                        <button
                            className="select-order-modal__btn"
                            type="submit"
                        >
                            {t("orders.buttons.new-order")}
                        </button>
                    </>
                )}
            </form>
        </Fragment>
    );
};

SelectOrderModal.propTypes = { details: PropTypes.string };

export default SelectOrderModal;
