import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import React, { Fragment } from "react";

import { authSelector } from "../auth.selectors";
import { setRoleAction } from "../auth.actions";

import ReactHelmet from "../../../components/react-helmet/react-helmet";
import { userRoles } from "../../../constants/user-roles";
import routerBook from "../../../router/router-book";

import SearchIcon from "../../../assets/images/auth/type_user_search.svg";
import UserIcon from "../../../assets/images/auth/type_user_icon.svg";
import "./index.scss";

const UserTypeDistributor = () => {
    const { customer, specialist } = userRoles;
    const { role } = useSelector(authSelector);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const setAccountTypeSpecialist = () => dispatch(setRoleAction(specialist));
    const setAccountTypeCustomer = () => dispatch(setRoleAction(customer));

    return (
        <Fragment>
            <ReactHelmet title="аccount_type" />
            <div className="users_container">
                <div className="users_container__content">
                    <h2 className="users_container__title">
                        {t("аccount_type")}
                    </h2>
                    <div className="users_container__types">
                        <Link
                            to={routerBook.auth.emailConfirmation}
                            className={`users_container__btn specialist${
                                role === specialist ? "-active" : ""
                            }`}
                            state={true}
                            onClick={setAccountTypeSpecialist}
                        >
                            <img src={UserIcon} alt="user_icon" />
                            <p className="users_container__btn_decription">
                                {t("specialist")}
                            </p>
                        </Link>
                        <Link
                            to={routerBook.auth.emailConfirmation}
                            className={`users_container__btn customer${
                                role === customer ? "-active" : ""
                            }`}
                            state={true}
                            onClick={setAccountTypeCustomer}
                        >
                            <img src={SearchIcon} alt="search_icon" />
                            <p className="users_container__btn_decription">
                                {t("customer")}
                            </p>
                        </Link>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default UserTypeDistributor;
