import { t } from "i18next";
import { string, object, number } from "yup";

export const feedbackSchema = object().shape({
    text: string()
        .required("validation_required")
        .min(10, ({ min }) => t("validation_min", { min }))
        .max(5000, ({ max }) => t("validation_max", { max })),
    assessment: number().required("validation_required"),
});
