import { clientTypes } from "./clients.types";

const initialQueryParams = { limit: 5, page: 0 };

const initialState = {
    clientList: { items: [], count: 0 },
    params: initialQueryParams,
    loading: false,
    error: "",
};

export const client = (state = initialState, { type, payload }) => {
    switch (type) {
        // loading
        case clientTypes.GET_CLIENT_LIST_PENDING: {
            return { ...state, loading: true };
        }

        // data client list
        case clientTypes.GET_CLIENT_LIST_FULFILLED: {
            return { ...state, loading: false, clientList: payload };
        }

        // error
        case clientTypes.GET_CLIENT_LIST_REJECTED: {
            return { ...state, loading: false, error: payload };
        }

        // set
        case clientTypes.CLEAR_ALL_PARAMS:
            return { ...state, params: initialQueryParams };

        case clientTypes.SET_PAGE:
            return { ...state, params: { ...state.params, page: payload } };

        default:
            return state;
    }
};
